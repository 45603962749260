import React, { useState } from 'react'
import styled from 'styled-components'
import { GREY_MEDIUM_DARK, PRIMARY, SLATE } from '../constants/cssVars'
import { TextLink } from './Common'
import { useDispatch } from 'react-redux'
import { setPreviewModal } from '../actions/modals'
import { getFirstThreePaths } from '../actions/queries'
import downloadIcon from '../assets/icons/download.svg'
import eyeIcon from '../assets/icons/eye.svg'

const TDIS_DATA_CATALOG_URL_BASE =
  process.env.REACT_APP_TDIS_DATA_CATALOG_URL_BASE ||
  'https://tdisproddatalakehouse.blob.core.windows.net/tdis-data-bronze/ms2'

const SAS_TOKEN = process.env.REACT_APP_SAS_TOKEN || 'test'

const FileItem = styled.div`
  button,
  a {
    font-family: 'Open Sans';
    border: 0px;
    background-color: transparent;
    font-weight: 600;
    text-transform: none;
    color: ${PRIMARY};
    display: flex;
    align-items: center;
    &:hover {
      color: ${SLATE};
    }
  }
  a {
    font-weight: 500;
  }
  > div {
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    padding-top: 5px;
    padding-bottom: 10px;
  }
  > p {
    font-weight: 600;
    margin: 0px;
    margin-left: 23px;
    color: ${PRIMARY};
  }
  img {
    width: 20px;
    height: 18px;
    padding-top: 4px;
  }
  span {
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
`

const ARROW_SIZE = 7
const LilArrow = styled.div`
  width: 0;
  height: 0;
  border-top: ${ARROW_SIZE - 3}px solid transparent;
  border-bottom: ${ARROW_SIZE - 3}px solid transparent;
  border-left: ${ARROW_SIZE}px solid ${PRIMARY};
  margin-right: 10px;
  transform: rotateZ(${({ rotated }) => (rotated ? 90 : 0)}deg);
`

const EyeButton = styled.span`
  position: absolute;
  margin-left: 20px;
  opacity: 0.8;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  > button {
    background-color: none;
    border: none;
    cursor: pointer;
  }
`

const EmptyFolderMessage = styled.span`
  font-weight: 500;
  color: ${GREY_MEDIUM_DARK};
  font-style: italic;
  margin-left: 6px;
  font-size: 0.9em;
`

const PreviewButton = ({ fileObj }) => {
  const dispatch = useDispatch()
  return (
    <EyeButton>
      <button
        onClick={() => dispatch(setPreviewModal({ showModal: true, fileObj }))}
      >
        <img alt="view preview" src={eyeIcon}></img>
      </button>
    </EyeButton>
  )
}

const pathEndsWithPreviewableFileType = (path) => {
  return (
    path.endsWith('.png') ||
    path.endsWith('.img') ||
    path.endsWith('.pdf') ||
    path.endsWith('.txt')
  )
}

export const FileViewer = ({ unstructuredFiles, parent, name, path, id }) => {
  const [toggled, setToggled] = useState(false)
  const nextParent = parent[name]
  const fileObj = unstructuredFiles.find((obj) => {
    const shortenedPath = (obj.properties.path || '').replace(
      getFirstThreePaths(obj.properties.path),
      ''
    )
    return shortenedPath === path
  })
  const fullPath = `${TDIS_DATA_CATALOG_URL_BASE}/${fileObj.properties.path}${SAS_TOKEN}`
  return (
    <FileItem>
      {(nextParent && Object.keys(nextParent).length > 0) ||
      fileObj.properties.mime_type === 'directory' ? (
        <button onClick={() => setToggled(!toggled)}>
          <LilArrow rotated={toggled} /> {name}{' '}
          {nextParent && Object.keys(nextParent).length < 1 && (
            <EmptyFolderMessage>(empty folder)</EmptyFolderMessage>
          )}
        </button>
      ) : (
        <p>
          {name}
          <span
            style={{ position: 'absolute', marginLeft: '5px', opacity: '0.8' }}
          >
            <TextLink to={fullPath}>
              <img src={downloadIcon} alt="download"></img>
            </TextLink>{' '}
          </span>
          {pathEndsWithPreviewableFileType(path) && (
            <PreviewButton fileObj={fileObj} />
          )}
        </p>
      )}
      {toggled && nextParent && Object.keys(nextParent).length > 0 && (
        <div>
          {Object.keys(nextParent).map((childName, childIdx) => {
            const childId = `${id}_${childIdx}`
            return (
              <FileViewer
                key={childId}
                path={`${path}/${childName}`}
                parent={nextParent}
                name={childName}
                id={childId}
                unstructuredFiles={unstructuredFiles}
              />
            )
          })}
        </div>
      )}
    </FileItem>
  )
}
