import { useSelector } from "react-redux"
import { recordsSelector } from "../selectors/entities"
import { searchStrSelector } from "../selectors/general";
import { stringIsEmptyOrNull } from "../utils/helpers";

// filter by model name
export const useFilteredRecords = () => {
  const records = useSelector(recordsSelector);
  const searchStr = useSelector(searchStrSelector);

  if (stringIsEmptyOrNull(searchStr)) {
    return records;
  }

  return records.filter(record => record.currentMetadata.modelName && searchStr 
                                  && record.currentMetadata.modelName.toLowerCase().includes(searchStr.toLowerCase()))
}