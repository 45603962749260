import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setActiveDropdown } from '../../actions/general';
import { PRIMARY, PRIMARY_DARK, PRIMARY_LIGHT, SECONDARY, SHADOW_CRISP } from '../../constants/cssVars';
import { activeDropdownSelector } from '../../selectors/general';

const DropdownWrapper = styled.div`
  display: inline-block;
  margin-right: 10px;
  position: relative;
`

const DropdownButton = styled.button`
  font-size: 1em;
  border: none;
  background-color: transparent;
  padding: 0px;
  color: ${PRIMARY_LIGHT};
  cursor: pointer;
  margin-bottom: 10px;
  font-weight: 600;
  &:hover {
    color: ${PRIMARY};
  }
`

const DropdownItems = styled.div`
  position: absolute;
  top: 1.2em;
  right: 0px;
  z-index: 10;
  background-color: #fff;
  border: 1px solid grey;
  padding: 10px;
  box-shadow: ${SHADOW_CRISP};
`

export const Dropdown = ({ buttonContent, dropdownId, children }) => {
  const dispatch = useDispatch();
  const activeDropdown = useSelector(activeDropdownSelector);

  const handleClick = (e) => {
    e.stopPropagation();
    if (activeDropdown !== dropdownId) {
      dispatch(setActiveDropdown(dropdownId))
    } else {
      dispatch(setActiveDropdown(null))
    }
  }

  return (
    <DropdownWrapper>
      <DropdownButton id={`${dropdownId}_btn`} onClick={handleClick}>
        {buttonContent}
      </DropdownButton>
      {activeDropdown === dropdownId && <DropdownItems id={dropdownId}>
        {children}
      </DropdownItems>}
    </DropdownWrapper>
  )
}