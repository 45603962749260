import moment from 'moment';

const isValidEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
const isValidPhone = (phone) => /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(phone);
const isOnlyNumbers = (str) => /^[0-9]*$/.test(str);
const isOnlyLettersAndNumbers = (str) => /^[A-Za-z0-9]*$/.test(str);
const isEmptyStringOrNull = (val) => val == null || val === "";
const arrayHasValues = (val) => Array.isArray(val) && val.length > 0;
const isValidDate = (d) => d instanceof Date && !isNaN(d);

// Model ID: Numeric, 12-digit number. 2-digit Flood Planning Region identifier, followed by regionally-unique 10-digit number. 
const isValidModelId = (val) => /^(0|1)\d{11}$/.test(val)

// TWDB FP Project ID: Numeric, 9 digits. 2-digit Flood Planning Region identifier; followed by "1" (for FME), "2" (for FMS), 
// or "3" (for FMP); followed by 6-digit unique sequence generated by vendor.
const isValidTwdbFloodPlanningProjectID = (val) => /^(0|1)\d(1|2|3)\d{6}$/.test(val)

// HUC: 12-digit Hydrologic Unit Code(s) identifying the watershed(s).
const isValidHUC = (val) => /^\d{12}$/.test(val)

export const validateMetadata = (metadataFieldInfo) => (values) => {
  const errors = {};
  // return errors;

  if (!isEmptyStringOrNull(values.modelIdentifier) && !isValidModelId(values.modelIdentifier)) {
    errors.modelIdentifier = 'Must be a 12-digit number where first 2-digits are the Flood Planning Region identifier, followed by regionally-unique 10-digit number.';
  }

  if (arrayHasValues(values.twdbFloodPlanningProjectID) && values.twdbFloodPlanningProjectID.some(val => !isValidTwdbFloodPlanningProjectID(val))) {
    errors.twdbFloodPlanningProjectID = 'Each entry must be a 9 digit number where the first 2-digits are the Flood Planning Region identifier; followed by "1" (for FME), "2" (for FMS), or "3" (for FMP); followed by 6-digit unique sequence generated by vendor.'
  }

  if (arrayHasValues(values.hydrologicUnitCode) && values.hydrologicUnitCode.some(val => !isValidHUC(val))) {
    errors.hydrologicUnitCode = 'Each entry must be a 12 digit number.'
  }

  
  if (isEmptyStringOrNull(values.modelOriginatorAgencyId)) {
    errors.modelOriginatorAgencyId = "Required"
  }

  // Time period covered is an array of two-element date ranges
  // e.g. [[date1, date2], [date3, date4]]
  if (Array.isArray(values.timePeriodCovered)) {
    // For each two-element date range ...
    values.timePeriodCovered.forEach((datePair, idx) => {
      if ((datePair[0] == null && datePair[1] != null) || (datePair[0] != null && datePair[1] == null)) {
        errors.timePeriodCovered = "Start date & end date must both be defined or both be empty. If you'd like to represent a single date, simply use the same start & end date."
      } else if (moment(datePair[0]).isAfter(datePair[1], 'day')) {
        errors.timePeriodCovered = "End date cannot be before the start date."
      }
    })
  }

  Object.keys(values).forEach(key => {
    if (key !== 'contacts' && key !== 'tdisDataIdentifier' && key !== 'showOriginatorContact') {
      let fieldInfo = metadataFieldInfo[key];
      let value = values[key];
      if (fieldInfo.required) {
        if (!fieldInfo.acceptsMultipleValues && isEmptyStringOrNull(value)) {
          errors[key] = 'Required';
        }
        if (fieldInfo.acceptsMultipleValues && !arrayHasValues(value)) {
          errors[key] = 'Required, must add at least one entry'
        }
      }
      if (fieldInfo.isDate && !errors[key] && value.length > 0 && value[0].length > 0) {
        if (fieldInfo.acceptsMultipleValues && value.some(arr => 
              (arr[0] != null && arr[1] != null) 
           && (!isValidDate(new Date(arr[0])) || !isValidDate(new Date(arr[1]))))) {
          errors[key] = 'At least one date is invalid';
        }
        if (!fieldInfo.acceptsMultipleValues && !isValidDate(new Date(value))) {
          errors[key] = 'Date is invalid';
        }
      }
    }
  })

  return errors;
}

export const validateExample = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  return errors;
}

export const validateContact = (isOriginator, contactsById, isCreatingNew) => (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (!isValidEmail(values.email)) {
    errors.email = 'Invalid email address';
  } else {
    const emailAlreadyExists = Object.values(contactsById).some(contactObj => contactObj.email === values.email)
    // If not creating new (AKA editing), the email will already exist & is ineditable, so we shouldn't return an error
    if (emailAlreadyExists && isCreatingNew) {
      errors.email = 'A contact with this email already exists. Please select the contact from existing contacts list instead.';
    }
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = 'Required';
  }
  if (!isOriginator && !values.agency) {
    errors.agency = 'Required';
  }
  if (!values.firstName) {
    errors.firstName = 'Required';
  }
  if (!values.lastName) {
    errors.lastName = 'Required';
  }
  return errors;
}

export const validatePersonalContact = (values) => {
  const errors = {};
  if (!values.phoneNumber) {
    errors.phoneNumber = 'Required';
  }
  if (!values.agency) {
    errors.agency = 'Required';
  }
  if (!values.firstName) {
    errors.firstName = 'Required';
  }
  if (!values.lastName) {
    errors.lastName = 'Required';
  }
  return errors;
}

export const validateLogin = (values) => {
  const errors = {};
  if (!values.username) {
    errors.username = 'Username is required';
  }
  // } else if (!isValidEmail(values.email)) {
  //   errors.email = 'Invalid email address';
  // }
  if (!values.password) {
    errors.password = 'Password is required'
  }
  return errors;
}

export const validateForgotPassword = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!isValidEmail(values.email)) {
    errors.email = 'Invalid email address';
  }
  return errors;
}

export const validateResetPassword = (values) => {
  const errors = {};
  if (!values.oldPassword) {
    errors.oldPassword = 'Required';
  }

  if (!values.newPassword) {
    errors.newPassword = 'Required';
  } else if (!isOnlyLettersAndNumbers(values.newPassword)) {
    errors.newPassword = 'Due to usage in scripts, passwords can only contain numbers and upper & lowercase letters (certain symbols are restricted)';
  } else if (values.newPassword.length < 10) {
    errors.newPassword = 'Invalid password, must be 10 characters or more';
  }
  if (!values.newPasswordConfirmation) {
    errors.newPasswordConfirmation = 'Required'
  } else if (values.newPasswordConfirmation !== values.newPassword) {
    errors.newPasswordConfirmation = "Passwords must match"
  }
  return errors;
}