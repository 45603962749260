export const GREY_DARKEST = "#121212";
export const GREY_DARK = "#444444";
export const GREY_MEDIUM_DARK = "#686868";
export const GREY_MEDIUM = "#CECECE";
export const GREY_MEDIUM_LIGHT= "#dedede";
export const GREY_LIGHT = "#F0F0F0";
export const GREY_LIGHTER = "#F7F7F7";
export const GREY_LIGHTEST = "#FAFAFA";
export const WHITE = "#FFFFFF";
export const TABLE_GREY = "#E9E9E9";

// TDIS COLORS 
// Primary
export const ONYX = "#1B1C20";
export const WHOOP = "#500000";
export const RAIN = "#324755";
export const SLATE = "#6E8CA0";
export const SAGE = "#87BCBF";
export const SNOW = "#FFFFFF";
export const SUNSHINE = "#FCC931";
// Secondary
export const ICE = "#F0F3F4";
export const FOSSIL = "#C8D1D3";
export const SAND = "#B9B0A2";

export const PRIMARY = RAIN;
export const PRIMARY_LIGHT = SLATE;
export const PRIMARY_DARK = '#06624A';
export const SECONDARY = SAGE;
export const SECONDARY_LIGHT = "#7284D6";
export const TERTIARY = "#4F2489";
export const TERTIARY_LIGHT = "#8968B6";
export const HIGHLIGHT = SUNSHINE;
export const ERROR = '#C01818';

export const NOTICE = '#E8B44C';

export const BG_PRIMARY = ICE;
export const BG_LIGHTER = SNOW;
export const BG_DARKER = FOSSIL;

export const SHADOW_MEDIUM = "0 3px 6px rgba(0,0,0,.14)";
export const SHADOW_LIGHT = "0 2px 2px rgba(0,0,0,.06)";
export const SHADOW_CRISP = "0 2px 4px rgba(0,0,0,.16)";
export const SHADOW_DARK= "0 4px 8px rgba(0,0,0,.2)";
export const SHADOW_MENU= "-3px 0px 8px rgba(0,0,0,.2)";
export const SHADOW_NONE= "0px 0px 0px rgba(0,0,0,0)";

export const HEADER_HEIGHT = 60;
export const MAIN_CONTENT_WIDTH = 850;
export const MAX_DESKTOP_WIDTH = MAIN_CONTENT_WIDTH + 20;