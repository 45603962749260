import React, { useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useTable, usePagination, useFilters } from 'react-table'
import { useRequest } from 'redux-query-react';
import { recordsSelector } from '../selectors/entities';
import { Button, LinkLikeButton } from './Button';
import { ERROR, GREY_DARK, MAIN_CONTENT_WIDTH, FOSSIL, GREY_LIGHTER, GREY_MEDIUM, PRIMARY, SHADOW_CRISP, SLATE, SAGE, RAIN, NOTICE, GREY_MEDIUM_DARK, SHADOW_MEDIUM } from '../constants/cssVars';
import searchIcon from '../assets/icons/search.svg'
import { useHistory } from 'react-router';
import { Dropdown } from './general/Dropdown';
import { Table, TableRow, TableHeader, TableData, TableFooter, TableWrapper, OuterWrapper } from './Common'
import { metadataFieldInfo, metadataInit } from '../constants/metadataFields';
import cardIcon from '../assets/icons/cards.svg';
import tableIcon from '../assets/icons/table.svg';
import cardIconWhite from '../assets/icons/cards_white.svg';
import tableIconWhite from '../assets/icons/table_white.svg';
import { searchStrSelector, showTableViewSelector } from '../selectors/general';
import { setContactsAndRoles, setSearch, setTableView, updateContactId } from '../actions/general';
import { useDeleteRecordQuery, useDuplicateRecord } from '../hooks/messagesAndRequests';
import { useFilteredRecords } from '../hooks/useFilteredRecords';
import { UploadConflictsBanner } from './UploadConflictsBanner';
import { getAllUploadConflictsQuery, getRecordsQuery } from '../actions/queries';
import { WaveLoader } from './Loaders';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { useInterval } from '../hooks/useInterval';
import { QUERY_REFRESH_INTERVAL_MS } from '../constants/general';
import { Link } from 'react-router-dom';
import { useGlobalFilter } from 'react-table/dist/react-table.development';

const SearchWrapper = styled.div`
  position: relative;
  width: 250px;
  > img {
    position: absolute;
    top: 8px;
    right: 10px;
    width: 20px;
    height: 20px;
  }
  > input {
    padding-right: 35px !important;
    font-size: 0.9em;
  }
`

const ActionsBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
`

const ViewAndSearch = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
`

const Tile = styled.div`
  margin-bottom: 20px;
  width: 100%;
  background-color: #fff;
  box-shadow: ${SHADOW_CRISP};
  border: 1px solid ${FOSSIL};
  text-align: left;
  transition: all 0.2s ease;
  > div {
    padding: 20px;
    display: flex;
    justify-content: space-between;
  }
  &:hover {
    box-shadow: ${SHADOW_MEDIUM};
  }
`

const TileHeader = styled.h3`
  margin: 0px;
  margin-bottom: 10px;
  color: ${PRIMARY};
`

const TileText = styled.p`
  margin: 0px;
  margin-bottom: 10px;
  color: ${GREY_DARK};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  hyphens: auto;
`

const TableCardButtonWrapper = styled.div`
  padding-bottom: 0px;
  margin-right: 10px;
`

const TableButton = styled.button`
  border-radius: 0px 30px 30px 0px;
  padding: 5px 10px;
  padding-right: 15px;
  background-color: ${({active}) => active ? PRIMARY : '#fff'};
  border: 1px solid ${GREY_MEDIUM};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s linear;
  > img {
    width: 30px;
    height: 30px;
  }
  &:hover {
    background-color: ${({active}) => active ? PRIMARY : GREY_LIGHTER};
  }
`

const CardButton = styled(TableButton)`
  border-radius: 30px 0px 0px 30px;
  padding-left: 15px;
  padding-right: 10px;
  border-right: 0px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > h3 {
    margin: 0px;
    margin-bottom: 20px;
  }
`

const TopContent = styled.div`
  width: ${MAIN_CONTENT_WIDTH}px;
  @media only screen and (max-width: ${MAIN_CONTENT_WIDTH}px) {
    width: 100%;
  }
`

const ListWrapper = styled.div`
  width: ${MAIN_CONTENT_WIDTH}px;
  @media only screen and (max-width: ${MAIN_CONTENT_WIDTH}px) {
    width: 100%;
  }
`

const CreateDesktop = styled.div`
  display: block;
  @media only screen and (max-width: ${MAIN_CONTENT_WIDTH}px) {
    display: none;
  }
`

const CreateMobile = styled.div`
  display: none;
  margin-bottom: 10px;
  @media only screen and (max-width: ${MAIN_CONTENT_WIDTH}px) {
    display: block;
  }
`

// Homepage display listing all of the metadata records in card or table view
export const AllMetadataRecords = () => {
  const search = useSelector(searchStrSelector);
  const dispatch = useDispatch();
  const showTableView = useSelector(showTableViewSelector);
  const history = useHistory();
  const { accessToken, username } = useCurrentUser();
  const [{ isFinished }, refreshRecordsQuery] = useRequest(accessToken ? getRecordsQuery(username, accessToken) : null);
  const [, refreshUploadConflictsQuery] = useRequest(accessToken ? getAllUploadConflictsQuery(username, accessToken) : null);
  
  // Occassionally refresh our records & conflicts
  useInterval(() => {
    refreshRecordsQuery();
    refreshUploadConflictsQuery();
  }, QUERY_REFRESH_INTERVAL_MS);

  const createButton = (
    <Button short={true} onClick={() => {
      history.push('/app/form/new');
      dispatch(setContactsAndRoles('new', [''], ['']));
    }}>
      Create +
    </Button>
  )

  return (
    <>
      <TopContent>
        <UploadConflictsBanner />
        <Header>
          <h3>
            Metadata Records
          </h3>
          <CreateMobile>
            {createButton}
          </CreateMobile>
        </Header>
        <ActionsBar>
          <ViewAndSearch>
            <TableCardButtonWrapper>
              <CardButton active={!showTableView} onClick={() => dispatch(setTableView(false))}>
                <img src={showTableView ? cardIcon : cardIconWhite} alt="card format icon" />
              </CardButton>
              <TableButton active={showTableView} onClick={() => dispatch(setTableView(true))}>
                <img src={showTableView ? tableIconWhite : tableIcon} alt="table format icon" />
              </TableButton>
            </TableCardButtonWrapper>
            <SearchWrapper>
              <img src={searchIcon} alt="Search magnifying glass" />
              <input id="noticesSearchInput" placeholder="Search by name or ID" onChange={e => dispatch(setSearch(e.target.value))} value={search || ""} />
            </SearchWrapper>
          </ViewAndSearch>
          <CreateDesktop>
            {createButton}
          </CreateDesktop>
        </ActionsBar>
      </TopContent>
      {showTableView ? <TableView recordsQueryFinished={isFinished} /> : <CardsView recordsQueryFinished={isFinished} />}
    </>
  )
}

const StatusSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  min-width: 300px;
  font-size: 0.95em;
  > p {
    margin-top: 10px;
  }
`

const statusColor = {
  'in progress': SLATE,
  'pending': NOTICE,
  'accepted': SAGE,
  'rejected': ERROR
}

const statusVerb = {
  'in progress': 'Last updated',
  'pending': 'Uploaded',
  'accepted': 'Uploaded',
  'rejected': 'Uploaded',
}

const StatusCard = styled.div`
  border-radius: 40px;
  padding: 2px 10px;
  display: inline-block;
  background-color: ${({status}) => statusColor[status]};
  color: #fff;
`

// If the metadata object has 'in progress' status, link to form - otherwise link to static viewer
// obj.statusInfo.status === 'in progress'
const getMetadataLink = (obj) => obj.statusInfo.status === 'in progress' ? `/app/form/${obj.tdisDataIdentifier}` : `/app/view/${obj.tdisDataIdentifier}`

const prettyPrintArray = (arr, suffix) => Array.isArray(arr) ? arr.map(str => suffix ? `${str} ${suffix}` : str).join(', ') : ''

// Display metadata records as a list of cards
const CardsView = ({ recordsQueryFinished }) => {
  const history = useHistory();
  const records = useFilteredRecords();
  const [duplicate] = useDuplicateRecord();
  const [deleteRecord] = useDeleteRecordQuery();

  return <ListWrapper>
    {recordsQueryFinished && records.length === 0 && <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{maxWidth: '600px', textAlign: 'center', fontStyle: 'italic', color: GREY_DARK}}>
        <p>
        No records found.
        </p>
        <p>
        First time here? <Link style={{ color: SLATE }} to="/app/about">Learn about metadata records & the upload process.</Link>
        </p>
        <p>
        Once you're ready to get started, click <b>"Create +"</b> in the top-left corner to make your first metadata record.
        </p>
      </div>
    </div> }
    {!recordsQueryFinished && <WaveLoader />}
    {records.map((obj, idx) => {
      const handleDuplicateClick = (e) => {
        e.stopPropagation();
        duplicate(obj.tdisDataIdentifier, obj);
      }
      const handleDeleteClick = (e) => {
        e.stopPropagation();
        deleteRecord(obj.tdisDataIdentifier)
      }

      return (
        <div key={`card_${idx}_${obj.tdisDataIdentifier}`} style={{textDecoration: 'none', cursor: 'pointer'}} onClick={() => history.push(getMetadataLink(obj))}>
          <Tile>
            <div>
              <div>
                <TileHeader>{obj.currentMetadata.modelName}</TileHeader>
                <TileText>{obj.currentMetadata.modelDescription}</TileText>
                <TileText style={{ marginBottom: '0px'}}>{prettyPrintArray(obj.currentMetadata.city)}</TileText>
                <TileText>{prettyPrintArray(obj.currentMetadata.county, 'County')}</TileText>
              </div>
              <div style={{ display: 'inline-flex', flexDirection: 'column', alignItems: "flex-end", justifyContent: 'space-between' }}>
                <Dropdown buttonContent={<div style={{fontSize: '2em', height: '10px', position: 'relative', top: '-20px'}}>...</div>} dropdownId={`dropdown_${obj.tdisDataIdentifier}`}>
                  <LinkLikeButton style={{display: 'block', padding: '3px 40px 3px 8px'}} onClick={() => history.push(getMetadataLink(obj))}>{obj.statusInfo.status === 'in progress' ? 'Edit' : 'View'}</LinkLikeButton>
                  <LinkLikeButton style={{display: 'block', padding: '3px 40px 3px 8px'}} onClick={handleDuplicateClick}>Duplicate</LinkLikeButton>
                  <LinkLikeButton style={{display: 'block', padding: '3px 40px 3px 8px', color: ERROR}} onClick={handleDeleteClick}>Delete</LinkLikeButton>
                </Dropdown>
                <StatusSection>
                  <StatusCard status={obj.statusInfo.status}>{obj.statusInfo.status === 'pending' ? 'pending admin acceptance' : obj.statusInfo.status}</StatusCard>
                  <p>{statusVerb[obj.statusInfo.status]} on {moment(obj.statusInfo.timeOfLastStatusChange).format('YYYY-MM-DD')}</p>
                </StatusSection>
              </div>
            </div>
          </Tile>
        </div>
      )
    })}
  </ListWrapper>
}

// Pretty print the time-period array as a YYYY-MM-DD formatted string 
const convertTimePeriodToString = (value) => {
  if (value) {
    let arr = value.map(dateArr => dateArr.map(date => moment(date).format('YYYY-MM-DD')));
    return `[${arr.join(', ')}]`
  }
  return null;
}

// Join an array into a single string with comma space separationg.
// For example, ['one', 'two'] would become 'one, two'
const convertArrayToString = (arr) => arr.join(', ')

// Filter function for search input
const searchStringFilter = (rows, ids, query) => {
    return rows.filter((row) => 
        row.values['modelName'].includes(query) ||
        row.values['modelIdentifier'].includes(query)
    );
}

// Table view of metadata records
const TableView = ({ recordsQueryFinished }) => {
  const records = useSelector(recordsSelector);
  const searchStr = useSelector(searchStrSelector);
  const history = useHistory();
  const [duplicate] = useDuplicateRecord();

  const data = React.useMemo(
    () => records.map(obj => {
      const metadataWithStringArrs = Object.keys(obj.currentMetadata).reduce((accum, key) => {
        accum[key] = obj.currentMetadata[key]
        if (Array.isArray(obj.currentMetadata[key])) {
          accum[key] = convertArrayToString(obj.currentMetadata[key])
        }
        return accum;
      }, {})
      return { 
        ...metadataWithStringArrs, 
        timePeriodCovered: convertTimePeriodToString(obj.currentMetadata.timePeriodCovered),
        editBtn: <>
          <Button padding="6px 10px" style={{ marginRight: '5px' }} short={true} onClick={() => history.push(getMetadataLink(obj))}>
            {obj.statusInfo.status === 'in progress' ? 'Edit' : 'View'}
          </Button>
          <Button padding="6px 10px" short={true} onClick={() => duplicate(obj.tdisDataIdentifier, obj)}>Duplicate</Button>
        </>,
      }}),
    [records]
  )

  const keysToSkip = ['contacts', 'tdisCollectionIdentifier', 'modelUpdateFrequency', 'modelIdentifier', 'modelName']
  const columns = React.useMemo(() => {
    const fieldColumns = Object.keys(metadataInit).filter(key => !keysToSkip.includes(key)).map(key => {
      return { accessor: key, Header: metadataFieldInfo[key].friendlyName };
    })
    return [
      { accessor: 'editBtn', Header: '' }, 
      { accessor: 'modelName', Header: metadataFieldInfo['modelName'].friendlyName }, 
      { accessor: 'modelIdentifier', Header: metadataFieldInfo['modelIdentifier'].friendlyName }, 
      ...fieldColumns
    ]
  }, [])
    
  const props = useTable(
    { columns, data, initialState: { pageSize: 7 }, globalFilter: searchStringFilter }, 
    useFilters, useGlobalFilter, usePagination,
  );
  const { 
    getTableProps, getTableBodyProps, headerGroups, prepareRow, page, canPreviousPage, setGlobalFilter,
    canNextPage, pageOptions, pageCount, gotoPage, nextPage, previousPage, state,
  } = props;

  useEffect(() => {
    setGlobalFilter(searchStr);
  }, [searchStr])
  
  return (
    <OuterWrapper>
      <TableWrapper>
        <Table {...getTableProps()}>
          <thead className="no-scroll">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, idx) => (
                  <TableHeader {...column.getHeaderProps()} style={{ textAlign: idx > 0 ? 'center' : 'left', minWidth: idx > 0 ? '200px' : '190px', padding: '5px 0px' }}>
                    {column.render("Header")}
                  </TableHeader>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="no-scroll" {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell, idx) => {
                    return (
                      <TableData style={{ textAlign: 'center' }} highlighted={row.original.dataIsAvailable} {...cell.getCellProps()}>
                        <div style={{ maxWidth: '300px', maxHeight: '70px', overflow: 'auto', textOverflow: 'ellipses'}}>
                          {cell.render('Cell')}
                        </div>
                      </TableData>
                    );
                  })}
                </TableRow>
              );
            })}
          </tbody>
        </Table>
      </TableWrapper>
      {!recordsQueryFinished && <WaveLoader />}
      {recordsQueryFinished && page.length < 1 &&
          <div style={{ padding: '20px', textAlign: 'center', fontStyle: 'italic'}}>No records found.</div> }
      <TableFooter>
        <div>
          <div>
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {"first"}
            </button>{" "}
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {"<"}
            </button>{" "}
            <span style={{margin: '0px 10px'}}>
              {" "}<strong>{state.pageIndex + 1}</strong> of {pageOptions.length}{" "}
            </span>
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {">"}
            </button>{" "}
            <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              {"last"}
            </button>{" "}
          </div>
        </div>
      </TableFooter>
    </OuterWrapper>
  );
}