const hucsRegion01 = [
  { value: '110800060909', regionId: '01', label: '110800060909 (Canadian-Upper Red)' },
  { value: '110901010104', regionId: '01', label: '110901010104 (Canadian-Upper Red)' },
  { value: '110901010105', regionId: '01', label: '110901010105 (Canadian-Upper Red)' },
  { value: '110901010108', regionId: '01', label: '110901010108 (Canadian-Upper Red)' },
  { value: '110901010203', regionId: '01', label: '110901010203 (Canadian-Upper Red)' },
  { value: '110901010204', regionId: '01', label: '110901010204 (Canadian-Upper Red)' },
  { value: '110901010305', regionId: '01', label: '110901010305 (Canadian-Upper Red)' },
  { value: '110901010401', regionId: '01', label: '110901010401 (Canadian-Upper Red)' },
  { value: '110901010403', regionId: '01', label: '110901010403 (Canadian-Upper Red)' },
  { value: '110901010404', regionId: '01', label: '110901010404 (Canadian-Upper Red)' },
  { value: '110901010405', regionId: '01', label: '110901010405 (Canadian-Upper Red)' },
  { value: '110901010707', regionId: '01', label: '110901010707 (Canadian-Upper Red)' },
  { value: '110901010708', regionId: '01', label: '110901010708 (Canadian-Upper Red)' },
  { value: '110901010106', regionId: '01', label: '110901010106 (Canadian-Upper Red)' },
  { value: '110901010107', regionId: '01', label: '110901010107 (Canadian-Upper Red)' },
  { value: '110901010109', regionId: '01', label: '110901010109 (Canadian-Upper Red)' },
  { value: '110901010205', regionId: '01', label: '110901010205 (Canadian-Upper Red)' },
  { value: '110901010703', regionId: '01', label: '110901010703 (Canadian-Upper Red)' },
  { value: '110901010704', regionId: '01', label: '110901010704 (Canadian-Upper Red)' },
  { value: '110901010501', regionId: '01', label: '110901010501 (Canadian-Upper Red)' },
  { value: '110901010502', regionId: '01', label: '110901010502 (Canadian-Upper Red)' },
  { value: '110901010503', regionId: '01', label: '110901010503 (Canadian-Upper Red)' },
  { value: '110901010504', regionId: '01', label: '110901010504 (Canadian-Upper Red)' },
  { value: '110901010505', regionId: '01', label: '110901010505 (Canadian-Upper Red)' },
  { value: '110901010601', regionId: '01', label: '110901010601 (Canadian-Upper Red)' },
  { value: '110901010602', regionId: '01', label: '110901010602 (Canadian-Upper Red)' },
  { value: '110901010603', regionId: '01', label: '110901010603 (Canadian-Upper Red)' },
  { value: '110901010604', regionId: '01', label: '110901010604 (Canadian-Upper Red)' },
  { value: '110901010605', regionId: '01', label: '110901010605 (Canadian-Upper Red)' },
  { value: '110901010701', regionId: '01', label: '110901010701 (Canadian-Upper Red)' },
  { value: '110901010702', regionId: '01', label: '110901010702 (Canadian-Upper Red)' },
  { value: '110901010705', regionId: '01', label: '110901010705 (Canadian-Upper Red)' },
  { value: '110901010706', regionId: '01', label: '110901010706 (Canadian-Upper Red)' },
  { value: '110901040304', regionId: '01', label: '110901040304 (Canadian-Upper Red)' },
  { value: '110901040305', regionId: '01', label: '110901040305 (Canadian-Upper Red)' },
  { value: '110901040307', regionId: '01', label: '110901040307 (Canadian-Upper Red)' },
  { value: '110901040401', regionId: '01', label: '110901040401 (Canadian-Upper Red)' },
  { value: '110901040402', regionId: '01', label: '110901040402 (Canadian-Upper Red)' },
  { value: '110901040306', regionId: '01', label: '110901040306 (Canadian-Upper Red)' },
  { value: '110901040403', regionId: '01', label: '110901040403 (Canadian-Upper Red)' },
  { value: '110901040404', regionId: '01', label: '110901040404 (Canadian-Upper Red)' },
  { value: '110901040405', regionId: '01', label: '110901040405 (Canadian-Upper Red)' },
  { value: '110901040406', regionId: '01', label: '110901040406 (Canadian-Upper Red)' },
  { value: '110901040407', regionId: '01', label: '110901040407 (Canadian-Upper Red)' },
  { value: '111201020101', regionId: '01', label: '111201020101 (Canadian-Upper Red)' },
  { value: '111201020104', regionId: '01', label: '111201020104 (Canadian-Upper Red)' },
  { value: '111201020105', regionId: '01', label: '111201020105 (Canadian-Upper Red)' },
  { value: '111201020202', regionId: '01', label: '111201020202 (Canadian-Upper Red)' },
  { value: '111201020203', regionId: '01', label: '111201020203 (Canadian-Upper Red)' },
  { value: '111201020204', regionId: '01', label: '111201020204 (Canadian-Upper Red)' },
  { value: '111201020205', regionId: '01', label: '111201020205 (Canadian-Upper Red)' },
  { value: '111201020206', regionId: '01', label: '111201020206 (Canadian-Upper Red)' },
  { value: '111201020207', regionId: '01', label: '111201020207 (Canadian-Upper Red)' },
  { value: '111201020303', regionId: '01', label: '111201020303 (Canadian-Upper Red)' },
  { value: '111201020102', regionId: '01', label: '111201020102 (Canadian-Upper Red)' },
  { value: '111201020103', regionId: '01', label: '111201020103 (Canadian-Upper Red)' },
  { value: '111201020106', regionId: '01', label: '111201020106 (Canadian-Upper Red)' },
  { value: '111201020201', regionId: '01', label: '111201020201 (Canadian-Upper Red)' },
  { value: '111201020301', regionId: '01', label: '111201020301 (Canadian-Upper Red)' },
  { value: '111201020302', regionId: '01', label: '111201020302 (Canadian-Upper Red)' },
  { value: '111201020304', regionId: '01', label: '111201020304 (Canadian-Upper Red)' },
  { value: '110902010101', regionId: '01', label: '110902010101 (Canadian-Upper Red)' },
  { value: '110902010102', regionId: '01', label: '110902010102 (Canadian-Upper Red)' },
  { value: '110902010104', regionId: '01', label: '110902010104 (Canadian-Upper Red)' },
  { value: '111001020506', regionId: '01', label: '111001020506 (Canadian-Upper Red)' },
  { value: '111001020503', regionId: '01', label: '111001020503 (Canadian-Upper Red)' },
  { value: '111001020504', regionId: '01', label: '111001020504 (Canadian-Upper Red)' },
  { value: '111202020301', regionId: '01', label: '111202020301 (Canadian-Upper Red)' },
  { value: '111202020101', regionId: '01', label: '111202020101 (Canadian-Upper Red)' },
  { value: '111202020102', regionId: '01', label: '111202020102 (Canadian-Upper Red)' },
  { value: '111202020103', regionId: '01', label: '111202020103 (Canadian-Upper Red)' },
  { value: '111202020104', regionId: '01', label: '111202020104 (Canadian-Upper Red)' },
  { value: '111202020105', regionId: '01', label: '111202020105 (Canadian-Upper Red)' },
  { value: '111202020106', regionId: '01', label: '111202020106 (Canadian-Upper Red)' },
  { value: '111202020107', regionId: '01', label: '111202020107 (Canadian-Upper Red)' },
  { value: '111202020108', regionId: '01', label: '111202020108 (Canadian-Upper Red)' },
  { value: '111202020201', regionId: '01', label: '111202020201 (Canadian-Upper Red)' },
  { value: '111202020202', regionId: '01', label: '111202020202 (Canadian-Upper Red)' },
  { value: '111202020203', regionId: '01', label: '111202020203 (Canadian-Upper Red)' },
  { value: '111202020204', regionId: '01', label: '111202020204 (Canadian-Upper Red)' },
  { value: '111202020205', regionId: '01', label: '111202020205 (Canadian-Upper Red)' },
  { value: '111202020206', regionId: '01', label: '111202020206 (Canadian-Upper Red)' },
  { value: '111203020107', regionId: '01', label: '111203020107 (Canadian-Upper Red)' },
  { value: '111203020210', regionId: '01', label: '111203020210 (Canadian-Upper Red)' },
  { value: '111203020211', regionId: '01', label: '111203020211 (Canadian-Upper Red)' },
  { value: '111203020212', regionId: '01', label: '111203020212 (Canadian-Upper Red)' },
  { value: '111203020213', regionId: '01', label: '111203020213 (Canadian-Upper Red)' },
  { value: '111203020304', regionId: '01', label: '111203020304 (Canadian-Upper Red)' },
  { value: '111203020101', regionId: '01', label: '111203020101 (Canadian-Upper Red)' },
  { value: '111203020102', regionId: '01', label: '111203020102 (Canadian-Upper Red)' },
  { value: '111203020103', regionId: '01', label: '111203020103 (Canadian-Upper Red)' },
  { value: '111203020104', regionId: '01', label: '111203020104 (Canadian-Upper Red)' },
  { value: '111203020105', regionId: '01', label: '111203020105 (Canadian-Upper Red)' },
  { value: '111203020106', regionId: '01', label: '111203020106 (Canadian-Upper Red)' },
  { value: '111203020201', regionId: '01', label: '111203020201 (Canadian-Upper Red)' },
  { value: '111203020202', regionId: '01', label: '111203020202 (Canadian-Upper Red)' },
  { value: '111203020203', regionId: '01', label: '111203020203 (Canadian-Upper Red)' },
  { value: '111203020204', regionId: '01', label: '111203020204 (Canadian-Upper Red)' },
  { value: '111203020205', regionId: '01', label: '111203020205 (Canadian-Upper Red)' },
  { value: '111203020206', regionId: '01', label: '111203020206 (Canadian-Upper Red)' },
  { value: '111203020207', regionId: '01', label: '111203020207 (Canadian-Upper Red)' },
  { value: '111203020208', regionId: '01', label: '111203020208 (Canadian-Upper Red)' },
  { value: '111203020209', regionId: '01', label: '111203020209 (Canadian-Upper Red)' },
  { value: '111203040108', regionId: '01', label: '111203040108 (Canadian-Upper Red)' },
  { value: '111203040109', regionId: '01', label: '111203040109 (Canadian-Upper Red)' },
  { value: '111203040107', regionId: '01', label: '111203040107 (Canadian-Upper Red)' },
  { value: '111203040203', regionId: '01', label: '111203040203 (Canadian-Upper Red)' },
  { value: '111203040101', regionId: '01', label: '111203040101 (Canadian-Upper Red)' },
  { value: '111203040102', regionId: '01', label: '111203040102 (Canadian-Upper Red)' },
  { value: '111203040103', regionId: '01', label: '111203040103 (Canadian-Upper Red)' },
  { value: '111203040104', regionId: '01', label: '111203040104 (Canadian-Upper Red)' },
  { value: '111203040105', regionId: '01', label: '111203040105 (Canadian-Upper Red)' },
  { value: '111203040106', regionId: '01', label: '111203040106 (Canadian-Upper Red)' },
  { value: '111301020101', regionId: '01', label: '111301020101 (Canadian-Upper Red)' },
  { value: '111301020103', regionId: '01', label: '111301020103 (Canadian-Upper Red)' },
  { value: '111301020107', regionId: '01', label: '111301020107 (Canadian-Upper Red)' },
  { value: '111301020205', regionId: '01', label: '111301020205 (Canadian-Upper Red)' },
  { value: '111301020206', regionId: '01', label: '111301020206 (Canadian-Upper Red)' },
  { value: '111301020303', regionId: '01', label: '111301020303 (Canadian-Upper Red)' },
  { value: '111301020305', regionId: '01', label: '111301020305 (Canadian-Upper Red)' },
  { value: '111301020307', regionId: '01', label: '111301020307 (Canadian-Upper Red)' },
  { value: '111301020102', regionId: '01', label: '111301020102 (Canadian-Upper Red)' },
  { value: '111301020202', regionId: '01', label: '111301020202 (Canadian-Upper Red)' },
  { value: '111301020203', regionId: '01', label: '111301020203 (Canadian-Upper Red)' },
  { value: '111301020204', regionId: '01', label: '111301020204 (Canadian-Upper Red)' },
  { value: '111301020302', regionId: '01', label: '111301020302 (Canadian-Upper Red)' },
  { value: '111301020304', regionId: '01', label: '111301020304 (Canadian-Upper Red)' },
  { value: '111301020306', regionId: '01', label: '111301020306 (Canadian-Upper Red)' },
  { value: '111302010708', regionId: '01', label: '111302010708 (Canadian-Upper Red)' },
  { value: '111302010102', regionId: '01', label: '111302010102 (Canadian-Upper Red)' },
  { value: '111302010104', regionId: '01', label: '111302010104 (Canadian-Upper Red)' },
  { value: '111302010105', regionId: '01', label: '111302010105 (Canadian-Upper Red)' },
  { value: '111302010201', regionId: '01', label: '111302010201 (Canadian-Upper Red)' },
  { value: '111302010210', regionId: '01', label: '111302010210 (Canadian-Upper Red)' },
  { value: '111302010211', regionId: '01', label: '111302010211 (Canadian-Upper Red)' },
  { value: '111302010502', regionId: '01', label: '111302010502 (Canadian-Upper Red)' },
  { value: '111302010503', regionId: '01', label: '111302010503 (Canadian-Upper Red)' },
  { value: '111302010504', regionId: '01', label: '111302010504 (Canadian-Upper Red)' },
  { value: '111302010508', regionId: '01', label: '111302010508 (Canadian-Upper Red)' },
  { value: '111302010702', regionId: '01', label: '111302010702 (Canadian-Upper Red)' },
  { value: '111302010703', regionId: '01', label: '111302010703 (Canadian-Upper Red)' },
  { value: '111302010704', regionId: '01', label: '111302010704 (Canadian-Upper Red)' },
  { value: '111302010705', regionId: '01', label: '111302010705 (Canadian-Upper Red)' },
  { value: '111302010707', regionId: '01', label: '111302010707 (Canadian-Upper Red)' },
  { value: '111302010103', regionId: '01', label: '111302010103 (Canadian-Upper Red)' },
  { value: '111302010202', regionId: '01', label: '111302010202 (Canadian-Upper Red)' },
  { value: '111302010203', regionId: '01', label: '111302010203 (Canadian-Upper Red)' },
  { value: '111302010204', regionId: '01', label: '111302010204 (Canadian-Upper Red)' },
  { value: '111302010205', regionId: '01', label: '111302010205 (Canadian-Upper Red)' },
  { value: '111302010206', regionId: '01', label: '111302010206 (Canadian-Upper Red)' },
  { value: '111302010207', regionId: '01', label: '111302010207 (Canadian-Upper Red)' },
  { value: '111302010208', regionId: '01', label: '111302010208 (Canadian-Upper Red)' },
  { value: '111302010209', regionId: '01', label: '111302010209 (Canadian-Upper Red)' },
  { value: '111302010505', regionId: '01', label: '111302010505 (Canadian-Upper Red)' },
  { value: '111302010506', regionId: '01', label: '111302010506 (Canadian-Upper Red)' },
  { value: '111302010507', regionId: '01', label: '111302010507 (Canadian-Upper Red)' },
  { value: '111302010701', regionId: '01', label: '111302010701 (Canadian-Upper Red)' },
  { value: '111303010202', regionId: '01', label: '111303010202 (Canadian-Upper Red)' },
  { value: '111303010204', regionId: '01', label: '111303010204 (Canadian-Upper Red)' },
  { value: '111303010205', regionId: '01', label: '111303010205 (Canadian-Upper Red)' },
  { value: '111303010103', regionId: '01', label: '111303010103 (Canadian-Upper Red)' },
  { value: '111303010104', regionId: '01', label: '111303010104 (Canadian-Upper Red)' },
  { value: '111303010105', regionId: '01', label: '111303010105 (Canadian-Upper Red)' },
  { value: '111303010106', regionId: '01', label: '111303010106 (Canadian-Upper Red)' },
  { value: '111303010107', regionId: '01', label: '111303010107 (Canadian-Upper Red)' },
  { value: '111303010201', regionId: '01', label: '111303010201 (Canadian-Upper Red)' },
  { value: '111303010101', regionId: '01', label: '111303010101 (Canadian-Upper Red)' },
  { value: '111303010102', regionId: '01', label: '111303010102 (Canadian-Upper Red)' },
  { value: '111001010601', regionId: '01', label: '111001010601 (Canadian-Upper Red)' },
  { value: '111001010603', regionId: '01', label: '111001010603 (Canadian-Upper Red)' },
  { value: '111001010801', regionId: '01', label: '111001010801 (Canadian-Upper Red)' },
  { value: '111001010802', regionId: '01', label: '111001010802 (Canadian-Upper Red)' },
  { value: '111001010804', regionId: '01', label: '111001010804 (Canadian-Upper Red)' },
  { value: '111001010805', regionId: '01', label: '111001010805 (Canadian-Upper Red)' },
  { value: '110901050403', regionId: '01', label: '110901050403 (Canadian-Upper Red)' },
  { value: '110901050404', regionId: '01', label: '110901050404 (Canadian-Upper Red)' },
  { value: '110901050405', regionId: '01', label: '110901050405 (Canadian-Upper Red)' },
  { value: '110901050406', regionId: '01', label: '110901050406 (Canadian-Upper Red)' },
  { value: '110901050501', regionId: '01', label: '110901050501 (Canadian-Upper Red)' },
  { value: '110901050502', regionId: '01', label: '110901050502 (Canadian-Upper Red)' },
  { value: '110901050503', regionId: '01', label: '110901050503 (Canadian-Upper Red)' },
  { value: '110901050601', regionId: '01', label: '110901050601 (Canadian-Upper Red)' },
  { value: '110901050204', regionId: '01', label: '110901050204 (Canadian-Upper Red)' },
  { value: '110901050205', regionId: '01', label: '110901050205 (Canadian-Upper Red)' },
  { value: '110901050206', regionId: '01', label: '110901050206 (Canadian-Upper Red)' },
  { value: '110901050207', regionId: '01', label: '110901050207 (Canadian-Upper Red)' },
  { value: '110901050208', regionId: '01', label: '110901050208 (Canadian-Upper Red)' },
  { value: '110901050209', regionId: '01', label: '110901050209 (Canadian-Upper Red)' },
  { value: '110901050210', regionId: '01', label: '110901050210 (Canadian-Upper Red)' },
  { value: '110901050211', regionId: '01', label: '110901050211 (Canadian-Upper Red)' },
  { value: '110901050301', regionId: '01', label: '110901050301 (Canadian-Upper Red)' },
  { value: '110901050302', regionId: '01', label: '110901050302 (Canadian-Upper Red)' },
  { value: '110901050303', regionId: '01', label: '110901050303 (Canadian-Upper Red)' },
  { value: '110901050304', regionId: '01', label: '110901050304 (Canadian-Upper Red)' },
  { value: '110901050305', regionId: '01', label: '110901050305 (Canadian-Upper Red)' },
  { value: '110901050306', regionId: '01', label: '110901050306 (Canadian-Upper Red)' },
  { value: '110901050307', regionId: '01', label: '110901050307 (Canadian-Upper Red)' },
  { value: '110901050308', regionId: '01', label: '110901050308 (Canadian-Upper Red)' },
  { value: '110901050309', regionId: '01', label: '110901050309 (Canadian-Upper Red)' },
  { value: '110901050310', regionId: '01', label: '110901050310 (Canadian-Upper Red)' },
  { value: '110901050401', regionId: '01', label: '110901050401 (Canadian-Upper Red)' },
  { value: '110901050402', regionId: '01', label: '110901050402 (Canadian-Upper Red)' },
  { value: '110901050602', regionId: '01', label: '110901050602 (Canadian-Upper Red)' },
  { value: '110901050603', regionId: '01', label: '110901050603 (Canadian-Upper Red)' },
  { value: '110901050604', regionId: '01', label: '110901050604 (Canadian-Upper Red)' },
  { value: '110901050605', regionId: '01', label: '110901050605 (Canadian-Upper Red)' },
  { value: '110901050606', regionId: '01', label: '110901050606 (Canadian-Upper Red)' },
  { value: '110901050607', regionId: '01', label: '110901050607 (Canadian-Upper Red)' },
  { value: '110901050608', regionId: '01', label: '110901050608 (Canadian-Upper Red)' },
  { value: '110901050701', regionId: '01', label: '110901050701 (Canadian-Upper Red)' },
  { value: '110901050702', regionId: '01', label: '110901050702 (Canadian-Upper Red)' },
  { value: '110901050703', regionId: '01', label: '110901050703 (Canadian-Upper Red)' },
  { value: '110901050704', regionId: '01', label: '110901050704 (Canadian-Upper Red)' },
  { value: '110901050705', regionId: '01', label: '110901050705 (Canadian-Upper Red)' },
  { value: '110901050706', regionId: '01', label: '110901050706 (Canadian-Upper Red)' },
  { value: '110901050707', regionId: '01', label: '110901050707 (Canadian-Upper Red)' },
  { value: '110901050708', regionId: '01', label: '110901050708 (Canadian-Upper Red)' },
  { value: '110901050202', regionId: '01', label: '110901050202 (Canadian-Upper Red)' },
  { value: '110901050203', regionId: '01', label: '110901050203 (Canadian-Upper Red)' },
  { value: '110901050101', regionId: '01', label: '110901050101 (Canadian-Upper Red)' },
  { value: '110901050102', regionId: '01', label: '110901050102 (Canadian-Upper Red)' },
  { value: '110901050103', regionId: '01', label: '110901050103 (Canadian-Upper Red)' },
  { value: '110901050104', regionId: '01', label: '110901050104 (Canadian-Upper Red)' },
  { value: '110901050105', regionId: '01', label: '110901050105 (Canadian-Upper Red)' },
  { value: '110901050106', regionId: '01', label: '110901050106 (Canadian-Upper Red)' },
  { value: '110901050201', regionId: '01', label: '110901050201 (Canadian-Upper Red)' },
  { value: '110901060808', regionId: '01', label: '110901060808 (Canadian-Upper Red)' },
  { value: '110901060807', regionId: '01', label: '110901060807 (Canadian-Upper Red)' },
  { value: '110901060301', regionId: '01', label: '110901060301 (Canadian-Upper Red)' },
  { value: '110901060311', regionId: '01', label: '110901060311 (Canadian-Upper Red)' },
  { value: '110901060101', regionId: '01', label: '110901060101 (Canadian-Upper Red)' },
  { value: '110901060102', regionId: '01', label: '110901060102 (Canadian-Upper Red)' },
  { value: '110901060103', regionId: '01', label: '110901060103 (Canadian-Upper Red)' },
  { value: '110901060104', regionId: '01', label: '110901060104 (Canadian-Upper Red)' },
  { value: '110901060105', regionId: '01', label: '110901060105 (Canadian-Upper Red)' },
  { value: '110901060106', regionId: '01', label: '110901060106 (Canadian-Upper Red)' },
  { value: '110901060107', regionId: '01', label: '110901060107 (Canadian-Upper Red)' },
  { value: '110901060108', regionId: '01', label: '110901060108 (Canadian-Upper Red)' },
  { value: '110901060109', regionId: '01', label: '110901060109 (Canadian-Upper Red)' },
  { value: '110901060201', regionId: '01', label: '110901060201 (Canadian-Upper Red)' },
  { value: '110901060409', regionId: '01', label: '110901060409 (Canadian-Upper Red)' },
  { value: '110901060410', regionId: '01', label: '110901060410 (Canadian-Upper Red)' },
  { value: '110901060411', regionId: '01', label: '110901060411 (Canadian-Upper Red)' },
  { value: '110901060202', regionId: '01', label: '110901060202 (Canadian-Upper Red)' },
  { value: '110901060203', regionId: '01', label: '110901060203 (Canadian-Upper Red)' },
  { value: '110901060204', regionId: '01', label: '110901060204 (Canadian-Upper Red)' },
  { value: '110901060205', regionId: '01', label: '110901060205 (Canadian-Upper Red)' },
  { value: '110901060206', regionId: '01', label: '110901060206 (Canadian-Upper Red)' },
  { value: '110901060207', regionId: '01', label: '110901060207 (Canadian-Upper Red)' },
  { value: '110901060208', regionId: '01', label: '110901060208 (Canadian-Upper Red)' },
  { value: '110901060302', regionId: '01', label: '110901060302 (Canadian-Upper Red)' },
  { value: '110901060303', regionId: '01', label: '110901060303 (Canadian-Upper Red)' },
  { value: '110901060304', regionId: '01', label: '110901060304 (Canadian-Upper Red)' },
  { value: '110901060305', regionId: '01', label: '110901060305 (Canadian-Upper Red)' },
  { value: '110901060306', regionId: '01', label: '110901060306 (Canadian-Upper Red)' },
  { value: '110901060307', regionId: '01', label: '110901060307 (Canadian-Upper Red)' },
  { value: '110901060308', regionId: '01', label: '110901060308 (Canadian-Upper Red)' },
  { value: '110901060309', regionId: '01', label: '110901060309 (Canadian-Upper Red)' },
  { value: '110901060310', regionId: '01', label: '110901060310 (Canadian-Upper Red)' },
  { value: '110901060401', regionId: '01', label: '110901060401 (Canadian-Upper Red)' },
  { value: '110901060402', regionId: '01', label: '110901060402 (Canadian-Upper Red)' },
  { value: '110901060403', regionId: '01', label: '110901060403 (Canadian-Upper Red)' },
  { value: '110901060404', regionId: '01', label: '110901060404 (Canadian-Upper Red)' },
  { value: '110901060405', regionId: '01', label: '110901060405 (Canadian-Upper Red)' },
  { value: '110901060406', regionId: '01', label: '110901060406 (Canadian-Upper Red)' },
  { value: '110901060407', regionId: '01', label: '110901060407 (Canadian-Upper Red)' },
  { value: '110901060408', regionId: '01', label: '110901060408 (Canadian-Upper Red)' },
  { value: '110901060501', regionId: '01', label: '110901060501 (Canadian-Upper Red)' },
  { value: '110901060502', regionId: '01', label: '110901060502 (Canadian-Upper Red)' },
  { value: '110901060503', regionId: '01', label: '110901060503 (Canadian-Upper Red)' },
  { value: '110901060504', regionId: '01', label: '110901060504 (Canadian-Upper Red)' },
  { value: '110901060602', regionId: '01', label: '110901060602 (Canadian-Upper Red)' },
  { value: '110901060601', regionId: '01', label: '110901060601 (Canadian-Upper Red)' },
  { value: '110901060505', regionId: '01', label: '110901060505 (Canadian-Upper Red)' },
  { value: '110901060506', regionId: '01', label: '110901060506 (Canadian-Upper Red)' },
  { value: '110901060507', regionId: '01', label: '110901060507 (Canadian-Upper Red)' },
  { value: '110901060603', regionId: '01', label: '110901060603 (Canadian-Upper Red)' },
  { value: '110901060604', regionId: '01', label: '110901060604 (Canadian-Upper Red)' },
  { value: '110901060605', regionId: '01', label: '110901060605 (Canadian-Upper Red)' },
  { value: '110901060606', regionId: '01', label: '110901060606 (Canadian-Upper Red)' },
  { value: '110901060607', regionId: '01', label: '110901060607 (Canadian-Upper Red)' },
  { value: '110901060805', regionId: '01', label: '110901060805 (Canadian-Upper Red)' },
  { value: '110901060608', regionId: '01', label: '110901060608 (Canadian-Upper Red)' },
  { value: '110901060609', regionId: '01', label: '110901060609 (Canadian-Upper Red)' },
  { value: '110901060701', regionId: '01', label: '110901060701 (Canadian-Upper Red)' },
  { value: '110901060702', regionId: '01', label: '110901060702 (Canadian-Upper Red)' },
  { value: '110901060703', regionId: '01', label: '110901060703 (Canadian-Upper Red)' },
  { value: '110901060704', regionId: '01', label: '110901060704 (Canadian-Upper Red)' },
  { value: '110901060705', regionId: '01', label: '110901060705 (Canadian-Upper Red)' },
  { value: '110901060801', regionId: '01', label: '110901060801 (Canadian-Upper Red)' },
  { value: '110901060802', regionId: '01', label: '110901060802 (Canadian-Upper Red)' },
  { value: '110901060803', regionId: '01', label: '110901060803 (Canadian-Upper Red)' },
  { value: '110901060804', regionId: '01', label: '110901060804 (Canadian-Upper Red)' },
  { value: '110901060806', regionId: '01', label: '110901060806 (Canadian-Upper Red)' },
  { value: '111001030101', regionId: '01', label: '111001030101 (Canadian-Upper Red)' },
  { value: '111001030104', regionId: '01', label: '111001030104 (Canadian-Upper Red)' },
  { value: '111001030105', regionId: '01', label: '111001030105 (Canadian-Upper Red)' },
  { value: '111001030107', regionId: '01', label: '111001030107 (Canadian-Upper Red)' },
  { value: '111001030407', regionId: '01', label: '111001030407 (Canadian-Upper Red)' },
  { value: '111001030408', regionId: '01', label: '111001030408 (Canadian-Upper Red)' },
  { value: '111001030409', regionId: '01', label: '111001030409 (Canadian-Upper Red)' },
  { value: '111001030510', regionId: '01', label: '111001030510 (Canadian-Upper Red)' },
  { value: '111001030304', regionId: '01', label: '111001030304 (Canadian-Upper Red)' },
  { value: '111001030305', regionId: '01', label: '111001030305 (Canadian-Upper Red)' },
  { value: '111001030306', regionId: '01', label: '111001030306 (Canadian-Upper Red)' },
  { value: '111001030108', regionId: '01', label: '111001030108 (Canadian-Upper Red)' },
  { value: '111001030103', regionId: '01', label: '111001030103 (Canadian-Upper Red)' },
  { value: '111001030106', regionId: '01', label: '111001030106 (Canadian-Upper Red)' },
  { value: '111001030207', regionId: '01', label: '111001030207 (Canadian-Upper Red)' },
  { value: '111001030201', regionId: '01', label: '111001030201 (Canadian-Upper Red)' },
  { value: '111001030202', regionId: '01', label: '111001030202 (Canadian-Upper Red)' },
  { value: '111001030203', regionId: '01', label: '111001030203 (Canadian-Upper Red)' },
  { value: '111001030204', regionId: '01', label: '111001030204 (Canadian-Upper Red)' },
  { value: '111001030205', regionId: '01', label: '111001030205 (Canadian-Upper Red)' },
  { value: '111001030206', regionId: '01', label: '111001030206 (Canadian-Upper Red)' },
  { value: '111001030208', regionId: '01', label: '111001030208 (Canadian-Upper Red)' },
  { value: '111001030209', regionId: '01', label: '111001030209 (Canadian-Upper Red)' },
  { value: '111001030301', regionId: '01', label: '111001030301 (Canadian-Upper Red)' },
  { value: '111001030402', regionId: '01', label: '111001030402 (Canadian-Upper Red)' },
  { value: '111001030403', regionId: '01', label: '111001030403 (Canadian-Upper Red)' },
  { value: '111001030404', regionId: '01', label: '111001030404 (Canadian-Upper Red)' },
  { value: '111001030302', regionId: '01', label: '111001030302 (Canadian-Upper Red)' },
  { value: '111001030303', regionId: '01', label: '111001030303 (Canadian-Upper Red)' },
  { value: '111001030307', regionId: '01', label: '111001030307 (Canadian-Upper Red)' },
  { value: '111001030308', regionId: '01', label: '111001030308 (Canadian-Upper Red)' },
  { value: '111001030309', regionId: '01', label: '111001030309 (Canadian-Upper Red)' },
  { value: '111001030401', regionId: '01', label: '111001030401 (Canadian-Upper Red)' },
  { value: '111001030405', regionId: '01', label: '111001030405 (Canadian-Upper Red)' },
  { value: '111001030406', regionId: '01', label: '111001030406 (Canadian-Upper Red)' },
  { value: '111001030501', regionId: '01', label: '111001030501 (Canadian-Upper Red)' },
  { value: '111001030502', regionId: '01', label: '111001030502 (Canadian-Upper Red)' },
  { value: '111001030503', regionId: '01', label: '111001030503 (Canadian-Upper Red)' },
  { value: '111001030504', regionId: '01', label: '111001030504 (Canadian-Upper Red)' },
  { value: '111001030505', regionId: '01', label: '111001030505 (Canadian-Upper Red)' },
  { value: '111001030506', regionId: '01', label: '111001030506 (Canadian-Upper Red)' },
  { value: '111001030507', regionId: '01', label: '111001030507 (Canadian-Upper Red)' },
  { value: '111001030508', regionId: '01', label: '111001030508 (Canadian-Upper Red)' },
  { value: '111001030509', regionId: '01', label: '111001030509 (Canadian-Upper Red)' },
  { value: '111001040403', regionId: '01', label: '111001040403 (Canadian-Upper Red)' },
  { value: '111001040404', regionId: '01', label: '111001040404 (Canadian-Upper Red)' },
  { value: '111001040405', regionId: '01', label: '111001040405 (Canadian-Upper Red)' },
  { value: '111001040504', regionId: '01', label: '111001040504 (Canadian-Upper Red)' },
  { value: '111001040505', regionId: '01', label: '111001040505 (Canadian-Upper Red)' },
  { value: '111001040506', regionId: '01', label: '111001040506 (Canadian-Upper Red)' },
  { value: '111001040101', regionId: '01', label: '111001040101 (Canadian-Upper Red)' },
  { value: '111001040102', regionId: '01', label: '111001040102 (Canadian-Upper Red)' },
  { value: '111001040103', regionId: '01', label: '111001040103 (Canadian-Upper Red)' },
  { value: '111001040104', regionId: '01', label: '111001040104 (Canadian-Upper Red)' },
  { value: '111001040105', regionId: '01', label: '111001040105 (Canadian-Upper Red)' },
  { value: '111001040106', regionId: '01', label: '111001040106 (Canadian-Upper Red)' },
  { value: '111001040107', regionId: '01', label: '111001040107 (Canadian-Upper Red)' },
  { value: '111001040108', regionId: '01', label: '111001040108 (Canadian-Upper Red)' },
  { value: '111001040109', regionId: '01', label: '111001040109 (Canadian-Upper Red)' },
  { value: '111001040201', regionId: '01', label: '111001040201 (Canadian-Upper Red)' },
  { value: '111001040202', regionId: '01', label: '111001040202 (Canadian-Upper Red)' },
  { value: '111001040203', regionId: '01', label: '111001040203 (Canadian-Upper Red)' },
  { value: '111001040204', regionId: '01', label: '111001040204 (Canadian-Upper Red)' },
  { value: '111001040205', regionId: '01', label: '111001040205 (Canadian-Upper Red)' },
  { value: '111001040206', regionId: '01', label: '111001040206 (Canadian-Upper Red)' },
  { value: '111001040207', regionId: '01', label: '111001040207 (Canadian-Upper Red)' },
  { value: '111001040208', regionId: '01', label: '111001040208 (Canadian-Upper Red)' },
  { value: '111001040209', regionId: '01', label: '111001040209 (Canadian-Upper Red)' },
  { value: '111001040210', regionId: '01', label: '111001040210 (Canadian-Upper Red)' },
  { value: '111001040211', regionId: '01', label: '111001040211 (Canadian-Upper Red)' },
  { value: '111001040212', regionId: '01', label: '111001040212 (Canadian-Upper Red)' },
  { value: '111001040213', regionId: '01', label: '111001040213 (Canadian-Upper Red)' },
  { value: '111001040214', regionId: '01', label: '111001040214 (Canadian-Upper Red)' },
  { value: '111001040215', regionId: '01', label: '111001040215 (Canadian-Upper Red)' },
  { value: '111001040301', regionId: '01', label: '111001040301 (Canadian-Upper Red)' },
  { value: '111001040312', regionId: '01', label: '111001040312 (Canadian-Upper Red)' },
  { value: '111001040302', regionId: '01', label: '111001040302 (Canadian-Upper Red)' },
  { value: '111001040303', regionId: '01', label: '111001040303 (Canadian-Upper Red)' },
  { value: '111001040304', regionId: '01', label: '111001040304 (Canadian-Upper Red)' },
  { value: '111001040305', regionId: '01', label: '111001040305 (Canadian-Upper Red)' },
  { value: '111001040306', regionId: '01', label: '111001040306 (Canadian-Upper Red)' },
  { value: '111001040307', regionId: '01', label: '111001040307 (Canadian-Upper Red)' },
  { value: '111001040308', regionId: '01', label: '111001040308 (Canadian-Upper Red)' },
  { value: '111001040309', regionId: '01', label: '111001040309 (Canadian-Upper Red)' },
  { value: '111001040310', regionId: '01', label: '111001040310 (Canadian-Upper Red)' },
  { value: '111001040311', regionId: '01', label: '111001040311 (Canadian-Upper Red)' },
  { value: '111001040313', regionId: '01', label: '111001040313 (Canadian-Upper Red)' },
  { value: '111001040501', regionId: '01', label: '111001040501 (Canadian-Upper Red)' },
  { value: '111001040314', regionId: '01', label: '111001040314 (Canadian-Upper Red)' },
  { value: '111001040315', regionId: '01', label: '111001040315 (Canadian-Upper Red)' },
  { value: '111001040401', regionId: '01', label: '111001040401 (Canadian-Upper Red)' },
  { value: '111001040402', regionId: '01', label: '111001040402 (Canadian-Upper Red)' },
  { value: '111001040502', regionId: '01', label: '111001040502 (Canadian-Upper Red)' },
  { value: '111001040503', regionId: '01', label: '111001040503 (Canadian-Upper Red)' },
  { value: '111002020111', regionId: '01', label: '111002020111 (Canadian-Upper Red)' },
  { value: '111002020201', regionId: '01', label: '111002020201 (Canadian-Upper Red)' },
  { value: '111002020202', regionId: '01', label: '111002020202 (Canadian-Upper Red)' },
  { value: '111002020203', regionId: '01', label: '111002020203 (Canadian-Upper Red)' },
  { value: '111002020204', regionId: '01', label: '111002020204 (Canadian-Upper Red)' },
  { value: '111002020205', regionId: '01', label: '111002020205 (Canadian-Upper Red)' },
  { value: '111002020206', regionId: '01', label: '111002020206 (Canadian-Upper Red)' },
  { value: '111002020301', regionId: '01', label: '111002020301 (Canadian-Upper Red)' },
  { value: '111002020302', regionId: '01', label: '111002020302 (Canadian-Upper Red)' },
  { value: '111002020303', regionId: '01', label: '111002020303 (Canadian-Upper Red)' },
  { value: '111002020304', regionId: '01', label: '111002020304 (Canadian-Upper Red)' },
  { value: '111002020305', regionId: '01', label: '111002020305 (Canadian-Upper Red)' },
  { value: '111002020306', regionId: '01', label: '111002020306 (Canadian-Upper Red)' },
  { value: '111002020101', regionId: '01', label: '111002020101 (Canadian-Upper Red)' },
  { value: '111002020102', regionId: '01', label: '111002020102 (Canadian-Upper Red)' },
  { value: '111002020103', regionId: '01', label: '111002020103 (Canadian-Upper Red)' },
  { value: '111002020104', regionId: '01', label: '111002020104 (Canadian-Upper Red)' },
  { value: '111002020105', regionId: '01', label: '111002020105 (Canadian-Upper Red)' },
  { value: '111002020108', regionId: '01', label: '111002020108 (Canadian-Upper Red)' },
  { value: '111002020106', regionId: '01', label: '111002020106 (Canadian-Upper Red)' },
  { value: '111002020107', regionId: '01', label: '111002020107 (Canadian-Upper Red)' },
  { value: '111002020109', regionId: '01', label: '111002020109 (Canadian-Upper Red)' },
  { value: '111002020110', regionId: '01', label: '111002020110 (Canadian-Upper Red)' },
  { value: '111201030102', regionId: '01', label: '111201030102 (Canadian-Upper Red)' },
  { value: '111201030101', regionId: '01', label: '111201030101 (Canadian-Upper Red)' },
  { value: '111201030302', regionId: '01', label: '111201030302 (Canadian-Upper Red)' },
  { value: '111201030104', regionId: '01', label: '111201030104 (Canadian-Upper Red)' },
  { value: '111201030107', regionId: '01', label: '111201030107 (Canadian-Upper Red)' },
  { value: '111201030202', regionId: '01', label: '111201030202 (Canadian-Upper Red)' },
  { value: '111201030203', regionId: '01', label: '111201030203 (Canadian-Upper Red)' },
  { value: '111201030103', regionId: '01', label: '111201030103 (Canadian-Upper Red)' },
  { value: '111201030105', regionId: '01', label: '111201030105 (Canadian-Upper Red)' },
  { value: '111201030106', regionId: '01', label: '111201030106 (Canadian-Upper Red)' },
  { value: '111201030108', regionId: '01', label: '111201030108 (Canadian-Upper Red)' },
  { value: '111201030109', regionId: '01', label: '111201030109 (Canadian-Upper Red)' },
  { value: '111201030110', regionId: '01', label: '111201030110 (Canadian-Upper Red)' },
  { value: '111201030111', regionId: '01', label: '111201030111 (Canadian-Upper Red)' },
  { value: '111201030112', regionId: '01', label: '111201030112 (Canadian-Upper Red)' },
  { value: '111201030113', regionId: '01', label: '111201030113 (Canadian-Upper Red)' },
  { value: '111201030201', regionId: '01', label: '111201030201 (Canadian-Upper Red)' },
  { value: '111201030204', regionId: '01', label: '111201030204 (Canadian-Upper Red)' },
  { value: '111201030205', regionId: '01', label: '111201030205 (Canadian-Upper Red)' },
  { value: '111201030206', regionId: '01', label: '111201030206 (Canadian-Upper Red)' },
  { value: '111201030207', regionId: '01', label: '111201030207 (Canadian-Upper Red)' },
  { value: '111201030301', regionId: '01', label: '111201030301 (Canadian-Upper Red)' },
  { value: '111201030303', regionId: '01', label: '111201030303 (Canadian-Upper Red)' },
  { value: '111201030304', regionId: '01', label: '111201030304 (Canadian-Upper Red)' },
  { value: '111201030305', regionId: '01', label: '111201030305 (Canadian-Upper Red)' },
  { value: '111201030306', regionId: '01', label: '111201030306 (Canadian-Upper Red)' },
  { value: '111201030401', regionId: '01', label: '111201030401 (Canadian-Upper Red)' },
  { value: '111201030402', regionId: '01', label: '111201030402 (Canadian-Upper Red)' },
  { value: '111201030403', regionId: '01', label: '111201030403 (Canadian-Upper Red)' },
  { value: '111201030502', regionId: '01', label: '111201030502 (Canadian-Upper Red)' },
  { value: '111201030503', regionId: '01', label: '111201030503 (Canadian-Upper Red)' },
  { value: '111201030504', regionId: '01', label: '111201030504 (Canadian-Upper Red)' },
  { value: '111201030505', regionId: '01', label: '111201030505 (Canadian-Upper Red)' },
  { value: '111201030601', regionId: '01', label: '111201030601 (Canadian-Upper Red)' },
  { value: '111201030602', regionId: '01', label: '111201030602 (Canadian-Upper Red)' },
  { value: '111201030603', regionId: '01', label: '111201030603 (Canadian-Upper Red)' },
  { value: '111201030604', regionId: '01', label: '111201030604 (Canadian-Upper Red)' },
  { value: '111201030605', regionId: '01', label: '111201030605 (Canadian-Upper Red)' },
  { value: '111201030606', regionId: '01', label: '111201030606 (Canadian-Upper Red)' },
  { value: '111201030607', regionId: '01', label: '111201030607 (Canadian-Upper Red)' },
  { value: '111201030404', regionId: '01', label: '111201030404 (Canadian-Upper Red)' },
  { value: '111201030405', regionId: '01', label: '111201030405 (Canadian-Upper Red)' },
  { value: '111201030406', regionId: '01', label: '111201030406 (Canadian-Upper Red)' },
  { value: '111201030407', regionId: '01', label: '111201030407 (Canadian-Upper Red)' },
  { value: '111201030408', regionId: '01', label: '111201030408 (Canadian-Upper Red)' },
  { value: '111201030501', regionId: '01', label: '111201030501 (Canadian-Upper Red)' },
  { value: '111201030506', regionId: '01', label: '111201030506 (Canadian-Upper Red)' },
  { value: '111201030507', regionId: '01', label: '111201030507 (Canadian-Upper Red)' },
  { value: '111201030608', regionId: '01', label: '111201030608 (Canadian-Upper Red)' },
  { value: '111201040103', regionId: '01', label: '111201040103 (Canadian-Upper Red)' },
  { value: '111201040104', regionId: '01', label: '111201040104 (Canadian-Upper Red)' },
  { value: '111201040105', regionId: '01', label: '111201040105 (Canadian-Upper Red)' },
  { value: '111201040201', regionId: '01', label: '111201040201 (Canadian-Upper Red)' },
  { value: '111201040202', regionId: '01', label: '111201040202 (Canadian-Upper Red)' },
  { value: '111201040203', regionId: '01', label: '111201040203 (Canadian-Upper Red)' },
  { value: '111201040206', regionId: '01', label: '111201040206 (Canadian-Upper Red)' },
  { value: '111201040301', regionId: '01', label: '111201040301 (Canadian-Upper Red)' },
  { value: '111201040302', regionId: '01', label: '111201040302 (Canadian-Upper Red)' },
  { value: '111201040303', regionId: '01', label: '111201040303 (Canadian-Upper Red)' },
  { value: '111201040306', regionId: '01', label: '111201040306 (Canadian-Upper Red)' },
  { value: '111201040401', regionId: '01', label: '111201040401 (Canadian-Upper Red)' },
  { value: '111201040102', regionId: '01', label: '111201040102 (Canadian-Upper Red)' },
  { value: '111201040101', regionId: '01', label: '111201040101 (Canadian-Upper Red)' },
  { value: '111201040204', regionId: '01', label: '111201040204 (Canadian-Upper Red)' },
  { value: '111201040205', regionId: '01', label: '111201040205 (Canadian-Upper Red)' },
  { value: '111201040304', regionId: '01', label: '111201040304 (Canadian-Upper Red)' },
  { value: '111201040305', regionId: '01', label: '111201040305 (Canadian-Upper Red)' },
  { value: '111201040404', regionId: '01', label: '111201040404 (Canadian-Upper Red)' },
  { value: '111201040503', regionId: '01', label: '111201040503 (Canadian-Upper Red)' },
  { value: '111201040602', regionId: '01', label: '111201040602 (Canadian-Upper Red)' },
  { value: '111201040603', regionId: '01', label: '111201040603 (Canadian-Upper Red)' },
  { value: '111201040604', regionId: '01', label: '111201040604 (Canadian-Upper Red)' },
  { value: '111201040402', regionId: '01', label: '111201040402 (Canadian-Upper Red)' },
  { value: '111201040403', regionId: '01', label: '111201040403 (Canadian-Upper Red)' },
  { value: '111201040501', regionId: '01', label: '111201040501 (Canadian-Upper Red)' },
  { value: '111201040502', regionId: '01', label: '111201040502 (Canadian-Upper Red)' },
  { value: '111201040504', regionId: '01', label: '111201040504 (Canadian-Upper Red)' },
  { value: '111201040601', regionId: '01', label: '111201040601 (Canadian-Upper Red)' },
  { value: '111201040605', regionId: '01', label: '111201040605 (Canadian-Upper Red)' },
  { value: '111201040606', regionId: '01', label: '111201040606 (Canadian-Upper Red)' },
  { value: '111201040607', regionId: '01', label: '111201040607 (Canadian-Upper Red)' },
  { value: '111202010202', regionId: '01', label: '111202010202 (Canadian-Upper Red)' },
  { value: '111202010203', regionId: '01', label: '111202010203 (Canadian-Upper Red)' },
  { value: '111202010209', regionId: '01', label: '111202010209 (Canadian-Upper Red)' },
  { value: '111202010210', regionId: '01', label: '111202010210 (Canadian-Upper Red)' },
  { value: '111202010211', regionId: '01', label: '111202010211 (Canadian-Upper Red)' },
  { value: '111202010101', regionId: '01', label: '111202010101 (Canadian-Upper Red)' },
  { value: '111202010102', regionId: '01', label: '111202010102 (Canadian-Upper Red)' },
  { value: '111202010103', regionId: '01', label: '111202010103 (Canadian-Upper Red)' },
  { value: '111202010104', regionId: '01', label: '111202010104 (Canadian-Upper Red)' },
  { value: '111202010105', regionId: '01', label: '111202010105 (Canadian-Upper Red)' },
  { value: '111202010106', regionId: '01', label: '111202010106 (Canadian-Upper Red)' },
  { value: '111202010107', regionId: '01', label: '111202010107 (Canadian-Upper Red)' },
  { value: '111202010108', regionId: '01', label: '111202010108 (Canadian-Upper Red)' },
  { value: '111202010201', regionId: '01', label: '111202010201 (Canadian-Upper Red)' },
  { value: '111202010204', regionId: '01', label: '111202010204 (Canadian-Upper Red)' },
  { value: '111202010205', regionId: '01', label: '111202010205 (Canadian-Upper Red)' },
  { value: '111202010206', regionId: '01', label: '111202010206 (Canadian-Upper Red)' },
  { value: '111202010207', regionId: '01', label: '111202010207 (Canadian-Upper Red)' },
  { value: '111202010208', regionId: '01', label: '111202010208 (Canadian-Upper Red)' },
  { value: '111203010103', regionId: '01', label: '111203010103 (Canadian-Upper Red)' },
  { value: '111203010104', regionId: '01', label: '111203010104 (Canadian-Upper Red)' },
  { value: '111203010105', regionId: '01', label: '111203010105 (Canadian-Upper Red)' },
  { value: '111203010106', regionId: '01', label: '111203010106 (Canadian-Upper Red)' },
  { value: '111203010107', regionId: '01', label: '111203010107 (Canadian-Upper Red)' },
  { value: '111203010108', regionId: '01', label: '111203010108 (Canadian-Upper Red)' },
  { value: '111203010201', regionId: '01', label: '111203010201 (Canadian-Upper Red)' },
  { value: '111203010202', regionId: '01', label: '111203010202 (Canadian-Upper Red)' },
  { value: '111203010203', regionId: '01', label: '111203010203 (Canadian-Upper Red)' },
  { value: '111203010204', regionId: '01', label: '111203010204 (Canadian-Upper Red)' },
  { value: '111203010101', regionId: '01', label: '111203010101 (Canadian-Upper Red)' },
  { value: '111203010102', regionId: '01', label: '111203010102 (Canadian-Upper Red)' },
  { value: '111203010205', regionId: '01', label: '111203010205 (Canadian-Upper Red)' },
  { value: '111203010206', regionId: '01', label: '111203010206 (Canadian-Upper Red)' },
  { value: '111203010207', regionId: '01', label: '111203010207 (Canadian-Upper Red)' },
  { value: '111203010208', regionId: '01', label: '111203010208 (Canadian-Upper Red)' },
  { value: '111203010302', regionId: '01', label: '111203010302 (Canadian-Upper Red)' },
  { value: '111203010303', regionId: '01', label: '111203010303 (Canadian-Upper Red)' },
  { value: '111203010307', regionId: '01', label: '111203010307 (Canadian-Upper Red)' },
  { value: '111203010301', regionId: '01', label: '111203010301 (Canadian-Upper Red)' },
  { value: '111203010304', regionId: '01', label: '111203010304 (Canadian-Upper Red)' },
  { value: '111203010305', regionId: '01', label: '111203010305 (Canadian-Upper Red)' },
  { value: '111203010306', regionId: '01', label: '111203010306 (Canadian-Upper Red)' },
  { value: '111203010308', regionId: '01', label: '111203010308 (Canadian-Upper Red)' },
  { value: '111203010309', regionId: '01', label: '111203010309 (Canadian-Upper Red)' },
  { value: '111203010310', regionId: '01', label: '111203010310 (Canadian-Upper Red)' },
  { value: '111301030202', regionId: '01', label: '111301030202 (Canadian-Upper Red)' },
  { value: '111301030203', regionId: '01', label: '111301030203 (Canadian-Upper Red)' },
  { value: '111301030204', regionId: '01', label: '111301030204 (Canadian-Upper Red)' },
  { value: '111301030205', regionId: '01', label: '111301030205 (Canadian-Upper Red)' },
  { value: '111301030209', regionId: '01', label: '111301030209 (Canadian-Upper Red)' },
  { value: '111301030102', regionId: '01', label: '111301030102 (Canadian-Upper Red)' },
  { value: '111301030103', regionId: '01', label: '111301030103 (Canadian-Upper Red)' },
  { value: '111301030201', regionId: '01', label: '111301030201 (Canadian-Upper Red)' },
  { value: '111301030101', regionId: '01', label: '111301030101 (Canadian-Upper Red)' },
  { value: '111301030104', regionId: '01', label: '111301030104 (Canadian-Upper Red)' },
  { value: '111301030105', regionId: '01', label: '111301030105 (Canadian-Upper Red)' },
  { value: '111301030106', regionId: '01', label: '111301030106 (Canadian-Upper Red)' },
  { value: '111301030206', regionId: '01', label: '111301030206 (Canadian-Upper Red)' },
  { value: '111301030207', regionId: '01', label: '111301030207 (Canadian-Upper Red)' },
  { value: '111301030208', regionId: '01', label: '111301030208 (Canadian-Upper Red)' },
  { value: '111301030301', regionId: '01', label: '111301030301 (Canadian-Upper Red)' },
  { value: '111301030302', regionId: '01', label: '111301030302 (Canadian-Upper Red)' },
  { value: '111301030303', regionId: '01', label: '111301030303 (Canadian-Upper Red)' },
  { value: '111301030304', regionId: '01', label: '111301030304 (Canadian-Upper Red)' },
  { value: '111301030305', regionId: '01', label: '111301030305 (Canadian-Upper Red)' },
  { value: '111301030306', regionId: '01', label: '111301030306 (Canadian-Upper Red)' },
  { value: '111301030307', regionId: '01', label: '111301030307 (Canadian-Upper Red)' },
  { value: '111301030308', regionId: '01', label: '111301030308 (Canadian-Upper Red)' },
  { value: '111301030309', regionId: '01', label: '111301030309 (Canadian-Upper Red)' },
  { value: '111301030310', regionId: '01', label: '111301030310 (Canadian-Upper Red)' },
  { value: '111301030401', regionId: '01', label: '111301030401 (Canadian-Upper Red)' },
  { value: '111301030402', regionId: '01', label: '111301030402 (Canadian-Upper Red)' },
  { value: '111301030403', regionId: '01', label: '111301030403 (Canadian-Upper Red)' },
  { value: '111301030404', regionId: '01', label: '111301030404 (Canadian-Upper Red)' },
  { value: '111301030405', regionId: '01', label: '111301030405 (Canadian-Upper Red)' },
  { value: '111301030406', regionId: '01', label: '111301030406 (Canadian-Upper Red)' },
  { value: '111301030407', regionId: '01', label: '111301030407 (Canadian-Upper Red)' },
  { value: '111301030408', regionId: '01', label: '111301030408 (Canadian-Upper Red)' },
  { value: '111301030501', regionId: '01', label: '111301030501 (Canadian-Upper Red)' },
  { value: '111301030502', regionId: '01', label: '111301030502 (Canadian-Upper Red)' },
  { value: '111301030503', regionId: '01', label: '111301030503 (Canadian-Upper Red)' },
  { value: '111301030504', regionId: '01', label: '111301030504 (Canadian-Upper Red)' },
  { value: '111301030505', regionId: '01', label: '111301030505 (Canadian-Upper Red)' },
  { value: '111301040201', regionId: '01', label: '111301040201 (Canadian-Upper Red)' },
  { value: '111301040102', regionId: '01', label: '111301040102 (Canadian-Upper Red)' },
  { value: '111301040103', regionId: '01', label: '111301040103 (Canadian-Upper Red)' },
  { value: '111301040104', regionId: '01', label: '111301040104 (Canadian-Upper Red)' },
  { value: '111301040101', regionId: '01', label: '111301040101 (Canadian-Upper Red)' },
  { value: '111301040105', regionId: '01', label: '111301040105 (Canadian-Upper Red)' },
  { value: '111301040106', regionId: '01', label: '111301040106 (Canadian-Upper Red)' },
  { value: '111301040202', regionId: '01', label: '111301040202 (Canadian-Upper Red)' },
  { value: '111301040203', regionId: '01', label: '111301040203 (Canadian-Upper Red)' },
  { value: '111301040204', regionId: '01', label: '111301040204 (Canadian-Upper Red)' },
  { value: '111301040205', regionId: '01', label: '111301040205 (Canadian-Upper Red)' },
  { value: '111301040206', regionId: '01', label: '111301040206 (Canadian-Upper Red)' },
  { value: '111301040207', regionId: '01', label: '111301040207 (Canadian-Upper Red)' },
  { value: '111301040208', regionId: '01', label: '111301040208 (Canadian-Upper Red)' },
  { value: '111301040304', regionId: '01', label: '111301040304 (Canadian-Upper Red)' },
  { value: '111301040305', regionId: '01', label: '111301040305 (Canadian-Upper Red)' },
  { value: '111301040406', regionId: '01', label: '111301040406 (Canadian-Upper Red)' },
  { value: '111301040209', regionId: '01', label: '111301040209 (Canadian-Upper Red)' },
  { value: '111301040210', regionId: '01', label: '111301040210 (Canadian-Upper Red)' },
  { value: '111301040301', regionId: '01', label: '111301040301 (Canadian-Upper Red)' },
  { value: '111301040302', regionId: '01', label: '111301040302 (Canadian-Upper Red)' },
  { value: '111301040303', regionId: '01', label: '111301040303 (Canadian-Upper Red)' },
  { value: '111301040401', regionId: '01', label: '111301040401 (Canadian-Upper Red)' },
  { value: '111301040402', regionId: '01', label: '111301040402 (Canadian-Upper Red)' },
  { value: '111301040403', regionId: '01', label: '111301040403 (Canadian-Upper Red)' },
  { value: '111301040404', regionId: '01', label: '111301040404 (Canadian-Upper Red)' },
  { value: '111301040405', regionId: '01', label: '111301040405 (Canadian-Upper Red)' },
  { value: '111301040407', regionId: '01', label: '111301040407 (Canadian-Upper Red)' },
  { value: '111301040408', regionId: '01', label: '111301040408 (Canadian-Upper Red)' },
  { value: '111301040409', regionId: '01', label: '111301040409 (Canadian-Upper Red)' },
  { value: '111301040410', regionId: '01', label: '111301040410 (Canadian-Upper Red)' },
  { value: '111301040501', regionId: '01', label: '111301040501 (Canadian-Upper Red)' },
  { value: '111301040502', regionId: '01', label: '111301040502 (Canadian-Upper Red)' },
  { value: '111301040503', regionId: '01', label: '111301040503 (Canadian-Upper Red)' },
  { value: '111301050101', regionId: '01', label: '111301050101 (Canadian-Upper Red)' },
  { value: '111301050102', regionId: '01', label: '111301050102 (Canadian-Upper Red)' },
  { value: '111301050103', regionId: '01', label: '111301050103 (Canadian-Upper Red)' },
  { value: '111301050104', regionId: '01', label: '111301050104 (Canadian-Upper Red)' },
  { value: '111301050105', regionId: '01', label: '111301050105 (Canadian-Upper Red)' },
  { value: '111301050106', regionId: '01', label: '111301050106 (Canadian-Upper Red)' },
  { value: '111301050107', regionId: '01', label: '111301050107 (Canadian-Upper Red)' },
  { value: '111301050108', regionId: '01', label: '111301050108 (Canadian-Upper Red)' },
  { value: '111301050109', regionId: '01', label: '111301050109 (Canadian-Upper Red)' },
  { value: '111301050110', regionId: '01', label: '111301050110 (Canadian-Upper Red)' },
  { value: '111301050111', regionId: '01', label: '111301050111 (Canadian-Upper Red)' },
  { value: '111301050203', regionId: '01', label: '111301050203 (Canadian-Upper Red)' },
  { value: '111301050207', regionId: '01', label: '111301050207 (Canadian-Upper Red)' },
  { value: '111301050208', regionId: '01', label: '111301050208 (Canadian-Upper Red)' },
  { value: '111301050201', regionId: '01', label: '111301050201 (Canadian-Upper Red)' },
  { value: '111301050202', regionId: '01', label: '111301050202 (Canadian-Upper Red)' },
  { value: '111301050204', regionId: '01', label: '111301050204 (Canadian-Upper Red)' },
  { value: '111301050205', regionId: '01', label: '111301050205 (Canadian-Upper Red)' },
  { value: '111301050206', regionId: '01', label: '111301050206 (Canadian-Upper Red)' },
  { value: '111302040101', regionId: '01', label: '111302040101 (Canadian-Upper Red)' },
  { value: '111302040102', regionId: '01', label: '111302040102 (Canadian-Upper Red)' },
  { value: '111302040206', regionId: '01', label: '111302040206 (Canadian-Upper Red)' },
  { value: '111302040207', regionId: '01', label: '111302040207 (Canadian-Upper Red)' },
  { value: '111302040208', regionId: '01', label: '111302040208 (Canadian-Upper Red)' },
  { value: '111302040103', regionId: '01', label: '111302040103 (Canadian-Upper Red)' },
  { value: '111302040104', regionId: '01', label: '111302040104 (Canadian-Upper Red)' },
  { value: '111302040105', regionId: '01', label: '111302040105 (Canadian-Upper Red)' },
  { value: '111302040106', regionId: '01', label: '111302040106 (Canadian-Upper Red)' },
  { value: '111302040107', regionId: '01', label: '111302040107 (Canadian-Upper Red)' },
  { value: '111302040108', regionId: '01', label: '111302040108 (Canadian-Upper Red)' },
  { value: '111302040201', regionId: '01', label: '111302040201 (Canadian-Upper Red)' },
  { value: '111302040202', regionId: '01', label: '111302040202 (Canadian-Upper Red)' },
  { value: '111302040203', regionId: '01', label: '111302040203 (Canadian-Upper Red)' },
  { value: '111302040204', regionId: '01', label: '111302040204 (Canadian-Upper Red)' },
  { value: '111302040205', regionId: '01', label: '111302040205 (Canadian-Upper Red)' },
  { value: '111302040303', regionId: '01', label: '111302040303 (Canadian-Upper Red)' },
  { value: '111302040209', regionId: '01', label: '111302040209 (Canadian-Upper Red)' },
  { value: '111302040210', regionId: '01', label: '111302040210 (Canadian-Upper Red)' },
  { value: '111302040301', regionId: '01', label: '111302040301 (Canadian-Upper Red)' },
  { value: '111302040302', regionId: '01', label: '111302040302 (Canadian-Upper Red)' },
  { value: '111302040304', regionId: '01', label: '111302040304 (Canadian-Upper Red)' },
  { value: '111302040305', regionId: '01', label: '111302040305 (Canadian-Upper Red)' },
  { value: '111302040306', regionId: '01', label: '111302040306 (Canadian-Upper Red)' },
  { value: '111302050105', regionId: '01', label: '111302050105 (Canadian-Upper Red)' },
  { value: '111302050101', regionId: '01', label: '111302050101 (Canadian-Upper Red)' },
  { value: '111302050102', regionId: '01', label: '111302050102 (Canadian-Upper Red)' },
  { value: '111302050103', regionId: '01', label: '111302050103 (Canadian-Upper Red)' },
  { value: '111302050104', regionId: '01', label: '111302050104 (Canadian-Upper Red)' },
  { value: '111302050207', regionId: '01', label: '111302050207 (Canadian-Upper Red)' },
  { value: '111302050208', regionId: '01', label: '111302050208 (Canadian-Upper Red)' },
  { value: '111302050106', regionId: '01', label: '111302050106 (Canadian-Upper Red)' },
  { value: '111302050107', regionId: '01', label: '111302050107 (Canadian-Upper Red)' },
  { value: '111302050201', regionId: '01', label: '111302050201 (Canadian-Upper Red)' },
  { value: '111302050202', regionId: '01', label: '111302050202 (Canadian-Upper Red)' },
  { value: '111302050203', regionId: '01', label: '111302050203 (Canadian-Upper Red)' },
  { value: '111302050204', regionId: '01', label: '111302050204 (Canadian-Upper Red)' },
  { value: '111302050205', regionId: '01', label: '111302050205 (Canadian-Upper Red)' },
  { value: '111302050206', regionId: '01', label: '111302050206 (Canadian-Upper Red)' },
  { value: '111302060501', regionId: '01', label: '111302060501 (Canadian-Upper Red)' },
  { value: '111302060502', regionId: '01', label: '111302060502 (Canadian-Upper Red)' },
  { value: '111302060503', regionId: '01', label: '111302060503 (Canadian-Upper Red)' },
  { value: '111302060101', regionId: '01', label: '111302060101 (Canadian-Upper Red)' },
  { value: '111302060102', regionId: '01', label: '111302060102 (Canadian-Upper Red)' },
  { value: '111302060103', regionId: '01', label: '111302060103 (Canadian-Upper Red)' },
  { value: '111302060108', regionId: '01', label: '111302060108 (Canadian-Upper Red)' },
  { value: '111302060201', regionId: '01', label: '111302060201 (Canadian-Upper Red)' },
  { value: '111302060202', regionId: '01', label: '111302060202 (Canadian-Upper Red)' },
  { value: '111302060301', regionId: '01', label: '111302060301 (Canadian-Upper Red)' },
  { value: '111302060104', regionId: '01', label: '111302060104 (Canadian-Upper Red)' },
  { value: '111302060105', regionId: '01', label: '111302060105 (Canadian-Upper Red)' },
  { value: '111302060106', regionId: '01', label: '111302060106 (Canadian-Upper Red)' },
  { value: '111302060107', regionId: '01', label: '111302060107 (Canadian-Upper Red)' },
  { value: '111302060203', regionId: '01', label: '111302060203 (Canadian-Upper Red)' },
  { value: '111302060204', regionId: '01', label: '111302060204 (Canadian-Upper Red)' },
  { value: '111302060302', regionId: '01', label: '111302060302 (Canadian-Upper Red)' },
  { value: '111302060303', regionId: '01', label: '111302060303 (Canadian-Upper Red)' },
  { value: '111302060304', regionId: '01', label: '111302060304 (Canadian-Upper Red)' },
  { value: '111302060401', regionId: '01', label: '111302060401 (Canadian-Upper Red)' },
  { value: '111302060402', regionId: '01', label: '111302060402 (Canadian-Upper Red)' },
  { value: '111302060403', regionId: '01', label: '111302060403 (Canadian-Upper Red)' },
  { value: '111302060404', regionId: '01', label: '111302060404 (Canadian-Upper Red)' },
  { value: '111302060405', regionId: '01', label: '111302060405 (Canadian-Upper Red)' },
  { value: '111302060406', regionId: '01', label: '111302060406 (Canadian-Upper Red)' },
  { value: '111302060407', regionId: '01', label: '111302060407 (Canadian-Upper Red)' },
  { value: '111302070103', regionId: '01', label: '111302070103 (Canadian-Upper Red)' },
  { value: '111302070201', regionId: '01', label: '111302070201 (Canadian-Upper Red)' },
  { value: '111302070101', regionId: '01', label: '111302070101 (Canadian-Upper Red)' },
  { value: '111302070102', regionId: '01', label: '111302070102 (Canadian-Upper Red)' },
  { value: '111302070202', regionId: '01', label: '111302070202 (Canadian-Upper Red)' },
  { value: '111302070203', regionId: '01', label: '111302070203 (Canadian-Upper Red)' },
  { value: '111302070204', regionId: '01', label: '111302070204 (Canadian-Upper Red)' },
  { value: '111302070301', regionId: '01', label: '111302070301 (Canadian-Upper Red)' },
  { value: '111302070302', regionId: '01', label: '111302070302 (Canadian-Upper Red)' },
  { value: '111302070303', regionId: '01', label: '111302070303 (Canadian-Upper Red)' },
  { value: '111302070304', regionId: '01', label: '111302070304 (Canadian-Upper Red)' },
  { value: '111302070305', regionId: '01', label: '111302070305 (Canadian-Upper Red)' },
  { value: '111302070306', regionId: '01', label: '111302070306 (Canadian-Upper Red)' },
  { value: '111302070307', regionId: '01', label: '111302070307 (Canadian-Upper Red)' },
  { value: '111302070401', regionId: '01', label: '111302070401 (Canadian-Upper Red)' },
  { value: '111302070402', regionId: '01', label: '111302070402 (Canadian-Upper Red)' },
  { value: '111302070403', regionId: '01', label: '111302070403 (Canadian-Upper Red)' },
  { value: '111302090101', regionId: '01', label: '111302090101 (Canadian-Upper Red)' },
  { value: '111302090105', regionId: '01', label: '111302090105 (Canadian-Upper Red)' },
  { value: '111302090106', regionId: '01', label: '111302090106 (Canadian-Upper Red)' },
  { value: '111302090107', regionId: '01', label: '111302090107 (Canadian-Upper Red)' },
  { value: '111302090102', regionId: '01', label: '111302090102 (Canadian-Upper Red)' },
  { value: '111302090103', regionId: '01', label: '111302090103 (Canadian-Upper Red)' },
  { value: '111302090104', regionId: '01', label: '111302090104 (Canadian-Upper Red)' },
  { value: '111302090201', regionId: '01', label: '111302090201 (Canadian-Upper Red)' },
  { value: '111302090202', regionId: '01', label: '111302090202 (Canadian-Upper Red)' },
  { value: '111302090203', regionId: '01', label: '111302090203 (Canadian-Upper Red)' },
  { value: '111302090204', regionId: '01', label: '111302090204 (Canadian-Upper Red)' },
  { value: '111302090205', regionId: '01', label: '111302090205 (Canadian-Upper Red)' },
  { value: '111302090206', regionId: '01', label: '111302090206 (Canadian-Upper Red)' },
  { value: '111302090207', regionId: '01', label: '111302090207 (Canadian-Upper Red)' },
  { value: '111302090208', regionId: '01', label: '111302090208 (Canadian-Upper Red)' },
  { value: '111302090301', regionId: '01', label: '111302090301 (Canadian-Upper Red)' },
  { value: '111302090302', regionId: '01', label: '111302090302 (Canadian-Upper Red)' },
  { value: '111302090303', regionId: '01', label: '111302090303 (Canadian-Upper Red)' },
  { value: '111302090304', regionId: '01', label: '111302090304 (Canadian-Upper Red)' },
  { value: '111302090401', regionId: '01', label: '111302090401 (Canadian-Upper Red)' },
  { value: '111302090402', regionId: '01', label: '111302090402 (Canadian-Upper Red)' },
  { value: '111302090403', regionId: '01', label: '111302090403 (Canadian-Upper Red)' },
  { value: '111302090404', regionId: '01', label: '111302090404 (Canadian-Upper Red)' },
  { value: '111302090405', regionId: '01', label: '111302090405 (Canadian-Upper Red)' },
  { value: '111302090406', regionId: '01', label: '111302090406 (Canadian-Upper Red)' },
  { value: '111302090501', regionId: '01', label: '111302090501 (Canadian-Upper Red)' },
  { value: '111302090502', regionId: '01', label: '111302090502 (Canadian-Upper Red)' },
  { value: '111302090503', regionId: '01', label: '111302090503 (Canadian-Upper Red)' },
  { value: '111302090504', regionId: '01', label: '111302090504 (Canadian-Upper Red)' },
  { value: '111302090505', regionId: '01', label: '111302090505 (Canadian-Upper Red)' },
  { value: '111302090506', regionId: '01', label: '111302090506 (Canadian-Upper Red)' },
  { value: '111302090507', regionId: '01', label: '111302090507 (Canadian-Upper Red)' },
  { value: '111302090508', regionId: '01', label: '111302090508 (Canadian-Upper Red)' },
  { value: '111302090509', regionId: '01', label: '111302090509 (Canadian-Upper Red)' },
  { value: '111302090510', regionId: '01', label: '111302090510 (Canadian-Upper Red)' },
  { value: '120301010101', regionId: '01', label: '120301010101 (Canadian-Upper Red)' },
  { value: '120301010102', regionId: '01', label: '120301010102 (Canadian-Upper Red)' },
  { value: '120301010107', regionId: '01', label: '120301010107 (Canadian-Upper Red)' },
  { value: '120301010201', regionId: '01', label: '120301010201 (Canadian-Upper Red)' },
  { value: '120301010202', regionId: '01', label: '120301010202 (Canadian-Upper Red)' },
  { value: '120301010204', regionId: '01', label: '120301010204 (Canadian-Upper Red)' },
  { value: '120301010501', regionId: '01', label: '120301010501 (Canadian-Upper Red)' },
  { value: '120301010503', regionId: '01', label: '120301010503 (Canadian-Upper Red)' },
  { value: '120301010506', regionId: '01', label: '120301010506 (Canadian-Upper Red)' },
  { value: '120301030101', regionId: '01', label: '120301030101 (Canadian-Upper Red)' },
  { value: '120301030103', regionId: '01', label: '120301030103 (Canadian-Upper Red)' },
  { value: '120301030104', regionId: '01', label: '120301030104 (Canadian-Upper Red)' },
  { value: '120301030105', regionId: '01', label: '120301030105 (Canadian-Upper Red)' },
  { value: '120301030106', regionId: '01', label: '120301030106 (Canadian-Upper Red)' },
  { value: '120301030501', regionId: '01', label: '120301030501 (Canadian-Upper Red)' },
  { value: '120301040101', regionId: '01', label: '120301040101 (Canadian-Upper Red)' },
  { value: '120301040102', regionId: '01', label: '120301040102 (Canadian-Upper Red)' },
  { value: '120500060306', regionId: '01', label: '120500060306 (Canadian-Upper Red)' },
  { value: '120500060307', regionId: '01', label: '120500060307 (Canadian-Upper Red)' },
  { value: '120500070601', regionId: '01', label: '120500070601 (Canadian-Upper Red)' },
  { value: '120500070602', regionId: '01', label: '120500070602 (Canadian-Upper Red)' },
  { value: '120500070605', regionId: '01', label: '120500070605 (Canadian-Upper Red)' },
  { value: '120500070300', regionId: '01', label: '120500070300 (Canadian-Upper Red)' },
  { value: '120500070402', regionId: '01', label: '120500070402 (Canadian-Upper Red)' },
  { value: '120601010101', regionId: '01', label: '120601010101 (Canadian-Upper Red)' },
  { value: '120601010102', regionId: '01', label: '120601010102 (Canadian-Upper Red)' },
  { value: '120601010104', regionId: '01', label: '120601010104 (Canadian-Upper Red)' },
  { value: '120601010204', regionId: '01', label: '120601010204 (Canadian-Upper Red)' },
  { value: '120601010205', regionId: '01', label: '120601010205 (Canadian-Upper Red)' },
  { value: '120601010403', regionId: '01', label: '120601010403 (Canadian-Upper Red)' },
  { value: '120601010406', regionId: '01', label: '120601010406 (Canadian-Upper Red)' },
  { value: '120601010407', regionId: '01', label: '120601010407 (Canadian-Upper Red)' },
  { value: '120601010408', regionId: '01', label: '120601010408 (Canadian-Upper Red)' },
  { value: '120601010601', regionId: '01', label: '120601010601 (Canadian-Upper Red)' },
  { value: '120601010602', regionId: '01', label: '120601010602 (Canadian-Upper Red)' },
  { value: '120601010801', regionId: '01', label: '120601010801 (Canadian-Upper Red)' },
  { value: '120601010805', regionId: '01', label: '120601010805 (Canadian-Upper Red)' },
  { value: '120601010806', regionId: '01', label: '120601010806 (Canadian-Upper Red)' },
  { value: '120601010807', regionId: '01', label: '120601010807 (Canadian-Upper Red)' },
  { value: '120602010101', regionId: '01', label: '120602010101 (Canadian-Upper Red)' },
  { value: '110901020306', regionId: '01', label: '110901020306 (Canadian-Upper Red)' },
  { value: '110901020404', regionId: '01', label: '110901020404 (Canadian-Upper Red)' },
  { value: '110901020405', regionId: '01', label: '110901020405 (Canadian-Upper Red)' },
  { value: '110901020407', regionId: '01', label: '110901020407 (Canadian-Upper Red)' },
  { value: '110901020501', regionId: '01', label: '110901020501 (Canadian-Upper Red)' },
  { value: '110901020408', regionId: '01', label: '110901020408 (Canadian-Upper Red)' },
  { value: '110901020502', regionId: '01', label: '110901020502 (Canadian-Upper Red)' },
  { value: '110901020503', regionId: '01', label: '110901020503 (Canadian-Upper Red)' },
  { value: '110901020504', regionId: '01', label: '110901020504 (Canadian-Upper Red)' },
  { value: '110901020505', regionId: '01', label: '110901020505 (Canadian-Upper Red)' },
  { value: '110901020506', regionId: '01', label: '110901020506 (Canadian-Upper Red)' },
  { value: '110901020601', regionId: '01', label: '110901020601 (Canadian-Upper Red)' },
  { value: '110901020602', regionId: '01', label: '110901020602 (Canadian-Upper Red)' },
  { value: '110901020603', regionId: '01', label: '110901020603 (Canadian-Upper Red)' },
  { value: '110901020604', regionId: '01', label: '110901020604 (Canadian-Upper Red)' },
  { value: '110901020701', regionId: '01', label: '110901020701 (Canadian-Upper Red)' },
  { value: '110901020702', regionId: '01', label: '110901020702 (Canadian-Upper Red)' },
  { value: '110901020703', regionId: '01', label: '110901020703 (Canadian-Upper Red)' },
  { value: '110901030104', regionId: '01', label: '110901030104 (Canadian-Upper Red)' },
  { value: '110901030103', regionId: '01', label: '110901030103 (Canadian-Upper Red)' },
  { value: '110901030105', regionId: '01', label: '110901030105 (Canadian-Upper Red)' },
  { value: '110901030205', regionId: '01', label: '110901030205 (Canadian-Upper Red)' },
  { value: '110901030206', regionId: '01', label: '110901030206 (Canadian-Upper Red)' },
  { value: '110901030207', regionId: '01', label: '110901030207 (Canadian-Upper Red)' },
  { value: '110901030304', regionId: '01', label: '110901030304 (Canadian-Upper Red)' },
  { value: '110901030303', regionId: '01', label: '110901030303 (Canadian-Upper Red)' },
  { value: '110901030401', regionId: '01', label: '110901030401 (Canadian-Upper Red)' },
  { value: '110901030402', regionId: '01', label: '110901030402 (Canadian-Upper Red)' },
  { value: '110901030403', regionId: '01', label: '110901030403 (Canadian-Upper Red)' },
  { value: '110901030404', regionId: '01', label: '110901030404 (Canadian-Upper Red)' },
  { value: '110901030405', regionId: '01', label: '110901030405 (Canadian-Upper Red)' },
  { value: '110901030406', regionId: '01', label: '110901030406 (Canadian-Upper Red)' },
  { value: '110901030407', regionId: '01', label: '110901030407 (Canadian-Upper Red)' },
  { value: '110901030408', regionId: '01', label: '110901030408 (Canadian-Upper Red)' },
  { value: '110901030409', regionId: '01', label: '110901030409 (Canadian-Upper Red)' },
  { value: '110901030501', regionId: '01', label: '110901030501 (Canadian-Upper Red)' },
  { value: '110901030502', regionId: '01', label: '110901030502 (Canadian-Upper Red)' },
  { value: '110901030503', regionId: '01', label: '110901030503 (Canadian-Upper Red)' },
  { value: '110901030504', regionId: '01', label: '110901030504 (Canadian-Upper Red)' },
  { value: '110901030505', regionId: '01', label: '110901030505 (Canadian-Upper Red)' },
  { value: '110901030506', regionId: '01', label: '110901030506 (Canadian-Upper Red)' },
  { value: '111201010202', regionId: '01', label: '111201010202 (Canadian-Upper Red)' },
  { value: '111201010201', regionId: '01', label: '111201010201 (Canadian-Upper Red)' },
  { value: '111201010204', regionId: '01', label: '111201010204 (Canadian-Upper Red)' },
  { value: '111201010307', regionId: '01', label: '111201010307 (Canadian-Upper Red)' },
  { value: '111201010603', regionId: '01', label: '111201010603 (Canadian-Upper Red)' },
  { value: '111201010604', regionId: '01', label: '111201010604 (Canadian-Upper Red)' },
  { value: '111201010605', regionId: '01', label: '111201010605 (Canadian-Upper Red)' },
  { value: '111201010606', regionId: '01', label: '111201010606 (Canadian-Upper Red)' },
  { value: '111201010607', regionId: '01', label: '111201010607 (Canadian-Upper Red)' },
  { value: '111201010608', regionId: '01', label: '111201010608 (Canadian-Upper Red)' },
  { value: '111201010203', regionId: '01', label: '111201010203 (Canadian-Upper Red)' },
  { value: '111201010401', regionId: '01', label: '111201010401 (Canadian-Upper Red)' },
  { value: '111201010402', regionId: '01', label: '111201010402 (Canadian-Upper Red)' },
  { value: '111201010403', regionId: '01', label: '111201010403 (Canadian-Upper Red)' },
  { value: '111201010404', regionId: '01', label: '111201010404 (Canadian-Upper Red)' },
  { value: '111201010405', regionId: '01', label: '111201010405 (Canadian-Upper Red)' },
  { value: '111201010406', regionId: '01', label: '111201010406 (Canadian-Upper Red)' },
  { value: '111201010502', regionId: '01', label: '111201010502 (Canadian-Upper Red)' },
  { value: '111201010503', regionId: '01', label: '111201010503 (Canadian-Upper Red)' },
  { value: '111201010504', regionId: '01', label: '111201010504 (Canadian-Upper Red)' },
  { value: '111201010602', regionId: '01', label: '111201010602 (Canadian-Upper Red)' },
  { value: '111201010407', regionId: '01', label: '111201010407 (Canadian-Upper Red)' },
  { value: '111201010501', regionId: '01', label: '111201010501 (Canadian-Upper Red)' },
  { value: '111201010505', regionId: '01', label: '111201010505 (Canadian-Upper Red)' },
  { value: '111201010506', regionId: '01', label: '111201010506 (Canadian-Upper Red)' },
  { value: '111201010507', regionId: '01', label: '111201010507 (Canadian-Upper Red)' },
  { value: '111201010601', regionId: '01', label: '111201010601 (Canadian-Upper Red)' },
  { value: '111201010609', regionId: '01', label: '111201010609 (Canadian-Upper Red)' },
  { value: '120500050108', regionId: '01', label: '120500050108 (Canadian-Upper Red)' },
  { value: '120500050401', regionId: '01', label: '120500050401 (Canadian-Upper Red)' },
  { value: '120500050202', regionId: '01', label: '120500050202 (Canadian-Upper Red)' },
  { value: '120500050203', regionId: '01', label: '120500050203 (Canadian-Upper Red)' },
  { value: '120500050402', regionId: '01', label: '120500050402 (Canadian-Upper Red)' },
  { value: '120500050404', regionId: '01', label: '120500050404 (Canadian-Upper Red)' },
  { value: '120500050502', regionId: '01', label: '120500050502 (Canadian-Upper Red)' },
  { value: '120500050503', regionId: '01', label: '120500050503 (Canadian-Upper Red)' },
  { value: '120500050504', regionId: '01', label: '120500050504 (Canadian-Upper Red)' },
  { value: '120500050201', regionId: '01', label: '120500050201 (Canadian-Upper Red)' },
  { value: '120500050301', regionId: '01', label: '120500050301 (Canadian-Upper Red)' },
  { value: '120500050303', regionId: '01', label: '120500050303 (Canadian-Upper Red)' },
  { value: '120500050305', regionId: '01', label: '120500050305 (Canadian-Upper Red)' },
  { value: '120500050306', regionId: '01', label: '120500050306 (Canadian-Upper Red)' },
  { value: '111002010101', regionId: '01', label: '111002010101 (Canadian-Upper Red)' },
  { value: '111002010202', regionId: '01', label: '111002010202 (Canadian-Upper Red)' },
  { value: '111002010203', regionId: '01', label: '111002010203 (Canadian-Upper Red)' },
  { value: '111002010204', regionId: '01', label: '111002010204 (Canadian-Upper Red)' },
  { value: '111002010401', regionId: '01', label: '111002010401 (Canadian-Upper Red)' },
  { value: '111002010402', regionId: '01', label: '111002010402 (Canadian-Upper Red)' },
  { value: '111002010403', regionId: '01', label: '111002010403 (Canadian-Upper Red)' },
  { value: '111002010504', regionId: '01', label: '111002010504 (Canadian-Upper Red)' },
  { value: '111002010603', regionId: '01', label: '111002010603 (Canadian-Upper Red)' },
  { value: '111002010604', regionId: '01', label: '111002010604 (Canadian-Upper Red)' },
  { value: '111002010303', regionId: '01', label: '111002010303 (Canadian-Upper Red)' },
  { value: '111002010304', regionId: '01', label: '111002010304 (Canadian-Upper Red)' },
  { value: '111002010201', regionId: '01', label: '111002010201 (Canadian-Upper Red)' },
  { value: '111002010301', regionId: '01', label: '111002010301 (Canadian-Upper Red)' },
  { value: '111002010302', regionId: '01', label: '111002010302 (Canadian-Upper Red)' },
  { value: '111002010305', regionId: '01', label: '111002010305 (Canadian-Upper Red)' },
  { value: '111002010306', regionId: '01', label: '111002010306 (Canadian-Upper Red)' },
  { value: '111002030108', regionId: '01', label: '111002030108 (Canadian-Upper Red)' },
  { value: '111002030202', regionId: '01', label: '111002030202 (Canadian-Upper Red)' },
  { value: '111002030401', regionId: '01', label: '111002030401 (Canadian-Upper Red)' },
  { value: '111002030402', regionId: '01', label: '111002030402 (Canadian-Upper Red)' },
  { value: '111002030403', regionId: '01', label: '111002030403 (Canadian-Upper Red)' },
  { value: '111002030101', regionId: '01', label: '111002030101 (Canadian-Upper Red)' },
  { value: '111002030102', regionId: '01', label: '111002030102 (Canadian-Upper Red)' },
  { value: '111002030103', regionId: '01', label: '111002030103 (Canadian-Upper Red)' },
  { value: '111002030104', regionId: '01', label: '111002030104 (Canadian-Upper Red)' },
  { value: '111002030105', regionId: '01', label: '111002030105 (Canadian-Upper Red)' },
  { value: '111002030106', regionId: '01', label: '111002030106 (Canadian-Upper Red)' },
  { value: '111002030107', regionId: '01', label: '111002030107 (Canadian-Upper Red)' },
  { value: '111002030201', regionId: '01', label: '111002030201 (Canadian-Upper Red)' },
  { value: '111201050503', regionId: '01', label: '111201050503 (Canadian-Upper Red)' },
  { value: '111201050407', regionId: '01', label: '111201050407 (Canadian-Upper Red)' },
  { value: '111201050101', regionId: '01', label: '111201050101 (Canadian-Upper Red)' },
  { value: '111201050102', regionId: '01', label: '111201050102 (Canadian-Upper Red)' },
  { value: '111201050103', regionId: '01', label: '111201050103 (Canadian-Upper Red)' },
  { value: '111201050104', regionId: '01', label: '111201050104 (Canadian-Upper Red)' },
  { value: '111201050201', regionId: '01', label: '111201050201 (Canadian-Upper Red)' },
  { value: '111201050202', regionId: '01', label: '111201050202 (Canadian-Upper Red)' },
  { value: '111201050203', regionId: '01', label: '111201050203 (Canadian-Upper Red)' },
  { value: '111201050501', regionId: '01', label: '111201050501 (Canadian-Upper Red)' },
  { value: '111201050502', regionId: '01', label: '111201050502 (Canadian-Upper Red)' },
  { value: '111201050204', regionId: '01', label: '111201050204 (Canadian-Upper Red)' },
  { value: '111201050205', regionId: '01', label: '111201050205 (Canadian-Upper Red)' },
  { value: '111201050206', regionId: '01', label: '111201050206 (Canadian-Upper Red)' },
  { value: '111201050207', regionId: '01', label: '111201050207 (Canadian-Upper Red)' },
  { value: '111201050208', regionId: '01', label: '111201050208 (Canadian-Upper Red)' },
  { value: '111201050209', regionId: '01', label: '111201050209 (Canadian-Upper Red)' },
  { value: '111201050301', regionId: '01', label: '111201050301 (Canadian-Upper Red)' },
  { value: '111201050302', regionId: '01', label: '111201050302 (Canadian-Upper Red)' },
  { value: '111201050303', regionId: '01', label: '111201050303 (Canadian-Upper Red)' },
  { value: '111201050304', regionId: '01', label: '111201050304 (Canadian-Upper Red)' },
  { value: '111201050305', regionId: '01', label: '111201050305 (Canadian-Upper Red)' },
  { value: '111201050306', regionId: '01', label: '111201050306 (Canadian-Upper Red)' },
  { value: '111201050307', regionId: '01', label: '111201050307 (Canadian-Upper Red)' },
  { value: '111201050308', regionId: '01', label: '111201050308 (Canadian-Upper Red)' },
  { value: '111201050401', regionId: '01', label: '111201050401 (Canadian-Upper Red)' },
  { value: '111201050402', regionId: '01', label: '111201050402 (Canadian-Upper Red)' },
  { value: '111201050403', regionId: '01', label: '111201050403 (Canadian-Upper Red)' },
  { value: '111201050404', regionId: '01', label: '111201050404 (Canadian-Upper Red)' },
  { value: '111201050405', regionId: '01', label: '111201050405 (Canadian-Upper Red)' },
  { value: '111201050406', regionId: '01', label: '111201050406 (Canadian-Upper Red)' },
  { value: '111301010201', regionId: '01', label: '111301010201 (Canadian-Upper Red)' },
  { value: '111301010202', regionId: '01', label: '111301010202 (Canadian-Upper Red)' },
  { value: '111301010301', regionId: '01', label: '111301010301 (Canadian-Upper Red)' },
  { value: '111301010303', regionId: '01', label: '111301010303 (Canadian-Upper Red)' },
  { value: '111301010601', regionId: '01', label: '111301010601 (Canadian-Upper Red)' },
  { value: '111301010602', regionId: '01', label: '111301010602 (Canadian-Upper Red)' },
  { value: '111301010604', regionId: '01', label: '111301010604 (Canadian-Upper Red)' },
  { value: '111301010403', regionId: '01', label: '111301010403 (Canadian-Upper Red)' },
  { value: '111301010105', regionId: '01', label: '111301010105 (Canadian-Upper Red)' },
  { value: '111301010106', regionId: '01', label: '111301010106 (Canadian-Upper Red)' },
  { value: '111301010107', regionId: '01', label: '111301010107 (Canadian-Upper Red)' },
  { value: '111301010108', regionId: '01', label: '111301010108 (Canadian-Upper Red)' },
  { value: '111301010401', regionId: '01', label: '111301010401 (Canadian-Upper Red)' },
  { value: '111301010402', regionId: '01', label: '111301010402 (Canadian-Upper Red)' },
  { value: '111301010404', regionId: '01', label: '111301010404 (Canadian-Upper Red)' },
  { value: '111301010405', regionId: '01', label: '111301010405 (Canadian-Upper Red)' },
  { value: '111301010101', regionId: '01', label: '111301010101 (Canadian-Upper Red)' },
  { value: '111301010102', regionId: '01', label: '111301010102 (Canadian-Upper Red)' },
  { value: '111301010103', regionId: '01', label: '111301010103 (Canadian-Upper Red)' },
  { value: '111301010104', regionId: '01', label: '111301010104 (Canadian-Upper Red)' },
  { value: '111302100202', regionId: '01', label: '111302100202 (Canadian-Upper Red)' },
]

const hucsRegion11 = [
  { value: '120902040105', regionId: '11', label: '120902040105 (Guadalupe)' },
  { value: '120902040101', regionId: '11', label: '120902040101 (Guadalupe)' },
  { value: '120902040103', regionId: '11', label: '120902040103 (Guadalupe)' },
  { value: '120902040401', regionId: '11', label: '120902040401 (Guadalupe)' },
  { value: '120902050401', regionId: '11', label: '120902050401 (Guadalupe)' },
  { value: '120902050402', regionId: '11', label: '120902050402 (Guadalupe)' },
  { value: '120902050403', regionId: '11', label: '120902050403 (Guadalupe)' },
  { value: '120902050404', regionId: '11', label: '120902050404 (Guadalupe)' },
  { value: '120902050405', regionId: '11', label: '120902050405 (Guadalupe)' },
  { value: '120902050407', regionId: '11', label: '120902050407 (Guadalupe)' },
  { value: '120902060104', regionId: '11', label: '120902060104 (Guadalupe)' },
  { value: '120902060106', regionId: '11', label: '120902060106 (Guadalupe)' },
  { value: '120902060107', regionId: '11', label: '120902060107 (Guadalupe)' },
  { value: '120902060203', regionId: '11', label: '120902060203 (Guadalupe)' },
  { value: '120902060204', regionId: '11', label: '120902060204 (Guadalupe)' },
  { value: '120902060206', regionId: '11', label: '120902060206 (Guadalupe)' },
  { value: '120902060207', regionId: '11', label: '120902060207 (Guadalupe)' },
  { value: '120902060303', regionId: '11', label: '120902060303 (Guadalupe)' },
  { value: '120902060304', regionId: '11', label: '120902060304 (Guadalupe)' },
  { value: '120902060101', regionId: '11', label: '120902060101 (Guadalupe)' },
  { value: '120902060102', regionId: '11', label: '120902060102 (Guadalupe)' },
  { value: '120903010301', regionId: '11', label: '120903010301 (Guadalupe)' },
  { value: '120903010302', regionId: '11', label: '120903010302 (Guadalupe)' },
  { value: '120903010304', regionId: '11', label: '120903010304 (Guadalupe)' },
  { value: '120903010305', regionId: '11', label: '120903010305 (Guadalupe)' },
  { value: '120903010308', regionId: '11', label: '120903010308 (Guadalupe)' },
  { value: '120903010309', regionId: '11', label: '120903010309 (Guadalupe)' },
  { value: '120903010403', regionId: '11', label: '120903010403 (Guadalupe)' },
  { value: '120903010702', regionId: '11', label: '120903010702 (Guadalupe)' },
  { value: '120903010703', regionId: '11', label: '120903010703 (Guadalupe)' },
  { value: '120903010704', regionId: '11', label: '120903010704 (Guadalupe)' },
  { value: '121001020101', regionId: '11', label: '121001020101 (Guadalupe)' },
  { value: '121001020103', regionId: '11', label: '121001020103 (Guadalupe)' },
  { value: '121001020106', regionId: '11', label: '121001020106 (Guadalupe)' },
  { value: '121002010406', regionId: '11', label: '121002010406 (Guadalupe)' },
  { value: '121002010407', regionId: '11', label: '121002010407 (Guadalupe)' },
  { value: '121002010101', regionId: '11', label: '121002010101 (Guadalupe)' },
  { value: '121002010102', regionId: '11', label: '121002010102 (Guadalupe)' },
  { value: '121002010103', regionId: '11', label: '121002010103 (Guadalupe)' },
  { value: '121002010104', regionId: '11', label: '121002010104 (Guadalupe)' },
  { value: '121002010105', regionId: '11', label: '121002010105 (Guadalupe)' },
  { value: '121002010106', regionId: '11', label: '121002010106 (Guadalupe)' },
  { value: '121002010107', regionId: '11', label: '121002010107 (Guadalupe)' },
  { value: '121002010108', regionId: '11', label: '121002010108 (Guadalupe)' },
  { value: '121002010109', regionId: '11', label: '121002010109 (Guadalupe)' },
  { value: '121002010110', regionId: '11', label: '121002010110 (Guadalupe)' },
  { value: '121002010111', regionId: '11', label: '121002010111 (Guadalupe)' },
  { value: '121002010201', regionId: '11', label: '121002010201 (Guadalupe)' },
  { value: '121002010202', regionId: '11', label: '121002010202 (Guadalupe)' },
  { value: '121002010203', regionId: '11', label: '121002010203 (Guadalupe)' },
  { value: '121002010204', regionId: '11', label: '121002010204 (Guadalupe)' },
  { value: '121002010205', regionId: '11', label: '121002010205 (Guadalupe)' },
  { value: '121002010206', regionId: '11', label: '121002010206 (Guadalupe)' },
  { value: '121002010207', regionId: '11', label: '121002010207 (Guadalupe)' },
  { value: '121002010208', regionId: '11', label: '121002010208 (Guadalupe)' },
  { value: '121002010209', regionId: '11', label: '121002010209 (Guadalupe)' },
  { value: '121002010210', regionId: '11', label: '121002010210 (Guadalupe)' },
  { value: '121002010301', regionId: '11', label: '121002010301 (Guadalupe)' },
  { value: '121002010302', regionId: '11', label: '121002010302 (Guadalupe)' },
  { value: '121002010303', regionId: '11', label: '121002010303 (Guadalupe)' },
  { value: '121002010304', regionId: '11', label: '121002010304 (Guadalupe)' },
  { value: '121002010305', regionId: '11', label: '121002010305 (Guadalupe)' },
  { value: '121002010402', regionId: '11', label: '121002010402 (Guadalupe)' },
  { value: '121002010404', regionId: '11', label: '121002010404 (Guadalupe)' },
  { value: '121002010405', regionId: '11', label: '121002010405 (Guadalupe)' },
  { value: '121002010306', regionId: '11', label: '121002010306 (Guadalupe)' },
  { value: '121002010307', regionId: '11', label: '121002010307 (Guadalupe)' },
  { value: '121002010308', regionId: '11', label: '121002010308 (Guadalupe)' },
  { value: '121002010401', regionId: '11', label: '121002010401 (Guadalupe)' },
  { value: '121002010403', regionId: '11', label: '121002010403 (Guadalupe)' },
  { value: '121002020505', regionId: '11', label: '121002020505 (Guadalupe)' },
  { value: '121002020101', regionId: '11', label: '121002020101 (Guadalupe)' },
  { value: '121002020102', regionId: '11', label: '121002020102 (Guadalupe)' },
  { value: '121002020103', regionId: '11', label: '121002020103 (Guadalupe)' },
  { value: '121002020104', regionId: '11', label: '121002020104 (Guadalupe)' },
  { value: '121002020105', regionId: '11', label: '121002020105 (Guadalupe)' },
  { value: '121002020106', regionId: '11', label: '121002020106 (Guadalupe)' },
  { value: '121002020107', regionId: '11', label: '121002020107 (Guadalupe)' },
  { value: '121002020108', regionId: '11', label: '121002020108 (Guadalupe)' },
  { value: '121002020109', regionId: '11', label: '121002020109 (Guadalupe)' },
  { value: '121002020110', regionId: '11', label: '121002020110 (Guadalupe)' },
  { value: '121002020111', regionId: '11', label: '121002020111 (Guadalupe)' },
  { value: '121002020201', regionId: '11', label: '121002020201 (Guadalupe)' },
  { value: '121002020202', regionId: '11', label: '121002020202 (Guadalupe)' },
  { value: '121002020203', regionId: '11', label: '121002020203 (Guadalupe)' },
  { value: '121002020204', regionId: '11', label: '121002020204 (Guadalupe)' },
  { value: '121002020205', regionId: '11', label: '121002020205 (Guadalupe)' },
  { value: '121002020206', regionId: '11', label: '121002020206 (Guadalupe)' },
  { value: '121002020207', regionId: '11', label: '121002020207 (Guadalupe)' },
  { value: '121002020208', regionId: '11', label: '121002020208 (Guadalupe)' },
  { value: '121002020301', regionId: '11', label: '121002020301 (Guadalupe)' },
  { value: '121002020302', regionId: '11', label: '121002020302 (Guadalupe)' },
  { value: '121002020303', regionId: '11', label: '121002020303 (Guadalupe)' },
  { value: '121002020304', regionId: '11', label: '121002020304 (Guadalupe)' },
  { value: '121002020305', regionId: '11', label: '121002020305 (Guadalupe)' },
  { value: '121002020306', regionId: '11', label: '121002020306 (Guadalupe)' },
  { value: '121002020307', regionId: '11', label: '121002020307 (Guadalupe)' },
  { value: '121002020308', regionId: '11', label: '121002020308 (Guadalupe)' },
  { value: '121002020309', regionId: '11', label: '121002020309 (Guadalupe)' },
  { value: '121002020310', regionId: '11', label: '121002020310 (Guadalupe)' },
  { value: '121002020311', regionId: '11', label: '121002020311 (Guadalupe)' },
  { value: '121002020401', regionId: '11', label: '121002020401 (Guadalupe)' },
  { value: '121002020402', regionId: '11', label: '121002020402 (Guadalupe)' },
  { value: '121002020403', regionId: '11', label: '121002020403 (Guadalupe)' },
  { value: '121002020404', regionId: '11', label: '121002020404 (Guadalupe)' },
  { value: '121002020405', regionId: '11', label: '121002020405 (Guadalupe)' },
  { value: '121002020501', regionId: '11', label: '121002020501 (Guadalupe)' },
  { value: '121002020502', regionId: '11', label: '121002020502 (Guadalupe)' },
  { value: '121002020503', regionId: '11', label: '121002020503 (Guadalupe)' },
  { value: '121002020504', regionId: '11', label: '121002020504 (Guadalupe)' },
  { value: '121002020506', regionId: '11', label: '121002020506 (Guadalupe)' },
  { value: '121002020507', regionId: '11', label: '121002020507 (Guadalupe)' },
  { value: '121002020508', regionId: '11', label: '121002020508 (Guadalupe)' },
  { value: '121002020509', regionId: '11', label: '121002020509 (Guadalupe)' },
  { value: '121002020510', regionId: '11', label: '121002020510 (Guadalupe)' },
  { value: '121002020601', regionId: '11', label: '121002020601 (Guadalupe)' },
  { value: '121002020602', regionId: '11', label: '121002020602 (Guadalupe)' },
  { value: '121002020603', regionId: '11', label: '121002020603 (Guadalupe)' },
  { value: '121002020604', regionId: '11', label: '121002020604 (Guadalupe)' },
  { value: '121002020605', regionId: '11', label: '121002020605 (Guadalupe)' },
  { value: '121002020701', regionId: '11', label: '121002020701 (Guadalupe)' },
  { value: '121002020702', regionId: '11', label: '121002020702 (Guadalupe)' },
  { value: '121002020703', regionId: '11', label: '121002020703 (Guadalupe)' },
  { value: '121002020704', regionId: '11', label: '121002020704 (Guadalupe)' },
  { value: '121002020705', regionId: '11', label: '121002020705 (Guadalupe)' },
  { value: '121002030307', regionId: '11', label: '121002030307 (Guadalupe)' },
  { value: '121002030205', regionId: '11', label: '121002030205 (Guadalupe)' },
  { value: '121002030301', regionId: '11', label: '121002030301 (Guadalupe)' },
  { value: '121002030302', regionId: '11', label: '121002030302 (Guadalupe)' },
  { value: '121002030303', regionId: '11', label: '121002030303 (Guadalupe)' },
  { value: '121002030304', regionId: '11', label: '121002030304 (Guadalupe)' },
  { value: '121002030305', regionId: '11', label: '121002030305 (Guadalupe)' },
  { value: '121002030306', regionId: '11', label: '121002030306 (Guadalupe)' },
  { value: '121002030308', regionId: '11', label: '121002030308 (Guadalupe)' },
  { value: '121002030401', regionId: '11', label: '121002030401 (Guadalupe)' },
  { value: '121002030501', regionId: '11', label: '121002030501 (Guadalupe)' },
  { value: '121002030502', regionId: '11', label: '121002030502 (Guadalupe)' },
  { value: '121002030503', regionId: '11', label: '121002030503 (Guadalupe)' },
  { value: '121002030504', regionId: '11', label: '121002030504 (Guadalupe)' },
  { value: '121002030101', regionId: '11', label: '121002030101 (Guadalupe)' },
  { value: '121002030102', regionId: '11', label: '121002030102 (Guadalupe)' },
  { value: '121002030103', regionId: '11', label: '121002030103 (Guadalupe)' },
  { value: '121002030104', regionId: '11', label: '121002030104 (Guadalupe)' },
  { value: '121002030105', regionId: '11', label: '121002030105 (Guadalupe)' },
  { value: '121002030201', regionId: '11', label: '121002030201 (Guadalupe)' },
  { value: '121002030202', regionId: '11', label: '121002030202 (Guadalupe)' },
  { value: '121002030203', regionId: '11', label: '121002030203 (Guadalupe)' },
  { value: '121002030204', regionId: '11', label: '121002030204 (Guadalupe)' },
  { value: '121002030408', regionId: '11', label: '121002030408 (Guadalupe)' },
  { value: '121002030409', regionId: '11', label: '121002030409 (Guadalupe)' },
  { value: '121002030410', regionId: '11', label: '121002030410 (Guadalupe)' },
  { value: '121002030402', regionId: '11', label: '121002030402 (Guadalupe)' },
  { value: '121002030403', regionId: '11', label: '121002030403 (Guadalupe)' },
  { value: '121002030404', regionId: '11', label: '121002030404 (Guadalupe)' },
  { value: '121002030405', regionId: '11', label: '121002030405 (Guadalupe)' },
  { value: '121002030406', regionId: '11', label: '121002030406 (Guadalupe)' },
  { value: '121002030407', regionId: '11', label: '121002030407 (Guadalupe)' },
  { value: '121002040101', regionId: '11', label: '121002040101 (Guadalupe)' },
  { value: '121002040102', regionId: '11', label: '121002040102 (Guadalupe)' },
  { value: '121002040103', regionId: '11', label: '121002040103 (Guadalupe)' },
  { value: '121002040104', regionId: '11', label: '121002040104 (Guadalupe)' },
  { value: '121002040105', regionId: '11', label: '121002040105 (Guadalupe)' },
  { value: '121002040106', regionId: '11', label: '121002040106 (Guadalupe)' },
  { value: '121002040305', regionId: '11', label: '121002040305 (Guadalupe)' },
  { value: '121002040403', regionId: '11', label: '121002040403 (Guadalupe)' },
  { value: '121002040107', regionId: '11', label: '121002040107 (Guadalupe)' },
  { value: '121002040108', regionId: '11', label: '121002040108 (Guadalupe)' },
  { value: '121002040201', regionId: '11', label: '121002040201 (Guadalupe)' },
  { value: '121002040202', regionId: '11', label: '121002040202 (Guadalupe)' },
  { value: '121002040203', regionId: '11', label: '121002040203 (Guadalupe)' },
  { value: '121002040204', regionId: '11', label: '121002040204 (Guadalupe)' },
  { value: '121002040205', regionId: '11', label: '121002040205 (Guadalupe)' },
  { value: '121002040206', regionId: '11', label: '121002040206 (Guadalupe)' },
  { value: '121002040207', regionId: '11', label: '121002040207 (Guadalupe)' },
  { value: '121002040208', regionId: '11', label: '121002040208 (Guadalupe)' },
  { value: '121002040209', regionId: '11', label: '121002040209 (Guadalupe)' },
  { value: '121002040210', regionId: '11', label: '121002040210 (Guadalupe)' },
  { value: '121002040301', regionId: '11', label: '121002040301 (Guadalupe)' },
  { value: '121002040302', regionId: '11', label: '121002040302 (Guadalupe)' },
  { value: '121002040303', regionId: '11', label: '121002040303 (Guadalupe)' },
  { value: '121002040304', regionId: '11', label: '121002040304 (Guadalupe)' },
  { value: '121002040401', regionId: '11', label: '121002040401 (Guadalupe)' },
  { value: '121002040402', regionId: '11', label: '121002040402 (Guadalupe)' },
  { value: '121002040404', regionId: '11', label: '121002040404 (Guadalupe)' },
  { value: '121003030301', regionId: '11', label: '121003030301 (Guadalupe)' },
  { value: '121003030302', regionId: '11', label: '121003030302 (Guadalupe)' },
  { value: '121003030303', regionId: '11', label: '121003030303 (Guadalupe)' },
  { value: '121003030304', regionId: '11', label: '121003030304 (Guadalupe)' },
  { value: '121003030305', regionId: '11', label: '121003030305 (Guadalupe)' },
  { value: '121003030506', regionId: '11', label: '121003030506 (Guadalupe)' },
  { value: '121003030601', regionId: '11', label: '121003030601 (Guadalupe)' },
  { value: '121003030602', regionId: '11', label: '121003030602 (Guadalupe)' },
  { value: '121003030603', regionId: '11', label: '121003030603 (Guadalupe)' },
  { value: '121003030605', regionId: '11', label: '121003030605 (Guadalupe)' },
  { value: '121003030606', regionId: '11', label: '121003030606 (Guadalupe)' },
  { value: '121003030607', regionId: '11', label: '121003030607 (Guadalupe)' },
  { value: '121003030608', regionId: '11', label: '121003030608 (Guadalupe)' },
  { value: '121003040104', regionId: '11', label: '121003040104 (Guadalupe)' },
  { value: '121003040101', regionId: '11', label: '121003040101 (Guadalupe)' },
  { value: '121003040102', regionId: '11', label: '121003040102 (Guadalupe)' },
  { value: '121003040103', regionId: '11', label: '121003040103 (Guadalupe)' },
  { value: '121003040105', regionId: '11', label: '121003040105 (Guadalupe)' },
  { value: '121003040201', regionId: '11', label: '121003040201 (Guadalupe)' },
  { value: '121003040202', regionId: '11', label: '121003040202 (Guadalupe)' },
  { value: '121003040203', regionId: '11', label: '121003040203 (Guadalupe)' },
  { value: '121003040204', regionId: '11', label: '121003040204 (Guadalupe)' },
  { value: '121003040303', regionId: '11', label: '121003040303 (Guadalupe)' },
  { value: '121004020101', regionId: '11', label: '121004020101 (Guadalupe)' },
  { value: '121004020102', regionId: '11', label: '121004020102 (Guadalupe)' },
  { value: '121004020302', regionId: '11', label: '121004020302 (Guadalupe)' },
  { value: '121004020400', regionId: '11', label: '121004020400 (Guadalupe)' },
  { value: '121004020500', regionId: '11', label: '121004020500 (Guadalupe)' },
  { value: '121004030200', regionId: '11', label: '121004030200 (Guadalupe)' },
  { value: '121004030100', regionId: '11', label: '121004030100 (Guadalupe)' },
  { value: '121004040000', regionId: '11', label: '121004040000 (Guadalupe)' },
  { value: '121101060101', regionId: '11', label: '121101060101 (Guadalupe)' },
  { value: '121101060106', regionId: '11', label: '121101060106 (Guadalupe)' },
  { value: '120902030301', regionId: '11', label: '120902030301 (Guadalupe)' },
  { value: '121001010101', regionId: '11', label: '121001010101 (Guadalupe)' },
  { value: '121001010104', regionId: '11', label: '121001010104 (Guadalupe)' },
  { value: '121001010105', regionId: '11', label: '121001010105 (Guadalupe)' },
  { value: '121001010106', regionId: '11', label: '121001010106 (Guadalupe)' },
  { value: '121001010204', regionId: '11', label: '121001010204 (Guadalupe)' },
  { value: '121001010301', regionId: '11', label: '121001010301 (Guadalupe)' },
  { value: '121001010303', regionId: '11', label: '121001010303 (Guadalupe)' },
  { value: '121003020206', regionId: '11', label: '121003020206 (Guadalupe)' },
  { value: '121003020201', regionId: '11', label: '121003020201 (Guadalupe)' },
  { value: '121003020204', regionId: '11', label: '121003020204 (Guadalupe)' },
  { value: '121003020101', regionId: '11', label: '121003020101 (Guadalupe)' },
  { value: '121003020102', regionId: '11', label: '121003020102 (Guadalupe)' },
  { value: '121003020103', regionId: '11', label: '121003020103 (Guadalupe)' },
  { value: '121003020104', regionId: '11', label: '121003020104 (Guadalupe)' },
  { value: '121003020105', regionId: '11', label: '121003020105 (Guadalupe)' },
]

const hucsRegion08 = [
  { value: '111302090201', regionId: '08', label: '111302090201 (Lower Brazos)' },
  { value: '111302090203', regionId: '08', label: '111302090203 (Lower Brazos)' },
  { value: '120301010205', regionId: '08', label: '120301010205 (Lower Brazos)' },
  { value: '120301010206', regionId: '08', label: '120301010206 (Lower Brazos)' },
  { value: '120301010101', regionId: '08', label: '120301010101 (Lower Brazos)' },
  { value: '120301010103', regionId: '08', label: '120301010103 (Lower Brazos)' },
  { value: '120301010105', regionId: '08', label: '120301010105 (Lower Brazos)' },
  { value: '120301010301', regionId: '08', label: '120301010301 (Lower Brazos)' },
  { value: '120301010303', regionId: '08', label: '120301010303 (Lower Brazos)' },
  { value: '120301010402', regionId: '08', label: '120301010402 (Lower Brazos)' },
  { value: '120301010406', regionId: '08', label: '120301010406 (Lower Brazos)' },
  { value: '120301010407', regionId: '08', label: '120301010407 (Lower Brazos)' },
  { value: '120301020201', regionId: '08', label: '120301020201 (Lower Brazos)' },
  { value: '120301020202', regionId: '08', label: '120301020202 (Lower Brazos)' },
  { value: '120301020205', regionId: '08', label: '120301020205 (Lower Brazos)' },
  { value: '120301020206', regionId: '08', label: '120301020206 (Lower Brazos)' },
  { value: '120301020207', regionId: '08', label: '120301020207 (Lower Brazos)' },
  { value: '120301020301', regionId: '08', label: '120301020301 (Lower Brazos)' },
  { value: '120301020302', regionId: '08', label: '120301020302 (Lower Brazos)' },
  { value: '120301020303', regionId: '08', label: '120301020303 (Lower Brazos)' },
  { value: '120301020304', regionId: '08', label: '120301020304 (Lower Brazos)' },
  { value: '120301020401', regionId: '08', label: '120301020401 (Lower Brazos)' },
  { value: '120301080101', regionId: '08', label: '120301080101 (Lower Brazos)' },
  { value: '120301080104', regionId: '08', label: '120301080104 (Lower Brazos)' },
  { value: '120301080105', regionId: '08', label: '120301080105 (Lower Brazos)' },
  { value: '120301080106', regionId: '08', label: '120301080106 (Lower Brazos)' },
  { value: '120301080107', regionId: '08', label: '120301080107 (Lower Brazos)' },
  { value: '120301080108', regionId: '08', label: '120301080108 (Lower Brazos)' },
  { value: '120301080301', regionId: '08', label: '120301080301 (Lower Brazos)' },
  { value: '120301080302', regionId: '08', label: '120301080302 (Lower Brazos)' },
  { value: '120301080303', regionId: '08', label: '120301080303 (Lower Brazos)' },
  { value: '120301090101', regionId: '08', label: '120301090101 (Lower Brazos)' },
  { value: '120301090106', regionId: '08', label: '120301090106 (Lower Brazos)' },
  { value: '120301090107', regionId: '08', label: '120301090107 (Lower Brazos)' },
  { value: '120302010101', regionId: '08', label: '120302010101 (Lower Brazos)' },
  { value: '120302010103', regionId: '08', label: '120302010103 (Lower Brazos)' },
  { value: '120302010104', regionId: '08', label: '120302010104 (Lower Brazos)' },
  { value: '120302010501', regionId: '08', label: '120302010501 (Lower Brazos)' },
  { value: '120302010502', regionId: '08', label: '120302010502 (Lower Brazos)' },
  { value: '120302010601', regionId: '08', label: '120302010601 (Lower Brazos)' },
  { value: '120302020101', regionId: '08', label: '120302020101 (Lower Brazos)' },
  { value: '120302020201', regionId: '08', label: '120302020201 (Lower Brazos)' },
  { value: '120302020402', regionId: '08', label: '120302020402 (Lower Brazos)' },
  { value: '120302020404', regionId: '08', label: '120302020404 (Lower Brazos)' },
  { value: '120302020405', regionId: '08', label: '120302020405 (Lower Brazos)' },
  { value: '120302020406', regionId: '08', label: '120302020406 (Lower Brazos)' },
  { value: '120302020501', regionId: '08', label: '120302020501 (Lower Brazos)' },
  { value: '120401010301', regionId: '08', label: '120401010301 (Lower Brazos)' },
  { value: '120401010302', regionId: '08', label: '120401010302 (Lower Brazos)' },
  { value: '120401010304', regionId: '08', label: '120401010304 (Lower Brazos)' },
  { value: '120401010305', regionId: '08', label: '120401010305 (Lower Brazos)' },
  { value: '120401020101', regionId: '08', label: '120401020101 (Lower Brazos)' },
  { value: '120401020102', regionId: '08', label: '120401020102 (Lower Brazos)' },
  { value: '120401020201', regionId: '08', label: '120401020201 (Lower Brazos)' },
  { value: '120401020202', regionId: '08', label: '120401020202 (Lower Brazos)' },
  { value: '120401020203', regionId: '08', label: '120401020203 (Lower Brazos)' },
  { value: '120401020206', regionId: '08', label: '120401020206 (Lower Brazos)' },
  { value: '120401040101', regionId: '08', label: '120401040101 (Lower Brazos)' },
  { value: '120401040103', regionId: '08', label: '120401040103 (Lower Brazos)' },
  { value: '120401040501', regionId: '08', label: '120401040501 (Lower Brazos)' },
  { value: '120401040401', regionId: '08', label: '120401040401 (Lower Brazos)' },
  { value: '120402040400', regionId: '08', label: '120402040400 (Lower Brazos)' },
  { value: '120402050100', regionId: '08', label: '120402050100 (Lower Brazos)' },
  { value: '120402050200', regionId: '08', label: '120402050200 (Lower Brazos)' },
  { value: '120402050300', regionId: '08', label: '120402050300 (Lower Brazos)' },
  { value: '120402050400', regionId: '08', label: '120402050400 (Lower Brazos)' },
  { value: '120402050500', regionId: '08', label: '120402050500 (Lower Brazos)' },
  { value: '120601010807', regionId: '08', label: '120601010807 (Lower Brazos)' },
  { value: '120601010901', regionId: '08', label: '120601010901 (Lower Brazos)' },
  { value: '120601010902', regionId: '08', label: '120601010902 (Lower Brazos)' },
  { value: '120601010905', regionId: '08', label: '120601010905 (Lower Brazos)' },
  { value: '120601010906', regionId: '08', label: '120601010906 (Lower Brazos)' },
  { value: '120601040301', regionId: '08', label: '120601040301 (Lower Brazos)' },
  { value: '120601040302', regionId: '08', label: '120601040302 (Lower Brazos)' },
  { value: '120601040303', regionId: '08', label: '120601040303 (Lower Brazos)' },
  { value: '120601050401', regionId: '08', label: '120601050401 (Lower Brazos)' },
  { value: '120601050402', regionId: '08', label: '120601050402 (Lower Brazos)' },
  { value: '120601050403', regionId: '08', label: '120601050403 (Lower Brazos)' },
  { value: '120601050404', regionId: '08', label: '120601050404 (Lower Brazos)' },
  { value: '120601050501', regionId: '08', label: '120601050501 (Lower Brazos)' },
  { value: '120601050502', regionId: '08', label: '120601050502 (Lower Brazos)' },
  { value: '120601050503', regionId: '08', label: '120601050503 (Lower Brazos)' },
  { value: '120601050504', regionId: '08', label: '120601050504 (Lower Brazos)' },
  { value: '120602010905', regionId: '08', label: '120602010905 (Lower Brazos)' },
  { value: '120602010906', regionId: '08', label: '120602010906 (Lower Brazos)' },
  { value: '120602010907', regionId: '08', label: '120602010907 (Lower Brazos)' },
  { value: '120602011001', regionId: '08', label: '120602011001 (Lower Brazos)' },
  { value: '120602011002', regionId: '08', label: '120602011002 (Lower Brazos)' },
  { value: '120602011003', regionId: '08', label: '120602011003 (Lower Brazos)' },
  { value: '120602011004', regionId: '08', label: '120602011004 (Lower Brazos)' },
  { value: '120602011101', regionId: '08', label: '120602011101 (Lower Brazos)' },
  { value: '120602011102', regionId: '08', label: '120602011102 (Lower Brazos)' },
  { value: '120602010101', regionId: '08', label: '120602010101 (Lower Brazos)' },
  { value: '120602010102', regionId: '08', label: '120602010102 (Lower Brazos)' },
  { value: '120602010103', regionId: '08', label: '120602010103 (Lower Brazos)' },
  { value: '120602010104', regionId: '08', label: '120602010104 (Lower Brazos)' },
  { value: '120602010105', regionId: '08', label: '120602010105 (Lower Brazos)' },
  { value: '120602010106', regionId: '08', label: '120602010106 (Lower Brazos)' },
  { value: '120602010107', regionId: '08', label: '120602010107 (Lower Brazos)' },
  { value: '120602010201', regionId: '08', label: '120602010201 (Lower Brazos)' },
  { value: '120602010202', regionId: '08', label: '120602010202 (Lower Brazos)' },
  { value: '120602010203', regionId: '08', label: '120602010203 (Lower Brazos)' },
  { value: '120602010301', regionId: '08', label: '120602010301 (Lower Brazos)' },
  { value: '120602010302', regionId: '08', label: '120602010302 (Lower Brazos)' },
  { value: '120602010505', regionId: '08', label: '120602010505 (Lower Brazos)' },
  { value: '120602010506', regionId: '08', label: '120602010506 (Lower Brazos)' },
  { value: '120602010303', regionId: '08', label: '120602010303 (Lower Brazos)' },
  { value: '120602010304', regionId: '08', label: '120602010304 (Lower Brazos)' },
  { value: '120602010401', regionId: '08', label: '120602010401 (Lower Brazos)' },
  { value: '120602010402', regionId: '08', label: '120602010402 (Lower Brazos)' },
  { value: '120602010403', regionId: '08', label: '120602010403 (Lower Brazos)' },
  { value: '120602010404', regionId: '08', label: '120602010404 (Lower Brazos)' },
  { value: '120602010501', regionId: '08', label: '120602010501 (Lower Brazos)' },
  { value: '120602010502', regionId: '08', label: '120602010502 (Lower Brazos)' },
  { value: '120602010503', regionId: '08', label: '120602010503 (Lower Brazos)' },
  { value: '120602010504', regionId: '08', label: '120602010504 (Lower Brazos)' },
  { value: '120602010601', regionId: '08', label: '120602010601 (Lower Brazos)' },
  { value: '120602010602', regionId: '08', label: '120602010602 (Lower Brazos)' },
  { value: '120602010603', regionId: '08', label: '120602010603 (Lower Brazos)' },
  { value: '120602010604', regionId: '08', label: '120602010604 (Lower Brazos)' },
  { value: '120602010605', regionId: '08', label: '120602010605 (Lower Brazos)' },
  { value: '120602010606', regionId: '08', label: '120602010606 (Lower Brazos)' },
  { value: '120602010607', regionId: '08', label: '120602010607 (Lower Brazos)' },
  { value: '120602010608', regionId: '08', label: '120602010608 (Lower Brazos)' },
  { value: '120602010701', regionId: '08', label: '120602010701 (Lower Brazos)' },
  { value: '120602010702', regionId: '08', label: '120602010702 (Lower Brazos)' },
  { value: '120602010703', regionId: '08', label: '120602010703 (Lower Brazos)' },
  { value: '120602010806', regionId: '08', label: '120602010806 (Lower Brazos)' },
  { value: '120602010807', regionId: '08', label: '120602010807 (Lower Brazos)' },
  { value: '120602010704', regionId: '08', label: '120602010704 (Lower Brazos)' },
  { value: '120602010705', regionId: '08', label: '120602010705 (Lower Brazos)' },
  { value: '120602010706', regionId: '08', label: '120602010706 (Lower Brazos)' },
  { value: '120602010801', regionId: '08', label: '120602010801 (Lower Brazos)' },
  { value: '120602010802', regionId: '08', label: '120602010802 (Lower Brazos)' },
  { value: '120602010803', regionId: '08', label: '120602010803 (Lower Brazos)' },
  { value: '120602010804', regionId: '08', label: '120602010804 (Lower Brazos)' },
  { value: '120602010805', regionId: '08', label: '120602010805 (Lower Brazos)' },
  { value: '120602010808', regionId: '08', label: '120602010808 (Lower Brazos)' },
  { value: '120602010809', regionId: '08', label: '120602010809 (Lower Brazos)' },
  { value: '120602010901', regionId: '08', label: '120602010901 (Lower Brazos)' },
  { value: '120602010902', regionId: '08', label: '120602010902 (Lower Brazos)' },
  { value: '120602010903', regionId: '08', label: '120602010903 (Lower Brazos)' },
  { value: '120602010904', regionId: '08', label: '120602010904 (Lower Brazos)' },
  { value: '120602011103', regionId: '08', label: '120602011103 (Lower Brazos)' },
  { value: '120602011104', regionId: '08', label: '120602011104 (Lower Brazos)' },
  { value: '120602011105', regionId: '08', label: '120602011105 (Lower Brazos)' },
  { value: '120602011106', regionId: '08', label: '120602011106 (Lower Brazos)' },
  { value: '120602011204', regionId: '08', label: '120602011204 (Lower Brazos)' },
  { value: '120602011107', regionId: '08', label: '120602011107 (Lower Brazos)' },
  { value: '120602011108', regionId: '08', label: '120602011108 (Lower Brazos)' },
  { value: '120602011109', regionId: '08', label: '120602011109 (Lower Brazos)' },
  { value: '120602011110', regionId: '08', label: '120602011110 (Lower Brazos)' },
  { value: '120602011201', regionId: '08', label: '120602011201 (Lower Brazos)' },
  { value: '120602011202', regionId: '08', label: '120602011202 (Lower Brazos)' },
  { value: '120602011203', regionId: '08', label: '120602011203 (Lower Brazos)' },
  { value: '120602011205', regionId: '08', label: '120602011205 (Lower Brazos)' },
  { value: '120602011206', regionId: '08', label: '120602011206 (Lower Brazos)' },
  { value: '120602011207', regionId: '08', label: '120602011207 (Lower Brazos)' },
  { value: '120602011208', regionId: '08', label: '120602011208 (Lower Brazos)' },
  { value: '120602011301', regionId: '08', label: '120602011301 (Lower Brazos)' },
  { value: '120602011302', regionId: '08', label: '120602011302 (Lower Brazos)' },
  { value: '120602011303', regionId: '08', label: '120602011303 (Lower Brazos)' },
  { value: '120602020509', regionId: '08', label: '120602020509 (Lower Brazos)' },
  { value: '120602020601', regionId: '08', label: '120602020601 (Lower Brazos)' },
  { value: '120602020602', regionId: '08', label: '120602020602 (Lower Brazos)' },
  { value: '120602020603', regionId: '08', label: '120602020603 (Lower Brazos)' },
  { value: '120602020604', regionId: '08', label: '120602020604 (Lower Brazos)' },
  { value: '120602020605', regionId: '08', label: '120602020605 (Lower Brazos)' },
  { value: '120602020701', regionId: '08', label: '120602020701 (Lower Brazos)' },
  { value: '120602020702', regionId: '08', label: '120602020702 (Lower Brazos)' },
  { value: '120602020804', regionId: '08', label: '120602020804 (Lower Brazos)' },
  { value: '120602020805', regionId: '08', label: '120602020805 (Lower Brazos)' },
  { value: '120602020708', regionId: '08', label: '120602020708 (Lower Brazos)' },
  { value: '120602020703', regionId: '08', label: '120602020703 (Lower Brazos)' },
  { value: '120602020704', regionId: '08', label: '120602020704 (Lower Brazos)' },
  { value: '120602020705', regionId: '08', label: '120602020705 (Lower Brazos)' },
  { value: '120602020706', regionId: '08', label: '120602020706 (Lower Brazos)' },
  { value: '120602020707', regionId: '08', label: '120602020707 (Lower Brazos)' },
  { value: '120602020801', regionId: '08', label: '120602020801 (Lower Brazos)' },
  { value: '120602020802', regionId: '08', label: '120602020802 (Lower Brazos)' },
  { value: '120602020803', regionId: '08', label: '120602020803 (Lower Brazos)' },
  { value: '120602020101', regionId: '08', label: '120602020101 (Lower Brazos)' },
  { value: '120602020102', regionId: '08', label: '120602020102 (Lower Brazos)' },
  { value: '120602020103', regionId: '08', label: '120602020103 (Lower Brazos)' },
  { value: '120602020104', regionId: '08', label: '120602020104 (Lower Brazos)' },
  { value: '120602020105', regionId: '08', label: '120602020105 (Lower Brazos)' },
  { value: '120602020106', regionId: '08', label: '120602020106 (Lower Brazos)' },
  { value: '120602020107', regionId: '08', label: '120602020107 (Lower Brazos)' },
  { value: '120602020108', regionId: '08', label: '120602020108 (Lower Brazos)' },
  { value: '120602020109', regionId: '08', label: '120602020109 (Lower Brazos)' },
  { value: '120602020110', regionId: '08', label: '120602020110 (Lower Brazos)' },
  { value: '120602020111', regionId: '08', label: '120602020111 (Lower Brazos)' },
  { value: '120602020201', regionId: '08', label: '120602020201 (Lower Brazos)' },
  { value: '120602020202', regionId: '08', label: '120602020202 (Lower Brazos)' },
  { value: '120602020203', regionId: '08', label: '120602020203 (Lower Brazos)' },
  { value: '120602020204', regionId: '08', label: '120602020204 (Lower Brazos)' },
  { value: '120602020205', regionId: '08', label: '120602020205 (Lower Brazos)' },
  { value: '120602020206', regionId: '08', label: '120602020206 (Lower Brazos)' },
  { value: '120602020207', regionId: '08', label: '120602020207 (Lower Brazos)' },
  { value: '120602020301', regionId: '08', label: '120602020301 (Lower Brazos)' },
  { value: '120602020306', regionId: '08', label: '120602020306 (Lower Brazos)' },
  { value: '120602020401', regionId: '08', label: '120602020401 (Lower Brazos)' },
  { value: '120602020402', regionId: '08', label: '120602020402 (Lower Brazos)' },
  { value: '120602020406', regionId: '08', label: '120602020406 (Lower Brazos)' },
  { value: '120602020302', regionId: '08', label: '120602020302 (Lower Brazos)' },
  { value: '120602020303', regionId: '08', label: '120602020303 (Lower Brazos)' },
  { value: '120602020304', regionId: '08', label: '120602020304 (Lower Brazos)' },
  { value: '120602020305', regionId: '08', label: '120602020305 (Lower Brazos)' },
  { value: '120602020403', regionId: '08', label: '120602020403 (Lower Brazos)' },
  { value: '120602020404', regionId: '08', label: '120602020404 (Lower Brazos)' },
  { value: '120602020405', regionId: '08', label: '120602020405 (Lower Brazos)' },
  { value: '120602020407', regionId: '08', label: '120602020407 (Lower Brazos)' },
  { value: '120602020408', regionId: '08', label: '120602020408 (Lower Brazos)' },
  { value: '120602020501', regionId: '08', label: '120602020501 (Lower Brazos)' },
  { value: '120602020502', regionId: '08', label: '120602020502 (Lower Brazos)' },
  { value: '120602020503', regionId: '08', label: '120602020503 (Lower Brazos)' },
  { value: '120602020504', regionId: '08', label: '120602020504 (Lower Brazos)' },
  { value: '120602020505', regionId: '08', label: '120602020505 (Lower Brazos)' },
  { value: '120602020506', regionId: '08', label: '120602020506 (Lower Brazos)' },
  { value: '120602020507', regionId: '08', label: '120602020507 (Lower Brazos)' },
  { value: '120602020508', regionId: '08', label: '120602020508 (Lower Brazos)' },
  { value: '120602030102', regionId: '08', label: '120602030102 (Lower Brazos)' },
  { value: '120602030101', regionId: '08', label: '120602030101 (Lower Brazos)' },
  { value: '120602030103', regionId: '08', label: '120602030103 (Lower Brazos)' },
  { value: '120602030104', regionId: '08', label: '120602030104 (Lower Brazos)' },
  { value: '120602030105', regionId: '08', label: '120602030105 (Lower Brazos)' },
  { value: '120602030201', regionId: '08', label: '120602030201 (Lower Brazos)' },
  { value: '120602030202', regionId: '08', label: '120602030202 (Lower Brazos)' },
  { value: '120602030301', regionId: '08', label: '120602030301 (Lower Brazos)' },
  { value: '120602030302', regionId: '08', label: '120602030302 (Lower Brazos)' },
  { value: '120602030303', regionId: '08', label: '120602030303 (Lower Brazos)' },
  { value: '120602040101', regionId: '08', label: '120602040101 (Lower Brazos)' },
  { value: '120602040102', regionId: '08', label: '120602040102 (Lower Brazos)' },
  { value: '120602040103', regionId: '08', label: '120602040103 (Lower Brazos)' },
  { value: '120602040104', regionId: '08', label: '120602040104 (Lower Brazos)' },
  { value: '120602040105', regionId: '08', label: '120602040105 (Lower Brazos)' },
  { value: '120602040106', regionId: '08', label: '120602040106 (Lower Brazos)' },
  { value: '120602040107', regionId: '08', label: '120602040107 (Lower Brazos)' },
  { value: '120602040201', regionId: '08', label: '120602040201 (Lower Brazos)' },
  { value: '120602040202', regionId: '08', label: '120602040202 (Lower Brazos)' },
  { value: '120602040203', regionId: '08', label: '120602040203 (Lower Brazos)' },
  { value: '120602040204', regionId: '08', label: '120602040204 (Lower Brazos)' },
  { value: '120602040205', regionId: '08', label: '120602040205 (Lower Brazos)' },
  { value: '120602040206', regionId: '08', label: '120602040206 (Lower Brazos)' },
  { value: '120602040301', regionId: '08', label: '120602040301 (Lower Brazos)' },
  { value: '120602040302', regionId: '08', label: '120602040302 (Lower Brazos)' },
  { value: '120602040303', regionId: '08', label: '120602040303 (Lower Brazos)' },
  { value: '120602040304', regionId: '08', label: '120602040304 (Lower Brazos)' },
  { value: '120602040305', regionId: '08', label: '120602040305 (Lower Brazos)' },
  { value: '120602040306', regionId: '08', label: '120602040306 (Lower Brazos)' },
  { value: '120602040307', regionId: '08', label: '120602040307 (Lower Brazos)' },
  { value: '120602040308', regionId: '08', label: '120602040308 (Lower Brazos)' },
  { value: '120602040309', regionId: '08', label: '120602040309 (Lower Brazos)' },
  { value: '120602040401', regionId: '08', label: '120602040401 (Lower Brazos)' },
  { value: '120602040402', regionId: '08', label: '120602040402 (Lower Brazos)' },
  { value: '120602040403', regionId: '08', label: '120602040403 (Lower Brazos)' },
  { value: '120602040404', regionId: '08', label: '120602040404 (Lower Brazos)' },
  { value: '120602040405', regionId: '08', label: '120602040405 (Lower Brazos)' },
  { value: '120602040406', regionId: '08', label: '120602040406 (Lower Brazos)' },
  { value: '120602040407', regionId: '08', label: '120602040407 (Lower Brazos)' },
  { value: '120701010205', regionId: '08', label: '120701010205 (Lower Brazos)' },
  { value: '120701010206', regionId: '08', label: '120701010206 (Lower Brazos)' },
  { value: '120701010207', regionId: '08', label: '120701010207 (Lower Brazos)' },
  { value: '120701010208', regionId: '08', label: '120701010208 (Lower Brazos)' },
  { value: '120701010902', regionId: '08', label: '120701010902 (Lower Brazos)' },
  { value: '120701010903', regionId: '08', label: '120701010903 (Lower Brazos)' },
  { value: '120701010904', regionId: '08', label: '120701010904 (Lower Brazos)' },
  { value: '120701010905', regionId: '08', label: '120701010905 (Lower Brazos)' },
  { value: '120701010906', regionId: '08', label: '120701010906 (Lower Brazos)' },
  { value: '120701010907', regionId: '08', label: '120701010907 (Lower Brazos)' },
  { value: '120701010908', regionId: '08', label: '120701010908 (Lower Brazos)' },
  { value: '120701010101', regionId: '08', label: '120701010101 (Lower Brazos)' },
  { value: '120701010102', regionId: '08', label: '120701010102 (Lower Brazos)' },
  { value: '120701010103', regionId: '08', label: '120701010103 (Lower Brazos)' },
  { value: '120701010104', regionId: '08', label: '120701010104 (Lower Brazos)' },
  { value: '120701010105', regionId: '08', label: '120701010105 (Lower Brazos)' },
  { value: '120701010106', regionId: '08', label: '120701010106 (Lower Brazos)' },
  { value: '120701010107', regionId: '08', label: '120701010107 (Lower Brazos)' },
  { value: '120701010108', regionId: '08', label: '120701010108 (Lower Brazos)' },
  { value: '120701010109', regionId: '08', label: '120701010109 (Lower Brazos)' },
  { value: '120701010110', regionId: '08', label: '120701010110 (Lower Brazos)' },
  { value: '120701010111', regionId: '08', label: '120701010111 (Lower Brazos)' },
  { value: '120701010201', regionId: '08', label: '120701010201 (Lower Brazos)' },
  { value: '120701010202', regionId: '08', label: '120701010202 (Lower Brazos)' },
  { value: '120701010203', regionId: '08', label: '120701010203 (Lower Brazos)' },
  { value: '120701010204', regionId: '08', label: '120701010204 (Lower Brazos)' },
  { value: '120701010301', regionId: '08', label: '120701010301 (Lower Brazos)' },
  { value: '120701010302', regionId: '08', label: '120701010302 (Lower Brazos)' },
  { value: '120701010303', regionId: '08', label: '120701010303 (Lower Brazos)' },
  { value: '120701010304', regionId: '08', label: '120701010304 (Lower Brazos)' },
  { value: '120701010305', regionId: '08', label: '120701010305 (Lower Brazos)' },
  { value: '120701010401', regionId: '08', label: '120701010401 (Lower Brazos)' },
  { value: '120701010402', regionId: '08', label: '120701010402 (Lower Brazos)' },
  { value: '120701010405', regionId: '08', label: '120701010405 (Lower Brazos)' },
  { value: '120701010501', regionId: '08', label: '120701010501 (Lower Brazos)' },
  { value: '120701010502', regionId: '08', label: '120701010502 (Lower Brazos)' },
  { value: '120701010503', regionId: '08', label: '120701010503 (Lower Brazos)' },
  { value: '120701010504', regionId: '08', label: '120701010504 (Lower Brazos)' },
  { value: '120701010604', regionId: '08', label: '120701010604 (Lower Brazos)' },
  { value: '120701010605', regionId: '08', label: '120701010605 (Lower Brazos)' },
  { value: '120701010606', regionId: '08', label: '120701010606 (Lower Brazos)' },
  { value: '120701010607', regionId: '08', label: '120701010607 (Lower Brazos)' },
  { value: '120701010608', regionId: '08', label: '120701010608 (Lower Brazos)' },
  { value: '120701010609', regionId: '08', label: '120701010609 (Lower Brazos)' },
  { value: '120701010610', regionId: '08', label: '120701010610 (Lower Brazos)' },
  { value: '120701010611', regionId: '08', label: '120701010611 (Lower Brazos)' },
  { value: '120701010612', regionId: '08', label: '120701010612 (Lower Brazos)' },
  { value: '120701010613', regionId: '08', label: '120701010613 (Lower Brazos)' },
  { value: '120701010701', regionId: '08', label: '120701010701 (Lower Brazos)' },
  { value: '120701010702', regionId: '08', label: '120701010702 (Lower Brazos)' },
  { value: '120701010703', regionId: '08', label: '120701010703 (Lower Brazos)' },
  { value: '120701010706', regionId: '08', label: '120701010706 (Lower Brazos)' },
  { value: '120701010801', regionId: '08', label: '120701010801 (Lower Brazos)' },
  { value: '120701010802', regionId: '08', label: '120701010802 (Lower Brazos)' },
  { value: '120701010803', regionId: '08', label: '120701010803 (Lower Brazos)' },
  { value: '120701010804', regionId: '08', label: '120701010804 (Lower Brazos)' },
  { value: '120701010901', regionId: '08', label: '120701010901 (Lower Brazos)' },
  { value: '120701010403', regionId: '08', label: '120701010403 (Lower Brazos)' },
  { value: '120701010404', regionId: '08', label: '120701010404 (Lower Brazos)' },
  { value: '120701010601', regionId: '08', label: '120701010601 (Lower Brazos)' },
  { value: '120701010602', regionId: '08', label: '120701010602 (Lower Brazos)' },
  { value: '120701010603', regionId: '08', label: '120701010603 (Lower Brazos)' },
  { value: '120701010704', regionId: '08', label: '120701010704 (Lower Brazos)' },
  { value: '120701010705', regionId: '08', label: '120701010705 (Lower Brazos)' },
  { value: '120701020101', regionId: '08', label: '120701020101 (Lower Brazos)' },
  { value: '120701020102', regionId: '08', label: '120701020102 (Lower Brazos)' },
  { value: '120701020103', regionId: '08', label: '120701020103 (Lower Brazos)' },
  { value: '120701020104', regionId: '08', label: '120701020104 (Lower Brazos)' },
  { value: '120701020105', regionId: '08', label: '120701020105 (Lower Brazos)' },
  { value: '120701020106', regionId: '08', label: '120701020106 (Lower Brazos)' },
  { value: '120701020107', regionId: '08', label: '120701020107 (Lower Brazos)' },
  { value: '120701020108', regionId: '08', label: '120701020108 (Lower Brazos)' },
  { value: '120701020109', regionId: '08', label: '120701020109 (Lower Brazos)' },
  { value: '120701020110', regionId: '08', label: '120701020110 (Lower Brazos)' },
  { value: '120701020111', regionId: '08', label: '120701020111 (Lower Brazos)' },
  { value: '120701020201', regionId: '08', label: '120701020201 (Lower Brazos)' },
  { value: '120701020202', regionId: '08', label: '120701020202 (Lower Brazos)' },
  { value: '120701020203', regionId: '08', label: '120701020203 (Lower Brazos)' },
  { value: '120701020204', regionId: '08', label: '120701020204 (Lower Brazos)' },
  { value: '120701020205', regionId: '08', label: '120701020205 (Lower Brazos)' },
  { value: '120701020206', regionId: '08', label: '120701020206 (Lower Brazos)' },
  { value: '120701020301', regionId: '08', label: '120701020301 (Lower Brazos)' },
  { value: '120701020302', regionId: '08', label: '120701020302 (Lower Brazos)' },
  { value: '120701020303', regionId: '08', label: '120701020303 (Lower Brazos)' },
  { value: '120701020304', regionId: '08', label: '120701020304 (Lower Brazos)' },
  { value: '120701020305', regionId: '08', label: '120701020305 (Lower Brazos)' },
  { value: '120701020306', regionId: '08', label: '120701020306 (Lower Brazos)' },
  { value: '120701020307', regionId: '08', label: '120701020307 (Lower Brazos)' },
  { value: '120701020308', regionId: '08', label: '120701020308 (Lower Brazos)' },
  { value: '120701020309', regionId: '08', label: '120701020309 (Lower Brazos)' },
  { value: '120701020401', regionId: '08', label: '120701020401 (Lower Brazos)' },
  { value: '120701020402', regionId: '08', label: '120701020402 (Lower Brazos)' },
  { value: '120701020403', regionId: '08', label: '120701020403 (Lower Brazos)' },
  { value: '120701020404', regionId: '08', label: '120701020404 (Lower Brazos)' },
  { value: '120701020405', regionId: '08', label: '120701020405 (Lower Brazos)' },
  { value: '120701020406', regionId: '08', label: '120701020406 (Lower Brazos)' },
  { value: '120701020501', regionId: '08', label: '120701020501 (Lower Brazos)' },
  { value: '120701020502', regionId: '08', label: '120701020502 (Lower Brazos)' },
  { value: '120701030305', regionId: '08', label: '120701030305 (Lower Brazos)' },
  { value: '120701030306', regionId: '08', label: '120701030306 (Lower Brazos)' },
  { value: '120701030101', regionId: '08', label: '120701030101 (Lower Brazos)' },
  { value: '120701030102', regionId: '08', label: '120701030102 (Lower Brazos)' },
  { value: '120701030103', regionId: '08', label: '120701030103 (Lower Brazos)' },
  { value: '120701030104', regionId: '08', label: '120701030104 (Lower Brazos)' },
  { value: '120701030105', regionId: '08', label: '120701030105 (Lower Brazos)' },
  { value: '120701030106', regionId: '08', label: '120701030106 (Lower Brazos)' },
  { value: '120701030107', regionId: '08', label: '120701030107 (Lower Brazos)' },
  { value: '120701030108', regionId: '08', label: '120701030108 (Lower Brazos)' },
  { value: '120701030201', regionId: '08', label: '120701030201 (Lower Brazos)' },
  { value: '120701030202', regionId: '08', label: '120701030202 (Lower Brazos)' },
  { value: '120701030203', regionId: '08', label: '120701030203 (Lower Brazos)' },
  { value: '120701030204', regionId: '08', label: '120701030204 (Lower Brazos)' },
  { value: '120701030301', regionId: '08', label: '120701030301 (Lower Brazos)' },
  { value: '120701030302', regionId: '08', label: '120701030302 (Lower Brazos)' },
  { value: '120701030303', regionId: '08', label: '120701030303 (Lower Brazos)' },
  { value: '120701030304', regionId: '08', label: '120701030304 (Lower Brazos)' },
  { value: '120701030307', regionId: '08', label: '120701030307 (Lower Brazos)' },
  { value: '120701030308', regionId: '08', label: '120701030308 (Lower Brazos)' },
  { value: '120701030309', regionId: '08', label: '120701030309 (Lower Brazos)' },
  { value: '120701030401', regionId: '08', label: '120701030401 (Lower Brazos)' },
  { value: '120701030402', regionId: '08', label: '120701030402 (Lower Brazos)' },
  { value: '120701030403', regionId: '08', label: '120701030403 (Lower Brazos)' },
  { value: '120701030404', regionId: '08', label: '120701030404 (Lower Brazos)' },
  { value: '120701030405', regionId: '08', label: '120701030405 (Lower Brazos)' },
  { value: '120701030406', regionId: '08', label: '120701030406 (Lower Brazos)' },
  { value: '120701030407', regionId: '08', label: '120701030407 (Lower Brazos)' },
  { value: '120701030501', regionId: '08', label: '120701030501 (Lower Brazos)' },
  { value: '120701030502', regionId: '08', label: '120701030502 (Lower Brazos)' },
  { value: '120701030503', regionId: '08', label: '120701030503 (Lower Brazos)' },
  { value: '120701030504', regionId: '08', label: '120701030504 (Lower Brazos)' },
  { value: '120701030505', regionId: '08', label: '120701030505 (Lower Brazos)' },
  { value: '120701030506', regionId: '08', label: '120701030506 (Lower Brazos)' },
  { value: '120701030507', regionId: '08', label: '120701030507 (Lower Brazos)' },
  { value: '120701030508', regionId: '08', label: '120701030508 (Lower Brazos)' },
  { value: '120701030509', regionId: '08', label: '120701030509 (Lower Brazos)' },
  { value: '120701030510', regionId: '08', label: '120701030510 (Lower Brazos)' },
  { value: '120701030601', regionId: '08', label: '120701030601 (Lower Brazos)' },
  { value: '120701030602', regionId: '08', label: '120701030602 (Lower Brazos)' },
  { value: '120701030603', regionId: '08', label: '120701030603 (Lower Brazos)' },
  { value: '120701030701', regionId: '08', label: '120701030701 (Lower Brazos)' },
  { value: '120701030702', regionId: '08', label: '120701030702 (Lower Brazos)' },
  { value: '120701030604', regionId: '08', label: '120701030604 (Lower Brazos)' },
  { value: '120701030703', regionId: '08', label: '120701030703 (Lower Brazos)' },
  { value: '120701030704', regionId: '08', label: '120701030704 (Lower Brazos)' },
  { value: '120701030705', regionId: '08', label: '120701030705 (Lower Brazos)' },
  { value: '120701030706', regionId: '08', label: '120701030706 (Lower Brazos)' },
  { value: '120701030707', regionId: '08', label: '120701030707 (Lower Brazos)' },
  { value: '120701030801', regionId: '08', label: '120701030801 (Lower Brazos)' },
  { value: '120701030802', regionId: '08', label: '120701030802 (Lower Brazos)' },
  { value: '120701030803', regionId: '08', label: '120701030803 (Lower Brazos)' },
  { value: '120701030804', regionId: '08', label: '120701030804 (Lower Brazos)' },
  { value: '120701040403', regionId: '08', label: '120701040403 (Lower Brazos)' },
  { value: '120701040401', regionId: '08', label: '120701040401 (Lower Brazos)' },
  { value: '120701040402', regionId: '08', label: '120701040402 (Lower Brazos)' },
  { value: '120701040404', regionId: '08', label: '120701040404 (Lower Brazos)' },
  { value: '120701040405', regionId: '08', label: '120701040405 (Lower Brazos)' },
  { value: '120701040406', regionId: '08', label: '120701040406 (Lower Brazos)' },
  { value: '120701040407', regionId: '08', label: '120701040407 (Lower Brazos)' },
  { value: '120701040408', regionId: '08', label: '120701040408 (Lower Brazos)' },
  { value: '120701040409', regionId: '08', label: '120701040409 (Lower Brazos)' },
  { value: '120701040501', regionId: '08', label: '120701040501 (Lower Brazos)' },
  { value: '120701040502', regionId: '08', label: '120701040502 (Lower Brazos)' },
  { value: '120701040503', regionId: '08', label: '120701040503 (Lower Brazos)' },
  { value: '120701040504', regionId: '08', label: '120701040504 (Lower Brazos)' },
  { value: '120701040505', regionId: '08', label: '120701040505 (Lower Brazos)' },
  { value: '120701040207', regionId: '08', label: '120701040207 (Lower Brazos)' },
  { value: '120701040208', regionId: '08', label: '120701040208 (Lower Brazos)' },
  { value: '120701040209', regionId: '08', label: '120701040209 (Lower Brazos)' },
  { value: '120701040210', regionId: '08', label: '120701040210 (Lower Brazos)' },
  { value: '120701040101', regionId: '08', label: '120701040101 (Lower Brazos)' },
  { value: '120701040102', regionId: '08', label: '120701040102 (Lower Brazos)' },
  { value: '120701040103', regionId: '08', label: '120701040103 (Lower Brazos)' },
  { value: '120701040104', regionId: '08', label: '120701040104 (Lower Brazos)' },
  { value: '120701040105', regionId: '08', label: '120701040105 (Lower Brazos)' },
  { value: '120701040106', regionId: '08', label: '120701040106 (Lower Brazos)' },
  { value: '120701040107', regionId: '08', label: '120701040107 (Lower Brazos)' },
  { value: '120701040108', regionId: '08', label: '120701040108 (Lower Brazos)' },
  { value: '120701040201', regionId: '08', label: '120701040201 (Lower Brazos)' },
  { value: '120701040202', regionId: '08', label: '120701040202 (Lower Brazos)' },
  { value: '120701040203', regionId: '08', label: '120701040203 (Lower Brazos)' },
  { value: '120701040204', regionId: '08', label: '120701040204 (Lower Brazos)' },
  { value: '120701040205', regionId: '08', label: '120701040205 (Lower Brazos)' },
  { value: '120701040206', regionId: '08', label: '120701040206 (Lower Brazos)' },
  { value: '120701040301', regionId: '08', label: '120701040301 (Lower Brazos)' },
  { value: '120701040302', regionId: '08', label: '120701040302 (Lower Brazos)' },
  { value: '120701040303', regionId: '08', label: '120701040303 (Lower Brazos)' },
  { value: '120701040304', regionId: '08', label: '120701040304 (Lower Brazos)' },
  { value: '120701040305', regionId: '08', label: '120701040305 (Lower Brazos)' },
  { value: '120701040306', regionId: '08', label: '120701040306 (Lower Brazos)' },
  { value: '120701040307', regionId: '08', label: '120701040307 (Lower Brazos)' },
  { value: '120701040308', regionId: '08', label: '120701040308 (Lower Brazos)' },
  { value: '120701040309', regionId: '08', label: '120701040309 (Lower Brazos)' },
  { value: '120702010101', regionId: '08', label: '120702010101 (Lower Brazos)' },
  { value: '120702010102', regionId: '08', label: '120702010102 (Lower Brazos)' },
  { value: '120702010103', regionId: '08', label: '120702010103 (Lower Brazos)' },
  { value: '120702010104', regionId: '08', label: '120702010104 (Lower Brazos)' },
  { value: '120702010105', regionId: '08', label: '120702010105 (Lower Brazos)' },
  { value: '120702010106', regionId: '08', label: '120702010106 (Lower Brazos)' },
  { value: '120702010107', regionId: '08', label: '120702010107 (Lower Brazos)' },
  { value: '120702010108', regionId: '08', label: '120702010108 (Lower Brazos)' },
  { value: '120702010201', regionId: '08', label: '120702010201 (Lower Brazos)' },
  { value: '120702010202', regionId: '08', label: '120702010202 (Lower Brazos)' },
  { value: '120702010203', regionId: '08', label: '120702010203 (Lower Brazos)' },
  { value: '120702010204', regionId: '08', label: '120702010204 (Lower Brazos)' },
  { value: '120702010205', regionId: '08', label: '120702010205 (Lower Brazos)' },
  { value: '120702010206', regionId: '08', label: '120702010206 (Lower Brazos)' },
  { value: '120702010207', regionId: '08', label: '120702010207 (Lower Brazos)' },
  { value: '120702010208', regionId: '08', label: '120702010208 (Lower Brazos)' },
  { value: '120702010209', regionId: '08', label: '120702010209 (Lower Brazos)' },
  { value: '120702010403', regionId: '08', label: '120702010403 (Lower Brazos)' },
  { value: '120702010605', regionId: '08', label: '120702010605 (Lower Brazos)' },
  { value: '120702010401', regionId: '08', label: '120702010401 (Lower Brazos)' },
  { value: '120702010301', regionId: '08', label: '120702010301 (Lower Brazos)' },
  { value: '120702010302', regionId: '08', label: '120702010302 (Lower Brazos)' },
  { value: '120702010303', regionId: '08', label: '120702010303 (Lower Brazos)' },
  { value: '120702010304', regionId: '08', label: '120702010304 (Lower Brazos)' },
  { value: '120702010305', regionId: '08', label: '120702010305 (Lower Brazos)' },
  { value: '120702010306', regionId: '08', label: '120702010306 (Lower Brazos)' },
  { value: '120702010307', regionId: '08', label: '120702010307 (Lower Brazos)' },
  { value: '120702010402', regionId: '08', label: '120702010402 (Lower Brazos)' },
  { value: '120702010404', regionId: '08', label: '120702010404 (Lower Brazos)' },
  { value: '120702010405', regionId: '08', label: '120702010405 (Lower Brazos)' },
  { value: '120702010406', regionId: '08', label: '120702010406 (Lower Brazos)' },
  { value: '120702010407', regionId: '08', label: '120702010407 (Lower Brazos)' },
  { value: '120702010408', regionId: '08', label: '120702010408 (Lower Brazos)' },
  { value: '120702010409', regionId: '08', label: '120702010409 (Lower Brazos)' },
  { value: '120702010501', regionId: '08', label: '120702010501 (Lower Brazos)' },
  { value: '120702010502', regionId: '08', label: '120702010502 (Lower Brazos)' },
  { value: '120702010503', regionId: '08', label: '120702010503 (Lower Brazos)' },
  { value: '120702010504', regionId: '08', label: '120702010504 (Lower Brazos)' },
  { value: '120702010505', regionId: '08', label: '120702010505 (Lower Brazos)' },
  { value: '120702010506', regionId: '08', label: '120702010506 (Lower Brazos)' },
  { value: '120702010507', regionId: '08', label: '120702010507 (Lower Brazos)' },
  { value: '120702010508', regionId: '08', label: '120702010508 (Lower Brazos)' },
  { value: '120702010509', regionId: '08', label: '120702010509 (Lower Brazos)' },
  { value: '120702010601', regionId: '08', label: '120702010601 (Lower Brazos)' },
  { value: '120702010602', regionId: '08', label: '120702010602 (Lower Brazos)' },
  { value: '120702010603', regionId: '08', label: '120702010603 (Lower Brazos)' },
  { value: '120702010604', regionId: '08', label: '120702010604 (Lower Brazos)' },
  { value: '120702010701', regionId: '08', label: '120702010701 (Lower Brazos)' },
  { value: '120702010702', regionId: '08', label: '120702010702 (Lower Brazos)' },
  { value: '120702010703', regionId: '08', label: '120702010703 (Lower Brazos)' },
  { value: '120702010803', regionId: '08', label: '120702010803 (Lower Brazos)' },
  { value: '120702010704', regionId: '08', label: '120702010704 (Lower Brazos)' },
  { value: '120702010705', regionId: '08', label: '120702010705 (Lower Brazos)' },
  { value: '120702010801', regionId: '08', label: '120702010801 (Lower Brazos)' },
  { value: '120702010802', regionId: '08', label: '120702010802 (Lower Brazos)' },
  { value: '120702010804', regionId: '08', label: '120702010804 (Lower Brazos)' },
  { value: '120702010805', regionId: '08', label: '120702010805 (Lower Brazos)' },
  { value: '120702010806', regionId: '08', label: '120702010806 (Lower Brazos)' },
  { value: '120702010901', regionId: '08', label: '120702010901 (Lower Brazos)' },
  { value: '120702010902', regionId: '08', label: '120702010902 (Lower Brazos)' },
  { value: '120702010903', regionId: '08', label: '120702010903 (Lower Brazos)' },
  { value: '120702010904', regionId: '08', label: '120702010904 (Lower Brazos)' },
  { value: '120702010905', regionId: '08', label: '120702010905 (Lower Brazos)' },
  { value: '120702010906', regionId: '08', label: '120702010906 (Lower Brazos)' },
  { value: '120702010907', regionId: '08', label: '120702010907 (Lower Brazos)' },
  { value: '120702010908', regionId: '08', label: '120702010908 (Lower Brazos)' },
  { value: '120702011001', regionId: '08', label: '120702011001 (Lower Brazos)' },
  { value: '120702011002', regionId: '08', label: '120702011002 (Lower Brazos)' },
  { value: '120702011003', regionId: '08', label: '120702011003 (Lower Brazos)' },
  { value: '120702011004', regionId: '08', label: '120702011004 (Lower Brazos)' },
  { value: '120702011005', regionId: '08', label: '120702011005 (Lower Brazos)' },
  { value: '120702011101', regionId: '08', label: '120702011101 (Lower Brazos)' },
  { value: '120702011102', regionId: '08', label: '120702011102 (Lower Brazos)' },
  { value: '120702011103', regionId: '08', label: '120702011103 (Lower Brazos)' },
  { value: '120702011104', regionId: '08', label: '120702011104 (Lower Brazos)' },
  { value: '120702011105', regionId: '08', label: '120702011105 (Lower Brazos)' },
  { value: '120702011106', regionId: '08', label: '120702011106 (Lower Brazos)' },
  { value: '120702020101', regionId: '08', label: '120702020101 (Lower Brazos)' },
  { value: '120702020102', regionId: '08', label: '120702020102 (Lower Brazos)' },
  { value: '120702020103', regionId: '08', label: '120702020103 (Lower Brazos)' },
  { value: '120702020104', regionId: '08', label: '120702020104 (Lower Brazos)' },
  { value: '120702020105', regionId: '08', label: '120702020105 (Lower Brazos)' },
  { value: '120702020106', regionId: '08', label: '120702020106 (Lower Brazos)' },
  { value: '120702020201', regionId: '08', label: '120702020201 (Lower Brazos)' },
  { value: '120702020202', regionId: '08', label: '120702020202 (Lower Brazos)' },
  { value: '120702020203', regionId: '08', label: '120702020203 (Lower Brazos)' },
  { value: '120702020204', regionId: '08', label: '120702020204 (Lower Brazos)' },
  { value: '120702020205', regionId: '08', label: '120702020205 (Lower Brazos)' },
  { value: '120702020301', regionId: '08', label: '120702020301 (Lower Brazos)' },
  { value: '120702020303', regionId: '08', label: '120702020303 (Lower Brazos)' },
  { value: '120702020302', regionId: '08', label: '120702020302 (Lower Brazos)' },
  { value: '120702020304', regionId: '08', label: '120702020304 (Lower Brazos)' },
  { value: '120702020305', regionId: '08', label: '120702020305 (Lower Brazos)' },
  { value: '120702020306', regionId: '08', label: '120702020306 (Lower Brazos)' },
  { value: '120702020307', regionId: '08', label: '120702020307 (Lower Brazos)' },
  { value: '120702030101', regionId: '08', label: '120702030101 (Lower Brazos)' },
  { value: '120702030102', regionId: '08', label: '120702030102 (Lower Brazos)' },
  { value: '120702030103', regionId: '08', label: '120702030103 (Lower Brazos)' },
  { value: '120702030104', regionId: '08', label: '120702030104 (Lower Brazos)' },
  { value: '120702030105', regionId: '08', label: '120702030105 (Lower Brazos)' },
  { value: '120702030106', regionId: '08', label: '120702030106 (Lower Brazos)' },
  { value: '120702030201', regionId: '08', label: '120702030201 (Lower Brazos)' },
  { value: '120702030202', regionId: '08', label: '120702030202 (Lower Brazos)' },
  { value: '120702030203', regionId: '08', label: '120702030203 (Lower Brazos)' },
  { value: '120702030204', regionId: '08', label: '120702030204 (Lower Brazos)' },
  { value: '120702030205', regionId: '08', label: '120702030205 (Lower Brazos)' },
  { value: '120702030301', regionId: '08', label: '120702030301 (Lower Brazos)' },
  { value: '120702030302', regionId: '08', label: '120702030302 (Lower Brazos)' },
  { value: '120702030303', regionId: '08', label: '120702030303 (Lower Brazos)' },
  { value: '120702030304', regionId: '08', label: '120702030304 (Lower Brazos)' },
  { value: '120702030305', regionId: '08', label: '120702030305 (Lower Brazos)' },
  { value: '120702030306', regionId: '08', label: '120702030306 (Lower Brazos)' },
  { value: '120702030307', regionId: '08', label: '120702030307 (Lower Brazos)' },
  { value: '120702030308', regionId: '08', label: '120702030308 (Lower Brazos)' },
  { value: '120702030309', regionId: '08', label: '120702030309 (Lower Brazos)' },
  { value: '120702030401', regionId: '08', label: '120702030401 (Lower Brazos)' },
  { value: '120702030402', regionId: '08', label: '120702030402 (Lower Brazos)' },
  { value: '120702030403', regionId: '08', label: '120702030403 (Lower Brazos)' },
  { value: '120702030404', regionId: '08', label: '120702030404 (Lower Brazos)' },
  { value: '120702030501', regionId: '08', label: '120702030501 (Lower Brazos)' },
  { value: '120702030502', regionId: '08', label: '120702030502 (Lower Brazos)' },
  { value: '120702030503', regionId: '08', label: '120702030503 (Lower Brazos)' },
  { value: '120702030504', regionId: '08', label: '120702030504 (Lower Brazos)' },
  { value: '120702030505', regionId: '08', label: '120702030505 (Lower Brazos)' },
  { value: '120702030506', regionId: '08', label: '120702030506 (Lower Brazos)' },
  { value: '120702030507', regionId: '08', label: '120702030507 (Lower Brazos)' },
  { value: '120702030508', regionId: '08', label: '120702030508 (Lower Brazos)' },
  { value: '120702030509', regionId: '08', label: '120702030509 (Lower Brazos)' },
  { value: '120702030510', regionId: '08', label: '120702030510 (Lower Brazos)' },
  { value: '120702030511', regionId: '08', label: '120702030511 (Lower Brazos)' },
  { value: '120702040101', regionId: '08', label: '120702040101 (Lower Brazos)' },
  { value: '120702040102', regionId: '08', label: '120702040102 (Lower Brazos)' },
  { value: '120702040103', regionId: '08', label: '120702040103 (Lower Brazos)' },
  { value: '120702040104', regionId: '08', label: '120702040104 (Lower Brazos)' },
  { value: '120702040105', regionId: '08', label: '120702040105 (Lower Brazos)' },
  { value: '120702040106', regionId: '08', label: '120702040106 (Lower Brazos)' },
  { value: '120702040107', regionId: '08', label: '120702040107 (Lower Brazos)' },
  { value: '120702040302', regionId: '08', label: '120702040302 (Lower Brazos)' },
  { value: '120702040108', regionId: '08', label: '120702040108 (Lower Brazos)' },
  { value: '120702040109', regionId: '08', label: '120702040109 (Lower Brazos)' },
  { value: '120702040110', regionId: '08', label: '120702040110 (Lower Brazos)' },
  { value: '120702040111', regionId: '08', label: '120702040111 (Lower Brazos)' },
  { value: '120702040201', regionId: '08', label: '120702040201 (Lower Brazos)' },
  { value: '120702040202', regionId: '08', label: '120702040202 (Lower Brazos)' },
  { value: '120702040203', regionId: '08', label: '120702040203 (Lower Brazos)' },
  { value: '120702040204', regionId: '08', label: '120702040204 (Lower Brazos)' },
  { value: '120702040205', regionId: '08', label: '120702040205 (Lower Brazos)' },
  { value: '120702040206', regionId: '08', label: '120702040206 (Lower Brazos)' },
  { value: '120702040207', regionId: '08', label: '120702040207 (Lower Brazos)' },
  { value: '120702040301', regionId: '08', label: '120702040301 (Lower Brazos)' },
  { value: '120702040303', regionId: '08', label: '120702040303 (Lower Brazos)' },
  { value: '120702040304', regionId: '08', label: '120702040304 (Lower Brazos)' },
  { value: '120702040305', regionId: '08', label: '120702040305 (Lower Brazos)' },
  { value: '120702040306', regionId: '08', label: '120702040306 (Lower Brazos)' },
  { value: '120702040307', regionId: '08', label: '120702040307 (Lower Brazos)' },
  { value: '120702040308', regionId: '08', label: '120702040308 (Lower Brazos)' },
  { value: '120702050507', regionId: '08', label: '120702050507 (Lower Brazos)' },
  { value: '120702050105', regionId: '08', label: '120702050105 (Lower Brazos)' },
  { value: '120702050106', regionId: '08', label: '120702050106 (Lower Brazos)' },
  { value: '120702050201', regionId: '08', label: '120702050201 (Lower Brazos)' },
  { value: '120702050202', regionId: '08', label: '120702050202 (Lower Brazos)' },
  { value: '120702050203', regionId: '08', label: '120702050203 (Lower Brazos)' },
  { value: '120702050301', regionId: '08', label: '120702050301 (Lower Brazos)' },
  { value: '120702050302', regionId: '08', label: '120702050302 (Lower Brazos)' },
  { value: '120702050303', regionId: '08', label: '120702050303 (Lower Brazos)' },
  { value: '120702050401', regionId: '08', label: '120702050401 (Lower Brazos)' },
  { value: '120702050404', regionId: '08', label: '120702050404 (Lower Brazos)' },
  { value: '120702050402', regionId: '08', label: '120702050402 (Lower Brazos)' },
  { value: '120702050403', regionId: '08', label: '120702050403 (Lower Brazos)' },
  { value: '120702050405', regionId: '08', label: '120702050405 (Lower Brazos)' },
  { value: '120702050406', regionId: '08', label: '120702050406 (Lower Brazos)' },
  { value: '120702050407', regionId: '08', label: '120702050407 (Lower Brazos)' },
  { value: '120702050408', regionId: '08', label: '120702050408 (Lower Brazos)' },
  { value: '120702050409', regionId: '08', label: '120702050409 (Lower Brazos)' },
  { value: '120702050410', regionId: '08', label: '120702050410 (Lower Brazos)' },
  { value: '120702050501', regionId: '08', label: '120702050501 (Lower Brazos)' },
  { value: '120702050502', regionId: '08', label: '120702050502 (Lower Brazos)' },
  { value: '120702050503', regionId: '08', label: '120702050503 (Lower Brazos)' },
  { value: '120702050504', regionId: '08', label: '120702050504 (Lower Brazos)' },
  { value: '120702050505', regionId: '08', label: '120702050505 (Lower Brazos)' },
  { value: '120702050506', regionId: '08', label: '120702050506 (Lower Brazos)' },
  { value: '120702050508', regionId: '08', label: '120702050508 (Lower Brazos)' },
  { value: '120702050101', regionId: '08', label: '120702050101 (Lower Brazos)' },
  { value: '120702050102', regionId: '08', label: '120702050102 (Lower Brazos)' },
  { value: '120702050103', regionId: '08', label: '120702050103 (Lower Brazos)' },
  { value: '120702050104', regionId: '08', label: '120702050104 (Lower Brazos)' },
  { value: '120901060604', regionId: '08', label: '120901060604 (Lower Brazos)' },
  { value: '120901060605', regionId: '08', label: '120901060605 (Lower Brazos)' },
  { value: '120901070203', regionId: '08', label: '120901070203 (Lower Brazos)' },
  { value: '120901070301', regionId: '08', label: '120901070301 (Lower Brazos)' },
  { value: '120901070303', regionId: '08', label: '120901070303 (Lower Brazos)' },
  { value: '120901070304', regionId: '08', label: '120901070304 (Lower Brazos)' },
  { value: '120901070401', regionId: '08', label: '120901070401 (Lower Brazos)' },
  { value: '120901070501', regionId: '08', label: '120901070501 (Lower Brazos)' },
  { value: '120901070502', regionId: '08', label: '120901070502 (Lower Brazos)' },
  { value: '120901070504', regionId: '08', label: '120901070504 (Lower Brazos)' },
  { value: '120901070602', regionId: '08', label: '120901070602 (Lower Brazos)' },
  { value: '120901070603', regionId: '08', label: '120901070603 (Lower Brazos)' },
  { value: '120901070604', regionId: '08', label: '120901070604 (Lower Brazos)' },
  { value: '120902010111', regionId: '08', label: '120902010111 (Lower Brazos)' },
  { value: '120902010202', regionId: '08', label: '120902010202 (Lower Brazos)' },
  { value: '120902010205', regionId: '08', label: '120902010205 (Lower Brazos)' },
  { value: '120902010101', regionId: '08', label: '120902010101 (Lower Brazos)' },
  { value: '120902010102', regionId: '08', label: '120902010102 (Lower Brazos)' },
  { value: '120902010103', regionId: '08', label: '120902010103 (Lower Brazos)' },
  { value: '120902010110', regionId: '08', label: '120902010110 (Lower Brazos)' },
  { value: '120902050105', regionId: '08', label: '120902050105 (Lower Brazos)' },
  { value: '120902050104', regionId: '08', label: '120902050104 (Lower Brazos)' },
  { value: '120902050203', regionId: '08', label: '120902050203 (Lower Brazos)' },
  { value: '120902050205', regionId: '08', label: '120902050205 (Lower Brazos)' },
  { value: '120902050206', regionId: '08', label: '120902050206 (Lower Brazos)' },
  { value: '120902050207', regionId: '08', label: '120902050207 (Lower Brazos)' },
  { value: '120902050302', regionId: '08', label: '120902050302 (Lower Brazos)' },
  { value: '120902050307', regionId: '08', label: '120902050307 (Lower Brazos)' },
  { value: '120903010103', regionId: '08', label: '120903010103 (Lower Brazos)' },
  { value: '120903010106', regionId: '08', label: '120903010106 (Lower Brazos)' },
  { value: '120903010201', regionId: '08', label: '120903010201 (Lower Brazos)' },
  { value: '120903010202', regionId: '08', label: '120903010202 (Lower Brazos)' },
  { value: '120903010205', regionId: '08', label: '120903010205 (Lower Brazos)' },
  { value: '120903010402', regionId: '08', label: '120903010402 (Lower Brazos)' },
  { value: '120903010501', regionId: '08', label: '120903010501 (Lower Brazos)' },
  { value: '120903010504', regionId: '08', label: '120903010504 (Lower Brazos)' },
  { value: '120903010101', regionId: '08', label: '120903010101 (Lower Brazos)' },
  { value: '120903010102', regionId: '08', label: '120903010102 (Lower Brazos)' },
  { value: '120903010104', regionId: '08', label: '120903010104 (Lower Brazos)' },
  { value: '120903010603', regionId: '08', label: '120903010603 (Lower Brazos)' },
  { value: '120903010605', regionId: '08', label: '120903010605 (Lower Brazos)' },
  { value: '120903010606', regionId: '08', label: '120903010606 (Lower Brazos)' },
  { value: '120903010607', regionId: '08', label: '120903010607 (Lower Brazos)' },
  { value: '120903010608', regionId: '08', label: '120903010608 (Lower Brazos)' },
  { value: '120903010601', regionId: '08', label: '120903010601 (Lower Brazos)' },
  { value: '120904010107', regionId: '08', label: '120904010107 (Lower Brazos)' },
  { value: '120904010102', regionId: '08', label: '120904010102 (Lower Brazos)' },
  { value: '120904010103', regionId: '08', label: '120904010103 (Lower Brazos)' },
  { value: '120904010109', regionId: '08', label: '120904010109 (Lower Brazos)' },
  { value: '120904010306', regionId: '08', label: '120904010306 (Lower Brazos)' },
  { value: '120904010203', regionId: '08', label: '120904010203 (Lower Brazos)' },
  { value: '120904010207', regionId: '08', label: '120904010207 (Lower Brazos)' },
  { value: '120904010301', regionId: '08', label: '120904010301 (Lower Brazos)' },
  { value: '120904010303', regionId: '08', label: '120904010303 (Lower Brazos)' },
  { value: '120904010305', regionId: '08', label: '120904010305 (Lower Brazos)' },
  { value: '120904010307', regionId: '08', label: '120904010307 (Lower Brazos)' },
  { value: '120904010308', regionId: '08', label: '120904010308 (Lower Brazos)' },
  { value: '120904020500', regionId: '08', label: '120904020500 (Lower Brazos)' },
]

const hucsRegion10 = [
  { value: '120601020703', regionId: '10', label: '120601020703 (Lower Colorado-Lavaca)' },
  { value: '120601020707', regionId: '10', label: '120601020707 (Lower Colorado-Lavaca)' },
  { value: '120601020801', regionId: '10', label: '120601020801 (Lower Colorado-Lavaca)' },
  { value: '120601020708', regionId: '10', label: '120601020708 (Lower Colorado-Lavaca)' },
  { value: '120601020709', regionId: '10', label: '120601020709 (Lower Colorado-Lavaca)' },
  { value: '120601020710', regionId: '10', label: '120601020710 (Lower Colorado-Lavaca)' },
  { value: '120601050101', regionId: '10', label: '120601050101 (Lower Colorado-Lavaca)' },
  { value: '120601050102', regionId: '10', label: '120601050102 (Lower Colorado-Lavaca)' },
  { value: '120601050103', regionId: '10', label: '120601050103 (Lower Colorado-Lavaca)' },
  { value: '120601050301', regionId: '10', label: '120601050301 (Lower Colorado-Lavaca)' },
  { value: '120601050401', regionId: '10', label: '120601050401 (Lower Colorado-Lavaca)' },
  { value: '120701020101', regionId: '10', label: '120701020101 (Lower Colorado-Lavaca)' },
  { value: '120701020102', regionId: '10', label: '120701020102 (Lower Colorado-Lavaca)' },
  { value: '120701020107', regionId: '10', label: '120701020107 (Lower Colorado-Lavaca)' },
  { value: '120701020108', regionId: '10', label: '120701020108 (Lower Colorado-Lavaca)' },
  { value: '120701020109', regionId: '10', label: '120701020109 (Lower Colorado-Lavaca)' },
  { value: '120701020302', regionId: '10', label: '120701020302 (Lower Colorado-Lavaca)' },
  { value: '120701020303', regionId: '10', label: '120701020303 (Lower Colorado-Lavaca)' },
  { value: '120701020304', regionId: '10', label: '120701020304 (Lower Colorado-Lavaca)' },
  { value: '120701040401', regionId: '10', label: '120701040401 (Lower Colorado-Lavaca)' },
  { value: '120701040404', regionId: '10', label: '120701040404 (Lower Colorado-Lavaca)' },
  { value: '120701040405', regionId: '10', label: '120701040405 (Lower Colorado-Lavaca)' },
  { value: '120701040406', regionId: '10', label: '120701040406 (Lower Colorado-Lavaca)' },
  { value: '120701040501', regionId: '10', label: '120701040501 (Lower Colorado-Lavaca)' },
  { value: '120701040502', regionId: '10', label: '120701040502 (Lower Colorado-Lavaca)' },
  { value: '120701040503', regionId: '10', label: '120701040503 (Lower Colorado-Lavaca)' },
  { value: '120701040504', regionId: '10', label: '120701040504 (Lower Colorado-Lavaca)' },
  { value: '120701040505', regionId: '10', label: '120701040505 (Lower Colorado-Lavaca)' },
  { value: '120701040207', regionId: '10', label: '120701040207 (Lower Colorado-Lavaca)' },
  { value: '120701040208', regionId: '10', label: '120701040208 (Lower Colorado-Lavaca)' },
  { value: '120701040209', regionId: '10', label: '120701040209 (Lower Colorado-Lavaca)' },
  { value: '120701040210', regionId: '10', label: '120701040210 (Lower Colorado-Lavaca)' },
  { value: '120701040205', regionId: '10', label: '120701040205 (Lower Colorado-Lavaca)' },
  { value: '120701040206', regionId: '10', label: '120701040206 (Lower Colorado-Lavaca)' },
  { value: '120701040304', regionId: '10', label: '120701040304 (Lower Colorado-Lavaca)' },
  { value: '120701040305', regionId: '10', label: '120701040305 (Lower Colorado-Lavaca)' },
  { value: '120701040306', regionId: '10', label: '120701040306 (Lower Colorado-Lavaca)' },
  { value: '120701040309', regionId: '10', label: '120701040309 (Lower Colorado-Lavaca)' },
  { value: '120702010401', regionId: '10', label: '120702010401 (Lower Colorado-Lavaca)' },
  { value: '120702010301', regionId: '10', label: '120702010301 (Lower Colorado-Lavaca)' },
  { value: '120702010302', regionId: '10', label: '120702010302 (Lower Colorado-Lavaca)' },
  { value: '120702010304', regionId: '10', label: '120702010304 (Lower Colorado-Lavaca)' },
  { value: '120702010305', regionId: '10', label: '120702010305 (Lower Colorado-Lavaca)' },
  { value: '120702010402', regionId: '10', label: '120702010402 (Lower Colorado-Lavaca)' },
  { value: '120702010504', regionId: '10', label: '120702010504 (Lower Colorado-Lavaca)' },
  { value: '120702010506', regionId: '10', label: '120702010506 (Lower Colorado-Lavaca)' },
  { value: '120702010507', regionId: '10', label: '120702010507 (Lower Colorado-Lavaca)' },
  { value: '120702020101', regionId: '10', label: '120702020101 (Lower Colorado-Lavaca)' },
  { value: '120702030101', regionId: '10', label: '120702030101 (Lower Colorado-Lavaca)' },
  { value: '120702030102', regionId: '10', label: '120702030102 (Lower Colorado-Lavaca)' },
  { value: '120702030201', regionId: '10', label: '120702030201 (Lower Colorado-Lavaca)' },
  { value: '120702030202', regionId: '10', label: '120702030202 (Lower Colorado-Lavaca)' },
  { value: '120702030204', regionId: '10', label: '120702030204 (Lower Colorado-Lavaca)' },
  { value: '120702030304', regionId: '10', label: '120702030304 (Lower Colorado-Lavaca)' },
  { value: '120702030305', regionId: '10', label: '120702030305 (Lower Colorado-Lavaca)' },
  { value: '120702030307', regionId: '10', label: '120702030307 (Lower Colorado-Lavaca)' },
  { value: '120702050201', regionId: '10', label: '120702050201 (Lower Colorado-Lavaca)' },
  { value: '120702050202', regionId: '10', label: '120702050202 (Lower Colorado-Lavaca)' },
  { value: '120702050203', regionId: '10', label: '120702050203 (Lower Colorado-Lavaca)' },
  { value: '120702050401', regionId: '10', label: '120702050401 (Lower Colorado-Lavaca)' },
  { value: '120702050404', regionId: '10', label: '120702050404 (Lower Colorado-Lavaca)' },
  { value: '120702050402', regionId: '10', label: '120702050402 (Lower Colorado-Lavaca)' },
  { value: '120702050403', regionId: '10', label: '120702050403 (Lower Colorado-Lavaca)' },
  { value: '120702050406', regionId: '10', label: '120702050406 (Lower Colorado-Lavaca)' },
  { value: '120702050407', regionId: '10', label: '120702050407 (Lower Colorado-Lavaca)' },
  { value: '120702050101', regionId: '10', label: '120702050101 (Lower Colorado-Lavaca)' },
  { value: '120702050102', regionId: '10', label: '120702050102 (Lower Colorado-Lavaca)' },
  { value: '120901010501', regionId: '10', label: '120901010501 (Lower Colorado-Lavaca)' },
  { value: '120901010301', regionId: '10', label: '120901010301 (Lower Colorado-Lavaca)' },
  { value: '120901010302', regionId: '10', label: '120901010302 (Lower Colorado-Lavaca)' },
  { value: '120901010303', regionId: '10', label: '120901010303 (Lower Colorado-Lavaca)' },
  { value: '120901010305', regionId: '10', label: '120901010305 (Lower Colorado-Lavaca)' },
  { value: '120901010308', regionId: '10', label: '120901010308 (Lower Colorado-Lavaca)' },
  { value: '120901010502', regionId: '10', label: '120901010502 (Lower Colorado-Lavaca)' },
  { value: '120901010503', regionId: '10', label: '120901010503 (Lower Colorado-Lavaca)' },
  { value: '120901010505', regionId: '10', label: '120901010505 (Lower Colorado-Lavaca)' },
  { value: '120901020402', regionId: '10', label: '120901020402 (Lower Colorado-Lavaca)' },
  { value: '120901020405', regionId: '10', label: '120901020405 (Lower Colorado-Lavaca)' },
  { value: '120901020406', regionId: '10', label: '120901020406 (Lower Colorado-Lavaca)' },
  { value: '120901020501', regionId: '10', label: '120901020501 (Lower Colorado-Lavaca)' },
  { value: '120901050302', regionId: '10', label: '120901050302 (Lower Colorado-Lavaca)' },
  { value: '120901050303', regionId: '10', label: '120901050303 (Lower Colorado-Lavaca)' },
  { value: '120901050304', regionId: '10', label: '120901050304 (Lower Colorado-Lavaca)' },
  { value: '120901050306', regionId: '10', label: '120901050306 (Lower Colorado-Lavaca)' },
  { value: '120901050405', regionId: '10', label: '120901050405 (Lower Colorado-Lavaca)' },
  { value: '120901050407', regionId: '10', label: '120901050407 (Lower Colorado-Lavaca)' },
  { value: '120901060203', regionId: '10', label: '120901060203 (Lower Colorado-Lavaca)' },
  { value: '120901060208', regionId: '10', label: '120901060208 (Lower Colorado-Lavaca)' },
  { value: '120901060505', regionId: '10', label: '120901060505 (Lower Colorado-Lavaca)' },
  { value: '120901060204', regionId: '10', label: '120901060204 (Lower Colorado-Lavaca)' },
  { value: '120901060205', regionId: '10', label: '120901060205 (Lower Colorado-Lavaca)' },
  { value: '120901060206', regionId: '10', label: '120901060206 (Lower Colorado-Lavaca)' },
  { value: '120901060207', regionId: '10', label: '120901060207 (Lower Colorado-Lavaca)' },
  { value: '120901060209', regionId: '10', label: '120901060209 (Lower Colorado-Lavaca)' },
  { value: '120901060301', regionId: '10', label: '120901060301 (Lower Colorado-Lavaca)' },
  { value: '120901060302', regionId: '10', label: '120901060302 (Lower Colorado-Lavaca)' },
  { value: '120901060303', regionId: '10', label: '120901060303 (Lower Colorado-Lavaca)' },
  { value: '120901060304', regionId: '10', label: '120901060304 (Lower Colorado-Lavaca)' },
  { value: '120901060305', regionId: '10', label: '120901060305 (Lower Colorado-Lavaca)' },
  { value: '120901060306', regionId: '10', label: '120901060306 (Lower Colorado-Lavaca)' },
  { value: '120901060307', regionId: '10', label: '120901060307 (Lower Colorado-Lavaca)' },
  { value: '120901060308', regionId: '10', label: '120901060308 (Lower Colorado-Lavaca)' },
  { value: '120901060309', regionId: '10', label: '120901060309 (Lower Colorado-Lavaca)' },
  { value: '120901060401', regionId: '10', label: '120901060401 (Lower Colorado-Lavaca)' },
  { value: '120901060402', regionId: '10', label: '120901060402 (Lower Colorado-Lavaca)' },
  { value: '120901060403', regionId: '10', label: '120901060403 (Lower Colorado-Lavaca)' },
  { value: '120901060404', regionId: '10', label: '120901060404 (Lower Colorado-Lavaca)' },
  { value: '120901060405', regionId: '10', label: '120901060405 (Lower Colorado-Lavaca)' },
  { value: '120901060406', regionId: '10', label: '120901060406 (Lower Colorado-Lavaca)' },
  { value: '120901060501', regionId: '10', label: '120901060501 (Lower Colorado-Lavaca)' },
  { value: '120901060502', regionId: '10', label: '120901060502 (Lower Colorado-Lavaca)' },
  { value: '120901060503', regionId: '10', label: '120901060503 (Lower Colorado-Lavaca)' },
  { value: '120901060504', regionId: '10', label: '120901060504 (Lower Colorado-Lavaca)' },
  { value: '120901060506', regionId: '10', label: '120901060506 (Lower Colorado-Lavaca)' },
  { value: '120901060507', regionId: '10', label: '120901060507 (Lower Colorado-Lavaca)' },
  { value: '120901060601', regionId: '10', label: '120901060601 (Lower Colorado-Lavaca)' },
  { value: '120901060602', regionId: '10', label: '120901060602 (Lower Colorado-Lavaca)' },
  { value: '120901060603', regionId: '10', label: '120901060603 (Lower Colorado-Lavaca)' },
  { value: '120901060604', regionId: '10', label: '120901060604 (Lower Colorado-Lavaca)' },
  { value: '120901060605', regionId: '10', label: '120901060605 (Lower Colorado-Lavaca)' },
  { value: '120901060201', regionId: '10', label: '120901060201 (Lower Colorado-Lavaca)' },
  { value: '120901060101', regionId: '10', label: '120901060101 (Lower Colorado-Lavaca)' },
  { value: '120901060102', regionId: '10', label: '120901060102 (Lower Colorado-Lavaca)' },
  { value: '120901060103', regionId: '10', label: '120901060103 (Lower Colorado-Lavaca)' },
  { value: '120901060104', regionId: '10', label: '120901060104 (Lower Colorado-Lavaca)' },
  { value: '120901060105', regionId: '10', label: '120901060105 (Lower Colorado-Lavaca)' },
  { value: '120901060106', regionId: '10', label: '120901060106 (Lower Colorado-Lavaca)' },
  { value: '120901060107', regionId: '10', label: '120901060107 (Lower Colorado-Lavaca)' },
  { value: '120901060108', regionId: '10', label: '120901060108 (Lower Colorado-Lavaca)' },
  { value: '120901060202', regionId: '10', label: '120901060202 (Lower Colorado-Lavaca)' },
  { value: '120901070204', regionId: '10', label: '120901070204 (Lower Colorado-Lavaca)' },
  { value: '120901070101', regionId: '10', label: '120901070101 (Lower Colorado-Lavaca)' },
  { value: '120901070102', regionId: '10', label: '120901070102 (Lower Colorado-Lavaca)' },
  { value: '120901070103', regionId: '10', label: '120901070103 (Lower Colorado-Lavaca)' },
  { value: '120901070104', regionId: '10', label: '120901070104 (Lower Colorado-Lavaca)' },
  { value: '120901070105', regionId: '10', label: '120901070105 (Lower Colorado-Lavaca)' },
  { value: '120901070106', regionId: '10', label: '120901070106 (Lower Colorado-Lavaca)' },
  { value: '120901070107', regionId: '10', label: '120901070107 (Lower Colorado-Lavaca)' },
  { value: '120901070108', regionId: '10', label: '120901070108 (Lower Colorado-Lavaca)' },
  { value: '120901070201', regionId: '10', label: '120901070201 (Lower Colorado-Lavaca)' },
  { value: '120901070202', regionId: '10', label: '120901070202 (Lower Colorado-Lavaca)' },
  { value: '120901070203', regionId: '10', label: '120901070203 (Lower Colorado-Lavaca)' },
  { value: '120901070205', regionId: '10', label: '120901070205 (Lower Colorado-Lavaca)' },
  { value: '120901070301', regionId: '10', label: '120901070301 (Lower Colorado-Lavaca)' },
  { value: '120901070302', regionId: '10', label: '120901070302 (Lower Colorado-Lavaca)' },
  { value: '120901070303', regionId: '10', label: '120901070303 (Lower Colorado-Lavaca)' },
  { value: '120901070304', regionId: '10', label: '120901070304 (Lower Colorado-Lavaca)' },
  { value: '120901070305', regionId: '10', label: '120901070305 (Lower Colorado-Lavaca)' },
  { value: '120901070401', regionId: '10', label: '120901070401 (Lower Colorado-Lavaca)' },
  { value: '120901070402', regionId: '10', label: '120901070402 (Lower Colorado-Lavaca)' },
  { value: '120901070403', regionId: '10', label: '120901070403 (Lower Colorado-Lavaca)' },
  { value: '120901070404', regionId: '10', label: '120901070404 (Lower Colorado-Lavaca)' },
  { value: '120901070405', regionId: '10', label: '120901070405 (Lower Colorado-Lavaca)' },
  { value: '120901070406', regionId: '10', label: '120901070406 (Lower Colorado-Lavaca)' },
  { value: '120901070407', regionId: '10', label: '120901070407 (Lower Colorado-Lavaca)' },
  { value: '120901070501', regionId: '10', label: '120901070501 (Lower Colorado-Lavaca)' },
  { value: '120901070502', regionId: '10', label: '120901070502 (Lower Colorado-Lavaca)' },
  { value: '120901070503', regionId: '10', label: '120901070503 (Lower Colorado-Lavaca)' },
  { value: '120901070504', regionId: '10', label: '120901070504 (Lower Colorado-Lavaca)' },
  { value: '120901070505', regionId: '10', label: '120901070505 (Lower Colorado-Lavaca)' },
  { value: '120901070601', regionId: '10', label: '120901070601 (Lower Colorado-Lavaca)' },
  { value: '120901070602', regionId: '10', label: '120901070602 (Lower Colorado-Lavaca)' },
  { value: '120901070603', regionId: '10', label: '120901070603 (Lower Colorado-Lavaca)' },
  { value: '120901070604', regionId: '10', label: '120901070604 (Lower Colorado-Lavaca)' },
  { value: '120901070605', regionId: '10', label: '120901070605 (Lower Colorado-Lavaca)' },
  { value: '120901080101', regionId: '10', label: '120901080101 (Lower Colorado-Lavaca)' },
  { value: '120901080102', regionId: '10', label: '120901080102 (Lower Colorado-Lavaca)' },
  { value: '120901080103', regionId: '10', label: '120901080103 (Lower Colorado-Lavaca)' },
  { value: '120901080104', regionId: '10', label: '120901080104 (Lower Colorado-Lavaca)' },
  { value: '120901080105', regionId: '10', label: '120901080105 (Lower Colorado-Lavaca)' },
  { value: '120901080106', regionId: '10', label: '120901080106 (Lower Colorado-Lavaca)' },
  { value: '120901080107', regionId: '10', label: '120901080107 (Lower Colorado-Lavaca)' },
  { value: '120901080108', regionId: '10', label: '120901080108 (Lower Colorado-Lavaca)' },
  { value: '120901080109', regionId: '10', label: '120901080109 (Lower Colorado-Lavaca)' },
  { value: '120901080110', regionId: '10', label: '120901080110 (Lower Colorado-Lavaca)' },
  { value: '120901080201', regionId: '10', label: '120901080201 (Lower Colorado-Lavaca)' },
  { value: '120901080202', regionId: '10', label: '120901080202 (Lower Colorado-Lavaca)' },
  { value: '120901080203', regionId: '10', label: '120901080203 (Lower Colorado-Lavaca)' },
  { value: '120901080204', regionId: '10', label: '120901080204 (Lower Colorado-Lavaca)' },
  { value: '120901080205', regionId: '10', label: '120901080205 (Lower Colorado-Lavaca)' },
  { value: '120901080206', regionId: '10', label: '120901080206 (Lower Colorado-Lavaca)' },
  { value: '120901080207', regionId: '10', label: '120901080207 (Lower Colorado-Lavaca)' },
  { value: '120901080208', regionId: '10', label: '120901080208 (Lower Colorado-Lavaca)' },
  { value: '120901080209', regionId: '10', label: '120901080209 (Lower Colorado-Lavaca)' },
  { value: '120901090101', regionId: '10', label: '120901090101 (Lower Colorado-Lavaca)' },
  { value: '120901090102', regionId: '10', label: '120901090102 (Lower Colorado-Lavaca)' },
  { value: '120901090103', regionId: '10', label: '120901090103 (Lower Colorado-Lavaca)' },
  { value: '120901090104', regionId: '10', label: '120901090104 (Lower Colorado-Lavaca)' },
  { value: '120901090105', regionId: '10', label: '120901090105 (Lower Colorado-Lavaca)' },
  { value: '120901090106', regionId: '10', label: '120901090106 (Lower Colorado-Lavaca)' },
  { value: '120901090107', regionId: '10', label: '120901090107 (Lower Colorado-Lavaca)' },
  { value: '120901090108', regionId: '10', label: '120901090108 (Lower Colorado-Lavaca)' },
  { value: '120901090201', regionId: '10', label: '120901090201 (Lower Colorado-Lavaca)' },
  { value: '120901090204', regionId: '10', label: '120901090204 (Lower Colorado-Lavaca)' },
  { value: '120901090202', regionId: '10', label: '120901090202 (Lower Colorado-Lavaca)' },
  { value: '120901090203', regionId: '10', label: '120901090203 (Lower Colorado-Lavaca)' },
  { value: '120901090205', regionId: '10', label: '120901090205 (Lower Colorado-Lavaca)' },
  { value: '120901090301', regionId: '10', label: '120901090301 (Lower Colorado-Lavaca)' },
  { value: '120901090302', regionId: '10', label: '120901090302 (Lower Colorado-Lavaca)' },
  { value: '120901090303', regionId: '10', label: '120901090303 (Lower Colorado-Lavaca)' },
  { value: '120901090304', regionId: '10', label: '120901090304 (Lower Colorado-Lavaca)' },
  { value: '120901090305', regionId: '10', label: '120901090305 (Lower Colorado-Lavaca)' },
  { value: '120901090306', regionId: '10', label: '120901090306 (Lower Colorado-Lavaca)' },
  { value: '120901090307', regionId: '10', label: '120901090307 (Lower Colorado-Lavaca)' },
  { value: '120901090405', regionId: '10', label: '120901090405 (Lower Colorado-Lavaca)' },
  { value: '120901090406', regionId: '10', label: '120901090406 (Lower Colorado-Lavaca)' },
  { value: '120901090503', regionId: '10', label: '120901090503 (Lower Colorado-Lavaca)' },
  { value: '120901090401', regionId: '10', label: '120901090401 (Lower Colorado-Lavaca)' },
  { value: '120901090402', regionId: '10', label: '120901090402 (Lower Colorado-Lavaca)' },
  { value: '120901090403', regionId: '10', label: '120901090403 (Lower Colorado-Lavaca)' },
  { value: '120901090404', regionId: '10', label: '120901090404 (Lower Colorado-Lavaca)' },
  { value: '120901090407', regionId: '10', label: '120901090407 (Lower Colorado-Lavaca)' },
  { value: '120901090408', regionId: '10', label: '120901090408 (Lower Colorado-Lavaca)' },
  { value: '120901090501', regionId: '10', label: '120901090501 (Lower Colorado-Lavaca)' },
  { value: '120901090502', regionId: '10', label: '120901090502 (Lower Colorado-Lavaca)' },
  { value: '120901090504', regionId: '10', label: '120901090504 (Lower Colorado-Lavaca)' },
  { value: '120901090604', regionId: '10', label: '120901090604 (Lower Colorado-Lavaca)' },
  { value: '120901090605', regionId: '10', label: '120901090605 (Lower Colorado-Lavaca)' },
  { value: '120901090701', regionId: '10', label: '120901090701 (Lower Colorado-Lavaca)' },
  { value: '120901090702', regionId: '10', label: '120901090702 (Lower Colorado-Lavaca)' },
  { value: '120901090505', regionId: '10', label: '120901090505 (Lower Colorado-Lavaca)' },
  { value: '120901090506', regionId: '10', label: '120901090506 (Lower Colorado-Lavaca)' },
  { value: '120901090601', regionId: '10', label: '120901090601 (Lower Colorado-Lavaca)' },
  { value: '120901090602', regionId: '10', label: '120901090602 (Lower Colorado-Lavaca)' },
  { value: '120901090603', regionId: '10', label: '120901090603 (Lower Colorado-Lavaca)' },
  { value: '120901090703', regionId: '10', label: '120901090703 (Lower Colorado-Lavaca)' },
  { value: '120901090704', regionId: '10', label: '120901090704 (Lower Colorado-Lavaca)' },
  { value: '120901090705', regionId: '10', label: '120901090705 (Lower Colorado-Lavaca)' },
  { value: '120901090706', regionId: '10', label: '120901090706 (Lower Colorado-Lavaca)' },
  { value: '120901090707', regionId: '10', label: '120901090707 (Lower Colorado-Lavaca)' },
  { value: '120901090708', regionId: '10', label: '120901090708 (Lower Colorado-Lavaca)' },
  { value: '120901090709', regionId: '10', label: '120901090709 (Lower Colorado-Lavaca)' },
  { value: '120901090801', regionId: '10', label: '120901090801 (Lower Colorado-Lavaca)' },
  { value: '120901090802', regionId: '10', label: '120901090802 (Lower Colorado-Lavaca)' },
  { value: '120901090803', regionId: '10', label: '120901090803 (Lower Colorado-Lavaca)' },
  { value: '120901090804', regionId: '10', label: '120901090804 (Lower Colorado-Lavaca)' },
  { value: '120901090805', regionId: '10', label: '120901090805 (Lower Colorado-Lavaca)' },
  { value: '120901090806', regionId: '10', label: '120901090806 (Lower Colorado-Lavaca)' },
  { value: '120901090807', regionId: '10', label: '120901090807 (Lower Colorado-Lavaca)' },
  { value: '120901090808', regionId: '10', label: '120901090808 (Lower Colorado-Lavaca)' },
  { value: '120901100101', regionId: '10', label: '120901100101 (Lower Colorado-Lavaca)' },
  { value: '120901100105', regionId: '10', label: '120901100105 (Lower Colorado-Lavaca)' },
  { value: '120901100102', regionId: '10', label: '120901100102 (Lower Colorado-Lavaca)' },
  { value: '120901100103', regionId: '10', label: '120901100103 (Lower Colorado-Lavaca)' },
  { value: '120901100104', regionId: '10', label: '120901100104 (Lower Colorado-Lavaca)' },
  { value: '120901100106', regionId: '10', label: '120901100106 (Lower Colorado-Lavaca)' },
  { value: '120901100107', regionId: '10', label: '120901100107 (Lower Colorado-Lavaca)' },
  { value: '120901100201', regionId: '10', label: '120901100201 (Lower Colorado-Lavaca)' },
  { value: '120901100108', regionId: '10', label: '120901100108 (Lower Colorado-Lavaca)' },
  { value: '120901100202', regionId: '10', label: '120901100202 (Lower Colorado-Lavaca)' },
  { value: '120901100203', regionId: '10', label: '120901100203 (Lower Colorado-Lavaca)' },
  { value: '120901100204', regionId: '10', label: '120901100204 (Lower Colorado-Lavaca)' },
  { value: '120901100205', regionId: '10', label: '120901100205 (Lower Colorado-Lavaca)' },
  { value: '120901100206', regionId: '10', label: '120901100206 (Lower Colorado-Lavaca)' },
  { value: '120901100207', regionId: '10', label: '120901100207 (Lower Colorado-Lavaca)' },
  { value: '120901100208', regionId: '10', label: '120901100208 (Lower Colorado-Lavaca)' },
  { value: '120901100209', regionId: '10', label: '120901100209 (Lower Colorado-Lavaca)' },
  { value: '120901100210', regionId: '10', label: '120901100210 (Lower Colorado-Lavaca)' },
  { value: '120902010111', regionId: '10', label: '120902010111 (Lower Colorado-Lavaca)' },
  { value: '120902010201', regionId: '10', label: '120902010201 (Lower Colorado-Lavaca)' },
  { value: '120902010202', regionId: '10', label: '120902010202 (Lower Colorado-Lavaca)' },
  { value: '120902010203', regionId: '10', label: '120902010203 (Lower Colorado-Lavaca)' },
  { value: '120902010204', regionId: '10', label: '120902010204 (Lower Colorado-Lavaca)' },
  { value: '120902010205', regionId: '10', label: '120902010205 (Lower Colorado-Lavaca)' },
  { value: '120902010206', regionId: '10', label: '120902010206 (Lower Colorado-Lavaca)' },
  { value: '120902010301', regionId: '10', label: '120902010301 (Lower Colorado-Lavaca)' },
  { value: '120902010302', regionId: '10', label: '120902010302 (Lower Colorado-Lavaca)' },
  { value: '120902010303', regionId: '10', label: '120902010303 (Lower Colorado-Lavaca)' },
  { value: '120902010304', regionId: '10', label: '120902010304 (Lower Colorado-Lavaca)' },
  { value: '120902010305', regionId: '10', label: '120902010305 (Lower Colorado-Lavaca)' },
  { value: '120902010306', regionId: '10', label: '120902010306 (Lower Colorado-Lavaca)' },
  { value: '120902010307', regionId: '10', label: '120902010307 (Lower Colorado-Lavaca)' },
  { value: '120902010308', regionId: '10', label: '120902010308 (Lower Colorado-Lavaca)' },
  { value: '120902010309', regionId: '10', label: '120902010309 (Lower Colorado-Lavaca)' },
  { value: '120902010310', regionId: '10', label: '120902010310 (Lower Colorado-Lavaca)' },
  { value: '120902010401', regionId: '10', label: '120902010401 (Lower Colorado-Lavaca)' },
  { value: '120902010402', regionId: '10', label: '120902010402 (Lower Colorado-Lavaca)' },
  { value: '120902010403', regionId: '10', label: '120902010403 (Lower Colorado-Lavaca)' },
  { value: '120902010404', regionId: '10', label: '120902010404 (Lower Colorado-Lavaca)' },
  { value: '120902010405', regionId: '10', label: '120902010405 (Lower Colorado-Lavaca)' },
  { value: '120902010406', regionId: '10', label: '120902010406 (Lower Colorado-Lavaca)' },
  { value: '120902010101', regionId: '10', label: '120902010101 (Lower Colorado-Lavaca)' },
  { value: '120902010102', regionId: '10', label: '120902010102 (Lower Colorado-Lavaca)' },
  { value: '120902010103', regionId: '10', label: '120902010103 (Lower Colorado-Lavaca)' },
  { value: '120902010104', regionId: '10', label: '120902010104 (Lower Colorado-Lavaca)' },
  { value: '120902010105', regionId: '10', label: '120902010105 (Lower Colorado-Lavaca)' },
  { value: '120902010106', regionId: '10', label: '120902010106 (Lower Colorado-Lavaca)' },
  { value: '120902010107', regionId: '10', label: '120902010107 (Lower Colorado-Lavaca)' },
  { value: '120902010108', regionId: '10', label: '120902010108 (Lower Colorado-Lavaca)' },
  { value: '120902010109', regionId: '10', label: '120902010109 (Lower Colorado-Lavaca)' },
  { value: '120902010110', regionId: '10', label: '120902010110 (Lower Colorado-Lavaca)' },
  { value: '120902020106', regionId: '10', label: '120902020106 (Lower Colorado-Lavaca)' },
  { value: '120902020108', regionId: '10', label: '120902020108 (Lower Colorado-Lavaca)' },
  { value: '120902020101', regionId: '10', label: '120902020101 (Lower Colorado-Lavaca)' },
  { value: '120902020102', regionId: '10', label: '120902020102 (Lower Colorado-Lavaca)' },
  { value: '120902020103', regionId: '10', label: '120902020103 (Lower Colorado-Lavaca)' },
  { value: '120902020104', regionId: '10', label: '120902020104 (Lower Colorado-Lavaca)' },
  { value: '120902020105', regionId: '10', label: '120902020105 (Lower Colorado-Lavaca)' },
  { value: '120902020107', regionId: '10', label: '120902020107 (Lower Colorado-Lavaca)' },
  { value: '120902020109', regionId: '10', label: '120902020109 (Lower Colorado-Lavaca)' },
  { value: '120902020201', regionId: '10', label: '120902020201 (Lower Colorado-Lavaca)' },
  { value: '120902020202', regionId: '10', label: '120902020202 (Lower Colorado-Lavaca)' },
  { value: '120902020203', regionId: '10', label: '120902020203 (Lower Colorado-Lavaca)' },
  { value: '120902020204', regionId: '10', label: '120902020204 (Lower Colorado-Lavaca)' },
  { value: '120902020205', regionId: '10', label: '120902020205 (Lower Colorado-Lavaca)' },
  { value: '120902020206', regionId: '10', label: '120902020206 (Lower Colorado-Lavaca)' },
  { value: '120902020207', regionId: '10', label: '120902020207 (Lower Colorado-Lavaca)' },
  { value: '120902020208', regionId: '10', label: '120902020208 (Lower Colorado-Lavaca)' },
  { value: '120902020301', regionId: '10', label: '120902020301 (Lower Colorado-Lavaca)' },
  { value: '120902020302', regionId: '10', label: '120902020302 (Lower Colorado-Lavaca)' },
  { value: '120902020303', regionId: '10', label: '120902020303 (Lower Colorado-Lavaca)' },
  { value: '120902020304', regionId: '10', label: '120902020304 (Lower Colorado-Lavaca)' },
  { value: '120902020305', regionId: '10', label: '120902020305 (Lower Colorado-Lavaca)' },
  { value: '120902020306', regionId: '10', label: '120902020306 (Lower Colorado-Lavaca)' },
  { value: '120902040306', regionId: '10', label: '120902040306 (Lower Colorado-Lavaca)' },
  { value: '120902040105', regionId: '10', label: '120902040105 (Lower Colorado-Lavaca)' },
  { value: '120902040101', regionId: '10', label: '120902040101 (Lower Colorado-Lavaca)' },
  { value: '120902040102', regionId: '10', label: '120902040102 (Lower Colorado-Lavaca)' },
  { value: '120902040103', regionId: '10', label: '120902040103 (Lower Colorado-Lavaca)' },
  { value: '120902040104', regionId: '10', label: '120902040104 (Lower Colorado-Lavaca)' },
  { value: '120902040106', regionId: '10', label: '120902040106 (Lower Colorado-Lavaca)' },
  { value: '120902040107', regionId: '10', label: '120902040107 (Lower Colorado-Lavaca)' },
  { value: '120902040108', regionId: '10', label: '120902040108 (Lower Colorado-Lavaca)' },
  { value: '120902040201', regionId: '10', label: '120902040201 (Lower Colorado-Lavaca)' },
  { value: '120902040202', regionId: '10', label: '120902040202 (Lower Colorado-Lavaca)' },
  { value: '120902040203', regionId: '10', label: '120902040203 (Lower Colorado-Lavaca)' },
  { value: '120902040204', regionId: '10', label: '120902040204 (Lower Colorado-Lavaca)' },
  { value: '120902040205', regionId: '10', label: '120902040205 (Lower Colorado-Lavaca)' },
  { value: '120902040206', regionId: '10', label: '120902040206 (Lower Colorado-Lavaca)' },
  { value: '120902040207', regionId: '10', label: '120902040207 (Lower Colorado-Lavaca)' },
  { value: '120902040401', regionId: '10', label: '120902040401 (Lower Colorado-Lavaca)' },
  { value: '120902040301', regionId: '10', label: '120902040301 (Lower Colorado-Lavaca)' },
  { value: '120902040302', regionId: '10', label: '120902040302 (Lower Colorado-Lavaca)' },
  { value: '120902040303', regionId: '10', label: '120902040303 (Lower Colorado-Lavaca)' },
  { value: '120902040304', regionId: '10', label: '120902040304 (Lower Colorado-Lavaca)' },
  { value: '120902040305', regionId: '10', label: '120902040305 (Lower Colorado-Lavaca)' },
  { value: '120902040402', regionId: '10', label: '120902040402 (Lower Colorado-Lavaca)' },
  { value: '120902040403', regionId: '10', label: '120902040403 (Lower Colorado-Lavaca)' },
  { value: '120902040404', regionId: '10', label: '120902040404 (Lower Colorado-Lavaca)' },
  { value: '120902040405', regionId: '10', label: '120902040405 (Lower Colorado-Lavaca)' },
  { value: '120902040406', regionId: '10', label: '120902040406 (Lower Colorado-Lavaca)' },
  { value: '120902040407', regionId: '10', label: '120902040407 (Lower Colorado-Lavaca)' },
  { value: '120902040408', regionId: '10', label: '120902040408 (Lower Colorado-Lavaca)' },
  { value: '120902040409', regionId: '10', label: '120902040409 (Lower Colorado-Lavaca)' },
  { value: '120902040410', regionId: '10', label: '120902040410 (Lower Colorado-Lavaca)' },
  { value: '120902040501', regionId: '10', label: '120902040501 (Lower Colorado-Lavaca)' },
  { value: '120902040502', regionId: '10', label: '120902040502 (Lower Colorado-Lavaca)' },
  { value: '120902040503', regionId: '10', label: '120902040503 (Lower Colorado-Lavaca)' },
  { value: '120902040504', regionId: '10', label: '120902040504 (Lower Colorado-Lavaca)' },
  { value: '120902040505', regionId: '10', label: '120902040505 (Lower Colorado-Lavaca)' },
  { value: '120902040506', regionId: '10', label: '120902040506 (Lower Colorado-Lavaca)' },
  { value: '120902040507', regionId: '10', label: '120902040507 (Lower Colorado-Lavaca)' },
  { value: '120902040508', regionId: '10', label: '120902040508 (Lower Colorado-Lavaca)' },
  { value: '120902040509', regionId: '10', label: '120902040509 (Lower Colorado-Lavaca)' },
  { value: '120902040510', regionId: '10', label: '120902040510 (Lower Colorado-Lavaca)' },
  { value: '120902040601', regionId: '10', label: '120902040601 (Lower Colorado-Lavaca)' },
  { value: '120902040602', regionId: '10', label: '120902040602 (Lower Colorado-Lavaca)' },
  { value: '120902040603', regionId: '10', label: '120902040603 (Lower Colorado-Lavaca)' },
  { value: '120902040604', regionId: '10', label: '120902040604 (Lower Colorado-Lavaca)' },
  { value: '120902040605', regionId: '10', label: '120902040605 (Lower Colorado-Lavaca)' },
  { value: '120902040606', regionId: '10', label: '120902040606 (Lower Colorado-Lavaca)' },
  { value: '120902040607', regionId: '10', label: '120902040607 (Lower Colorado-Lavaca)' },
  { value: '120902040608', regionId: '10', label: '120902040608 (Lower Colorado-Lavaca)' },
  { value: '120902040609', regionId: '10', label: '120902040609 (Lower Colorado-Lavaca)' },
  { value: '120902040610', regionId: '10', label: '120902040610 (Lower Colorado-Lavaca)' },
  { value: '120902040701', regionId: '10', label: '120902040701 (Lower Colorado-Lavaca)' },
  { value: '120902040702', regionId: '10', label: '120902040702 (Lower Colorado-Lavaca)' },
  { value: '120902040703', regionId: '10', label: '120902040703 (Lower Colorado-Lavaca)' },
  { value: '120902040704', regionId: '10', label: '120902040704 (Lower Colorado-Lavaca)' },
  { value: '120902040705', regionId: '10', label: '120902040705 (Lower Colorado-Lavaca)' },
  { value: '120902040706', regionId: '10', label: '120902040706 (Lower Colorado-Lavaca)' },
  { value: '120902040707', regionId: '10', label: '120902040707 (Lower Colorado-Lavaca)' },
  { value: '120902040708', regionId: '10', label: '120902040708 (Lower Colorado-Lavaca)' },
  { value: '120902040801', regionId: '10', label: '120902040801 (Lower Colorado-Lavaca)' },
  { value: '120902040802', regionId: '10', label: '120902040802 (Lower Colorado-Lavaca)' },
  { value: '120902040803', regionId: '10', label: '120902040803 (Lower Colorado-Lavaca)' },
  { value: '120902040804', regionId: '10', label: '120902040804 (Lower Colorado-Lavaca)' },
  { value: '120902040805', regionId: '10', label: '120902040805 (Lower Colorado-Lavaca)' },
  { value: '120902050105', regionId: '10', label: '120902050105 (Lower Colorado-Lavaca)' },
  { value: '120902050101', regionId: '10', label: '120902050101 (Lower Colorado-Lavaca)' },
  { value: '120902050102', regionId: '10', label: '120902050102 (Lower Colorado-Lavaca)' },
  { value: '120902050103', regionId: '10', label: '120902050103 (Lower Colorado-Lavaca)' },
  { value: '120902050104', regionId: '10', label: '120902050104 (Lower Colorado-Lavaca)' },
  { value: '120902050106', regionId: '10', label: '120902050106 (Lower Colorado-Lavaca)' },
  { value: '120902050107', regionId: '10', label: '120902050107 (Lower Colorado-Lavaca)' },
  { value: '120902050108', regionId: '10', label: '120902050108 (Lower Colorado-Lavaca)' },
  { value: '120902050201', regionId: '10', label: '120902050201 (Lower Colorado-Lavaca)' },
  { value: '120902050202', regionId: '10', label: '120902050202 (Lower Colorado-Lavaca)' },
  { value: '120902050203', regionId: '10', label: '120902050203 (Lower Colorado-Lavaca)' },
  { value: '120902050204', regionId: '10', label: '120902050204 (Lower Colorado-Lavaca)' },
  { value: '120902050205', regionId: '10', label: '120902050205 (Lower Colorado-Lavaca)' },
  { value: '120902050206', regionId: '10', label: '120902050206 (Lower Colorado-Lavaca)' },
  { value: '120902050207', regionId: '10', label: '120902050207 (Lower Colorado-Lavaca)' },
  { value: '120902050301', regionId: '10', label: '120902050301 (Lower Colorado-Lavaca)' },
  { value: '120902050302', regionId: '10', label: '120902050302 (Lower Colorado-Lavaca)' },
  { value: '120902050303', regionId: '10', label: '120902050303 (Lower Colorado-Lavaca)' },
  { value: '120902050304', regionId: '10', label: '120902050304 (Lower Colorado-Lavaca)' },
  { value: '120902050305', regionId: '10', label: '120902050305 (Lower Colorado-Lavaca)' },
  { value: '120902050306', regionId: '10', label: '120902050306 (Lower Colorado-Lavaca)' },
  { value: '120902050307', regionId: '10', label: '120902050307 (Lower Colorado-Lavaca)' },
  { value: '120902050401', regionId: '10', label: '120902050401 (Lower Colorado-Lavaca)' },
  { value: '120902050402', regionId: '10', label: '120902050402 (Lower Colorado-Lavaca)' },
  { value: '120902050403', regionId: '10', label: '120902050403 (Lower Colorado-Lavaca)' },
  { value: '120902050404', regionId: '10', label: '120902050404 (Lower Colorado-Lavaca)' },
  { value: '120902050405', regionId: '10', label: '120902050405 (Lower Colorado-Lavaca)' },
  { value: '120902050406', regionId: '10', label: '120902050406 (Lower Colorado-Lavaca)' },
  { value: '120902050407', regionId: '10', label: '120902050407 (Lower Colorado-Lavaca)' },
  { value: '120902050408', regionId: '10', label: '120902050408 (Lower Colorado-Lavaca)' },
  { value: '120902050409', regionId: '10', label: '120902050409 (Lower Colorado-Lavaca)' },
  { value: '120902060103', regionId: '10', label: '120902060103 (Lower Colorado-Lavaca)' },
  { value: '120902060104', regionId: '10', label: '120902060104 (Lower Colorado-Lavaca)' },
  { value: '120902060105', regionId: '10', label: '120902060105 (Lower Colorado-Lavaca)' },
  { value: '120902060106', regionId: '10', label: '120902060106 (Lower Colorado-Lavaca)' },
  { value: '120902060107', regionId: '10', label: '120902060107 (Lower Colorado-Lavaca)' },
  { value: '120902060108', regionId: '10', label: '120902060108 (Lower Colorado-Lavaca)' },
  { value: '120902060109', regionId: '10', label: '120902060109 (Lower Colorado-Lavaca)' },
  { value: '120902060110', regionId: '10', label: '120902060110 (Lower Colorado-Lavaca)' },
  { value: '120902060201', regionId: '10', label: '120902060201 (Lower Colorado-Lavaca)' },
  { value: '120902060202', regionId: '10', label: '120902060202 (Lower Colorado-Lavaca)' },
  { value: '120902060203', regionId: '10', label: '120902060203 (Lower Colorado-Lavaca)' },
  { value: '120902060204', regionId: '10', label: '120902060204 (Lower Colorado-Lavaca)' },
  { value: '120902060205', regionId: '10', label: '120902060205 (Lower Colorado-Lavaca)' },
  { value: '120902060206', regionId: '10', label: '120902060206 (Lower Colorado-Lavaca)' },
  { value: '120902060207', regionId: '10', label: '120902060207 (Lower Colorado-Lavaca)' },
  { value: '120902060208', regionId: '10', label: '120902060208 (Lower Colorado-Lavaca)' },
  { value: '120902060209', regionId: '10', label: '120902060209 (Lower Colorado-Lavaca)' },
  { value: '120902060210', regionId: '10', label: '120902060210 (Lower Colorado-Lavaca)' },
  { value: '120902060301', regionId: '10', label: '120902060301 (Lower Colorado-Lavaca)' },
  { value: '120902060302', regionId: '10', label: '120902060302 (Lower Colorado-Lavaca)' },
  { value: '120902060303', regionId: '10', label: '120902060303 (Lower Colorado-Lavaca)' },
  { value: '120902060304', regionId: '10', label: '120902060304 (Lower Colorado-Lavaca)' },
  { value: '120902060305', regionId: '10', label: '120902060305 (Lower Colorado-Lavaca)' },
  { value: '120902060306', regionId: '10', label: '120902060306 (Lower Colorado-Lavaca)' },
  { value: '120902060307', regionId: '10', label: '120902060307 (Lower Colorado-Lavaca)' },
  { value: '120902060308', regionId: '10', label: '120902060308 (Lower Colorado-Lavaca)' },
  { value: '120902060309', regionId: '10', label: '120902060309 (Lower Colorado-Lavaca)' },
  { value: '120902060310', regionId: '10', label: '120902060310 (Lower Colorado-Lavaca)' },
  { value: '120902060101', regionId: '10', label: '120902060101 (Lower Colorado-Lavaca)' },
  { value: '120902060102', regionId: '10', label: '120902060102 (Lower Colorado-Lavaca)' },
  { value: '120903010103', regionId: '10', label: '120903010103 (Lower Colorado-Lavaca)' },
  { value: '120903010105', regionId: '10', label: '120903010105 (Lower Colorado-Lavaca)' },
  { value: '120903010106', regionId: '10', label: '120903010106 (Lower Colorado-Lavaca)' },
  { value: '120903010107', regionId: '10', label: '120903010107 (Lower Colorado-Lavaca)' },
  { value: '120903010108', regionId: '10', label: '120903010108 (Lower Colorado-Lavaca)' },
  { value: '120903010109', regionId: '10', label: '120903010109 (Lower Colorado-Lavaca)' },
  { value: '120903010110', regionId: '10', label: '120903010110 (Lower Colorado-Lavaca)' },
  { value: '120903010201', regionId: '10', label: '120903010201 (Lower Colorado-Lavaca)' },
  { value: '120903010202', regionId: '10', label: '120903010202 (Lower Colorado-Lavaca)' },
  { value: '120903010203', regionId: '10', label: '120903010203 (Lower Colorado-Lavaca)' },
  { value: '120903010204', regionId: '10', label: '120903010204 (Lower Colorado-Lavaca)' },
  { value: '120903010205', regionId: '10', label: '120903010205 (Lower Colorado-Lavaca)' },
  { value: '120903010206', regionId: '10', label: '120903010206 (Lower Colorado-Lavaca)' },
  { value: '120903010301', regionId: '10', label: '120903010301 (Lower Colorado-Lavaca)' },
  { value: '120903010302', regionId: '10', label: '120903010302 (Lower Colorado-Lavaca)' },
  { value: '120903010303', regionId: '10', label: '120903010303 (Lower Colorado-Lavaca)' },
  { value: '120903010304', regionId: '10', label: '120903010304 (Lower Colorado-Lavaca)' },
  { value: '120903010305', regionId: '10', label: '120903010305 (Lower Colorado-Lavaca)' },
  { value: '120903010306', regionId: '10', label: '120903010306 (Lower Colorado-Lavaca)' },
  { value: '120903010307', regionId: '10', label: '120903010307 (Lower Colorado-Lavaca)' },
  { value: '120903010308', regionId: '10', label: '120903010308 (Lower Colorado-Lavaca)' },
  { value: '120903010309', regionId: '10', label: '120903010309 (Lower Colorado-Lavaca)' },
  { value: '120903010401', regionId: '10', label: '120903010401 (Lower Colorado-Lavaca)' },
  { value: '120903010402', regionId: '10', label: '120903010402 (Lower Colorado-Lavaca)' },
  { value: '120903010403', regionId: '10', label: '120903010403 (Lower Colorado-Lavaca)' },
  { value: '120903010404', regionId: '10', label: '120903010404 (Lower Colorado-Lavaca)' },
  { value: '120903010501', regionId: '10', label: '120903010501 (Lower Colorado-Lavaca)' },
  { value: '120903010502', regionId: '10', label: '120903010502 (Lower Colorado-Lavaca)' },
  { value: '120903010503', regionId: '10', label: '120903010503 (Lower Colorado-Lavaca)' },
  { value: '120903010504', regionId: '10', label: '120903010504 (Lower Colorado-Lavaca)' },
  { value: '120903010505', regionId: '10', label: '120903010505 (Lower Colorado-Lavaca)' },
  { value: '120903010506', regionId: '10', label: '120903010506 (Lower Colorado-Lavaca)' },
  { value: '120903010101', regionId: '10', label: '120903010101 (Lower Colorado-Lavaca)' },
  { value: '120903010102', regionId: '10', label: '120903010102 (Lower Colorado-Lavaca)' },
  { value: '120903010104', regionId: '10', label: '120903010104 (Lower Colorado-Lavaca)' },
  { value: '120903010603', regionId: '10', label: '120903010603 (Lower Colorado-Lavaca)' },
  { value: '120903010604', regionId: '10', label: '120903010604 (Lower Colorado-Lavaca)' },
  { value: '120903010605', regionId: '10', label: '120903010605 (Lower Colorado-Lavaca)' },
  { value: '120903010606', regionId: '10', label: '120903010606 (Lower Colorado-Lavaca)' },
  { value: '120903010607', regionId: '10', label: '120903010607 (Lower Colorado-Lavaca)' },
  { value: '120903010608', regionId: '10', label: '120903010608 (Lower Colorado-Lavaca)' },
  { value: '120903010701', regionId: '10', label: '120903010701 (Lower Colorado-Lavaca)' },
  { value: '120903010702', regionId: '10', label: '120903010702 (Lower Colorado-Lavaca)' },
  { value: '120903010703', regionId: '10', label: '120903010703 (Lower Colorado-Lavaca)' },
  { value: '120903010704', regionId: '10', label: '120903010704 (Lower Colorado-Lavaca)' },
  { value: '120903010705', regionId: '10', label: '120903010705 (Lower Colorado-Lavaca)' },
  { value: '120903010706', regionId: '10', label: '120903010706 (Lower Colorado-Lavaca)' },
  { value: '120903010707', regionId: '10', label: '120903010707 (Lower Colorado-Lavaca)' },
  { value: '120903010708', regionId: '10', label: '120903010708 (Lower Colorado-Lavaca)' },
  { value: '120903010709', regionId: '10', label: '120903010709 (Lower Colorado-Lavaca)' },
  { value: '120903010710', regionId: '10', label: '120903010710 (Lower Colorado-Lavaca)' },
  { value: '120903010711', regionId: '10', label: '120903010711 (Lower Colorado-Lavaca)' },
  { value: '120903010712', regionId: '10', label: '120903010712 (Lower Colorado-Lavaca)' },
  { value: '120903010507', regionId: '10', label: '120903010507 (Lower Colorado-Lavaca)' },
  { value: '120903010601', regionId: '10', label: '120903010601 (Lower Colorado-Lavaca)' },
  { value: '120903010602', regionId: '10', label: '120903010602 (Lower Colorado-Lavaca)' },
  { value: '120903020101', regionId: '10', label: '120903020101 (Lower Colorado-Lavaca)' },
  { value: '120903020102', regionId: '10', label: '120903020102 (Lower Colorado-Lavaca)' },
  { value: '120903020103', regionId: '10', label: '120903020103 (Lower Colorado-Lavaca)' },
  { value: '120903020104', regionId: '10', label: '120903020104 (Lower Colorado-Lavaca)' },
  { value: '120903020105', regionId: '10', label: '120903020105 (Lower Colorado-Lavaca)' },
  { value: '120903020106', regionId: '10', label: '120903020106 (Lower Colorado-Lavaca)' },
  { value: '120903020107', regionId: '10', label: '120903020107 (Lower Colorado-Lavaca)' },
  { value: '120903020108', regionId: '10', label: '120903020108 (Lower Colorado-Lavaca)' },
  { value: '120903020201', regionId: '10', label: '120903020201 (Lower Colorado-Lavaca)' },
  { value: '120903020202', regionId: '10', label: '120903020202 (Lower Colorado-Lavaca)' },
  { value: '120903020203', regionId: '10', label: '120903020203 (Lower Colorado-Lavaca)' },
  { value: '120903020204', regionId: '10', label: '120903020204 (Lower Colorado-Lavaca)' },
  { value: '120903020205', regionId: '10', label: '120903020205 (Lower Colorado-Lavaca)' },
  { value: '120903020206', regionId: '10', label: '120903020206 (Lower Colorado-Lavaca)' },
  { value: '120903020207', regionId: '10', label: '120903020207 (Lower Colorado-Lavaca)' },
  { value: '120904010105', regionId: '10', label: '120904010105 (Lower Colorado-Lavaca)' },
  { value: '120904010106', regionId: '10', label: '120904010106 (Lower Colorado-Lavaca)' },
  { value: '120904010107', regionId: '10', label: '120904010107 (Lower Colorado-Lavaca)' },
  { value: '120904010108', regionId: '10', label: '120904010108 (Lower Colorado-Lavaca)' },
  { value: '120904010101', regionId: '10', label: '120904010101 (Lower Colorado-Lavaca)' },
  { value: '120904010102', regionId: '10', label: '120904010102 (Lower Colorado-Lavaca)' },
  { value: '120904010103', regionId: '10', label: '120904010103 (Lower Colorado-Lavaca)' },
  { value: '120904010104', regionId: '10', label: '120904010104 (Lower Colorado-Lavaca)' },
  { value: '120904010109', regionId: '10', label: '120904010109 (Lower Colorado-Lavaca)' },
  { value: '120904010201', regionId: '10', label: '120904010201 (Lower Colorado-Lavaca)' },
  { value: '120904010202', regionId: '10', label: '120904010202 (Lower Colorado-Lavaca)' },
  { value: '120904010205', regionId: '10', label: '120904010205 (Lower Colorado-Lavaca)' },
  { value: '120904010206', regionId: '10', label: '120904010206 (Lower Colorado-Lavaca)' },
  { value: '120904010306', regionId: '10', label: '120904010306 (Lower Colorado-Lavaca)' },
  { value: '120904010203', regionId: '10', label: '120904010203 (Lower Colorado-Lavaca)' },
  { value: '120904010204', regionId: '10', label: '120904010204 (Lower Colorado-Lavaca)' },
  { value: '120904010207', regionId: '10', label: '120904010207 (Lower Colorado-Lavaca)' },
  { value: '120904010301', regionId: '10', label: '120904010301 (Lower Colorado-Lavaca)' },
  { value: '120904010302', regionId: '10', label: '120904010302 (Lower Colorado-Lavaca)' },
  { value: '120904010303', regionId: '10', label: '120904010303 (Lower Colorado-Lavaca)' },
  { value: '120904010304', regionId: '10', label: '120904010304 (Lower Colorado-Lavaca)' },
  { value: '120904010305', regionId: '10', label: '120904010305 (Lower Colorado-Lavaca)' },
  { value: '120904010307', regionId: '10', label: '120904010307 (Lower Colorado-Lavaca)' },
  { value: '120904010308', regionId: '10', label: '120904010308 (Lower Colorado-Lavaca)' },
  { value: '120904020100', regionId: '10', label: '120904020100 (Lower Colorado-Lavaca)' },
  { value: '120904020201', regionId: '10', label: '120904020201 (Lower Colorado-Lavaca)' },
  { value: '120904020202', regionId: '10', label: '120904020202 (Lower Colorado-Lavaca)' },
  { value: '120904020203', regionId: '10', label: '120904020203 (Lower Colorado-Lavaca)' },
  { value: '120904020204', regionId: '10', label: '120904020204 (Lower Colorado-Lavaca)' },
  { value: '120904020300', regionId: '10', label: '120904020300 (Lower Colorado-Lavaca)' },
  { value: '120904020400', regionId: '10', label: '120904020400 (Lower Colorado-Lavaca)' },
  { value: '120904020500', regionId: '10', label: '120904020500 (Lower Colorado-Lavaca)' },
  { value: '121001020108', regionId: '10', label: '121001020108 (Lower Colorado-Lavaca)' },
  { value: '121001020201', regionId: '10', label: '121001020201 (Lower Colorado-Lavaca)' },
  { value: '121001020202', regionId: '10', label: '121001020202 (Lower Colorado-Lavaca)' },
  { value: '121001020203', regionId: '10', label: '121001020203 (Lower Colorado-Lavaca)' },
  { value: '121001020204', regionId: '10', label: '121001020204 (Lower Colorado-Lavaca)' },
  { value: '121001020101', regionId: '10', label: '121001020101 (Lower Colorado-Lavaca)' },
  { value: '121001020102', regionId: '10', label: '121001020102 (Lower Colorado-Lavaca)' },
  { value: '121001020103', regionId: '10', label: '121001020103 (Lower Colorado-Lavaca)' },
  { value: '121001020104', regionId: '10', label: '121001020104 (Lower Colorado-Lavaca)' },
  { value: '121001020105', regionId: '10', label: '121001020105 (Lower Colorado-Lavaca)' },
  { value: '121001020106', regionId: '10', label: '121001020106 (Lower Colorado-Lavaca)' },
  { value: '121001020107', regionId: '10', label: '121001020107 (Lower Colorado-Lavaca)' },
  { value: '121001020205', regionId: '10', label: '121001020205 (Lower Colorado-Lavaca)' },
  { value: '121001020206', regionId: '10', label: '121001020206 (Lower Colorado-Lavaca)' },
  { value: '121001020301', regionId: '10', label: '121001020301 (Lower Colorado-Lavaca)' },
  { value: '121001020302', regionId: '10', label: '121001020302 (Lower Colorado-Lavaca)' },
  { value: '121001020303', regionId: '10', label: '121001020303 (Lower Colorado-Lavaca)' },
  { value: '121001020304', regionId: '10', label: '121001020304 (Lower Colorado-Lavaca)' },
  { value: '121001020305', regionId: '10', label: '121001020305 (Lower Colorado-Lavaca)' },
  { value: '121001020306', regionId: '10', label: '121001020306 (Lower Colorado-Lavaca)' },
  { value: '121001020307', regionId: '10', label: '121001020307 (Lower Colorado-Lavaca)' },
  { value: '121001020308', regionId: '10', label: '121001020308 (Lower Colorado-Lavaca)' },
  { value: '121001020309', regionId: '10', label: '121001020309 (Lower Colorado-Lavaca)' },
  { value: '121001020310', regionId: '10', label: '121001020310 (Lower Colorado-Lavaca)' },
  { value: '121001020401', regionId: '10', label: '121001020401 (Lower Colorado-Lavaca)' },
  { value: '121001020402', regionId: '10', label: '121001020402 (Lower Colorado-Lavaca)' },
  { value: '121001020403', regionId: '10', label: '121001020403 (Lower Colorado-Lavaca)' },
  { value: '121001020404', regionId: '10', label: '121001020404 (Lower Colorado-Lavaca)' },
  { value: '121001020405', regionId: '10', label: '121001020405 (Lower Colorado-Lavaca)' },
  { value: '121001020501', regionId: '10', label: '121001020501 (Lower Colorado-Lavaca)' },
  { value: '121001020502', regionId: '10', label: '121001020502 (Lower Colorado-Lavaca)' },
  { value: '121001020503', regionId: '10', label: '121001020503 (Lower Colorado-Lavaca)' },
  { value: '121002010101', regionId: '10', label: '121002010101 (Lower Colorado-Lavaca)' },
  { value: '121002010102', regionId: '10', label: '121002010102 (Lower Colorado-Lavaca)' },
  { value: '121002010103', regionId: '10', label: '121002010103 (Lower Colorado-Lavaca)' },
  { value: '121002010104', regionId: '10', label: '121002010104 (Lower Colorado-Lavaca)' },
  { value: '121002010109', regionId: '10', label: '121002010109 (Lower Colorado-Lavaca)' },
  { value: '121002010110', regionId: '10', label: '121002010110 (Lower Colorado-Lavaca)' },
  { value: '121002010111', regionId: '10', label: '121002010111 (Lower Colorado-Lavaca)' },
  { value: '121002010201', regionId: '10', label: '121002010201 (Lower Colorado-Lavaca)' },
  { value: '121002010202', regionId: '10', label: '121002010202 (Lower Colorado-Lavaca)' },
  { value: '121002010203', regionId: '10', label: '121002010203 (Lower Colorado-Lavaca)' },
  { value: '121002010209', regionId: '10', label: '121002010209 (Lower Colorado-Lavaca)' },
  { value: '121002010210', regionId: '10', label: '121002010210 (Lower Colorado-Lavaca)' },
  { value: '121002010301', regionId: '10', label: '121002010301 (Lower Colorado-Lavaca)' },
  { value: '121002010304', regionId: '10', label: '121002010304 (Lower Colorado-Lavaca)' },
  { value: '121002020301', regionId: '10', label: '121002020301 (Lower Colorado-Lavaca)' },
  { value: '121002020302', regionId: '10', label: '121002020302 (Lower Colorado-Lavaca)' },
  { value: '121002020303', regionId: '10', label: '121002020303 (Lower Colorado-Lavaca)' },
  { value: '121002020304', regionId: '10', label: '121002020304 (Lower Colorado-Lavaca)' },
  { value: '121002020305', regionId: '10', label: '121002020305 (Lower Colorado-Lavaca)' },
  { value: '121002020306', regionId: '10', label: '121002020306 (Lower Colorado-Lavaca)' },
  { value: '121002020309', regionId: '10', label: '121002020309 (Lower Colorado-Lavaca)' },
  { value: '121002020311', regionId: '10', label: '121002020311 (Lower Colorado-Lavaca)' },
  { value: '121002020401', regionId: '10', label: '121002020401 (Lower Colorado-Lavaca)' },
  { value: '121002020404', regionId: '10', label: '121002020404 (Lower Colorado-Lavaca)' },
  { value: '121002020405', regionId: '10', label: '121002020405 (Lower Colorado-Lavaca)' },
  { value: '121002030205', regionId: '10', label: '121002030205 (Lower Colorado-Lavaca)' },
  { value: '121002030401', regionId: '10', label: '121002030401 (Lower Colorado-Lavaca)' },
  { value: '121002030101', regionId: '10', label: '121002030101 (Lower Colorado-Lavaca)' },
  { value: '121002030102', regionId: '10', label: '121002030102 (Lower Colorado-Lavaca)' },
  { value: '121002030103', regionId: '10', label: '121002030103 (Lower Colorado-Lavaca)' },
  { value: '121002030105', regionId: '10', label: '121002030105 (Lower Colorado-Lavaca)' },
  { value: '121002030201', regionId: '10', label: '121002030201 (Lower Colorado-Lavaca)' },
  { value: '121002030202', regionId: '10', label: '121002030202 (Lower Colorado-Lavaca)' },
  { value: '121002030204', regionId: '10', label: '121002030204 (Lower Colorado-Lavaca)' },
  { value: '121002030402', regionId: '10', label: '121002030402 (Lower Colorado-Lavaca)' },
  { value: '121002030403', regionId: '10', label: '121002030403 (Lower Colorado-Lavaca)' },
  { value: '121002030404', regionId: '10', label: '121002030404 (Lower Colorado-Lavaca)' },
  { value: '121002030405', regionId: '10', label: '121002030405 (Lower Colorado-Lavaca)' },
  { value: '121002030406', regionId: '10', label: '121002030406 (Lower Colorado-Lavaca)' },
  { value: '121002040102', regionId: '10', label: '121002040102 (Lower Colorado-Lavaca)' },
  { value: '121002040104', regionId: '10', label: '121002040104 (Lower Colorado-Lavaca)' },
  { value: '121002040107', regionId: '10', label: '121002040107 (Lower Colorado-Lavaca)' },
  { value: '121002040108', regionId: '10', label: '121002040108 (Lower Colorado-Lavaca)' },
  { value: '121002040401', regionId: '10', label: '121002040401 (Lower Colorado-Lavaca)' },
  { value: '121004010100', regionId: '10', label: '121004010100 (Lower Colorado-Lavaca)' },
  { value: '121004010200', regionId: '10', label: '121004010200 (Lower Colorado-Lavaca)' },
  { value: '121004010300', regionId: '10', label: '121004010300 (Lower Colorado-Lavaca)' },
  { value: '121004010400', regionId: '10', label: '121004010400 (Lower Colorado-Lavaca)' },
  { value: '121004010500', regionId: '10', label: '121004010500 (Lower Colorado-Lavaca)' },
  { value: '121004010600', regionId: '10', label: '121004010600 (Lower Colorado-Lavaca)' },
  { value: '121004020303', regionId: '10', label: '121004020303 (Lower Colorado-Lavaca)' },
  { value: '121004020305', regionId: '10', label: '121004020305 (Lower Colorado-Lavaca)' },
  { value: '121004020101', regionId: '10', label: '121004020101 (Lower Colorado-Lavaca)' },
  { value: '121004020102', regionId: '10', label: '121004020102 (Lower Colorado-Lavaca)' },
  { value: '121004020103', regionId: '10', label: '121004020103 (Lower Colorado-Lavaca)' },
  { value: '121004020104', regionId: '10', label: '121004020104 (Lower Colorado-Lavaca)' },
  { value: '121004020201', regionId: '10', label: '121004020201 (Lower Colorado-Lavaca)' },
  { value: '121004020202', regionId: '10', label: '121004020202 (Lower Colorado-Lavaca)' },
  { value: '121004020203', regionId: '10', label: '121004020203 (Lower Colorado-Lavaca)' },
  { value: '121004020204', regionId: '10', label: '121004020204 (Lower Colorado-Lavaca)' },
  { value: '121004020205', regionId: '10', label: '121004020205 (Lower Colorado-Lavaca)' },
  { value: '121004020206', regionId: '10', label: '121004020206 (Lower Colorado-Lavaca)' },
  { value: '121004020301', regionId: '10', label: '121004020301 (Lower Colorado-Lavaca)' },
  { value: '121004020302', regionId: '10', label: '121004020302 (Lower Colorado-Lavaca)' },
  { value: '121004020304', regionId: '10', label: '121004020304 (Lower Colorado-Lavaca)' },
  { value: '121004020400', regionId: '10', label: '121004020400 (Lower Colorado-Lavaca)' },
  { value: '121004020500', regionId: '10', label: '121004020500 (Lower Colorado-Lavaca)' },
  { value: '121004030200', regionId: '10', label: '121004030200 (Lower Colorado-Lavaca)' },
  { value: '121004030100', regionId: '10', label: '121004030100 (Lower Colorado-Lavaca)' },
  { value: '121101010101', regionId: '10', label: '121101010101 (Lower Colorado-Lavaca)' },
  { value: '121101010102', regionId: '10', label: '121101010102 (Lower Colorado-Lavaca)' },
  { value: '121101010103', regionId: '10', label: '121101010103 (Lower Colorado-Lavaca)' },
  { value: '121101010104', regionId: '10', label: '121101010104 (Lower Colorado-Lavaca)' },
  { value: '121101020101', regionId: '10', label: '121101020101 (Lower Colorado-Lavaca)' },
  { value: '121101020102', regionId: '10', label: '121101020102 (Lower Colorado-Lavaca)' },
  { value: '121101020201', regionId: '10', label: '121101020201 (Lower Colorado-Lavaca)' },
  { value: '121101060101', regionId: '10', label: '121101060101 (Lower Colorado-Lavaca)' },
  { value: '121101060102', regionId: '10', label: '121101060102 (Lower Colorado-Lavaca)' },
  { value: '130403010402', regionId: '10', label: '130403010402 (Lower Colorado-Lavaca)' },
  { value: '130403010403', regionId: '10', label: '130403010403 (Lower Colorado-Lavaca)' },
  { value: '130403010405', regionId: '10', label: '130403010405 (Lower Colorado-Lavaca)' },
  { value: '130403010501', regionId: '10', label: '130403010501 (Lower Colorado-Lavaca)' },
  { value: '130403010601', regionId: '10', label: '130403010601 (Lower Colorado-Lavaca)' },
  { value: '130403010502', regionId: '10', label: '130403010502 (Lower Colorado-Lavaca)' },
  { value: '130403010603', regionId: '10', label: '130403010603 (Lower Colorado-Lavaca)' },
  { value: '130403030101', regionId: '10', label: '130403030101 (Lower Colorado-Lavaca)' },
  { value: '130403030201', regionId: '10', label: '130403030201 (Lower Colorado-Lavaca)' },
  { value: '120902030103', regionId: '10', label: '120902030103 (Lower Colorado-Lavaca)' },
  { value: '120902030104', regionId: '10', label: '120902030104 (Lower Colorado-Lavaca)' },
  { value: '120902030107', regionId: '10', label: '120902030107 (Lower Colorado-Lavaca)' },
  { value: '120902030101', regionId: '10', label: '120902030101 (Lower Colorado-Lavaca)' },
  { value: '120902030102', regionId: '10', label: '120902030102 (Lower Colorado-Lavaca)' },
  { value: '120902030105', regionId: '10', label: '120902030105 (Lower Colorado-Lavaca)' },
  { value: '120902030106', regionId: '10', label: '120902030106 (Lower Colorado-Lavaca)' },
  { value: '120902030108', regionId: '10', label: '120902030108 (Lower Colorado-Lavaca)' },
  { value: '120902030201', regionId: '10', label: '120902030201 (Lower Colorado-Lavaca)' },
  { value: '120902030202', regionId: '10', label: '120902030202 (Lower Colorado-Lavaca)' },
  { value: '120902030203', regionId: '10', label: '120902030203 (Lower Colorado-Lavaca)' },
  { value: '120902030204', regionId: '10', label: '120902030204 (Lower Colorado-Lavaca)' },
  { value: '120902030205', regionId: '10', label: '120902030205 (Lower Colorado-Lavaca)' },
  { value: '120902030206', regionId: '10', label: '120902030206 (Lower Colorado-Lavaca)' },
  { value: '120902030301', regionId: '10', label: '120902030301 (Lower Colorado-Lavaca)' },
  { value: '120902030302', regionId: '10', label: '120902030302 (Lower Colorado-Lavaca)' },
  { value: '120902030303', regionId: '10', label: '120902030303 (Lower Colorado-Lavaca)' },
  { value: '120902030304', regionId: '10', label: '120902030304 (Lower Colorado-Lavaca)' },
  { value: '120902030305', regionId: '10', label: '120902030305 (Lower Colorado-Lavaca)' },
  { value: '120902030401', regionId: '10', label: '120902030401 (Lower Colorado-Lavaca)' },
  { value: '120902030402', regionId: '10', label: '120902030402 (Lower Colorado-Lavaca)' },
  { value: '120902030403', regionId: '10', label: '120902030403 (Lower Colorado-Lavaca)' },
  { value: '120902030404', regionId: '10', label: '120902030404 (Lower Colorado-Lavaca)' },
  { value: '120902030405', regionId: '10', label: '120902030405 (Lower Colorado-Lavaca)' },
  { value: '121001010107', regionId: '10', label: '121001010107 (Lower Colorado-Lavaca)' },
  { value: '121001010108', regionId: '10', label: '121001010108 (Lower Colorado-Lavaca)' },
  { value: '121001010101', regionId: '10', label: '121001010101 (Lower Colorado-Lavaca)' },
  { value: '121001010102', regionId: '10', label: '121001010102 (Lower Colorado-Lavaca)' },
  { value: '121001010103', regionId: '10', label: '121001010103 (Lower Colorado-Lavaca)' },
  { value: '121001010104', regionId: '10', label: '121001010104 (Lower Colorado-Lavaca)' },
  { value: '121001010105', regionId: '10', label: '121001010105 (Lower Colorado-Lavaca)' },
  { value: '121001010106', regionId: '10', label: '121001010106 (Lower Colorado-Lavaca)' },
  { value: '121001010201', regionId: '10', label: '121001010201 (Lower Colorado-Lavaca)' },
  { value: '121001010202', regionId: '10', label: '121001010202 (Lower Colorado-Lavaca)' },
  { value: '121001010203', regionId: '10', label: '121001010203 (Lower Colorado-Lavaca)' },
  { value: '121001010204', regionId: '10', label: '121001010204 (Lower Colorado-Lavaca)' },
  { value: '121001010205', regionId: '10', label: '121001010205 (Lower Colorado-Lavaca)' },
  { value: '121001010206', regionId: '10', label: '121001010206 (Lower Colorado-Lavaca)' },
  { value: '121001010301', regionId: '10', label: '121001010301 (Lower Colorado-Lavaca)' },
  { value: '121001010302', regionId: '10', label: '121001010302 (Lower Colorado-Lavaca)' },
  { value: '121001010303', regionId: '10', label: '121001010303 (Lower Colorado-Lavaca)' },
  { value: '121001010304', regionId: '10', label: '121001010304 (Lower Colorado-Lavaca)' },
  { value: '121001010305', regionId: '10', label: '121001010305 (Lower Colorado-Lavaca)' },
  { value: '121001010401', regionId: '10', label: '121001010401 (Lower Colorado-Lavaca)' },
  { value: '121001010402', regionId: '10', label: '121001010402 (Lower Colorado-Lavaca)' },
  { value: '121001010403', regionId: '10', label: '121001010403 (Lower Colorado-Lavaca)' },
  { value: '121001010404', regionId: '10', label: '121001010404 (Lower Colorado-Lavaca)' },
]

const hucsRegion02 = [
  { value: '111302010708', regionId: '02', label: '111302010708 (Lower Red-Sulphur-Cypress)' },
  { value: '111402010201', regionId: '02', label: '111402010201 (Lower Red-Sulphur-Cypress)' },
  { value: '111402010202', regionId: '02', label: '111402010202 (Lower Red-Sulphur-Cypress)' },
  { value: '111401060501', regionId: '02', label: '111401060501 (Lower Red-Sulphur-Cypress)' },
  { value: '111401060504', regionId: '02', label: '111401060504 (Lower Red-Sulphur-Cypress)' },
  { value: '111401060505', regionId: '02', label: '111401060505 (Lower Red-Sulphur-Cypress)' },
  { value: '111401060509', regionId: '02', label: '111401060509 (Lower Red-Sulphur-Cypress)' },
  { value: '111401060601', regionId: '02', label: '111401060601 (Lower Red-Sulphur-Cypress)' },
  { value: '111401060602', regionId: '02', label: '111401060602 (Lower Red-Sulphur-Cypress)' },
  { value: '111401060605', regionId: '02', label: '111401060605 (Lower Red-Sulphur-Cypress)' },
  { value: '111401060606', regionId: '02', label: '111401060606 (Lower Red-Sulphur-Cypress)' },
  { value: '111401060607', regionId: '02', label: '111401060607 (Lower Red-Sulphur-Cypress)' },
  { value: '111401060107', regionId: '02', label: '111401060107 (Lower Red-Sulphur-Cypress)' },
  { value: '111401060108', regionId: '02', label: '111401060108 (Lower Red-Sulphur-Cypress)' },
  { value: '111401060302', regionId: '02', label: '111401060302 (Lower Red-Sulphur-Cypress)' },
  { value: '111401060304', regionId: '02', label: '111401060304 (Lower Red-Sulphur-Cypress)' },
  { value: '111401060306', regionId: '02', label: '111401060306 (Lower Red-Sulphur-Cypress)' },
  { value: '111401060402', regionId: '02', label: '111401060402 (Lower Red-Sulphur-Cypress)' },
  { value: '111401060404', regionId: '02', label: '111401060404 (Lower Red-Sulphur-Cypress)' },
  { value: '111401060407', regionId: '02', label: '111401060407 (Lower Red-Sulphur-Cypress)' },
  { value: '111401060104', regionId: '02', label: '111401060104 (Lower Red-Sulphur-Cypress)' },
  { value: '111401060105', regionId: '02', label: '111401060105 (Lower Red-Sulphur-Cypress)' },
  { value: '111401060106', regionId: '02', label: '111401060106 (Lower Red-Sulphur-Cypress)' },
  { value: '111401060201', regionId: '02', label: '111401060201 (Lower Red-Sulphur-Cypress)' },
  { value: '111401060202', regionId: '02', label: '111401060202 (Lower Red-Sulphur-Cypress)' },
  { value: '111401060203', regionId: '02', label: '111401060203 (Lower Red-Sulphur-Cypress)' },
  { value: '111401060204', regionId: '02', label: '111401060204 (Lower Red-Sulphur-Cypress)' },
  { value: '111401060303', regionId: '02', label: '111401060303 (Lower Red-Sulphur-Cypress)' },
  { value: '111401060405', regionId: '02', label: '111401060405 (Lower Red-Sulphur-Cypress)' },
  { value: '111401060603', regionId: '02', label: '111401060603 (Lower Red-Sulphur-Cypress)' },
  { value: '111401060604', regionId: '02', label: '111401060604 (Lower Red-Sulphur-Cypress)' },
  { value: '111401060406', regionId: '02', label: '111401060406 (Lower Red-Sulphur-Cypress)' },
  { value: '111401060502', regionId: '02', label: '111401060502 (Lower Red-Sulphur-Cypress)' },
  { value: '111401060503', regionId: '02', label: '111401060503 (Lower Red-Sulphur-Cypress)' },
  { value: '111403010101', regionId: '02', label: '111403010101 (Lower Red-Sulphur-Cypress)' },
  { value: '111403010102', regionId: '02', label: '111403010102 (Lower Red-Sulphur-Cypress)' },
  { value: '111403010103', regionId: '02', label: '111403010103 (Lower Red-Sulphur-Cypress)' },
  { value: '111403010104', regionId: '02', label: '111403010104 (Lower Red-Sulphur-Cypress)' },
  { value: '111403010105', regionId: '02', label: '111403010105 (Lower Red-Sulphur-Cypress)' },
  { value: '111403010106', regionId: '02', label: '111403010106 (Lower Red-Sulphur-Cypress)' },
  { value: '111403010201', regionId: '02', label: '111403010201 (Lower Red-Sulphur-Cypress)' },
  { value: '111403010202', regionId: '02', label: '111403010202 (Lower Red-Sulphur-Cypress)' },
  { value: '111403010203', regionId: '02', label: '111403010203 (Lower Red-Sulphur-Cypress)' },
  { value: '111403010204', regionId: '02', label: '111403010204 (Lower Red-Sulphur-Cypress)' },
  { value: '111403010205', regionId: '02', label: '111403010205 (Lower Red-Sulphur-Cypress)' },
  { value: '111403010206', regionId: '02', label: '111403010206 (Lower Red-Sulphur-Cypress)' },
  { value: '111403010207', regionId: '02', label: '111403010207 (Lower Red-Sulphur-Cypress)' },
  { value: '111403010208', regionId: '02', label: '111403010208 (Lower Red-Sulphur-Cypress)' },
  { value: '111403010301', regionId: '02', label: '111403010301 (Lower Red-Sulphur-Cypress)' },
  { value: '111403010302', regionId: '02', label: '111403010302 (Lower Red-Sulphur-Cypress)' },
  { value: '111403010306', regionId: '02', label: '111403010306 (Lower Red-Sulphur-Cypress)' },
  { value: '111403010308', regionId: '02', label: '111403010308 (Lower Red-Sulphur-Cypress)' },
  { value: '111403010303', regionId: '02', label: '111403010303 (Lower Red-Sulphur-Cypress)' },
  { value: '111403010304', regionId: '02', label: '111403010304 (Lower Red-Sulphur-Cypress)' },
  { value: '111403010305', regionId: '02', label: '111403010305 (Lower Red-Sulphur-Cypress)' },
  { value: '111403010307', regionId: '02', label: '111403010307 (Lower Red-Sulphur-Cypress)' },
  { value: '111403010401', regionId: '02', label: '111403010401 (Lower Red-Sulphur-Cypress)' },
  { value: '111403010402', regionId: '02', label: '111403010402 (Lower Red-Sulphur-Cypress)' },
  { value: '111403010403', regionId: '02', label: '111403010403 (Lower Red-Sulphur-Cypress)' },
  { value: '111403010404', regionId: '02', label: '111403010404 (Lower Red-Sulphur-Cypress)' },
  { value: '111403010405', regionId: '02', label: '111403010405 (Lower Red-Sulphur-Cypress)' },
  { value: '111403010406', regionId: '02', label: '111403010406 (Lower Red-Sulphur-Cypress)' },
  { value: '111403010407', regionId: '02', label: '111403010407 (Lower Red-Sulphur-Cypress)' },
  { value: '111403030104', regionId: '02', label: '111403030104 (Lower Red-Sulphur-Cypress)' },
  { value: '111403030105', regionId: '02', label: '111403030105 (Lower Red-Sulphur-Cypress)' },
  { value: '111403030106', regionId: '02', label: '111403030106 (Lower Red-Sulphur-Cypress)' },
  { value: '111403030107', regionId: '02', label: '111403030107 (Lower Red-Sulphur-Cypress)' },
  { value: '111403030108', regionId: '02', label: '111403030108 (Lower Red-Sulphur-Cypress)' },
  { value: '111403030109', regionId: '02', label: '111403030109 (Lower Red-Sulphur-Cypress)' },
  { value: '111403030110', regionId: '02', label: '111403030110 (Lower Red-Sulphur-Cypress)' },
  { value: '111403030111', regionId: '02', label: '111403030111 (Lower Red-Sulphur-Cypress)' },
  { value: '111403030201', regionId: '02', label: '111403030201 (Lower Red-Sulphur-Cypress)' },
  { value: '111403030202', regionId: '02', label: '111403030202 (Lower Red-Sulphur-Cypress)' },
  { value: '111403030203', regionId: '02', label: '111403030203 (Lower Red-Sulphur-Cypress)' },
  { value: '111403030204', regionId: '02', label: '111403030204 (Lower Red-Sulphur-Cypress)' },
  { value: '111403030205', regionId: '02', label: '111403030205 (Lower Red-Sulphur-Cypress)' },
  { value: '111403030206', regionId: '02', label: '111403030206 (Lower Red-Sulphur-Cypress)' },
  { value: '111403030207', regionId: '02', label: '111403030207 (Lower Red-Sulphur-Cypress)' },
  { value: '111403030208', regionId: '02', label: '111403030208 (Lower Red-Sulphur-Cypress)' },
  { value: '111403030101', regionId: '02', label: '111403030101 (Lower Red-Sulphur-Cypress)' },
  { value: '111403030102', regionId: '02', label: '111403030102 (Lower Red-Sulphur-Cypress)' },
  { value: '111403030103', regionId: '02', label: '111403030103 (Lower Red-Sulphur-Cypress)' },
  { value: '111403050306', regionId: '02', label: '111403050306 (Lower Red-Sulphur-Cypress)' },
  { value: '111403050307', regionId: '02', label: '111403050307 (Lower Red-Sulphur-Cypress)' },
  { value: '111403050308', regionId: '02', label: '111403050308 (Lower Red-Sulphur-Cypress)' },
  { value: '111403050309', regionId: '02', label: '111403050309 (Lower Red-Sulphur-Cypress)' },
  { value: '111403050310', regionId: '02', label: '111403050310 (Lower Red-Sulphur-Cypress)' },
  { value: '111403050401', regionId: '02', label: '111403050401 (Lower Red-Sulphur-Cypress)' },
  { value: '111403050402', regionId: '02', label: '111403050402 (Lower Red-Sulphur-Cypress)' },
  { value: '111403050403', regionId: '02', label: '111403050403 (Lower Red-Sulphur-Cypress)' },
  { value: '111403050404', regionId: '02', label: '111403050404 (Lower Red-Sulphur-Cypress)' },
  { value: '111403050405', regionId: '02', label: '111403050405 (Lower Red-Sulphur-Cypress)' },
  { value: '111403050406', regionId: '02', label: '111403050406 (Lower Red-Sulphur-Cypress)' },
  { value: '111403050407', regionId: '02', label: '111403050407 (Lower Red-Sulphur-Cypress)' },
  { value: '111403050101', regionId: '02', label: '111403050101 (Lower Red-Sulphur-Cypress)' },
  { value: '111403050102', regionId: '02', label: '111403050102 (Lower Red-Sulphur-Cypress)' },
  { value: '111403050103', regionId: '02', label: '111403050103 (Lower Red-Sulphur-Cypress)' },
  { value: '111403050201', regionId: '02', label: '111403050201 (Lower Red-Sulphur-Cypress)' },
  { value: '111403050202', regionId: '02', label: '111403050202 (Lower Red-Sulphur-Cypress)' },
  { value: '111403050203', regionId: '02', label: '111403050203 (Lower Red-Sulphur-Cypress)' },
  { value: '111403050204', regionId: '02', label: '111403050204 (Lower Red-Sulphur-Cypress)' },
  { value: '111403050301', regionId: '02', label: '111403050301 (Lower Red-Sulphur-Cypress)' },
  { value: '111403050302', regionId: '02', label: '111403050302 (Lower Red-Sulphur-Cypress)' },
  { value: '111403050303', regionId: '02', label: '111403050303 (Lower Red-Sulphur-Cypress)' },
  { value: '111403050304', regionId: '02', label: '111403050304 (Lower Red-Sulphur-Cypress)' },
  { value: '111403050305', regionId: '02', label: '111403050305 (Lower Red-Sulphur-Cypress)' },
  { value: '111403060308', regionId: '02', label: '111403060308 (Lower Red-Sulphur-Cypress)' },
  { value: '111403060408', regionId: '02', label: '111403060408 (Lower Red-Sulphur-Cypress)' },
  { value: '111403060409', regionId: '02', label: '111403060409 (Lower Red-Sulphur-Cypress)' },
  { value: '111403060410', regionId: '02', label: '111403060410 (Lower Red-Sulphur-Cypress)' },
  { value: '111403060101', regionId: '02', label: '111403060101 (Lower Red-Sulphur-Cypress)' },
  { value: '111403060402', regionId: '02', label: '111403060402 (Lower Red-Sulphur-Cypress)' },
  { value: '111403060403', regionId: '02', label: '111403060403 (Lower Red-Sulphur-Cypress)' },
  { value: '111403060404', regionId: '02', label: '111403060404 (Lower Red-Sulphur-Cypress)' },
  { value: '111403060405', regionId: '02', label: '111403060405 (Lower Red-Sulphur-Cypress)' },
  { value: '111403060406', regionId: '02', label: '111403060406 (Lower Red-Sulphur-Cypress)' },
  { value: '111403060407', regionId: '02', label: '111403060407 (Lower Red-Sulphur-Cypress)' },
  { value: '111403060102', regionId: '02', label: '111403060102 (Lower Red-Sulphur-Cypress)' },
  { value: '111403060201', regionId: '02', label: '111403060201 (Lower Red-Sulphur-Cypress)' },
  { value: '111403060202', regionId: '02', label: '111403060202 (Lower Red-Sulphur-Cypress)' },
  { value: '111403060203', regionId: '02', label: '111403060203 (Lower Red-Sulphur-Cypress)' },
  { value: '111403060204', regionId: '02', label: '111403060204 (Lower Red-Sulphur-Cypress)' },
  { value: '111403060205', regionId: '02', label: '111403060205 (Lower Red-Sulphur-Cypress)' },
  { value: '111403060206', regionId: '02', label: '111403060206 (Lower Red-Sulphur-Cypress)' },
  { value: '111403060207', regionId: '02', label: '111403060207 (Lower Red-Sulphur-Cypress)' },
  { value: '111403060208', regionId: '02', label: '111403060208 (Lower Red-Sulphur-Cypress)' },
  { value: '111403060209', regionId: '02', label: '111403060209 (Lower Red-Sulphur-Cypress)' },
  { value: '111403060301', regionId: '02', label: '111403060301 (Lower Red-Sulphur-Cypress)' },
  { value: '111403060302', regionId: '02', label: '111403060302 (Lower Red-Sulphur-Cypress)' },
  { value: '111403060303', regionId: '02', label: '111403060303 (Lower Red-Sulphur-Cypress)' },
  { value: '111403060304', regionId: '02', label: '111403060304 (Lower Red-Sulphur-Cypress)' },
  { value: '111403060305', regionId: '02', label: '111403060305 (Lower Red-Sulphur-Cypress)' },
  { value: '111403060306', regionId: '02', label: '111403060306 (Lower Red-Sulphur-Cypress)' },
  { value: '111403060307', regionId: '02', label: '111403060307 (Lower Red-Sulphur-Cypress)' },
  { value: '111403060401', regionId: '02', label: '111403060401 (Lower Red-Sulphur-Cypress)' },
  { value: '111403070101', regionId: '02', label: '111403070101 (Lower Red-Sulphur-Cypress)' },
  { value: '111403070102', regionId: '02', label: '111403070102 (Lower Red-Sulphur-Cypress)' },
  { value: '111403070103', regionId: '02', label: '111403070103 (Lower Red-Sulphur-Cypress)' },
  { value: '111403070104', regionId: '02', label: '111403070104 (Lower Red-Sulphur-Cypress)' },
  { value: '111403070105', regionId: '02', label: '111403070105 (Lower Red-Sulphur-Cypress)' },
  { value: '111403070106', regionId: '02', label: '111403070106 (Lower Red-Sulphur-Cypress)' },
  { value: '111403070107', regionId: '02', label: '111403070107 (Lower Red-Sulphur-Cypress)' },
  { value: '111403070108', regionId: '02', label: '111403070108 (Lower Red-Sulphur-Cypress)' },
  { value: '111403070109', regionId: '02', label: '111403070109 (Lower Red-Sulphur-Cypress)' },
  { value: '111403070201', regionId: '02', label: '111403070201 (Lower Red-Sulphur-Cypress)' },
  { value: '111403070202', regionId: '02', label: '111403070202 (Lower Red-Sulphur-Cypress)' },
  { value: '111403070203', regionId: '02', label: '111403070203 (Lower Red-Sulphur-Cypress)' },
  { value: '111403070204', regionId: '02', label: '111403070204 (Lower Red-Sulphur-Cypress)' },
  { value: '111403070205', regionId: '02', label: '111403070205 (Lower Red-Sulphur-Cypress)' },
  { value: '111403070206', regionId: '02', label: '111403070206 (Lower Red-Sulphur-Cypress)' },
  { value: '111403070207', regionId: '02', label: '111403070207 (Lower Red-Sulphur-Cypress)' },
  { value: '111403070208', regionId: '02', label: '111403070208 (Lower Red-Sulphur-Cypress)' },
  { value: '111403070209', regionId: '02', label: '111403070209 (Lower Red-Sulphur-Cypress)' },
  { value: '120100010103', regionId: '02', label: '120100010103 (Lower Red-Sulphur-Cypress)' },
  { value: '120100010104', regionId: '02', label: '120100010104 (Lower Red-Sulphur-Cypress)' },
  { value: '120100010101', regionId: '02', label: '120100010101 (Lower Red-Sulphur-Cypress)' },
  { value: '120100010102', regionId: '02', label: '120100010102 (Lower Red-Sulphur-Cypress)' },
  { value: '120100021101', regionId: '02', label: '120100021101 (Lower Red-Sulphur-Cypress)' },
  { value: '120100020607', regionId: '02', label: '120100020607 (Lower Red-Sulphur-Cypress)' },
  { value: '120100020701', regionId: '02', label: '120100020701 (Lower Red-Sulphur-Cypress)' },
  { value: '120100020703', regionId: '02', label: '120100020703 (Lower Red-Sulphur-Cypress)' },
  { value: '120100020704', regionId: '02', label: '120100020704 (Lower Red-Sulphur-Cypress)' },
  { value: '120100020201', regionId: '02', label: '120100020201 (Lower Red-Sulphur-Cypress)' },
  { value: '120100020202', regionId: '02', label: '120100020202 (Lower Red-Sulphur-Cypress)' },
  { value: '120100020203', regionId: '02', label: '120100020203 (Lower Red-Sulphur-Cypress)' },
  { value: '120100020204', regionId: '02', label: '120100020204 (Lower Red-Sulphur-Cypress)' },
  { value: '120100020205', regionId: '02', label: '120100020205 (Lower Red-Sulphur-Cypress)' },
  { value: '120100020401', regionId: '02', label: '120100020401 (Lower Red-Sulphur-Cypress)' },
  { value: '120100020402', regionId: '02', label: '120100020402 (Lower Red-Sulphur-Cypress)' },
  { value: '120100020405', regionId: '02', label: '120100020405 (Lower Red-Sulphur-Cypress)' },
  { value: '120100020504', regionId: '02', label: '120100020504 (Lower Red-Sulphur-Cypress)' },
  { value: '120100020505', regionId: '02', label: '120100020505 (Lower Red-Sulphur-Cypress)' },
  { value: '120100020601', regionId: '02', label: '120100020601 (Lower Red-Sulphur-Cypress)' },
  { value: '120100020602', regionId: '02', label: '120100020602 (Lower Red-Sulphur-Cypress)' },
  { value: '120100020603', regionId: '02', label: '120100020603 (Lower Red-Sulphur-Cypress)' },
  { value: '120100021102', regionId: '02', label: '120100021102 (Lower Red-Sulphur-Cypress)' },
  { value: '120100030101', regionId: '02', label: '120100030101 (Lower Red-Sulphur-Cypress)' },
  { value: '120100030202', regionId: '02', label: '120100030202 (Lower Red-Sulphur-Cypress)' },
  { value: '120100030203', regionId: '02', label: '120100030203 (Lower Red-Sulphur-Cypress)' },
  { value: '120100030104', regionId: '02', label: '120100030104 (Lower Red-Sulphur-Cypress)' },
  { value: '120100030105', regionId: '02', label: '120100030105 (Lower Red-Sulphur-Cypress)' },
  { value: '120100030106', regionId: '02', label: '120100030106 (Lower Red-Sulphur-Cypress)' },
  { value: '120100030201', regionId: '02', label: '120100030201 (Lower Red-Sulphur-Cypress)' },
  { value: '120301030105', regionId: '02', label: '120301030105 (Lower Red-Sulphur-Cypress)' },
  { value: '120301030201', regionId: '02', label: '120301030201 (Lower Red-Sulphur-Cypress)' },
  { value: '120301030202', regionId: '02', label: '120301030202 (Lower Red-Sulphur-Cypress)' },
  { value: '120301030203', regionId: '02', label: '120301030203 (Lower Red-Sulphur-Cypress)' },
  { value: '120301030204', regionId: '02', label: '120301030204 (Lower Red-Sulphur-Cypress)' },
  { value: '120301060101', regionId: '02', label: '120301060101 (Lower Red-Sulphur-Cypress)' },
  { value: '120301060102', regionId: '02', label: '120301060102 (Lower Red-Sulphur-Cypress)' },
  { value: '120301060105', regionId: '02', label: '120301060105 (Lower Red-Sulphur-Cypress)' },
  { value: '120301060201', regionId: '02', label: '120301060201 (Lower Red-Sulphur-Cypress)' },
  { value: '120301060202', regionId: '02', label: '120301060202 (Lower Red-Sulphur-Cypress)' },
  { value: '120301060104', regionId: '02', label: '120301060104 (Lower Red-Sulphur-Cypress)' },
  { value: '120301060301', regionId: '02', label: '120301060301 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020705', regionId: '02', label: '111403020705 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020702', regionId: '02', label: '111403020702 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020703', regionId: '02', label: '111403020703 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020704', regionId: '02', label: '111403020704 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020707', regionId: '02', label: '111403020707 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020709', regionId: '02', label: '111403020709 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020101', regionId: '02', label: '111403020101 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020102', regionId: '02', label: '111403020102 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020103', regionId: '02', label: '111403020103 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020104', regionId: '02', label: '111403020104 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020105', regionId: '02', label: '111403020105 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020106', regionId: '02', label: '111403020106 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020107', regionId: '02', label: '111403020107 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020201', regionId: '02', label: '111403020201 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020202', regionId: '02', label: '111403020202 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020203', regionId: '02', label: '111403020203 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020204', regionId: '02', label: '111403020204 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020205', regionId: '02', label: '111403020205 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020206', regionId: '02', label: '111403020206 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020207', regionId: '02', label: '111403020207 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020208', regionId: '02', label: '111403020208 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020209', regionId: '02', label: '111403020209 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020302', regionId: '02', label: '111403020302 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020403', regionId: '02', label: '111403020403 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020404', regionId: '02', label: '111403020404 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020501', regionId: '02', label: '111403020501 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020502', regionId: '02', label: '111403020502 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020503', regionId: '02', label: '111403020503 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020301', regionId: '02', label: '111403020301 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020303', regionId: '02', label: '111403020303 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020401', regionId: '02', label: '111403020401 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020402', regionId: '02', label: '111403020402 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020504', regionId: '02', label: '111403020504 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020607', regionId: '02', label: '111403020607 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020608', regionId: '02', label: '111403020608 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020701', regionId: '02', label: '111403020701 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020601', regionId: '02', label: '111403020601 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020602', regionId: '02', label: '111403020602 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020603', regionId: '02', label: '111403020603 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020604', regionId: '02', label: '111403020604 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020605', regionId: '02', label: '111403020605 (Lower Red-Sulphur-Cypress)' },
  { value: '111403020606', regionId: '02', label: '111403020606 (Lower Red-Sulphur-Cypress)' },
  { value: '111302100202', regionId: '02', label: '111302100202 (Lower Red-Sulphur-Cypress)' },
  { value: '111302100204', regionId: '02', label: '111302100204 (Lower Red-Sulphur-Cypress)' },
  { value: '111302100205', regionId: '02', label: '111302100205 (Lower Red-Sulphur-Cypress)' },
  { value: '111302100304', regionId: '02', label: '111302100304 (Lower Red-Sulphur-Cypress)' },
  { value: '111302100305', regionId: '02', label: '111302100305 (Lower Red-Sulphur-Cypress)' },
  { value: '111302100503', regionId: '02', label: '111302100503 (Lower Red-Sulphur-Cypress)' },
  { value: '111302100505', regionId: '02', label: '111302100505 (Lower Red-Sulphur-Cypress)' },
  { value: '111302100201', regionId: '02', label: '111302100201 (Lower Red-Sulphur-Cypress)' },
  { value: '111302100401', regionId: '02', label: '111302100401 (Lower Red-Sulphur-Cypress)' },
  { value: '111302100402', regionId: '02', label: '111302100402 (Lower Red-Sulphur-Cypress)' },
  { value: '111302100403', regionId: '02', label: '111302100403 (Lower Red-Sulphur-Cypress)' },
  { value: '111302100404', regionId: '02', label: '111302100404 (Lower Red-Sulphur-Cypress)' },
  { value: '111302100406', regionId: '02', label: '111302100406 (Lower Red-Sulphur-Cypress)' },
  { value: '111302100405', regionId: '02', label: '111302100405 (Lower Red-Sulphur-Cypress)' },
  { value: '111302100504', regionId: '02', label: '111302100504 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010201', regionId: '02', label: '111401010201 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010202', regionId: '02', label: '111401010202 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010203', regionId: '02', label: '111401010203 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010304', regionId: '02', label: '111401010304 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010807', regionId: '02', label: '111401010807 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010808', regionId: '02', label: '111401010808 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010809', regionId: '02', label: '111401010809 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010403', regionId: '02', label: '111401010403 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010404', regionId: '02', label: '111401010404 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010405', regionId: '02', label: '111401010405 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010407', regionId: '02', label: '111401010407 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010606', regionId: '02', label: '111401010606 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010801', regionId: '02', label: '111401010801 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010804', regionId: '02', label: '111401010804 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010805', regionId: '02', label: '111401010805 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010806', regionId: '02', label: '111401010806 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010505', regionId: '02', label: '111401010505 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010506', regionId: '02', label: '111401010506 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010507', regionId: '02', label: '111401010507 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010508', regionId: '02', label: '111401010508 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010101', regionId: '02', label: '111401010101 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010102', regionId: '02', label: '111401010102 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010103', regionId: '02', label: '111401010103 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010104', regionId: '02', label: '111401010104 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010105', regionId: '02', label: '111401010105 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010106', regionId: '02', label: '111401010106 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010107', regionId: '02', label: '111401010107 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010401', regionId: '02', label: '111401010401 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010402', regionId: '02', label: '111401010402 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010501', regionId: '02', label: '111401010501 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010502', regionId: '02', label: '111401010502 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010503', regionId: '02', label: '111401010503 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010504', regionId: '02', label: '111401010504 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010601', regionId: '02', label: '111401010601 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010602', regionId: '02', label: '111401010602 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010603', regionId: '02', label: '111401010603 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010604', regionId: '02', label: '111401010604 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010605', regionId: '02', label: '111401010605 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010701', regionId: '02', label: '111401010701 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010702', regionId: '02', label: '111401010702 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010703', regionId: '02', label: '111401010703 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010704', regionId: '02', label: '111401010704 (Lower Red-Sulphur-Cypress)' },
  { value: '111401010705', regionId: '02', label: '111401010705 (Lower Red-Sulphur-Cypress)' },
  { value: '111403040205', regionId: '02', label: '111403040205 (Lower Red-Sulphur-Cypress)' },
  { value: '111403040203', regionId: '02', label: '111403040203 (Lower Red-Sulphur-Cypress)' },
  { value: '111403040401', regionId: '02', label: '111403040401 (Lower Red-Sulphur-Cypress)' },
  { value: '111403040206', regionId: '02', label: '111403040206 (Lower Red-Sulphur-Cypress)' },
  { value: '111403040402', regionId: '02', label: '111403040402 (Lower Red-Sulphur-Cypress)' },
  { value: '111403040403', regionId: '02', label: '111403040403 (Lower Red-Sulphur-Cypress)' },
  { value: '111403040502', regionId: '02', label: '111403040502 (Lower Red-Sulphur-Cypress)' },
  { value: '111403040503', regionId: '02', label: '111403040503 (Lower Red-Sulphur-Cypress)' },
  { value: '111403040201', regionId: '02', label: '111403040201 (Lower Red-Sulphur-Cypress)' },
  { value: '111403040202', regionId: '02', label: '111403040202 (Lower Red-Sulphur-Cypress)' },
]

const hucsRegion15 = [
  { value: '121101020105', regionId: '15', label: '121101020105 (Lower Rio Grande)' },
  { value: '121101020203', regionId: '15', label: '121101020203 (Lower Rio Grande)' },
  { value: '121101020204', regionId: '15', label: '121101020204 (Lower Rio Grande)' },
  { value: '121101020205', regionId: '15', label: '121101020205 (Lower Rio Grande)' },
  { value: '121101020304', regionId: '15', label: '121101020304 (Lower Rio Grande)' },
  { value: '121101020305', regionId: '15', label: '121101020305 (Lower Rio Grande)' },
  { value: '121101020402', regionId: '15', label: '121101020402 (Lower Rio Grande)' },
  { value: '121101030205', regionId: '15', label: '121101030205 (Lower Rio Grande)' },
  { value: '121101030302', regionId: '15', label: '121101030302 (Lower Rio Grande)' },
  { value: '121101030303', regionId: '15', label: '121101030303 (Lower Rio Grande)' },
  { value: '121101030501', regionId: '15', label: '121101030501 (Lower Rio Grande)' },
  { value: '121101030502', regionId: '15', label: '121101030502 (Lower Rio Grande)' },
  { value: '121101030504', regionId: '15', label: '121101030504 (Lower Rio Grande)' },
  { value: '121101040101', regionId: '15', label: '121101040101 (Lower Rio Grande)' },
  { value: '121101040105', regionId: '15', label: '121101040105 (Lower Rio Grande)' },
  { value: '121101040106', regionId: '15', label: '121101040106 (Lower Rio Grande)' },
  { value: '121101040107', regionId: '15', label: '121101040107 (Lower Rio Grande)' },
  { value: '121101040108', regionId: '15', label: '121101040108 (Lower Rio Grande)' },
  { value: '121101040301', regionId: '15', label: '121101040301 (Lower Rio Grande)' },
  { value: '121101040302', regionId: '15', label: '121101040302 (Lower Rio Grande)' },
  { value: '121101040303', regionId: '15', label: '121101040303 (Lower Rio Grande)' },
  { value: '121101040305', regionId: '15', label: '121101040305 (Lower Rio Grande)' },
  { value: '121101040307', regionId: '15', label: '121101040307 (Lower Rio Grande)' },
  { value: '121101040308', regionId: '15', label: '121101040308 (Lower Rio Grande)' },
  { value: '121101040603', regionId: '15', label: '121101040603 (Lower Rio Grande)' },
  { value: '121101050101', regionId: '15', label: '121101050101 (Lower Rio Grande)' },
  { value: '121101050102', regionId: '15', label: '121101050102 (Lower Rio Grande)' },
  { value: '121101050301', regionId: '15', label: '121101050301 (Lower Rio Grande)' },
  { value: '121101050401', regionId: '15', label: '121101050401 (Lower Rio Grande)' },
  { value: '121101050404', regionId: '15', label: '121101050404 (Lower Rio Grande)' },
  { value: '121101050501', regionId: '15', label: '121101050501 (Lower Rio Grande)' },
  { value: '121101050502', regionId: '15', label: '121101050502 (Lower Rio Grande)' },
  { value: '121101050601', regionId: '15', label: '121101050601 (Lower Rio Grande)' },
  { value: '121101050602', regionId: '15', label: '121101050602 (Lower Rio Grande)' },
  { value: '121101050701', regionId: '15', label: '121101050701 (Lower Rio Grande)' },
  { value: '121101050702', regionId: '15', label: '121101050702 (Lower Rio Grande)' },
  { value: '121101050703', regionId: '15', label: '121101050703 (Lower Rio Grande)' },
  { value: '121102030100', regionId: '15', label: '121102030100 (Lower Rio Grande)' },
  { value: '121102050807', regionId: '15', label: '121102050807 (Lower Rio Grande)' },
  { value: '121102050706', regionId: '15', label: '121102050706 (Lower Rio Grande)' },
  { value: '121102060102', regionId: '15', label: '121102060102 (Lower Rio Grande)' },
  { value: '121102060101', regionId: '15', label: '121102060101 (Lower Rio Grande)' },
  { value: '121102060402', regionId: '15', label: '121102060402 (Lower Rio Grande)' },
  { value: '121102060403', regionId: '15', label: '121102060403 (Lower Rio Grande)' },
  { value: '121102060404', regionId: '15', label: '121102060404 (Lower Rio Grande)' },
  { value: '121102060405', regionId: '15', label: '121102060405 (Lower Rio Grande)' },
  { value: '121102060406', regionId: '15', label: '121102060406 (Lower Rio Grande)' },
  { value: '121102060501', regionId: '15', label: '121102060501 (Lower Rio Grande)' },
  { value: '121102060502', regionId: '15', label: '121102060502 (Lower Rio Grande)' },
  { value: '121102060103', regionId: '15', label: '121102060103 (Lower Rio Grande)' },
  { value: '121102060105', regionId: '15', label: '121102060105 (Lower Rio Grande)' },
  { value: '121102060401', regionId: '15', label: '121102060401 (Lower Rio Grande)' },
  { value: '121102070100', regionId: '15', label: '121102070100 (Lower Rio Grande)' },
  { value: '121102070200', regionId: '15', label: '121102070200 (Lower Rio Grande)' },
  { value: '121102080100', regionId: '15', label: '121102080100 (Lower Rio Grande)' },
  { value: '121102080200', regionId: '15', label: '121102080200 (Lower Rio Grande)' },
  { value: '121102080300', regionId: '15', label: '121102080300 (Lower Rio Grande)' },
  { value: '121102080400', regionId: '15', label: '121102080400 (Lower Rio Grande)' },
  { value: '121102080500', regionId: '15', label: '121102080500 (Lower Rio Grande)' },
  { value: '121102080600', regionId: '15', label: '121102080600 (Lower Rio Grande)' },
  { value: '121102080700', regionId: '15', label: '121102080700 (Lower Rio Grande)' },
  { value: '121102080800', regionId: '15', label: '121102080800 (Lower Rio Grande)' },
  { value: '121102080900', regionId: '15', label: '121102080900 (Lower Rio Grande)' },
  { value: '121102081000', regionId: '15', label: '121102081000 (Lower Rio Grande)' },
  { value: '121102081100', regionId: '15', label: '121102081100 (Lower Rio Grande)' },
  { value: '130402121405', regionId: '15', label: '130402121405 (Lower Rio Grande)' },
  { value: '130403020305', regionId: '15', label: '130403020305 (Lower Rio Grande)' },
  { value: '130403020402', regionId: '15', label: '130403020402 (Lower Rio Grande)' },
  { value: '130403020404', regionId: '15', label: '130403020404 (Lower Rio Grande)' },
  { value: '130403020405', regionId: '15', label: '130403020405 (Lower Rio Grande)' },
  { value: '130403020408', regionId: '15', label: '130403020408 (Lower Rio Grande)' },
  { value: '130403030304', regionId: '15', label: '130403030304 (Lower Rio Grande)' },
  { value: '130403030301', regionId: '15', label: '130403030301 (Lower Rio Grande)' },
  { value: '130403030302', regionId: '15', label: '130403030302 (Lower Rio Grande)' },
  { value: '130800010207', regionId: '15', label: '130800010207 (Lower Rio Grande)' },
  { value: '130800010208', regionId: '15', label: '130800010208 (Lower Rio Grande)' },
  { value: '130800010301', regionId: '15', label: '130800010301 (Lower Rio Grande)' },
  { value: '130800010302', regionId: '15', label: '130800010302 (Lower Rio Grande)' },
  { value: '130800010303', regionId: '15', label: '130800010303 (Lower Rio Grande)' },
  { value: '130800010304', regionId: '15', label: '130800010304 (Lower Rio Grande)' },
  { value: '130800010305', regionId: '15', label: '130800010305 (Lower Rio Grande)' },
  { value: '130800010306', regionId: '15', label: '130800010306 (Lower Rio Grande)' },
  { value: '130800010401', regionId: '15', label: '130800010401 (Lower Rio Grande)' },
  { value: '130800010402', regionId: '15', label: '130800010402 (Lower Rio Grande)' },
  { value: '130800010403', regionId: '15', label: '130800010403 (Lower Rio Grande)' },
  { value: '130800010404', regionId: '15', label: '130800010404 (Lower Rio Grande)' },
  { value: '130800010405', regionId: '15', label: '130800010405 (Lower Rio Grande)' },
  { value: '130800010406', regionId: '15', label: '130800010406 (Lower Rio Grande)' },
  { value: '130800010407', regionId: '15', label: '130800010407 (Lower Rio Grande)' },
  { value: '130800010501', regionId: '15', label: '130800010501 (Lower Rio Grande)' },
  { value: '130800010502', regionId: '15', label: '130800010502 (Lower Rio Grande)' },
  { value: '130800010503', regionId: '15', label: '130800010503 (Lower Rio Grande)' },
  { value: '130800010504', regionId: '15', label: '130800010504 (Lower Rio Grande)' },
  { value: '130800011204', regionId: '15', label: '130800011204 (Lower Rio Grande)' },
  { value: '130800011205', regionId: '15', label: '130800011205 (Lower Rio Grande)' },
  { value: '130800011301', regionId: '15', label: '130800011301 (Lower Rio Grande)' },
  { value: '130800010203', regionId: '15', label: '130800010203 (Lower Rio Grande)' },
  { value: '130800010204', regionId: '15', label: '130800010204 (Lower Rio Grande)' },
  { value: '130800010205', regionId: '15', label: '130800010205 (Lower Rio Grande)' },
  { value: '130800010505', regionId: '15', label: '130800010505 (Lower Rio Grande)' },
  { value: '130800010506', regionId: '15', label: '130800010506 (Lower Rio Grande)' },
  { value: '130800011202', regionId: '15', label: '130800011202 (Lower Rio Grande)' },
  { value: '130800011203', regionId: '15', label: '130800011203 (Lower Rio Grande)' },
  { value: '130800011302', regionId: '15', label: '130800011302 (Lower Rio Grande)' },
  { value: '130800011303', regionId: '15', label: '130800011303 (Lower Rio Grande)' },
  { value: '130800011304', regionId: '15', label: '130800011304 (Lower Rio Grande)' },
  { value: '130800011305', regionId: '15', label: '130800011305 (Lower Rio Grande)' },
  { value: '130800011801', regionId: '15', label: '130800011801 (Lower Rio Grande)' },
  { value: '130800011802', regionId: '15', label: '130800011802 (Lower Rio Grande)' },
  { value: '130800011803', regionId: '15', label: '130800011803 (Lower Rio Grande)' },
  { value: '130800011804', regionId: '15', label: '130800011804 (Lower Rio Grande)' },
  { value: '130800011805', regionId: '15', label: '130800011805 (Lower Rio Grande)' },
  { value: '130800011902', regionId: '15', label: '130800011902 (Lower Rio Grande)' },
  { value: '130800011903', regionId: '15', label: '130800011903 (Lower Rio Grande)' },
  { value: '130800011906', regionId: '15', label: '130800011906 (Lower Rio Grande)' },
  { value: '130800011907', regionId: '15', label: '130800011907 (Lower Rio Grande)' },
  { value: '130800022303', regionId: '15', label: '130800022303 (Lower Rio Grande)' },
  { value: '130800022304', regionId: '15', label: '130800022304 (Lower Rio Grande)' },
  { value: '130800022305', regionId: '15', label: '130800022305 (Lower Rio Grande)' },
  { value: '130800022401', regionId: '15', label: '130800022401 (Lower Rio Grande)' },
  { value: '130800022402', regionId: '15', label: '130800022402 (Lower Rio Grande)' },
  { value: '130800022403', regionId: '15', label: '130800022403 (Lower Rio Grande)' },
  { value: '130800022404', regionId: '15', label: '130800022404 (Lower Rio Grande)' },
  { value: '130800022405', regionId: '15', label: '130800022405 (Lower Rio Grande)' },
  { value: '130800022601', regionId: '15', label: '130800022601 (Lower Rio Grande)' },
  { value: '130800022602', regionId: '15', label: '130800022602 (Lower Rio Grande)' },
  { value: '130800021504', regionId: '15', label: '130800021504 (Lower Rio Grande)' },
  { value: '130800021403', regionId: '15', label: '130800021403 (Lower Rio Grande)' },
  { value: '130800021404', regionId: '15', label: '130800021404 (Lower Rio Grande)' },
  { value: '130800021405', regionId: '15', label: '130800021405 (Lower Rio Grande)' },
  { value: '130800021901', regionId: '15', label: '130800021901 (Lower Rio Grande)' },
  { value: '130800021902', regionId: '15', label: '130800021902 (Lower Rio Grande)' },
  { value: '130800021903', regionId: '15', label: '130800021903 (Lower Rio Grande)' },
  { value: '130800021904', regionId: '15', label: '130800021904 (Lower Rio Grande)' },
  { value: '130800021905', regionId: '15', label: '130800021905 (Lower Rio Grande)' },
  { value: '130800021907', regionId: '15', label: '130800021907 (Lower Rio Grande)' },
  { value: '130800021909', regionId: '15', label: '130800021909 (Lower Rio Grande)' },
  { value: '130800022301', regionId: '15', label: '130800022301 (Lower Rio Grande)' },
  { value: '130800022302', regionId: '15', label: '130800022302 (Lower Rio Grande)' },
  { value: '130800022603', regionId: '15', label: '130800022603 (Lower Rio Grande)' },
  { value: '130800022606', regionId: '15', label: '130800022606 (Lower Rio Grande)' },
  { value: '130800022607', regionId: '15', label: '130800022607 (Lower Rio Grande)' },
  { value: '130800022609', regionId: '15', label: '130800022609 (Lower Rio Grande)' },
  { value: '130800022610', regionId: '15', label: '130800022610 (Lower Rio Grande)' },
  { value: '130800022611', regionId: '15', label: '130800022611 (Lower Rio Grande)' },
  { value: '130800022612', regionId: '15', label: '130800022612 (Lower Rio Grande)' },
  { value: '130800022801', regionId: '15', label: '130800022801 (Lower Rio Grande)' },
  { value: '130800022802', regionId: '15', label: '130800022802 (Lower Rio Grande)' },
  { value: '130800022803', regionId: '15', label: '130800022803 (Lower Rio Grande)' },
  { value: '130800022804', regionId: '15', label: '130800022804 (Lower Rio Grande)' },
  { value: '130800022805', regionId: '15', label: '130800022805 (Lower Rio Grande)' },
  { value: '130800022808', regionId: '15', label: '130800022808 (Lower Rio Grande)' },
  { value: '130800022809', regionId: '15', label: '130800022809 (Lower Rio Grande)' },
  { value: '130800020703', regionId: '15', label: '130800020703 (Lower Rio Grande)' },
  { value: '130800020704', regionId: '15', label: '130800020704 (Lower Rio Grande)' },
  { value: '130800020707', regionId: '15', label: '130800020707 (Lower Rio Grande)' },
  { value: '130800020708', regionId: '15', label: '130800020708 (Lower Rio Grande)' },
  { value: '130800020709', regionId: '15', label: '130800020709 (Lower Rio Grande)' },
  { value: '130800020710', regionId: '15', label: '130800020710 (Lower Rio Grande)' },
  { value: '130800021502', regionId: '15', label: '130800021502 (Lower Rio Grande)' },
  { value: '130800021503', regionId: '15', label: '130800021503 (Lower Rio Grande)' },
  { value: '130800021505', regionId: '15', label: '130800021505 (Lower Rio Grande)' },
  { value: '130800021506', regionId: '15', label: '130800021506 (Lower Rio Grande)' },
  { value: '130800021401', regionId: '15', label: '130800021401 (Lower Rio Grande)' },
  { value: '130800021402', regionId: '15', label: '130800021402 (Lower Rio Grande)' },
  { value: '130800020702', regionId: '15', label: '130800020702 (Lower Rio Grande)' },
  { value: '130800021501', regionId: '15', label: '130800021501 (Lower Rio Grande)' },
  { value: '130800022604', regionId: '15', label: '130800022604 (Lower Rio Grande)' },
  { value: '130800022806', regionId: '15', label: '130800022806 (Lower Rio Grande)' },
  { value: '130800030101', regionId: '15', label: '130800030101 (Lower Rio Grande)' },
  { value: '130800030102', regionId: '15', label: '130800030102 (Lower Rio Grande)' },
  { value: '130800030103', regionId: '15', label: '130800030103 (Lower Rio Grande)' },
  { value: '130800030104', regionId: '15', label: '130800030104 (Lower Rio Grande)' },
  { value: '130800030105', regionId: '15', label: '130800030105 (Lower Rio Grande)' },
  { value: '130800030106', regionId: '15', label: '130800030106 (Lower Rio Grande)' },
  { value: '130800030701', regionId: '15', label: '130800030701 (Lower Rio Grande)' },
  { value: '130800030702', regionId: '15', label: '130800030702 (Lower Rio Grande)' },
  { value: '130800030703', regionId: '15', label: '130800030703 (Lower Rio Grande)' },
  { value: '130800030704', regionId: '15', label: '130800030704 (Lower Rio Grande)' },
  { value: '130800030705', regionId: '15', label: '130800030705 (Lower Rio Grande)' },
  { value: '130800030706', regionId: '15', label: '130800030706 (Lower Rio Grande)' },
  { value: '130800030707', regionId: '15', label: '130800030707 (Lower Rio Grande)' },
  { value: '130800030708', regionId: '15', label: '130800030708 (Lower Rio Grande)' },
  { value: '130800030605', regionId: '15', label: '130800030605 (Lower Rio Grande)' },
  { value: '130800030606', regionId: '15', label: '130800030606 (Lower Rio Grande)' },
  { value: '130800030608', regionId: '15', label: '130800030608 (Lower Rio Grande)' },
  { value: '130800030901', regionId: '15', label: '130800030901 (Lower Rio Grande)' },
  { value: '130800030904', regionId: '15', label: '130800030904 (Lower Rio Grande)' },
  { value: '130800030107', regionId: '15', label: '130800030107 (Lower Rio Grande)' },
  { value: '130800030201', regionId: '15', label: '130800030201 (Lower Rio Grande)' },
  { value: '130800030202', regionId: '15', label: '130800030202 (Lower Rio Grande)' },
  { value: '130800030203', regionId: '15', label: '130800030203 (Lower Rio Grande)' },
  { value: '130800030204', regionId: '15', label: '130800030204 (Lower Rio Grande)' },
  { value: '130800030205', regionId: '15', label: '130800030205 (Lower Rio Grande)' },
  { value: '130800030206', regionId: '15', label: '130800030206 (Lower Rio Grande)' },
  { value: '130800030207', regionId: '15', label: '130800030207 (Lower Rio Grande)' },
  { value: '130800030208', regionId: '15', label: '130800030208 (Lower Rio Grande)' },
  { value: '130800030209', regionId: '15', label: '130800030209 (Lower Rio Grande)' },
  { value: '130800030210', regionId: '15', label: '130800030210 (Lower Rio Grande)' },
  { value: '130800030709', regionId: '15', label: '130800030709 (Lower Rio Grande)' },
  { value: '130800030710', regionId: '15', label: '130800030710 (Lower Rio Grande)' },
  { value: '130800030801', regionId: '15', label: '130800030801 (Lower Rio Grande)' },
  { value: '130800030802', regionId: '15', label: '130800030802 (Lower Rio Grande)' },
  { value: '130800030803', regionId: '15', label: '130800030803 (Lower Rio Grande)' },
  { value: '130800030804', regionId: '15', label: '130800030804 (Lower Rio Grande)' },
  { value: '130800030805', regionId: '15', label: '130800030805 (Lower Rio Grande)' },
  { value: '130800030806', regionId: '15', label: '130800030806 (Lower Rio Grande)' },
  { value: '130800030601', regionId: '15', label: '130800030601 (Lower Rio Grande)' },
  { value: '130800030602', regionId: '15', label: '130800030602 (Lower Rio Grande)' },
  { value: '130800030603', regionId: '15', label: '130800030603 (Lower Rio Grande)' },
  { value: '130800030604', regionId: '15', label: '130800030604 (Lower Rio Grande)' },
  { value: '130800030902', regionId: '15', label: '130800030902 (Lower Rio Grande)' },
  { value: '130800030903', regionId: '15', label: '130800030903 (Lower Rio Grande)' },
  { value: '130800031001', regionId: '15', label: '130800031001 (Lower Rio Grande)' },
  { value: '130800031002', regionId: '15', label: '130800031002 (Lower Rio Grande)' },
  { value: '130800031003', regionId: '15', label: '130800031003 (Lower Rio Grande)' },
  { value: '130800031004', regionId: '15', label: '130800031004 (Lower Rio Grande)' },
  { value: '130800031005', regionId: '15', label: '130800031005 (Lower Rio Grande)' },
  { value: '130800031006', regionId: '15', label: '130800031006 (Lower Rio Grande)' },
  { value: '130800031007', regionId: '15', label: '130800031007 (Lower Rio Grande)' },
  { value: '130800031011', regionId: '15', label: '130800031011 (Lower Rio Grande)' },
  { value: '130900011301', regionId: '15', label: '130900011301 (Lower Rio Grande)' },
  { value: '130900011302', regionId: '15', label: '130900011302 (Lower Rio Grande)' },
  { value: '130900011303', regionId: '15', label: '130900011303 (Lower Rio Grande)' },
  { value: '130900011304', regionId: '15', label: '130900011304 (Lower Rio Grande)' },
  { value: '130900011201', regionId: '15', label: '130900011201 (Lower Rio Grande)' },
  { value: '130900011202', regionId: '15', label: '130900011202 (Lower Rio Grande)' },
  { value: '130900011203', regionId: '15', label: '130900011203 (Lower Rio Grande)' },
  { value: '130900011204', regionId: '15', label: '130900011204 (Lower Rio Grande)' },
  { value: '130900011401', regionId: '15', label: '130900011401 (Lower Rio Grande)' },
  { value: '130900011402', regionId: '15', label: '130900011402 (Lower Rio Grande)' },
  { value: '130900011103', regionId: '15', label: '130900011103 (Lower Rio Grande)' },
  { value: '130900011108', regionId: '15', label: '130900011108 (Lower Rio Grande)' },
  { value: '130900011110', regionId: '15', label: '130900011110 (Lower Rio Grande)' },
  { value: '130900011403', regionId: '15', label: '130900011403 (Lower Rio Grande)' },
  { value: '130900011501', regionId: '15', label: '130900011501 (Lower Rio Grande)' },
  { value: '130900011502', regionId: '15', label: '130900011502 (Lower Rio Grande)' },
  { value: '130900011601', regionId: '15', label: '130900011601 (Lower Rio Grande)' },
  { value: '130900011603', regionId: '15', label: '130900011603 (Lower Rio Grande)' },
  { value: '130900011604', regionId: '15', label: '130900011604 (Lower Rio Grande)' },
  { value: '130900011605', regionId: '15', label: '130900011605 (Lower Rio Grande)' },
  { value: '130900011606', regionId: '15', label: '130900011606 (Lower Rio Grande)' },
  { value: '130900011607', regionId: '15', label: '130900011607 (Lower Rio Grande)' },
  { value: '130900011701', regionId: '15', label: '130900011701 (Lower Rio Grande)' },
  { value: '130900011702', regionId: '15', label: '130900011702 (Lower Rio Grande)' },
  { value: '130900011102', regionId: '15', label: '130900011102 (Lower Rio Grande)' },
  { value: '130900011703', regionId: '15', label: '130900011703 (Lower Rio Grande)' },
  { value: '130900011704', regionId: '15', label: '130900011704 (Lower Rio Grande)' },
  { value: '130900011705', regionId: '15', label: '130900011705 (Lower Rio Grande)' },
  { value: '130900011706', regionId: '15', label: '130900011706 (Lower Rio Grande)' },
  { value: '130900011107', regionId: '15', label: '130900011107 (Lower Rio Grande)' },
  { value: '130900011109', regionId: '15', label: '130900011109 (Lower Rio Grande)' },
  { value: '130900011112', regionId: '15', label: '130900011112 (Lower Rio Grande)' },
  { value: '130900020501', regionId: '15', label: '130900020501 (Lower Rio Grande)' },
  { value: '130900020403', regionId: '15', label: '130900020403 (Lower Rio Grande)' },
  { value: '130900020401', regionId: '15', label: '130900020401 (Lower Rio Grande)' },
  { value: '130900020311', regionId: '15', label: '130900020311 (Lower Rio Grande)' },
  { value: '130900020305', regionId: '15', label: '130900020305 (Lower Rio Grande)' },
  { value: '130900020301', regionId: '15', label: '130900020301 (Lower Rio Grande)' },
  { value: '130900020504', regionId: '15', label: '130900020504 (Lower Rio Grande)' },
]

const hucsRegion05 = [
  { value: '120100010503', regionId: '05', label: '120100010503 (Neches)' },
  { value: '120100010604', regionId: '05', label: '120100010604 (Neches)' },
  { value: '120100010607', regionId: '05', label: '120100010607 (Neches)' },
  { value: '120100010608', regionId: '05', label: '120100010608 (Neches)' },
  { value: '120100020801', regionId: '05', label: '120100020801 (Neches)' },
  { value: '120100021004', regionId: '05', label: '120100021004 (Neches)' },
  { value: '120100021005', regionId: '05', label: '120100021005 (Neches)' },
  { value: '120100021006', regionId: '05', label: '120100021006 (Neches)' },
  { value: '120100021007', regionId: '05', label: '120100021007 (Neches)' },
  { value: '120100020102', regionId: '05', label: '120100020102 (Neches)' },
  { value: '120100020104', regionId: '05', label: '120100020104 (Neches)' },
  { value: '120100020105', regionId: '05', label: '120100020105 (Neches)' },
  { value: '120100020301', regionId: '05', label: '120100020301 (Neches)' },
  { value: '120100020302', regionId: '05', label: '120100020302 (Neches)' },
  { value: '120100020404', regionId: '05', label: '120100020404 (Neches)' },
  { value: '120100020501', regionId: '05', label: '120100020501 (Neches)' },
  { value: '120100020604', regionId: '05', label: '120100020604 (Neches)' },
  { value: '120100020605', regionId: '05', label: '120100020605 (Neches)' },
  { value: '120200010101', regionId: '05', label: '120200010101 (Neches)' },
  { value: '120200010102', regionId: '05', label: '120200010102 (Neches)' },
  { value: '120200010103', regionId: '05', label: '120200010103 (Neches)' },
  { value: '120200010306', regionId: '05', label: '120200010306 (Neches)' },
  { value: '120200010307', regionId: '05', label: '120200010307 (Neches)' },
  { value: '120200010401', regionId: '05', label: '120200010401 (Neches)' },
  { value: '120200010402', regionId: '05', label: '120200010402 (Neches)' },
  { value: '120200010403', regionId: '05', label: '120200010403 (Neches)' },
  { value: '120200010404', regionId: '05', label: '120200010404 (Neches)' },
  { value: '120200010405', regionId: '05', label: '120200010405 (Neches)' },
  { value: '120200010406', regionId: '05', label: '120200010406 (Neches)' },
  { value: '120200010407', regionId: '05', label: '120200010407 (Neches)' },
  { value: '120200010408', regionId: '05', label: '120200010408 (Neches)' },
  { value: '120200010409', regionId: '05', label: '120200010409 (Neches)' },
  { value: '120200010501', regionId: '05', label: '120200010501 (Neches)' },
  { value: '120200010502', regionId: '05', label: '120200010502 (Neches)' },
  { value: '120200010503', regionId: '05', label: '120200010503 (Neches)' },
  { value: '120200010504', regionId: '05', label: '120200010504 (Neches)' },
  { value: '120200010505', regionId: '05', label: '120200010505 (Neches)' },
  { value: '120200010506', regionId: '05', label: '120200010506 (Neches)' },
  { value: '120200010507', regionId: '05', label: '120200010507 (Neches)' },
  { value: '120200010508', regionId: '05', label: '120200010508 (Neches)' },
  { value: '120200010509', regionId: '05', label: '120200010509 (Neches)' },
  { value: '120200010510', regionId: '05', label: '120200010510 (Neches)' },
  { value: '120200010511', regionId: '05', label: '120200010511 (Neches)' },
  { value: '120200010601', regionId: '05', label: '120200010601 (Neches)' },
  { value: '120200010602', regionId: '05', label: '120200010602 (Neches)' },
  { value: '120200010603', regionId: '05', label: '120200010603 (Neches)' },
  { value: '120200010701', regionId: '05', label: '120200010701 (Neches)' },
  { value: '120200010702', regionId: '05', label: '120200010702 (Neches)' },
  { value: '120200010703', regionId: '05', label: '120200010703 (Neches)' },
  { value: '120200010704', regionId: '05', label: '120200010704 (Neches)' },
  { value: '120200010705', regionId: '05', label: '120200010705 (Neches)' },
  { value: '120200010104', regionId: '05', label: '120200010104 (Neches)' },
  { value: '120200010105', regionId: '05', label: '120200010105 (Neches)' },
  { value: '120200010201', regionId: '05', label: '120200010201 (Neches)' },
  { value: '120200010202', regionId: '05', label: '120200010202 (Neches)' },
  { value: '120200010203', regionId: '05', label: '120200010203 (Neches)' },
  { value: '120200010204', regionId: '05', label: '120200010204 (Neches)' },
  { value: '120200010205', regionId: '05', label: '120200010205 (Neches)' },
  { value: '120200010206', regionId: '05', label: '120200010206 (Neches)' },
  { value: '120200010301', regionId: '05', label: '120200010301 (Neches)' },
  { value: '120200010302', regionId: '05', label: '120200010302 (Neches)' },
  { value: '120200010303', regionId: '05', label: '120200010303 (Neches)' },
  { value: '120200010304', regionId: '05', label: '120200010304 (Neches)' },
  { value: '120200010305', regionId: '05', label: '120200010305 (Neches)' },
  { value: '120200020101', regionId: '05', label: '120200020101 (Neches)' },
  { value: '120200020102', regionId: '05', label: '120200020102 (Neches)' },
  { value: '120200020103', regionId: '05', label: '120200020103 (Neches)' },
  { value: '120200020104', regionId: '05', label: '120200020104 (Neches)' },
  { value: '120200020105', regionId: '05', label: '120200020105 (Neches)' },
  { value: '120200020106', regionId: '05', label: '120200020106 (Neches)' },
  { value: '120200020201', regionId: '05', label: '120200020201 (Neches)' },
  { value: '120200020202', regionId: '05', label: '120200020202 (Neches)' },
  { value: '120200020203', regionId: '05', label: '120200020203 (Neches)' },
  { value: '120200020302', regionId: '05', label: '120200020302 (Neches)' },
  { value: '120200020303', regionId: '05', label: '120200020303 (Neches)' },
  { value: '120200020304', regionId: '05', label: '120200020304 (Neches)' },
  { value: '120200020305', regionId: '05', label: '120200020305 (Neches)' },
  { value: '120200020306', regionId: '05', label: '120200020306 (Neches)' },
  { value: '120200020307', regionId: '05', label: '120200020307 (Neches)' },
  { value: '120200020308', regionId: '05', label: '120200020308 (Neches)' },
  { value: '120200020401', regionId: '05', label: '120200020401 (Neches)' },
  { value: '120200020402', regionId: '05', label: '120200020402 (Neches)' },
  { value: '120200020403', regionId: '05', label: '120200020403 (Neches)' },
  { value: '120200020404', regionId: '05', label: '120200020404 (Neches)' },
  { value: '120200020405', regionId: '05', label: '120200020405 (Neches)' },
  { value: '120200020406', regionId: '05', label: '120200020406 (Neches)' },
  { value: '120200020407', regionId: '05', label: '120200020407 (Neches)' },
  { value: '120200020408', regionId: '05', label: '120200020408 (Neches)' },
  { value: '120200020409', regionId: '05', label: '120200020409 (Neches)' },
  { value: '120200020501', regionId: '05', label: '120200020501 (Neches)' },
  { value: '120200020502', regionId: '05', label: '120200020502 (Neches)' },
  { value: '120200020503', regionId: '05', label: '120200020503 (Neches)' },
  { value: '120200020504', regionId: '05', label: '120200020504 (Neches)' },
  { value: '120200020505', regionId: '05', label: '120200020505 (Neches)' },
  { value: '120200020506', regionId: '05', label: '120200020506 (Neches)' },
  { value: '120200020507', regionId: '05', label: '120200020507 (Neches)' },
  { value: '120200020508', regionId: '05', label: '120200020508 (Neches)' },
  { value: '120200020204', regionId: '05', label: '120200020204 (Neches)' },
  { value: '120200020205', regionId: '05', label: '120200020205 (Neches)' },
  { value: '120200020206', regionId: '05', label: '120200020206 (Neches)' },
  { value: '120200020207', regionId: '05', label: '120200020207 (Neches)' },
  { value: '120200020301', regionId: '05', label: '120200020301 (Neches)' },
  { value: '120200030404', regionId: '05', label: '120200030404 (Neches)' },
  { value: '120200030405', regionId: '05', label: '120200030405 (Neches)' },
  { value: '120200030406', regionId: '05', label: '120200030406 (Neches)' },
  { value: '120200030407', regionId: '05', label: '120200030407 (Neches)' },
  { value: '120200030101', regionId: '05', label: '120200030101 (Neches)' },
  { value: '120200030102', regionId: '05', label: '120200030102 (Neches)' },
  { value: '120200030103', regionId: '05', label: '120200030103 (Neches)' },
  { value: '120200030104', regionId: '05', label: '120200030104 (Neches)' },
  { value: '120200030105', regionId: '05', label: '120200030105 (Neches)' },
  { value: '120200030201', regionId: '05', label: '120200030201 (Neches)' },
  { value: '120200030202', regionId: '05', label: '120200030202 (Neches)' },
  { value: '120200030203', regionId: '05', label: '120200030203 (Neches)' },
  { value: '120200030204', regionId: '05', label: '120200030204 (Neches)' },
  { value: '120200030205', regionId: '05', label: '120200030205 (Neches)' },
  { value: '120200030401', regionId: '05', label: '120200030401 (Neches)' },
  { value: '120200030206', regionId: '05', label: '120200030206 (Neches)' },
  { value: '120200030301', regionId: '05', label: '120200030301 (Neches)' },
  { value: '120200030302', regionId: '05', label: '120200030302 (Neches)' },
  { value: '120200030303', regionId: '05', label: '120200030303 (Neches)' },
  { value: '120200030304', regionId: '05', label: '120200030304 (Neches)' },
  { value: '120200030305', regionId: '05', label: '120200030305 (Neches)' },
  { value: '120200030306', regionId: '05', label: '120200030306 (Neches)' },
  { value: '120200030307', regionId: '05', label: '120200030307 (Neches)' },
  { value: '120200030308', regionId: '05', label: '120200030308 (Neches)' },
  { value: '120200030402', regionId: '05', label: '120200030402 (Neches)' },
  { value: '120200030403', regionId: '05', label: '120200030403 (Neches)' },
  { value: '120200040205', regionId: '05', label: '120200040205 (Neches)' },
  { value: '120200040206', regionId: '05', label: '120200040206 (Neches)' },
  { value: '120200040207', regionId: '05', label: '120200040207 (Neches)' },
  { value: '120200040301', regionId: '05', label: '120200040301 (Neches)' },
  { value: '120200040302', regionId: '05', label: '120200040302 (Neches)' },
  { value: '120200040303', regionId: '05', label: '120200040303 (Neches)' },
  { value: '120200040304', regionId: '05', label: '120200040304 (Neches)' },
  { value: '120200040305', regionId: '05', label: '120200040305 (Neches)' },
  { value: '120200040401', regionId: '05', label: '120200040401 (Neches)' },
  { value: '120200040402', regionId: '05', label: '120200040402 (Neches)' },
  { value: '120200040403', regionId: '05', label: '120200040403 (Neches)' },
  { value: '120200040404', regionId: '05', label: '120200040404 (Neches)' },
  { value: '120200040405', regionId: '05', label: '120200040405 (Neches)' },
  { value: '120200040501', regionId: '05', label: '120200040501 (Neches)' },
  { value: '120200040502', regionId: '05', label: '120200040502 (Neches)' },
  { value: '120200040503', regionId: '05', label: '120200040503 (Neches)' },
  { value: '120200040504', regionId: '05', label: '120200040504 (Neches)' },
  { value: '120200040505', regionId: '05', label: '120200040505 (Neches)' },
  { value: '120200040506', regionId: '05', label: '120200040506 (Neches)' },
  { value: '120200040601', regionId: '05', label: '120200040601 (Neches)' },
  { value: '120200040602', regionId: '05', label: '120200040602 (Neches)' },
  { value: '120200040603', regionId: '05', label: '120200040603 (Neches)' },
  { value: '120200040604', regionId: '05', label: '120200040604 (Neches)' },
  { value: '120200040701', regionId: '05', label: '120200040701 (Neches)' },
  { value: '120200040702', regionId: '05', label: '120200040702 (Neches)' },
  { value: '120200040703', regionId: '05', label: '120200040703 (Neches)' },
  { value: '120200040704', regionId: '05', label: '120200040704 (Neches)' },
  { value: '120200040705', regionId: '05', label: '120200040705 (Neches)' },
  { value: '120200040101', regionId: '05', label: '120200040101 (Neches)' },
  { value: '120200040102', regionId: '05', label: '120200040102 (Neches)' },
  { value: '120200040103', regionId: '05', label: '120200040103 (Neches)' },
  { value: '120200040104', regionId: '05', label: '120200040104 (Neches)' },
  { value: '120200040105', regionId: '05', label: '120200040105 (Neches)' },
  { value: '120200040106', regionId: '05', label: '120200040106 (Neches)' },
  { value: '120200040201', regionId: '05', label: '120200040201 (Neches)' },
  { value: '120200040202', regionId: '05', label: '120200040202 (Neches)' },
  { value: '120200040203', regionId: '05', label: '120200040203 (Neches)' },
  { value: '120200040204', regionId: '05', label: '120200040204 (Neches)' },
  { value: '120200050101', regionId: '05', label: '120200050101 (Neches)' },
  { value: '120200050102', regionId: '05', label: '120200050102 (Neches)' },
  { value: '120200050107', regionId: '05', label: '120200050107 (Neches)' },
  { value: '120200050201', regionId: '05', label: '120200050201 (Neches)' },
  { value: '120200050202', regionId: '05', label: '120200050202 (Neches)' },
  { value: '120200050203', regionId: '05', label: '120200050203 (Neches)' },
  { value: '120200050204', regionId: '05', label: '120200050204 (Neches)' },
  { value: '120200050205', regionId: '05', label: '120200050205 (Neches)' },
  { value: '120200050301', regionId: '05', label: '120200050301 (Neches)' },
  { value: '120200050302', regionId: '05', label: '120200050302 (Neches)' },
  { value: '120200050303', regionId: '05', label: '120200050303 (Neches)' },
  { value: '120200050304', regionId: '05', label: '120200050304 (Neches)' },
  { value: '120200050305', regionId: '05', label: '120200050305 (Neches)' },
  { value: '120200050306', regionId: '05', label: '120200050306 (Neches)' },
  { value: '120200050307', regionId: '05', label: '120200050307 (Neches)' },
  { value: '120200050401', regionId: '05', label: '120200050401 (Neches)' },
  { value: '120200050402', regionId: '05', label: '120200050402 (Neches)' },
  { value: '120200050403', regionId: '05', label: '120200050403 (Neches)' },
  { value: '120200050404', regionId: '05', label: '120200050404 (Neches)' },
  { value: '120200050405', regionId: '05', label: '120200050405 (Neches)' },
  { value: '120200050406', regionId: '05', label: '120200050406 (Neches)' },
  { value: '120200050501', regionId: '05', label: '120200050501 (Neches)' },
  { value: '120200050502', regionId: '05', label: '120200050502 (Neches)' },
  { value: '120200050503', regionId: '05', label: '120200050503 (Neches)' },
  { value: '120200050601', regionId: '05', label: '120200050601 (Neches)' },
  { value: '120200050602', regionId: '05', label: '120200050602 (Neches)' },
  { value: '120200050603', regionId: '05', label: '120200050603 (Neches)' },
  { value: '120200050604', regionId: '05', label: '120200050604 (Neches)' },
  { value: '120200050701', regionId: '05', label: '120200050701 (Neches)' },
  { value: '120200050702', regionId: '05', label: '120200050702 (Neches)' },
  { value: '120200050703', regionId: '05', label: '120200050703 (Neches)' },
  { value: '120200050103', regionId: '05', label: '120200050103 (Neches)' },
  { value: '120200050104', regionId: '05', label: '120200050104 (Neches)' },
  { value: '120200050105', regionId: '05', label: '120200050105 (Neches)' },
  { value: '120200050106', regionId: '05', label: '120200050106 (Neches)' },
  { value: '120200050704', regionId: '05', label: '120200050704 (Neches)' },
  { value: '120200050705', regionId: '05', label: '120200050705 (Neches)' },
  { value: '120200050801', regionId: '05', label: '120200050801 (Neches)' },
  { value: '120200050802', regionId: '05', label: '120200050802 (Neches)' },
  { value: '120200050803', regionId: '05', label: '120200050803 (Neches)' },
  { value: '120200050804', regionId: '05', label: '120200050804 (Neches)' },
  { value: '120200050805', regionId: '05', label: '120200050805 (Neches)' },
  { value: '120200050808', regionId: '05', label: '120200050808 (Neches)' },
  { value: '120200050809', regionId: '05', label: '120200050809 (Neches)' },
  { value: '120200050901', regionId: '05', label: '120200050901 (Neches)' },
  { value: '120200050902', regionId: '05', label: '120200050902 (Neches)' },
  { value: '120200050903', regionId: '05', label: '120200050903 (Neches)' },
  { value: '120200050904', regionId: '05', label: '120200050904 (Neches)' },
  { value: '120200051001', regionId: '05', label: '120200051001 (Neches)' },
  { value: '120200051002', regionId: '05', label: '120200051002 (Neches)' },
  { value: '120200050806', regionId: '05', label: '120200050806 (Neches)' },
  { value: '120200050807', regionId: '05', label: '120200050807 (Neches)' },
  { value: '120200051003', regionId: '05', label: '120200051003 (Neches)' },
  { value: '120200060105', regionId: '05', label: '120200060105 (Neches)' },
  { value: '120200060106', regionId: '05', label: '120200060106 (Neches)' },
  { value: '120200060107', regionId: '05', label: '120200060107 (Neches)' },
  { value: '120200060201', regionId: '05', label: '120200060201 (Neches)' },
  { value: '120200060202', regionId: '05', label: '120200060202 (Neches)' },
  { value: '120200060203', regionId: '05', label: '120200060203 (Neches)' },
  { value: '120200060204', regionId: '05', label: '120200060204 (Neches)' },
  { value: '120200060205', regionId: '05', label: '120200060205 (Neches)' },
  { value: '120200060206', regionId: '05', label: '120200060206 (Neches)' },
  { value: '120200060207', regionId: '05', label: '120200060207 (Neches)' },
  { value: '120200060301', regionId: '05', label: '120200060301 (Neches)' },
  { value: '120200060302', regionId: '05', label: '120200060302 (Neches)' },
  { value: '120200060303', regionId: '05', label: '120200060303 (Neches)' },
  { value: '120200060304', regionId: '05', label: '120200060304 (Neches)' },
  { value: '120200060101', regionId: '05', label: '120200060101 (Neches)' },
  { value: '120200060102', regionId: '05', label: '120200060102 (Neches)' },
  { value: '120200060103', regionId: '05', label: '120200060103 (Neches)' },
  { value: '120200060104', regionId: '05', label: '120200060104 (Neches)' },
  { value: '120200060305', regionId: '05', label: '120200060305 (Neches)' },
  { value: '120200060306', regionId: '05', label: '120200060306 (Neches)' },
  { value: '120200060401', regionId: '05', label: '120200060401 (Neches)' },
  { value: '120200060402', regionId: '05', label: '120200060402 (Neches)' },
  { value: '120200060403', regionId: '05', label: '120200060403 (Neches)' },
  { value: '120200060404', regionId: '05', label: '120200060404 (Neches)' },
  { value: '120200060405', regionId: '05', label: '120200060405 (Neches)' },
  { value: '120200060406', regionId: '05', label: '120200060406 (Neches)' },
  { value: '120200060407', regionId: '05', label: '120200060407 (Neches)' },
  { value: '120200070103', regionId: '05', label: '120200070103 (Neches)' },
  { value: '120200070104', regionId: '05', label: '120200070104 (Neches)' },
  { value: '120200070108', regionId: '05', label: '120200070108 (Neches)' },
  { value: '120200070204', regionId: '05', label: '120200070204 (Neches)' },
  { value: '120200070301', regionId: '05', label: '120200070301 (Neches)' },
  { value: '120200070105', regionId: '05', label: '120200070105 (Neches)' },
  { value: '120200070101', regionId: '05', label: '120200070101 (Neches)' },
  { value: '120200070102', regionId: '05', label: '120200070102 (Neches)' },
  { value: '120200070106', regionId: '05', label: '120200070106 (Neches)' },
  { value: '120200070107', regionId: '05', label: '120200070107 (Neches)' },
  { value: '120200070109', regionId: '05', label: '120200070109 (Neches)' },
  { value: '120200070110', regionId: '05', label: '120200070110 (Neches)' },
  { value: '120200070201', regionId: '05', label: '120200070201 (Neches)' },
  { value: '120200070202', regionId: '05', label: '120200070202 (Neches)' },
  { value: '120200070203', regionId: '05', label: '120200070203 (Neches)' },
  { value: '120200070205', regionId: '05', label: '120200070205 (Neches)' },
  { value: '120200070302', regionId: '05', label: '120200070302 (Neches)' },
  { value: '120200070303', regionId: '05', label: '120200070303 (Neches)' },
  { value: '120200070304', regionId: '05', label: '120200070304 (Neches)' },
  { value: '120301070301', regionId: '05', label: '120301070301 (Neches)' },
  { value: '120301070305', regionId: '05', label: '120301070305 (Neches)' },
  { value: '120301070306', regionId: '05', label: '120301070306 (Neches)' },
  { value: '120301070307', regionId: '05', label: '120301070307 (Neches)' },
  { value: '120301070308', regionId: '05', label: '120301070308 (Neches)' },
  { value: '120302010705', regionId: '05', label: '120302010705 (Neches)' },
  { value: '120302010202', regionId: '05', label: '120302010202 (Neches)' },
  { value: '120302010203', regionId: '05', label: '120302010203 (Neches)' },
  { value: '120302010205', regionId: '05', label: '120302010205 (Neches)' },
  { value: '120302010206', regionId: '05', label: '120302010206 (Neches)' },
  { value: '120302010401', regionId: '05', label: '120302010401 (Neches)' },
  { value: '120302010402', regionId: '05', label: '120302010402 (Neches)' },
  { value: '120302010404', regionId: '05', label: '120302010404 (Neches)' },
  { value: '120302010405', regionId: '05', label: '120302010405 (Neches)' },
  { value: '120302010702', regionId: '05', label: '120302010702 (Neches)' },
  { value: '120302010703', regionId: '05', label: '120302010703 (Neches)' },
  { value: '120302020701', regionId: '05', label: '120302020701 (Neches)' },
  { value: '120302020703', regionId: '05', label: '120302020703 (Neches)' },
  { value: '120302020711', regionId: '05', label: '120302020711 (Neches)' },
  { value: '120302020901', regionId: '05', label: '120302020901 (Neches)' },
  { value: '120302021001', regionId: '05', label: '120302021001 (Neches)' },
  { value: '120302021002', regionId: '05', label: '120302021002 (Neches)' },
  { value: '120302020902', regionId: '05', label: '120302020902 (Neches)' },
  { value: '120302021003', regionId: '05', label: '120302021003 (Neches)' },
  { value: '120302021203', regionId: '05', label: '120302021203 (Neches)' },
  { value: '120302021204', regionId: '05', label: '120302021204 (Neches)' },
  { value: '120302021205', regionId: '05', label: '120302021205 (Neches)' },
  { value: '120302030102', regionId: '05', label: '120302030102 (Neches)' },
  { value: '120302030104', regionId: '05', label: '120302030104 (Neches)' },
  { value: '120302030107', regionId: '05', label: '120302030107 (Neches)' },
  { value: '120302030109', regionId: '05', label: '120302030109 (Neches)' },
  { value: '120302030201', regionId: '05', label: '120302030201 (Neches)' },
  { value: '120302030202', regionId: '05', label: '120302030202 (Neches)' },
  { value: '120302030307', regionId: '05', label: '120302030307 (Neches)' },
  { value: '120402020100', regionId: '05', label: '120402020100 (Neches)' },
  { value: '120402020200', regionId: '05', label: '120402020200 (Neches)' },
  { value: '120402020300', regionId: '05', label: '120402020300 (Neches)' },
  { value: '120402020400', regionId: '05', label: '120402020400 (Neches)' },
  { value: '120402020500', regionId: '05', label: '120402020500 (Neches)' },
  { value: '120402030200', regionId: '05', label: '120402030200 (Neches)' },
  { value: '120402040100', regionId: '05', label: '120402040100 (Neches)' },
  { value: '120402040200', regionId: '05', label: '120402040200 (Neches)' },
  { value: '120402040500', regionId: '05', label: '120402040500 (Neches)' },
  { value: '120100040101', regionId: '05', label: '120100040101 (Neches)' },
  { value: '120100040102', regionId: '05', label: '120100040102 (Neches)' },
  { value: '120100040201', regionId: '05', label: '120100040201 (Neches)' },
  { value: '120100040202', regionId: '05', label: '120100040202 (Neches)' },
  { value: '120100040701', regionId: '05', label: '120100040701 (Neches)' },
  { value: '120100041003', regionId: '05', label: '120100041003 (Neches)' },
  { value: '120100040901', regionId: '05', label: '120100040901 (Neches)' },
  { value: '120100040902', regionId: '05', label: '120100040902 (Neches)' },
  { value: '120100041101', regionId: '05', label: '120100041101 (Neches)' },
  { value: '120100041103', regionId: '05', label: '120100041103 (Neches)' },
  { value: '120402010500', regionId: '05', label: '120402010500 (Neches)' },
  { value: '120402010100', regionId: '05', label: '120402010100 (Neches)' },
  { value: '120402010200', regionId: '05', label: '120402010200 (Neches)' },
  { value: '120402010300', regionId: '05', label: '120402010300 (Neches)' },
  { value: '120100051100', regionId: '05', label: '120100051100 (Neches)' },
  { value: '120100050602', regionId: '05', label: '120100050602 (Neches)' },
  { value: '120100050603', regionId: '05', label: '120100050603 (Neches)' },
  { value: '120100050604', regionId: '05', label: '120100050604 (Neches)' },
  { value: '120100050607', regionId: '05', label: '120100050607 (Neches)' },
  { value: '120100050801', regionId: '05', label: '120100050801 (Neches)' },
  { value: '120100050803', regionId: '05', label: '120100050803 (Neches)' },
  { value: '120100050902', regionId: '05', label: '120100050902 (Neches)' },
  { value: '120100051002', regionId: '05', label: '120100051002 (Neches)' },
  { value: '120100051003', regionId: '05', label: '120100051003 (Neches)' },
  { value: '120100051004', regionId: '05', label: '120100051004 (Neches)' },
  { value: '120100051005', regionId: '05', label: '120100051005 (Neches)' },
]

const hucsRegion13 = [
  { value: '121002010102', regionId: '13', label: '121002010102 (Nueces)' },
  { value: '121002010106', regionId: '13', label: '121002010106 (Nueces)' },
  { value: '121003010301', regionId: '13', label: '121003010301 (Nueces)' },
  { value: '121003030101', regionId: '13', label: '121003030101 (Nueces)' },
  { value: '121003030104', regionId: '13', label: '121003030104 (Nueces)' },
  { value: '121003030106', regionId: '13', label: '121003030106 (Nueces)' },
  { value: '121003030201', regionId: '13', label: '121003030201 (Nueces)' },
  { value: '121003030202', regionId: '13', label: '121003030202 (Nueces)' },
  { value: '121003030401', regionId: '13', label: '121003030401 (Nueces)' },
  { value: '121003030402', regionId: '13', label: '121003030402 (Nueces)' },
  { value: '121003030405', regionId: '13', label: '121003030405 (Nueces)' },
  { value: '121003030504', regionId: '13', label: '121003030504 (Nueces)' },
  { value: '121003030505', regionId: '13', label: '121003030505 (Nueces)' },
  { value: '121003030604', regionId: '13', label: '121003030604 (Nueces)' },
  { value: '121003030605', regionId: '13', label: '121003030605 (Nueces)' },
  { value: '121003030606', regionId: '13', label: '121003030606 (Nueces)' },
  { value: '121003030607', regionId: '13', label: '121003030607 (Nueces)' },
  { value: '121003030608', regionId: '13', label: '121003030608 (Nueces)' },
  { value: '121004030200', regionId: '13', label: '121004030200 (Nueces)' },
  { value: '121004040000', regionId: '13', label: '121004040000 (Nueces)' },
  { value: '121004050101', regionId: '13', label: '121004050101 (Nueces)' },
  { value: '121004050202', regionId: '13', label: '121004050202 (Nueces)' },
  { value: '121004050400', regionId: '13', label: '121004050400 (Nueces)' },
  { value: '121004050102', regionId: '13', label: '121004050102 (Nueces)' },
  { value: '121004050103', regionId: '13', label: '121004050103 (Nueces)' },
  { value: '121004050201', regionId: '13', label: '121004050201 (Nueces)' },
  { value: '121004050203', regionId: '13', label: '121004050203 (Nueces)' },
  { value: '121004050204', regionId: '13', label: '121004050204 (Nueces)' },
  { value: '121004050205', regionId: '13', label: '121004050205 (Nueces)' },
  { value: '121004050301', regionId: '13', label: '121004050301 (Nueces)' },
  { value: '121004050302', regionId: '13', label: '121004050302 (Nueces)' },
  { value: '121004050303', regionId: '13', label: '121004050303 (Nueces)' },
  { value: '121004050304', regionId: '13', label: '121004050304 (Nueces)' },
  { value: '121004050305', regionId: '13', label: '121004050305 (Nueces)' },
  { value: '121004050306', regionId: '13', label: '121004050306 (Nueces)' },
  { value: '121004050307', regionId: '13', label: '121004050307 (Nueces)' },
  { value: '121004050308', regionId: '13', label: '121004050308 (Nueces)' },
  { value: '121004050500', regionId: '13', label: '121004050500 (Nueces)' },
  { value: '121004060301', regionId: '13', label: '121004060301 (Nueces)' },
  { value: '121004060304', regionId: '13', label: '121004060304 (Nueces)' },
  { value: '121004060306', regionId: '13', label: '121004060306 (Nueces)' },
  { value: '121004060307', regionId: '13', label: '121004060307 (Nueces)' },
  { value: '121004060205', regionId: '13', label: '121004060205 (Nueces)' },
  { value: '121004060206', regionId: '13', label: '121004060206 (Nueces)' },
  { value: '121004060207', regionId: '13', label: '121004060207 (Nueces)' },
  { value: '121004060208', regionId: '13', label: '121004060208 (Nueces)' },
  { value: '121004060209', regionId: '13', label: '121004060209 (Nueces)' },
  { value: '121004060302', regionId: '13', label: '121004060302 (Nueces)' },
  { value: '121004060303', regionId: '13', label: '121004060303 (Nueces)' },
  { value: '121004060305', regionId: '13', label: '121004060305 (Nueces)' },
  { value: '121004060101', regionId: '13', label: '121004060101 (Nueces)' },
  { value: '121004060102', regionId: '13', label: '121004060102 (Nueces)' },
  { value: '121004060103', regionId: '13', label: '121004060103 (Nueces)' },
  { value: '121004060104', regionId: '13', label: '121004060104 (Nueces)' },
  { value: '121004060105', regionId: '13', label: '121004060105 (Nueces)' },
  { value: '121004060106', regionId: '13', label: '121004060106 (Nueces)' },
  { value: '121004060107', regionId: '13', label: '121004060107 (Nueces)' },
  { value: '121004060108', regionId: '13', label: '121004060108 (Nueces)' },
  { value: '121004060109', regionId: '13', label: '121004060109 (Nueces)' },
  { value: '121004060201', regionId: '13', label: '121004060201 (Nueces)' },
  { value: '121004060202', regionId: '13', label: '121004060202 (Nueces)' },
  { value: '121004060203', regionId: '13', label: '121004060203 (Nueces)' },
  { value: '121004060204', regionId: '13', label: '121004060204 (Nueces)' },
  { value: '121004070101', regionId: '13', label: '121004070101 (Nueces)' },
  { value: '121004070102', regionId: '13', label: '121004070102 (Nueces)' },
  { value: '121004070103', regionId: '13', label: '121004070103 (Nueces)' },
  { value: '121004070104', regionId: '13', label: '121004070104 (Nueces)' },
  { value: '121004070304', regionId: '13', label: '121004070304 (Nueces)' },
  { value: '121004070404', regionId: '13', label: '121004070404 (Nueces)' },
  { value: '121004070105', regionId: '13', label: '121004070105 (Nueces)' },
  { value: '121004070106', regionId: '13', label: '121004070106 (Nueces)' },
  { value: '121004070201', regionId: '13', label: '121004070201 (Nueces)' },
  { value: '121004070202', regionId: '13', label: '121004070202 (Nueces)' },
  { value: '121004070203', regionId: '13', label: '121004070203 (Nueces)' },
  { value: '121004070204', regionId: '13', label: '121004070204 (Nueces)' },
  { value: '121004070205', regionId: '13', label: '121004070205 (Nueces)' },
  { value: '121004070301', regionId: '13', label: '121004070301 (Nueces)' },
  { value: '121004070302', regionId: '13', label: '121004070302 (Nueces)' },
  { value: '121004070303', regionId: '13', label: '121004070303 (Nueces)' },
  { value: '121004070206', regionId: '13', label: '121004070206 (Nueces)' },
  { value: '121004070305', regionId: '13', label: '121004070305 (Nueces)' },
  { value: '121004070401', regionId: '13', label: '121004070401 (Nueces)' },
  { value: '121004070402', regionId: '13', label: '121004070402 (Nueces)' },
  { value: '121004070403', regionId: '13', label: '121004070403 (Nueces)' },
  { value: '121101010101', regionId: '13', label: '121101010101 (Nueces)' },
  { value: '121101010102', regionId: '13', label: '121101010102 (Nueces)' },
  { value: '121101010103', regionId: '13', label: '121101010103 (Nueces)' },
  { value: '121101010104', regionId: '13', label: '121101010104 (Nueces)' },
  { value: '121101010105', regionId: '13', label: '121101010105 (Nueces)' },
  { value: '121101010201', regionId: '13', label: '121101010201 (Nueces)' },
  { value: '121101010202', regionId: '13', label: '121101010202 (Nueces)' },
  { value: '121101010203', regionId: '13', label: '121101010203 (Nueces)' },
  { value: '121101010204', regionId: '13', label: '121101010204 (Nueces)' },
  { value: '121101010205', regionId: '13', label: '121101010205 (Nueces)' },
  { value: '121101010301', regionId: '13', label: '121101010301 (Nueces)' },
  { value: '121101010302', regionId: '13', label: '121101010302 (Nueces)' },
  { value: '121101010303', regionId: '13', label: '121101010303 (Nueces)' },
  { value: '121101010304', regionId: '13', label: '121101010304 (Nueces)' },
  { value: '121101010305', regionId: '13', label: '121101010305 (Nueces)' },
  { value: '121101010401', regionId: '13', label: '121101010401 (Nueces)' },
  { value: '121101010402', regionId: '13', label: '121101010402 (Nueces)' },
  { value: '121101010403', regionId: '13', label: '121101010403 (Nueces)' },
  { value: '121101010404', regionId: '13', label: '121101010404 (Nueces)' },
  { value: '121101010405', regionId: '13', label: '121101010405 (Nueces)' },
  { value: '121101010406', regionId: '13', label: '121101010406 (Nueces)' },
  { value: '121101020101', regionId: '13', label: '121101020101 (Nueces)' },
  { value: '121101020102', regionId: '13', label: '121101020102 (Nueces)' },
  { value: '121101020103', regionId: '13', label: '121101020103 (Nueces)' },
  { value: '121101020104', regionId: '13', label: '121101020104 (Nueces)' },
  { value: '121101020105', regionId: '13', label: '121101020105 (Nueces)' },
  { value: '121101020201', regionId: '13', label: '121101020201 (Nueces)' },
  { value: '121101020202', regionId: '13', label: '121101020202 (Nueces)' },
  { value: '121101020203', regionId: '13', label: '121101020203 (Nueces)' },
  { value: '121101020204', regionId: '13', label: '121101020204 (Nueces)' },
  { value: '121101020205', regionId: '13', label: '121101020205 (Nueces)' },
  { value: '121101020206', regionId: '13', label: '121101020206 (Nueces)' },
  { value: '121101020301', regionId: '13', label: '121101020301 (Nueces)' },
  { value: '121101020302', regionId: '13', label: '121101020302 (Nueces)' },
  { value: '121101020303', regionId: '13', label: '121101020303 (Nueces)' },
  { value: '121101020304', regionId: '13', label: '121101020304 (Nueces)' },
  { value: '121101020305', regionId: '13', label: '121101020305 (Nueces)' },
  { value: '121101020401', regionId: '13', label: '121101020401 (Nueces)' },
  { value: '121101020402', regionId: '13', label: '121101020402 (Nueces)' },
  { value: '121101020403', regionId: '13', label: '121101020403 (Nueces)' },
  { value: '121101020404', regionId: '13', label: '121101020404 (Nueces)' },
  { value: '121101020405', regionId: '13', label: '121101020405 (Nueces)' },
  { value: '121101020406', regionId: '13', label: '121101020406 (Nueces)' },
  { value: '121101030202', regionId: '13', label: '121101030202 (Nueces)' },
  { value: '121101030203', regionId: '13', label: '121101030203 (Nueces)' },
  { value: '121101030101', regionId: '13', label: '121101030101 (Nueces)' },
  { value: '121101030102', regionId: '13', label: '121101030102 (Nueces)' },
  { value: '121101030103', regionId: '13', label: '121101030103 (Nueces)' },
  { value: '121101030104', regionId: '13', label: '121101030104 (Nueces)' },
  { value: '121101030105', regionId: '13', label: '121101030105 (Nueces)' },
  { value: '121101030201', regionId: '13', label: '121101030201 (Nueces)' },
  { value: '121101030301', regionId: '13', label: '121101030301 (Nueces)' },
  { value: '121101030204', regionId: '13', label: '121101030204 (Nueces)' },
  { value: '121101030206', regionId: '13', label: '121101030206 (Nueces)' },
  { value: '121101030205', regionId: '13', label: '121101030205 (Nueces)' },
  { value: '121101030207', regionId: '13', label: '121101030207 (Nueces)' },
  { value: '121101030302', regionId: '13', label: '121101030302 (Nueces)' },
  { value: '121101030303', regionId: '13', label: '121101030303 (Nueces)' },
  { value: '121101030304', regionId: '13', label: '121101030304 (Nueces)' },
  { value: '121101030305', regionId: '13', label: '121101030305 (Nueces)' },
  { value: '121101030306', regionId: '13', label: '121101030306 (Nueces)' },
  { value: '121101030402', regionId: '13', label: '121101030402 (Nueces)' },
  { value: '121101030401', regionId: '13', label: '121101030401 (Nueces)' },
  { value: '121101030403', regionId: '13', label: '121101030403 (Nueces)' },
  { value: '121101030404', regionId: '13', label: '121101030404 (Nueces)' },
  { value: '121101030405', regionId: '13', label: '121101030405 (Nueces)' },
  { value: '121101030501', regionId: '13', label: '121101030501 (Nueces)' },
  { value: '121101030502', regionId: '13', label: '121101030502 (Nueces)' },
  { value: '121101030503', regionId: '13', label: '121101030503 (Nueces)' },
  { value: '121101030504', regionId: '13', label: '121101030504 (Nueces)' },
  { value: '121101030505', regionId: '13', label: '121101030505 (Nueces)' },
  { value: '121101030506', regionId: '13', label: '121101030506 (Nueces)' },
  { value: '121101030507', regionId: '13', label: '121101030507 (Nueces)' },
  { value: '121101030601', regionId: '13', label: '121101030601 (Nueces)' },
  { value: '121101030602', regionId: '13', label: '121101030602 (Nueces)' },
  { value: '121101030603', regionId: '13', label: '121101030603 (Nueces)' },
  { value: '121101030702', regionId: '13', label: '121101030702 (Nueces)' },
  { value: '121101030604', regionId: '13', label: '121101030604 (Nueces)' },
  { value: '121101030605', regionId: '13', label: '121101030605 (Nueces)' },
  { value: '121101030606', regionId: '13', label: '121101030606 (Nueces)' },
  { value: '121101030701', regionId: '13', label: '121101030701 (Nueces)' },
  { value: '121101030703', regionId: '13', label: '121101030703 (Nueces)' },
  { value: '121101030704', regionId: '13', label: '121101030704 (Nueces)' },
  { value: '121101030705', regionId: '13', label: '121101030705 (Nueces)' },
  { value: '121101040203', regionId: '13', label: '121101040203 (Nueces)' },
  { value: '121101040101', regionId: '13', label: '121101040101 (Nueces)' },
  { value: '121101040102', regionId: '13', label: '121101040102 (Nueces)' },
  { value: '121101040103', regionId: '13', label: '121101040103 (Nueces)' },
  { value: '121101040104', regionId: '13', label: '121101040104 (Nueces)' },
  { value: '121101040105', regionId: '13', label: '121101040105 (Nueces)' },
  { value: '121101040304', regionId: '13', label: '121101040304 (Nueces)' },
  { value: '121101040106', regionId: '13', label: '121101040106 (Nueces)' },
  { value: '121101040107', regionId: '13', label: '121101040107 (Nueces)' },
  { value: '121101040108', regionId: '13', label: '121101040108 (Nueces)' },
  { value: '121101040201', regionId: '13', label: '121101040201 (Nueces)' },
  { value: '121101040202', regionId: '13', label: '121101040202 (Nueces)' },
  { value: '121101040204', regionId: '13', label: '121101040204 (Nueces)' },
  { value: '121101040205', regionId: '13', label: '121101040205 (Nueces)' },
  { value: '121101040301', regionId: '13', label: '121101040301 (Nueces)' },
  { value: '121101040302', regionId: '13', label: '121101040302 (Nueces)' },
  { value: '121101040303', regionId: '13', label: '121101040303 (Nueces)' },
  { value: '121101040306', regionId: '13', label: '121101040306 (Nueces)' },
  { value: '121101040305', regionId: '13', label: '121101040305 (Nueces)' },
  { value: '121101040307', regionId: '13', label: '121101040307 (Nueces)' },
  { value: '121101040308', regionId: '13', label: '121101040308 (Nueces)' },
  { value: '121101040309', regionId: '13', label: '121101040309 (Nueces)' },
  { value: '121101040310', regionId: '13', label: '121101040310 (Nueces)' },
  { value: '121101040401', regionId: '13', label: '121101040401 (Nueces)' },
  { value: '121101040402', regionId: '13', label: '121101040402 (Nueces)' },
  { value: '121101040403', regionId: '13', label: '121101040403 (Nueces)' },
  { value: '121101040404', regionId: '13', label: '121101040404 (Nueces)' },
  { value: '121101040405', regionId: '13', label: '121101040405 (Nueces)' },
  { value: '121101040501', regionId: '13', label: '121101040501 (Nueces)' },
  { value: '121101040502', regionId: '13', label: '121101040502 (Nueces)' },
  { value: '121101040503', regionId: '13', label: '121101040503 (Nueces)' },
  { value: '121101040504', regionId: '13', label: '121101040504 (Nueces)' },
  { value: '121101040505', regionId: '13', label: '121101040505 (Nueces)' },
  { value: '121101040601', regionId: '13', label: '121101040601 (Nueces)' },
  { value: '121101040602', regionId: '13', label: '121101040602 (Nueces)' },
  { value: '121101040603', regionId: '13', label: '121101040603 (Nueces)' },
  { value: '121101040604', regionId: '13', label: '121101040604 (Nueces)' },
  { value: '121101040605', regionId: '13', label: '121101040605 (Nueces)' },
  { value: '121101050802', regionId: '13', label: '121101050802 (Nueces)' },
  { value: '121101050803', regionId: '13', label: '121101050803 (Nueces)' },
  { value: '121101050804', regionId: '13', label: '121101050804 (Nueces)' },
  { value: '121101050805', regionId: '13', label: '121101050805 (Nueces)' },
  { value: '121101050806', regionId: '13', label: '121101050806 (Nueces)' },
  { value: '121101050807', regionId: '13', label: '121101050807 (Nueces)' },
  { value: '121101050808', regionId: '13', label: '121101050808 (Nueces)' },
  { value: '121101050809', regionId: '13', label: '121101050809 (Nueces)' },
  { value: '121101050901', regionId: '13', label: '121101050901 (Nueces)' },
  { value: '121101050902', regionId: '13', label: '121101050902 (Nueces)' },
  { value: '121101050903', regionId: '13', label: '121101050903 (Nueces)' },
  { value: '121101050904', regionId: '13', label: '121101050904 (Nueces)' },
  { value: '121101050905', regionId: '13', label: '121101050905 (Nueces)' },
  { value: '121101050906', regionId: '13', label: '121101050906 (Nueces)' },
  { value: '121101050907', regionId: '13', label: '121101050907 (Nueces)' },
  { value: '121101051001', regionId: '13', label: '121101051001 (Nueces)' },
  { value: '121101051002', regionId: '13', label: '121101051002 (Nueces)' },
  { value: '121101051003', regionId: '13', label: '121101051003 (Nueces)' },
  { value: '121101051004', regionId: '13', label: '121101051004 (Nueces)' },
  { value: '121101051005', regionId: '13', label: '121101051005 (Nueces)' },
  { value: '121101051006', regionId: '13', label: '121101051006 (Nueces)' },
  { value: '121101051101', regionId: '13', label: '121101051101 (Nueces)' },
  { value: '121101051103', regionId: '13', label: '121101051103 (Nueces)' },
  { value: '121101051007', regionId: '13', label: '121101051007 (Nueces)' },
  { value: '121101051008', regionId: '13', label: '121101051008 (Nueces)' },
  { value: '121101051009', regionId: '13', label: '121101051009 (Nueces)' },
  { value: '121101051102', regionId: '13', label: '121101051102 (Nueces)' },
  { value: '121101051104', regionId: '13', label: '121101051104 (Nueces)' },
  { value: '121101051105', regionId: '13', label: '121101051105 (Nueces)' },
  { value: '121101051106', regionId: '13', label: '121101051106 (Nueces)' },
  { value: '121101051201', regionId: '13', label: '121101051201 (Nueces)' },
  { value: '121101051202', regionId: '13', label: '121101051202 (Nueces)' },
  { value: '121101051203', regionId: '13', label: '121101051203 (Nueces)' },
  { value: '121101051204', regionId: '13', label: '121101051204 (Nueces)' },
  { value: '121101051205', regionId: '13', label: '121101051205 (Nueces)' },
  { value: '121101051206', regionId: '13', label: '121101051206 (Nueces)' },
  { value: '121101050204', regionId: '13', label: '121101050204 (Nueces)' },
  { value: '121101050205', regionId: '13', label: '121101050205 (Nueces)' },
  { value: '121101050101', regionId: '13', label: '121101050101 (Nueces)' },
  { value: '121101050102', regionId: '13', label: '121101050102 (Nueces)' },
  { value: '121101050103', regionId: '13', label: '121101050103 (Nueces)' },
  { value: '121101050104', regionId: '13', label: '121101050104 (Nueces)' },
  { value: '121101050105', regionId: '13', label: '121101050105 (Nueces)' },
  { value: '121101050106', regionId: '13', label: '121101050106 (Nueces)' },
  { value: '121101050201', regionId: '13', label: '121101050201 (Nueces)' },
  { value: '121101050202', regionId: '13', label: '121101050202 (Nueces)' },
  { value: '121101050203', regionId: '13', label: '121101050203 (Nueces)' },
  { value: '121101050302', regionId: '13', label: '121101050302 (Nueces)' },
  { value: '121101050206', regionId: '13', label: '121101050206 (Nueces)' },
  { value: '121101050207', regionId: '13', label: '121101050207 (Nueces)' },
  { value: '121101050301', regionId: '13', label: '121101050301 (Nueces)' },
  { value: '121101050303', regionId: '13', label: '121101050303 (Nueces)' },
  { value: '121101050304', regionId: '13', label: '121101050304 (Nueces)' },
  { value: '121101050305', regionId: '13', label: '121101050305 (Nueces)' },
  { value: '121101050401', regionId: '13', label: '121101050401 (Nueces)' },
  { value: '121101050402', regionId: '13', label: '121101050402 (Nueces)' },
  { value: '121101050403', regionId: '13', label: '121101050403 (Nueces)' },
  { value: '121101050404', regionId: '13', label: '121101050404 (Nueces)' },
  { value: '121101050405', regionId: '13', label: '121101050405 (Nueces)' },
  { value: '121101050406', regionId: '13', label: '121101050406 (Nueces)' },
  { value: '121101050501', regionId: '13', label: '121101050501 (Nueces)' },
  { value: '121101050502', regionId: '13', label: '121101050502 (Nueces)' },
  { value: '121101050503', regionId: '13', label: '121101050503 (Nueces)' },
  { value: '121101050504', regionId: '13', label: '121101050504 (Nueces)' },
  { value: '121101050505', regionId: '13', label: '121101050505 (Nueces)' },
  { value: '121101050506', regionId: '13', label: '121101050506 (Nueces)' },
  { value: '121101050507', regionId: '13', label: '121101050507 (Nueces)' },
  { value: '121101050601', regionId: '13', label: '121101050601 (Nueces)' },
  { value: '121101050602', regionId: '13', label: '121101050602 (Nueces)' },
  { value: '121101050603', regionId: '13', label: '121101050603 (Nueces)' },
  { value: '121101050604', regionId: '13', label: '121101050604 (Nueces)' },
  { value: '121101050605', regionId: '13', label: '121101050605 (Nueces)' },
  { value: '121101050701', regionId: '13', label: '121101050701 (Nueces)' },
  { value: '121101050702', regionId: '13', label: '121101050702 (Nueces)' },
  { value: '121101050703', regionId: '13', label: '121101050703 (Nueces)' },
  { value: '121101050704', regionId: '13', label: '121101050704 (Nueces)' },
  { value: '121101050705', regionId: '13', label: '121101050705 (Nueces)' },
  { value: '121101050706', regionId: '13', label: '121101050706 (Nueces)' },
  { value: '121101050707', regionId: '13', label: '121101050707 (Nueces)' },
  { value: '121101050708', regionId: '13', label: '121101050708 (Nueces)' },
  { value: '121101050709', regionId: '13', label: '121101050709 (Nueces)' },
  { value: '121101050801', regionId: '13', label: '121101050801 (Nueces)' },
  { value: '121101060403', regionId: '13', label: '121101060403 (Nueces)' },
  { value: '121101060504', regionId: '13', label: '121101060504 (Nueces)' },
  { value: '121101060701', regionId: '13', label: '121101060701 (Nueces)' },
  { value: '121101060702', regionId: '13', label: '121101060702 (Nueces)' },
  { value: '121101060703', regionId: '13', label: '121101060703 (Nueces)' },
  { value: '121101060803', regionId: '13', label: '121101060803 (Nueces)' },
  { value: '121101060101', regionId: '13', label: '121101060101 (Nueces)' },
  { value: '121101060102', regionId: '13', label: '121101060102 (Nueces)' },
  { value: '121101060103', regionId: '13', label: '121101060103 (Nueces)' },
  { value: '121101060104', regionId: '13', label: '121101060104 (Nueces)' },
  { value: '121101060105', regionId: '13', label: '121101060105 (Nueces)' },
  { value: '121101060202', regionId: '13', label: '121101060202 (Nueces)' },
  { value: '121101060203', regionId: '13', label: '121101060203 (Nueces)' },
  { value: '121101060204', regionId: '13', label: '121101060204 (Nueces)' },
  { value: '121101060205', regionId: '13', label: '121101060205 (Nueces)' },
  { value: '121101060106', regionId: '13', label: '121101060106 (Nueces)' },
  { value: '121101060107', regionId: '13', label: '121101060107 (Nueces)' },
  { value: '121101060201', regionId: '13', label: '121101060201 (Nueces)' },
  { value: '121101060206', regionId: '13', label: '121101060206 (Nueces)' },
  { value: '121101060301', regionId: '13', label: '121101060301 (Nueces)' },
  { value: '121101060302', regionId: '13', label: '121101060302 (Nueces)' },
  { value: '121101060303', regionId: '13', label: '121101060303 (Nueces)' },
  { value: '121101060304', regionId: '13', label: '121101060304 (Nueces)' },
  { value: '121101060305', regionId: '13', label: '121101060305 (Nueces)' },
  { value: '121101060401', regionId: '13', label: '121101060401 (Nueces)' },
  { value: '121101060402', regionId: '13', label: '121101060402 (Nueces)' },
  { value: '121101060404', regionId: '13', label: '121101060404 (Nueces)' },
  { value: '121101060503', regionId: '13', label: '121101060503 (Nueces)' },
  { value: '121101060405', regionId: '13', label: '121101060405 (Nueces)' },
  { value: '121101060501', regionId: '13', label: '121101060501 (Nueces)' },
  { value: '121101060502', regionId: '13', label: '121101060502 (Nueces)' },
  { value: '121101060505', regionId: '13', label: '121101060505 (Nueces)' },
  { value: '121101060601', regionId: '13', label: '121101060601 (Nueces)' },
  { value: '121101060602', regionId: '13', label: '121101060602 (Nueces)' },
  { value: '121101060603', regionId: '13', label: '121101060603 (Nueces)' },
  { value: '121101060604', regionId: '13', label: '121101060604 (Nueces)' },
  { value: '121101060605', regionId: '13', label: '121101060605 (Nueces)' },
  { value: '121101060606', regionId: '13', label: '121101060606 (Nueces)' },
  { value: '121101060704', regionId: '13', label: '121101060704 (Nueces)' },
  { value: '121101060705', regionId: '13', label: '121101060705 (Nueces)' },
  { value: '121101060706', regionId: '13', label: '121101060706 (Nueces)' },
  { value: '121101060801', regionId: '13', label: '121101060801 (Nueces)' },
  { value: '121101060802', regionId: '13', label: '121101060802 (Nueces)' },
  { value: '121101060804', regionId: '13', label: '121101060804 (Nueces)' },
  { value: '121101061001', regionId: '13', label: '121101061001 (Nueces)' },
  { value: '121101061002', regionId: '13', label: '121101061002 (Nueces)' },
  { value: '121101060805', regionId: '13', label: '121101060805 (Nueces)' },
  { value: '121101060901', regionId: '13', label: '121101060901 (Nueces)' },
  { value: '121101060902', regionId: '13', label: '121101060902 (Nueces)' },
  { value: '121101060903', regionId: '13', label: '121101060903 (Nueces)' },
  { value: '121101060904', regionId: '13', label: '121101060904 (Nueces)' },
  { value: '121101060905', regionId: '13', label: '121101060905 (Nueces)' },
  { value: '121101060906', regionId: '13', label: '121101060906 (Nueces)' },
  { value: '121101061003', regionId: '13', label: '121101061003 (Nueces)' },
  { value: '121101061004', regionId: '13', label: '121101061004 (Nueces)' },
  { value: '121101061203', regionId: '13', label: '121101061203 (Nueces)' },
  { value: '121101061005', regionId: '13', label: '121101061005 (Nueces)' },
  { value: '121101061006', regionId: '13', label: '121101061006 (Nueces)' },
  { value: '121101061101', regionId: '13', label: '121101061101 (Nueces)' },
  { value: '121101061102', regionId: '13', label: '121101061102 (Nueces)' },
  { value: '121101061103', regionId: '13', label: '121101061103 (Nueces)' },
  { value: '121101061104', regionId: '13', label: '121101061104 (Nueces)' },
  { value: '121101061105', regionId: '13', label: '121101061105 (Nueces)' },
  { value: '121101061106', regionId: '13', label: '121101061106 (Nueces)' },
  { value: '121101061201', regionId: '13', label: '121101061201 (Nueces)' },
  { value: '121101061202', regionId: '13', label: '121101061202 (Nueces)' },
  { value: '121101061204', regionId: '13', label: '121101061204 (Nueces)' },
  { value: '121101061205', regionId: '13', label: '121101061205 (Nueces)' },
  { value: '121101070101', regionId: '13', label: '121101070101 (Nueces)' },
  { value: '121101070102', regionId: '13', label: '121101070102 (Nueces)' },
  { value: '121101070103', regionId: '13', label: '121101070103 (Nueces)' },
  { value: '121101070104', regionId: '13', label: '121101070104 (Nueces)' },
  { value: '121101070105', regionId: '13', label: '121101070105 (Nueces)' },
  { value: '121101070106', regionId: '13', label: '121101070106 (Nueces)' },
  { value: '121101070107', regionId: '13', label: '121101070107 (Nueces)' },
  { value: '121101070108', regionId: '13', label: '121101070108 (Nueces)' },
  { value: '121101070109', regionId: '13', label: '121101070109 (Nueces)' },
  { value: '121101070207', regionId: '13', label: '121101070207 (Nueces)' },
  { value: '121101070301', regionId: '13', label: '121101070301 (Nueces)' },
  { value: '121101070302', regionId: '13', label: '121101070302 (Nueces)' },
  { value: '121101070303', regionId: '13', label: '121101070303 (Nueces)' },
  { value: '121101070402', regionId: '13', label: '121101070402 (Nueces)' },
  { value: '121101070403', regionId: '13', label: '121101070403 (Nueces)' },
  { value: '121101070201', regionId: '13', label: '121101070201 (Nueces)' },
  { value: '121101070202', regionId: '13', label: '121101070202 (Nueces)' },
  { value: '121101070203', regionId: '13', label: '121101070203 (Nueces)' },
  { value: '121101070204', regionId: '13', label: '121101070204 (Nueces)' },
  { value: '121101070205', regionId: '13', label: '121101070205 (Nueces)' },
  { value: '121101070206', regionId: '13', label: '121101070206 (Nueces)' },
  { value: '121101070304', regionId: '13', label: '121101070304 (Nueces)' },
  { value: '121101070305', regionId: '13', label: '121101070305 (Nueces)' },
  { value: '121101070401', regionId: '13', label: '121101070401 (Nueces)' },
  { value: '121101070404', regionId: '13', label: '121101070404 (Nueces)' },
  { value: '121101070405', regionId: '13', label: '121101070405 (Nueces)' },
  { value: '121101070406', regionId: '13', label: '121101070406 (Nueces)' },
  { value: '121101070407', regionId: '13', label: '121101070407 (Nueces)' },
  { value: '121101080101', regionId: '13', label: '121101080101 (Nueces)' },
  { value: '121101080102', regionId: '13', label: '121101080102 (Nueces)' },
  { value: '121101080103', regionId: '13', label: '121101080103 (Nueces)' },
  { value: '121101080104', regionId: '13', label: '121101080104 (Nueces)' },
  { value: '121101080105', regionId: '13', label: '121101080105 (Nueces)' },
  { value: '121101080106', regionId: '13', label: '121101080106 (Nueces)' },
  { value: '121101080202', regionId: '13', label: '121101080202 (Nueces)' },
  { value: '121101080303', regionId: '13', label: '121101080303 (Nueces)' },
  { value: '121101080201', regionId: '13', label: '121101080201 (Nueces)' },
  { value: '121101080203', regionId: '13', label: '121101080203 (Nueces)' },
  { value: '121101080204', regionId: '13', label: '121101080204 (Nueces)' },
  { value: '121101080401', regionId: '13', label: '121101080401 (Nueces)' },
  { value: '121101080205', regionId: '13', label: '121101080205 (Nueces)' },
  { value: '121101080301', regionId: '13', label: '121101080301 (Nueces)' },
  { value: '121101080302', regionId: '13', label: '121101080302 (Nueces)' },
  { value: '121101080304', regionId: '13', label: '121101080304 (Nueces)' },
  { value: '121101080305', regionId: '13', label: '121101080305 (Nueces)' },
  { value: '121101080402', regionId: '13', label: '121101080402 (Nueces)' },
  { value: '121101080403', regionId: '13', label: '121101080403 (Nueces)' },
  { value: '121101080404', regionId: '13', label: '121101080404 (Nueces)' },
  { value: '121101080405', regionId: '13', label: '121101080405 (Nueces)' },
  { value: '121101080406', regionId: '13', label: '121101080406 (Nueces)' },
  { value: '121101080407', regionId: '13', label: '121101080407 (Nueces)' },
  { value: '121101080408', regionId: '13', label: '121101080408 (Nueces)' },
  { value: '121101080409', regionId: '13', label: '121101080409 (Nueces)' },
  { value: '121101080410', regionId: '13', label: '121101080410 (Nueces)' },
  { value: '121101080501', regionId: '13', label: '121101080501 (Nueces)' },
  { value: '121101080502', regionId: '13', label: '121101080502 (Nueces)' },
  { value: '121101080503', regionId: '13', label: '121101080503 (Nueces)' },
  { value: '121101080504', regionId: '13', label: '121101080504 (Nueces)' },
  { value: '121101080505', regionId: '13', label: '121101080505 (Nueces)' },
  { value: '121101080506', regionId: '13', label: '121101080506 (Nueces)' },
  { value: '121101090402', regionId: '13', label: '121101090402 (Nueces)' },
  { value: '121101090403', regionId: '13', label: '121101090403 (Nueces)' },
  { value: '121101090404', regionId: '13', label: '121101090404 (Nueces)' },
  { value: '121101090405', regionId: '13', label: '121101090405 (Nueces)' },
  { value: '121101090406', regionId: '13', label: '121101090406 (Nueces)' },
  { value: '121101090501', regionId: '13', label: '121101090501 (Nueces)' },
  { value: '121101090502', regionId: '13', label: '121101090502 (Nueces)' },
  { value: '121101090503', regionId: '13', label: '121101090503 (Nueces)' },
  { value: '121101090504', regionId: '13', label: '121101090504 (Nueces)' },
  { value: '121101090505', regionId: '13', label: '121101090505 (Nueces)' },
  { value: '121101090101', regionId: '13', label: '121101090101 (Nueces)' },
  { value: '121101090102', regionId: '13', label: '121101090102 (Nueces)' },
  { value: '121101090103', regionId: '13', label: '121101090103 (Nueces)' },
  { value: '121101090104', regionId: '13', label: '121101090104 (Nueces)' },
  { value: '121101090105', regionId: '13', label: '121101090105 (Nueces)' },
  { value: '121101090201', regionId: '13', label: '121101090201 (Nueces)' },
  { value: '121101090202', regionId: '13', label: '121101090202 (Nueces)' },
  { value: '121101090203', regionId: '13', label: '121101090203 (Nueces)' },
  { value: '121101090204', regionId: '13', label: '121101090204 (Nueces)' },
  { value: '121101090205', regionId: '13', label: '121101090205 (Nueces)' },
  { value: '121101090301', regionId: '13', label: '121101090301 (Nueces)' },
  { value: '121101090302', regionId: '13', label: '121101090302 (Nueces)' },
  { value: '121101090303', regionId: '13', label: '121101090303 (Nueces)' },
  { value: '121101090304', regionId: '13', label: '121101090304 (Nueces)' },
  { value: '121101090305', regionId: '13', label: '121101090305 (Nueces)' },
  { value: '121101090401', regionId: '13', label: '121101090401 (Nueces)' },
  { value: '121101100202', regionId: '13', label: '121101100202 (Nueces)' },
  { value: '121101100203', regionId: '13', label: '121101100203 (Nueces)' },
  { value: '121101100204', regionId: '13', label: '121101100204 (Nueces)' },
  { value: '121101100205', regionId: '13', label: '121101100205 (Nueces)' },
  { value: '121101100101', regionId: '13', label: '121101100101 (Nueces)' },
  { value: '121101100102', regionId: '13', label: '121101100102 (Nueces)' },
  { value: '121101100103', regionId: '13', label: '121101100103 (Nueces)' },
  { value: '121101100104', regionId: '13', label: '121101100104 (Nueces)' },
  { value: '121101100105', regionId: '13', label: '121101100105 (Nueces)' },
  { value: '121101100201', regionId: '13', label: '121101100201 (Nueces)' },
  { value: '121101100206', regionId: '13', label: '121101100206 (Nueces)' },
  { value: '121101100301', regionId: '13', label: '121101100301 (Nueces)' },
  { value: '121101100302', regionId: '13', label: '121101100302 (Nueces)' },
  { value: '121101100303', regionId: '13', label: '121101100303 (Nueces)' },
  { value: '121101100304', regionId: '13', label: '121101100304 (Nueces)' },
  { value: '121101100305', regionId: '13', label: '121101100305 (Nueces)' },
  { value: '121101100306', regionId: '13', label: '121101100306 (Nueces)' },
  { value: '121101100307', regionId: '13', label: '121101100307 (Nueces)' },
  { value: '121101100308', regionId: '13', label: '121101100308 (Nueces)' },
  { value: '121101100401', regionId: '13', label: '121101100401 (Nueces)' },
  { value: '121101100402', regionId: '13', label: '121101100402 (Nueces)' },
  { value: '121101100403', regionId: '13', label: '121101100403 (Nueces)' },
  { value: '121101100404', regionId: '13', label: '121101100404 (Nueces)' },
  { value: '121101100405', regionId: '13', label: '121101100405 (Nueces)' },
  { value: '121101100406', regionId: '13', label: '121101100406 (Nueces)' },
  { value: '121101100407', regionId: '13', label: '121101100407 (Nueces)' },
  { value: '121101100408', regionId: '13', label: '121101100408 (Nueces)' },
  { value: '121101100409', regionId: '13', label: '121101100409 (Nueces)' },
  { value: '121101100501', regionId: '13', label: '121101100501 (Nueces)' },
  { value: '121101100502', regionId: '13', label: '121101100502 (Nueces)' },
  { value: '121101100503', regionId: '13', label: '121101100503 (Nueces)' },
  { value: '121101100504', regionId: '13', label: '121101100504 (Nueces)' },
  { value: '121101100505', regionId: '13', label: '121101100505 (Nueces)' },
  { value: '121101100506', regionId: '13', label: '121101100506 (Nueces)' },
  { value: '121101100507', regionId: '13', label: '121101100507 (Nueces)' },
  { value: '121101110104', regionId: '13', label: '121101110104 (Nueces)' },
  { value: '121101110201', regionId: '13', label: '121101110201 (Nueces)' },
  { value: '121101110103', regionId: '13', label: '121101110103 (Nueces)' },
  { value: '121101110101', regionId: '13', label: '121101110101 (Nueces)' },
  { value: '121101110102', regionId: '13', label: '121101110102 (Nueces)' },
  { value: '121101110105', regionId: '13', label: '121101110105 (Nueces)' },
  { value: '121101110106', regionId: '13', label: '121101110106 (Nueces)' },
  { value: '121101110202', regionId: '13', label: '121101110202 (Nueces)' },
  { value: '121101110203', regionId: '13', label: '121101110203 (Nueces)' },
  { value: '121101110204', regionId: '13', label: '121101110204 (Nueces)' },
  { value: '121101110205', regionId: '13', label: '121101110205 (Nueces)' },
  { value: '121101110206', regionId: '13', label: '121101110206 (Nueces)' },
  { value: '121101110301', regionId: '13', label: '121101110301 (Nueces)' },
  { value: '121101110302', regionId: '13', label: '121101110302 (Nueces)' },
  { value: '121101110303', regionId: '13', label: '121101110303 (Nueces)' },
  { value: '121101110304', regionId: '13', label: '121101110304 (Nueces)' },
  { value: '121101110305', regionId: '13', label: '121101110305 (Nueces)' },
  { value: '121101110401', regionId: '13', label: '121101110401 (Nueces)' },
  { value: '121101110402', regionId: '13', label: '121101110402 (Nueces)' },
  { value: '121101110403', regionId: '13', label: '121101110403 (Nueces)' },
  { value: '121101110404', regionId: '13', label: '121101110404 (Nueces)' },
  { value: '121101110405', regionId: '13', label: '121101110405 (Nueces)' },
  { value: '121101110501', regionId: '13', label: '121101110501 (Nueces)' },
  { value: '121101110502', regionId: '13', label: '121101110502 (Nueces)' },
  { value: '121101110503', regionId: '13', label: '121101110503 (Nueces)' },
  { value: '121101110504', regionId: '13', label: '121101110504 (Nueces)' },
  { value: '121101110505', regionId: '13', label: '121101110505 (Nueces)' },
  { value: '121101110601', regionId: '13', label: '121101110601 (Nueces)' },
  { value: '121101110602', regionId: '13', label: '121101110602 (Nueces)' },
  { value: '121101110603', regionId: '13', label: '121101110603 (Nueces)' },
  { value: '121101110604', regionId: '13', label: '121101110604 (Nueces)' },
  { value: '121101110605', regionId: '13', label: '121101110605 (Nueces)' },
  { value: '121101110701', regionId: '13', label: '121101110701 (Nueces)' },
  { value: '121101110702', regionId: '13', label: '121101110702 (Nueces)' },
  { value: '121101110703', regionId: '13', label: '121101110703 (Nueces)' },
  { value: '121101110704', regionId: '13', label: '121101110704 (Nueces)' },
  { value: '121101110705', regionId: '13', label: '121101110705 (Nueces)' },
  { value: '121101110706', regionId: '13', label: '121101110706 (Nueces)' },
  { value: '121101110707', regionId: '13', label: '121101110707 (Nueces)' },
  { value: '121102010002', regionId: '13', label: '121102010002 (Nueces)' },
  { value: '121102010001', regionId: '13', label: '121102010001 (Nueces)' },
  { value: '121102010003', regionId: '13', label: '121102010003 (Nueces)' },
  { value: '121102010005', regionId: '13', label: '121102010005 (Nueces)' },
  { value: '121102010004', regionId: '13', label: '121102010004 (Nueces)' },
  { value: '121102020101', regionId: '13', label: '121102020101 (Nueces)' },
  { value: '121102020104', regionId: '13', label: '121102020104 (Nueces)' },
  { value: '121102020102', regionId: '13', label: '121102020102 (Nueces)' },
  { value: '121102020103', regionId: '13', label: '121102020103 (Nueces)' },
  { value: '121102020105', regionId: '13', label: '121102020105 (Nueces)' },
  { value: '121102020106', regionId: '13', label: '121102020106 (Nueces)' },
  { value: '121102020107', regionId: '13', label: '121102020107 (Nueces)' },
  { value: '121102020200', regionId: '13', label: '121102020200 (Nueces)' },
  { value: '121102020300', regionId: '13', label: '121102020300 (Nueces)' },
  { value: '121102030100', regionId: '13', label: '121102030100 (Nueces)' },
  { value: '121102030200', regionId: '13', label: '121102030200 (Nueces)' },
  { value: '121102040203', regionId: '13', label: '121102040203 (Nueces)' },
  { value: '121102040109', regionId: '13', label: '121102040109 (Nueces)' },
  { value: '121102040201', regionId: '13', label: '121102040201 (Nueces)' },
  { value: '121102040101', regionId: '13', label: '121102040101 (Nueces)' },
  { value: '121102040102', regionId: '13', label: '121102040102 (Nueces)' },
  { value: '121102040103', regionId: '13', label: '121102040103 (Nueces)' },
  { value: '121102040104', regionId: '13', label: '121102040104 (Nueces)' },
  { value: '121102040105', regionId: '13', label: '121102040105 (Nueces)' },
  { value: '121102040106', regionId: '13', label: '121102040106 (Nueces)' },
  { value: '121102040107', regionId: '13', label: '121102040107 (Nueces)' },
  { value: '121102040108', regionId: '13', label: '121102040108 (Nueces)' },
  { value: '121102040202', regionId: '13', label: '121102040202 (Nueces)' },
  { value: '121102040204', regionId: '13', label: '121102040204 (Nueces)' },
  { value: '121102040205', regionId: '13', label: '121102040205 (Nueces)' },
  { value: '121102040206', regionId: '13', label: '121102040206 (Nueces)' },
  { value: '121102040301', regionId: '13', label: '121102040301 (Nueces)' },
  { value: '121102040302', regionId: '13', label: '121102040302 (Nueces)' },
  { value: '121102040303', regionId: '13', label: '121102040303 (Nueces)' },
  { value: '121102040304', regionId: '13', label: '121102040304 (Nueces)' },
  { value: '121102040305', regionId: '13', label: '121102040305 (Nueces)' },
  { value: '121102040306', regionId: '13', label: '121102040306 (Nueces)' },
  { value: '121102040307', regionId: '13', label: '121102040307 (Nueces)' },
  { value: '121102040308', regionId: '13', label: '121102040308 (Nueces)' },
  { value: '121102040309', regionId: '13', label: '121102040309 (Nueces)' },
  { value: '121102040310', regionId: '13', label: '121102040310 (Nueces)' },
  { value: '121102040401', regionId: '13', label: '121102040401 (Nueces)' },
  { value: '121102040402', regionId: '13', label: '121102040402 (Nueces)' },
  { value: '121102040403', regionId: '13', label: '121102040403 (Nueces)' },
  { value: '121102040408', regionId: '13', label: '121102040408 (Nueces)' },
  { value: '121102040409', regionId: '13', label: '121102040409 (Nueces)' },
  { value: '121102040404', regionId: '13', label: '121102040404 (Nueces)' },
  { value: '121102040405', regionId: '13', label: '121102040405 (Nueces)' },
  { value: '121102040406', regionId: '13', label: '121102040406 (Nueces)' },
  { value: '121102040410', regionId: '13', label: '121102040410 (Nueces)' },
  { value: '121102040407', regionId: '13', label: '121102040407 (Nueces)' },
  { value: '121102050101', regionId: '13', label: '121102050101 (Nueces)' },
  { value: '121102050102', regionId: '13', label: '121102050102 (Nueces)' },
  { value: '121102050103', regionId: '13', label: '121102050103 (Nueces)' },
  { value: '121102050104', regionId: '13', label: '121102050104 (Nueces)' },
  { value: '121102050105', regionId: '13', label: '121102050105 (Nueces)' },
  { value: '121102050106', regionId: '13', label: '121102050106 (Nueces)' },
  { value: '121102050201', regionId: '13', label: '121102050201 (Nueces)' },
  { value: '121102050202', regionId: '13', label: '121102050202 (Nueces)' },
  { value: '121102050203', regionId: '13', label: '121102050203 (Nueces)' },
  { value: '121102050204', regionId: '13', label: '121102050204 (Nueces)' },
  { value: '121102050301', regionId: '13', label: '121102050301 (Nueces)' },
  { value: '121102050302', regionId: '13', label: '121102050302 (Nueces)' },
  { value: '121102050303', regionId: '13', label: '121102050303 (Nueces)' },
  { value: '121102050304', regionId: '13', label: '121102050304 (Nueces)' },
  { value: '121102050305', regionId: '13', label: '121102050305 (Nueces)' },
  { value: '121102050306', regionId: '13', label: '121102050306 (Nueces)' },
  { value: '121102050307', regionId: '13', label: '121102050307 (Nueces)' },
  { value: '121102050401', regionId: '13', label: '121102050401 (Nueces)' },
  { value: '121102050402', regionId: '13', label: '121102050402 (Nueces)' },
  { value: '121102050403', regionId: '13', label: '121102050403 (Nueces)' },
  { value: '121102050404', regionId: '13', label: '121102050404 (Nueces)' },
  { value: '121102050405', regionId: '13', label: '121102050405 (Nueces)' },
  { value: '121102050406', regionId: '13', label: '121102050406 (Nueces)' },
  { value: '121102050407', regionId: '13', label: '121102050407 (Nueces)' },
  { value: '121102050501', regionId: '13', label: '121102050501 (Nueces)' },
  { value: '121102050502', regionId: '13', label: '121102050502 (Nueces)' },
  { value: '121102050503', regionId: '13', label: '121102050503 (Nueces)' },
  { value: '121102050504', regionId: '13', label: '121102050504 (Nueces)' },
  { value: '121102050505', regionId: '13', label: '121102050505 (Nueces)' },
  { value: '121102050506', regionId: '13', label: '121102050506 (Nueces)' },
  { value: '121102050601', regionId: '13', label: '121102050601 (Nueces)' },
  { value: '121102050605', regionId: '13', label: '121102050605 (Nueces)' },
  { value: '121102050801', regionId: '13', label: '121102050801 (Nueces)' },
  { value: '121102050802', regionId: '13', label: '121102050802 (Nueces)' },
  { value: '121102050808', regionId: '13', label: '121102050808 (Nueces)' },
  { value: '121102050602', regionId: '13', label: '121102050602 (Nueces)' },
  { value: '121102050804', regionId: '13', label: '121102050804 (Nueces)' },
  { value: '121102050805', regionId: '13', label: '121102050805 (Nueces)' },
  { value: '121102050806', regionId: '13', label: '121102050806 (Nueces)' },
  { value: '121102050807', regionId: '13', label: '121102050807 (Nueces)' },
  { value: '121102050603', regionId: '13', label: '121102050603 (Nueces)' },
  { value: '121102050604', regionId: '13', label: '121102050604 (Nueces)' },
  { value: '121102050606', regionId: '13', label: '121102050606 (Nueces)' },
  { value: '121102050607', regionId: '13', label: '121102050607 (Nueces)' },
  { value: '121102050608', regionId: '13', label: '121102050608 (Nueces)' },
  { value: '121102050701', regionId: '13', label: '121102050701 (Nueces)' },
  { value: '121102050702', regionId: '13', label: '121102050702 (Nueces)' },
  { value: '121102050703', regionId: '13', label: '121102050703 (Nueces)' },
  { value: '121102050704', regionId: '13', label: '121102050704 (Nueces)' },
  { value: '121102050705', regionId: '13', label: '121102050705 (Nueces)' },
  { value: '121102050706', regionId: '13', label: '121102050706 (Nueces)' },
  { value: '121102050707', regionId: '13', label: '121102050707 (Nueces)' },
  { value: '121102050803', regionId: '13', label: '121102050803 (Nueces)' },
  { value: '121102060102', regionId: '13', label: '121102060102 (Nueces)' },
  { value: '121102060101', regionId: '13', label: '121102060101 (Nueces)' },
  { value: '121102060402', regionId: '13', label: '121102060402 (Nueces)' },
  { value: '121102060403', regionId: '13', label: '121102060403 (Nueces)' },
  { value: '121102060404', regionId: '13', label: '121102060404 (Nueces)' },
  { value: '121102060405', regionId: '13', label: '121102060405 (Nueces)' },
  { value: '121102060406', regionId: '13', label: '121102060406 (Nueces)' },
  { value: '121102060501', regionId: '13', label: '121102060501 (Nueces)' },
  { value: '121102060502', regionId: '13', label: '121102060502 (Nueces)' },
  { value: '121102060103', regionId: '13', label: '121102060103 (Nueces)' },
  { value: '121102060104', regionId: '13', label: '121102060104 (Nueces)' },
  { value: '121102060105', regionId: '13', label: '121102060105 (Nueces)' },
  { value: '121102060201', regionId: '13', label: '121102060201 (Nueces)' },
  { value: '121102060202', regionId: '13', label: '121102060202 (Nueces)' },
  { value: '121102060203', regionId: '13', label: '121102060203 (Nueces)' },
  { value: '121102060204', regionId: '13', label: '121102060204 (Nueces)' },
  { value: '121102060205', regionId: '13', label: '121102060205 (Nueces)' },
  { value: '121102060206', regionId: '13', label: '121102060206 (Nueces)' },
  { value: '121102060301', regionId: '13', label: '121102060301 (Nueces)' },
  { value: '121102060302', regionId: '13', label: '121102060302 (Nueces)' },
  { value: '121102060303', regionId: '13', label: '121102060303 (Nueces)' },
  { value: '121102060304', regionId: '13', label: '121102060304 (Nueces)' },
  { value: '121102060401', regionId: '13', label: '121102060401 (Nueces)' },
  { value: '121102070100', regionId: '13', label: '121102070100 (Nueces)' },
  { value: '130403030401', regionId: '13', label: '130403030401 (Nueces)' },
  { value: '130403030301', regionId: '13', label: '130403030301 (Nueces)' },
  { value: '130403030201', regionId: '13', label: '130403030201 (Nueces)' },
  { value: '130403030202', regionId: '13', label: '130403030202 (Nueces)' },
  { value: '130800010301', regionId: '13', label: '130800010301 (Nueces)' },
  { value: '130800010401', regionId: '13', label: '130800010401 (Nueces)' },
  { value: '130800010402', regionId: '13', label: '130800010402 (Nueces)' },
  { value: '130800010501', regionId: '13', label: '130800010501 (Nueces)' },
  { value: '130800010503', regionId: '13', label: '130800010503 (Nueces)' },
  { value: '130800011301', regionId: '13', label: '130800011301 (Nueces)' },
  { value: '130800011303', regionId: '13', label: '130800011303 (Nueces)' },
  { value: '130800011801', regionId: '13', label: '130800011801 (Nueces)' },
  { value: '130800011802', regionId: '13', label: '130800011802 (Nueces)' },
  { value: '130800011804', regionId: '13', label: '130800011804 (Nueces)' },
  { value: '130800011805', regionId: '13', label: '130800011805 (Nueces)' },
  { value: '130800022304', regionId: '13', label: '130800022304 (Nueces)' },
  { value: '130800022401', regionId: '13', label: '130800022401 (Nueces)' },
  { value: '130800022402', regionId: '13', label: '130800022402 (Nueces)' },
  { value: '130800022404', regionId: '13', label: '130800022404 (Nueces)' },
  { value: '130800022601', regionId: '13', label: '130800022601 (Nueces)' },
  { value: '130800021403', regionId: '13', label: '130800021403 (Nueces)' },
  { value: '130800021901', regionId: '13', label: '130800021901 (Nueces)' },
  { value: '130800021902', regionId: '13', label: '130800021902 (Nueces)' },
  { value: '130800021903', regionId: '13', label: '130800021903 (Nueces)' },
  { value: '130800021907', regionId: '13', label: '130800021907 (Nueces)' },
  { value: '130800022301', regionId: '13', label: '130800022301 (Nueces)' },
  { value: '130800022302', regionId: '13', label: '130800022302 (Nueces)' },
  { value: '130800022801', regionId: '13', label: '130800022801 (Nueces)' },
  { value: '130800022803', regionId: '13', label: '130800022803 (Nueces)' },
  { value: '130800022804', regionId: '13', label: '130800022804 (Nueces)' },
  { value: '130800020704', regionId: '13', label: '130800020704 (Nueces)' },
  { value: '130800020707', regionId: '13', label: '130800020707 (Nueces)' },
  { value: '130800020708', regionId: '13', label: '130800020708 (Nueces)' },
  { value: '130800021401', regionId: '13', label: '130800021401 (Nueces)' },
  { value: '130800030101', regionId: '13', label: '130800030101 (Nueces)' },
  { value: '130800030102', regionId: '13', label: '130800030102 (Nueces)' },
  { value: '130800030104', regionId: '13', label: '130800030104 (Nueces)' },
  { value: '130800030106', regionId: '13', label: '130800030106 (Nueces)' },
  { value: '130800030701', regionId: '13', label: '130800030701 (Nueces)' },
  { value: '130800030704', regionId: '13', label: '130800030704 (Nueces)' },
  { value: '130800030707', regionId: '13', label: '130800030707 (Nueces)' },
  { value: '130800030201', regionId: '13', label: '130800030201 (Nueces)' },
  { value: '120902030103', regionId: '13', label: '120902030103 (Nueces)' },
  { value: '120902030101', regionId: '13', label: '120902030101 (Nueces)' },
  { value: '120902030201', regionId: '13', label: '120902030201 (Nueces)' },
  { value: '120902030202', regionId: '13', label: '120902030202 (Nueces)' },
  { value: '120902030203', regionId: '13', label: '120902030203 (Nueces)' },
  { value: '120902030205', regionId: '13', label: '120902030205 (Nueces)' },
  { value: '120902030301', regionId: '13', label: '120902030301 (Nueces)' },
  { value: '120902030303', regionId: '13', label: '120902030303 (Nueces)' },
  { value: '121003020202', regionId: '13', label: '121003020202 (Nueces)' },
  { value: '121003020203', regionId: '13', label: '121003020203 (Nueces)' },
  { value: '121003020205', regionId: '13', label: '121003020205 (Nueces)' },
  { value: '121003020101', regionId: '13', label: '121003020101 (Nueces)' },
  { value: '121003020106', regionId: '13', label: '121003020106 (Nueces)' },
  { value: '121003020107', regionId: '13', label: '121003020107 (Nueces)' },
  { value: '121003020303', regionId: '13', label: '121003020303 (Nueces)' },
  { value: '121003020305', regionId: '13', label: '121003020305 (Nueces)' },
  { value: '121003020501', regionId: '13', label: '121003020501 (Nueces)' },
  { value: '121003020503', regionId: '13', label: '121003020503 (Nueces)' },
  { value: '121003020506', regionId: '13', label: '121003020506 (Nueces)' },
  { value: '121003020507', regionId: '13', label: '121003020507 (Nueces)' },
  { value: '121003020508', regionId: '13', label: '121003020508 (Nueces)' },
  { value: '121003020509', regionId: '13', label: '121003020509 (Nueces)' },
]

const hucsRegion04 = [
  { value: '111403010101', regionId: '04', label: '111403010101 (Sabine)' },
  { value: '111403010103', regionId: '04', label: '111403010103 (Sabine)' },
  { value: '111403010104', regionId: '04', label: '111403010104 (Sabine)' },
  { value: '111403010105', regionId: '04', label: '111403010105 (Sabine)' },
  { value: '111403030104', regionId: '04', label: '111403030104 (Sabine)' },
  { value: '111403030106', regionId: '04', label: '111403030106 (Sabine)' },
  { value: '111403030109', regionId: '04', label: '111403030109 (Sabine)' },
  { value: '111403030101', regionId: '04', label: '111403030101 (Sabine)' },
  { value: '111403030102', regionId: '04', label: '111403030102 (Sabine)' },
  { value: '111403030103', regionId: '04', label: '111403030103 (Sabine)' },
  { value: '111403050101', regionId: '04', label: '111403050101 (Sabine)' },
  { value: '111403050201', regionId: '04', label: '111403050201 (Sabine)' },
  { value: '111403060402', regionId: '04', label: '111403060402 (Sabine)' },
  { value: '111403060406', regionId: '04', label: '111403060406 (Sabine)' },
  { value: '111403060401', regionId: '04', label: '111403060401 (Sabine)' },
  { value: '111403070101', regionId: '04', label: '111403070101 (Sabine)' },
  { value: '111403070102', regionId: '04', label: '111403070102 (Sabine)' },
  { value: '111403070104', regionId: '04', label: '111403070104 (Sabine)' },
  { value: '111403070108', regionId: '04', label: '111403070108 (Sabine)' },
  { value: '111403070201', regionId: '04', label: '111403070201 (Sabine)' },
  { value: '111403070203', regionId: '04', label: '111403070203 (Sabine)' },
  { value: '111403070204', regionId: '04', label: '111403070204 (Sabine)' },
  { value: '111403070205', regionId: '04', label: '111403070205 (Sabine)' },
  { value: '111403070206', regionId: '04', label: '111403070206 (Sabine)' },
  { value: '111403070207', regionId: '04', label: '111403070207 (Sabine)' },
  { value: '111403070208', regionId: '04', label: '111403070208 (Sabine)' },
  { value: '120100010103', regionId: '04', label: '120100010103 (Sabine)' },
  { value: '120100010104', regionId: '04', label: '120100010104 (Sabine)' },
  { value: '120100010201', regionId: '04', label: '120100010201 (Sabine)' },
  { value: '120100010202', regionId: '04', label: '120100010202 (Sabine)' },
  { value: '120100010101', regionId: '04', label: '120100010101 (Sabine)' },
  { value: '120100010102', regionId: '04', label: '120100010102 (Sabine)' },
  { value: '120100010203', regionId: '04', label: '120100010203 (Sabine)' },
  { value: '120100010204', regionId: '04', label: '120100010204 (Sabine)' },
  { value: '120100010301', regionId: '04', label: '120100010301 (Sabine)' },
  { value: '120100010302', regionId: '04', label: '120100010302 (Sabine)' },
  { value: '120100010303', regionId: '04', label: '120100010303 (Sabine)' },
  { value: '120100010401', regionId: '04', label: '120100010401 (Sabine)' },
  { value: '120100010507', regionId: '04', label: '120100010507 (Sabine)' },
  { value: '120100010601', regionId: '04', label: '120100010601 (Sabine)' },
  { value: '120100010402', regionId: '04', label: '120100010402 (Sabine)' },
  { value: '120100010403', regionId: '04', label: '120100010403 (Sabine)' },
  { value: '120100010404', regionId: '04', label: '120100010404 (Sabine)' },
  { value: '120100010405', regionId: '04', label: '120100010405 (Sabine)' },
  { value: '120100010406', regionId: '04', label: '120100010406 (Sabine)' },
  { value: '120100010407', regionId: '04', label: '120100010407 (Sabine)' },
  { value: '120100010501', regionId: '04', label: '120100010501 (Sabine)' },
  { value: '120100010502', regionId: '04', label: '120100010502 (Sabine)' },
  { value: '120100010503', regionId: '04', label: '120100010503 (Sabine)' },
  { value: '120100010504', regionId: '04', label: '120100010504 (Sabine)' },
  { value: '120100010505', regionId: '04', label: '120100010505 (Sabine)' },
  { value: '120100010506', regionId: '04', label: '120100010506 (Sabine)' },
  { value: '120100010602', regionId: '04', label: '120100010602 (Sabine)' },
  { value: '120100010603', regionId: '04', label: '120100010603 (Sabine)' },
  { value: '120100010604', regionId: '04', label: '120100010604 (Sabine)' },
  { value: '120100010605', regionId: '04', label: '120100010605 (Sabine)' },
  { value: '120100010606', regionId: '04', label: '120100010606 (Sabine)' },
  { value: '120100010607', regionId: '04', label: '120100010607 (Sabine)' },
  { value: '120100010608', regionId: '04', label: '120100010608 (Sabine)' },
  { value: '120100021109', regionId: '04', label: '120100021109 (Sabine)' },
  { value: '120100021101', regionId: '04', label: '120100021101 (Sabine)' },
  { value: '120100021104', regionId: '04', label: '120100021104 (Sabine)' },
  { value: '120100021105', regionId: '04', label: '120100021105 (Sabine)' },
  { value: '120100021108', regionId: '04', label: '120100021108 (Sabine)' },
  { value: '120100020607', regionId: '04', label: '120100020607 (Sabine)' },
  { value: '120100020608', regionId: '04', label: '120100020608 (Sabine)' },
  { value: '120100020701', regionId: '04', label: '120100020701 (Sabine)' },
  { value: '120100020702', regionId: '04', label: '120100020702 (Sabine)' },
  { value: '120100020703', regionId: '04', label: '120100020703 (Sabine)' },
  { value: '120100020704', regionId: '04', label: '120100020704 (Sabine)' },
  { value: '120100020705', regionId: '04', label: '120100020705 (Sabine)' },
  { value: '120100020706', regionId: '04', label: '120100020706 (Sabine)' },
  { value: '120100020801', regionId: '04', label: '120100020801 (Sabine)' },
  { value: '120100020802', regionId: '04', label: '120100020802 (Sabine)' },
  { value: '120100020803', regionId: '04', label: '120100020803 (Sabine)' },
  { value: '120100020804', regionId: '04', label: '120100020804 (Sabine)' },
  { value: '120100020805', regionId: '04', label: '120100020805 (Sabine)' },
  { value: '120100020901', regionId: '04', label: '120100020901 (Sabine)' },
  { value: '120100020902', regionId: '04', label: '120100020902 (Sabine)' },
  { value: '120100021003', regionId: '04', label: '120100021003 (Sabine)' },
  { value: '120100021004', regionId: '04', label: '120100021004 (Sabine)' },
  { value: '120100021005', regionId: '04', label: '120100021005 (Sabine)' },
  { value: '120100021006', regionId: '04', label: '120100021006 (Sabine)' },
  { value: '120100021007', regionId: '04', label: '120100021007 (Sabine)' },
  { value: '120100021008', regionId: '04', label: '120100021008 (Sabine)' },
  { value: '120100021009', regionId: '04', label: '120100021009 (Sabine)' },
  { value: '120100021010', regionId: '04', label: '120100021010 (Sabine)' },
  { value: '120100021103', regionId: '04', label: '120100021103 (Sabine)' },
  { value: '120100021106', regionId: '04', label: '120100021106 (Sabine)' },
  { value: '120100021107', regionId: '04', label: '120100021107 (Sabine)' },
  { value: '120100020101', regionId: '04', label: '120100020101 (Sabine)' },
  { value: '120100020102', regionId: '04', label: '120100020102 (Sabine)' },
  { value: '120100020103', regionId: '04', label: '120100020103 (Sabine)' },
  { value: '120100020104', regionId: '04', label: '120100020104 (Sabine)' },
  { value: '120100020105', regionId: '04', label: '120100020105 (Sabine)' },
  { value: '120100020106', regionId: '04', label: '120100020106 (Sabine)' },
  { value: '120100020107', regionId: '04', label: '120100020107 (Sabine)' },
  { value: '120100020201', regionId: '04', label: '120100020201 (Sabine)' },
  { value: '120100020202', regionId: '04', label: '120100020202 (Sabine)' },
  { value: '120100020203', regionId: '04', label: '120100020203 (Sabine)' },
  { value: '120100020204', regionId: '04', label: '120100020204 (Sabine)' },
  { value: '120100020205', regionId: '04', label: '120100020205 (Sabine)' },
  { value: '120100020206', regionId: '04', label: '120100020206 (Sabine)' },
  { value: '120100020301', regionId: '04', label: '120100020301 (Sabine)' },
  { value: '120100020302', regionId: '04', label: '120100020302 (Sabine)' },
  { value: '120100020303', regionId: '04', label: '120100020303 (Sabine)' },
  { value: '120100020401', regionId: '04', label: '120100020401 (Sabine)' },
  { value: '120100020402', regionId: '04', label: '120100020402 (Sabine)' },
  { value: '120100020403', regionId: '04', label: '120100020403 (Sabine)' },
  { value: '120100020404', regionId: '04', label: '120100020404 (Sabine)' },
  { value: '120100020405', regionId: '04', label: '120100020405 (Sabine)' },
  { value: '120100020501', regionId: '04', label: '120100020501 (Sabine)' },
  { value: '120100020502', regionId: '04', label: '120100020502 (Sabine)' },
  { value: '120100020503', regionId: '04', label: '120100020503 (Sabine)' },
  { value: '120100020504', regionId: '04', label: '120100020504 (Sabine)' },
  { value: '120100020505', regionId: '04', label: '120100020505 (Sabine)' },
  { value: '120100020506', regionId: '04', label: '120100020506 (Sabine)' },
  { value: '120100020601', regionId: '04', label: '120100020601 (Sabine)' },
  { value: '120100020602', regionId: '04', label: '120100020602 (Sabine)' },
  { value: '120100020603', regionId: '04', label: '120100020603 (Sabine)' },
  { value: '120100020604', regionId: '04', label: '120100020604 (Sabine)' },
  { value: '120100020605', regionId: '04', label: '120100020605 (Sabine)' },
  { value: '120100020606', regionId: '04', label: '120100020606 (Sabine)' },
  { value: '120100020903', regionId: '04', label: '120100020903 (Sabine)' },
  { value: '120100020904', regionId: '04', label: '120100020904 (Sabine)' },
  { value: '120100020905', regionId: '04', label: '120100020905 (Sabine)' },
  { value: '120100020906', regionId: '04', label: '120100020906 (Sabine)' },
  { value: '120100021001', regionId: '04', label: '120100021001 (Sabine)' },
  { value: '120100021002', regionId: '04', label: '120100021002 (Sabine)' },
  { value: '120100021102', regionId: '04', label: '120100021102 (Sabine)' },
  { value: '120100030101', regionId: '04', label: '120100030101 (Sabine)' },
  { value: '120100030102', regionId: '04', label: '120100030102 (Sabine)' },
  { value: '120100030202', regionId: '04', label: '120100030202 (Sabine)' },
  { value: '120100030203', regionId: '04', label: '120100030203 (Sabine)' },
  { value: '120100030204', regionId: '04', label: '120100030204 (Sabine)' },
  { value: '120100030205', regionId: '04', label: '120100030205 (Sabine)' },
  { value: '120100030301', regionId: '04', label: '120100030301 (Sabine)' },
  { value: '120100030302', regionId: '04', label: '120100030302 (Sabine)' },
  { value: '120100030303', regionId: '04', label: '120100030303 (Sabine)' },
  { value: '120100030304', regionId: '04', label: '120100030304 (Sabine)' },
  { value: '120100030305', regionId: '04', label: '120100030305 (Sabine)' },
  { value: '120100030103', regionId: '04', label: '120100030103 (Sabine)' },
  { value: '120100030104', regionId: '04', label: '120100030104 (Sabine)' },
  { value: '120100030105', regionId: '04', label: '120100030105 (Sabine)' },
  { value: '120100030106', regionId: '04', label: '120100030106 (Sabine)' },
  { value: '120100030107', regionId: '04', label: '120100030107 (Sabine)' },
  { value: '120100030201', regionId: '04', label: '120100030201 (Sabine)' },
  { value: '120200010101', regionId: '04', label: '120200010101 (Sabine)' },
  { value: '120200010102', regionId: '04', label: '120200010102 (Sabine)' },
  { value: '120200010103', regionId: '04', label: '120200010103 (Sabine)' },
  { value: '120200010104', regionId: '04', label: '120200010104 (Sabine)' },
  { value: '120200010105', regionId: '04', label: '120200010105 (Sabine)' },
  { value: '120200010201', regionId: '04', label: '120200010201 (Sabine)' },
  { value: '120200010203', regionId: '04', label: '120200010203 (Sabine)' },
  { value: '120200030404', regionId: '04', label: '120200030404 (Sabine)' },
  { value: '120200030405', regionId: '04', label: '120200030405 (Sabine)' },
  { value: '120200030406', regionId: '04', label: '120200030406 (Sabine)' },
  { value: '120200030407', regionId: '04', label: '120200030407 (Sabine)' },
  { value: '120200030205', regionId: '04', label: '120200030205 (Sabine)' },
  { value: '120200030301', regionId: '04', label: '120200030301 (Sabine)' },
  { value: '120200030302', regionId: '04', label: '120200030302 (Sabine)' },
  { value: '120200030303', regionId: '04', label: '120200030303 (Sabine)' },
  { value: '120200030304', regionId: '04', label: '120200030304 (Sabine)' },
  { value: '120200030305', regionId: '04', label: '120200030305 (Sabine)' },
  { value: '120200030306', regionId: '04', label: '120200030306 (Sabine)' },
  { value: '120200030307', regionId: '04', label: '120200030307 (Sabine)' },
  { value: '120200030308', regionId: '04', label: '120200030308 (Sabine)' },
  { value: '120200030403', regionId: '04', label: '120200030403 (Sabine)' },
  { value: '120200040301', regionId: '04', label: '120200040301 (Sabine)' },
  { value: '120200040303', regionId: '04', label: '120200040303 (Sabine)' },
  { value: '120200040401', regionId: '04', label: '120200040401 (Sabine)' },
  { value: '120200040403', regionId: '04', label: '120200040403 (Sabine)' },
  { value: '120200040501', regionId: '04', label: '120200040501 (Sabine)' },
  { value: '120200040101', regionId: '04', label: '120200040101 (Sabine)' },
  { value: '120200040103', regionId: '04', label: '120200040103 (Sabine)' },
  { value: '120200050301', regionId: '04', label: '120200050301 (Sabine)' },
  { value: '120200050303', regionId: '04', label: '120200050303 (Sabine)' },
  { value: '120200050401', regionId: '04', label: '120200050401 (Sabine)' },
  { value: '120200050404', regionId: '04', label: '120200050404 (Sabine)' },
  { value: '120200050405', regionId: '04', label: '120200050405 (Sabine)' },
  { value: '120200050701', regionId: '04', label: '120200050701 (Sabine)' },
  { value: '120200050703', regionId: '04', label: '120200050703 (Sabine)' },
  { value: '120200050705', regionId: '04', label: '120200050705 (Sabine)' },
  { value: '120200050804', regionId: '04', label: '120200050804 (Sabine)' },
  { value: '120200050805', regionId: '04', label: '120200050805 (Sabine)' },
  { value: '120200050809', regionId: '04', label: '120200050809 (Sabine)' },
  { value: '120200050806', regionId: '04', label: '120200050806 (Sabine)' },
  { value: '120200050807', regionId: '04', label: '120200050807 (Sabine)' },
  { value: '120301060305', regionId: '04', label: '120301060305 (Sabine)' },
  { value: '120301060102', regionId: '04', label: '120301060102 (Sabine)' },
  { value: '120301060103', regionId: '04', label: '120301060103 (Sabine)' },
  { value: '120301060304', regionId: '04', label: '120301060304 (Sabine)' },
  { value: '120301060307', regionId: '04', label: '120301060307 (Sabine)' },
  { value: '120301060401', regionId: '04', label: '120301060401 (Sabine)' },
  { value: '120301070101', regionId: '04', label: '120301070101 (Sabine)' },
  { value: '120301070102', regionId: '04', label: '120301070102 (Sabine)' },
  { value: '120301070105', regionId: '04', label: '120301070105 (Sabine)' },
  { value: '120301070201', regionId: '04', label: '120301070201 (Sabine)' },
  { value: '120301070202', regionId: '04', label: '120301070202 (Sabine)' },
  { value: '120301070203', regionId: '04', label: '120301070203 (Sabine)' },
  { value: '120301070204', regionId: '04', label: '120301070204 (Sabine)' },
  { value: '120301070301', regionId: '04', label: '120301070301 (Sabine)' },
  { value: '120100040301', regionId: '04', label: '120100040301 (Sabine)' },
  { value: '120100040307', regionId: '04', label: '120100040307 (Sabine)' },
  { value: '120100040309', regionId: '04', label: '120100040309 (Sabine)' },
  { value: '120100040801', regionId: '04', label: '120100040801 (Sabine)' },
  { value: '120100040804', regionId: '04', label: '120100040804 (Sabine)' },
  { value: '120100040805', regionId: '04', label: '120100040805 (Sabine)' },
  { value: '120100040806', regionId: '04', label: '120100040806 (Sabine)' },
  { value: '120100040807', regionId: '04', label: '120100040807 (Sabine)' },
  { value: '120100041002', regionId: '04', label: '120100041002 (Sabine)' },
  { value: '120100041005', regionId: '04', label: '120100041005 (Sabine)' },
  { value: '120100041104', regionId: '04', label: '120100041104 (Sabine)' },
  { value: '120100041105', regionId: '04', label: '120100041105 (Sabine)' },
  { value: '120100041106', regionId: '04', label: '120100041106 (Sabine)' },
  { value: '120100040101', regionId: '04', label: '120100040101 (Sabine)' },
  { value: '120100040102', regionId: '04', label: '120100040102 (Sabine)' },
  { value: '120100040104', regionId: '04', label: '120100040104 (Sabine)' },
  { value: '120100040201', regionId: '04', label: '120100040201 (Sabine)' },
  { value: '120100040202', regionId: '04', label: '120100040202 (Sabine)' },
  { value: '120100040203', regionId: '04', label: '120100040203 (Sabine)' },
  { value: '120100040103', regionId: '04', label: '120100040103 (Sabine)' },
  { value: '120100040205', regionId: '04', label: '120100040205 (Sabine)' },
  { value: '120100040701', regionId: '04', label: '120100040701 (Sabine)' },
  { value: '120100040702', regionId: '04', label: '120100040702 (Sabine)' },
  { value: '120100040703', regionId: '04', label: '120100040703 (Sabine)' },
  { value: '120100040905', regionId: '04', label: '120100040905 (Sabine)' },
  { value: '120100041003', regionId: '04', label: '120100041003 (Sabine)' },
  { value: '120100040204', regionId: '04', label: '120100040204 (Sabine)' },
  { value: '120100040802', regionId: '04', label: '120100040802 (Sabine)' },
  { value: '120100040803', regionId: '04', label: '120100040803 (Sabine)' },
  { value: '120100040901', regionId: '04', label: '120100040901 (Sabine)' },
  { value: '120100040902', regionId: '04', label: '120100040902 (Sabine)' },
  { value: '120100040903', regionId: '04', label: '120100040903 (Sabine)' },
  { value: '120100040904', regionId: '04', label: '120100040904 (Sabine)' },
  { value: '120100041004', regionId: '04', label: '120100041004 (Sabine)' },
  { value: '120100041101', regionId: '04', label: '120100041101 (Sabine)' },
  { value: '120100041102', regionId: '04', label: '120100041102 (Sabine)' },
  { value: '120100041103', regionId: '04', label: '120100041103 (Sabine)' },
  { value: '120402010500', regionId: '04', label: '120402010500 (Sabine)' },
  { value: '111402060101', regionId: '04', label: '111402060101 (Sabine)' },
  { value: '120100050310', regionId: '04', label: '120100050310 (Sabine)' },
  { value: '120100050403', regionId: '04', label: '120100050403 (Sabine)' },
  { value: '120100050404', regionId: '04', label: '120100050404 (Sabine)' },
  { value: '120100050405', regionId: '04', label: '120100050405 (Sabine)' },
  { value: '120100050505', regionId: '04', label: '120100050505 (Sabine)' },
  { value: '120100050705', regionId: '04', label: '120100050705 (Sabine)' },
  { value: '120100050706', regionId: '04', label: '120100050706 (Sabine)' },
  { value: '120100050905', regionId: '04', label: '120100050905 (Sabine)' },
  { value: '120100051100', regionId: '04', label: '120100051100 (Sabine)' },
  { value: '120100050204', regionId: '04', label: '120100050204 (Sabine)' },
  { value: '120100050502', regionId: '04', label: '120100050502 (Sabine)' },
  { value: '120100050503', regionId: '04', label: '120100050503 (Sabine)' },
  { value: '120100050504', regionId: '04', label: '120100050504 (Sabine)' },
  { value: '120100050201', regionId: '04', label: '120100050201 (Sabine)' },
  { value: '120100050202', regionId: '04', label: '120100050202 (Sabine)' },
  { value: '120100050203', regionId: '04', label: '120100050203 (Sabine)' },
  { value: '120100050601', regionId: '04', label: '120100050601 (Sabine)' },
  { value: '120100050602', regionId: '04', label: '120100050602 (Sabine)' },
  { value: '120100050603', regionId: '04', label: '120100050603 (Sabine)' },
  { value: '120100050604', regionId: '04', label: '120100050604 (Sabine)' },
  { value: '120100050605', regionId: '04', label: '120100050605 (Sabine)' },
  { value: '120100050606', regionId: '04', label: '120100050606 (Sabine)' },
  { value: '120100050607', regionId: '04', label: '120100050607 (Sabine)' },
  { value: '120100050608', regionId: '04', label: '120100050608 (Sabine)' },
  { value: '120100050701', regionId: '04', label: '120100050701 (Sabine)' },
  { value: '120100050609', regionId: '04', label: '120100050609 (Sabine)' },
  { value: '120100050704', regionId: '04', label: '120100050704 (Sabine)' },
  { value: '120100050801', regionId: '04', label: '120100050801 (Sabine)' },
  { value: '120100050802', regionId: '04', label: '120100050802 (Sabine)' },
  { value: '120100050804', regionId: '04', label: '120100050804 (Sabine)' },
  { value: '120100050901', regionId: '04', label: '120100050901 (Sabine)' },
  { value: '120100050803', regionId: '04', label: '120100050803 (Sabine)' },
  { value: '120100050902', regionId: '04', label: '120100050902 (Sabine)' },
  { value: '120100051001', regionId: '04', label: '120100051001 (Sabine)' },
  { value: '120100051002', regionId: '04', label: '120100051002 (Sabine)' },
  { value: '120100051003', regionId: '04', label: '120100051003 (Sabine)' },
  { value: '120100051004', regionId: '04', label: '120100051004 (Sabine)' },
  { value: '120100051005', regionId: '04', label: '120100051005 (Sabine)' },
  { value: '111403040402', regionId: '04', label: '111403040402 (Sabine)' },
  { value: '111403040502', regionId: '04', label: '111403040502 (Sabine)' },
]

const hucsRegion12 = [
  { value: '121002010106', regionId: '12', label: '121002010106 (San Antonio)' },
  { value: '121002010107', regionId: '12', label: '121002010107 (San Antonio)' },
  { value: '121002010204', regionId: '12', label: '121002010204 (San Antonio)' },
  { value: '121002010207', regionId: '12', label: '121002010207 (San Antonio)' },
  { value: '121002010208', regionId: '12', label: '121002010208 (San Antonio)' },
  { value: '121002010303', regionId: '12', label: '121002010303 (San Antonio)' },
  { value: '121002010404', regionId: '12', label: '121002010404 (San Antonio)' },
  { value: '121002010405', regionId: '12', label: '121002010405 (San Antonio)' },
  { value: '121002010307', regionId: '12', label: '121002010307 (San Antonio)' },
  { value: '121002010308', regionId: '12', label: '121002010308 (San Antonio)' },
  { value: '121002010401', regionId: '12', label: '121002010401 (San Antonio)' },
  { value: '121002020104', regionId: '12', label: '121002020104 (San Antonio)' },
  { value: '121002020105', regionId: '12', label: '121002020105 (San Antonio)' },
  { value: '121002020106', regionId: '12', label: '121002020106 (San Antonio)' },
  { value: '121002020107', regionId: '12', label: '121002020107 (San Antonio)' },
  { value: '121002020108', regionId: '12', label: '121002020108 (San Antonio)' },
  { value: '121002020109', regionId: '12', label: '121002020109 (San Antonio)' },
  { value: '121002020501', regionId: '12', label: '121002020501 (San Antonio)' },
  { value: '121002020504', regionId: '12', label: '121002020504 (San Antonio)' },
  { value: '121002020507', regionId: '12', label: '121002020507 (San Antonio)' },
  { value: '121002040305', regionId: '12', label: '121002040305 (San Antonio)' },
  { value: '121002040403', regionId: '12', label: '121002040403 (San Antonio)' },
  { value: '121002040201', regionId: '12', label: '121002040201 (San Antonio)' },
  { value: '121002040202', regionId: '12', label: '121002040202 (San Antonio)' },
  { value: '121002040203', regionId: '12', label: '121002040203 (San Antonio)' },
  { value: '121002040205', regionId: '12', label: '121002040205 (San Antonio)' },
  { value: '121002040206', regionId: '12', label: '121002040206 (San Antonio)' },
  { value: '121002040303', regionId: '12', label: '121002040303 (San Antonio)' },
  { value: '121002040304', regionId: '12', label: '121002040304 (San Antonio)' },
  { value: '121002040404', regionId: '12', label: '121002040404 (San Antonio)' },
  { value: '121003010101', regionId: '12', label: '121003010101 (San Antonio)' },
  { value: '121003010102', regionId: '12', label: '121003010102 (San Antonio)' },
  { value: '121003010103', regionId: '12', label: '121003010103 (San Antonio)' },
  { value: '121003010104', regionId: '12', label: '121003010104 (San Antonio)' },
  { value: '121003010105', regionId: '12', label: '121003010105 (San Antonio)' },
  { value: '121003010106', regionId: '12', label: '121003010106 (San Antonio)' },
  { value: '121003010201', regionId: '12', label: '121003010201 (San Antonio)' },
  { value: '121003010202', regionId: '12', label: '121003010202 (San Antonio)' },
  { value: '121003010203', regionId: '12', label: '121003010203 (San Antonio)' },
  { value: '121003010204', regionId: '12', label: '121003010204 (San Antonio)' },
  { value: '121003010301', regionId: '12', label: '121003010301 (San Antonio)' },
  { value: '121003010302', regionId: '12', label: '121003010302 (San Antonio)' },
  { value: '121003010303', regionId: '12', label: '121003010303 (San Antonio)' },
  { value: '121003030101', regionId: '12', label: '121003030101 (San Antonio)' },
  { value: '121003030102', regionId: '12', label: '121003030102 (San Antonio)' },
  { value: '121003030103', regionId: '12', label: '121003030103 (San Antonio)' },
  { value: '121003030104', regionId: '12', label: '121003030104 (San Antonio)' },
  { value: '121003030105', regionId: '12', label: '121003030105 (San Antonio)' },
  { value: '121003030106', regionId: '12', label: '121003030106 (San Antonio)' },
  { value: '121003030201', regionId: '12', label: '121003030201 (San Antonio)' },
  { value: '121003030202', regionId: '12', label: '121003030202 (San Antonio)' },
  { value: '121003030203', regionId: '12', label: '121003030203 (San Antonio)' },
  { value: '121003030204', regionId: '12', label: '121003030204 (San Antonio)' },
  { value: '121003030205', regionId: '12', label: '121003030205 (San Antonio)' },
  { value: '121003030206', regionId: '12', label: '121003030206 (San Antonio)' },
  { value: '121003030301', regionId: '12', label: '121003030301 (San Antonio)' },
  { value: '121003030302', regionId: '12', label: '121003030302 (San Antonio)' },
  { value: '121003030303', regionId: '12', label: '121003030303 (San Antonio)' },
  { value: '121003030304', regionId: '12', label: '121003030304 (San Antonio)' },
  { value: '121003030305', regionId: '12', label: '121003030305 (San Antonio)' },
  { value: '121003030306', regionId: '12', label: '121003030306 (San Antonio)' },
  { value: '121003030401', regionId: '12', label: '121003030401 (San Antonio)' },
  { value: '121003030402', regionId: '12', label: '121003030402 (San Antonio)' },
  { value: '121003030403', regionId: '12', label: '121003030403 (San Antonio)' },
  { value: '121003030404', regionId: '12', label: '121003030404 (San Antonio)' },
  { value: '121003030405', regionId: '12', label: '121003030405 (San Antonio)' },
  { value: '121003030501', regionId: '12', label: '121003030501 (San Antonio)' },
  { value: '121003030502', regionId: '12', label: '121003030502 (San Antonio)' },
  { value: '121003030503', regionId: '12', label: '121003030503 (San Antonio)' },
  { value: '121003030504', regionId: '12', label: '121003030504 (San Antonio)' },
  { value: '121003030505', regionId: '12', label: '121003030505 (San Antonio)' },
  { value: '121003030506', regionId: '12', label: '121003030506 (San Antonio)' },
  { value: '121003030507', regionId: '12', label: '121003030507 (San Antonio)' },
  { value: '121003030601', regionId: '12', label: '121003030601 (San Antonio)' },
  { value: '121003030602', regionId: '12', label: '121003030602 (San Antonio)' },
  { value: '121003030603', regionId: '12', label: '121003030603 (San Antonio)' },
  { value: '121003030604', regionId: '12', label: '121003030604 (San Antonio)' },
  { value: '121003030605', regionId: '12', label: '121003030605 (San Antonio)' },
  { value: '121003030606', regionId: '12', label: '121003030606 (San Antonio)' },
  { value: '121003030607', regionId: '12', label: '121003030607 (San Antonio)' },
  { value: '121003030608', regionId: '12', label: '121003030608 (San Antonio)' },
  { value: '121003040104', regionId: '12', label: '121003040104 (San Antonio)' },
  { value: '121003040101', regionId: '12', label: '121003040101 (San Antonio)' },
  { value: '121003040102', regionId: '12', label: '121003040102 (San Antonio)' },
  { value: '121003040103', regionId: '12', label: '121003040103 (San Antonio)' },
  { value: '121003040105', regionId: '12', label: '121003040105 (San Antonio)' },
  { value: '121003040201', regionId: '12', label: '121003040201 (San Antonio)' },
  { value: '121003040202', regionId: '12', label: '121003040202 (San Antonio)' },
  { value: '121003040203', regionId: '12', label: '121003040203 (San Antonio)' },
  { value: '121003040204', regionId: '12', label: '121003040204 (San Antonio)' },
  { value: '121003040205', regionId: '12', label: '121003040205 (San Antonio)' },
  { value: '121003040206', regionId: '12', label: '121003040206 (San Antonio)' },
  { value: '121003040301', regionId: '12', label: '121003040301 (San Antonio)' },
  { value: '121003040302', regionId: '12', label: '121003040302 (San Antonio)' },
  { value: '121003040303', regionId: '12', label: '121003040303 (San Antonio)' },
  { value: '121003040304', regionId: '12', label: '121003040304 (San Antonio)' },
  { value: '121003040305', regionId: '12', label: '121003040305 (San Antonio)' },
  { value: '121003040401', regionId: '12', label: '121003040401 (San Antonio)' },
  { value: '121003040402', regionId: '12', label: '121003040402 (San Antonio)' },
  { value: '121003040403', regionId: '12', label: '121003040403 (San Antonio)' },
  { value: '121003040404', regionId: '12', label: '121003040404 (San Antonio)' },
  { value: '121003040405', regionId: '12', label: '121003040405 (San Antonio)' },
  { value: '121004020500', regionId: '12', label: '121004020500 (San Antonio)' },
  { value: '121004030200', regionId: '12', label: '121004030200 (San Antonio)' },
  { value: '121004030100', regionId: '12', label: '121004030100 (San Antonio)' },
  { value: '121004030300', regionId: '12', label: '121004030300 (San Antonio)' },
  { value: '121004040000', regionId: '12', label: '121004040000 (San Antonio)' },
  { value: '121004050101', regionId: '12', label: '121004050101 (San Antonio)' },
  { value: '121004050400', regionId: '12', label: '121004050400 (San Antonio)' },
  { value: '121004050102', regionId: '12', label: '121004050102 (San Antonio)' },
  { value: '121004050301', regionId: '12', label: '121004050301 (San Antonio)' },
  { value: '121004050302', regionId: '12', label: '121004050302 (San Antonio)' },
  { value: '121004050303', regionId: '12', label: '121004050303 (San Antonio)' },
  { value: '121004050304', regionId: '12', label: '121004050304 (San Antonio)' },
  { value: '121004050307', regionId: '12', label: '121004050307 (San Antonio)' },
  { value: '121004060207', regionId: '12', label: '121004060207 (San Antonio)' },
  { value: '121004060305', regionId: '12', label: '121004060305 (San Antonio)' },
  { value: '121004060101', regionId: '12', label: '121004060101 (San Antonio)' },
  { value: '121004060201', regionId: '12', label: '121004060201 (San Antonio)' },
  { value: '121004060202', regionId: '12', label: '121004060202 (San Antonio)' },
  { value: '121004060203', regionId: '12', label: '121004060203 (San Antonio)' },
  { value: '121101060106', regionId: '12', label: '121101060106 (San Antonio)' },
  { value: '121101060601', regionId: '12', label: '121101060601 (San Antonio)' },
  { value: '121101060602', regionId: '12', label: '121101060602 (San Antonio)' },
  { value: '121101060605', regionId: '12', label: '121101060605 (San Antonio)' },
  { value: '121101070101', regionId: '12', label: '121101070101 (San Antonio)' },
  { value: '121101070102', regionId: '12', label: '121101070102 (San Antonio)' },
  { value: '121101070104', regionId: '12', label: '121101070104 (San Antonio)' },
  { value: '121101070107', regionId: '12', label: '121101070107 (San Antonio)' },
  { value: '121101070108', regionId: '12', label: '121101070108 (San Antonio)' },
  { value: '121101070301', regionId: '12', label: '121101070301 (San Antonio)' },
  { value: '121101090101', regionId: '12', label: '121101090101 (San Antonio)' },
  { value: '121101090102', regionId: '12', label: '121101090102 (San Antonio)' },
  { value: '121101100202', regionId: '12', label: '121101100202 (San Antonio)' },
  { value: '121101100204', regionId: '12', label: '121101100204 (San Antonio)' },
  { value: '121101100205', regionId: '12', label: '121101100205 (San Antonio)' },
  { value: '121101100101', regionId: '12', label: '121101100101 (San Antonio)' },
  { value: '121101100102', regionId: '12', label: '121101100102 (San Antonio)' },
  { value: '121101100303', regionId: '12', label: '121101100303 (San Antonio)' },
  { value: '121101100304', regionId: '12', label: '121101100304 (San Antonio)' },
  { value: '121101100305', regionId: '12', label: '121101100305 (San Antonio)' },
  { value: '121101100306', regionId: '12', label: '121101100306 (San Antonio)' },
  { value: '121101100504', regionId: '12', label: '121101100504 (San Antonio)' },
  { value: '121101100505', regionId: '12', label: '121101100505 (San Antonio)' },
  { value: '121101110102', regionId: '12', label: '121101110102 (San Antonio)' },
  { value: '121003020206', regionId: '12', label: '121003020206 (San Antonio)' },
  { value: '121003020301', regionId: '12', label: '121003020301 (San Antonio)' },
  { value: '121003020401', regionId: '12', label: '121003020401 (San Antonio)' },
  { value: '121003020402', regionId: '12', label: '121003020402 (San Antonio)' },
  { value: '121003020403', regionId: '12', label: '121003020403 (San Antonio)' },
  { value: '121003020404', regionId: '12', label: '121003020404 (San Antonio)' },
  { value: '121003020405', regionId: '12', label: '121003020405 (San Antonio)' },
  { value: '121003020201', regionId: '12', label: '121003020201 (San Antonio)' },
  { value: '121003020202', regionId: '12', label: '121003020202 (San Antonio)' },
  { value: '121003020203', regionId: '12', label: '121003020203 (San Antonio)' },
  { value: '121003020204', regionId: '12', label: '121003020204 (San Antonio)' },
  { value: '121003020205', regionId: '12', label: '121003020205 (San Antonio)' },
  { value: '121003020101', regionId: '12', label: '121003020101 (San Antonio)' },
  { value: '121003020102', regionId: '12', label: '121003020102 (San Antonio)' },
  { value: '121003020103', regionId: '12', label: '121003020103 (San Antonio)' },
  { value: '121003020104', regionId: '12', label: '121003020104 (San Antonio)' },
  { value: '121003020105', regionId: '12', label: '121003020105 (San Antonio)' },
  { value: '121003020106', regionId: '12', label: '121003020106 (San Antonio)' },
  { value: '121003020107', regionId: '12', label: '121003020107 (San Antonio)' },
  { value: '121003020302', regionId: '12', label: '121003020302 (San Antonio)' },
  { value: '121003020303', regionId: '12', label: '121003020303 (San Antonio)' },
  { value: '121003020304', regionId: '12', label: '121003020304 (San Antonio)' },
  { value: '121003020305', regionId: '12', label: '121003020305 (San Antonio)' },
  { value: '121003020306', regionId: '12', label: '121003020306 (San Antonio)' },
  { value: '121003020307', regionId: '12', label: '121003020307 (San Antonio)' },
  { value: '121003020406', regionId: '12', label: '121003020406 (San Antonio)' },
  { value: '121003020501', regionId: '12', label: '121003020501 (San Antonio)' },
  { value: '121003020502', regionId: '12', label: '121003020502 (San Antonio)' },
  { value: '121003020503', regionId: '12', label: '121003020503 (San Antonio)' },
  { value: '121003020504', regionId: '12', label: '121003020504 (San Antonio)' },
  { value: '121003020505', regionId: '12', label: '121003020505 (San Antonio)' },
  { value: '121003020506', regionId: '12', label: '121003020506 (San Antonio)' },
  { value: '121003020507', regionId: '12', label: '121003020507 (San Antonio)' },
  { value: '121003020508', regionId: '12', label: '121003020508 (San Antonio)' },
  { value: '121003020509', regionId: '12', label: '121003020509 (San Antonio)' },
]

const hucsRegion06 = [
  { value: '120302020501', regionId: '06', label: '120302020501 (San Jacinto)' },
  { value: '120302020502', regionId: '06', label: '120302020502 (San Jacinto)' },
  { value: '120302020505', regionId: '06', label: '120302020505 (San Jacinto)' },
  { value: '120302020801', regionId: '06', label: '120302020801 (San Jacinto)' },
  { value: '120302020804', regionId: '06', label: '120302020804 (San Jacinto)' },
  { value: '120302020805', regionId: '06', label: '120302020805 (San Jacinto)' },
  { value: '120302020807', regionId: '06', label: '120302020807 (San Jacinto)' },
  { value: '120302021101', regionId: '06', label: '120302021101 (San Jacinto)' },
  { value: '120302021104', regionId: '06', label: '120302021104 (San Jacinto)' },
  { value: '120302021105', regionId: '06', label: '120302021105 (San Jacinto)' },
  { value: '120302021201', regionId: '06', label: '120302021201 (San Jacinto)' },
  { value: '120302021207', regionId: '06', label: '120302021207 (San Jacinto)' },
  { value: '120302030108', regionId: '06', label: '120302030108 (San Jacinto)' },
  { value: '120302030101', regionId: '06', label: '120302030101 (San Jacinto)' },
  { value: '120302030105', regionId: '06', label: '120302030105 (San Jacinto)' },
  { value: '120302030106', regionId: '06', label: '120302030106 (San Jacinto)' },
  { value: '120302030301', regionId: '06', label: '120302030301 (San Jacinto)' },
  { value: '120302030302', regionId: '06', label: '120302030302 (San Jacinto)' },
  { value: '120302030304', regionId: '06', label: '120302030304 (San Jacinto)' },
  { value: '120302030306', regionId: '06', label: '120302030306 (San Jacinto)' },
  { value: '120302030307', regionId: '06', label: '120302030307 (San Jacinto)' },
  { value: '120401010308', regionId: '06', label: '120401010308 (San Jacinto)' },
  { value: '120401010401', regionId: '06', label: '120401010401 (San Jacinto)' },
  { value: '120401010101', regionId: '06', label: '120401010101 (San Jacinto)' },
  { value: '120401010102', regionId: '06', label: '120401010102 (San Jacinto)' },
  { value: '120401010103', regionId: '06', label: '120401010103 (San Jacinto)' },
  { value: '120401010104', regionId: '06', label: '120401010104 (San Jacinto)' },
  { value: '120401010201', regionId: '06', label: '120401010201 (San Jacinto)' },
  { value: '120401010202', regionId: '06', label: '120401010202 (San Jacinto)' },
  { value: '120401010203', regionId: '06', label: '120401010203 (San Jacinto)' },
  { value: '120401010204', regionId: '06', label: '120401010204 (San Jacinto)' },
  { value: '120401010205', regionId: '06', label: '120401010205 (San Jacinto)' },
  { value: '120401010206', regionId: '06', label: '120401010206 (San Jacinto)' },
  { value: '120401010207', regionId: '06', label: '120401010207 (San Jacinto)' },
  { value: '120401010301', regionId: '06', label: '120401010301 (San Jacinto)' },
  { value: '120401010302', regionId: '06', label: '120401010302 (San Jacinto)' },
  { value: '120401010303', regionId: '06', label: '120401010303 (San Jacinto)' },
  { value: '120401010304', regionId: '06', label: '120401010304 (San Jacinto)' },
  { value: '120401010305', regionId: '06', label: '120401010305 (San Jacinto)' },
  { value: '120401010306', regionId: '06', label: '120401010306 (San Jacinto)' },
  { value: '120401010307', regionId: '06', label: '120401010307 (San Jacinto)' },
  { value: '120401010402', regionId: '06', label: '120401010402 (San Jacinto)' },
  { value: '120401010403', regionId: '06', label: '120401010403 (San Jacinto)' },
  { value: '120401010404', regionId: '06', label: '120401010404 (San Jacinto)' },
  { value: '120401010501', regionId: '06', label: '120401010501 (San Jacinto)' },
  { value: '120401010502', regionId: '06', label: '120401010502 (San Jacinto)' },
  { value: '120401020101', regionId: '06', label: '120401020101 (San Jacinto)' },
  { value: '120401020102', regionId: '06', label: '120401020102 (San Jacinto)' },
  { value: '120401020103', regionId: '06', label: '120401020103 (San Jacinto)' },
  { value: '120401020104', regionId: '06', label: '120401020104 (San Jacinto)' },
  { value: '120401020105', regionId: '06', label: '120401020105 (San Jacinto)' },
  { value: '120401020106', regionId: '06', label: '120401020106 (San Jacinto)' },
  { value: '120401020107', regionId: '06', label: '120401020107 (San Jacinto)' },
  { value: '120401020201', regionId: '06', label: '120401020201 (San Jacinto)' },
  { value: '120401020202', regionId: '06', label: '120401020202 (San Jacinto)' },
  { value: '120401020203', regionId: '06', label: '120401020203 (San Jacinto)' },
  { value: '120401020204', regionId: '06', label: '120401020204 (San Jacinto)' },
  { value: '120401020205', regionId: '06', label: '120401020205 (San Jacinto)' },
  { value: '120401020206', regionId: '06', label: '120401020206 (San Jacinto)' },
  { value: '120401020209', regionId: '06', label: '120401020209 (San Jacinto)' },
  { value: '120401020210', regionId: '06', label: '120401020210 (San Jacinto)' },
  { value: '120401020211', regionId: '06', label: '120401020211 (San Jacinto)' },
  { value: '120401020212', regionId: '06', label: '120401020212 (San Jacinto)' },
  { value: '120401020213', regionId: '06', label: '120401020213 (San Jacinto)' },
  { value: '120401020207', regionId: '06', label: '120401020207 (San Jacinto)' },
  { value: '120401020208', regionId: '06', label: '120401020208 (San Jacinto)' },
  { value: '120401030203', regionId: '06', label: '120401030203 (San Jacinto)' },
  { value: '120401030101', regionId: '06', label: '120401030101 (San Jacinto)' },
  { value: '120401030102', regionId: '06', label: '120401030102 (San Jacinto)' },
  { value: '120401030103', regionId: '06', label: '120401030103 (San Jacinto)' },
  { value: '120401030104', regionId: '06', label: '120401030104 (San Jacinto)' },
  { value: '120401030105', regionId: '06', label: '120401030105 (San Jacinto)' },
  { value: '120401030106', regionId: '06', label: '120401030106 (San Jacinto)' },
  { value: '120401030107', regionId: '06', label: '120401030107 (San Jacinto)' },
  { value: '120401030108', regionId: '06', label: '120401030108 (San Jacinto)' },
  { value: '120401030109', regionId: '06', label: '120401030109 (San Jacinto)' },
  { value: '120401030110', regionId: '06', label: '120401030110 (San Jacinto)' },
  { value: '120401030201', regionId: '06', label: '120401030201 (San Jacinto)' },
  { value: '120401030204', regionId: '06', label: '120401030204 (San Jacinto)' },
  { value: '120401030401', regionId: '06', label: '120401030401 (San Jacinto)' },
  { value: '120401030402', regionId: '06', label: '120401030402 (San Jacinto)' },
  { value: '120401030202', regionId: '06', label: '120401030202 (San Jacinto)' },
  { value: '120401030205', regionId: '06', label: '120401030205 (San Jacinto)' },
  { value: '120401030301', regionId: '06', label: '120401030301 (San Jacinto)' },
  { value: '120401030302', regionId: '06', label: '120401030302 (San Jacinto)' },
  { value: '120401030303', regionId: '06', label: '120401030303 (San Jacinto)' },
  { value: '120401030304', regionId: '06', label: '120401030304 (San Jacinto)' },
  { value: '120401030305', regionId: '06', label: '120401030305 (San Jacinto)' },
  { value: '120401030306', regionId: '06', label: '120401030306 (San Jacinto)' },
  { value: '120401030307', regionId: '06', label: '120401030307 (San Jacinto)' },
  { value: '120401030308', regionId: '06', label: '120401030308 (San Jacinto)' },
  { value: '120401040606', regionId: '06', label: '120401040606 (San Jacinto)' },
  { value: '120401040101', regionId: '06', label: '120401040101 (San Jacinto)' },
  { value: '120401040102', regionId: '06', label: '120401040102 (San Jacinto)' },
  { value: '120401040103', regionId: '06', label: '120401040103 (San Jacinto)' },
  { value: '120401040104', regionId: '06', label: '120401040104 (San Jacinto)' },
  { value: '120401040201', regionId: '06', label: '120401040201 (San Jacinto)' },
  { value: '120401040202', regionId: '06', label: '120401040202 (San Jacinto)' },
  { value: '120401040203', regionId: '06', label: '120401040203 (San Jacinto)' },
  { value: '120401040501', regionId: '06', label: '120401040501 (San Jacinto)' },
  { value: '120401040604', regionId: '06', label: '120401040604 (San Jacinto)' },
  { value: '120401040605', regionId: '06', label: '120401040605 (San Jacinto)' },
  { value: '120401040301', regionId: '06', label: '120401040301 (San Jacinto)' },
  { value: '120401040302', regionId: '06', label: '120401040302 (San Jacinto)' },
  { value: '120401040305', regionId: '06', label: '120401040305 (San Jacinto)' },
  { value: '120401040303', regionId: '06', label: '120401040303 (San Jacinto)' },
  { value: '120401040304', regionId: '06', label: '120401040304 (San Jacinto)' },
  { value: '120401040401', regionId: '06', label: '120401040401 (San Jacinto)' },
  { value: '120401040402', regionId: '06', label: '120401040402 (San Jacinto)' },
  { value: '120401040502', regionId: '06', label: '120401040502 (San Jacinto)' },
  { value: '120401040601', regionId: '06', label: '120401040601 (San Jacinto)' },
  { value: '120401040602', regionId: '06', label: '120401040602 (San Jacinto)' },
  { value: '120401040603', regionId: '06', label: '120401040603 (San Jacinto)' },
  { value: '120401040701', regionId: '06', label: '120401040701 (San Jacinto)' },
  { value: '120401040702', regionId: '06', label: '120401040702 (San Jacinto)' },
  { value: '120401040703', regionId: '06', label: '120401040703 (San Jacinto)' },
  { value: '120401040704', regionId: '06', label: '120401040704 (San Jacinto)' },
  { value: '120401040705', regionId: '06', label: '120401040705 (San Jacinto)' },
  { value: '120401040706', regionId: '06', label: '120401040706 (San Jacinto)' },
  { value: '120402030101', regionId: '06', label: '120402030101 (San Jacinto)' },
  { value: '120402030104', regionId: '06', label: '120402030104 (San Jacinto)' },
  { value: '120402030105', regionId: '06', label: '120402030105 (San Jacinto)' },
  { value: '120402030102', regionId: '06', label: '120402030102 (San Jacinto)' },
  { value: '120402030103', regionId: '06', label: '120402030103 (San Jacinto)' },
  { value: '120402030106', regionId: '06', label: '120402030106 (San Jacinto)' },
  { value: '120402030200', regionId: '06', label: '120402030200 (San Jacinto)' },
  { value: '120402040100', regionId: '06', label: '120402040100 (San Jacinto)' },
  { value: '120402040200', regionId: '06', label: '120402040200 (San Jacinto)' },
  { value: '120402040300', regionId: '06', label: '120402040300 (San Jacinto)' },
  { value: '120402040400', regionId: '06', label: '120402040400 (San Jacinto)' },
  { value: '120402040500', regionId: '06', label: '120402040500 (San Jacinto)' },
  { value: '120402050100', regionId: '06', label: '120402050100 (San Jacinto)' },
  { value: '120402050200', regionId: '06', label: '120402050200 (San Jacinto)' },
  { value: '120402050300', regionId: '06', label: '120402050300 (San Jacinto)' },
  { value: '120402050400', regionId: '06', label: '120402050400 (San Jacinto)' },
  { value: '120701010802', regionId: '06', label: '120701010802 (San Jacinto)' },
  { value: '120701010803', regionId: '06', label: '120701010803 (San Jacinto)' },
  { value: '120701010804', regionId: '06', label: '120701010804 (San Jacinto)' },
  { value: '120701030705', regionId: '06', label: '120701030705 (San Jacinto)' },
  { value: '120701030801', regionId: '06', label: '120701030801 (San Jacinto)' },
  { value: '120701030802', regionId: '06', label: '120701030802 (San Jacinto)' },
  { value: '120701040104', regionId: '06', label: '120701040104 (San Jacinto)' },
  { value: '120701040105', regionId: '06', label: '120701040105 (San Jacinto)' },
  { value: '120701040301', regionId: '06', label: '120701040301 (San Jacinto)' },
  { value: '120701040303', regionId: '06', label: '120701040303 (San Jacinto)' },
  { value: '120701040308', regionId: '06', label: '120701040308 (San Jacinto)' },
]

const hucsRegion03 = [
  { value: '111302010708', regionId: '03', label: '111302010708 (Trinity)' },
  { value: '111302010703', regionId: '03', label: '111302010703 (Trinity)' },
  { value: '111302010707', regionId: '03', label: '111302010707 (Trinity)' },
  { value: '111302010203', regionId: '03', label: '111302010203 (Trinity)' },
  { value: '111302010204', regionId: '03', label: '111302010204 (Trinity)' },
  { value: '111302010205', regionId: '03', label: '111302010205 (Trinity)' },
  { value: '111302010208', regionId: '03', label: '111302010208 (Trinity)' },
  { value: '111302010209', regionId: '03', label: '111302010209 (Trinity)' },
  { value: '111302010505', regionId: '03', label: '111302010505 (Trinity)' },
  { value: '111302010701', regionId: '03', label: '111302010701 (Trinity)' },
  { value: '111302090203', regionId: '03', label: '111302090203 (Trinity)' },
  { value: '111302090206', regionId: '03', label: '111302090206 (Trinity)' },
  { value: '111302090207', regionId: '03', label: '111302090207 (Trinity)' },
  { value: '111302090301', regionId: '03', label: '111302090301 (Trinity)' },
  { value: '111302090302', regionId: '03', label: '111302090302 (Trinity)' },
  { value: '111302090303', regionId: '03', label: '111302090303 (Trinity)' },
  { value: '111302090401', regionId: '03', label: '111302090401 (Trinity)' },
  { value: '111302090402', regionId: '03', label: '111302090402 (Trinity)' },
  { value: '111302090403', regionId: '03', label: '111302090403 (Trinity)' },
  { value: '111403010101', regionId: '03', label: '111403010101 (Trinity)' },
  { value: '120100010201', regionId: '03', label: '120100010201 (Trinity)' },
  { value: '120100010202', regionId: '03', label: '120100010202 (Trinity)' },
  { value: '120100010101', regionId: '03', label: '120100010101 (Trinity)' },
  { value: '120100010301', regionId: '03', label: '120100010301 (Trinity)' },
  { value: '120100010302', regionId: '03', label: '120100010302 (Trinity)' },
  { value: '120100010303', regionId: '03', label: '120100010303 (Trinity)' },
  { value: '120100010405', regionId: '03', label: '120100010405 (Trinity)' },
  { value: '120100010501', regionId: '03', label: '120100010501 (Trinity)' },
  { value: '120100010502', regionId: '03', label: '120100010502 (Trinity)' },
  { value: '120100010503', regionId: '03', label: '120100010503 (Trinity)' },
  { value: '120100010504', regionId: '03', label: '120100010504 (Trinity)' },
  { value: '120200010403', regionId: '03', label: '120200010403 (Trinity)' },
  { value: '120200010405', regionId: '03', label: '120200010405 (Trinity)' },
  { value: '120200010501', regionId: '03', label: '120200010501 (Trinity)' },
  { value: '120200010502', regionId: '03', label: '120200010502 (Trinity)' },
  { value: '120200010504', regionId: '03', label: '120200010504 (Trinity)' },
  { value: '120200010508', regionId: '03', label: '120200010508 (Trinity)' },
  { value: '120200010509', regionId: '03', label: '120200010509 (Trinity)' },
  { value: '120200010701', regionId: '03', label: '120200010701 (Trinity)' },
  { value: '120200010201', regionId: '03', label: '120200010201 (Trinity)' },
  { value: '120200010202', regionId: '03', label: '120200010202 (Trinity)' },
  { value: '120200010204', regionId: '03', label: '120200010204 (Trinity)' },
  { value: '120200010303', regionId: '03', label: '120200010303 (Trinity)' },
  { value: '120200020102', regionId: '03', label: '120200020102 (Trinity)' },
  { value: '120200020401', regionId: '03', label: '120200020401 (Trinity)' },
  { value: '120200020402', regionId: '03', label: '120200020402 (Trinity)' },
  { value: '120200020403', regionId: '03', label: '120200020403 (Trinity)' },
  { value: '120200020404', regionId: '03', label: '120200020404 (Trinity)' },
  { value: '120200020405', regionId: '03', label: '120200020405 (Trinity)' },
  { value: '120200020406', regionId: '03', label: '120200020406 (Trinity)' },
  { value: '120200020407', regionId: '03', label: '120200020407 (Trinity)' },
  { value: '120200020408', regionId: '03', label: '120200020408 (Trinity)' },
  { value: '120200060101', regionId: '03', label: '120200060101 (Trinity)' },
  { value: '120200060103', regionId: '03', label: '120200060103 (Trinity)' },
  { value: '120200070108', regionId: '03', label: '120200070108 (Trinity)' },
  { value: '120200070101', regionId: '03', label: '120200070101 (Trinity)' },
  { value: '120200070102', regionId: '03', label: '120200070102 (Trinity)' },
  { value: '120200070106', regionId: '03', label: '120200070106 (Trinity)' },
  { value: '120200070109', regionId: '03', label: '120200070109 (Trinity)' },
  { value: '120200070110', regionId: '03', label: '120200070110 (Trinity)' },
  { value: '120200070202', regionId: '03', label: '120200070202 (Trinity)' },
  { value: '120301010205', regionId: '03', label: '120301010205 (Trinity)' },
  { value: '120301010206', regionId: '03', label: '120301010206 (Trinity)' },
  { value: '120301010207', regionId: '03', label: '120301010207 (Trinity)' },
  { value: '120301010208', regionId: '03', label: '120301010208 (Trinity)' },
  { value: '120301010610', regionId: '03', label: '120301010610 (Trinity)' },
  { value: '120301010101', regionId: '03', label: '120301010101 (Trinity)' },
  { value: '120301010102', regionId: '03', label: '120301010102 (Trinity)' },
  { value: '120301010103', regionId: '03', label: '120301010103 (Trinity)' },
  { value: '120301010104', regionId: '03', label: '120301010104 (Trinity)' },
  { value: '120301010105', regionId: '03', label: '120301010105 (Trinity)' },
  { value: '120301010106', regionId: '03', label: '120301010106 (Trinity)' },
  { value: '120301010107', regionId: '03', label: '120301010107 (Trinity)' },
  { value: '120301010201', regionId: '03', label: '120301010201 (Trinity)' },
  { value: '120301010202', regionId: '03', label: '120301010202 (Trinity)' },
  { value: '120301010203', regionId: '03', label: '120301010203 (Trinity)' },
  { value: '120301010204', regionId: '03', label: '120301010204 (Trinity)' },
  { value: '120301010301', regionId: '03', label: '120301010301 (Trinity)' },
  { value: '120301010302', regionId: '03', label: '120301010302 (Trinity)' },
  { value: '120301010303', regionId: '03', label: '120301010303 (Trinity)' },
  { value: '120301010304', regionId: '03', label: '120301010304 (Trinity)' },
  { value: '120301010401', regionId: '03', label: '120301010401 (Trinity)' },
  { value: '120301010402', regionId: '03', label: '120301010402 (Trinity)' },
  { value: '120301010403', regionId: '03', label: '120301010403 (Trinity)' },
  { value: '120301010404', regionId: '03', label: '120301010404 (Trinity)' },
  { value: '120301010405', regionId: '03', label: '120301010405 (Trinity)' },
  { value: '120301010406', regionId: '03', label: '120301010406 (Trinity)' },
  { value: '120301010407', regionId: '03', label: '120301010407 (Trinity)' },
  { value: '120301010408', regionId: '03', label: '120301010408 (Trinity)' },
  { value: '120301010409', regionId: '03', label: '120301010409 (Trinity)' },
  { value: '120301010410', regionId: '03', label: '120301010410 (Trinity)' },
  { value: '120301010411', regionId: '03', label: '120301010411 (Trinity)' },
  { value: '120301010501', regionId: '03', label: '120301010501 (Trinity)' },
  { value: '120301010502', regionId: '03', label: '120301010502 (Trinity)' },
  { value: '120301010503', regionId: '03', label: '120301010503 (Trinity)' },
  { value: '120301010603', regionId: '03', label: '120301010603 (Trinity)' },
  { value: '120301010604', regionId: '03', label: '120301010604 (Trinity)' },
  { value: '120301010605', regionId: '03', label: '120301010605 (Trinity)' },
  { value: '120301010606', regionId: '03', label: '120301010606 (Trinity)' },
  { value: '120301010504', regionId: '03', label: '120301010504 (Trinity)' },
  { value: '120301010505', regionId: '03', label: '120301010505 (Trinity)' },
  { value: '120301010506', regionId: '03', label: '120301010506 (Trinity)' },
  { value: '120301010507', regionId: '03', label: '120301010507 (Trinity)' },
  { value: '120301010508', regionId: '03', label: '120301010508 (Trinity)' },
  { value: '120301010509', regionId: '03', label: '120301010509 (Trinity)' },
  { value: '120301010510', regionId: '03', label: '120301010510 (Trinity)' },
  { value: '120301010511', regionId: '03', label: '120301010511 (Trinity)' },
  { value: '120301010601', regionId: '03', label: '120301010601 (Trinity)' },
  { value: '120301010602', regionId: '03', label: '120301010602 (Trinity)' },
  { value: '120301010607', regionId: '03', label: '120301010607 (Trinity)' },
  { value: '120301010608', regionId: '03', label: '120301010608 (Trinity)' },
  { value: '120301010609', regionId: '03', label: '120301010609 (Trinity)' },
  { value: '120301020101', regionId: '03', label: '120301020101 (Trinity)' },
  { value: '120301020102', regionId: '03', label: '120301020102 (Trinity)' },
  { value: '120301020103', regionId: '03', label: '120301020103 (Trinity)' },
  { value: '120301020104', regionId: '03', label: '120301020104 (Trinity)' },
  { value: '120301020105', regionId: '03', label: '120301020105 (Trinity)' },
  { value: '120301020201', regionId: '03', label: '120301020201 (Trinity)' },
  { value: '120301020202', regionId: '03', label: '120301020202 (Trinity)' },
  { value: '120301020203', regionId: '03', label: '120301020203 (Trinity)' },
  { value: '120301020204', regionId: '03', label: '120301020204 (Trinity)' },
  { value: '120301020205', regionId: '03', label: '120301020205 (Trinity)' },
  { value: '120301020206', regionId: '03', label: '120301020206 (Trinity)' },
  { value: '120301020207', regionId: '03', label: '120301020207 (Trinity)' },
  { value: '120301020208', regionId: '03', label: '120301020208 (Trinity)' },
  { value: '120301020301', regionId: '03', label: '120301020301 (Trinity)' },
  { value: '120301020302', regionId: '03', label: '120301020302 (Trinity)' },
  { value: '120301020303', regionId: '03', label: '120301020303 (Trinity)' },
  { value: '120301020304', regionId: '03', label: '120301020304 (Trinity)' },
  { value: '120301020305', regionId: '03', label: '120301020305 (Trinity)' },
  { value: '120301020306', regionId: '03', label: '120301020306 (Trinity)' },
  { value: '120301020307', regionId: '03', label: '120301020307 (Trinity)' },
  { value: '120301020401', regionId: '03', label: '120301020401 (Trinity)' },
  { value: '120301020402', regionId: '03', label: '120301020402 (Trinity)' },
  { value: '120301020403', regionId: '03', label: '120301020403 (Trinity)' },
  { value: '120301020703', regionId: '03', label: '120301020703 (Trinity)' },
  { value: '120301020704', regionId: '03', label: '120301020704 (Trinity)' },
  { value: '120301020705', regionId: '03', label: '120301020705 (Trinity)' },
  { value: '120301020706', regionId: '03', label: '120301020706 (Trinity)' },
  { value: '120301020404', regionId: '03', label: '120301020404 (Trinity)' },
  { value: '120301020405', regionId: '03', label: '120301020405 (Trinity)' },
  { value: '120301020501', regionId: '03', label: '120301020501 (Trinity)' },
  { value: '120301020502', regionId: '03', label: '120301020502 (Trinity)' },
  { value: '120301020503', regionId: '03', label: '120301020503 (Trinity)' },
  { value: '120301020504', regionId: '03', label: '120301020504 (Trinity)' },
  { value: '120301020505', regionId: '03', label: '120301020505 (Trinity)' },
  { value: '120301020506', regionId: '03', label: '120301020506 (Trinity)' },
  { value: '120301020601', regionId: '03', label: '120301020601 (Trinity)' },
  { value: '120301020602', regionId: '03', label: '120301020602 (Trinity)' },
  { value: '120301020603', regionId: '03', label: '120301020603 (Trinity)' },
  { value: '120301020604', regionId: '03', label: '120301020604 (Trinity)' },
  { value: '120301020605', regionId: '03', label: '120301020605 (Trinity)' },
  { value: '120301020606', regionId: '03', label: '120301020606 (Trinity)' },
  { value: '120301020607', regionId: '03', label: '120301020607 (Trinity)' },
  { value: '120301020701', regionId: '03', label: '120301020701 (Trinity)' },
  { value: '120301020702', regionId: '03', label: '120301020702 (Trinity)' },
  { value: '120301031006', regionId: '03', label: '120301031006 (Trinity)' },
  { value: '120301031007', regionId: '03', label: '120301031007 (Trinity)' },
  { value: '120301030101', regionId: '03', label: '120301030101 (Trinity)' },
  { value: '120301030102', regionId: '03', label: '120301030102 (Trinity)' },
  { value: '120301030103', regionId: '03', label: '120301030103 (Trinity)' },
  { value: '120301030104', regionId: '03', label: '120301030104 (Trinity)' },
  { value: '120301030105', regionId: '03', label: '120301030105 (Trinity)' },
  { value: '120301030106', regionId: '03', label: '120301030106 (Trinity)' },
  { value: '120301030201', regionId: '03', label: '120301030201 (Trinity)' },
  { value: '120301030202', regionId: '03', label: '120301030202 (Trinity)' },
  { value: '120301030301', regionId: '03', label: '120301030301 (Trinity)' },
  { value: '120301030302', regionId: '03', label: '120301030302 (Trinity)' },
  { value: '120301030303', regionId: '03', label: '120301030303 (Trinity)' },
  { value: '120301030304', regionId: '03', label: '120301030304 (Trinity)' },
  { value: '120301030401', regionId: '03', label: '120301030401 (Trinity)' },
  { value: '120301030402', regionId: '03', label: '120301030402 (Trinity)' },
  { value: '120301030403', regionId: '03', label: '120301030403 (Trinity)' },
  { value: '120301030404', regionId: '03', label: '120301030404 (Trinity)' },
  { value: '120301030405', regionId: '03', label: '120301030405 (Trinity)' },
  { value: '120301030406', regionId: '03', label: '120301030406 (Trinity)' },
  { value: '120301030501', regionId: '03', label: '120301030501 (Trinity)' },
  { value: '120301030502', regionId: '03', label: '120301030502 (Trinity)' },
  { value: '120301030503', regionId: '03', label: '120301030503 (Trinity)' },
  { value: '120301030504', regionId: '03', label: '120301030504 (Trinity)' },
  { value: '120301030505', regionId: '03', label: '120301030505 (Trinity)' },
  { value: '120301030203', regionId: '03', label: '120301030203 (Trinity)' },
  { value: '120301030204', regionId: '03', label: '120301030204 (Trinity)' },
  { value: '120301030205', regionId: '03', label: '120301030205 (Trinity)' },
  { value: '120301030206', regionId: '03', label: '120301030206 (Trinity)' },
  { value: '120301030601', regionId: '03', label: '120301030601 (Trinity)' },
  { value: '120301030602', regionId: '03', label: '120301030602 (Trinity)' },
  { value: '120301030603', regionId: '03', label: '120301030603 (Trinity)' },
  { value: '120301030604', regionId: '03', label: '120301030604 (Trinity)' },
  { value: '120301030605', regionId: '03', label: '120301030605 (Trinity)' },
  { value: '120301030701', regionId: '03', label: '120301030701 (Trinity)' },
  { value: '120301030703', regionId: '03', label: '120301030703 (Trinity)' },
  { value: '120301030704', regionId: '03', label: '120301030704 (Trinity)' },
  { value: '120301030702', regionId: '03', label: '120301030702 (Trinity)' },
  { value: '120301030705', regionId: '03', label: '120301030705 (Trinity)' },
  { value: '120301030801', regionId: '03', label: '120301030801 (Trinity)' },
  { value: '120301030802', regionId: '03', label: '120301030802 (Trinity)' },
  { value: '120301030803', regionId: '03', label: '120301030803 (Trinity)' },
  { value: '120301030804', regionId: '03', label: '120301030804 (Trinity)' },
  { value: '120301030805', regionId: '03', label: '120301030805 (Trinity)' },
  { value: '120301030901', regionId: '03', label: '120301030901 (Trinity)' },
  { value: '120301030902', regionId: '03', label: '120301030902 (Trinity)' },
  { value: '120301030903', regionId: '03', label: '120301030903 (Trinity)' },
  { value: '120301030904', regionId: '03', label: '120301030904 (Trinity)' },
  { value: '120301030905', regionId: '03', label: '120301030905 (Trinity)' },
  { value: '120301030906', regionId: '03', label: '120301030906 (Trinity)' },
  { value: '120301031001', regionId: '03', label: '120301031001 (Trinity)' },
  { value: '120301031002', regionId: '03', label: '120301031002 (Trinity)' },
  { value: '120301031003', regionId: '03', label: '120301031003 (Trinity)' },
  { value: '120301031004', regionId: '03', label: '120301031004 (Trinity)' },
  { value: '120301031005', regionId: '03', label: '120301031005 (Trinity)' },
  { value: '120301040103', regionId: '03', label: '120301040103 (Trinity)' },
  { value: '120301040104', regionId: '03', label: '120301040104 (Trinity)' },
  { value: '120301040105', regionId: '03', label: '120301040105 (Trinity)' },
  { value: '120301040201', regionId: '03', label: '120301040201 (Trinity)' },
  { value: '120301040202', regionId: '03', label: '120301040202 (Trinity)' },
  { value: '120301040203', regionId: '03', label: '120301040203 (Trinity)' },
  { value: '120301040204', regionId: '03', label: '120301040204 (Trinity)' },
  { value: '120301040205', regionId: '03', label: '120301040205 (Trinity)' },
  { value: '120301040206', regionId: '03', label: '120301040206 (Trinity)' },
  { value: '120301040301', regionId: '03', label: '120301040301 (Trinity)' },
  { value: '120301040302', regionId: '03', label: '120301040302 (Trinity)' },
  { value: '120301040303', regionId: '03', label: '120301040303 (Trinity)' },
  { value: '120301040304', regionId: '03', label: '120301040304 (Trinity)' },
  { value: '120301040305', regionId: '03', label: '120301040305 (Trinity)' },
  { value: '120301040306', regionId: '03', label: '120301040306 (Trinity)' },
  { value: '120301040101', regionId: '03', label: '120301040101 (Trinity)' },
  { value: '120301040102', regionId: '03', label: '120301040102 (Trinity)' },
  { value: '120301040307', regionId: '03', label: '120301040307 (Trinity)' },
  { value: '120301050101', regionId: '03', label: '120301050101 (Trinity)' },
  { value: '120301050102', regionId: '03', label: '120301050102 (Trinity)' },
  { value: '120301050103', regionId: '03', label: '120301050103 (Trinity)' },
  { value: '120301050202', regionId: '03', label: '120301050202 (Trinity)' },
  { value: '120301050203', regionId: '03', label: '120301050203 (Trinity)' },
  { value: '120301050104', regionId: '03', label: '120301050104 (Trinity)' },
  { value: '120301050105', regionId: '03', label: '120301050105 (Trinity)' },
  { value: '120301050106', regionId: '03', label: '120301050106 (Trinity)' },
  { value: '120301050107', regionId: '03', label: '120301050107 (Trinity)' },
  { value: '120301050108', regionId: '03', label: '120301050108 (Trinity)' },
  { value: '120301050201', regionId: '03', label: '120301050201 (Trinity)' },
  { value: '120301050204', regionId: '03', label: '120301050204 (Trinity)' },
  { value: '120301050205', regionId: '03', label: '120301050205 (Trinity)' },
  { value: '120301050301', regionId: '03', label: '120301050301 (Trinity)' },
  { value: '120301050302', regionId: '03', label: '120301050302 (Trinity)' },
  { value: '120301050303', regionId: '03', label: '120301050303 (Trinity)' },
  { value: '120301050304', regionId: '03', label: '120301050304 (Trinity)' },
  { value: '120301050305', regionId: '03', label: '120301050305 (Trinity)' },
  { value: '120301050408', regionId: '03', label: '120301050408 (Trinity)' },
  { value: '120301050409', regionId: '03', label: '120301050409 (Trinity)' },
  { value: '120301050410', regionId: '03', label: '120301050410 (Trinity)' },
  { value: '120301050501', regionId: '03', label: '120301050501 (Trinity)' },
  { value: '120301050502', regionId: '03', label: '120301050502 (Trinity)' },
  { value: '120301050503', regionId: '03', label: '120301050503 (Trinity)' },
  { value: '120301050504', regionId: '03', label: '120301050504 (Trinity)' },
  { value: '120301050505', regionId: '03', label: '120301050505 (Trinity)' },
  { value: '120301050506', regionId: '03', label: '120301050506 (Trinity)' },
  { value: '120301050306', regionId: '03', label: '120301050306 (Trinity)' },
  { value: '120301050401', regionId: '03', label: '120301050401 (Trinity)' },
  { value: '120301050402', regionId: '03', label: '120301050402 (Trinity)' },
  { value: '120301050403', regionId: '03', label: '120301050403 (Trinity)' },
  { value: '120301050404', regionId: '03', label: '120301050404 (Trinity)' },
  { value: '120301050405', regionId: '03', label: '120301050405 (Trinity)' },
  { value: '120301050406', regionId: '03', label: '120301050406 (Trinity)' },
  { value: '120301050407', regionId: '03', label: '120301050407 (Trinity)' },
  { value: '120301060305', regionId: '03', label: '120301060305 (Trinity)' },
  { value: '120301060402', regionId: '03', label: '120301060402 (Trinity)' },
  { value: '120301060403', regionId: '03', label: '120301060403 (Trinity)' },
  { value: '120301060407', regionId: '03', label: '120301060407 (Trinity)' },
  { value: '120301060101', regionId: '03', label: '120301060101 (Trinity)' },
  { value: '120301060102', regionId: '03', label: '120301060102 (Trinity)' },
  { value: '120301060103', regionId: '03', label: '120301060103 (Trinity)' },
  { value: '120301060105', regionId: '03', label: '120301060105 (Trinity)' },
  { value: '120301060201', regionId: '03', label: '120301060201 (Trinity)' },
  { value: '120301060202', regionId: '03', label: '120301060202 (Trinity)' },
  { value: '120301060104', regionId: '03', label: '120301060104 (Trinity)' },
  { value: '120301060203', regionId: '03', label: '120301060203 (Trinity)' },
  { value: '120301060204', regionId: '03', label: '120301060204 (Trinity)' },
  { value: '120301060205', regionId: '03', label: '120301060205 (Trinity)' },
  { value: '120301060206', regionId: '03', label: '120301060206 (Trinity)' },
  { value: '120301060207', regionId: '03', label: '120301060207 (Trinity)' },
  { value: '120301060208', regionId: '03', label: '120301060208 (Trinity)' },
  { value: '120301060301', regionId: '03', label: '120301060301 (Trinity)' },
  { value: '120301060302', regionId: '03', label: '120301060302 (Trinity)' },
  { value: '120301060303', regionId: '03', label: '120301060303 (Trinity)' },
  { value: '120301060304', regionId: '03', label: '120301060304 (Trinity)' },
  { value: '120301060306', regionId: '03', label: '120301060306 (Trinity)' },
  { value: '120301060307', regionId: '03', label: '120301060307 (Trinity)' },
  { value: '120301060401', regionId: '03', label: '120301060401 (Trinity)' },
  { value: '120301060404', regionId: '03', label: '120301060404 (Trinity)' },
  { value: '120301060405', regionId: '03', label: '120301060405 (Trinity)' },
  { value: '120301060406', regionId: '03', label: '120301060406 (Trinity)' },
  { value: '120301060408', regionId: '03', label: '120301060408 (Trinity)' },
  { value: '120301060409', regionId: '03', label: '120301060409 (Trinity)' },
  { value: '120301060501', regionId: '03', label: '120301060501 (Trinity)' },
  { value: '120301060502', regionId: '03', label: '120301060502 (Trinity)' },
  { value: '120301060503', regionId: '03', label: '120301060503 (Trinity)' },
  { value: '120301060504', regionId: '03', label: '120301060504 (Trinity)' },
  { value: '120301060505', regionId: '03', label: '120301060505 (Trinity)' },
  { value: '120301060506', regionId: '03', label: '120301060506 (Trinity)' },
  { value: '120301060507', regionId: '03', label: '120301060507 (Trinity)' },
  { value: '120301070302', regionId: '03', label: '120301070302 (Trinity)' },
  { value: '120301070101', regionId: '03', label: '120301070101 (Trinity)' },
  { value: '120301070102', regionId: '03', label: '120301070102 (Trinity)' },
  { value: '120301070103', regionId: '03', label: '120301070103 (Trinity)' },
  { value: '120301070104', regionId: '03', label: '120301070104 (Trinity)' },
  { value: '120301070105', regionId: '03', label: '120301070105 (Trinity)' },
  { value: '120301070106', regionId: '03', label: '120301070106 (Trinity)' },
  { value: '120301070107', regionId: '03', label: '120301070107 (Trinity)' },
  { value: '120301070108', regionId: '03', label: '120301070108 (Trinity)' },
  { value: '120301070109', regionId: '03', label: '120301070109 (Trinity)' },
  { value: '120301070110', regionId: '03', label: '120301070110 (Trinity)' },
  { value: '120301070111', regionId: '03', label: '120301070111 (Trinity)' },
  { value: '120301070201', regionId: '03', label: '120301070201 (Trinity)' },
  { value: '120301070202', regionId: '03', label: '120301070202 (Trinity)' },
  { value: '120301070203', regionId: '03', label: '120301070203 (Trinity)' },
  { value: '120301070204', regionId: '03', label: '120301070204 (Trinity)' },
  { value: '120301070205', regionId: '03', label: '120301070205 (Trinity)' },
  { value: '120301070206', regionId: '03', label: '120301070206 (Trinity)' },
  { value: '120301070301', regionId: '03', label: '120301070301 (Trinity)' },
  { value: '120301070303', regionId: '03', label: '120301070303 (Trinity)' },
  { value: '120301070304', regionId: '03', label: '120301070304 (Trinity)' },
  { value: '120301070305', regionId: '03', label: '120301070305 (Trinity)' },
  { value: '120301070306', regionId: '03', label: '120301070306 (Trinity)' },
  { value: '120301070307', regionId: '03', label: '120301070307 (Trinity)' },
  { value: '120301070308', regionId: '03', label: '120301070308 (Trinity)' },
  { value: '120301070309', regionId: '03', label: '120301070309 (Trinity)' },
  { value: '120301070310', regionId: '03', label: '120301070310 (Trinity)' },
  { value: '120301070311', regionId: '03', label: '120301070311 (Trinity)' },
  { value: '120301070312', regionId: '03', label: '120301070312 (Trinity)' },
  { value: '120301080101', regionId: '03', label: '120301080101 (Trinity)' },
  { value: '120301080102', regionId: '03', label: '120301080102 (Trinity)' },
  { value: '120301080103', regionId: '03', label: '120301080103 (Trinity)' },
  { value: '120301080104', regionId: '03', label: '120301080104 (Trinity)' },
  { value: '120301080105', regionId: '03', label: '120301080105 (Trinity)' },
  { value: '120301080106', regionId: '03', label: '120301080106 (Trinity)' },
  { value: '120301080107', regionId: '03', label: '120301080107 (Trinity)' },
  { value: '120301080108', regionId: '03', label: '120301080108 (Trinity)' },
  { value: '120301080109', regionId: '03', label: '120301080109 (Trinity)' },
  { value: '120301080110', regionId: '03', label: '120301080110 (Trinity)' },
  { value: '120301080201', regionId: '03', label: '120301080201 (Trinity)' },
  { value: '120301080202', regionId: '03', label: '120301080202 (Trinity)' },
  { value: '120301080203', regionId: '03', label: '120301080203 (Trinity)' },
  { value: '120301080204', regionId: '03', label: '120301080204 (Trinity)' },
  { value: '120301080205', regionId: '03', label: '120301080205 (Trinity)' },
  { value: '120301080206', regionId: '03', label: '120301080206 (Trinity)' },
  { value: '120301080301', regionId: '03', label: '120301080301 (Trinity)' },
  { value: '120301080302', regionId: '03', label: '120301080302 (Trinity)' },
  { value: '120301080303', regionId: '03', label: '120301080303 (Trinity)' },
  { value: '120301080304', regionId: '03', label: '120301080304 (Trinity)' },
  { value: '120301080305', regionId: '03', label: '120301080305 (Trinity)' },
  { value: '120301080306', regionId: '03', label: '120301080306 (Trinity)' },
  { value: '120301080401', regionId: '03', label: '120301080401 (Trinity)' },
  { value: '120301080402', regionId: '03', label: '120301080402 (Trinity)' },
  { value: '120301080403', regionId: '03', label: '120301080403 (Trinity)' },
  { value: '120301080404', regionId: '03', label: '120301080404 (Trinity)' },
  { value: '120301080405', regionId: '03', label: '120301080405 (Trinity)' },
  { value: '120301080406', regionId: '03', label: '120301080406 (Trinity)' },
  { value: '120301080407', regionId: '03', label: '120301080407 (Trinity)' },
  { value: '120301090301', regionId: '03', label: '120301090301 (Trinity)' },
  { value: '120301090302', regionId: '03', label: '120301090302 (Trinity)' },
  { value: '120301090303', regionId: '03', label: '120301090303 (Trinity)' },
  { value: '120301090403', regionId: '03', label: '120301090403 (Trinity)' },
  { value: '120301090404', regionId: '03', label: '120301090404 (Trinity)' },
  { value: '120301090405', regionId: '03', label: '120301090405 (Trinity)' },
  { value: '120301090406', regionId: '03', label: '120301090406 (Trinity)' },
  { value: '120301090407', regionId: '03', label: '120301090407 (Trinity)' },
  { value: '120301090108', regionId: '03', label: '120301090108 (Trinity)' },
  { value: '120301090201', regionId: '03', label: '120301090201 (Trinity)' },
  { value: '120301090202', regionId: '03', label: '120301090202 (Trinity)' },
  { value: '120301090203', regionId: '03', label: '120301090203 (Trinity)' },
  { value: '120301090204', regionId: '03', label: '120301090204 (Trinity)' },
  { value: '120301090205', regionId: '03', label: '120301090205 (Trinity)' },
  { value: '120301090206', regionId: '03', label: '120301090206 (Trinity)' },
  { value: '120301090207', regionId: '03', label: '120301090207 (Trinity)' },
  { value: '120301090101', regionId: '03', label: '120301090101 (Trinity)' },
  { value: '120301090102', regionId: '03', label: '120301090102 (Trinity)' },
  { value: '120301090103', regionId: '03', label: '120301090103 (Trinity)' },
  { value: '120301090104', regionId: '03', label: '120301090104 (Trinity)' },
  { value: '120301090105', regionId: '03', label: '120301090105 (Trinity)' },
  { value: '120301090106', regionId: '03', label: '120301090106 (Trinity)' },
  { value: '120301090107', regionId: '03', label: '120301090107 (Trinity)' },
  { value: '120301090304', regionId: '03', label: '120301090304 (Trinity)' },
  { value: '120301090305', regionId: '03', label: '120301090305 (Trinity)' },
  { value: '120301090306', regionId: '03', label: '120301090306 (Trinity)' },
  { value: '120301090307', regionId: '03', label: '120301090307 (Trinity)' },
  { value: '120301090308', regionId: '03', label: '120301090308 (Trinity)' },
  { value: '120301090401', regionId: '03', label: '120301090401 (Trinity)' },
  { value: '120301090402', regionId: '03', label: '120301090402 (Trinity)' },
  { value: '120302010704', regionId: '03', label: '120302010704 (Trinity)' },
  { value: '120302010705', regionId: '03', label: '120302010705 (Trinity)' },
  { value: '120302010706', regionId: '03', label: '120302010706 (Trinity)' },
  { value: '120302010707', regionId: '03', label: '120302010707 (Trinity)' },
  { value: '120302010101', regionId: '03', label: '120302010101 (Trinity)' },
  { value: '120302010102', regionId: '03', label: '120302010102 (Trinity)' },
  { value: '120302010103', regionId: '03', label: '120302010103 (Trinity)' },
  { value: '120302010104', regionId: '03', label: '120302010104 (Trinity)' },
  { value: '120302010105', regionId: '03', label: '120302010105 (Trinity)' },
  { value: '120302010106', regionId: '03', label: '120302010106 (Trinity)' },
  { value: '120302010107', regionId: '03', label: '120302010107 (Trinity)' },
  { value: '120302010108', regionId: '03', label: '120302010108 (Trinity)' },
  { value: '120302010109', regionId: '03', label: '120302010109 (Trinity)' },
  { value: '120302010110', regionId: '03', label: '120302010110 (Trinity)' },
  { value: '120302010201', regionId: '03', label: '120302010201 (Trinity)' },
  { value: '120302010202', regionId: '03', label: '120302010202 (Trinity)' },
  { value: '120302010203', regionId: '03', label: '120302010203 (Trinity)' },
  { value: '120302010204', regionId: '03', label: '120302010204 (Trinity)' },
  { value: '120302010205', regionId: '03', label: '120302010205 (Trinity)' },
  { value: '120302010206', regionId: '03', label: '120302010206 (Trinity)' },
  { value: '120302010207', regionId: '03', label: '120302010207 (Trinity)' },
  { value: '120302010208', regionId: '03', label: '120302010208 (Trinity)' },
  { value: '120302010301', regionId: '03', label: '120302010301 (Trinity)' },
  { value: '120302010302', regionId: '03', label: '120302010302 (Trinity)' },
  { value: '120302010303', regionId: '03', label: '120302010303 (Trinity)' },
  { value: '120302010304', regionId: '03', label: '120302010304 (Trinity)' },
  { value: '120302010305', regionId: '03', label: '120302010305 (Trinity)' },
  { value: '120302010401', regionId: '03', label: '120302010401 (Trinity)' },
  { value: '120302010402', regionId: '03', label: '120302010402 (Trinity)' },
  { value: '120302010403', regionId: '03', label: '120302010403 (Trinity)' },
  { value: '120302010404', regionId: '03', label: '120302010404 (Trinity)' },
  { value: '120302010405', regionId: '03', label: '120302010405 (Trinity)' },
  { value: '120302010406', regionId: '03', label: '120302010406 (Trinity)' },
  { value: '120302010501', regionId: '03', label: '120302010501 (Trinity)' },
  { value: '120302010502', regionId: '03', label: '120302010502 (Trinity)' },
  { value: '120302010503', regionId: '03', label: '120302010503 (Trinity)' },
  { value: '120302010504', regionId: '03', label: '120302010504 (Trinity)' },
  { value: '120302010505', regionId: '03', label: '120302010505 (Trinity)' },
  { value: '120302010506', regionId: '03', label: '120302010506 (Trinity)' },
  { value: '120302010601', regionId: '03', label: '120302010601 (Trinity)' },
  { value: '120302010602', regionId: '03', label: '120302010602 (Trinity)' },
  { value: '120302010603', regionId: '03', label: '120302010603 (Trinity)' },
  { value: '120302010604', regionId: '03', label: '120302010604 (Trinity)' },
  { value: '120302010605', regionId: '03', label: '120302010605 (Trinity)' },
  { value: '120302010701', regionId: '03', label: '120302010701 (Trinity)' },
  { value: '120302010702', regionId: '03', label: '120302010702 (Trinity)' },
  { value: '120302010703', regionId: '03', label: '120302010703 (Trinity)' },
  { value: '120302020407', regionId: '03', label: '120302020407 (Trinity)' },
  { value: '120302020101', regionId: '03', label: '120302020101 (Trinity)' },
  { value: '120302020102', regionId: '03', label: '120302020102 (Trinity)' },
  { value: '120302020103', regionId: '03', label: '120302020103 (Trinity)' },
  { value: '120302020104', regionId: '03', label: '120302020104 (Trinity)' },
  { value: '120302020201', regionId: '03', label: '120302020201 (Trinity)' },
  { value: '120302020202', regionId: '03', label: '120302020202 (Trinity)' },
  { value: '120302020203', regionId: '03', label: '120302020203 (Trinity)' },
  { value: '120302020301', regionId: '03', label: '120302020301 (Trinity)' },
  { value: '120302020302', regionId: '03', label: '120302020302 (Trinity)' },
  { value: '120302020303', regionId: '03', label: '120302020303 (Trinity)' },
  { value: '120302020304', regionId: '03', label: '120302020304 (Trinity)' },
  { value: '120302020305', regionId: '03', label: '120302020305 (Trinity)' },
  { value: '120302020306', regionId: '03', label: '120302020306 (Trinity)' },
  { value: '120302020307', regionId: '03', label: '120302020307 (Trinity)' },
  { value: '120302020401', regionId: '03', label: '120302020401 (Trinity)' },
  { value: '120302020308', regionId: '03', label: '120302020308 (Trinity)' },
  { value: '120302020402', regionId: '03', label: '120302020402 (Trinity)' },
  { value: '120302020403', regionId: '03', label: '120302020403 (Trinity)' },
  { value: '120302020404', regionId: '03', label: '120302020404 (Trinity)' },
  { value: '120302020405', regionId: '03', label: '120302020405 (Trinity)' },
  { value: '120302020406', regionId: '03', label: '120302020406 (Trinity)' },
  { value: '120302020408', regionId: '03', label: '120302020408 (Trinity)' },
  { value: '120302020409', regionId: '03', label: '120302020409 (Trinity)' },
  { value: '120302020501', regionId: '03', label: '120302020501 (Trinity)' },
  { value: '120302020502', regionId: '03', label: '120302020502 (Trinity)' },
  { value: '120302020503', regionId: '03', label: '120302020503 (Trinity)' },
  { value: '120302020504', regionId: '03', label: '120302020504 (Trinity)' },
  { value: '120302020505', regionId: '03', label: '120302020505 (Trinity)' },
  { value: '120302020506', regionId: '03', label: '120302020506 (Trinity)' },
  { value: '120302020507', regionId: '03', label: '120302020507 (Trinity)' },
  { value: '120302020601', regionId: '03', label: '120302020601 (Trinity)' },
  { value: '120302020602', regionId: '03', label: '120302020602 (Trinity)' },
  { value: '120302020603', regionId: '03', label: '120302020603 (Trinity)' },
  { value: '120302020604', regionId: '03', label: '120302020604 (Trinity)' },
  { value: '120302020701', regionId: '03', label: '120302020701 (Trinity)' },
  { value: '120302020702', regionId: '03', label: '120302020702 (Trinity)' },
  { value: '120302020703', regionId: '03', label: '120302020703 (Trinity)' },
  { value: '120302020704', regionId: '03', label: '120302020704 (Trinity)' },
  { value: '120302020705', regionId: '03', label: '120302020705 (Trinity)' },
  { value: '120302020706', regionId: '03', label: '120302020706 (Trinity)' },
  { value: '120302020707', regionId: '03', label: '120302020707 (Trinity)' },
  { value: '120302020708', regionId: '03', label: '120302020708 (Trinity)' },
  { value: '120302020709', regionId: '03', label: '120302020709 (Trinity)' },
  { value: '120302020710', regionId: '03', label: '120302020710 (Trinity)' },
  { value: '120302020711', regionId: '03', label: '120302020711 (Trinity)' },
  { value: '120302020712', regionId: '03', label: '120302020712 (Trinity)' },
  { value: '120302020713', regionId: '03', label: '120302020713 (Trinity)' },
  { value: '120302020801', regionId: '03', label: '120302020801 (Trinity)' },
  { value: '120302020901', regionId: '03', label: '120302020901 (Trinity)' },
  { value: '120302020903', regionId: '03', label: '120302020903 (Trinity)' },
  { value: '120302021001', regionId: '03', label: '120302021001 (Trinity)' },
  { value: '120302021002', regionId: '03', label: '120302021002 (Trinity)' },
  { value: '120302020802', regionId: '03', label: '120302020802 (Trinity)' },
  { value: '120302020803', regionId: '03', label: '120302020803 (Trinity)' },
  { value: '120302020804', regionId: '03', label: '120302020804 (Trinity)' },
  { value: '120302020805', regionId: '03', label: '120302020805 (Trinity)' },
  { value: '120302020806', regionId: '03', label: '120302020806 (Trinity)' },
  { value: '120302020807', regionId: '03', label: '120302020807 (Trinity)' },
  { value: '120302020902', regionId: '03', label: '120302020902 (Trinity)' },
  { value: '120302021003', regionId: '03', label: '120302021003 (Trinity)' },
  { value: '120302021004', regionId: '03', label: '120302021004 (Trinity)' },
  { value: '120302021005', regionId: '03', label: '120302021005 (Trinity)' },
  { value: '120302021006', regionId: '03', label: '120302021006 (Trinity)' },
  { value: '120302021101', regionId: '03', label: '120302021101 (Trinity)' },
  { value: '120302021102', regionId: '03', label: '120302021102 (Trinity)' },
  { value: '120302021103', regionId: '03', label: '120302021103 (Trinity)' },
  { value: '120302021104', regionId: '03', label: '120302021104 (Trinity)' },
  { value: '120302021105', regionId: '03', label: '120302021105 (Trinity)' },
  { value: '120302021106', regionId: '03', label: '120302021106 (Trinity)' },
  { value: '120302021201', regionId: '03', label: '120302021201 (Trinity)' },
  { value: '120302021202', regionId: '03', label: '120302021202 (Trinity)' },
  { value: '120302021203', regionId: '03', label: '120302021203 (Trinity)' },
  { value: '120302021204', regionId: '03', label: '120302021204 (Trinity)' },
  { value: '120302021205', regionId: '03', label: '120302021205 (Trinity)' },
  { value: '120302021206', regionId: '03', label: '120302021206 (Trinity)' },
  { value: '120302021207', regionId: '03', label: '120302021207 (Trinity)' },
  { value: '120302030108', regionId: '03', label: '120302030108 (Trinity)' },
  { value: '120302030101', regionId: '03', label: '120302030101 (Trinity)' },
  { value: '120302030102', regionId: '03', label: '120302030102 (Trinity)' },
  { value: '120302030103', regionId: '03', label: '120302030103 (Trinity)' },
  { value: '120302030104', regionId: '03', label: '120302030104 (Trinity)' },
  { value: '120302030105', regionId: '03', label: '120302030105 (Trinity)' },
  { value: '120302030106', regionId: '03', label: '120302030106 (Trinity)' },
  { value: '120302030107', regionId: '03', label: '120302030107 (Trinity)' },
  { value: '120302030109', regionId: '03', label: '120302030109 (Trinity)' },
  { value: '120302030201', regionId: '03', label: '120302030201 (Trinity)' },
  { value: '120302030202', regionId: '03', label: '120302030202 (Trinity)' },
  { value: '120302030203', regionId: '03', label: '120302030203 (Trinity)' },
  { value: '120302030204', regionId: '03', label: '120302030204 (Trinity)' },
  { value: '120302030301', regionId: '03', label: '120302030301 (Trinity)' },
  { value: '120302030302', regionId: '03', label: '120302030302 (Trinity)' },
  { value: '120302030303', regionId: '03', label: '120302030303 (Trinity)' },
  { value: '120302030304', regionId: '03', label: '120302030304 (Trinity)' },
  { value: '120302030305', regionId: '03', label: '120302030305 (Trinity)' },
  { value: '120302030306', regionId: '03', label: '120302030306 (Trinity)' },
  { value: '120302030307', regionId: '03', label: '120302030307 (Trinity)' },
  { value: '120401010101', regionId: '03', label: '120401010101 (Trinity)' },
  { value: '120401010102', regionId: '03', label: '120401010102 (Trinity)' },
  { value: '120401010104', regionId: '03', label: '120401010104 (Trinity)' },
  { value: '120401010202', regionId: '03', label: '120401010202 (Trinity)' },
  { value: '120401010301', regionId: '03', label: '120401010301 (Trinity)' },
  { value: '120401030203', regionId: '03', label: '120401030203 (Trinity)' },
  { value: '120401030201', regionId: '03', label: '120401030201 (Trinity)' },
  { value: '120401030204', regionId: '03', label: '120401030204 (Trinity)' },
  { value: '120401030202', regionId: '03', label: '120401030202 (Trinity)' },
  { value: '120401030302', regionId: '03', label: '120401030302 (Trinity)' },
  { value: '120401030305', regionId: '03', label: '120401030305 (Trinity)' },
  { value: '120401030306', regionId: '03', label: '120401030306 (Trinity)' },
  { value: '120401030307', regionId: '03', label: '120401030307 (Trinity)' },
  { value: '120402020100', regionId: '03', label: '120402020100 (Trinity)' },
  { value: '120402020200', regionId: '03', label: '120402020200 (Trinity)' },
  { value: '120402030101', regionId: '03', label: '120402030101 (Trinity)' },
  { value: '120402030104', regionId: '03', label: '120402030104 (Trinity)' },
  { value: '120402030105', regionId: '03', label: '120402030105 (Trinity)' },
  { value: '120402030102', regionId: '03', label: '120402030102 (Trinity)' },
  { value: '120402030103', regionId: '03', label: '120402030103 (Trinity)' },
  { value: '120402030106', regionId: '03', label: '120402030106 (Trinity)' },
  { value: '120402030200', regionId: '03', label: '120402030200 (Trinity)' },
  { value: '120602011101', regionId: '03', label: '120602011101 (Trinity)' },
  { value: '120602011102', regionId: '03', label: '120602011102 (Trinity)' },
  { value: '120602010101', regionId: '03', label: '120602010101 (Trinity)' },
  { value: '120602010102', regionId: '03', label: '120602010102 (Trinity)' },
  { value: '120602010104', regionId: '03', label: '120602010104 (Trinity)' },
  { value: '120602010105', regionId: '03', label: '120602010105 (Trinity)' },
  { value: '120602010201', regionId: '03', label: '120602010201 (Trinity)' },
  { value: '120602010601', regionId: '03', label: '120602010601 (Trinity)' },
  { value: '120602010603', regionId: '03', label: '120602010603 (Trinity)' },
  { value: '120602010605', regionId: '03', label: '120602010605 (Trinity)' },
  { value: '120602010606', regionId: '03', label: '120602010606 (Trinity)' },
  { value: '120602011105', regionId: '03', label: '120602011105 (Trinity)' },
  { value: '120602011108', regionId: '03', label: '120602011108 (Trinity)' },
  { value: '120602011201', regionId: '03', label: '120602011201 (Trinity)' },
  { value: '120602011202', regionId: '03', label: '120602011202 (Trinity)' },
  { value: '120602011203', regionId: '03', label: '120602011203 (Trinity)' },
  { value: '120602011206', regionId: '03', label: '120602011206 (Trinity)' },
  { value: '120602011301', regionId: '03', label: '120602011301 (Trinity)' },
  { value: '120602020701', regionId: '03', label: '120602020701 (Trinity)' },
  { value: '120602020703', regionId: '03', label: '120602020703 (Trinity)' },
  { value: '120602020201', regionId: '03', label: '120602020201 (Trinity)' },
  { value: '120602020202', regionId: '03', label: '120602020202 (Trinity)' },
  { value: '120602020204', regionId: '03', label: '120602020204 (Trinity)' },
  { value: '120602020501', regionId: '03', label: '120602020501 (Trinity)' },
  { value: '120602020502', regionId: '03', label: '120602020502 (Trinity)' },
  { value: '120602020503', regionId: '03', label: '120602020503 (Trinity)' },
  { value: '120602020506', regionId: '03', label: '120602020506 (Trinity)' },
  { value: '120701030306', regionId: '03', label: '120701030306 (Trinity)' },
  { value: '120701030101', regionId: '03', label: '120701030101 (Trinity)' },
  { value: '120701030103', regionId: '03', label: '120701030103 (Trinity)' },
  { value: '120701030104', regionId: '03', label: '120701030104 (Trinity)' },
  { value: '120701030105', regionId: '03', label: '120701030105 (Trinity)' },
  { value: '120701030301', regionId: '03', label: '120701030301 (Trinity)' },
  { value: '120701030303', regionId: '03', label: '120701030303 (Trinity)' },
  { value: '120701030401', regionId: '03', label: '120701030401 (Trinity)' },
  { value: '120701030501', regionId: '03', label: '120701030501 (Trinity)' },
  { value: '120701030504', regionId: '03', label: '120701030504 (Trinity)' },
  { value: '120701030509', regionId: '03', label: '120701030509 (Trinity)' },
  { value: '120701030510', regionId: '03', label: '120701030510 (Trinity)' },
  { value: '120701030601', regionId: '03', label: '120701030601 (Trinity)' },
  { value: '120701030701', regionId: '03', label: '120701030701 (Trinity)' },
  { value: '120701030604', regionId: '03', label: '120701030604 (Trinity)' },
  { value: '120701030705', regionId: '03', label: '120701030705 (Trinity)' },
  { value: '111302100202', regionId: '03', label: '111302100202 (Trinity)' },
  { value: '111302100201', regionId: '03', label: '111302100201 (Trinity)' },
  { value: '111302100401', regionId: '03', label: '111302100401 (Trinity)' },
  { value: '111302100403', regionId: '03', label: '111302100403 (Trinity)' },
  { value: '111302100404', regionId: '03', label: '111302100404 (Trinity)' },
  { value: '111401010101', regionId: '03', label: '111401010101 (Trinity)' },
  { value: '111401010103', regionId: '03', label: '111401010103 (Trinity)' },
  { value: '111401010105', regionId: '03', label: '111401010105 (Trinity)' },
  { value: '111401010501', regionId: '03', label: '111401010501 (Trinity)' },
]

const hucsRegion07 = [
  { value: '111201040104', regionId: '07', label: '111201040104 (Upper Brazos)' },
  { value: '111201040105', regionId: '07', label: '111201040105 (Upper Brazos)' },
  { value: '111201040202', regionId: '07', label: '111201040202 (Upper Brazos)' },
  { value: '111201040102', regionId: '07', label: '111201040102 (Upper Brazos)' },
  { value: '111201040101', regionId: '07', label: '111201040101 (Upper Brazos)' },
  { value: '111201040602', regionId: '07', label: '111201040602 (Upper Brazos)' },
  { value: '111201040402', regionId: '07', label: '111201040402 (Upper Brazos)' },
  { value: '111201040403', regionId: '07', label: '111201040403 (Upper Brazos)' },
  { value: '111201040601', regionId: '07', label: '111201040601 (Upper Brazos)' },
  { value: '111301030202', regionId: '07', label: '111301030202 (Upper Brazos)' },
  { value: '111301030203', regionId: '07', label: '111301030203 (Upper Brazos)' },
  { value: '111301040201', regionId: '07', label: '111301040201 (Upper Brazos)' },
  { value: '111301040103', regionId: '07', label: '111301040103 (Upper Brazos)' },
  { value: '111301040104', regionId: '07', label: '111301040104 (Upper Brazos)' },
  { value: '111301040101', regionId: '07', label: '111301040101 (Upper Brazos)' },
  { value: '111301040202', regionId: '07', label: '111301040202 (Upper Brazos)' },
  { value: '111301040203', regionId: '07', label: '111301040203 (Upper Brazos)' },
  { value: '111301040207', regionId: '07', label: '111301040207 (Upper Brazos)' },
  { value: '111301040208', regionId: '07', label: '111301040208 (Upper Brazos)' },
  { value: '111302040101', regionId: '07', label: '111302040101 (Upper Brazos)' },
  { value: '111302050101', regionId: '07', label: '111302050101 (Upper Brazos)' },
  { value: '111302050103', regionId: '07', label: '111302050103 (Upper Brazos)' },
  { value: '111302050104', regionId: '07', label: '111302050104 (Upper Brazos)' },
  { value: '111302050207', regionId: '07', label: '111302050207 (Upper Brazos)' },
  { value: '111302050208', regionId: '07', label: '111302050208 (Upper Brazos)' },
  { value: '111302050107', regionId: '07', label: '111302050107 (Upper Brazos)' },
  { value: '111302050201', regionId: '07', label: '111302050201 (Upper Brazos)' },
  { value: '111302050203', regionId: '07', label: '111302050203 (Upper Brazos)' },
  { value: '111302050204', regionId: '07', label: '111302050204 (Upper Brazos)' },
  { value: '111302050205', regionId: '07', label: '111302050205 (Upper Brazos)' },
  { value: '111302050206', regionId: '07', label: '111302050206 (Upper Brazos)' },
  { value: '111302060103', regionId: '07', label: '111302060103 (Upper Brazos)' },
  { value: '111302060104', regionId: '07', label: '111302060104 (Upper Brazos)' },
  { value: '111302060106', regionId: '07', label: '111302060106 (Upper Brazos)' },
  { value: '111302090101', regionId: '07', label: '111302090101 (Upper Brazos)' },
  { value: '111302090105', regionId: '07', label: '111302090105 (Upper Brazos)' },
  { value: '111302090102', regionId: '07', label: '111302090102 (Upper Brazos)' },
  { value: '111302090104', regionId: '07', label: '111302090104 (Upper Brazos)' },
  { value: '111302090201', regionId: '07', label: '111302090201 (Upper Brazos)' },
  { value: '120500030102', regionId: '07', label: '120500030102 (Upper Brazos)' },
  { value: '120500030202', regionId: '07', label: '120500030202 (Upper Brazos)' },
  { value: '120500030301', regionId: '07', label: '120500030301 (Upper Brazos)' },
  { value: '120500030402', regionId: '07', label: '120500030402 (Upper Brazos)' },
  { value: '120500030101', regionId: '07', label: '120500030101 (Upper Brazos)' },
  { value: '120500030103', regionId: '07', label: '120500030103 (Upper Brazos)' },
  { value: '120500030104', regionId: '07', label: '120500030104 (Upper Brazos)' },
  { value: '120500030201', regionId: '07', label: '120500030201 (Upper Brazos)' },
  { value: '120500030203', regionId: '07', label: '120500030203 (Upper Brazos)' },
  { value: '120500030302', regionId: '07', label: '120500030302 (Upper Brazos)' },
  { value: '120500030306', regionId: '07', label: '120500030306 (Upper Brazos)' },
  { value: '120500030303', regionId: '07', label: '120500030303 (Upper Brazos)' },
  { value: '120500030304', regionId: '07', label: '120500030304 (Upper Brazos)' },
  { value: '120500030305', regionId: '07', label: '120500030305 (Upper Brazos)' },
  { value: '120500030307', regionId: '07', label: '120500030307 (Upper Brazos)' },
  { value: '120500030308', regionId: '07', label: '120500030308 (Upper Brazos)' },
  { value: '120500030401', regionId: '07', label: '120500030401 (Upper Brazos)' },
  { value: '120500030403', regionId: '07', label: '120500030403 (Upper Brazos)' },
  { value: '120500030501', regionId: '07', label: '120500030501 (Upper Brazos)' },
  { value: '120500030502', regionId: '07', label: '120500030502 (Upper Brazos)' },
  { value: '120500030503', regionId: '07', label: '120500030503 (Upper Brazos)' },
  { value: '120500030504', regionId: '07', label: '120500030504 (Upper Brazos)' },
  { value: '120500030505', regionId: '07', label: '120500030505 (Upper Brazos)' },
  { value: '120500030506', regionId: '07', label: '120500030506 (Upper Brazos)' },
  { value: '120500040202', regionId: '07', label: '120500040202 (Upper Brazos)' },
  { value: '120500040203', regionId: '07', label: '120500040203 (Upper Brazos)' },
  { value: '120500040204', regionId: '07', label: '120500040204 (Upper Brazos)' },
  { value: '120500040205', regionId: '07', label: '120500040205 (Upper Brazos)' },
  { value: '120500040206', regionId: '07', label: '120500040206 (Upper Brazos)' },
  { value: '120500040207', regionId: '07', label: '120500040207 (Upper Brazos)' },
  { value: '120500040208', regionId: '07', label: '120500040208 (Upper Brazos)' },
  { value: '120500040101', regionId: '07', label: '120500040101 (Upper Brazos)' },
  { value: '120500040102', regionId: '07', label: '120500040102 (Upper Brazos)' },
  { value: '120500040305', regionId: '07', label: '120500040305 (Upper Brazos)' },
  { value: '120500040306', regionId: '07', label: '120500040306 (Upper Brazos)' },
  { value: '120500040104', regionId: '07', label: '120500040104 (Upper Brazos)' },
  { value: '120500040201', regionId: '07', label: '120500040201 (Upper Brazos)' },
  { value: '120500040103', regionId: '07', label: '120500040103 (Upper Brazos)' },
  { value: '120500040209', regionId: '07', label: '120500040209 (Upper Brazos)' },
  { value: '120500040210', regionId: '07', label: '120500040210 (Upper Brazos)' },
  { value: '120500040301', regionId: '07', label: '120500040301 (Upper Brazos)' },
  { value: '120500040307', regionId: '07', label: '120500040307 (Upper Brazos)' },
  { value: '120500040302', regionId: '07', label: '120500040302 (Upper Brazos)' },
  { value: '120500040402', regionId: '07', label: '120500040402 (Upper Brazos)' },
  { value: '120500040303', regionId: '07', label: '120500040303 (Upper Brazos)' },
  { value: '120500040304', regionId: '07', label: '120500040304 (Upper Brazos)' },
  { value: '120500040403', regionId: '07', label: '120500040403 (Upper Brazos)' },
  { value: '120500040308', regionId: '07', label: '120500040308 (Upper Brazos)' },
  { value: '120500040401', regionId: '07', label: '120500040401 (Upper Brazos)' },
  { value: '120500040404', regionId: '07', label: '120500040404 (Upper Brazos)' },
  { value: '120500040405', regionId: '07', label: '120500040405 (Upper Brazos)' },
  { value: '120500040406', regionId: '07', label: '120500040406 (Upper Brazos)' },
  { value: '120500040501', regionId: '07', label: '120500040501 (Upper Brazos)' },
  { value: '120500040502', regionId: '07', label: '120500040502 (Upper Brazos)' },
  { value: '120500040503', regionId: '07', label: '120500040503 (Upper Brazos)' },
  { value: '120500040504', regionId: '07', label: '120500040504 (Upper Brazos)' },
  { value: '120500040505', regionId: '07', label: '120500040505 (Upper Brazos)' },
  { value: '120500040506', regionId: '07', label: '120500040506 (Upper Brazos)' },
  { value: '120500040507', regionId: '07', label: '120500040507 (Upper Brazos)' },
  { value: '120500040604', regionId: '07', label: '120500040604 (Upper Brazos)' },
  { value: '120500040605', regionId: '07', label: '120500040605 (Upper Brazos)' },
  { value: '120500040606', regionId: '07', label: '120500040606 (Upper Brazos)' },
  { value: '120500040607', regionId: '07', label: '120500040607 (Upper Brazos)' },
  { value: '120500040601', regionId: '07', label: '120500040601 (Upper Brazos)' },
  { value: '120500040602', regionId: '07', label: '120500040602 (Upper Brazos)' },
  { value: '120500040603', regionId: '07', label: '120500040603 (Upper Brazos)' },
  { value: '120500040701', regionId: '07', label: '120500040701 (Upper Brazos)' },
  { value: '120500040702', regionId: '07', label: '120500040702 (Upper Brazos)' },
  { value: '120500040703', regionId: '07', label: '120500040703 (Upper Brazos)' },
  { value: '120500040704', regionId: '07', label: '120500040704 (Upper Brazos)' },
  { value: '120500040801', regionId: '07', label: '120500040801 (Upper Brazos)' },
  { value: '120500040802', regionId: '07', label: '120500040802 (Upper Brazos)' },
  { value: '120500040803', regionId: '07', label: '120500040803 (Upper Brazos)' },
  { value: '120500040804', regionId: '07', label: '120500040804 (Upper Brazos)' },
  { value: '120500040805', regionId: '07', label: '120500040805 (Upper Brazos)' },
  { value: '120500040806', regionId: '07', label: '120500040806 (Upper Brazos)' },
  { value: '120500040807', regionId: '07', label: '120500040807 (Upper Brazos)' },
  { value: '120500040901', regionId: '07', label: '120500040901 (Upper Brazos)' },
  { value: '120500040902', regionId: '07', label: '120500040902 (Upper Brazos)' },
  { value: '120500040904', regionId: '07', label: '120500040904 (Upper Brazos)' },
  { value: '120500040903', regionId: '07', label: '120500040903 (Upper Brazos)' },
  { value: '120500040905', regionId: '07', label: '120500040905 (Upper Brazos)' },
  { value: '120500040906', regionId: '07', label: '120500040906 (Upper Brazos)' },
  { value: '120500040907', regionId: '07', label: '120500040907 (Upper Brazos)' },
  { value: '120500060502', regionId: '07', label: '120500060502 (Upper Brazos)' },
  { value: '120500060102', regionId: '07', label: '120500060102 (Upper Brazos)' },
  { value: '120500060103', regionId: '07', label: '120500060103 (Upper Brazos)' },
  { value: '120500060104', regionId: '07', label: '120500060104 (Upper Brazos)' },
  { value: '120500060105', regionId: '07', label: '120500060105 (Upper Brazos)' },
  { value: '120500060106', regionId: '07', label: '120500060106 (Upper Brazos)' },
  { value: '120500060107', regionId: '07', label: '120500060107 (Upper Brazos)' },
  { value: '120500060108', regionId: '07', label: '120500060108 (Upper Brazos)' },
  { value: '120500060203', regionId: '07', label: '120500060203 (Upper Brazos)' },
  { value: '120500060204', regionId: '07', label: '120500060204 (Upper Brazos)' },
  { value: '120500060205', regionId: '07', label: '120500060205 (Upper Brazos)' },
  { value: '120500060101', regionId: '07', label: '120500060101 (Upper Brazos)' },
  { value: '120500060201', regionId: '07', label: '120500060201 (Upper Brazos)' },
  { value: '120500060206', regionId: '07', label: '120500060206 (Upper Brazos)' },
  { value: '120500060202', regionId: '07', label: '120500060202 (Upper Brazos)' },
  { value: '120500060207', regionId: '07', label: '120500060207 (Upper Brazos)' },
  { value: '120500060303', regionId: '07', label: '120500060303 (Upper Brazos)' },
  { value: '120500060304', regionId: '07', label: '120500060304 (Upper Brazos)' },
  { value: '120500060305', regionId: '07', label: '120500060305 (Upper Brazos)' },
  { value: '120500060301', regionId: '07', label: '120500060301 (Upper Brazos)' },
  { value: '120500060302', regionId: '07', label: '120500060302 (Upper Brazos)' },
  { value: '120500060306', regionId: '07', label: '120500060306 (Upper Brazos)' },
  { value: '120500060405', regionId: '07', label: '120500060405 (Upper Brazos)' },
  { value: '120500060307', regionId: '07', label: '120500060307 (Upper Brazos)' },
  { value: '120500060401', regionId: '07', label: '120500060401 (Upper Brazos)' },
  { value: '120500060308', regionId: '07', label: '120500060308 (Upper Brazos)' },
  { value: '120500060404', regionId: '07', label: '120500060404 (Upper Brazos)' },
  { value: '120500060402', regionId: '07', label: '120500060402 (Upper Brazos)' },
  { value: '120500060403', regionId: '07', label: '120500060403 (Upper Brazos)' },
  { value: '120500060501', regionId: '07', label: '120500060501 (Upper Brazos)' },
  { value: '120500060503', regionId: '07', label: '120500060503 (Upper Brazos)' },
  { value: '120500060504', regionId: '07', label: '120500060504 (Upper Brazos)' },
  { value: '120500060505', regionId: '07', label: '120500060505 (Upper Brazos)' },
  { value: '120500060506', regionId: '07', label: '120500060506 (Upper Brazos)' },
  { value: '120500070409', regionId: '07', label: '120500070409 (Upper Brazos)' },
  { value: '120500070408', regionId: '07', label: '120500070408 (Upper Brazos)' },
  { value: '120500070410', regionId: '07', label: '120500070410 (Upper Brazos)' },
  { value: '120500070501', regionId: '07', label: '120500070501 (Upper Brazos)' },
  { value: '120500070502', regionId: '07', label: '120500070502 (Upper Brazos)' },
  { value: '120500070503', regionId: '07', label: '120500070503 (Upper Brazos)' },
  { value: '120500070504', regionId: '07', label: '120500070504 (Upper Brazos)' },
  { value: '120500070505', regionId: '07', label: '120500070505 (Upper Brazos)' },
  { value: '120500070506', regionId: '07', label: '120500070506 (Upper Brazos)' },
  { value: '120500070507', regionId: '07', label: '120500070507 (Upper Brazos)' },
  { value: '120500070701', regionId: '07', label: '120500070701 (Upper Brazos)' },
  { value: '120500070702', regionId: '07', label: '120500070702 (Upper Brazos)' },
  { value: '120500070703', regionId: '07', label: '120500070703 (Upper Brazos)' },
  { value: '120500070704', regionId: '07', label: '120500070704 (Upper Brazos)' },
  { value: '120500070508', regionId: '07', label: '120500070508 (Upper Brazos)' },
  { value: '120500070601', regionId: '07', label: '120500070601 (Upper Brazos)' },
  { value: '120500070602', regionId: '07', label: '120500070602 (Upper Brazos)' },
  { value: '120500070603', regionId: '07', label: '120500070603 (Upper Brazos)' },
  { value: '120500070604', regionId: '07', label: '120500070604 (Upper Brazos)' },
  { value: '120500070605', regionId: '07', label: '120500070605 (Upper Brazos)' },
  { value: '120500070606', regionId: '07', label: '120500070606 (Upper Brazos)' },
  { value: '120500070607', regionId: '07', label: '120500070607 (Upper Brazos)' },
  { value: '120500070608', regionId: '07', label: '120500070608 (Upper Brazos)' },
  { value: '120500070705', regionId: '07', label: '120500070705 (Upper Brazos)' },
  { value: '120500070706', regionId: '07', label: '120500070706 (Upper Brazos)' },
  { value: '120500070707', regionId: '07', label: '120500070707 (Upper Brazos)' },
  { value: '120500070708', regionId: '07', label: '120500070708 (Upper Brazos)' },
  { value: '120500070101', regionId: '07', label: '120500070101 (Upper Brazos)' },
  { value: '120500070102', regionId: '07', label: '120500070102 (Upper Brazos)' },
  { value: '120500070103', regionId: '07', label: '120500070103 (Upper Brazos)' },
  { value: '120500070104', regionId: '07', label: '120500070104 (Upper Brazos)' },
  { value: '120500070105', regionId: '07', label: '120500070105 (Upper Brazos)' },
  { value: '120500070300', regionId: '07', label: '120500070300 (Upper Brazos)' },
  { value: '120500070106', regionId: '07', label: '120500070106 (Upper Brazos)' },
  { value: '120500070107', regionId: '07', label: '120500070107 (Upper Brazos)' },
  { value: '120500070108', regionId: '07', label: '120500070108 (Upper Brazos)' },
  { value: '120500070109', regionId: '07', label: '120500070109 (Upper Brazos)' },
  { value: '120500070110', regionId: '07', label: '120500070110 (Upper Brazos)' },
  { value: '120500070201', regionId: '07', label: '120500070201 (Upper Brazos)' },
  { value: '120500070202', regionId: '07', label: '120500070202 (Upper Brazos)' },
  { value: '120500070203', regionId: '07', label: '120500070203 (Upper Brazos)' },
  { value: '120500070204', regionId: '07', label: '120500070204 (Upper Brazos)' },
  { value: '120500070205', regionId: '07', label: '120500070205 (Upper Brazos)' },
  { value: '120500070206', regionId: '07', label: '120500070206 (Upper Brazos)' },
  { value: '120500070207', regionId: '07', label: '120500070207 (Upper Brazos)' },
  { value: '120500070208', regionId: '07', label: '120500070208 (Upper Brazos)' },
  { value: '120500070401', regionId: '07', label: '120500070401 (Upper Brazos)' },
  { value: '120500070402', regionId: '07', label: '120500070402 (Upper Brazos)' },
  { value: '120500070403', regionId: '07', label: '120500070403 (Upper Brazos)' },
  { value: '120500070404', regionId: '07', label: '120500070404 (Upper Brazos)' },
  { value: '120500070405', regionId: '07', label: '120500070405 (Upper Brazos)' },
  { value: '120500070406', regionId: '07', label: '120500070406 (Upper Brazos)' },
  { value: '120500070407', regionId: '07', label: '120500070407 (Upper Brazos)' },
  { value: '120601010302', regionId: '07', label: '120601010302 (Upper Brazos)' },
  { value: '120601010101', regionId: '07', label: '120601010101 (Upper Brazos)' },
  { value: '120601010102', regionId: '07', label: '120601010102 (Upper Brazos)' },
  { value: '120601010103', regionId: '07', label: '120601010103 (Upper Brazos)' },
  { value: '120601010104', regionId: '07', label: '120601010104 (Upper Brazos)' },
  { value: '120601010105', regionId: '07', label: '120601010105 (Upper Brazos)' },
  { value: '120601010106', regionId: '07', label: '120601010106 (Upper Brazos)' },
  { value: '120601010201', regionId: '07', label: '120601010201 (Upper Brazos)' },
  { value: '120601010202', regionId: '07', label: '120601010202 (Upper Brazos)' },
  { value: '120601010203', regionId: '07', label: '120601010203 (Upper Brazos)' },
  { value: '120601010204', regionId: '07', label: '120601010204 (Upper Brazos)' },
  { value: '120601010205', regionId: '07', label: '120601010205 (Upper Brazos)' },
  { value: '120601010206', regionId: '07', label: '120601010206 (Upper Brazos)' },
  { value: '120601010301', regionId: '07', label: '120601010301 (Upper Brazos)' },
  { value: '120601010303', regionId: '07', label: '120601010303 (Upper Brazos)' },
  { value: '120601010304', regionId: '07', label: '120601010304 (Upper Brazos)' },
  { value: '120601010401', regionId: '07', label: '120601010401 (Upper Brazos)' },
  { value: '120601010402', regionId: '07', label: '120601010402 (Upper Brazos)' },
  { value: '120601010403', regionId: '07', label: '120601010403 (Upper Brazos)' },
  { value: '120601010404', regionId: '07', label: '120601010404 (Upper Brazos)' },
  { value: '120601010405', regionId: '07', label: '120601010405 (Upper Brazos)' },
  { value: '120601010406', regionId: '07', label: '120601010406 (Upper Brazos)' },
  { value: '120601010407', regionId: '07', label: '120601010407 (Upper Brazos)' },
  { value: '120601010408', regionId: '07', label: '120601010408 (Upper Brazos)' },
  { value: '120601010501', regionId: '07', label: '120601010501 (Upper Brazos)' },
  { value: '120601010502', regionId: '07', label: '120601010502 (Upper Brazos)' },
  { value: '120601010503', regionId: '07', label: '120601010503 (Upper Brazos)' },
  { value: '120601010504', regionId: '07', label: '120601010504 (Upper Brazos)' },
  { value: '120601010505', regionId: '07', label: '120601010505 (Upper Brazos)' },
  { value: '120601010506', regionId: '07', label: '120601010506 (Upper Brazos)' },
  { value: '120601010507', regionId: '07', label: '120601010507 (Upper Brazos)' },
  { value: '120601010601', regionId: '07', label: '120601010601 (Upper Brazos)' },
  { value: '120601010602', regionId: '07', label: '120601010602 (Upper Brazos)' },
  { value: '120601010603', regionId: '07', label: '120601010603 (Upper Brazos)' },
  { value: '120601010701', regionId: '07', label: '120601010701 (Upper Brazos)' },
  { value: '120601010702', regionId: '07', label: '120601010702 (Upper Brazos)' },
  { value: '120601010703', regionId: '07', label: '120601010703 (Upper Brazos)' },
  { value: '120601010704', regionId: '07', label: '120601010704 (Upper Brazos)' },
  { value: '120601010705', regionId: '07', label: '120601010705 (Upper Brazos)' },
  { value: '120601010706', regionId: '07', label: '120601010706 (Upper Brazos)' },
  { value: '120601010707', regionId: '07', label: '120601010707 (Upper Brazos)' },
  { value: '120601010801', regionId: '07', label: '120601010801 (Upper Brazos)' },
  { value: '120601010802', regionId: '07', label: '120601010802 (Upper Brazos)' },
  { value: '120601010803', regionId: '07', label: '120601010803 (Upper Brazos)' },
  { value: '120601010804', regionId: '07', label: '120601010804 (Upper Brazos)' },
  { value: '120601010805', regionId: '07', label: '120601010805 (Upper Brazos)' },
  { value: '120601010806', regionId: '07', label: '120601010806 (Upper Brazos)' },
  { value: '120601010807', regionId: '07', label: '120601010807 (Upper Brazos)' },
  { value: '120601010808', regionId: '07', label: '120601010808 (Upper Brazos)' },
  { value: '120601010809', regionId: '07', label: '120601010809 (Upper Brazos)' },
  { value: '120601010810', regionId: '07', label: '120601010810 (Upper Brazos)' },
  { value: '120601010811', regionId: '07', label: '120601010811 (Upper Brazos)' },
  { value: '120601010901', regionId: '07', label: '120601010901 (Upper Brazos)' },
  { value: '120601010902', regionId: '07', label: '120601010902 (Upper Brazos)' },
  { value: '120601010903', regionId: '07', label: '120601010903 (Upper Brazos)' },
  { value: '120601010904', regionId: '07', label: '120601010904 (Upper Brazos)' },
  { value: '120601010905', regionId: '07', label: '120601010905 (Upper Brazos)' },
  { value: '120601010906', regionId: '07', label: '120601010906 (Upper Brazos)' },
  { value: '120601020705', regionId: '07', label: '120601020705 (Upper Brazos)' },
  { value: '120601020706', regionId: '07', label: '120601020706 (Upper Brazos)' },
  { value: '120601020102', regionId: '07', label: '120601020102 (Upper Brazos)' },
  { value: '120601020103', regionId: '07', label: '120601020103 (Upper Brazos)' },
  { value: '120601020101', regionId: '07', label: '120601020101 (Upper Brazos)' },
  { value: '120601020104', regionId: '07', label: '120601020104 (Upper Brazos)' },
  { value: '120601020105', regionId: '07', label: '120601020105 (Upper Brazos)' },
  { value: '120601020301', regionId: '07', label: '120601020301 (Upper Brazos)' },
  { value: '120601020106', regionId: '07', label: '120601020106 (Upper Brazos)' },
  { value: '120601020201', regionId: '07', label: '120601020201 (Upper Brazos)' },
  { value: '120601020205', regionId: '07', label: '120601020205 (Upper Brazos)' },
  { value: '120601020202', regionId: '07', label: '120601020202 (Upper Brazos)' },
  { value: '120601020203', regionId: '07', label: '120601020203 (Upper Brazos)' },
  { value: '120601020204', regionId: '07', label: '120601020204 (Upper Brazos)' },
  { value: '120601020206', regionId: '07', label: '120601020206 (Upper Brazos)' },
  { value: '120601020207', regionId: '07', label: '120601020207 (Upper Brazos)' },
  { value: '120601020302', regionId: '07', label: '120601020302 (Upper Brazos)' },
  { value: '120601020303', regionId: '07', label: '120601020303 (Upper Brazos)' },
  { value: '120601020304', regionId: '07', label: '120601020304 (Upper Brazos)' },
  { value: '120601020305', regionId: '07', label: '120601020305 (Upper Brazos)' },
  { value: '120601020306', regionId: '07', label: '120601020306 (Upper Brazos)' },
  { value: '120601020307', regionId: '07', label: '120601020307 (Upper Brazos)' },
  { value: '120601020308', regionId: '07', label: '120601020308 (Upper Brazos)' },
  { value: '120601020309', regionId: '07', label: '120601020309 (Upper Brazos)' },
  { value: '120601020310', regionId: '07', label: '120601020310 (Upper Brazos)' },
  { value: '120601020401', regionId: '07', label: '120601020401 (Upper Brazos)' },
  { value: '120601020402', regionId: '07', label: '120601020402 (Upper Brazos)' },
  { value: '120601020403', regionId: '07', label: '120601020403 (Upper Brazos)' },
  { value: '120601020404', regionId: '07', label: '120601020404 (Upper Brazos)' },
  { value: '120601020501', regionId: '07', label: '120601020501 (Upper Brazos)' },
  { value: '120601020502', regionId: '07', label: '120601020502 (Upper Brazos)' },
  { value: '120601020503', regionId: '07', label: '120601020503 (Upper Brazos)' },
  { value: '120601020504', regionId: '07', label: '120601020504 (Upper Brazos)' },
  { value: '120601020505', regionId: '07', label: '120601020505 (Upper Brazos)' },
  { value: '120601020601', regionId: '07', label: '120601020601 (Upper Brazos)' },
  { value: '120601020602', regionId: '07', label: '120601020602 (Upper Brazos)' },
  { value: '120601020603', regionId: '07', label: '120601020603 (Upper Brazos)' },
  { value: '120601020604', regionId: '07', label: '120601020604 (Upper Brazos)' },
  { value: '120601020605', regionId: '07', label: '120601020605 (Upper Brazos)' },
  { value: '120601020606', regionId: '07', label: '120601020606 (Upper Brazos)' },
  { value: '120601020607', regionId: '07', label: '120601020607 (Upper Brazos)' },
  { value: '120601020701', regionId: '07', label: '120601020701 (Upper Brazos)' },
  { value: '120601020702', regionId: '07', label: '120601020702 (Upper Brazos)' },
  { value: '120601020703', regionId: '07', label: '120601020703 (Upper Brazos)' },
  { value: '120601020704', regionId: '07', label: '120601020704 (Upper Brazos)' },
  { value: '120601020707', regionId: '07', label: '120601020707 (Upper Brazos)' },
  { value: '120601020801', regionId: '07', label: '120601020801 (Upper Brazos)' },
  { value: '120601020708', regionId: '07', label: '120601020708 (Upper Brazos)' },
  { value: '120601020709', regionId: '07', label: '120601020709 (Upper Brazos)' },
  { value: '120601020710', regionId: '07', label: '120601020710 (Upper Brazos)' },
  { value: '120601020711', regionId: '07', label: '120601020711 (Upper Brazos)' },
  { value: '120601020712', regionId: '07', label: '120601020712 (Upper Brazos)' },
  { value: '120601020802', regionId: '07', label: '120601020802 (Upper Brazos)' },
  { value: '120601020803', regionId: '07', label: '120601020803 (Upper Brazos)' },
  { value: '120601020804', regionId: '07', label: '120601020804 (Upper Brazos)' },
  { value: '120601020805', regionId: '07', label: '120601020805 (Upper Brazos)' },
  { value: '120601020807', regionId: '07', label: '120601020807 (Upper Brazos)' },
  { value: '120601020806', regionId: '07', label: '120601020806 (Upper Brazos)' },
  { value: '120601020901', regionId: '07', label: '120601020901 (Upper Brazos)' },
  { value: '120601020902', regionId: '07', label: '120601020902 (Upper Brazos)' },
  { value: '120601020903', regionId: '07', label: '120601020903 (Upper Brazos)' },
  { value: '120601020904', regionId: '07', label: '120601020904 (Upper Brazos)' },
  { value: '120601020905', regionId: '07', label: '120601020905 (Upper Brazos)' },
  { value: '120601020906', regionId: '07', label: '120601020906 (Upper Brazos)' },
  { value: '120601020907', regionId: '07', label: '120601020907 (Upper Brazos)' },
  { value: '120601020908', regionId: '07', label: '120601020908 (Upper Brazos)' },
  { value: '120601030303', regionId: '07', label: '120601030303 (Upper Brazos)' },
  { value: '120601030208', regionId: '07', label: '120601030208 (Upper Brazos)' },
  { value: '120601030301', regionId: '07', label: '120601030301 (Upper Brazos)' },
  { value: '120601030302', regionId: '07', label: '120601030302 (Upper Brazos)' },
  { value: '120601030304', regionId: '07', label: '120601030304 (Upper Brazos)' },
  { value: '120601030305', regionId: '07', label: '120601030305 (Upper Brazos)' },
  { value: '120601030306', regionId: '07', label: '120601030306 (Upper Brazos)' },
  { value: '120601030401', regionId: '07', label: '120601030401 (Upper Brazos)' },
  { value: '120601030402', regionId: '07', label: '120601030402 (Upper Brazos)' },
  { value: '120601030403', regionId: '07', label: '120601030403 (Upper Brazos)' },
  { value: '120601030202', regionId: '07', label: '120601030202 (Upper Brazos)' },
  { value: '120601030101', regionId: '07', label: '120601030101 (Upper Brazos)' },
  { value: '120601030102', regionId: '07', label: '120601030102 (Upper Brazos)' },
  { value: '120601030103', regionId: '07', label: '120601030103 (Upper Brazos)' },
  { value: '120601030203', regionId: '07', label: '120601030203 (Upper Brazos)' },
  { value: '120601030104', regionId: '07', label: '120601030104 (Upper Brazos)' },
  { value: '120601030204', regionId: '07', label: '120601030204 (Upper Brazos)' },
  { value: '120601030105', regionId: '07', label: '120601030105 (Upper Brazos)' },
  { value: '120601030106', regionId: '07', label: '120601030106 (Upper Brazos)' },
  { value: '120601030201', regionId: '07', label: '120601030201 (Upper Brazos)' },
  { value: '120601030205', regionId: '07', label: '120601030205 (Upper Brazos)' },
  { value: '120601030206', regionId: '07', label: '120601030206 (Upper Brazos)' },
  { value: '120601030207', regionId: '07', label: '120601030207 (Upper Brazos)' },
  { value: '120601040101', regionId: '07', label: '120601040101 (Upper Brazos)' },
  { value: '120601040102', regionId: '07', label: '120601040102 (Upper Brazos)' },
  { value: '120601040103', regionId: '07', label: '120601040103 (Upper Brazos)' },
  { value: '120601040104', regionId: '07', label: '120601040104 (Upper Brazos)' },
  { value: '120601040105', regionId: '07', label: '120601040105 (Upper Brazos)' },
  { value: '120601040106', regionId: '07', label: '120601040106 (Upper Brazos)' },
  { value: '120601040107', regionId: '07', label: '120601040107 (Upper Brazos)' },
  { value: '120601040108', regionId: '07', label: '120601040108 (Upper Brazos)' },
  { value: '120601040201', regionId: '07', label: '120601040201 (Upper Brazos)' },
  { value: '120601040202', regionId: '07', label: '120601040202 (Upper Brazos)' },
  { value: '120601040203', regionId: '07', label: '120601040203 (Upper Brazos)' },
  { value: '120601040204', regionId: '07', label: '120601040204 (Upper Brazos)' },
  { value: '120601040205', regionId: '07', label: '120601040205 (Upper Brazos)' },
  { value: '120601040301', regionId: '07', label: '120601040301 (Upper Brazos)' },
  { value: '120601040302', regionId: '07', label: '120601040302 (Upper Brazos)' },
  { value: '120601040303', regionId: '07', label: '120601040303 (Upper Brazos)' },
  { value: '120601050101', regionId: '07', label: '120601050101 (Upper Brazos)' },
  { value: '120601050102', regionId: '07', label: '120601050102 (Upper Brazos)' },
  { value: '120601050103', regionId: '07', label: '120601050103 (Upper Brazos)' },
  { value: '120601050104', regionId: '07', label: '120601050104 (Upper Brazos)' },
  { value: '120601050105', regionId: '07', label: '120601050105 (Upper Brazos)' },
  { value: '120601050106', regionId: '07', label: '120601050106 (Upper Brazos)' },
  { value: '120601050107', regionId: '07', label: '120601050107 (Upper Brazos)' },
  { value: '120601050201', regionId: '07', label: '120601050201 (Upper Brazos)' },
  { value: '120601050202', regionId: '07', label: '120601050202 (Upper Brazos)' },
  { value: '120601050203', regionId: '07', label: '120601050203 (Upper Brazos)' },
  { value: '120601050204', regionId: '07', label: '120601050204 (Upper Brazos)' },
  { value: '120601050301', regionId: '07', label: '120601050301 (Upper Brazos)' },
  { value: '120601050302', regionId: '07', label: '120601050302 (Upper Brazos)' },
  { value: '120601050303', regionId: '07', label: '120601050303 (Upper Brazos)' },
  { value: '120601050304', regionId: '07', label: '120601050304 (Upper Brazos)' },
  { value: '120601050401', regionId: '07', label: '120601050401 (Upper Brazos)' },
  { value: '120601050402', regionId: '07', label: '120601050402 (Upper Brazos)' },
  { value: '120601050403', regionId: '07', label: '120601050403 (Upper Brazos)' },
  { value: '120601050404', regionId: '07', label: '120601050404 (Upper Brazos)' },
  { value: '120601050405', regionId: '07', label: '120601050405 (Upper Brazos)' },
  { value: '120601050406', regionId: '07', label: '120601050406 (Upper Brazos)' },
  { value: '120601050407', regionId: '07', label: '120601050407 (Upper Brazos)' },
  { value: '120601050408', regionId: '07', label: '120601050408 (Upper Brazos)' },
  { value: '120601050409', regionId: '07', label: '120601050409 (Upper Brazos)' },
  { value: '120601050501', regionId: '07', label: '120601050501 (Upper Brazos)' },
  { value: '120601050502', regionId: '07', label: '120601050502 (Upper Brazos)' },
  { value: '120601050503', regionId: '07', label: '120601050503 (Upper Brazos)' },
  { value: '120601050504', regionId: '07', label: '120601050504 (Upper Brazos)' },
  { value: '120601050601', regionId: '07', label: '120601050601 (Upper Brazos)' },
  { value: '120601050602', regionId: '07', label: '120601050602 (Upper Brazos)' },
  { value: '120601050603', regionId: '07', label: '120601050603 (Upper Brazos)' },
  { value: '120601050604', regionId: '07', label: '120601050604 (Upper Brazos)' },
  { value: '120602010101', regionId: '07', label: '120602010101 (Upper Brazos)' },
  { value: '120602010103', regionId: '07', label: '120602010103 (Upper Brazos)' },
  { value: '120602010107', regionId: '07', label: '120602010107 (Upper Brazos)' },
  { value: '120602010302', regionId: '07', label: '120602010302 (Upper Brazos)' },
  { value: '120602010303', regionId: '07', label: '120602010303 (Upper Brazos)' },
  { value: '120602010401', regionId: '07', label: '120602010401 (Upper Brazos)' },
  { value: '120602010501', regionId: '07', label: '120602010501 (Upper Brazos)' },
  { value: '120602010504', regionId: '07', label: '120602010504 (Upper Brazos)' },
  { value: '120702010101', regionId: '07', label: '120702010101 (Upper Brazos)' },
  { value: '120702010103', regionId: '07', label: '120702010103 (Upper Brazos)' },
  { value: '120702010105', regionId: '07', label: '120702010105 (Upper Brazos)' },
  { value: '120702010106', regionId: '07', label: '120702010106 (Upper Brazos)' },
  { value: '120702010402', regionId: '07', label: '120702010402 (Upper Brazos)' },
  { value: '120800020103', regionId: '07', label: '120800020103 (Upper Brazos)' },
  { value: '120800020101', regionId: '07', label: '120800020101 (Upper Brazos)' },
  { value: '120800020106', regionId: '07', label: '120800020106 (Upper Brazos)' },
  { value: '120800020108', regionId: '07', label: '120800020108 (Upper Brazos)' },
  { value: '120800020503', regionId: '07', label: '120800020503 (Upper Brazos)' },
  { value: '120800020501', regionId: '07', label: '120800020501 (Upper Brazos)' },
  { value: '120800020601', regionId: '07', label: '120800020601 (Upper Brazos)' },
  { value: '120800020502', regionId: '07', label: '120800020502 (Upper Brazos)' },
  { value: '120800020506', regionId: '07', label: '120800020506 (Upper Brazos)' },
  { value: '120800020602', regionId: '07', label: '120800020602 (Upper Brazos)' },
  { value: '120800020603', regionId: '07', label: '120800020603 (Upper Brazos)' },
  { value: '120800020604', regionId: '07', label: '120800020604 (Upper Brazos)' },
  { value: '120800020605', regionId: '07', label: '120800020605 (Upper Brazos)' },
  { value: '120800020606', regionId: '07', label: '120800020606 (Upper Brazos)' },
  { value: '120800020607', regionId: '07', label: '120800020607 (Upper Brazos)' },
  { value: '120800020901', regionId: '07', label: '120800020901 (Upper Brazos)' },
  { value: '120800020902', regionId: '07', label: '120800020902 (Upper Brazos)' },
  { value: '120800020903', regionId: '07', label: '120800020903 (Upper Brazos)' },
  { value: '120800021002', regionId: '07', label: '120800021002 (Upper Brazos)' },
  { value: '120800080102', regionId: '07', label: '120800080102 (Upper Brazos)' },
  { value: '120800080301', regionId: '07', label: '120800080301 (Upper Brazos)' },
  { value: '120800080302', regionId: '07', label: '120800080302 (Upper Brazos)' },
  { value: '120800080304', regionId: '07', label: '120800080304 (Upper Brazos)' },
  { value: '120901010201', regionId: '07', label: '120901010201 (Upper Brazos)' },
  { value: '120901010203', regionId: '07', label: '120901010203 (Upper Brazos)' },
  { value: '120901010304', regionId: '07', label: '120901010304 (Upper Brazos)' },
  { value: '120901010305', regionId: '07', label: '120901010305 (Upper Brazos)' },
  { value: '120901070101', regionId: '07', label: '120901070101 (Upper Brazos)' },
  { value: '120901070102', regionId: '07', label: '120901070102 (Upper Brazos)' },
  { value: '120901070103', regionId: '07', label: '120901070103 (Upper Brazos)' },
  { value: '120901070105', regionId: '07', label: '120901070105 (Upper Brazos)' },
  { value: '120901070203', regionId: '07', label: '120901070203 (Upper Brazos)' },
  { value: '120901080101', regionId: '07', label: '120901080101 (Upper Brazos)' },
  { value: '111201010307', regionId: '07', label: '111201010307 (Upper Brazos)' },
  { value: '111201010401', regionId: '07', label: '111201010401 (Upper Brazos)' },
  { value: '111201010404', regionId: '07', label: '111201010404 (Upper Brazos)' },
  { value: '111201010405', regionId: '07', label: '111201010405 (Upper Brazos)' },
  { value: '120500010504', regionId: '07', label: '120500010504 (Upper Brazos)' },
  { value: '120500010505', regionId: '07', label: '120500010505 (Upper Brazos)' },
  { value: '120500010606', regionId: '07', label: '120500010606 (Upper Brazos)' },
  { value: '120500010607', regionId: '07', label: '120500010607 (Upper Brazos)' },
  { value: '120500010610', regionId: '07', label: '120500010610 (Upper Brazos)' },
  { value: '120500010801', regionId: '07', label: '120500010801 (Upper Brazos)' },
  { value: '120500010701', regionId: '07', label: '120500010701 (Upper Brazos)' },
  { value: '120500010702', regionId: '07', label: '120500010702 (Upper Brazos)' },
  { value: '120500010901', regionId: '07', label: '120500010901 (Upper Brazos)' },
  { value: '120500010902', regionId: '07', label: '120500010902 (Upper Brazos)' },
  { value: '120500010703', regionId: '07', label: '120500010703 (Upper Brazos)' },
  { value: '120500010704', regionId: '07', label: '120500010704 (Upper Brazos)' },
  { value: '120500011105', regionId: '07', label: '120500011105 (Upper Brazos)' },
  { value: '120500011106', regionId: '07', label: '120500011106 (Upper Brazos)' },
  { value: '120500011107', regionId: '07', label: '120500011107 (Upper Brazos)' },
  { value: '120500011203', regionId: '07', label: '120500011203 (Upper Brazos)' },
  { value: '120500011004', regionId: '07', label: '120500011004 (Upper Brazos)' },
  { value: '120500011005', regionId: '07', label: '120500011005 (Upper Brazos)' },
  { value: '120500011101', regionId: '07', label: '120500011101 (Upper Brazos)' },
  { value: '120500011006', regionId: '07', label: '120500011006 (Upper Brazos)' },
  { value: '120500011102', regionId: '07', label: '120500011102 (Upper Brazos)' },
  { value: '120500011103', regionId: '07', label: '120500011103 (Upper Brazos)' },
  { value: '120500011104', regionId: '07', label: '120500011104 (Upper Brazos)' },
  { value: '120500011201', regionId: '07', label: '120500011201 (Upper Brazos)' },
  { value: '120500011202', regionId: '07', label: '120500011202 (Upper Brazos)' },
  { value: '120500011301', regionId: '07', label: '120500011301 (Upper Brazos)' },
  { value: '120500011302', regionId: '07', label: '120500011302 (Upper Brazos)' },
  { value: '120500011303', regionId: '07', label: '120500011303 (Upper Brazos)' },
  { value: '120500011304', regionId: '07', label: '120500011304 (Upper Brazos)' },
  { value: '120500011305', regionId: '07', label: '120500011305 (Upper Brazos)' },
  { value: '120500010507', regionId: '07', label: '120500010507 (Upper Brazos)' },
  { value: '120500010611', regionId: '07', label: '120500010611 (Upper Brazos)' },
  { value: '120500010804', regionId: '07', label: '120500010804 (Upper Brazos)' },
  { value: '120500010906', regionId: '07', label: '120500010906 (Upper Brazos)' },
  { value: '120500011001', regionId: '07', label: '120500011001 (Upper Brazos)' },
  { value: '120500010506', regionId: '07', label: '120500010506 (Upper Brazos)' },
  { value: '120500010802', regionId: '07', label: '120500010802 (Upper Brazos)' },
  { value: '120500010803', regionId: '07', label: '120500010803 (Upper Brazos)' },
  { value: '120500010805', regionId: '07', label: '120500010805 (Upper Brazos)' },
  { value: '120500010903', regionId: '07', label: '120500010903 (Upper Brazos)' },
  { value: '120500010904', regionId: '07', label: '120500010904 (Upper Brazos)' },
  { value: '120500010905', regionId: '07', label: '120500010905 (Upper Brazos)' },
  { value: '120500011002', regionId: '07', label: '120500011002 (Upper Brazos)' },
  { value: '120500011003', regionId: '07', label: '120500011003 (Upper Brazos)' },
  { value: '120500020110', regionId: '07', label: '120500020110 (Upper Brazos)' },
  { value: '120500020111', regionId: '07', label: '120500020111 (Upper Brazos)' },
  { value: '120500020112', regionId: '07', label: '120500020112 (Upper Brazos)' },
  { value: '120500020201', regionId: '07', label: '120500020201 (Upper Brazos)' },
  { value: '120500020202', regionId: '07', label: '120500020202 (Upper Brazos)' },
  { value: '120500020203', regionId: '07', label: '120500020203 (Upper Brazos)' },
  { value: '120500020302', regionId: '07', label: '120500020302 (Upper Brazos)' },
  { value: '120500020402', regionId: '07', label: '120500020402 (Upper Brazos)' },
  { value: '120500020403', regionId: '07', label: '120500020403 (Upper Brazos)' },
  { value: '120500020406', regionId: '07', label: '120500020406 (Upper Brazos)' },
  { value: '120500020407', regionId: '07', label: '120500020407 (Upper Brazos)' },
  { value: '120500020501', regionId: '07', label: '120500020501 (Upper Brazos)' },
  { value: '120500020502', regionId: '07', label: '120500020502 (Upper Brazos)' },
  { value: '120500020505', regionId: '07', label: '120500020505 (Upper Brazos)' },
  { value: '120500020506', regionId: '07', label: '120500020506 (Upper Brazos)' },
  { value: '120500020507', regionId: '07', label: '120500020507 (Upper Brazos)' },
  { value: '120500020508', regionId: '07', label: '120500020508 (Upper Brazos)' },
  { value: '120500020204', regionId: '07', label: '120500020204 (Upper Brazos)' },
  { value: '120500020303', regionId: '07', label: '120500020303 (Upper Brazos)' },
  { value: '120500020401', regionId: '07', label: '120500020401 (Upper Brazos)' },
  { value: '120500020404', regionId: '07', label: '120500020404 (Upper Brazos)' },
  { value: '120500020405', regionId: '07', label: '120500020405 (Upper Brazos)' },
  { value: '120500020408', regionId: '07', label: '120500020408 (Upper Brazos)' },
  { value: '120500020409', regionId: '07', label: '120500020409 (Upper Brazos)' },
  { value: '120500020410', regionId: '07', label: '120500020410 (Upper Brazos)' },
  { value: '120500020411', regionId: '07', label: '120500020411 (Upper Brazos)' },
  { value: '120500020412', regionId: '07', label: '120500020412 (Upper Brazos)' },
  { value: '120500020503', regionId: '07', label: '120500020503 (Upper Brazos)' },
  { value: '120500020504', regionId: '07', label: '120500020504 (Upper Brazos)' },
  { value: '120500050106', regionId: '07', label: '120500050106 (Upper Brazos)' },
  { value: '120500050107', regionId: '07', label: '120500050107 (Upper Brazos)' },
  { value: '120500050108', regionId: '07', label: '120500050108 (Upper Brazos)' },
  { value: '120500050307', regionId: '07', label: '120500050307 (Upper Brazos)' },
  { value: '120500050401', regionId: '07', label: '120500050401 (Upper Brazos)' },
  { value: '120500050202', regionId: '07', label: '120500050202 (Upper Brazos)' },
  { value: '120500050203', regionId: '07', label: '120500050203 (Upper Brazos)' },
  { value: '120500050204', regionId: '07', label: '120500050204 (Upper Brazos)' },
  { value: '120500050402', regionId: '07', label: '120500050402 (Upper Brazos)' },
  { value: '120500050403', regionId: '07', label: '120500050403 (Upper Brazos)' },
  { value: '120500050404', regionId: '07', label: '120500050404 (Upper Brazos)' },
  { value: '120500050502', regionId: '07', label: '120500050502 (Upper Brazos)' },
  { value: '120500050503', regionId: '07', label: '120500050503 (Upper Brazos)' },
  { value: '120500050504', regionId: '07', label: '120500050504 (Upper Brazos)' },
  { value: '120500050205', regionId: '07', label: '120500050205 (Upper Brazos)' },
  { value: '120500050201', regionId: '07', label: '120500050201 (Upper Brazos)' },
  { value: '120500050109', regionId: '07', label: '120500050109 (Upper Brazos)' },
  { value: '120500050206', regionId: '07', label: '120500050206 (Upper Brazos)' },
  { value: '120500050207', regionId: '07', label: '120500050207 (Upper Brazos)' },
  { value: '120500050301', regionId: '07', label: '120500050301 (Upper Brazos)' },
  { value: '120500050302', regionId: '07', label: '120500050302 (Upper Brazos)' },
  { value: '120500050303', regionId: '07', label: '120500050303 (Upper Brazos)' },
  { value: '120500050304', regionId: '07', label: '120500050304 (Upper Brazos)' },
  { value: '120500050305', regionId: '07', label: '120500050305 (Upper Brazos)' },
  { value: '120500050306', regionId: '07', label: '120500050306 (Upper Brazos)' },
  { value: '120500050405', regionId: '07', label: '120500050405 (Upper Brazos)' },
  { value: '120500050501', regionId: '07', label: '120500050501 (Upper Brazos)' },
  { value: '120500050505', regionId: '07', label: '120500050505 (Upper Brazos)' },
  { value: '120500050506', regionId: '07', label: '120500050506 (Upper Brazos)' },
  { value: '120800010208', regionId: '07', label: '120800010208 (Upper Brazos)' },
  { value: '120800010209', regionId: '07', label: '120800010209 (Upper Brazos)' },
  { value: '120800010305', regionId: '07', label: '120800010305 (Upper Brazos)' },
  { value: '120800010307', regionId: '07', label: '120800010307 (Upper Brazos)' },
  { value: '120800010301', regionId: '07', label: '120800010301 (Upper Brazos)' },
  { value: '120800010304', regionId: '07', label: '120800010304 (Upper Brazos)' },
  { value: '120800010302', regionId: '07', label: '120800010302 (Upper Brazos)' },
  { value: '120800010303', regionId: '07', label: '120800010303 (Upper Brazos)' },
  { value: '120800010308', regionId: '07', label: '120800010308 (Upper Brazos)' },
  { value: '120800010310', regionId: '07', label: '120800010310 (Upper Brazos)' },
]

const hucsRegion09 = [
  { value: '120500040101', regionId: '09', label: '120500040101 (Upper Colorado)' },
  { value: '120500040102', regionId: '09', label: '120500040102 (Upper Colorado)' },
  { value: '120500040305', regionId: '09', label: '120500040305 (Upper Colorado)' },
  { value: '120500040306', regionId: '09', label: '120500040306 (Upper Colorado)' },
  { value: '120500040104', regionId: '09', label: '120500040104 (Upper Colorado)' },
  { value: '120500040103', regionId: '09', label: '120500040103 (Upper Colorado)' },
  { value: '120500040301', regionId: '09', label: '120500040301 (Upper Colorado)' },
  { value: '120500040302', regionId: '09', label: '120500040302 (Upper Colorado)' },
  { value: '120500040303', regionId: '09', label: '120500040303 (Upper Colorado)' },
  { value: '120500040308', regionId: '09', label: '120500040308 (Upper Colorado)' },
  { value: '120500040405', regionId: '09', label: '120500040405 (Upper Colorado)' },
  { value: '120500040406', regionId: '09', label: '120500040406 (Upper Colorado)' },
  { value: '120500040501', regionId: '09', label: '120500040501 (Upper Colorado)' },
  { value: '120500040503', regionId: '09', label: '120500040503 (Upper Colorado)' },
  { value: '120500040504', regionId: '09', label: '120500040504 (Upper Colorado)' },
  { value: '120500040505', regionId: '09', label: '120500040505 (Upper Colorado)' },
  { value: '120500040701', regionId: '09', label: '120500040701 (Upper Colorado)' },
  { value: '120500040703', regionId: '09', label: '120500040703 (Upper Colorado)' },
  { value: '120601020103', regionId: '09', label: '120601020103 (Upper Colorado)' },
  { value: '120601020101', regionId: '09', label: '120601020101 (Upper Colorado)' },
  { value: '120601020301', regionId: '09', label: '120601020301 (Upper Colorado)' },
  { value: '120601020201', regionId: '09', label: '120601020201 (Upper Colorado)' },
  { value: '120601020204', regionId: '09', label: '120601020204 (Upper Colorado)' },
  { value: '120601020302', regionId: '09', label: '120601020302 (Upper Colorado)' },
  { value: '120601020305', regionId: '09', label: '120601020305 (Upper Colorado)' },
  { value: '120601020701', regionId: '09', label: '120601020701 (Upper Colorado)' },
  { value: '120601020702', regionId: '09', label: '120601020702 (Upper Colorado)' },
  { value: '120601020703', regionId: '09', label: '120601020703 (Upper Colorado)' },
  { value: '120800020103', regionId: '09', label: '120800020103 (Upper Colorado)' },
  { value: '120800021003', regionId: '09', label: '120800021003 (Upper Colorado)' },
  { value: '120800021004', regionId: '09', label: '120800021004 (Upper Colorado)' },
  { value: '120800021005', regionId: '09', label: '120800021005 (Upper Colorado)' },
  { value: '120800021006', regionId: '09', label: '120800021006 (Upper Colorado)' },
  { value: '120800021007', regionId: '09', label: '120800021007 (Upper Colorado)' },
  { value: '120800020101', regionId: '09', label: '120800020101 (Upper Colorado)' },
  { value: '120800020102', regionId: '09', label: '120800020102 (Upper Colorado)' },
  { value: '120800020104', regionId: '09', label: '120800020104 (Upper Colorado)' },
  { value: '120800020105', regionId: '09', label: '120800020105 (Upper Colorado)' },
  { value: '120800020106', regionId: '09', label: '120800020106 (Upper Colorado)' },
  { value: '120800020303', regionId: '09', label: '120800020303 (Upper Colorado)' },
  { value: '120800020107', regionId: '09', label: '120800020107 (Upper Colorado)' },
  { value: '120800020108', regionId: '09', label: '120800020108 (Upper Colorado)' },
  { value: '120800020109', regionId: '09', label: '120800020109 (Upper Colorado)' },
  { value: '120800020201', regionId: '09', label: '120800020201 (Upper Colorado)' },
  { value: '120800020202', regionId: '09', label: '120800020202 (Upper Colorado)' },
  { value: '120800020203', regionId: '09', label: '120800020203 (Upper Colorado)' },
  { value: '120800020204', regionId: '09', label: '120800020204 (Upper Colorado)' },
  { value: '120800020301', regionId: '09', label: '120800020301 (Upper Colorado)' },
  { value: '120800020302', regionId: '09', label: '120800020302 (Upper Colorado)' },
  { value: '120800020304', regionId: '09', label: '120800020304 (Upper Colorado)' },
  { value: '120800020305', regionId: '09', label: '120800020305 (Upper Colorado)' },
  { value: '120800020306', regionId: '09', label: '120800020306 (Upper Colorado)' },
  { value: '120800020401', regionId: '09', label: '120800020401 (Upper Colorado)' },
  { value: '120800020402', regionId: '09', label: '120800020402 (Upper Colorado)' },
  { value: '120800020403', regionId: '09', label: '120800020403 (Upper Colorado)' },
  { value: '120800020404', regionId: '09', label: '120800020404 (Upper Colorado)' },
  { value: '120800020405', regionId: '09', label: '120800020405 (Upper Colorado)' },
  { value: '120800020406', regionId: '09', label: '120800020406 (Upper Colorado)' },
  { value: '120800020503', regionId: '09', label: '120800020503 (Upper Colorado)' },
  { value: '120800020407', regionId: '09', label: '120800020407 (Upper Colorado)' },
  { value: '120800020501', regionId: '09', label: '120800020501 (Upper Colorado)' },
  { value: '120800020601', regionId: '09', label: '120800020601 (Upper Colorado)' },
  { value: '120800020502', regionId: '09', label: '120800020502 (Upper Colorado)' },
  { value: '120800020504', regionId: '09', label: '120800020504 (Upper Colorado)' },
  { value: '120800020505', regionId: '09', label: '120800020505 (Upper Colorado)' },
  { value: '120800020506', regionId: '09', label: '120800020506 (Upper Colorado)' },
  { value: '120800020507', regionId: '09', label: '120800020507 (Upper Colorado)' },
  { value: '120800020508', regionId: '09', label: '120800020508 (Upper Colorado)' },
  { value: '120800020509', regionId: '09', label: '120800020509 (Upper Colorado)' },
  { value: '120800020510', regionId: '09', label: '120800020510 (Upper Colorado)' },
  { value: '120800020602', regionId: '09', label: '120800020602 (Upper Colorado)' },
  { value: '120800020603', regionId: '09', label: '120800020603 (Upper Colorado)' },
  { value: '120800020604', regionId: '09', label: '120800020604 (Upper Colorado)' },
  { value: '120800020605', regionId: '09', label: '120800020605 (Upper Colorado)' },
  { value: '120800020606', regionId: '09', label: '120800020606 (Upper Colorado)' },
  { value: '120800020607', regionId: '09', label: '120800020607 (Upper Colorado)' },
  { value: '120800020701', regionId: '09', label: '120800020701 (Upper Colorado)' },
  { value: '120800020703', regionId: '09', label: '120800020703 (Upper Colorado)' },
  { value: '120800020702', regionId: '09', label: '120800020702 (Upper Colorado)' },
  { value: '120800020704', regionId: '09', label: '120800020704 (Upper Colorado)' },
  { value: '120800020705', regionId: '09', label: '120800020705 (Upper Colorado)' },
  { value: '120800020706', regionId: '09', label: '120800020706 (Upper Colorado)' },
  { value: '120800020801', regionId: '09', label: '120800020801 (Upper Colorado)' },
  { value: '120800020802', regionId: '09', label: '120800020802 (Upper Colorado)' },
  { value: '120800020901', regionId: '09', label: '120800020901 (Upper Colorado)' },
  { value: '120800020803', regionId: '09', label: '120800020803 (Upper Colorado)' },
  { value: '120800020804', regionId: '09', label: '120800020804 (Upper Colorado)' },
  { value: '120800020805', regionId: '09', label: '120800020805 (Upper Colorado)' },
  { value: '120800020806', regionId: '09', label: '120800020806 (Upper Colorado)' },
  { value: '120800020807', regionId: '09', label: '120800020807 (Upper Colorado)' },
  { value: '120800020902', regionId: '09', label: '120800020902 (Upper Colorado)' },
  { value: '120800020903', regionId: '09', label: '120800020903 (Upper Colorado)' },
  { value: '120800021001', regionId: '09', label: '120800021001 (Upper Colorado)' },
  { value: '120800021002', regionId: '09', label: '120800021002 (Upper Colorado)' },
  { value: '120800050301', regionId: '09', label: '120800050301 (Upper Colorado)' },
  { value: '120800050402', regionId: '09', label: '120800050402 (Upper Colorado)' },
  { value: '120800050108', regionId: '09', label: '120800050108 (Upper Colorado)' },
  { value: '120800050101', regionId: '09', label: '120800050101 (Upper Colorado)' },
  { value: '120800050102', regionId: '09', label: '120800050102 (Upper Colorado)' },
  { value: '120800050103', regionId: '09', label: '120800050103 (Upper Colorado)' },
  { value: '120800050104', regionId: '09', label: '120800050104 (Upper Colorado)' },
  { value: '120800050105', regionId: '09', label: '120800050105 (Upper Colorado)' },
  { value: '120800050106', regionId: '09', label: '120800050106 (Upper Colorado)' },
  { value: '120800050107', regionId: '09', label: '120800050107 (Upper Colorado)' },
  { value: '120800050201', regionId: '09', label: '120800050201 (Upper Colorado)' },
  { value: '120800050202', regionId: '09', label: '120800050202 (Upper Colorado)' },
  { value: '120800050203', regionId: '09', label: '120800050203 (Upper Colorado)' },
  { value: '120800050204', regionId: '09', label: '120800050204 (Upper Colorado)' },
  { value: '120800050205', regionId: '09', label: '120800050205 (Upper Colorado)' },
  { value: '120800050206', regionId: '09', label: '120800050206 (Upper Colorado)' },
  { value: '120800050207', regionId: '09', label: '120800050207 (Upper Colorado)' },
  { value: '120800050302', regionId: '09', label: '120800050302 (Upper Colorado)' },
  { value: '120800050303', regionId: '09', label: '120800050303 (Upper Colorado)' },
  { value: '120800050403', regionId: '09', label: '120800050403 (Upper Colorado)' },
  { value: '120800050304', regionId: '09', label: '120800050304 (Upper Colorado)' },
  { value: '120800050305', regionId: '09', label: '120800050305 (Upper Colorado)' },
  { value: '120800050306', regionId: '09', label: '120800050306 (Upper Colorado)' },
  { value: '120800050307', regionId: '09', label: '120800050307 (Upper Colorado)' },
  { value: '120800050401', regionId: '09', label: '120800050401 (Upper Colorado)' },
  { value: '120800050404', regionId: '09', label: '120800050404 (Upper Colorado)' },
  { value: '120800050502', regionId: '09', label: '120800050502 (Upper Colorado)' },
  { value: '120800050405', regionId: '09', label: '120800050405 (Upper Colorado)' },
  { value: '120800050406', regionId: '09', label: '120800050406 (Upper Colorado)' },
  { value: '120800050501', regionId: '09', label: '120800050501 (Upper Colorado)' },
  { value: '120800050503', regionId: '09', label: '120800050503 (Upper Colorado)' },
  { value: '120800050504', regionId: '09', label: '120800050504 (Upper Colorado)' },
  { value: '120800050505', regionId: '09', label: '120800050505 (Upper Colorado)' },
  { value: '120800050507', regionId: '09', label: '120800050507 (Upper Colorado)' },
  { value: '120800050506', regionId: '09', label: '120800050506 (Upper Colorado)' },
  { value: '120800050508', regionId: '09', label: '120800050508 (Upper Colorado)' },
  { value: '120800050509', regionId: '09', label: '120800050509 (Upper Colorado)' },
  { value: '120800050601', regionId: '09', label: '120800050601 (Upper Colorado)' },
  { value: '120800050603', regionId: '09', label: '120800050603 (Upper Colorado)' },
  { value: '120800050604', regionId: '09', label: '120800050604 (Upper Colorado)' },
  { value: '120800050602', regionId: '09', label: '120800050602 (Upper Colorado)' },
  { value: '120800050605', regionId: '09', label: '120800050605 (Upper Colorado)' },
  { value: '120800070101', regionId: '09', label: '120800070101 (Upper Colorado)' },
  { value: '120800070102', regionId: '09', label: '120800070102 (Upper Colorado)' },
  { value: '120800070103', regionId: '09', label: '120800070103 (Upper Colorado)' },
  { value: '120800070104', regionId: '09', label: '120800070104 (Upper Colorado)' },
  { value: '120800070105', regionId: '09', label: '120800070105 (Upper Colorado)' },
  { value: '120800070106', regionId: '09', label: '120800070106 (Upper Colorado)' },
  { value: '120800070107', regionId: '09', label: '120800070107 (Upper Colorado)' },
  { value: '120800070108', regionId: '09', label: '120800070108 (Upper Colorado)' },
  { value: '120800070201', regionId: '09', label: '120800070201 (Upper Colorado)' },
  { value: '120800070202', regionId: '09', label: '120800070202 (Upper Colorado)' },
  { value: '120800070203', regionId: '09', label: '120800070203 (Upper Colorado)' },
  { value: '120800070204', regionId: '09', label: '120800070204 (Upper Colorado)' },
  { value: '120800070205', regionId: '09', label: '120800070205 (Upper Colorado)' },
  { value: '120800070206', regionId: '09', label: '120800070206 (Upper Colorado)' },
  { value: '120800070207', regionId: '09', label: '120800070207 (Upper Colorado)' },
  { value: '120800080305', regionId: '09', label: '120800080305 (Upper Colorado)' },
  { value: '120800080306', regionId: '09', label: '120800080306 (Upper Colorado)' },
  { value: '120800080307', regionId: '09', label: '120800080307 (Upper Colorado)' },
  { value: '120800080308', regionId: '09', label: '120800080308 (Upper Colorado)' },
  { value: '120800080401', regionId: '09', label: '120800080401 (Upper Colorado)' },
  { value: '120800080402', regionId: '09', label: '120800080402 (Upper Colorado)' },
  { value: '120800080403', regionId: '09', label: '120800080403 (Upper Colorado)' },
  { value: '120800080404', regionId: '09', label: '120800080404 (Upper Colorado)' },
  { value: '120800080405', regionId: '09', label: '120800080405 (Upper Colorado)' },
  { value: '120800080406', regionId: '09', label: '120800080406 (Upper Colorado)' },
  { value: '120800080407', regionId: '09', label: '120800080407 (Upper Colorado)' },
  { value: '120800080408', regionId: '09', label: '120800080408 (Upper Colorado)' },
  { value: '120800080409', regionId: '09', label: '120800080409 (Upper Colorado)' },
  { value: '120800080101', regionId: '09', label: '120800080101 (Upper Colorado)' },
  { value: '120800080102', regionId: '09', label: '120800080102 (Upper Colorado)' },
  { value: '120800080103', regionId: '09', label: '120800080103 (Upper Colorado)' },
  { value: '120800080104', regionId: '09', label: '120800080104 (Upper Colorado)' },
  { value: '120800080105', regionId: '09', label: '120800080105 (Upper Colorado)' },
  { value: '120800080106', regionId: '09', label: '120800080106 (Upper Colorado)' },
  { value: '120800080107', regionId: '09', label: '120800080107 (Upper Colorado)' },
  { value: '120800080108', regionId: '09', label: '120800080108 (Upper Colorado)' },
  { value: '120800080109', regionId: '09', label: '120800080109 (Upper Colorado)' },
  { value: '120800080110', regionId: '09', label: '120800080110 (Upper Colorado)' },
  { value: '120800080201', regionId: '09', label: '120800080201 (Upper Colorado)' },
  { value: '120800080202', regionId: '09', label: '120800080202 (Upper Colorado)' },
  { value: '120800080203', regionId: '09', label: '120800080203 (Upper Colorado)' },
  { value: '120800080204', regionId: '09', label: '120800080204 (Upper Colorado)' },
  { value: '120800080205', regionId: '09', label: '120800080205 (Upper Colorado)' },
  { value: '120800080206', regionId: '09', label: '120800080206 (Upper Colorado)' },
  { value: '120800080207', regionId: '09', label: '120800080207 (Upper Colorado)' },
  { value: '120800080301', regionId: '09', label: '120800080301 (Upper Colorado)' },
  { value: '120800080302', regionId: '09', label: '120800080302 (Upper Colorado)' },
  { value: '120800080303', regionId: '09', label: '120800080303 (Upper Colorado)' },
  { value: '120800080304', regionId: '09', label: '120800080304 (Upper Colorado)' },
  { value: '120901010204', regionId: '09', label: '120901010204 (Upper Colorado)' },
  { value: '120901010101', regionId: '09', label: '120901010101 (Upper Colorado)' },
  { value: '120901010102', regionId: '09', label: '120901010102 (Upper Colorado)' },
  { value: '120901010103', regionId: '09', label: '120901010103 (Upper Colorado)' },
  { value: '120901010104', regionId: '09', label: '120901010104 (Upper Colorado)' },
  { value: '120901010105', regionId: '09', label: '120901010105 (Upper Colorado)' },
  { value: '120901010201', regionId: '09', label: '120901010201 (Upper Colorado)' },
  { value: '120901010403', regionId: '09', label: '120901010403 (Upper Colorado)' },
  { value: '120901010202', regionId: '09', label: '120901010202 (Upper Colorado)' },
  { value: '120901010501', regionId: '09', label: '120901010501 (Upper Colorado)' },
  { value: '120901010203', regionId: '09', label: '120901010203 (Upper Colorado)' },
  { value: '120901010205', regionId: '09', label: '120901010205 (Upper Colorado)' },
  { value: '120901010206', regionId: '09', label: '120901010206 (Upper Colorado)' },
  { value: '120901010301', regionId: '09', label: '120901010301 (Upper Colorado)' },
  { value: '120901010302', regionId: '09', label: '120901010302 (Upper Colorado)' },
  { value: '120901010303', regionId: '09', label: '120901010303 (Upper Colorado)' },
  { value: '120901010304', regionId: '09', label: '120901010304 (Upper Colorado)' },
  { value: '120901010305', regionId: '09', label: '120901010305 (Upper Colorado)' },
  { value: '120901010306', regionId: '09', label: '120901010306 (Upper Colorado)' },
  { value: '120901010307', regionId: '09', label: '120901010307 (Upper Colorado)' },
  { value: '120901010308', regionId: '09', label: '120901010308 (Upper Colorado)' },
  { value: '120901010401', regionId: '09', label: '120901010401 (Upper Colorado)' },
  { value: '120901010402', regionId: '09', label: '120901010402 (Upper Colorado)' },
  { value: '120901010404', regionId: '09', label: '120901010404 (Upper Colorado)' },
  { value: '120901010405', regionId: '09', label: '120901010405 (Upper Colorado)' },
  { value: '120901010502', regionId: '09', label: '120901010502 (Upper Colorado)' },
  { value: '120901010503', regionId: '09', label: '120901010503 (Upper Colorado)' },
  { value: '120901010504', regionId: '09', label: '120901010504 (Upper Colorado)' },
  { value: '120901010505', regionId: '09', label: '120901010505 (Upper Colorado)' },
  { value: '120901020401', regionId: '09', label: '120901020401 (Upper Colorado)' },
  { value: '120901020101', regionId: '09', label: '120901020101 (Upper Colorado)' },
  { value: '120901020102', regionId: '09', label: '120901020102 (Upper Colorado)' },
  { value: '120901020402', regionId: '09', label: '120901020402 (Upper Colorado)' },
  { value: '120901020105', regionId: '09', label: '120901020105 (Upper Colorado)' },
  { value: '120901020104', regionId: '09', label: '120901020104 (Upper Colorado)' },
  { value: '120901020103', regionId: '09', label: '120901020103 (Upper Colorado)' },
  { value: '120901020201', regionId: '09', label: '120901020201 (Upper Colorado)' },
  { value: '120901020202', regionId: '09', label: '120901020202 (Upper Colorado)' },
  { value: '120901020203', regionId: '09', label: '120901020203 (Upper Colorado)' },
  { value: '120901020204', regionId: '09', label: '120901020204 (Upper Colorado)' },
  { value: '120901020301', regionId: '09', label: '120901020301 (Upper Colorado)' },
  { value: '120901020205', regionId: '09', label: '120901020205 (Upper Colorado)' },
  { value: '120901020302', regionId: '09', label: '120901020302 (Upper Colorado)' },
  { value: '120901020303', regionId: '09', label: '120901020303 (Upper Colorado)' },
  { value: '120901020403', regionId: '09', label: '120901020403 (Upper Colorado)' },
  { value: '120901020404', regionId: '09', label: '120901020404 (Upper Colorado)' },
  { value: '120901020304', regionId: '09', label: '120901020304 (Upper Colorado)' },
  { value: '120901020305', regionId: '09', label: '120901020305 (Upper Colorado)' },
  { value: '120901020306', regionId: '09', label: '120901020306 (Upper Colorado)' },
  { value: '120901020405', regionId: '09', label: '120901020405 (Upper Colorado)' },
  { value: '120901020406', regionId: '09', label: '120901020406 (Upper Colorado)' },
  { value: '120901020407', regionId: '09', label: '120901020407 (Upper Colorado)' },
  { value: '120901020408', regionId: '09', label: '120901020408 (Upper Colorado)' },
  { value: '120901020501', regionId: '09', label: '120901020501 (Upper Colorado)' },
  { value: '120901020508', regionId: '09', label: '120901020508 (Upper Colorado)' },
  { value: '120901020509', regionId: '09', label: '120901020509 (Upper Colorado)' },
  { value: '120901020502', regionId: '09', label: '120901020502 (Upper Colorado)' },
  { value: '120901020503', regionId: '09', label: '120901020503 (Upper Colorado)' },
  { value: '120901020504', regionId: '09', label: '120901020504 (Upper Colorado)' },
  { value: '120901020505', regionId: '09', label: '120901020505 (Upper Colorado)' },
  { value: '120901020506', regionId: '09', label: '120901020506 (Upper Colorado)' },
  { value: '120901020507', regionId: '09', label: '120901020507 (Upper Colorado)' },
  { value: '120901030203', regionId: '09', label: '120901030203 (Upper Colorado)' },
  { value: '120901030701', regionId: '09', label: '120901030701 (Upper Colorado)' },
  { value: '120901030204', regionId: '09', label: '120901030204 (Upper Colorado)' },
  { value: '120901030205', regionId: '09', label: '120901030205 (Upper Colorado)' },
  { value: '120901030206', regionId: '09', label: '120901030206 (Upper Colorado)' },
  { value: '120901030301', regionId: '09', label: '120901030301 (Upper Colorado)' },
  { value: '120901030302', regionId: '09', label: '120901030302 (Upper Colorado)' },
  { value: '120901030303', regionId: '09', label: '120901030303 (Upper Colorado)' },
  { value: '120901030702', regionId: '09', label: '120901030702 (Upper Colorado)' },
  { value: '120901030703', regionId: '09', label: '120901030703 (Upper Colorado)' },
  { value: '120901030905', regionId: '09', label: '120901030905 (Upper Colorado)' },
  { value: '120901030704', regionId: '09', label: '120901030704 (Upper Colorado)' },
  { value: '120901030705', regionId: '09', label: '120901030705 (Upper Colorado)' },
  { value: '120901030706', regionId: '09', label: '120901030706 (Upper Colorado)' },
  { value: '120901030801', regionId: '09', label: '120901030801 (Upper Colorado)' },
  { value: '120901030304', regionId: '09', label: '120901030304 (Upper Colorado)' },
  { value: '120901030305', regionId: '09', label: '120901030305 (Upper Colorado)' },
  { value: '120901030401', regionId: '09', label: '120901030401 (Upper Colorado)' },
  { value: '120901030402', regionId: '09', label: '120901030402 (Upper Colorado)' },
  { value: '120901030403', regionId: '09', label: '120901030403 (Upper Colorado)' },
  { value: '120901030404', regionId: '09', label: '120901030404 (Upper Colorado)' },
  { value: '120901030501', regionId: '09', label: '120901030501 (Upper Colorado)' },
  { value: '120901030502', regionId: '09', label: '120901030502 (Upper Colorado)' },
  { value: '120901030503', regionId: '09', label: '120901030503 (Upper Colorado)' },
  { value: '120901030504', regionId: '09', label: '120901030504 (Upper Colorado)' },
  { value: '120901030601', regionId: '09', label: '120901030601 (Upper Colorado)' },
  { value: '120901030101', regionId: '09', label: '120901030101 (Upper Colorado)' },
  { value: '120901030102', regionId: '09', label: '120901030102 (Upper Colorado)' },
  { value: '120901030103', regionId: '09', label: '120901030103 (Upper Colorado)' },
  { value: '120901030104', regionId: '09', label: '120901030104 (Upper Colorado)' },
  { value: '120901030201', regionId: '09', label: '120901030201 (Upper Colorado)' },
  { value: '120901030202', regionId: '09', label: '120901030202 (Upper Colorado)' },
  { value: '120901030602', regionId: '09', label: '120901030602 (Upper Colorado)' },
  { value: '120901030804', regionId: '09', label: '120901030804 (Upper Colorado)' },
  { value: '120901030901', regionId: '09', label: '120901030901 (Upper Colorado)' },
  { value: '120901030902', regionId: '09', label: '120901030902 (Upper Colorado)' },
  { value: '120901030802', regionId: '09', label: '120901030802 (Upper Colorado)' },
  { value: '120901030803', regionId: '09', label: '120901030803 (Upper Colorado)' },
  { value: '120901030903', regionId: '09', label: '120901030903 (Upper Colorado)' },
  { value: '120901030904', regionId: '09', label: '120901030904 (Upper Colorado)' },
  { value: '120901030906', regionId: '09', label: '120901030906 (Upper Colorado)' },
  { value: '120901030907', regionId: '09', label: '120901030907 (Upper Colorado)' },
  { value: '120901030908', regionId: '09', label: '120901030908 (Upper Colorado)' },
  { value: '120901030909', regionId: '09', label: '120901030909 (Upper Colorado)' },
  { value: '120901031101', regionId: '09', label: '120901031101 (Upper Colorado)' },
  { value: '120901031001', regionId: '09', label: '120901031001 (Upper Colorado)' },
  { value: '120901031002', regionId: '09', label: '120901031002 (Upper Colorado)' },
  { value: '120901031003', regionId: '09', label: '120901031003 (Upper Colorado)' },
  { value: '120901031004', regionId: '09', label: '120901031004 (Upper Colorado)' },
  { value: '120901031005', regionId: '09', label: '120901031005 (Upper Colorado)' },
  { value: '120901031006', regionId: '09', label: '120901031006 (Upper Colorado)' },
  { value: '120901031102', regionId: '09', label: '120901031102 (Upper Colorado)' },
  { value: '120901031103', regionId: '09', label: '120901031103 (Upper Colorado)' },
  { value: '120901031104', regionId: '09', label: '120901031104 (Upper Colorado)' },
  { value: '120901031105', regionId: '09', label: '120901031105 (Upper Colorado)' },
  { value: '120901040304', regionId: '09', label: '120901040304 (Upper Colorado)' },
  { value: '120901040404', regionId: '09', label: '120901040404 (Upper Colorado)' },
  { value: '120901040406', regionId: '09', label: '120901040406 (Upper Colorado)' },
  { value: '120901040103', regionId: '09', label: '120901040103 (Upper Colorado)' },
  { value: '120901040106', regionId: '09', label: '120901040106 (Upper Colorado)' },
  { value: '120901040108', regionId: '09', label: '120901040108 (Upper Colorado)' },
  { value: '120901040201', regionId: '09', label: '120901040201 (Upper Colorado)' },
  { value: '120901040202', regionId: '09', label: '120901040202 (Upper Colorado)' },
  { value: '120901040205', regionId: '09', label: '120901040205 (Upper Colorado)' },
  { value: '120901040101', regionId: '09', label: '120901040101 (Upper Colorado)' },
  { value: '120901040102', regionId: '09', label: '120901040102 (Upper Colorado)' },
  { value: '120901040104', regionId: '09', label: '120901040104 (Upper Colorado)' },
  { value: '120901040105', regionId: '09', label: '120901040105 (Upper Colorado)' },
  { value: '120901040107', regionId: '09', label: '120901040107 (Upper Colorado)' },
  { value: '120901040203', regionId: '09', label: '120901040203 (Upper Colorado)' },
  { value: '120901040204', regionId: '09', label: '120901040204 (Upper Colorado)' },
  { value: '120901040206', regionId: '09', label: '120901040206 (Upper Colorado)' },
  { value: '120901040302', regionId: '09', label: '120901040302 (Upper Colorado)' },
  { value: '120901040207', regionId: '09', label: '120901040207 (Upper Colorado)' },
  { value: '120901040303', regionId: '09', label: '120901040303 (Upper Colorado)' },
  { value: '120901040301', regionId: '09', label: '120901040301 (Upper Colorado)' },
  { value: '120901040305', regionId: '09', label: '120901040305 (Upper Colorado)' },
  { value: '120901040306', regionId: '09', label: '120901040306 (Upper Colorado)' },
  { value: '120901040401', regionId: '09', label: '120901040401 (Upper Colorado)' },
  { value: '120901040402', regionId: '09', label: '120901040402 (Upper Colorado)' },
  { value: '120901040403', regionId: '09', label: '120901040403 (Upper Colorado)' },
  { value: '120901040405', regionId: '09', label: '120901040405 (Upper Colorado)' },
  { value: '120901040407', regionId: '09', label: '120901040407 (Upper Colorado)' },
  { value: '120901040504', regionId: '09', label: '120901040504 (Upper Colorado)' },
  { value: '120901040408', regionId: '09', label: '120901040408 (Upper Colorado)' },
  { value: '120901040501', regionId: '09', label: '120901040501 (Upper Colorado)' },
  { value: '120901040507', regionId: '09', label: '120901040507 (Upper Colorado)' },
  { value: '120901040502', regionId: '09', label: '120901040502 (Upper Colorado)' },
  { value: '120901040503', regionId: '09', label: '120901040503 (Upper Colorado)' },
  { value: '120901040505', regionId: '09', label: '120901040505 (Upper Colorado)' },
  { value: '120901040506', regionId: '09', label: '120901040506 (Upper Colorado)' },
  { value: '120901040508', regionId: '09', label: '120901040508 (Upper Colorado)' },
  { value: '120901040509', regionId: '09', label: '120901040509 (Upper Colorado)' },
  { value: '120901040510', regionId: '09', label: '120901040510 (Upper Colorado)' },
  { value: '120901050102', regionId: '09', label: '120901050102 (Upper Colorado)' },
  { value: '120901050205', regionId: '09', label: '120901050205 (Upper Colorado)' },
  { value: '120901050101', regionId: '09', label: '120901050101 (Upper Colorado)' },
  { value: '120901050103', regionId: '09', label: '120901050103 (Upper Colorado)' },
  { value: '120901050104', regionId: '09', label: '120901050104 (Upper Colorado)' },
  { value: '120901050105', regionId: '09', label: '120901050105 (Upper Colorado)' },
  { value: '120901050106', regionId: '09', label: '120901050106 (Upper Colorado)' },
  { value: '120901050107', regionId: '09', label: '120901050107 (Upper Colorado)' },
  { value: '120901050201', regionId: '09', label: '120901050201 (Upper Colorado)' },
  { value: '120901050203', regionId: '09', label: '120901050203 (Upper Colorado)' },
  { value: '120901050202', regionId: '09', label: '120901050202 (Upper Colorado)' },
  { value: '120901050204', regionId: '09', label: '120901050204 (Upper Colorado)' },
  { value: '120901050206', regionId: '09', label: '120901050206 (Upper Colorado)' },
  { value: '120901050207', regionId: '09', label: '120901050207 (Upper Colorado)' },
  { value: '120901050301', regionId: '09', label: '120901050301 (Upper Colorado)' },
  { value: '120901050404', regionId: '09', label: '120901050404 (Upper Colorado)' },
  { value: '120901050302', regionId: '09', label: '120901050302 (Upper Colorado)' },
  { value: '120901050406', regionId: '09', label: '120901050406 (Upper Colorado)' },
  { value: '120901050303', regionId: '09', label: '120901050303 (Upper Colorado)' },
  { value: '120901050304', regionId: '09', label: '120901050304 (Upper Colorado)' },
  { value: '120901050305', regionId: '09', label: '120901050305 (Upper Colorado)' },
  { value: '120901050306', regionId: '09', label: '120901050306 (Upper Colorado)' },
  { value: '120901050307', regionId: '09', label: '120901050307 (Upper Colorado)' },
  { value: '120901050308', regionId: '09', label: '120901050308 (Upper Colorado)' },
  { value: '120901050401', regionId: '09', label: '120901050401 (Upper Colorado)' },
  { value: '120901050402', regionId: '09', label: '120901050402 (Upper Colorado)' },
  { value: '120901050403', regionId: '09', label: '120901050403 (Upper Colorado)' },
  { value: '120901050405', regionId: '09', label: '120901050405 (Upper Colorado)' },
  { value: '120901050407', regionId: '09', label: '120901050407 (Upper Colorado)' },
  { value: '120901060101', regionId: '09', label: '120901060101 (Upper Colorado)' },
  { value: '120901060102', regionId: '09', label: '120901060102 (Upper Colorado)' },
  { value: '120901060104', regionId: '09', label: '120901060104 (Upper Colorado)' },
  { value: '120901060105', regionId: '09', label: '120901060105 (Upper Colorado)' },
  { value: '120901060106', regionId: '09', label: '120901060106 (Upper Colorado)' },
  { value: '120901060107', regionId: '09', label: '120901060107 (Upper Colorado)' },
  { value: '120901080101', regionId: '09', label: '120901080101 (Upper Colorado)' },
  { value: '120901080102', regionId: '09', label: '120901080102 (Upper Colorado)' },
  { value: '120901080104', regionId: '09', label: '120901080104 (Upper Colorado)' },
  { value: '120901080201', regionId: '09', label: '120901080201 (Upper Colorado)' },
  { value: '120901090101', regionId: '09', label: '120901090101 (Upper Colorado)' },
  { value: '120901090102', regionId: '09', label: '120901090102 (Upper Colorado)' },
  { value: '120901090103', regionId: '09', label: '120901090103 (Upper Colorado)' },
  { value: '120901090104', regionId: '09', label: '120901090104 (Upper Colorado)' },
  { value: '120901090105', regionId: '09', label: '120901090105 (Upper Colorado)' },
  { value: '120901090107', regionId: '09', label: '120901090107 (Upper Colorado)' },
  { value: '120901090306', regionId: '09', label: '120901090306 (Upper Colorado)' },
  { value: '120901090401', regionId: '09', label: '120901090401 (Upper Colorado)' },
  { value: '120901100101', regionId: '09', label: '120901100101 (Upper Colorado)' },
  { value: '120901100102', regionId: '09', label: '120901100102 (Upper Colorado)' },
  { value: '120901100103', regionId: '09', label: '120901100103 (Upper Colorado)' },
  { value: '120901100104', regionId: '09', label: '120901100104 (Upper Colorado)' },
  { value: '130403010102', regionId: '09', label: '130403010102 (Upper Colorado)' },
  { value: '130403010104', regionId: '09', label: '130403010104 (Upper Colorado)' },
  { value: '130403010105', regionId: '09', label: '130403010105 (Upper Colorado)' },
  { value: '130403010107', regionId: '09', label: '130403010107 (Upper Colorado)' },
  { value: '130403010202', regionId: '09', label: '130403010202 (Upper Colorado)' },
  { value: '130403010401', regionId: '09', label: '130403010401 (Upper Colorado)' },
  { value: '130403010402', regionId: '09', label: '130403010402 (Upper Colorado)' },
  { value: '130403010101', regionId: '09', label: '130403010101 (Upper Colorado)' },
  { value: '130700080101', regionId: '09', label: '130700080101 (Upper Colorado)' },
  { value: '130700080103', regionId: '09', label: '130700080103 (Upper Colorado)' },
  { value: '130700080105', regionId: '09', label: '130700080105 (Upper Colorado)' },
  { value: '130700080106', regionId: '09', label: '130700080106 (Upper Colorado)' },
  { value: '130700080502', regionId: '09', label: '130700080502 (Upper Colorado)' },
  { value: '130700080503', regionId: '09', label: '130700080503 (Upper Colorado)' },
  { value: '130700110101', regionId: '09', label: '130700110101 (Upper Colorado)' },
  { value: '130700110102', regionId: '09', label: '130700110102 (Upper Colorado)' },
  { value: '130700110104', regionId: '09', label: '130700110104 (Upper Colorado)' },
  { value: '130700110105', regionId: '09', label: '130700110105 (Upper Colorado)' },
  { value: '120500010902', regionId: '09', label: '120500010902 (Upper Colorado)' },
  { value: '120500010905', regionId: '09', label: '120500010905 (Upper Colorado)' },
  { value: '120800010107', regionId: '09', label: '120800010107 (Upper Colorado)' },
  { value: '120800010109', regionId: '09', label: '120800010109 (Upper Colorado)' },
  { value: '120800010207', regionId: '09', label: '120800010207 (Upper Colorado)' },
  { value: '120800010208', regionId: '09', label: '120800010208 (Upper Colorado)' },
  { value: '120800010401', regionId: '09', label: '120800010401 (Upper Colorado)' },
  { value: '120800010106', regionId: '09', label: '120800010106 (Upper Colorado)' },
  { value: '120800010108', regionId: '09', label: '120800010108 (Upper Colorado)' },
  { value: '120800010110', regionId: '09', label: '120800010110 (Upper Colorado)' },
  { value: '120800010209', regionId: '09', label: '120800010209 (Upper Colorado)' },
  { value: '120800010210', regionId: '09', label: '120800010210 (Upper Colorado)' },
  { value: '120800010211', regionId: '09', label: '120800010211 (Upper Colorado)' },
  { value: '120800010305', regionId: '09', label: '120800010305 (Upper Colorado)' },
  { value: '120800010306', regionId: '09', label: '120800010306 (Upper Colorado)' },
  { value: '120800010307', regionId: '09', label: '120800010307 (Upper Colorado)' },
  { value: '120800010301', regionId: '09', label: '120800010301 (Upper Colorado)' },
  { value: '120800010304', regionId: '09', label: '120800010304 (Upper Colorado)' },
  { value: '120800010302', regionId: '09', label: '120800010302 (Upper Colorado)' },
  { value: '120800010303', regionId: '09', label: '120800010303 (Upper Colorado)' },
  { value: '120800010308', regionId: '09', label: '120800010308 (Upper Colorado)' },
  { value: '120800010309', regionId: '09', label: '120800010309 (Upper Colorado)' },
  { value: '120800010310', regionId: '09', label: '120800010310 (Upper Colorado)' },
  { value: '120800010402', regionId: '09', label: '120800010402 (Upper Colorado)' },
  { value: '120800010403', regionId: '09', label: '120800010403 (Upper Colorado)' },
  { value: '120800010404', regionId: '09', label: '120800010404 (Upper Colorado)' },
  { value: '120800010405', regionId: '09', label: '120800010405 (Upper Colorado)' },
  { value: '120800010407', regionId: '09', label: '120800010407 (Upper Colorado)' },
  { value: '120800010406', regionId: '09', label: '120800010406 (Upper Colorado)' },
  { value: '120800010408', regionId: '09', label: '120800010408 (Upper Colorado)' },
  { value: '120800010501', regionId: '09', label: '120800010501 (Upper Colorado)' },
  { value: '120800010409', regionId: '09', label: '120800010409 (Upper Colorado)' },
  { value: '120800010502', regionId: '09', label: '120800010502 (Upper Colorado)' },
  { value: '120800010503', regionId: '09', label: '120800010503 (Upper Colorado)' },
  { value: '120800030101', regionId: '09', label: '120800030101 (Upper Colorado)' },
  { value: '120800030103', regionId: '09', label: '120800030103 (Upper Colorado)' },
  { value: '120800030206', regionId: '09', label: '120800030206 (Upper Colorado)' },
  { value: '120800030207', regionId: '09', label: '120800030207 (Upper Colorado)' },
  { value: '120800030209', regionId: '09', label: '120800030209 (Upper Colorado)' },
  { value: '120800030304', regionId: '09', label: '120800030304 (Upper Colorado)' },
  { value: '120800030305', regionId: '09', label: '120800030305 (Upper Colorado)' },
  { value: '120800030306', regionId: '09', label: '120800030306 (Upper Colorado)' },
  { value: '120800030402', regionId: '09', label: '120800030402 (Upper Colorado)' },
  { value: '120800030405', regionId: '09', label: '120800030405 (Upper Colorado)' },
  { value: '120800030611', regionId: '09', label: '120800030611 (Upper Colorado)' },
  { value: '120800030703', regionId: '09', label: '120800030703 (Upper Colorado)' },
  { value: '120800030704', regionId: '09', label: '120800030704 (Upper Colorado)' },
  { value: '120800030307', regionId: '09', label: '120800030307 (Upper Colorado)' },
  { value: '120800030406', regionId: '09', label: '120800030406 (Upper Colorado)' },
  { value: '120800030407', regionId: '09', label: '120800030407 (Upper Colorado)' },
  { value: '120800030501', regionId: '09', label: '120800030501 (Upper Colorado)' },
  { value: '120800030502', regionId: '09', label: '120800030502 (Upper Colorado)' },
  { value: '120800030503', regionId: '09', label: '120800030503 (Upper Colorado)' },
  { value: '120800030801', regionId: '09', label: '120800030801 (Upper Colorado)' },
  { value: '120800030802', regionId: '09', label: '120800030802 (Upper Colorado)' },
  { value: '120800030803', regionId: '09', label: '120800030803 (Upper Colorado)' },
  { value: '120800030804', regionId: '09', label: '120800030804 (Upper Colorado)' },
  { value: '120800030805', regionId: '09', label: '120800030805 (Upper Colorado)' },
  { value: '120800030806', regionId: '09', label: '120800030806 (Upper Colorado)' },
  { value: '120800030807', regionId: '09', label: '120800030807 (Upper Colorado)' },
  { value: '120800030901', regionId: '09', label: '120800030901 (Upper Colorado)' },
  { value: '120800030902', regionId: '09', label: '120800030902 (Upper Colorado)' },
  { value: '120800030903', regionId: '09', label: '120800030903 (Upper Colorado)' },
  { value: '120800030904', regionId: '09', label: '120800030904 (Upper Colorado)' },
  { value: '120800030905', regionId: '09', label: '120800030905 (Upper Colorado)' },
  { value: '120800030906', regionId: '09', label: '120800030906 (Upper Colorado)' },
  { value: '120800030907', regionId: '09', label: '120800030907 (Upper Colorado)' },
  { value: '120800030908', regionId: '09', label: '120800030908 (Upper Colorado)' },
  { value: '120800040203', regionId: '09', label: '120800040203 (Upper Colorado)' },
  { value: '120800040502', regionId: '09', label: '120800040502 (Upper Colorado)' },
  { value: '120800040305', regionId: '09', label: '120800040305 (Upper Colorado)' },
  { value: '120800040306', regionId: '09', label: '120800040306 (Upper Colorado)' },
  { value: '120800040501', regionId: '09', label: '120800040501 (Upper Colorado)' },
  { value: '120800040503', regionId: '09', label: '120800040503 (Upper Colorado)' },
  { value: '120800040504', regionId: '09', label: '120800040504 (Upper Colorado)' },
  { value: '120800040801', regionId: '09', label: '120800040801 (Upper Colorado)' },
  { value: '120800040802', regionId: '09', label: '120800040802 (Upper Colorado)' },
  { value: '120800041005', regionId: '09', label: '120800041005 (Upper Colorado)' },
  { value: '120800040401', regionId: '09', label: '120800040401 (Upper Colorado)' },
  { value: '120800041104', regionId: '09', label: '120800041104 (Upper Colorado)' },
  { value: '120800040804', regionId: '09', label: '120800040804 (Upper Colorado)' },
  { value: '120800040805', regionId: '09', label: '120800040805 (Upper Colorado)' },
  { value: '120800040402', regionId: '09', label: '120800040402 (Upper Colorado)' },
  { value: '120800040403', regionId: '09', label: '120800040403 (Upper Colorado)' },
  { value: '120800040404', regionId: '09', label: '120800040404 (Upper Colorado)' },
  { value: '120800040405', regionId: '09', label: '120800040405 (Upper Colorado)' },
  { value: '120800040406', regionId: '09', label: '120800040406 (Upper Colorado)' },
  { value: '120800040505', regionId: '09', label: '120800040505 (Upper Colorado)' },
  { value: '120800040506', regionId: '09', label: '120800040506 (Upper Colorado)' },
  { value: '120800040507', regionId: '09', label: '120800040507 (Upper Colorado)' },
  { value: '120800040508', regionId: '09', label: '120800040508 (Upper Colorado)' },
  { value: '120800040601', regionId: '09', label: '120800040601 (Upper Colorado)' },
  { value: '120800040602', regionId: '09', label: '120800040602 (Upper Colorado)' },
  { value: '120800040603', regionId: '09', label: '120800040603 (Upper Colorado)' },
  { value: '120800040701', regionId: '09', label: '120800040701 (Upper Colorado)' },
  { value: '120800040702', regionId: '09', label: '120800040702 (Upper Colorado)' },
  { value: '120800040703', regionId: '09', label: '120800040703 (Upper Colorado)' },
  { value: '120800040704', regionId: '09', label: '120800040704 (Upper Colorado)' },
  { value: '120800040705', regionId: '09', label: '120800040705 (Upper Colorado)' },
  { value: '120800040706', regionId: '09', label: '120800040706 (Upper Colorado)' },
  { value: '120800040707', regionId: '09', label: '120800040707 (Upper Colorado)' },
  { value: '120800040803', regionId: '09', label: '120800040803 (Upper Colorado)' },
  { value: '120800040906', regionId: '09', label: '120800040906 (Upper Colorado)' },
  { value: '120800040806', regionId: '09', label: '120800040806 (Upper Colorado)' },
  { value: '120800040807', regionId: '09', label: '120800040807 (Upper Colorado)' },
  { value: '120800040901', regionId: '09', label: '120800040901 (Upper Colorado)' },
  { value: '120800040902', regionId: '09', label: '120800040902 (Upper Colorado)' },
  { value: '120800040903', regionId: '09', label: '120800040903 (Upper Colorado)' },
  { value: '120800040904', regionId: '09', label: '120800040904 (Upper Colorado)' },
  { value: '120800040905', regionId: '09', label: '120800040905 (Upper Colorado)' },
  { value: '120800040907', regionId: '09', label: '120800040907 (Upper Colorado)' },
  { value: '120800041001', regionId: '09', label: '120800041001 (Upper Colorado)' },
  { value: '120800041002', regionId: '09', label: '120800041002 (Upper Colorado)' },
  { value: '120800041003', regionId: '09', label: '120800041003 (Upper Colorado)' },
  { value: '120800041004', regionId: '09', label: '120800041004 (Upper Colorado)' },
  { value: '120800041006', regionId: '09', label: '120800041006 (Upper Colorado)' },
  { value: '120800041007', regionId: '09', label: '120800041007 (Upper Colorado)' },
  { value: '120800041008', regionId: '09', label: '120800041008 (Upper Colorado)' },
  { value: '120800041009', regionId: '09', label: '120800041009 (Upper Colorado)' },
  { value: '120800041010', regionId: '09', label: '120800041010 (Upper Colorado)' },
  { value: '120800041101', regionId: '09', label: '120800041101 (Upper Colorado)' },
  { value: '120800041102', regionId: '09', label: '120800041102 (Upper Colorado)' },
  { value: '120800041103', regionId: '09', label: '120800041103 (Upper Colorado)' },
  { value: '120800041105', regionId: '09', label: '120800041105 (Upper Colorado)' },
  { value: '120800041106', regionId: '09', label: '120800041106 (Upper Colorado)' },
  { value: '120800041107', regionId: '09', label: '120800041107 (Upper Colorado)' },
  { value: '120800060103', regionId: '09', label: '120800060103 (Upper Colorado)' },
  { value: '120800060104', regionId: '09', label: '120800060104 (Upper Colorado)' },
  { value: '120800060206', regionId: '09', label: '120800060206 (Upper Colorado)' },
  { value: '120800060306', regionId: '09', label: '120800060306 (Upper Colorado)' },
  { value: '120800060301', regionId: '09', label: '120800060301 (Upper Colorado)' },
  { value: '120800060302', regionId: '09', label: '120800060302 (Upper Colorado)' },
  { value: '120800060303', regionId: '09', label: '120800060303 (Upper Colorado)' },
  { value: '120800060304', regionId: '09', label: '120800060304 (Upper Colorado)' },
  { value: '120800060305', regionId: '09', label: '120800060305 (Upper Colorado)' },
  { value: '120800060401', regionId: '09', label: '120800060401 (Upper Colorado)' },
  { value: '120800060402', regionId: '09', label: '120800060402 (Upper Colorado)' },
  { value: '120800060403', regionId: '09', label: '120800060403 (Upper Colorado)' },
  { value: '120800060404', regionId: '09', label: '120800060404 (Upper Colorado)' },
  { value: '120800060501', regionId: '09', label: '120800060501 (Upper Colorado)' },
  { value: '120800060502', regionId: '09', label: '120800060502 (Upper Colorado)' },
  { value: '120800060503', regionId: '09', label: '120800060503 (Upper Colorado)' },
  { value: '120800060504', regionId: '09', label: '120800060504 (Upper Colorado)' },
  { value: '120800060505', regionId: '09', label: '120800060505 (Upper Colorado)' },
  { value: '120800060508', regionId: '09', label: '120800060508 (Upper Colorado)' },
  { value: '120800060506', regionId: '09', label: '120800060506 (Upper Colorado)' },
  { value: '120800060507', regionId: '09', label: '120800060507 (Upper Colorado)' },
  { value: '120800060509', regionId: '09', label: '120800060509 (Upper Colorado)' },
  { value: '120800060601', regionId: '09', label: '120800060601 (Upper Colorado)' },
  { value: '120800060602', regionId: '09', label: '120800060602 (Upper Colorado)' },
  { value: '120800060603', regionId: '09', label: '120800060603 (Upper Colorado)' },
  { value: '120800060604', regionId: '09', label: '120800060604 (Upper Colorado)' },
  { value: '120800060701', regionId: '09', label: '120800060701 (Upper Colorado)' },
  { value: '120800060702', regionId: '09', label: '120800060702 (Upper Colorado)' },
  { value: '120800060801', regionId: '09', label: '120800060801 (Upper Colorado)' },
  { value: '120800060703', regionId: '09', label: '120800060703 (Upper Colorado)' },
  { value: '120800060704', regionId: '09', label: '120800060704 (Upper Colorado)' },
  { value: '120800060705', regionId: '09', label: '120800060705 (Upper Colorado)' },
  { value: '120800060802', regionId: '09', label: '120800060802 (Upper Colorado)' },
  { value: '120800060803', regionId: '09', label: '120800060803 (Upper Colorado)' },
  { value: '120800060804', regionId: '09', label: '120800060804 (Upper Colorado)' },
  { value: '120800060805', regionId: '09', label: '120800060805 (Upper Colorado)' },
  { value: '120800060806', regionId: '09', label: '120800060806 (Upper Colorado)' },
  { value: '130700070601', regionId: '09', label: '130700070601 (Upper Colorado)' },
  { value: '130700070606', regionId: '09', label: '130700070606 (Upper Colorado)' },
  { value: '130700071102', regionId: '09', label: '130700071102 (Upper Colorado)' },
  { value: '130700070206', regionId: '09', label: '130700070206 (Upper Colorado)' },
  { value: '130700071405', regionId: '09', label: '130700071405 (Upper Colorado)' },
  { value: '130700070602', regionId: '09', label: '130700070602 (Upper Colorado)' },
  { value: '130700070604', regionId: '09', label: '130700070604 (Upper Colorado)' },
  { value: '130700071001', regionId: '09', label: '130700071001 (Upper Colorado)' },
  { value: '130700071002', regionId: '09', label: '130700071002 (Upper Colorado)' },
  { value: '130700071004', regionId: '09', label: '130700071004 (Upper Colorado)' },
  { value: '130700071101', regionId: '09', label: '130700071101 (Upper Colorado)' },
  { value: '130700071402', regionId: '09', label: '130700071402 (Upper Colorado)' },
  { value: '130700071302', regionId: '09', label: '130700071302 (Upper Colorado)' },
  { value: '130700071401', regionId: '09', label: '130700071401 (Upper Colorado)' },
  { value: '130700071501', regionId: '09', label: '130700071501 (Upper Colorado)' },
  { value: '130700071502', regionId: '09', label: '130700071502 (Upper Colorado)' },
  { value: '130700071504', regionId: '09', label: '130700071504 (Upper Colorado)' },
]

const hucsRegion14 = [
  { value: '130600111101', regionId: '14', label: '130600111101 (Upper Rio Grande)' },
  { value: '130600111102', regionId: '14', label: '130600111102 (Upper Rio Grande)' },
  { value: '130600111103', regionId: '14', label: '130600111103 (Upper Rio Grande)' },
  { value: '130600111301', regionId: '14', label: '130600111301 (Upper Rio Grande)' },
  { value: '130301020802', regionId: '14', label: '130301020802 (Upper Rio Grande)' },
  { value: '130301020803', regionId: '14', label: '130301020803 (Upper Rio Grande)' },
  { value: '130301020804', regionId: '14', label: '130301020804 (Upper Rio Grande)' },
  { value: '130301020902', regionId: '14', label: '130301020902 (Upper Rio Grande)' },
  { value: '130301020904', regionId: '14', label: '130301020904 (Upper Rio Grande)' },
  { value: '130301020905', regionId: '14', label: '130301020905 (Upper Rio Grande)' },
  { value: '130301020906', regionId: '14', label: '130301020906 (Upper Rio Grande)' },
  { value: '120800050301', regionId: '14', label: '120800050301 (Upper Rio Grande)' },
  { value: '120800050101', regionId: '14', label: '120800050101 (Upper Rio Grande)' },
  { value: '120800050103', regionId: '14', label: '120800050103 (Upper Rio Grande)' },
  { value: '120800050104', regionId: '14', label: '120800050104 (Upper Rio Grande)' },
  { value: '120800050201', regionId: '14', label: '120800050201 (Upper Rio Grande)' },
  { value: '120800050202', regionId: '14', label: '120800050202 (Upper Rio Grande)' },
  { value: '120800050203', regionId: '14', label: '120800050203 (Upper Rio Grande)' },
  { value: '120800050302', regionId: '14', label: '120800050302 (Upper Rio Grande)' },
  { value: '120800050303', regionId: '14', label: '120800050303 (Upper Rio Grande)' },
  { value: '120800050307', regionId: '14', label: '120800050307 (Upper Rio Grande)' },
  { value: '120800050401', regionId: '14', label: '120800050401 (Upper Rio Grande)' },
  { value: '120901020401', regionId: '14', label: '120901020401 (Upper Rio Grande)' },
  { value: '120901020101', regionId: '14', label: '120901020101 (Upper Rio Grande)' },
  { value: '120901020402', regionId: '14', label: '120901020402 (Upper Rio Grande)' },
  { value: '120901020105', regionId: '14', label: '120901020105 (Upper Rio Grande)' },
  { value: '120901020104', regionId: '14', label: '120901020104 (Upper Rio Grande)' },
  { value: '120901020301', regionId: '14', label: '120901020301 (Upper Rio Grande)' },
  { value: '120901030301', regionId: '14', label: '120901030301 (Upper Rio Grande)' },
  { value: '120901030302', regionId: '14', label: '120901030302 (Upper Rio Grande)' },
  { value: '120901030304', regionId: '14', label: '120901030304 (Upper Rio Grande)' },
  { value: '120901030601', regionId: '14', label: '120901030601 (Upper Rio Grande)' },
  { value: '120901030101', regionId: '14', label: '120901030101 (Upper Rio Grande)' },
  { value: '120901030102', regionId: '14', label: '120901030102 (Upper Rio Grande)' },
  { value: '120901030103', regionId: '14', label: '120901030103 (Upper Rio Grande)' },
  { value: '120901030602', regionId: '14', label: '120901030602 (Upper Rio Grande)' },
  { value: '120901030901', regionId: '14', label: '120901030901 (Upper Rio Grande)' },
  { value: '120901090101', regionId: '14', label: '120901090101 (Upper Rio Grande)' },
  { value: '120901090201', regionId: '14', label: '120901090201 (Upper Rio Grande)' },
  { value: '120902020106', regionId: '14', label: '120902020106 (Upper Rio Grande)' },
  { value: '120902020101', regionId: '14', label: '120902020101 (Upper Rio Grande)' },
  { value: '120902020102', regionId: '14', label: '120902020102 (Upper Rio Grande)' },
  { value: '121101020101', regionId: '14', label: '121101020101 (Upper Rio Grande)' },
  { value: '121101020103', regionId: '14', label: '121101020103 (Upper Rio Grande)' },
  { value: '121101020104', regionId: '14', label: '121101020104 (Upper Rio Grande)' },
  { value: '121101020105', regionId: '14', label: '121101020105 (Upper Rio Grande)' },
  { value: '130401000101', regionId: '14', label: '130401000101 (Upper Rio Grande)' },
  { value: '130401000102', regionId: '14', label: '130401000102 (Upper Rio Grande)' },
  { value: '130401000103', regionId: '14', label: '130401000103 (Upper Rio Grande)' },
  { value: '130401000107', regionId: '14', label: '130401000107 (Upper Rio Grande)' },
  { value: '130401000201', regionId: '14', label: '130401000201 (Upper Rio Grande)' },
  { value: '130401000202', regionId: '14', label: '130401000202 (Upper Rio Grande)' },
  { value: '130401000203', regionId: '14', label: '130401000203 (Upper Rio Grande)' },
  { value: '130401000204', regionId: '14', label: '130401000204 (Upper Rio Grande)' },
  { value: '130401000303', regionId: '14', label: '130401000303 (Upper Rio Grande)' },
  { value: '130401000304', regionId: '14', label: '130401000304 (Upper Rio Grande)' },
  { value: '130401000305', regionId: '14', label: '130401000305 (Upper Rio Grande)' },
  { value: '130401000306', regionId: '14', label: '130401000306 (Upper Rio Grande)' },
  { value: '130401000401', regionId: '14', label: '130401000401 (Upper Rio Grande)' },
  { value: '130401000402', regionId: '14', label: '130401000402 (Upper Rio Grande)' },
  { value: '130401000403', regionId: '14', label: '130401000403 (Upper Rio Grande)' },
  { value: '130401000404', regionId: '14', label: '130401000404 (Upper Rio Grande)' },
  { value: '130401000405', regionId: '14', label: '130401000405 (Upper Rio Grande)' },
  { value: '130401000901', regionId: '14', label: '130401000901 (Upper Rio Grande)' },
  { value: '130401000902', regionId: '14', label: '130401000902 (Upper Rio Grande)' },
  { value: '130401000903', regionId: '14', label: '130401000903 (Upper Rio Grande)' },
  { value: '130401000904', regionId: '14', label: '130401000904 (Upper Rio Grande)' },
  { value: '130401000905', regionId: '14', label: '130401000905 (Upper Rio Grande)' },
  { value: '130401000909', regionId: '14', label: '130401000909 (Upper Rio Grande)' },
  { value: '130401000701', regionId: '14', label: '130401000701 (Upper Rio Grande)' },
  { value: '130401000702', regionId: '14', label: '130401000702 (Upper Rio Grande)' },
  { value: '130401000703', regionId: '14', label: '130401000703 (Upper Rio Grande)' },
  { value: '130401000704', regionId: '14', label: '130401000704 (Upper Rio Grande)' },
  { value: '130401000705', regionId: '14', label: '130401000705 (Upper Rio Grande)' },
  { value: '130401000706', regionId: '14', label: '130401000706 (Upper Rio Grande)' },
  { value: '130401000707', regionId: '14', label: '130401000707 (Upper Rio Grande)' },
  { value: '130401000801', regionId: '14', label: '130401000801 (Upper Rio Grande)' },
  { value: '130401000802', regionId: '14', label: '130401000802 (Upper Rio Grande)' },
  { value: '130401000803', regionId: '14', label: '130401000803 (Upper Rio Grande)' },
  { value: '130401000809', regionId: '14', label: '130401000809 (Upper Rio Grande)' },
  { value: '130401000408', regionId: '14', label: '130401000408 (Upper Rio Grande)' },
  { value: '130401000307', regionId: '14', label: '130401000307 (Upper Rio Grande)' },
  { value: '130401000410', regionId: '14', label: '130401000410 (Upper Rio Grande)' },
  { value: '130402010204', regionId: '14', label: '130402010204 (Upper Rio Grande)' },
  { value: '130402010804', regionId: '14', label: '130402010804 (Upper Rio Grande)' },
  { value: '130402010807', regionId: '14', label: '130402010807 (Upper Rio Grande)' },
  { value: '130402010808', regionId: '14', label: '130402010808 (Upper Rio Grande)' },
  { value: '130402010809', regionId: '14', label: '130402010809 (Upper Rio Grande)' },
  { value: '130402010810', regionId: '14', label: '130402010810 (Upper Rio Grande)' },
  { value: '130402010901', regionId: '14', label: '130402010901 (Upper Rio Grande)' },
  { value: '130402010902', regionId: '14', label: '130402010902 (Upper Rio Grande)' },
  { value: '130402010903', regionId: '14', label: '130402010903 (Upper Rio Grande)' },
  { value: '130402011004', regionId: '14', label: '130402011004 (Upper Rio Grande)' },
  { value: '130402011006', regionId: '14', label: '130402011006 (Upper Rio Grande)' },
  { value: '130402010102', regionId: '14', label: '130402010102 (Upper Rio Grande)' },
  { value: '130402010103', regionId: '14', label: '130402010103 (Upper Rio Grande)' },
  { value: '130402010106', regionId: '14', label: '130402010106 (Upper Rio Grande)' },
  { value: '130402010203', regionId: '14', label: '130402010203 (Upper Rio Grande)' },
  { value: '130402010301', regionId: '14', label: '130402010301 (Upper Rio Grande)' },
  { value: '130402010302', regionId: '14', label: '130402010302 (Upper Rio Grande)' },
  { value: '130402010303', regionId: '14', label: '130402010303 (Upper Rio Grande)' },
  { value: '130402010304', regionId: '14', label: '130402010304 (Upper Rio Grande)' },
  { value: '130402010305', regionId: '14', label: '130402010305 (Upper Rio Grande)' },
  { value: '130402010306', regionId: '14', label: '130402010306 (Upper Rio Grande)' },
  { value: '130402010307', regionId: '14', label: '130402010307 (Upper Rio Grande)' },
  { value: '130402010308', regionId: '14', label: '130402010308 (Upper Rio Grande)' },
  { value: '130402010601', regionId: '14', label: '130402010601 (Upper Rio Grande)' },
  { value: '130402010604', regionId: '14', label: '130402010604 (Upper Rio Grande)' },
  { value: '130402010501', regionId: '14', label: '130402010501 (Upper Rio Grande)' },
  { value: '130402010502', regionId: '14', label: '130402010502 (Upper Rio Grande)' },
  { value: '130402010605', regionId: '14', label: '130402010605 (Upper Rio Grande)' },
  { value: '130402010701', regionId: '14', label: '130402010701 (Upper Rio Grande)' },
  { value: '130402010702', regionId: '14', label: '130402010702 (Upper Rio Grande)' },
  { value: '130402010703', regionId: '14', label: '130402010703 (Upper Rio Grande)' },
  { value: '130402010801', regionId: '14', label: '130402010801 (Upper Rio Grande)' },
  { value: '130402010802', regionId: '14', label: '130402010802 (Upper Rio Grande)' },
  { value: '130402010803', regionId: '14', label: '130402010803 (Upper Rio Grande)' },
  { value: '130402011102', regionId: '14', label: '130402011102 (Upper Rio Grande)' },
  { value: '130402011106', regionId: '14', label: '130402011106 (Upper Rio Grande)' },
  { value: '130402011108', regionId: '14', label: '130402011108 (Upper Rio Grande)' },
  { value: '130402011301', regionId: '14', label: '130402011301 (Upper Rio Grande)' },
  { value: '130402011305', regionId: '14', label: '130402011305 (Upper Rio Grande)' },
  { value: '130402011306', regionId: '14', label: '130402011306 (Upper Rio Grande)' },
  { value: '130402011307', regionId: '14', label: '130402011307 (Upper Rio Grande)' },
  { value: '130402011205', regionId: '14', label: '130402011205 (Upper Rio Grande)' },
  { value: '130402011207', regionId: '14', label: '130402011207 (Upper Rio Grande)' },
  { value: '130402011401', regionId: '14', label: '130402011401 (Upper Rio Grande)' },
  { value: '130402011402', regionId: '14', label: '130402011402 (Upper Rio Grande)' },
  { value: '130402011403', regionId: '14', label: '130402011403 (Upper Rio Grande)' },
  { value: '130402011302', regionId: '14', label: '130402011302 (Upper Rio Grande)' },
  { value: '130402011303', regionId: '14', label: '130402011303 (Upper Rio Grande)' },
  { value: '130402011304', regionId: '14', label: '130402011304 (Upper Rio Grande)' },
  { value: '130402011405', regionId: '14', label: '130402011405 (Upper Rio Grande)' },
  { value: '130402010205', regionId: '14', label: '130402010205 (Upper Rio Grande)' },
  { value: '130402011105', regionId: '14', label: '130402011105 (Upper Rio Grande)' },
  { value: '130402011406', regionId: '14', label: '130402011406 (Upper Rio Grande)' },
  { value: '130402020106', regionId: '14', label: '130402020106 (Upper Rio Grande)' },
  { value: '130402020101', regionId: '14', label: '130402020101 (Upper Rio Grande)' },
  { value: '130402020102', regionId: '14', label: '130402020102 (Upper Rio Grande)' },
  { value: '130402020103', regionId: '14', label: '130402020103 (Upper Rio Grande)' },
  { value: '130402020104', regionId: '14', label: '130402020104 (Upper Rio Grande)' },
  { value: '130402020105', regionId: '14', label: '130402020105 (Upper Rio Grande)' },
  { value: '130402020107', regionId: '14', label: '130402020107 (Upper Rio Grande)' },
  { value: '130402020108', regionId: '14', label: '130402020108 (Upper Rio Grande)' },
  { value: '130402020109', regionId: '14', label: '130402020109 (Upper Rio Grande)' },
  { value: '130402020201', regionId: '14', label: '130402020201 (Upper Rio Grande)' },
  { value: '130402020202', regionId: '14', label: '130402020202 (Upper Rio Grande)' },
  { value: '130402020203', regionId: '14', label: '130402020203 (Upper Rio Grande)' },
  { value: '130402020204', regionId: '14', label: '130402020204 (Upper Rio Grande)' },
  { value: '130402020205', regionId: '14', label: '130402020205 (Upper Rio Grande)' },
  { value: '130402020302', regionId: '14', label: '130402020302 (Upper Rio Grande)' },
  { value: '130402020206', regionId: '14', label: '130402020206 (Upper Rio Grande)' },
  { value: '130402020207', regionId: '14', label: '130402020207 (Upper Rio Grande)' },
  { value: '130402020208', regionId: '14', label: '130402020208 (Upper Rio Grande)' },
  { value: '130402020301', regionId: '14', label: '130402020301 (Upper Rio Grande)' },
  { value: '130402020303', regionId: '14', label: '130402020303 (Upper Rio Grande)' },
  { value: '130402020304', regionId: '14', label: '130402020304 (Upper Rio Grande)' },
  { value: '130402020305', regionId: '14', label: '130402020305 (Upper Rio Grande)' },
  { value: '130402020306', regionId: '14', label: '130402020306 (Upper Rio Grande)' },
  { value: '130402020307', regionId: '14', label: '130402020307 (Upper Rio Grande)' },
  { value: '130402020308', regionId: '14', label: '130402020308 (Upper Rio Grande)' },
  { value: '130402020309', regionId: '14', label: '130402020309 (Upper Rio Grande)' },
  { value: '130402020310', regionId: '14', label: '130402020310 (Upper Rio Grande)' },
  { value: '130402020311', regionId: '14', label: '130402020311 (Upper Rio Grande)' },
  { value: '130402020401', regionId: '14', label: '130402020401 (Upper Rio Grande)' },
  { value: '130402020402', regionId: '14', label: '130402020402 (Upper Rio Grande)' },
  { value: '130402020403', regionId: '14', label: '130402020403 (Upper Rio Grande)' },
  { value: '130402020501', regionId: '14', label: '130402020501 (Upper Rio Grande)' },
  { value: '130402020502', regionId: '14', label: '130402020502 (Upper Rio Grande)' },
  { value: '130402020503', regionId: '14', label: '130402020503 (Upper Rio Grande)' },
  { value: '130402020504', regionId: '14', label: '130402020504 (Upper Rio Grande)' },
  { value: '130402020505', regionId: '14', label: '130402020505 (Upper Rio Grande)' },
  { value: '130402020506', regionId: '14', label: '130402020506 (Upper Rio Grande)' },
  { value: '130402020507', regionId: '14', label: '130402020507 (Upper Rio Grande)' },
  { value: '130402030106', regionId: '14', label: '130402030106 (Upper Rio Grande)' },
  { value: '130402030107', regionId: '14', label: '130402030107 (Upper Rio Grande)' },
  { value: '130402030701', regionId: '14', label: '130402030701 (Upper Rio Grande)' },
  { value: '130402030705', regionId: '14', label: '130402030705 (Upper Rio Grande)' },
  { value: '130402030707', regionId: '14', label: '130402030707 (Upper Rio Grande)' },
  { value: '130402031501', regionId: '14', label: '130402031501 (Upper Rio Grande)' },
  { value: '130402031502', regionId: '14', label: '130402031502 (Upper Rio Grande)' },
  { value: '130402031503', regionId: '14', label: '130402031503 (Upper Rio Grande)' },
  { value: '130402031504', regionId: '14', label: '130402031504 (Upper Rio Grande)' },
  { value: '130402031505', regionId: '14', label: '130402031505 (Upper Rio Grande)' },
  { value: '130402030101', regionId: '14', label: '130402030101 (Upper Rio Grande)' },
  { value: '130402030102', regionId: '14', label: '130402030102 (Upper Rio Grande)' },
  { value: '130402030103', regionId: '14', label: '130402030103 (Upper Rio Grande)' },
  { value: '130402030104', regionId: '14', label: '130402030104 (Upper Rio Grande)' },
  { value: '130402030105', regionId: '14', label: '130402030105 (Upper Rio Grande)' },
  { value: '130402031509', regionId: '14', label: '130402031509 (Upper Rio Grande)' },
  { value: '130402030605', regionId: '14', label: '130402030605 (Upper Rio Grande)' },
  { value: '130402030702', regionId: '14', label: '130402030702 (Upper Rio Grande)' },
  { value: '130402040101', regionId: '14', label: '130402040101 (Upper Rio Grande)' },
  { value: '130402040102', regionId: '14', label: '130402040102 (Upper Rio Grande)' },
  { value: '130402040103', regionId: '14', label: '130402040103 (Upper Rio Grande)' },
  { value: '130402040104', regionId: '14', label: '130402040104 (Upper Rio Grande)' },
  { value: '130402040105', regionId: '14', label: '130402040105 (Upper Rio Grande)' },
  { value: '130402040106', regionId: '14', label: '130402040106 (Upper Rio Grande)' },
  { value: '130402040107', regionId: '14', label: '130402040107 (Upper Rio Grande)' },
  { value: '130402040201', regionId: '14', label: '130402040201 (Upper Rio Grande)' },
  { value: '130402040202', regionId: '14', label: '130402040202 (Upper Rio Grande)' },
  { value: '130402040203', regionId: '14', label: '130402040203 (Upper Rio Grande)' },
  { value: '130402040204', regionId: '14', label: '130402040204 (Upper Rio Grande)' },
  { value: '130402040205', regionId: '14', label: '130402040205 (Upper Rio Grande)' },
  { value: '130402040206', regionId: '14', label: '130402040206 (Upper Rio Grande)' },
  { value: '130402040301', regionId: '14', label: '130402040301 (Upper Rio Grande)' },
  { value: '130402040302', regionId: '14', label: '130402040302 (Upper Rio Grande)' },
  { value: '130402040303', regionId: '14', label: '130402040303 (Upper Rio Grande)' },
  { value: '130402040304', regionId: '14', label: '130402040304 (Upper Rio Grande)' },
  { value: '130402040305', regionId: '14', label: '130402040305 (Upper Rio Grande)' },
  { value: '130402040306', regionId: '14', label: '130402040306 (Upper Rio Grande)' },
  { value: '130402040307', regionId: '14', label: '130402040307 (Upper Rio Grande)' },
  { value: '130402040401', regionId: '14', label: '130402040401 (Upper Rio Grande)' },
  { value: '130402040402', regionId: '14', label: '130402040402 (Upper Rio Grande)' },
  { value: '130402040403', regionId: '14', label: '130402040403 (Upper Rio Grande)' },
  { value: '130402040404', regionId: '14', label: '130402040404 (Upper Rio Grande)' },
  { value: '130402040405', regionId: '14', label: '130402040405 (Upper Rio Grande)' },
  { value: '130402040406', regionId: '14', label: '130402040406 (Upper Rio Grande)' },
  { value: '130402040407', regionId: '14', label: '130402040407 (Upper Rio Grande)' },
  { value: '130402052403', regionId: '14', label: '130402052403 (Upper Rio Grande)' },
  { value: '130402050101', regionId: '14', label: '130402050101 (Upper Rio Grande)' },
  { value: '130402050102', regionId: '14', label: '130402050102 (Upper Rio Grande)' },
  { value: '130402050103', regionId: '14', label: '130402050103 (Upper Rio Grande)' },
  { value: '130402050104', regionId: '14', label: '130402050104 (Upper Rio Grande)' },
  { value: '130402050106', regionId: '14', label: '130402050106 (Upper Rio Grande)' },
  { value: '130402050107', regionId: '14', label: '130402050107 (Upper Rio Grande)' },
  { value: '130402051802', regionId: '14', label: '130402051802 (Upper Rio Grande)' },
  { value: '130402051805', regionId: '14', label: '130402051805 (Upper Rio Grande)' },
  { value: '130402051806', regionId: '14', label: '130402051806 (Upper Rio Grande)' },
  { value: '130402052002', regionId: '14', label: '130402052002 (Upper Rio Grande)' },
  { value: '130402052003', regionId: '14', label: '130402052003 (Upper Rio Grande)' },
  { value: '130402052004', regionId: '14', label: '130402052004 (Upper Rio Grande)' },
  { value: '130402052006', regionId: '14', label: '130402052006 (Upper Rio Grande)' },
  { value: '130402052007', regionId: '14', label: '130402052007 (Upper Rio Grande)' },
  { value: '130402052101', regionId: '14', label: '130402052101 (Upper Rio Grande)' },
  { value: '130402052102', regionId: '14', label: '130402052102 (Upper Rio Grande)' },
  { value: '130402052103', regionId: '14', label: '130402052103 (Upper Rio Grande)' },
  { value: '130402052104', regionId: '14', label: '130402052104 (Upper Rio Grande)' },
  { value: '130402052105', regionId: '14', label: '130402052105 (Upper Rio Grande)' },
  { value: '130402052106', regionId: '14', label: '130402052106 (Upper Rio Grande)' },
  { value: '130402052107', regionId: '14', label: '130402052107 (Upper Rio Grande)' },
  { value: '130402052108', regionId: '14', label: '130402052108 (Upper Rio Grande)' },
  { value: '130402052405', regionId: '14', label: '130402052405 (Upper Rio Grande)' },
  { value: '130402052407', regionId: '14', label: '130402052407 (Upper Rio Grande)' },
  { value: '130402052701', regionId: '14', label: '130402052701 (Upper Rio Grande)' },
  { value: '130402052704', regionId: '14', label: '130402052704 (Upper Rio Grande)' },
  { value: '130402052404', regionId: '14', label: '130402052404 (Upper Rio Grande)' },
  { value: '130402052402', regionId: '14', label: '130402052402 (Upper Rio Grande)' },
  { value: '130402050105', regionId: '14', label: '130402050105 (Upper Rio Grande)' },
  { value: '130402051803', regionId: '14', label: '130402051803 (Upper Rio Grande)' },
  { value: '130402060302', regionId: '14', label: '130402060302 (Upper Rio Grande)' },
  { value: '130402060303', regionId: '14', label: '130402060303 (Upper Rio Grande)' },
  { value: '130402060304', regionId: '14', label: '130402060304 (Upper Rio Grande)' },
  { value: '130402060305', regionId: '14', label: '130402060305 (Upper Rio Grande)' },
  { value: '130402060306', regionId: '14', label: '130402060306 (Upper Rio Grande)' },
  { value: '130402060401', regionId: '14', label: '130402060401 (Upper Rio Grande)' },
  { value: '130402060402', regionId: '14', label: '130402060402 (Upper Rio Grande)' },
  { value: '130402060403', regionId: '14', label: '130402060403 (Upper Rio Grande)' },
  { value: '130402060404', regionId: '14', label: '130402060404 (Upper Rio Grande)' },
  { value: '130402060405', regionId: '14', label: '130402060405 (Upper Rio Grande)' },
  { value: '130402060406', regionId: '14', label: '130402060406 (Upper Rio Grande)' },
  { value: '130402060101', regionId: '14', label: '130402060101 (Upper Rio Grande)' },
  { value: '130402060102', regionId: '14', label: '130402060102 (Upper Rio Grande)' },
  { value: '130402060103', regionId: '14', label: '130402060103 (Upper Rio Grande)' },
  { value: '130402060104', regionId: '14', label: '130402060104 (Upper Rio Grande)' },
  { value: '130402060201', regionId: '14', label: '130402060201 (Upper Rio Grande)' },
  { value: '130402060202', regionId: '14', label: '130402060202 (Upper Rio Grande)' },
  { value: '130402060203', regionId: '14', label: '130402060203 (Upper Rio Grande)' },
  { value: '130402060204', regionId: '14', label: '130402060204 (Upper Rio Grande)' },
  { value: '130402060205', regionId: '14', label: '130402060205 (Upper Rio Grande)' },
  { value: '130402060206', regionId: '14', label: '130402060206 (Upper Rio Grande)' },
  { value: '130402060207', regionId: '14', label: '130402060207 (Upper Rio Grande)' },
  { value: '130402060208', regionId: '14', label: '130402060208 (Upper Rio Grande)' },
  { value: '130402060301', regionId: '14', label: '130402060301 (Upper Rio Grande)' },
  { value: '130402060105', regionId: '14', label: '130402060105 (Upper Rio Grande)' },
  { value: '130402060106', regionId: '14', label: '130402060106 (Upper Rio Grande)' },
  { value: '130402060107', regionId: '14', label: '130402060107 (Upper Rio Grande)' },
  { value: '130402070205', regionId: '14', label: '130402070205 (Upper Rio Grande)' },
  { value: '130402070101', regionId: '14', label: '130402070101 (Upper Rio Grande)' },
  { value: '130402070102', regionId: '14', label: '130402070102 (Upper Rio Grande)' },
  { value: '130402070103', regionId: '14', label: '130402070103 (Upper Rio Grande)' },
  { value: '130402070104', regionId: '14', label: '130402070104 (Upper Rio Grande)' },
  { value: '130402070105', regionId: '14', label: '130402070105 (Upper Rio Grande)' },
  { value: '130402070106', regionId: '14', label: '130402070106 (Upper Rio Grande)' },
  { value: '130402070201', regionId: '14', label: '130402070201 (Upper Rio Grande)' },
  { value: '130402070202', regionId: '14', label: '130402070202 (Upper Rio Grande)' },
  { value: '130402070203', regionId: '14', label: '130402070203 (Upper Rio Grande)' },
  { value: '130402070204', regionId: '14', label: '130402070204 (Upper Rio Grande)' },
  { value: '130402070206', regionId: '14', label: '130402070206 (Upper Rio Grande)' },
  { value: '130402070301', regionId: '14', label: '130402070301 (Upper Rio Grande)' },
  { value: '130402070302', regionId: '14', label: '130402070302 (Upper Rio Grande)' },
  { value: '130402070303', regionId: '14', label: '130402070303 (Upper Rio Grande)' },
  { value: '130402070304', regionId: '14', label: '130402070304 (Upper Rio Grande)' },
  { value: '130402070305', regionId: '14', label: '130402070305 (Upper Rio Grande)' },
  { value: '130402070306', regionId: '14', label: '130402070306 (Upper Rio Grande)' },
  { value: '130402070401', regionId: '14', label: '130402070401 (Upper Rio Grande)' },
  { value: '130402070402', regionId: '14', label: '130402070402 (Upper Rio Grande)' },
  { value: '130402070403', regionId: '14', label: '130402070403 (Upper Rio Grande)' },
  { value: '130402070404', regionId: '14', label: '130402070404 (Upper Rio Grande)' },
  { value: '130402080201', regionId: '14', label: '130402080201 (Upper Rio Grande)' },
  { value: '130402080202', regionId: '14', label: '130402080202 (Upper Rio Grande)' },
  { value: '130402080203', regionId: '14', label: '130402080203 (Upper Rio Grande)' },
  { value: '130402080207', regionId: '14', label: '130402080207 (Upper Rio Grande)' },
  { value: '130402080401', regionId: '14', label: '130402080401 (Upper Rio Grande)' },
  { value: '130402080402', regionId: '14', label: '130402080402 (Upper Rio Grande)' },
  { value: '130402080403', regionId: '14', label: '130402080403 (Upper Rio Grande)' },
  { value: '130402080404', regionId: '14', label: '130402080404 (Upper Rio Grande)' },
  { value: '130402080405', regionId: '14', label: '130402080405 (Upper Rio Grande)' },
  { value: '130402080406', regionId: '14', label: '130402080406 (Upper Rio Grande)' },
  { value: '130402080501', regionId: '14', label: '130402080501 (Upper Rio Grande)' },
  { value: '130402080502', regionId: '14', label: '130402080502 (Upper Rio Grande)' },
  { value: '130402080503', regionId: '14', label: '130402080503 (Upper Rio Grande)' },
  { value: '130402080504', regionId: '14', label: '130402080504 (Upper Rio Grande)' },
  { value: '130402081103', regionId: '14', label: '130402081103 (Upper Rio Grande)' },
  { value: '130402081302', regionId: '14', label: '130402081302 (Upper Rio Grande)' },
  { value: '130402081303', regionId: '14', label: '130402081303 (Upper Rio Grande)' },
  { value: '130402081305', regionId: '14', label: '130402081305 (Upper Rio Grande)' },
  { value: '130402080206', regionId: '14', label: '130402080206 (Upper Rio Grande)' },
  { value: '130402081101', regionId: '14', label: '130402081101 (Upper Rio Grande)' },
  { value: '130402081102', regionId: '14', label: '130402081102 (Upper Rio Grande)' },
  { value: '130402081301', regionId: '14', label: '130402081301 (Upper Rio Grande)' },
  { value: '130402081106', regionId: '14', label: '130402081106 (Upper Rio Grande)' },
  { value: '130402090201', regionId: '14', label: '130402090201 (Upper Rio Grande)' },
  { value: '130402090202', regionId: '14', label: '130402090202 (Upper Rio Grande)' },
  { value: '130402090203', regionId: '14', label: '130402090203 (Upper Rio Grande)' },
  { value: '130402090204', regionId: '14', label: '130402090204 (Upper Rio Grande)' },
  { value: '130402090301', regionId: '14', label: '130402090301 (Upper Rio Grande)' },
  { value: '130402090302', regionId: '14', label: '130402090302 (Upper Rio Grande)' },
  { value: '130402090303', regionId: '14', label: '130402090303 (Upper Rio Grande)' },
  { value: '130402090304', regionId: '14', label: '130402090304 (Upper Rio Grande)' },
  { value: '130402090305', regionId: '14', label: '130402090305 (Upper Rio Grande)' },
  { value: '130402090306', regionId: '14', label: '130402090306 (Upper Rio Grande)' },
  { value: '130402090401', regionId: '14', label: '130402090401 (Upper Rio Grande)' },
  { value: '130402090402', regionId: '14', label: '130402090402 (Upper Rio Grande)' },
  { value: '130402090403', regionId: '14', label: '130402090403 (Upper Rio Grande)' },
  { value: '130402090404', regionId: '14', label: '130402090404 (Upper Rio Grande)' },
  { value: '130402090405', regionId: '14', label: '130402090405 (Upper Rio Grande)' },
  { value: '130402090101', regionId: '14', label: '130402090101 (Upper Rio Grande)' },
  { value: '130402090102', regionId: '14', label: '130402090102 (Upper Rio Grande)' },
  { value: '130402090103', regionId: '14', label: '130402090103 (Upper Rio Grande)' },
  { value: '130402090104', regionId: '14', label: '130402090104 (Upper Rio Grande)' },
  { value: '130402090501', regionId: '14', label: '130402090501 (Upper Rio Grande)' },
  { value: '130402090502', regionId: '14', label: '130402090502 (Upper Rio Grande)' },
  { value: '130402090503', regionId: '14', label: '130402090503 (Upper Rio Grande)' },
  { value: '130402090601', regionId: '14', label: '130402090601 (Upper Rio Grande)' },
  { value: '130402090602', regionId: '14', label: '130402090602 (Upper Rio Grande)' },
  { value: '130402090603', regionId: '14', label: '130402090603 (Upper Rio Grande)' },
  { value: '130402100106', regionId: '14', label: '130402100106 (Upper Rio Grande)' },
  { value: '130402100201', regionId: '14', label: '130402100201 (Upper Rio Grande)' },
  { value: '130402100202', regionId: '14', label: '130402100202 (Upper Rio Grande)' },
  { value: '130402100203', regionId: '14', label: '130402100203 (Upper Rio Grande)' },
  { value: '130402100204', regionId: '14', label: '130402100204 (Upper Rio Grande)' },
  { value: '130402100205', regionId: '14', label: '130402100205 (Upper Rio Grande)' },
  { value: '130402100101', regionId: '14', label: '130402100101 (Upper Rio Grande)' },
  { value: '130402100102', regionId: '14', label: '130402100102 (Upper Rio Grande)' },
  { value: '130402100103', regionId: '14', label: '130402100103 (Upper Rio Grande)' },
  { value: '130402100104', regionId: '14', label: '130402100104 (Upper Rio Grande)' },
  { value: '130402100105', regionId: '14', label: '130402100105 (Upper Rio Grande)' },
  { value: '130402100206', regionId: '14', label: '130402100206 (Upper Rio Grande)' },
  { value: '130402100207', regionId: '14', label: '130402100207 (Upper Rio Grande)' },
  { value: '130402100301', regionId: '14', label: '130402100301 (Upper Rio Grande)' },
  { value: '130402100302', regionId: '14', label: '130402100302 (Upper Rio Grande)' },
  { value: '130402100303', regionId: '14', label: '130402100303 (Upper Rio Grande)' },
  { value: '130402100304', regionId: '14', label: '130402100304 (Upper Rio Grande)' },
  { value: '130402100305', regionId: '14', label: '130402100305 (Upper Rio Grande)' },
  { value: '130402100401', regionId: '14', label: '130402100401 (Upper Rio Grande)' },
  { value: '130402100402', regionId: '14', label: '130402100402 (Upper Rio Grande)' },
  { value: '130402100403', regionId: '14', label: '130402100403 (Upper Rio Grande)' },
  { value: '130402100404', regionId: '14', label: '130402100404 (Upper Rio Grande)' },
  { value: '130402100405', regionId: '14', label: '130402100405 (Upper Rio Grande)' },
  { value: '130402110101', regionId: '14', label: '130402110101 (Upper Rio Grande)' },
  { value: '130402110102', regionId: '14', label: '130402110102 (Upper Rio Grande)' },
  { value: '130402110103', regionId: '14', label: '130402110103 (Upper Rio Grande)' },
  { value: '130402110104', regionId: '14', label: '130402110104 (Upper Rio Grande)' },
  { value: '130402110105', regionId: '14', label: '130402110105 (Upper Rio Grande)' },
  { value: '130402110106', regionId: '14', label: '130402110106 (Upper Rio Grande)' },
  { value: '130402110107', regionId: '14', label: '130402110107 (Upper Rio Grande)' },
  { value: '130402110108', regionId: '14', label: '130402110108 (Upper Rio Grande)' },
  { value: '130402110109', regionId: '14', label: '130402110109 (Upper Rio Grande)' },
  { value: '130402110201', regionId: '14', label: '130402110201 (Upper Rio Grande)' },
  { value: '130402110202', regionId: '14', label: '130402110202 (Upper Rio Grande)' },
  { value: '130402110203', regionId: '14', label: '130402110203 (Upper Rio Grande)' },
  { value: '130402110204', regionId: '14', label: '130402110204 (Upper Rio Grande)' },
  { value: '130402110205', regionId: '14', label: '130402110205 (Upper Rio Grande)' },
  { value: '130402110206', regionId: '14', label: '130402110206 (Upper Rio Grande)' },
  { value: '130402110207', regionId: '14', label: '130402110207 (Upper Rio Grande)' },
  { value: '130402110301', regionId: '14', label: '130402110301 (Upper Rio Grande)' },
  { value: '130402110302', regionId: '14', label: '130402110302 (Upper Rio Grande)' },
  { value: '130402110303', regionId: '14', label: '130402110303 (Upper Rio Grande)' },
  { value: '130402110304', regionId: '14', label: '130402110304 (Upper Rio Grande)' },
  { value: '130402120301', regionId: '14', label: '130402120301 (Upper Rio Grande)' },
  { value: '130402120305', regionId: '14', label: '130402120305 (Upper Rio Grande)' },
  { value: '130402120306', regionId: '14', label: '130402120306 (Upper Rio Grande)' },
  { value: '130402120307', regionId: '14', label: '130402120307 (Upper Rio Grande)' },
  { value: '130402120308', regionId: '14', label: '130402120308 (Upper Rio Grande)' },
  { value: '130402120309', regionId: '14', label: '130402120309 (Upper Rio Grande)' },
  { value: '130402120310', regionId: '14', label: '130402120310 (Upper Rio Grande)' },
  { value: '130402121401', regionId: '14', label: '130402121401 (Upper Rio Grande)' },
  { value: '130402121404', regionId: '14', label: '130402121404 (Upper Rio Grande)' },
  { value: '130402120304', regionId: '14', label: '130402120304 (Upper Rio Grande)' },
  { value: '130402121403', regionId: '14', label: '130402121403 (Upper Rio Grande)' },
  { value: '130402121405', regionId: '14', label: '130402121405 (Upper Rio Grande)' },
  { value: '130402120303', regionId: '14', label: '130402120303 (Upper Rio Grande)' },
  { value: '130403010102', regionId: '14', label: '130403010102 (Upper Rio Grande)' },
  { value: '130403010103', regionId: '14', label: '130403010103 (Upper Rio Grande)' },
  { value: '130403010104', regionId: '14', label: '130403010104 (Upper Rio Grande)' },
  { value: '130403010105', regionId: '14', label: '130403010105 (Upper Rio Grande)' },
  { value: '130403010106', regionId: '14', label: '130403010106 (Upper Rio Grande)' },
  { value: '130403010107', regionId: '14', label: '130403010107 (Upper Rio Grande)' },
  { value: '130403010201', regionId: '14', label: '130403010201 (Upper Rio Grande)' },
  { value: '130403010202', regionId: '14', label: '130403010202 (Upper Rio Grande)' },
  { value: '130403010203', regionId: '14', label: '130403010203 (Upper Rio Grande)' },
  { value: '130403010204', regionId: '14', label: '130403010204 (Upper Rio Grande)' },
  { value: '130403010902', regionId: '14', label: '130403010902 (Upper Rio Grande)' },
  { value: '130403010205', regionId: '14', label: '130403010205 (Upper Rio Grande)' },
  { value: '130403011103', regionId: '14', label: '130403011103 (Upper Rio Grande)' },
  { value: '130403010504', regionId: '14', label: '130403010504 (Upper Rio Grande)' },
  { value: '130403010206', regionId: '14', label: '130403010206 (Upper Rio Grande)' },
  { value: '130403010207', regionId: '14', label: '130403010207 (Upper Rio Grande)' },
  { value: '130403010208', regionId: '14', label: '130403010208 (Upper Rio Grande)' },
  { value: '130403010209', regionId: '14', label: '130403010209 (Upper Rio Grande)' },
  { value: '130403010301', regionId: '14', label: '130403010301 (Upper Rio Grande)' },
  { value: '130403010302', regionId: '14', label: '130403010302 (Upper Rio Grande)' },
  { value: '130403010303', regionId: '14', label: '130403010303 (Upper Rio Grande)' },
  { value: '130403010304', regionId: '14', label: '130403010304 (Upper Rio Grande)' },
  { value: '130403010305', regionId: '14', label: '130403010305 (Upper Rio Grande)' },
  { value: '130403010306', regionId: '14', label: '130403010306 (Upper Rio Grande)' },
  { value: '130403010401', regionId: '14', label: '130403010401 (Upper Rio Grande)' },
  { value: '130403010402', regionId: '14', label: '130403010402 (Upper Rio Grande)' },
  { value: '130403010403', regionId: '14', label: '130403010403 (Upper Rio Grande)' },
  { value: '130403010404', regionId: '14', label: '130403010404 (Upper Rio Grande)' },
  { value: '130403010405', regionId: '14', label: '130403010405 (Upper Rio Grande)' },
  { value: '130403010501', regionId: '14', label: '130403010501 (Upper Rio Grande)' },
  { value: '130403010601', regionId: '14', label: '130403010601 (Upper Rio Grande)' },
  { value: '130403010502', regionId: '14', label: '130403010502 (Upper Rio Grande)' },
  { value: '130403010503', regionId: '14', label: '130403010503 (Upper Rio Grande)' },
  { value: '130403010602', regionId: '14', label: '130403010602 (Upper Rio Grande)' },
  { value: '130403010603', regionId: '14', label: '130403010603 (Upper Rio Grande)' },
  { value: '130403010604', regionId: '14', label: '130403010604 (Upper Rio Grande)' },
  { value: '130403010701', regionId: '14', label: '130403010701 (Upper Rio Grande)' },
  { value: '130403010702', regionId: '14', label: '130403010702 (Upper Rio Grande)' },
  { value: '130403010703', regionId: '14', label: '130403010703 (Upper Rio Grande)' },
  { value: '130403010801', regionId: '14', label: '130403010801 (Upper Rio Grande)' },
  { value: '130403010802', regionId: '14', label: '130403010802 (Upper Rio Grande)' },
  { value: '130403010803', regionId: '14', label: '130403010803 (Upper Rio Grande)' },
  { value: '130403010804', regionId: '14', label: '130403010804 (Upper Rio Grande)' },
  { value: '130403010805', regionId: '14', label: '130403010805 (Upper Rio Grande)' },
  { value: '130403010901', regionId: '14', label: '130403010901 (Upper Rio Grande)' },
  { value: '130403010806', regionId: '14', label: '130403010806 (Upper Rio Grande)' },
  { value: '130403010807', regionId: '14', label: '130403010807 (Upper Rio Grande)' },
  { value: '130403010808', regionId: '14', label: '130403010808 (Upper Rio Grande)' },
  { value: '130403010809', regionId: '14', label: '130403010809 (Upper Rio Grande)' },
  { value: '130403010903', regionId: '14', label: '130403010903 (Upper Rio Grande)' },
  { value: '130403010904', regionId: '14', label: '130403010904 (Upper Rio Grande)' },
  { value: '130403010905', regionId: '14', label: '130403010905 (Upper Rio Grande)' },
  { value: '130403011001', regionId: '14', label: '130403011001 (Upper Rio Grande)' },
  { value: '130403011002', regionId: '14', label: '130403011002 (Upper Rio Grande)' },
  { value: '130403011003', regionId: '14', label: '130403011003 (Upper Rio Grande)' },
  { value: '130403011004', regionId: '14', label: '130403011004 (Upper Rio Grande)' },
  { value: '130403011005', regionId: '14', label: '130403011005 (Upper Rio Grande)' },
  { value: '130403011101', regionId: '14', label: '130403011101 (Upper Rio Grande)' },
  { value: '130403011102', regionId: '14', label: '130403011102 (Upper Rio Grande)' },
  { value: '130403011104', regionId: '14', label: '130403011104 (Upper Rio Grande)' },
  { value: '130403011105', regionId: '14', label: '130403011105 (Upper Rio Grande)' },
  { value: '130403010101', regionId: '14', label: '130403010101 (Upper Rio Grande)' },
  { value: '130403020101', regionId: '14', label: '130403020101 (Upper Rio Grande)' },
  { value: '130403020102', regionId: '14', label: '130403020102 (Upper Rio Grande)' },
  { value: '130403020103', regionId: '14', label: '130403020103 (Upper Rio Grande)' },
  { value: '130403020104', regionId: '14', label: '130403020104 (Upper Rio Grande)' },
  { value: '130403020105', regionId: '14', label: '130403020105 (Upper Rio Grande)' },
  { value: '130403020106', regionId: '14', label: '130403020106 (Upper Rio Grande)' },
  { value: '130403020201', regionId: '14', label: '130403020201 (Upper Rio Grande)' },
  { value: '130403020202', regionId: '14', label: '130403020202 (Upper Rio Grande)' },
  { value: '130403020203', regionId: '14', label: '130403020203 (Upper Rio Grande)' },
  { value: '130403020204', regionId: '14', label: '130403020204 (Upper Rio Grande)' },
  { value: '130403020205', regionId: '14', label: '130403020205 (Upper Rio Grande)' },
  { value: '130403020206', regionId: '14', label: '130403020206 (Upper Rio Grande)' },
  { value: '130403020301', regionId: '14', label: '130403020301 (Upper Rio Grande)' },
  { value: '130403020305', regionId: '14', label: '130403020305 (Upper Rio Grande)' },
  { value: '130403020401', regionId: '14', label: '130403020401 (Upper Rio Grande)' },
  { value: '130403020402', regionId: '14', label: '130403020402 (Upper Rio Grande)' },
  { value: '130403020302', regionId: '14', label: '130403020302 (Upper Rio Grande)' },
  { value: '130403020303', regionId: '14', label: '130403020303 (Upper Rio Grande)' },
  { value: '130403020304', regionId: '14', label: '130403020304 (Upper Rio Grande)' },
  { value: '130403020403', regionId: '14', label: '130403020403 (Upper Rio Grande)' },
  { value: '130403020404', regionId: '14', label: '130403020404 (Upper Rio Grande)' },
  { value: '130403020405', regionId: '14', label: '130403020405 (Upper Rio Grande)' },
  { value: '130403020406', regionId: '14', label: '130403020406 (Upper Rio Grande)' },
  { value: '130403020407', regionId: '14', label: '130403020407 (Upper Rio Grande)' },
  { value: '130403020408', regionId: '14', label: '130403020408 (Upper Rio Grande)' },
  { value: '130403030304', regionId: '14', label: '130403030304 (Upper Rio Grande)' },
  { value: '130403030305', regionId: '14', label: '130403030305 (Upper Rio Grande)' },
  { value: '130403030401', regionId: '14', label: '130403030401 (Upper Rio Grande)' },
  { value: '130403030402', regionId: '14', label: '130403030402 (Upper Rio Grande)' },
  { value: '130403030403', regionId: '14', label: '130403030403 (Upper Rio Grande)' },
  { value: '130403030404', regionId: '14', label: '130403030404 (Upper Rio Grande)' },
  { value: '130403030405', regionId: '14', label: '130403030405 (Upper Rio Grande)' },
  { value: '130403030204', regionId: '14', label: '130403030204 (Upper Rio Grande)' },
  { value: '130403030301', regionId: '14', label: '130403030301 (Upper Rio Grande)' },
  { value: '130403030302', regionId: '14', label: '130403030302 (Upper Rio Grande)' },
  { value: '130403030303', regionId: '14', label: '130403030303 (Upper Rio Grande)' },
  { value: '130403030101', regionId: '14', label: '130403030101 (Upper Rio Grande)' },
  { value: '130403030102', regionId: '14', label: '130403030102 (Upper Rio Grande)' },
  { value: '130403030103', regionId: '14', label: '130403030103 (Upper Rio Grande)' },
  { value: '130403030201', regionId: '14', label: '130403030201 (Upper Rio Grande)' },
  { value: '130403030202', regionId: '14', label: '130403030202 (Upper Rio Grande)' },
  { value: '130403030203', regionId: '14', label: '130403030203 (Upper Rio Grande)' },
  { value: '130700020309', regionId: '14', label: '130700020309 (Upper Rio Grande)' },
  { value: '130700020307', regionId: '14', label: '130700020307 (Upper Rio Grande)' },
  { value: '130700020308', regionId: '14', label: '130700020308 (Upper Rio Grande)' },
  { value: '130700020204', regionId: '14', label: '130700020204 (Upper Rio Grande)' },
  { value: '130700020301', regionId: '14', label: '130700020301 (Upper Rio Grande)' },
  { value: '130700020302', regionId: '14', label: '130700020302 (Upper Rio Grande)' },
  { value: '130700020101', regionId: '14', label: '130700020101 (Upper Rio Grande)' },
  { value: '130700020102', regionId: '14', label: '130700020102 (Upper Rio Grande)' },
  { value: '130700020103', regionId: '14', label: '130700020103 (Upper Rio Grande)' },
  { value: '130700020104', regionId: '14', label: '130700020104 (Upper Rio Grande)' },
  { value: '130700020105', regionId: '14', label: '130700020105 (Upper Rio Grande)' },
  { value: '130700020201', regionId: '14', label: '130700020201 (Upper Rio Grande)' },
  { value: '130700020202', regionId: '14', label: '130700020202 (Upper Rio Grande)' },
  { value: '130700020203', regionId: '14', label: '130700020203 (Upper Rio Grande)' },
  { value: '130700020303', regionId: '14', label: '130700020303 (Upper Rio Grande)' },
  { value: '130700020304', regionId: '14', label: '130700020304 (Upper Rio Grande)' },
  { value: '130700020305', regionId: '14', label: '130700020305 (Upper Rio Grande)' },
  { value: '130700020306', regionId: '14', label: '130700020306 (Upper Rio Grande)' },
  { value: '130700030201', regionId: '14', label: '130700030201 (Upper Rio Grande)' },
  { value: '130700030203', regionId: '14', label: '130700030203 (Upper Rio Grande)' },
  { value: '130700030204', regionId: '14', label: '130700030204 (Upper Rio Grande)' },
  { value: '130700030301', regionId: '14', label: '130700030301 (Upper Rio Grande)' },
  { value: '130700030101', regionId: '14', label: '130700030101 (Upper Rio Grande)' },
  { value: '130700030102', regionId: '14', label: '130700030102 (Upper Rio Grande)' },
  { value: '130700030103', regionId: '14', label: '130700030103 (Upper Rio Grande)' },
  { value: '130700030104', regionId: '14', label: '130700030104 (Upper Rio Grande)' },
  { value: '130700030105', regionId: '14', label: '130700030105 (Upper Rio Grande)' },
  { value: '130700030106', regionId: '14', label: '130700030106 (Upper Rio Grande)' },
  { value: '130700030202', regionId: '14', label: '130700030202 (Upper Rio Grande)' },
  { value: '130700030308', regionId: '14', label: '130700030308 (Upper Rio Grande)' },
  { value: '130700030401', regionId: '14', label: '130700030401 (Upper Rio Grande)' },
  { value: '130700030302', regionId: '14', label: '130700030302 (Upper Rio Grande)' },
  { value: '130700030303', regionId: '14', label: '130700030303 (Upper Rio Grande)' },
  { value: '130700030304', regionId: '14', label: '130700030304 (Upper Rio Grande)' },
  { value: '130700030305', regionId: '14', label: '130700030305 (Upper Rio Grande)' },
  { value: '130700030306', regionId: '14', label: '130700030306 (Upper Rio Grande)' },
  { value: '130700030307', regionId: '14', label: '130700030307 (Upper Rio Grande)' },
  { value: '130700030402', regionId: '14', label: '130700030402 (Upper Rio Grande)' },
  { value: '130700030403', regionId: '14', label: '130700030403 (Upper Rio Grande)' },
  { value: '130700040101', regionId: '14', label: '130700040101 (Upper Rio Grande)' },
  { value: '130700040102', regionId: '14', label: '130700040102 (Upper Rio Grande)' },
  { value: '130700040103', regionId: '14', label: '130700040103 (Upper Rio Grande)' },
  { value: '130700040104', regionId: '14', label: '130700040104 (Upper Rio Grande)' },
  { value: '130700040105', regionId: '14', label: '130700040105 (Upper Rio Grande)' },
  { value: '130700040106', regionId: '14', label: '130700040106 (Upper Rio Grande)' },
  { value: '130700040201', regionId: '14', label: '130700040201 (Upper Rio Grande)' },
  { value: '130700040202', regionId: '14', label: '130700040202 (Upper Rio Grande)' },
  { value: '130700040203', regionId: '14', label: '130700040203 (Upper Rio Grande)' },
  { value: '130700040301', regionId: '14', label: '130700040301 (Upper Rio Grande)' },
  { value: '130700040302', regionId: '14', label: '130700040302 (Upper Rio Grande)' },
  { value: '130700040303', regionId: '14', label: '130700040303 (Upper Rio Grande)' },
  { value: '130700040304', regionId: '14', label: '130700040304 (Upper Rio Grande)' },
  { value: '130700040305', regionId: '14', label: '130700040305 (Upper Rio Grande)' },
  { value: '130700040401', regionId: '14', label: '130700040401 (Upper Rio Grande)' },
  { value: '130700040402', regionId: '14', label: '130700040402 (Upper Rio Grande)' },
  { value: '130700040403', regionId: '14', label: '130700040403 (Upper Rio Grande)' },
  { value: '130700040404', regionId: '14', label: '130700040404 (Upper Rio Grande)' },
  { value: '130700040405', regionId: '14', label: '130700040405 (Upper Rio Grande)' },
  { value: '130700040406', regionId: '14', label: '130700040406 (Upper Rio Grande)' },
  { value: '130700040501', regionId: '14', label: '130700040501 (Upper Rio Grande)' },
  { value: '130700040506', regionId: '14', label: '130700040506 (Upper Rio Grande)' },
  { value: '130700040601', regionId: '14', label: '130700040601 (Upper Rio Grande)' },
  { value: '130700040502', regionId: '14', label: '130700040502 (Upper Rio Grande)' },
  { value: '130700040503', regionId: '14', label: '130700040503 (Upper Rio Grande)' },
  { value: '130700040504', regionId: '14', label: '130700040504 (Upper Rio Grande)' },
  { value: '130700040505', regionId: '14', label: '130700040505 (Upper Rio Grande)' },
  { value: '130700040602', regionId: '14', label: '130700040602 (Upper Rio Grande)' },
  { value: '130700040603', regionId: '14', label: '130700040603 (Upper Rio Grande)' },
  { value: '130700040604', regionId: '14', label: '130700040604 (Upper Rio Grande)' },
  { value: '130700040605', regionId: '14', label: '130700040605 (Upper Rio Grande)' },
  { value: '130700040701', regionId: '14', label: '130700040701 (Upper Rio Grande)' },
  { value: '130700040702', regionId: '14', label: '130700040702 (Upper Rio Grande)' },
  { value: '130700040804', regionId: '14', label: '130700040804 (Upper Rio Grande)' },
  { value: '130700040703', regionId: '14', label: '130700040703 (Upper Rio Grande)' },
  { value: '130700040704', regionId: '14', label: '130700040704 (Upper Rio Grande)' },
  { value: '130700040705', regionId: '14', label: '130700040705 (Upper Rio Grande)' },
  { value: '130700040801', regionId: '14', label: '130700040801 (Upper Rio Grande)' },
  { value: '130700040802', regionId: '14', label: '130700040802 (Upper Rio Grande)' },
  { value: '130700040803', regionId: '14', label: '130700040803 (Upper Rio Grande)' },
  { value: '130700040805', regionId: '14', label: '130700040805 (Upper Rio Grande)' },
  { value: '130700040806', regionId: '14', label: '130700040806 (Upper Rio Grande)' },
  { value: '130700050101', regionId: '14', label: '130700050101 (Upper Rio Grande)' },
  { value: '130700050201', regionId: '14', label: '130700050201 (Upper Rio Grande)' },
  { value: '130700050202', regionId: '14', label: '130700050202 (Upper Rio Grande)' },
  { value: '130700050203', regionId: '14', label: '130700050203 (Upper Rio Grande)' },
  { value: '130700050102', regionId: '14', label: '130700050102 (Upper Rio Grande)' },
  { value: '130700050103', regionId: '14', label: '130700050103 (Upper Rio Grande)' },
  { value: '130700050104', regionId: '14', label: '130700050104 (Upper Rio Grande)' },
  { value: '130700050105', regionId: '14', label: '130700050105 (Upper Rio Grande)' },
  { value: '130700050106', regionId: '14', label: '130700050106 (Upper Rio Grande)' },
  { value: '130700050204', regionId: '14', label: '130700050204 (Upper Rio Grande)' },
  { value: '130700050205', regionId: '14', label: '130700050205 (Upper Rio Grande)' },
  { value: '130700050301', regionId: '14', label: '130700050301 (Upper Rio Grande)' },
  { value: '130700050302', regionId: '14', label: '130700050302 (Upper Rio Grande)' },
  { value: '130700050303', regionId: '14', label: '130700050303 (Upper Rio Grande)' },
  { value: '130700050304', regionId: '14', label: '130700050304 (Upper Rio Grande)' },
  { value: '130700060506', regionId: '14', label: '130700060506 (Upper Rio Grande)' },
  { value: '130700060601', regionId: '14', label: '130700060601 (Upper Rio Grande)' },
  { value: '130700060602', regionId: '14', label: '130700060602 (Upper Rio Grande)' },
  { value: '130700060603', regionId: '14', label: '130700060603 (Upper Rio Grande)' },
  { value: '130700060101', regionId: '14', label: '130700060101 (Upper Rio Grande)' },
  { value: '130700060102', regionId: '14', label: '130700060102 (Upper Rio Grande)' },
  { value: '130700060103', regionId: '14', label: '130700060103 (Upper Rio Grande)' },
  { value: '130700060202', regionId: '14', label: '130700060202 (Upper Rio Grande)' },
  { value: '130700060203', regionId: '14', label: '130700060203 (Upper Rio Grande)' },
  { value: '130700060204', regionId: '14', label: '130700060204 (Upper Rio Grande)' },
  { value: '130700060104', regionId: '14', label: '130700060104 (Upper Rio Grande)' },
  { value: '130700060403', regionId: '14', label: '130700060403 (Upper Rio Grande)' },
  { value: '130700060105', regionId: '14', label: '130700060105 (Upper Rio Grande)' },
  { value: '130700060201', regionId: '14', label: '130700060201 (Upper Rio Grande)' },
  { value: '130700060604', regionId: '14', label: '130700060604 (Upper Rio Grande)' },
  { value: '130700060605', regionId: '14', label: '130700060605 (Upper Rio Grande)' },
  { value: '130700060205', regionId: '14', label: '130700060205 (Upper Rio Grande)' },
  { value: '130700060206', regionId: '14', label: '130700060206 (Upper Rio Grande)' },
  { value: '130700060301', regionId: '14', label: '130700060301 (Upper Rio Grande)' },
  { value: '130700060302', regionId: '14', label: '130700060302 (Upper Rio Grande)' },
  { value: '130700060303', regionId: '14', label: '130700060303 (Upper Rio Grande)' },
  { value: '130700060304', regionId: '14', label: '130700060304 (Upper Rio Grande)' },
  { value: '130700060305', regionId: '14', label: '130700060305 (Upper Rio Grande)' },
  { value: '130700060306', regionId: '14', label: '130700060306 (Upper Rio Grande)' },
  { value: '130700060401', regionId: '14', label: '130700060401 (Upper Rio Grande)' },
  { value: '130700060402', regionId: '14', label: '130700060402 (Upper Rio Grande)' },
  { value: '130700060501', regionId: '14', label: '130700060501 (Upper Rio Grande)' },
  { value: '130700060404', regionId: '14', label: '130700060404 (Upper Rio Grande)' },
  { value: '130700060405', regionId: '14', label: '130700060405 (Upper Rio Grande)' },
  { value: '130700060502', regionId: '14', label: '130700060502 (Upper Rio Grande)' },
  { value: '130700060503', regionId: '14', label: '130700060503 (Upper Rio Grande)' },
  { value: '130700060504', regionId: '14', label: '130700060504 (Upper Rio Grande)' },
  { value: '130700060505', regionId: '14', label: '130700060505 (Upper Rio Grande)' },
  { value: '130700080101', regionId: '14', label: '130700080101 (Upper Rio Grande)' },
  { value: '130700080102', regionId: '14', label: '130700080102 (Upper Rio Grande)' },
  { value: '130700080103', regionId: '14', label: '130700080103 (Upper Rio Grande)' },
  { value: '130700080104', regionId: '14', label: '130700080104 (Upper Rio Grande)' },
  { value: '130700080105', regionId: '14', label: '130700080105 (Upper Rio Grande)' },
  { value: '130700080106', regionId: '14', label: '130700080106 (Upper Rio Grande)' },
  { value: '130700080107', regionId: '14', label: '130700080107 (Upper Rio Grande)' },
  { value: '130700080108', regionId: '14', label: '130700080108 (Upper Rio Grande)' },
  { value: '130700080109', regionId: '14', label: '130700080109 (Upper Rio Grande)' },
  { value: '130700080201', regionId: '14', label: '130700080201 (Upper Rio Grande)' },
  { value: '130700080202', regionId: '14', label: '130700080202 (Upper Rio Grande)' },
  { value: '130700080203', regionId: '14', label: '130700080203 (Upper Rio Grande)' },
  { value: '130700080204', regionId: '14', label: '130700080204 (Upper Rio Grande)' },
  { value: '130700080205', regionId: '14', label: '130700080205 (Upper Rio Grande)' },
  { value: '130700080206', regionId: '14', label: '130700080206 (Upper Rio Grande)' },
  { value: '130700080207', regionId: '14', label: '130700080207 (Upper Rio Grande)' },
  { value: '130700080208', regionId: '14', label: '130700080208 (Upper Rio Grande)' },
  { value: '130700080301', regionId: '14', label: '130700080301 (Upper Rio Grande)' },
  { value: '130700080302', regionId: '14', label: '130700080302 (Upper Rio Grande)' },
  { value: '130700080303', regionId: '14', label: '130700080303 (Upper Rio Grande)' },
  { value: '130700080304', regionId: '14', label: '130700080304 (Upper Rio Grande)' },
  { value: '130700080305', regionId: '14', label: '130700080305 (Upper Rio Grande)' },
  { value: '130700080306', regionId: '14', label: '130700080306 (Upper Rio Grande)' },
  { value: '130700080307', regionId: '14', label: '130700080307 (Upper Rio Grande)' },
  { value: '130700080308', regionId: '14', label: '130700080308 (Upper Rio Grande)' },
  { value: '130700080401', regionId: '14', label: '130700080401 (Upper Rio Grande)' },
  { value: '130700080402', regionId: '14', label: '130700080402 (Upper Rio Grande)' },
  { value: '130700080403', regionId: '14', label: '130700080403 (Upper Rio Grande)' },
  { value: '130700080404', regionId: '14', label: '130700080404 (Upper Rio Grande)' },
  { value: '130700080405', regionId: '14', label: '130700080405 (Upper Rio Grande)' },
  { value: '130700080501', regionId: '14', label: '130700080501 (Upper Rio Grande)' },
  { value: '130700080502', regionId: '14', label: '130700080502 (Upper Rio Grande)' },
  { value: '130700080503', regionId: '14', label: '130700080503 (Upper Rio Grande)' },
  { value: '130700080505', regionId: '14', label: '130700080505 (Upper Rio Grande)' },
  { value: '130700080504', regionId: '14', label: '130700080504 (Upper Rio Grande)' },
  { value: '130700080506', regionId: '14', label: '130700080506 (Upper Rio Grande)' },
  { value: '130700080507', regionId: '14', label: '130700080507 (Upper Rio Grande)' },
  { value: '130700080508', regionId: '14', label: '130700080508 (Upper Rio Grande)' },
  { value: '130700080601', regionId: '14', label: '130700080601 (Upper Rio Grande)' },
  { value: '130700080602', regionId: '14', label: '130700080602 (Upper Rio Grande)' },
  { value: '130700080603', regionId: '14', label: '130700080603 (Upper Rio Grande)' },
  { value: '130700080604', regionId: '14', label: '130700080604 (Upper Rio Grande)' },
  { value: '130700080701', regionId: '14', label: '130700080701 (Upper Rio Grande)' },
  { value: '130700080702', regionId: '14', label: '130700080702 (Upper Rio Grande)' },
  { value: '130700080703', regionId: '14', label: '130700080703 (Upper Rio Grande)' },
  { value: '130700090108', regionId: '14', label: '130700090108 (Upper Rio Grande)' },
  { value: '130700090101', regionId: '14', label: '130700090101 (Upper Rio Grande)' },
  { value: '130700090102', regionId: '14', label: '130700090102 (Upper Rio Grande)' },
  { value: '130700090103', regionId: '14', label: '130700090103 (Upper Rio Grande)' },
  { value: '130700090104', regionId: '14', label: '130700090104 (Upper Rio Grande)' },
  { value: '130700090105', regionId: '14', label: '130700090105 (Upper Rio Grande)' },
  { value: '130700090106', regionId: '14', label: '130700090106 (Upper Rio Grande)' },
  { value: '130700090107', regionId: '14', label: '130700090107 (Upper Rio Grande)' },
  { value: '130700090109', regionId: '14', label: '130700090109 (Upper Rio Grande)' },
  { value: '130700090201', regionId: '14', label: '130700090201 (Upper Rio Grande)' },
  { value: '130700090202', regionId: '14', label: '130700090202 (Upper Rio Grande)' },
  { value: '130700090204', regionId: '14', label: '130700090204 (Upper Rio Grande)' },
  { value: '130700090203', regionId: '14', label: '130700090203 (Upper Rio Grande)' },
  { value: '130700090206', regionId: '14', label: '130700090206 (Upper Rio Grande)' },
  { value: '130700090205', regionId: '14', label: '130700090205 (Upper Rio Grande)' },
  { value: '130700090208', regionId: '14', label: '130700090208 (Upper Rio Grande)' },
  { value: '130700090207', regionId: '14', label: '130700090207 (Upper Rio Grande)' },
  { value: '130700090209', regionId: '14', label: '130700090209 (Upper Rio Grande)' },
  { value: '130700090304', regionId: '14', label: '130700090304 (Upper Rio Grande)' },
  { value: '130700090301', regionId: '14', label: '130700090301 (Upper Rio Grande)' },
  { value: '130700090210', regionId: '14', label: '130700090210 (Upper Rio Grande)' },
  { value: '130700090303', regionId: '14', label: '130700090303 (Upper Rio Grande)' },
  { value: '130700090302', regionId: '14', label: '130700090302 (Upper Rio Grande)' },
  { value: '130700090306', regionId: '14', label: '130700090306 (Upper Rio Grande)' },
  { value: '130700090305', regionId: '14', label: '130700090305 (Upper Rio Grande)' },
  { value: '130700100101', regionId: '14', label: '130700100101 (Upper Rio Grande)' },
  { value: '130700090307', regionId: '14', label: '130700090307 (Upper Rio Grande)' },
  { value: '130700100302', regionId: '14', label: '130700100302 (Upper Rio Grande)' },
  { value: '130700100102', regionId: '14', label: '130700100102 (Upper Rio Grande)' },
  { value: '130700100104', regionId: '14', label: '130700100104 (Upper Rio Grande)' },
  { value: '130700100103', regionId: '14', label: '130700100103 (Upper Rio Grande)' },
  { value: '130700100201', regionId: '14', label: '130700100201 (Upper Rio Grande)' },
  { value: '130700100105', regionId: '14', label: '130700100105 (Upper Rio Grande)' },
  { value: '130700100307', regionId: '14', label: '130700100307 (Upper Rio Grande)' },
  { value: '130700100202', regionId: '14', label: '130700100202 (Upper Rio Grande)' },
  { value: '130700100204', regionId: '14', label: '130700100204 (Upper Rio Grande)' },
  { value: '130700100203', regionId: '14', label: '130700100203 (Upper Rio Grande)' },
  { value: '130700100303', regionId: '14', label: '130700100303 (Upper Rio Grande)' },
  { value: '130700100301', regionId: '14', label: '130700100301 (Upper Rio Grande)' },
  { value: '130700100305', regionId: '14', label: '130700100305 (Upper Rio Grande)' },
  { value: '130700100304', regionId: '14', label: '130700100304 (Upper Rio Grande)' },
  { value: '130700100401', regionId: '14', label: '130700100401 (Upper Rio Grande)' },
  { value: '130700100306', regionId: '14', label: '130700100306 (Upper Rio Grande)' },
  { value: '130700100403', regionId: '14', label: '130700100403 (Upper Rio Grande)' },
  { value: '130700100402', regionId: '14', label: '130700100402 (Upper Rio Grande)' },
  { value: '130700100405', regionId: '14', label: '130700100405 (Upper Rio Grande)' },
  { value: '130700100404', regionId: '14', label: '130700100404 (Upper Rio Grande)' },
  { value: '130700110204', regionId: '14', label: '130700110204 (Upper Rio Grande)' },
  { value: '130700110203', regionId: '14', label: '130700110203 (Upper Rio Grande)' },
  { value: '130700110206', regionId: '14', label: '130700110206 (Upper Rio Grande)' },
  { value: '130700110205', regionId: '14', label: '130700110205 (Upper Rio Grande)' },
  { value: '130700110302', regionId: '14', label: '130700110302 (Upper Rio Grande)' },
  { value: '130700110301', regionId: '14', label: '130700110301 (Upper Rio Grande)' },
  { value: '130700110304', regionId: '14', label: '130700110304 (Upper Rio Grande)' },
  { value: '130700110303', regionId: '14', label: '130700110303 (Upper Rio Grande)' },
  { value: '130700110306', regionId: '14', label: '130700110306 (Upper Rio Grande)' },
  { value: '130700110305', regionId: '14', label: '130700110305 (Upper Rio Grande)' },
  { value: '130700110308', regionId: '14', label: '130700110308 (Upper Rio Grande)' },
  { value: '130700110307', regionId: '14', label: '130700110307 (Upper Rio Grande)' },
  { value: '130700110102', regionId: '14', label: '130700110102 (Upper Rio Grande)' },
  { value: '130700110101', regionId: '14', label: '130700110101 (Upper Rio Grande)' },
  { value: '130700110104', regionId: '14', label: '130700110104 (Upper Rio Grande)' },
  { value: '130700110103', regionId: '14', label: '130700110103 (Upper Rio Grande)' },
  { value: '130700110106', regionId: '14', label: '130700110106 (Upper Rio Grande)' },
  { value: '130700110105', regionId: '14', label: '130700110105 (Upper Rio Grande)' },
  { value: '130700110108', regionId: '14', label: '130700110108 (Upper Rio Grande)' },
  { value: '130700110107', regionId: '14', label: '130700110107 (Upper Rio Grande)' },
  { value: '130700110202', regionId: '14', label: '130700110202 (Upper Rio Grande)' },
  { value: '130700110201', regionId: '14', label: '130700110201 (Upper Rio Grande)' },
  { value: '130700120101', regionId: '14', label: '130700120101 (Upper Rio Grande)' },
  { value: '130700120104', regionId: '14', label: '130700120104 (Upper Rio Grande)' },
  { value: '130700120103', regionId: '14', label: '130700120103 (Upper Rio Grande)' },
  { value: '130700120102', regionId: '14', label: '130700120102 (Upper Rio Grande)' },
  { value: '130700120106', regionId: '14', label: '130700120106 (Upper Rio Grande)' },
  { value: '130700120105', regionId: '14', label: '130700120105 (Upper Rio Grande)' },
  { value: '130700120108', regionId: '14', label: '130700120108 (Upper Rio Grande)' },
  { value: '130700120107', regionId: '14', label: '130700120107 (Upper Rio Grande)' },
  { value: '130700120202', regionId: '14', label: '130700120202 (Upper Rio Grande)' },
  { value: '130700120201', regionId: '14', label: '130700120201 (Upper Rio Grande)' },
  { value: '130700120204', regionId: '14', label: '130700120204 (Upper Rio Grande)' },
  { value: '130700120203', regionId: '14', label: '130700120203 (Upper Rio Grande)' },
  { value: '130700120206', regionId: '14', label: '130700120206 (Upper Rio Grande)' },
  { value: '130700120205', regionId: '14', label: '130700120205 (Upper Rio Grande)' },
  { value: '130700120301', regionId: '14', label: '130700120301 (Upper Rio Grande)' },
  { value: '130700120207', regionId: '14', label: '130700120207 (Upper Rio Grande)' },
  { value: '130700120303', regionId: '14', label: '130700120303 (Upper Rio Grande)' },
  { value: '130700120302', regionId: '14', label: '130700120302 (Upper Rio Grande)' },
  { value: '130700120401', regionId: '14', label: '130700120401 (Upper Rio Grande)' },
  { value: '130700120304', regionId: '14', label: '130700120304 (Upper Rio Grande)' },
  { value: '130700120403', regionId: '14', label: '130700120403 (Upper Rio Grande)' },
  { value: '130700120402', regionId: '14', label: '130700120402 (Upper Rio Grande)' },
  { value: '130700120405', regionId: '14', label: '130700120405 (Upper Rio Grande)' },
  { value: '130700120404', regionId: '14', label: '130700120404 (Upper Rio Grande)' },
  { value: '130700120407', regionId: '14', label: '130700120407 (Upper Rio Grande)' },
  { value: '130700120406', regionId: '14', label: '130700120406 (Upper Rio Grande)' },
  { value: '130800010302', regionId: '14', label: '130800010302 (Upper Rio Grande)' },
  { value: '130800010301', regionId: '14', label: '130800010301 (Upper Rio Grande)' },
  { value: '130800010305', regionId: '14', label: '130800010305 (Upper Rio Grande)' },
  { value: '130800010304', regionId: '14', label: '130800010304 (Upper Rio Grande)' },
  { value: '130800010203', regionId: '14', label: '130800010203 (Upper Rio Grande)' },
  { value: '130800010306', regionId: '14', label: '130800010306 (Upper Rio Grande)' },
  { value: '130800010205', regionId: '14', label: '130800010205 (Upper Rio Grande)' },
  { value: '130800010204', regionId: '14', label: '130800010204 (Upper Rio Grande)' },
  { value: '120800040802', regionId: '14', label: '120800040802 (Upper Rio Grande)' },
  { value: '120800040801', regionId: '14', label: '120800040801 (Upper Rio Grande)' },
  { value: '130500032103', regionId: '14', label: '130500032103 (Upper Rio Grande)' },
  { value: '130500032101', regionId: '14', label: '130500032101 (Upper Rio Grande)' },
  { value: '130500032105', regionId: '14', label: '130500032105 (Upper Rio Grande)' },
  { value: '130500032104', regionId: '14', label: '130500032104 (Upper Rio Grande)' },
  { value: '130500032207', regionId: '14', label: '130500032207 (Upper Rio Grande)' },
  { value: '130500032106', regionId: '14', label: '130500032106 (Upper Rio Grande)' },
  { value: '130500040702', regionId: '14', label: '130500040702 (Upper Rio Grande)' },
  { value: '130500032102', regionId: '14', label: '130500032102 (Upper Rio Grande)' },
  { value: '130500040803', regionId: '14', label: '130500040803 (Upper Rio Grande)' },
  { value: '130500040801', regionId: '14', label: '130500040801 (Upper Rio Grande)' },
  { value: '130500040905', regionId: '14', label: '130500040905 (Upper Rio Grande)' },
  { value: '130500040903', regionId: '14', label: '130500040903 (Upper Rio Grande)' },
  { value: '130500040912', regionId: '14', label: '130500040912 (Upper Rio Grande)' },
  { value: '130500040907', regionId: '14', label: '130500040907 (Upper Rio Grande)' },
  { value: '130500041001', regionId: '14', label: '130500041001 (Upper Rio Grande)' },
  { value: '130500040913', regionId: '14', label: '130500040913 (Upper Rio Grande)' },
  { value: '130500041003', regionId: '14', label: '130500041003 (Upper Rio Grande)' },
  { value: '130500041002', regionId: '14', label: '130500041002 (Upper Rio Grande)' },
  { value: '130500042506', regionId: '14', label: '130500042506 (Upper Rio Grande)' },
  { value: '130500041700', regionId: '14', label: '130500041700 (Upper Rio Grande)' },
  { value: '130500042302', regionId: '14', label: '130500042302 (Upper Rio Grande)' },
  { value: '130500042301', regionId: '14', label: '130500042301 (Upper Rio Grande)' },
  { value: '130500042304', regionId: '14', label: '130500042304 (Upper Rio Grande)' },
  { value: '130500042303', regionId: '14', label: '130500042303 (Upper Rio Grande)' },
  { value: '130500042306', regionId: '14', label: '130500042306 (Upper Rio Grande)' },
  { value: '130500042305', regionId: '14', label: '130500042305 (Upper Rio Grande)' },
  { value: '130500042308', regionId: '14', label: '130500042308 (Upper Rio Grande)' },
  { value: '130500042307', regionId: '14', label: '130500042307 (Upper Rio Grande)' },
  { value: '130500042401', regionId: '14', label: '130500042401 (Upper Rio Grande)' },
  { value: '130500042309', regionId: '14', label: '130500042309 (Upper Rio Grande)' },
  { value: '130500042403', regionId: '14', label: '130500042403 (Upper Rio Grande)' },
  { value: '130500042402', regionId: '14', label: '130500042402 (Upper Rio Grande)' },
  { value: '130500042405', regionId: '14', label: '130500042405 (Upper Rio Grande)' },
  { value: '130500042404', regionId: '14', label: '130500042404 (Upper Rio Grande)' },
  { value: '130500042501', regionId: '14', label: '130500042501 (Upper Rio Grande)' },
  { value: '130500042406', regionId: '14', label: '130500042406 (Upper Rio Grande)' },
  { value: '130500042503', regionId: '14', label: '130500042503 (Upper Rio Grande)' },
  { value: '130500042502', regionId: '14', label: '130500042502 (Upper Rio Grande)' },
  { value: '130500042505', regionId: '14', label: '130500042505 (Upper Rio Grande)' },
  { value: '130500042504', regionId: '14', label: '130500042504 (Upper Rio Grande)' },
  { value: '130500040911', regionId: '14', label: '130500040911 (Upper Rio Grande)' },
  { value: '130500040906', regionId: '14', label: '130500040906 (Upper Rio Grande)' },
  { value: '130500040909', regionId: '14', label: '130500040909 (Upper Rio Grande)' },
  { value: '130500040908', regionId: '14', label: '130500040908 (Upper Rio Grande)' },
  { value: '130500041004', regionId: '14', label: '130500041004 (Upper Rio Grande)' },
  { value: '130500040910', regionId: '14', label: '130500040910 (Upper Rio Grande)' },
  { value: '130500041303', regionId: '14', label: '130500041303 (Upper Rio Grande)' },
  { value: '130500041302', regionId: '14', label: '130500041302 (Upper Rio Grande)' },
  { value: '130500041305', regionId: '14', label: '130500041305 (Upper Rio Grande)' },
  { value: '130500041304', regionId: '14', label: '130500041304 (Upper Rio Grande)' },
  { value: '130500041006', regionId: '14', label: '130500041006 (Upper Rio Grande)' },
  { value: '130500041005', regionId: '14', label: '130500041005 (Upper Rio Grande)' },
  { value: '130500041101', regionId: '14', label: '130500041101 (Upper Rio Grande)' },
  { value: '130500041007', regionId: '14', label: '130500041007 (Upper Rio Grande)' },
  { value: '130500041103', regionId: '14', label: '130500041103 (Upper Rio Grande)' },
  { value: '130500041102', regionId: '14', label: '130500041102 (Upper Rio Grande)' },
  { value: '130500041105', regionId: '14', label: '130500041105 (Upper Rio Grande)' },
  { value: '130500041104', regionId: '14', label: '130500041104 (Upper Rio Grande)' },
  { value: '130500041107', regionId: '14', label: '130500041107 (Upper Rio Grande)' },
  { value: '130500041106', regionId: '14', label: '130500041106 (Upper Rio Grande)' },
  { value: '130500041202', regionId: '14', label: '130500041202 (Upper Rio Grande)' },
  { value: '130500041201', regionId: '14', label: '130500041201 (Upper Rio Grande)' },
  { value: '130500041204', regionId: '14', label: '130500041204 (Upper Rio Grande)' },
  { value: '130500041203', regionId: '14', label: '130500041203 (Upper Rio Grande)' },
  { value: '130500041301', regionId: '14', label: '130500041301 (Upper Rio Grande)' },
  { value: '130500041205', regionId: '14', label: '130500041205 (Upper Rio Grande)' },
  { value: '130500041405', regionId: '14', label: '130500041405 (Upper Rio Grande)' },
  { value: '130500041306', regionId: '14', label: '130500041306 (Upper Rio Grande)' },
  { value: '130500041401', regionId: '14', label: '130500041401 (Upper Rio Grande)' },
  { value: '130500041307', regionId: '14', label: '130500041307 (Upper Rio Grande)' },
  { value: '130500041403', regionId: '14', label: '130500041403 (Upper Rio Grande)' },
  { value: '130500041402', regionId: '14', label: '130500041402 (Upper Rio Grande)' },
  { value: '130500041406', regionId: '14', label: '130500041406 (Upper Rio Grande)' },
  { value: '130500041404', regionId: '14', label: '130500041404 (Upper Rio Grande)' },
  { value: '130500041108', regionId: '14', label: '130500041108 (Upper Rio Grande)' },
  { value: '130500041407', regionId: '14', label: '130500041407 (Upper Rio Grande)' },
  { value: '130500041503', regionId: '14', label: '130500041503 (Upper Rio Grande)' },
  { value: '130500041502', regionId: '14', label: '130500041502 (Upper Rio Grande)' },
  { value: '130500041505', regionId: '14', label: '130500041505 (Upper Rio Grande)' },
  { value: '130500041504', regionId: '14', label: '130500041504 (Upper Rio Grande)' },
  { value: '130500041602', regionId: '14', label: '130500041602 (Upper Rio Grande)' },
  { value: '130500041601', regionId: '14', label: '130500041601 (Upper Rio Grande)' },
  { value: '130500041604', regionId: '14', label: '130500041604 (Upper Rio Grande)' },
  { value: '130500041603', regionId: '14', label: '130500041603 (Upper Rio Grande)' },
  { value: '130500041802', regionId: '14', label: '130500041802 (Upper Rio Grande)' },
  { value: '130500041801', regionId: '14', label: '130500041801 (Upper Rio Grande)' },
  { value: '130500041804', regionId: '14', label: '130500041804 (Upper Rio Grande)' },
  { value: '130500041803', regionId: '14', label: '130500041803 (Upper Rio Grande)' },
  { value: '130500041901', regionId: '14', label: '130500041901 (Upper Rio Grande)' },
  { value: '130500041805', regionId: '14', label: '130500041805 (Upper Rio Grande)' },
  { value: '130500041903', regionId: '14', label: '130500041903 (Upper Rio Grande)' },
  { value: '130500041902', regionId: '14', label: '130500041902 (Upper Rio Grande)' },
  { value: '130500042001', regionId: '14', label: '130500042001 (Upper Rio Grande)' },
  { value: '130500041904', regionId: '14', label: '130500041904 (Upper Rio Grande)' },
  { value: '130500042003', regionId: '14', label: '130500042003 (Upper Rio Grande)' },
  { value: '130500042002', regionId: '14', label: '130500042002 (Upper Rio Grande)' },
  { value: '130500042101', regionId: '14', label: '130500042101 (Upper Rio Grande)' },
  { value: '130500042004', regionId: '14', label: '130500042004 (Upper Rio Grande)' },
  { value: '130500042103', regionId: '14', label: '130500042103 (Upper Rio Grande)' },
  { value: '130500042102', regionId: '14', label: '130500042102 (Upper Rio Grande)' },
  { value: '130500042105', regionId: '14', label: '130500042105 (Upper Rio Grande)' },
  { value: '130500042104', regionId: '14', label: '130500042104 (Upper Rio Grande)' },
  { value: '130500042107', regionId: '14', label: '130500042107 (Upper Rio Grande)' },
  { value: '130500042106', regionId: '14', label: '130500042106 (Upper Rio Grande)' },
  { value: '130500042201', regionId: '14', label: '130500042201 (Upper Rio Grande)' },
  { value: '130500042108', regionId: '14', label: '130500042108 (Upper Rio Grande)' },
  { value: '130500042203', regionId: '14', label: '130500042203 (Upper Rio Grande)' },
  { value: '130500042202', regionId: '14', label: '130500042202 (Upper Rio Grande)' },
  { value: '130500042205', regionId: '14', label: '130500042205 (Upper Rio Grande)' },
  { value: '130500042204', regionId: '14', label: '130500042204 (Upper Rio Grande)' },
  { value: '130500042207', regionId: '14', label: '130500042207 (Upper Rio Grande)' },
  { value: '130500042206', regionId: '14', label: '130500042206 (Upper Rio Grande)' },
  { value: '130700010104', regionId: '14', label: '130700010104 (Upper Rio Grande)' },
  { value: '130700010102', regionId: '14', label: '130700010102 (Upper Rio Grande)' },
  { value: '130700010106', regionId: '14', label: '130700010106 (Upper Rio Grande)' },
  { value: '130700010105', regionId: '14', label: '130700010105 (Upper Rio Grande)' },
  { value: '130700010503', regionId: '14', label: '130700010503 (Upper Rio Grande)' },
  { value: '130700010401', regionId: '14', label: '130700010401 (Upper Rio Grande)' },
  { value: '130700010505', regionId: '14', label: '130700010505 (Upper Rio Grande)' },
  { value: '130700010504', regionId: '14', label: '130700010504 (Upper Rio Grande)' },
  { value: '130700010902', regionId: '14', label: '130700010902 (Upper Rio Grande)' },
  { value: '130700010506', regionId: '14', label: '130700010506 (Upper Rio Grande)' },
  { value: '130700010201', regionId: '14', label: '130700010201 (Upper Rio Grande)' },
  { value: '130700010103', regionId: '14', label: '130700010103 (Upper Rio Grande)' },
  { value: '130700010203', regionId: '14', label: '130700010203 (Upper Rio Grande)' },
  { value: '130700010202', regionId: '14', label: '130700010202 (Upper Rio Grande)' },
  { value: '130700010205', regionId: '14', label: '130700010205 (Upper Rio Grande)' },
  { value: '130700010204', regionId: '14', label: '130700010204 (Upper Rio Grande)' },
  { value: '130700010206', regionId: '14', label: '130700010206 (Upper Rio Grande)' },
  { value: '130700010207', regionId: '14', label: '130700010207 (Upper Rio Grande)' },
  { value: '130700010302', regionId: '14', label: '130700010302 (Upper Rio Grande)' },
  { value: '130700010301', regionId: '14', label: '130700010301 (Upper Rio Grande)' },
  { value: '130700010304', regionId: '14', label: '130700010304 (Upper Rio Grande)' },
  { value: '130700010303', regionId: '14', label: '130700010303 (Upper Rio Grande)' },
  { value: '130700010402', regionId: '14', label: '130700010402 (Upper Rio Grande)' },
  { value: '130700010305', regionId: '14', label: '130700010305 (Upper Rio Grande)' },
  { value: '130700010404', regionId: '14', label: '130700010404 (Upper Rio Grande)' },
  { value: '130700010403', regionId: '14', label: '130700010403 (Upper Rio Grande)' },
  { value: '130700010406', regionId: '14', label: '130700010406 (Upper Rio Grande)' },
  { value: '130700010405', regionId: '14', label: '130700010405 (Upper Rio Grande)' },
  { value: '130700010408', regionId: '14', label: '130700010408 (Upper Rio Grande)' },
  { value: '130700010407', regionId: '14', label: '130700010407 (Upper Rio Grande)' },
  { value: '130700010602', regionId: '14', label: '130700010602 (Upper Rio Grande)' },
  { value: '130700010601', regionId: '14', label: '130700010601 (Upper Rio Grande)' },
  { value: '130700010604', regionId: '14', label: '130700010604 (Upper Rio Grande)' },
  { value: '130700010603', regionId: '14', label: '130700010603 (Upper Rio Grande)' },
  { value: '130700010701', regionId: '14', label: '130700010701 (Upper Rio Grande)' },
  { value: '130700010605', regionId: '14', label: '130700010605 (Upper Rio Grande)' },
  { value: '130700010703', regionId: '14', label: '130700010703 (Upper Rio Grande)' },
  { value: '130700010702', regionId: '14', label: '130700010702 (Upper Rio Grande)' },
  { value: '130700010704', regionId: '14', label: '130700010704 (Upper Rio Grande)' },
  { value: '130700010901', regionId: '14', label: '130700010901 (Upper Rio Grande)' },
  { value: '130700010801', regionId: '14', label: '130700010801 (Upper Rio Grande)' },
  { value: '130700010705', regionId: '14', label: '130700010705 (Upper Rio Grande)' },
  { value: '130700010803', regionId: '14', label: '130700010803 (Upper Rio Grande)' },
  { value: '130700010802', regionId: '14', label: '130700010802 (Upper Rio Grande)' },
  { value: '130700010904', regionId: '14', label: '130700010904 (Upper Rio Grande)' },
  { value: '130700010903', regionId: '14', label: '130700010903 (Upper Rio Grande)' },
  { value: '130700010905', regionId: '14', label: '130700010905 (Upper Rio Grande)' },
  { value: '130700011003', regionId: '14', label: '130700011003 (Upper Rio Grande)' },
  { value: '130700011001', regionId: '14', label: '130700011001 (Upper Rio Grande)' },
  { value: '130700010906', regionId: '14', label: '130700010906 (Upper Rio Grande)' },
  { value: '130700011004', regionId: '14', label: '130700011004 (Upper Rio Grande)' },
  { value: '130700011002', regionId: '14', label: '130700011002 (Upper Rio Grande)' },
  { value: '130700011006', regionId: '14', label: '130700011006 (Upper Rio Grande)' },
  { value: '130700011005', regionId: '14', label: '130700011005 (Upper Rio Grande)' },
  { value: '130700070510', regionId: '14', label: '130700070510 (Upper Rio Grande)' },
  { value: '130700070509', regionId: '14', label: '130700070509 (Upper Rio Grande)' },
  { value: '130700070603', regionId: '14', label: '130700070603 (Upper Rio Grande)' },
  { value: '130700070601', regionId: '14', label: '130700070601 (Upper Rio Grande)' },
  { value: '130700070606', regionId: '14', label: '130700070606 (Upper Rio Grande)' },
  { value: '130700070605', regionId: '14', label: '130700070605 (Upper Rio Grande)' },
  { value: '130700071102', regionId: '14', label: '130700071102 (Upper Rio Grande)' },
  { value: '130700070607', regionId: '14', label: '130700070607 (Upper Rio Grande)' },
  { value: '130700070209', regionId: '14', label: '130700070209 (Upper Rio Grande)' },
  { value: '130700070206', regionId: '14', label: '130700070206 (Upper Rio Grande)' },
  { value: '130700070507', regionId: '14', label: '130700070507 (Upper Rio Grande)' },
  { value: '130700070406', regionId: '14', label: '130700070406 (Upper Rio Grande)' },
  { value: '130700071404', regionId: '14', label: '130700071404 (Upper Rio Grande)' },
  { value: '130700070508', regionId: '14', label: '130700070508 (Upper Rio Grande)' },
  { value: '130700071709', regionId: '14', label: '130700071709 (Upper Rio Grande)' },
  { value: '130700071405', regionId: '14', label: '130700071405 (Upper Rio Grande)' },
  { value: '130700071805', regionId: '14', label: '130700071805 (Upper Rio Grande)' },
  { value: '130700071802', regionId: '14', label: '130700071802 (Upper Rio Grande)' },
  { value: '130700070901', regionId: '14', label: '130700070901 (Upper Rio Grande)' },
  { value: '130700070705', regionId: '14', label: '130700070705 (Upper Rio Grande)' },
  { value: '130700070604', regionId: '14', label: '130700070604 (Upper Rio Grande)' },
  { value: '130700070602', regionId: '14', label: '130700070602 (Upper Rio Grande)' },
  { value: '130700070702', regionId: '14', label: '130700070702 (Upper Rio Grande)' },
  { value: '130700070701', regionId: '14', label: '130700070701 (Upper Rio Grande)' },
  { value: '130700070704', regionId: '14', label: '130700070704 (Upper Rio Grande)' },
  { value: '130700070703', regionId: '14', label: '130700070703 (Upper Rio Grande)' },
  { value: '130700070801', regionId: '14', label: '130700070801 (Upper Rio Grande)' },
  { value: '130700070706', regionId: '14', label: '130700070706 (Upper Rio Grande)' },
  { value: '130700070803', regionId: '14', label: '130700070803 (Upper Rio Grande)' },
  { value: '130700070802', regionId: '14', label: '130700070802 (Upper Rio Grande)' },
  { value: '130700071001', regionId: '14', label: '130700071001 (Upper Rio Grande)' },
  { value: '130700070804', regionId: '14', label: '130700070804 (Upper Rio Grande)' },
  { value: '130700070806', regionId: '14', label: '130700070806 (Upper Rio Grande)' },
  { value: '130700070805', regionId: '14', label: '130700070805 (Upper Rio Grande)' },
  { value: '130700070902', regionId: '14', label: '130700070902 (Upper Rio Grande)' },
  { value: '130700070807', regionId: '14', label: '130700070807 (Upper Rio Grande)' },
  { value: '130700071002', regionId: '14', label: '130700071002 (Upper Rio Grande)' },
  { value: '130700070903', regionId: '14', label: '130700070903 (Upper Rio Grande)' },
  { value: '130700071004', regionId: '14', label: '130700071004 (Upper Rio Grande)' },
  { value: '130700071003', regionId: '14', label: '130700071003 (Upper Rio Grande)' },
  { value: '130700071006', regionId: '14', label: '130700071006 (Upper Rio Grande)' },
  { value: '130700071005', regionId: '14', label: '130700071005 (Upper Rio Grande)' },
  { value: '130700071201', regionId: '14', label: '130700071201 (Upper Rio Grande)' },
  { value: '130700071101', regionId: '14', label: '130700071101 (Upper Rio Grande)' },
  { value: '130700071402', regionId: '14', label: '130700071402 (Upper Rio Grande)' },
  { value: '130700071202', regionId: '14', label: '130700071202 (Upper Rio Grande)' },
  { value: '130700071403', regionId: '14', label: '130700071403 (Upper Rio Grande)' },
  { value: '130700071301', regionId: '14', label: '130700071301 (Upper Rio Grande)' },
  { value: '130700071303', regionId: '14', label: '130700071303 (Upper Rio Grande)' },
  { value: '130700071302', regionId: '14', label: '130700071302 (Upper Rio Grande)' },
  { value: '130700071305', regionId: '14', label: '130700071305 (Upper Rio Grande)' },
  { value: '130700071304', regionId: '14', label: '130700071304 (Upper Rio Grande)' },
  { value: '130700071406', regionId: '14', label: '130700071406 (Upper Rio Grande)' },
  { value: '130700071401', regionId: '14', label: '130700071401 (Upper Rio Grande)' },
  { value: '130700071502', regionId: '14', label: '130700071502 (Upper Rio Grande)' },
  { value: '130700071501', regionId: '14', label: '130700071501 (Upper Rio Grande)' },
  { value: '130700071504', regionId: '14', label: '130700071504 (Upper Rio Grande)' },
  { value: '130700071503', regionId: '14', label: '130700071503 (Upper Rio Grande)' },
  { value: '130700071705', regionId: '14', label: '130700071705 (Upper Rio Grande)' },
  { value: '130700071505', regionId: '14', label: '130700071505 (Upper Rio Grande)' },
  { value: '130700071601', regionId: '14', label: '130700071601 (Upper Rio Grande)' },
  { value: '130700071506', regionId: '14', label: '130700071506 (Upper Rio Grande)' },
  { value: '130700071603', regionId: '14', label: '130700071603 (Upper Rio Grande)' },
  { value: '130700071602', regionId: '14', label: '130700071602 (Upper Rio Grande)' },
  { value: '130700071702', regionId: '14', label: '130700071702 (Upper Rio Grande)' },
  { value: '130700071701', regionId: '14', label: '130700071701 (Upper Rio Grande)' },
  { value: '130700071704', regionId: '14', label: '130700071704 (Upper Rio Grande)' },
  { value: '130700071703', regionId: '14', label: '130700071703 (Upper Rio Grande)' },
  { value: '130700071707', regionId: '14', label: '130700071707 (Upper Rio Grande)' },
  { value: '130700071706', regionId: '14', label: '130700071706 (Upper Rio Grande)' },
  { value: '130700071801', regionId: '14', label: '130700071801 (Upper Rio Grande)' },
  { value: '130700071708', regionId: '14', label: '130700071708 (Upper Rio Grande)' },
  { value: '130700071804', regionId: '14', label: '130700071804 (Upper Rio Grande)' },
  { value: '130700071803', regionId: '14', label: '130700071803 (Upper Rio Grande)' },
  { value: '130700071901', regionId: '14', label: '130700071901 (Upper Rio Grande)' },
  { value: '130700071806', regionId: '14', label: '130700071806 (Upper Rio Grande)' },
  { value: '130700072001', regionId: '14', label: '130700072001 (Upper Rio Grande)' },
  { value: '130700071902', regionId: '14', label: '130700071902 (Upper Rio Grande)' },
  { value: '130700071904', regionId: '14', label: '130700071904 (Upper Rio Grande)' },
  { value: '130700071903', regionId: '14', label: '130700071903 (Upper Rio Grande)' },
  { value: '130700072003', regionId: '14', label: '130700072003 (Upper Rio Grande)' },
  { value: '130700072002', regionId: '14', label: '130700072002 (Upper Rio Grande)' },
  { value: '130700072005', regionId: '14', label: '130700072005 (Upper Rio Grande)' },
  { value: '130700072004', regionId: '14', label: '130700072004 (Upper Rio Grande)' },
  { value: '130700072007', regionId: '14', label: '130700072007 (Upper Rio Grande)' },
  { value: '130700072006', regionId: '14', label: '130700072006 (Upper Rio Grande)' },
  { value: '130700072101', regionId: '14', label: '130700072101 (Upper Rio Grande)' },
  { value: '130700072008', regionId: '14', label: '130700072008 (Upper Rio Grande)' },
  { value: '130700072103', regionId: '14', label: '130700072103 (Upper Rio Grande)' },
  { value: '130700072102', regionId: '14', label: '130700072102 (Upper Rio Grande)' },
  { value: '130700072105', regionId: '14', label: '130700072105 (Upper Rio Grande)' },
  { value: '130700072104', regionId: '14', label: '130700072104 (Upper Rio Grande)' },
  { value: '120902030101', regionId: '14', label: '120902030101 (Upper Rio Grande)' },
  { value: '130700072106', regionId: '14', label: '130700072106 (Upper Rio Grande)' },
]

const hucsByRegionId = {
  '01': hucsRegion01,
  '02': hucsRegion02,
  '03': hucsRegion03,
  '04': hucsRegion04,
  '05': hucsRegion05,
  '06': hucsRegion06,
  '07': hucsRegion07,
  '08': hucsRegion08,
  '09': hucsRegion09,
  '10': hucsRegion10,
  '11': hucsRegion11,
  '12': hucsRegion12,
  '13': hucsRegion13,
  '14': hucsRegion14,
  '15': hucsRegion15,
}

const allHucOptions = Object.keys(hucsByRegionId).reduce((accum, key) => {
  let biggerList = [...accum, ...hucsByRegionId[key]]
  return biggerList;
}, [])

export const getHucOptionsByRegionId = (regionId) => regionId ? hucsByRegionId[regionId] : allHucOptions;

// TODO: this should be sufficient once we have regionId:
export const getHucOptionsByIds = (hucIds, regionId) => hucIds.map(hucId => getHucOptionsByRegionId(regionId).find(obj => obj.value === hucId))
  
