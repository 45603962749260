import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { uploadConflictsByIdSelector, recordByIdSelector } from '../selectors/entities';
import { SHADOW_CRISP } from '../constants/cssVars';
import { LinkLikeButton } from './Button';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import warningIcon from '../assets/icons/warning.svg'

const NotificationBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${({ error }) => error ? '#E29797' : '#E9D77F'};
  color: ${({ error }) => error ? '#690B0B' : '#453E1D'};
  padding: 10px;
  border-radius: 2px;
  box-shadow: ${SHADOW_CRISP};
  width: calc(100% - 20px);
  font-size: 0.9em;
  margin-bottom: 10px;
`

const NotificationHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 5px;
  > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > h3 {
      color: #453E1D;
      margin: 0px;
    }
    > img {
      width: 19px;
      height: 20px;
      margin-right: 10px;
    }
  }
`

const UploadConflict = ({ tdisDataIdentifier }) => {
  const history = useHistory();
  const [dismissed, setDismissed] = useState(false);
  const record = useSelector(state => recordByIdSelector(state, tdisDataIdentifier))

  if (dismissed || !record) {
    return null;
  }

  return <NotificationBar>
    <NotificationHeader>
      <div>
        <img src={warningIcon} alt="warning icon" />
        <h3>
          Multiple Uploads Detected
        </h3>
      </div>
      <LinkLikeButton style={{ color: '#453E1D', fontSize: '0.9em' }} onClick={() => setDismissed(true)}>
        Dismiss
      </LinkLikeButton>
    </NotificationHeader>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>
        Multipled conflicting uploads were detected for "{record.currentMetadata.modelName}". 
        <LinkLikeButton short={true} onClick={() => history.push(`/app/view/${tdisDataIdentifier}`)} style={{ color: '#453E1D', marginLeft: '5px' }} >
          Resolve Now
        </LinkLikeButton>
      </div>
    </div>
  </NotificationBar>
}

const UploadConflictsWrapper = styled.div`
  > div:last-child {
    margin-bottom: 30px;
  }
`

export const UploadConflictsBanner = () => {
  const uploadConflictsById = useSelector(uploadConflictsByIdSelector);

  return (
    <UploadConflictsWrapper>
      {Object.keys(uploadConflictsById).map((recordIdWithConflict, index) => {
        // if there's actually a non-null conflict entry for this record ...
        if (uploadConflictsById[recordIdWithConflict]) {
          return <UploadConflict key={`conflict_${index}`} tdisDataIdentifier={recordIdWithConflict} />
        }
        return null;
      })}
    </UploadConflictsWrapper>
  )
}