export const SET_EXAMPLE_MODAL = 'SET_EXAMPLE_MODAL';
export const SET_HISTORY_MODAL = 'SET_HISTORY_MODAL';
export const SET_CONTACT_MODAL = 'SET_CONTACT_MODAL';
export const SET_PREVIEW_MODAL = 'SET_PREVIEW_MODAL';

export const setExampleModal = (showModal) => {
  return { type: SET_EXAMPLE_MODAL, payload: showModal };
}

export const setHistoryModal = (showModal) => {
  return { type: SET_HISTORY_MODAL, payload: showModal };
}

export const setContactModal = ({ showModal, contactId, isOriginator, recordId, index, selectExisting }) => {
  return { type: SET_CONTACT_MODAL, payload: { showModal, contactId, isOriginator, recordId, index, selectExisting }};
}

export const setPreviewModal = ({ showModal, fileObj }) => {
  return { type: SET_PREVIEW_MODAL, payload: { showModal, fileObj }};
}