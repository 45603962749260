import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';
import { ERROR, GREY_DARK, GREY_DARKEST, GREY_LIGHT, GREY_MEDIUM, GREY_MEDIUM_DARK, ICE, MAIN_CONTENT_WIDTH, PRIMARY, SAGE, SHADOW_CRISP, SLATE } from '../constants/cssVars';
import { useYMLDownload } from '../hooks/fileDownloads/useYMLDownload';
import { Button, InvertedButton } from './Button';
import { Footer, TextLink } from './Common';
import copyIcon from '../assets/icons/copy_sage.svg'
import { useBashDownload } from '../hooks/fileDownloads/useBashDownload';
import { usePowershellDownload } from '../hooks/fileDownloads/usePowershellDownload';
import { useSelectedRecord } from '../hooks/useSelectedRecord';
import { useRequest } from 'redux-query-react';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { getRecordQuery } from '../actions/queries';
import { WaveLoader } from './Loaders';

import macDownloadMetadataGif from '../assets/instructionalImages/mac/download_metadata.gif'
import macCompressImg from '../assets/instructionalImages/mac/compress.png'
import macPathnameModelZipGif from '../assets/instructionalImages/mac/pathname_model_zip.gif'
import macPathnameScriptGif from '../assets/instructionalImages/mac/pathname_script.gif'
import macUploadGif from '../assets/instructionalImages/mac/upload.gif'

import windowsDownloadMetadataGif from '../assets/instructionalImages/windows/download_metadata.gif'
import windowsCompressImg from '../assets/instructionalImages/windows/compress.png'
import windowsPathnameModelZipGif from '../assets/instructionalImages/windows/pathname_model_zip.gif'
import windowsPathnameScriptGif from '../assets/instructionalImages/windows/pathname_script.gif'
import windowsUploadGif from '../assets/instructionalImages/windows/upload.gif'
import windowsUnblockGif from '../assets/instructionalImages/windows/unblock.gif'
import { Tooltip } from './general/Tooltip';
import { useGetRecordQuery } from '../hooks/messagesAndRequests';


require('dotenv').config()

const CRON_INTERVAL = process.env.REACT_APP_CRON_INTERVAL || '15 minutes';

const Content = styled.div`
  width: ${MAIN_CONTENT_WIDTH}px;
  @media only screen and (max-width: ${MAIN_CONTENT_WIDTH}px) {
    width: 100%;
  }
  color: ${PRIMARY};
  p {
    margin: 20px 0px;
    margin-top: 60px;
  }
`

const FigureWrapper = styled.figure`
  > img {
    max-width: 90%;
    width: 600px;
    margin: 10px 0px;
    border: 1px solid ${GREY_MEDIUM};
    margin-bottom: 3px;
  }
  > figcaption {
    max-width: 90%;
    width: 600px;
    font-style: italic;
  }
  margin: 10px 0px;
  font-size: 0.9em;
  color: ${GREY_MEDIUM_DARK};
`

const UploadErrorNote = styled.div`
  background-color: #d8e4ec;
  padding: 15px;
  margin: 0px -15px;
  font-size: 0.95em;
  box-shadow: ${SHADOW_CRISP};
  margin-top: 15px;
  > div {
    font-size: 1.1em;
    padding: 10px 15px;
    background-color: ${GREY_DARKEST};
    color: red;
    font-family: monospace;
    margin-top: 15px;
  }
`

const ExampleStructureWrapper = styled.div`
  background-color: ${GREY_LIGHT};
  padding: 10px;
  border-radius: 4px;
  font-size: 0.9em;
  color: ${GREY_DARK};
  > div {
    padding-left: 25px;
    margin: 5px;
  }
  > div:first-child {
    padding-left: 0px;
  }
`

const CodeBoxWrapper = styled.div`
  font-size: 1em;
  margin: 10px 0px;
  margin-bottom: 20px;
  padding: 6px 10px;
  background-color: ${SLATE};
  border-left: 6px solid ${SAGE};
  color: ${ICE};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background-color: ${PRIMARY};
  }
  > img {
    width: 18px;
    height: 18px;
  }
  > div {
    font-size: 0.85em;
  }
`

// Copyable code box
const CodeBox = ({ children }) => {
  const [copied, setCopied] = useState(false);
  const copyToClipboard = () => {
    setCopied(true);
    navigator.clipboard.writeText(children);
  }

  useEffect(() => {
      if (copied) {
        const timer = setTimeout(() => setCopied(false), 1000);
        return () => {
          clearTimeout(timer);
        };
      }
    },
    [copied]
  );

  return <CodeBoxWrapper onClick={copyToClipboard}>
    <code>{children}</code>
    {copied ? <div>Copied</div> : <img src={copyIcon} alt="copy squares" />}
  </CodeBoxWrapper>
}

const WINDOWS = "WINDOWS";
const MAC_LINUX = "MAC_LINUX";

// Steps specific to users with a Mac or other Linux/Unix based system
const MacLinuxInstructions = () => {
  const { recordId } = useParams();
  // Path to the model zip file
  const [zipPath, setZipPath] = useState('');
  // Path to the upload script
  const [scriptPath, setScriptPath] = useState('');
  const downloadBashScript = useBashDownload(recordId);
  const [selectedRecord] = useSelectedRecord();
  const downloadYMLFile = useYMLDownload();

  const handleYamlDownload = () => {
    downloadYMLFile(selectedRecord, recordId);
  }


  return (
    <div>
      <hr style={{ marginTop: '20px' }} />

      <h3>Mac or Linux/Unix System Instructions</h3>

      <p style={{ marginTop: '30px' }}>
        <b>1.</b> Use the button below to download a YAML file with your metadata. Place this file into the <b>root directory</b> of your model.
      </p>
      <Button onClick={handleYamlDownload}>Download Metadata YAML file</Button>

      <FigureWrapper>
        <img src={macDownloadMetadataGif} alt="hi" />
        <figcaption>
          In the above example, we want to upload the "010000000021_HEC-FDA" model. We move the downloaded metadata YAML file into the top-level of the "010000000021_HEC-FDA" folder.
        </figcaption>
      </FigureWrapper>

      <p>
        <b>2.</b> Once your metadata YAML file has been placed in the root directory of your model, <b>zip or compress</b> your model's root directory.
      </p>

      <FigureWrapper>
        <img src={macCompressImg} alt="hi" />
        <figcaption>
        On a Mac, we can right-click our "010000000021_HEC-FDA" folder and select "Compress '010000000021_HEC-FDA'" to create a zip file.
        </figcaption>
      </FigureWrapper>

      <p>
        <b>3.</b> Next, paste the path to your <b>zip file</b> into the input below. On Mac, you can copy this path by <b>right-clicking</b> on your zip file and pressing the <b>option</b> key. 
        You should see a "Copy ___ as pathname" option. Select this option to copy the pathname, then paste the pathname into the input below.
      </p>

      <label htmlFor="zipName" style={{ fontSize: '0.9em', display: 'block', color: GREY_MEDIUM_DARK, marginBottom: '5px' }}> 
        Path to your model zip file
      </label>
      <input name="zipName" style={{ maxWidth: '600px', fontSize: '0.9em' }} onChange={e => setZipPath(e.target.value)} value={zipPath} placeholder={'e.g. /Users/example/Desktop/Models/010000000021_HEC-FDA.zip'}/>

      <FigureWrapper>
        <img src={macPathnameModelZipGif} alt="hi" />
        <figcaption>
          In this example, we right-click on the zip file, press the "option" key, and select the "Copy '010000000021_HEC-FDA.zip' as pathname" option.
        </figcaption>
      </FigureWrapper>

      <p>
        <b>4.</b> Click the button below to download a script file called <b>upload_script.sh</b>. We'll use this to run your upload.
      </p>
      <Button onClick={downloadBashScript}>Download Script</Button>

      <p>
        <b>5.</b> Similar to Step 3, paste the path to the <b>upload_script.sh</b> file you just downloaded into the input below. On Mac, you can copy this path by <b>right-clicking</b> on the file and pressing the <b>option</b> key. 
        You should see a "Copy 'upload_script.sh' as pathname" option. Select this option to copy the pathname, then paste the pathname into the input below.
      </p>

      <label htmlFor="scriptPath" style={{ fontSize: '0.9em', display: 'block', color: GREY_MEDIUM_DARK, marginBottom: '5px' }}> 
        Path to your upload_script.sh file
      </label>
      <input name="scriptPath" style={{ maxWidth: '600px', fontSize: '0.9em' }} onChange={e => setScriptPath(e.target.value)} value={scriptPath} placeholder={'e.g. /Users/example/Downloads/upload_script.sh'}/>

      <FigureWrapper>
        <img src={macPathnameScriptGif} alt="hi" />
        <figcaption>
          In this example, we right-click on the downloaded script file, press the "option" key, and select the "Copy 'upload_script.sh' as pathname" option.
        </figcaption>
      </FigureWrapper>

      <p>
        <b>6.</b> Now it's time to run the script. On Mac, open the "Terminal" app. Click the box below to copy the command, paste it into your terminal, and press "enter" to run the command.
      </p>

      {(scriptPath.length < 1 || zipPath.length < 1) &&
        <p style={{ margin: '0px', color: ERROR }}>
          WARNING: It looks like you haven't filled out all the inputs needed to generate this command! Please make sure you have filled out the inputs in steps 3 & 5 above before using the following command.
        </p>
      }

      <CodeBox>
        {`bash "${scriptPath}" "${zipPath}"`}
      </CodeBox>

      <FigureWrapper>
        <img src={macUploadGif} alt="hi" />
        <figcaption>
          In this example, we paste our copied command directly into the terminal and press the enter or "return" key to run it. Successful upload output is printed.
        </figcaption>
      </FigureWrapper>

      <p style={{ margin: '10px 0px' }}>
        If your upload is successful, you should see a "HTTP/1.1 200 OK" message included in the output. 
      </p>

      <p>
        <b>7.</b> Once your upload has completed, you're all set! Please wait <b>{CRON_INTERVAL}</b> for our systems to process your upload. If your upload is large (upwards of 15G), please allow some extra time for processing.
        If your upload is successfully processed, you will see your model's status changed from "in progress" to "pending admin acceptance".
      </p>
    </div>
  )
}

// The windows pathnames (when copied using the instructions) contain double quotes
// We want to remove these if present & replace them with single quotes
const getSingleQuoteWrappedString = (windowsPath) => {
  if (!windowsPath || windowsPath === "" || windowsPath.length < 2) {
    return windowsPath;
  }
  let newPath = windowsPath;
  // if the path starts and ends with double quotes ...
  if (windowsPath[0] === `"` && windowsPath[windowsPath.length - 1] === `"`) {
    // Remove first & last characters
    newPath = newPath.slice(1);
    newPath = newPath.slice(0, -1);
  }
  // if the path already starts and ends with single quotes ...
  if (windowsPath[0] === `'` && windowsPath[windowsPath.length - 1] === `'`) {
    // Just return it
    return windowsPath;
  }
  // wrap in single quotes & return
  return `'${newPath}'`
}

// Steps specific to users with a Windows systme
const WindowsInstructions = () => {
  const { recordId } = useParams();
  const [selectedRecord] = useSelectedRecord();
  // Path to the model zip file
  const [zipPath, setZipPath] = useState('');
  // Path to the upload script
  const [scriptPath, setScriptPath] = useState('');
  const downloadPowershellScript = usePowershellDownload(recordId);
  const downloadYMLFile = useYMLDownload();

  const handleYamlDownload = () => {
    downloadYMLFile(selectedRecord, recordId);
  }

  return (
    <div>
      <hr style={{ marginTop: '20px' }} />

      <h3>Windows Instructions</h3>

      <p style={{ marginTop: '30px' }}>
        <b>SOFTWARE REQUIREMENT:</b> You will need Powershell 7.2 to run the upload script. You can check your Powershell version by opening the Powershell application, copying and pasting the text "<b>$PSVersionTable</b>" into the command prompt window, and pressing enter.
            If you have an earlier version, please install 7.2 by following the <TextLink to="https://docs.microsoft.com/en-us/powershell/scripting/install/installing-powershell-on-windows?view=powershell-7.2#installing-the-msi-package">Powershell 7.2 installation instructions.</TextLink>
      </p>

      <p style={{ marginTop: '40px' }}>
        <b>1.</b> Use the button below to download a YAML file with your metadata. Place this file into the <b>root directory</b> of your model.
      </p>
      <Button onClick={handleYamlDownload}>Download Metadata YAML file</Button>

      <FigureWrapper>
        <img src={windowsDownloadMetadataGif} alt="hi" />
        <figcaption>
          In the above example, we want to upload the "010000000021_HEC-FDA" model. We move the downloaded metadata YAML file into the top-level of the "010000000021_HEC-FDA" folder.
        </figcaption>
      </FigureWrapper>

      <p style={{ marginBottom: '10px'}}>
        <b>2.</b> Once your metadata YAML file has been placed in the root directory of your model, <b>zip or compress</b> your model's root directory.
      </p>

      <p style={{ marginTop: '0px'}}>
        <i>Note: Please don't include compressed files within your model folder. Zipping/compressing the whole folder should work just fine.</i>
      </p>

      <FigureWrapper>
        <img src={windowsCompressImg} alt="hi" />
        <figcaption>
          We can right-click our "010000000021_HEC-FDA" folder, hover over "Send to", then select "Compressed (zipped) folder".
        </figcaption>
      </FigureWrapper>

      <p>
        <b>3.</b> Next, paste the path to your <b>zip file</b> into the input below. To copy this path, click on your zip file to <b>select</b> it, hold down the <b>shift</b> key, and while still holding shift, <b>right-click</b> on the file.
        Select the "<b>Copy as path</b>" option, then paste the pathname into the input below.
      </p>

      <label htmlFor="zipName" style={{ fontSize: '0.9em', display: 'block', color: GREY_MEDIUM_DARK, marginBottom: '5px' }}> 
        Path to your model zip file
      </label>
      <input name="zipName" style={{ maxWidth: '600px', fontSize: '0.9em' }} onChange={e => setZipPath(e.target.value)} value={zipPath} placeholder={'e.g. "C:\\Users\\12345\\Desktop\\Models\\010000000021_HEC-FDA.zip"'}/>

      <FigureWrapper>
        <img src={windowsPathnameModelZipGif} alt="hi" />
        <figcaption>
          In this example, we select our zip file by clicking on it, hold down shift, right-click on the zip file, then select "Copy as path".
        </figcaption>
      </FigureWrapper>

      <p>
        <b>4.</b> Click the button below to download a script file called <b>upload_script.sh</b>. We'll use this to run your upload.
      </p>
      <Button onClick={downloadPowershellScript}>Download Script</Button>

      <p>
        <b>5.</b> Similar to Step 3, paste the path to <b>upload_script.ps1</b> file you just downloaded into the input below. To copy this path, click on the script file to <b>select</b> it, hold down the <b>shift</b> key, and while still holding shift, <b>right-click</b> on the file.
        Select the "<b>Copy as path</b>" option, then paste the pathname into the input below.
      </p>

      <label htmlFor="scriptPath" style={{ fontSize: '0.9em', display: 'block', color: GREY_MEDIUM_DARK, marginBottom: '5px' }}> 
        Path to your upload_script.sh file
      </label>
      <input name="scriptPath" style={{ maxWidth: '600px', fontSize: '0.9em' }} onChange={e => setScriptPath(e.target.value)} value={scriptPath} placeholder={'e.g. "C:\\Users\\12345\\Downloads\\upload_script.ps1"'}/>

      <FigureWrapper>
        <img src={windowsPathnameScriptGif} alt="hi" />
        <figcaption>
          In this example, we select the script file by clicking on it, hold down shift, right-click on the script file, then select "Copy as path".
        </figcaption>
      </FigureWrapper>

      <p>
        <b>6.</b> In order for Powershell to execute this script, we'll also need to <b>unblock</b> it. We can unblock the script by right-clicking on the script, selecting "Properties", & clicking the "Unblock" checkbox at the bottom of the properties window. 
        To save, click "Apply", then click "Ok".
      </p>

      <FigureWrapper>
        <img src={windowsUnblockGif} alt="hi" />
        <figcaption>
          In this example, we right-click on the script file & select "Properties" to open the properties window. We then check "Unblock", click "Apply", then click "Ok".
        </figcaption>
      </FigureWrapper>

      <p>
        <b>7.</b> Now it's time to run the script. Open the <b>Powershell 7</b> application. <strong>Please note that "Windows Powershell" and "Powershell 7" are different applications - please make sure you are using "Powershell 7".</strong> Click the box below to copy the command, paste it into the Powershell window, and press "enter" to run the command.
      </p>

      {(scriptPath.length < 1 || zipPath.length < 1) &&
        <p style={{ margin: '0px', color: ERROR }}>
          WARNING: It looks like you haven't filled out all the inputs needed to generate this command! Please make sure you have filled out the inputs in steps 3 & 5 above before using the following command.
        </p>
      }

      <CodeBox>
        {`& ${getSingleQuoteWrappedString(scriptPath)} ${getSingleQuoteWrappedString(zipPath)}`}
      </CodeBox>

      <FigureWrapper>
        <img src={windowsUploadGif} alt="hi" />
        <figcaption>
          In this example, we paste our copied command directly into the Powershell 7.2 window and press the enter or "return" key to run it. Successful upload output is printed.
        </figcaption>
      </FigureWrapper>

      <p style={{ margin: '10px 0px' }}>
        If your upload is successful, you should see a "Status: 200" message included in the output. 
      </p>

      <UploadErrorNote>
        <strong>Note:</strong> If you get the following error messages, your upload <strong>may still have been successful</strong>.
        Please wait {CRON_INTERVAL}, then refresh the home page to see if your model's status has been updated.
        <div>
          Unable to read data from the transport connection: An existing connection was forcibly closed by the remote host.
        </div>
        <div style={{ marginTop: '10px' }}>
          Internal Server Error
        </div>
      </UploadErrorNote>

      <p>
        <b>8.</b> Once your upload has completed, you're all set! Please wait <b>{CRON_INTERVAL}</b> for our systems to process your upload. If your upload is large (upwards of 15G), please allow some extra time for processing.
        If your upload is successfully processed, you will see your model's status changed from "in progress" to "pending admin acceptance".
      </p>
    </div>
  )
}

// Wrapper instructions component, where the user can select their system
export const Instructions = () => {
  // User should select their system type so we can show them the proper instructions
  // Types include "WINDOWS" or "MAC_LINUX"
  const [systemType, setSystemType] = useState(null); 
  const { recordId } = useParams();
  const { accessToken } = useCurrentUser();
  const [selectedRecord] = useSelectedRecord();
  useRequest(accessToken && !selectedRecord ? getRecordQuery(recordId, accessToken) : null);
  const history = useHistory();
  const [getRecord, getRecordPending] = useGetRecordQuery(() => history.push(`/app/`));
  const handleDone = () => {
    getRecord(recordId, accessToken);
  }

  let instructionsComponent = null;
  // if a system type is selected, show the system's instructions
  if (systemType != null && selectedRecord != null) {
    instructionsComponent = systemType === WINDOWS ? <WindowsInstructions /> : <MacLinuxInstructions />
  }

  return (
    <Content>
      <h2>Upload Instructions</h2>

      <p style={{ marginTop: '30px' }}>
        Before we get started, your model should be organized in the following structure. Hover over an item for more information.
      </p>

      <ExampleStructureWrapper>
        <div>
          <Tooltip style={{ width: '300px'}} content="Example: '010000000021_HEC-FDA'. This is the folder containing your model. Replace '<MODEL_ID>' with your model's ID and '<MODEL_NAME>' with your model's name.">
            {`<MODEL_ID>_<MODEL_NAME>/`}
          </Tooltip>
        </div>
        <div>
          <Tooltip content="Folder containing your model files, named 'Model_Files'.">
            Model_Files/
          </Tooltip>
        </div>
        <div>
          <Tooltip content="Folder containing any supporting documentation, named 'Supporting'. This may be empty, but please still include.">
            Supporting/
          </Tooltip>
        </div>
        <div>          
          <Tooltip content="Folder containing GIS files, named 'GIS'. This may be empty, but please still include.">
            GIS/
          </Tooltip>
        </div>
        <div>
          <Tooltip style={{ width: '300px'}} content="Example: 'Model_01000000000014_Coverage.gdb'. This is a zipped geodatabase with a feature class containing the spatial area covered by this model.">
            {`Model_<MODEL_ID>_Coverage.gdb`}
          </Tooltip>
        </div>
      </ExampleStructureWrapper>

      <p style={{ marginTop: '30px' }}>
        To view the upload instructions, please select your system below:
      </p>

      <div>
        <InvertedButton active={systemType === WINDOWS} onClick={() => setSystemType(WINDOWS)}>
          Windows
        </InvertedButton>
        <InvertedButton active={systemType === MAC_LINUX} onClick={() => setSystemType(MAC_LINUX)}>
          Mac or Linux/Unix
        </InvertedButton>
      </div>

      {selectedRecord == null && <WaveLoader />}

      {instructionsComponent}

      <div style={{width: '100%', height: '130px'}}>
      </div>
      <Footer>
        <div>
          <div>
            <InvertedButton onClick={() => history.push(`/app/form/${recordId}`)}>
              Back
            </InvertedButton>
            <Button short={true} style={{ width: '180px'}} onClick={handleDone} isLoading={getRecordPending}>
              Done
            </Button>
          </div>
        </div>
      </Footer>
    </Content>
  )
}