import React from 'react'
import styled from 'styled-components'
import { GREY_DARK, MAIN_CONTENT_WIDTH } from '../constants/cssVars'
import { useHistory } from 'react-router-dom'
import { BackIcon } from './Settings'
import { Button, InvertedButton } from './Button'
import { Footer, TextLink } from './Common'
require('dotenv').config()

const CRON_INTERVAL = process.env.REACT_APP_CRON_INTERVAL || '30 minutes'

const Wrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 100px;
  width: ${MAIN_CONTENT_WIDTH}px;
  @media only screen and (max-width: ${MAIN_CONTENT_WIDTH}px) {
    width: 100%;
  }
  color: ${GREY_DARK};
  > p {
    font-size: 0.95em;
    padding: 0px 10px;
  }
  > h3 {
    padding: 0px 10px;
  }
`

const BackButton = styled.button`
  background-color: transparent;
  border: none;
  > img {
    width: 40px;
    height: 30px;
  }
`

// Page to give overview of process to help provide context
export const GeneralInfo = () => {
  const history = useHistory()
  return (
    <Wrapper>
      <BackButton onClick={() => history.goBack()} ariaLabel="back">
        <BackIcon alt="back arrow" />
      </BackButton>
      <h2 style={{ marginLeft: '10px', marginTop: '10px' }}>
        About metadata records & the upload process
      </h2>
      <p>
        This site allows you to create metadata records associated with your
        models, help you upload your models, and track the status of your
        uploads.
      </p>
      <p>
        For full guides and instructions, please reference the official{' '}
        <TextLink to="https://ms2metadatauserguide.z21.web.core.windows.net">
          MS² documentation
        </TextLink>
        .
      </p>
      <h3>Creating metadata records</h3>
      <p>
        A <b>metadata record</b> is used to store information about a{' '}
        <strong>single</strong> model. If you have multiple models, each model
        should have their own metadata record.
      </p>
      <p>
        Once you've created a metadata record, you can <b>duplicate</b> it to
        easily create a new, editable copy for use on a different model.
      </p>
      <h3>Uploading models</h3>
      <p>
        After you've filled out the metadata record form, you'll download a{' '}
        <b>metadata YAML file</b> that will be populated with your metadata
        information & proceed to the upload instructions.
      </p>
      <p>
        This metadata YAML file will be placed into the root directory of your
        model.
      </p>
      <p>
        Next, we'll provide you with an <b>upload script</b> to run on your
        computer, specifying the location of your compressed model directory
        with the metadata YAML file inside.
      </p>
      <h3>Tracking your upload status</h3>
      <p>
        After a successful upload, leave about <strong>{CRON_INTERVAL}</strong>{' '}
        for our systems to process your upload.
      </p>
      <p>
        Once processing is complete, you'll see your model updated with a{' '}
        <b>"pending admin acceptance"</b> status. You should be able to view the
        files that you uploaded, & send & receive messages from a TWDB admin at
        this point.
      </p>
      <p>
        If an admin accepts your model, your model's status will be updated to{' '}
        <b>"accepted"</b>. If is rejected, the status will be <b>"rejected"</b>{' '}
        and you'll be provided detailed reasons about why your model was
        rejected.
      </p>

      <Footer>
        <div>
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <InvertedButton onClick={() => history.goBack()}>
              Back
            </InvertedButton>
          </div>
        </div>
      </Footer>
    </Wrapper>
  )
}
