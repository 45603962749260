import React from 'react'
import { Modal, ModalHeader } from './Modal'
import { useDispatch, useSelector } from 'react-redux'
import {
  previewFileObjSelector,
  previewModalSelector,
} from '../selectors/modals'
import { setPreviewModal } from '../actions/modals'

const TDIS_DATA_CATALOG_URL_BASE =
  process.env.REACT_APP_TDIS_DATA_CATALOG_URL_BASE ||
  'https://tdisproddatalakehouse.blob.core.windows.net/tdis-data-bronze/ms2'

const SAS_TOKEN = process.env.REACT_APP_SAS_TOKEN || 'test'

export const FilePreviewModal = () => {
  // Index of the history field to compare, show comparison screen if non-null
  const showModal = useSelector(previewModalSelector)
  const fileObj = useSelector(previewFileObjSelector)
  const dispatch = useDispatch()

  if (!showModal || !fileObj) {
    return null
  }

  const url = `${TDIS_DATA_CATALOG_URL_BASE}/${fileObj.properties.path}${SAS_TOKEN}`

  const onClose = () => {
    dispatch(setPreviewModal({ showModal: false, fileObj: null }))
  }

  return (
    <Modal width={650} onClose={onClose}>
      <ModalHeader>File Viewer</ModalHeader>
      <iframe title="file preview" src={url} width={630} height={400} />
    </Modal>
  )
}
