import React from 'react'
import styled from 'styled-components'
import { GREY_DARK, MAIN_CONTENT_WIDTH } from '../constants/cssVars'
import { useHistory } from 'react-router-dom'
import { BackIcon } from './Settings'
import { InvertedButton } from './Button'
import { Footer } from './Common'

const Wrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 100px;
  width: ${MAIN_CONTENT_WIDTH}px;
  @media only screen and (max-width: ${MAIN_CONTENT_WIDTH}px) {
    width: 100%;
  }
  color: ${GREY_DARK};
  > p {
    font-size: 0.95em;
    padding: 0px 10px;
  }
  > h3 {
    padding: 0px 10px;
  }
`

const BackButton = styled.button`
  background-color: transparent;
  border: none;
  > img {
    width: 40px;
    height: 30px;
  }
`

// Page to provide support contact information
export const Support = () => {
  const history = useHistory()
  return (
    <Wrapper>
      <BackButton onClick={() => history.goBack()} ariaLabel="back">
        <BackIcon alt="back arrow" />
      </BackButton>
      <h2 style={{ marginLeft: '10px', marginTop: '10px' }}>Support</h2>
      <p>
        If you run into any persistent issues with the site or upload process,
        please send an email addressed to TDIS Support (tdis@tamu.edu) with
        details on your experience.
      </p>
      <Footer>
        <div>
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <InvertedButton onClick={() => history.goBack()}>
              Back
            </InvertedButton>
          </div>
        </div>
      </Footer>
    </Wrapper>
  )
}
