// Initial values for generating new metadata 
export const metadataInit = {
  twdbFloodPlanningProjectID: [],
  twdbFloodPlanningRegionID: '',
  tdisCollectionIdentifier: null,
  county: [],
  city: [],
  spatialExtentShapefile: [],
  timePeriodCovered: [[]],
  modelName: '',
  modelDescription: '',
  modelType: [],
  modelIdentifier: '',
  softwareName: '',
  softwareVersion: '',
  modelOriginatorAgencyId: '',
  modelCreationDate: '',
  modelLastUpdatedDate: '',
  modelUpdateFrequency: 'None',
  useConstraint: ['Public'],
  contacts: [],
  documentationFilenames: '',
  chainOfCustody: [],
  hydrologicUnitCode: [],
  modelPurpose: '',
}

// User-friendly names & descriptions for each metadata record field,
// & whether or not the field is required & accepts multiple values
export const metadataFieldInfo = {
  twdbFloodPlanningProjectID: {
    friendlyName: 'TWDB Flood Planning Project Identifier(s)',
    description: 'Identifier(s) associated with FMP, FME, FMS in the regional flood plan. Each entry is a 9 digit number where the first 2 digits are the Flood Planning Region identifier; followed by "1" (for FME), "2" (for FMS), or "3" (for FMP); followed by a 6-digit unique sequence generated by vendor.',
    required: true,
    acceptsMultipleValues: true,
  },
  twdbFloodPlanningRegionID: {
    friendlyName: 'TWDB Flood Planning Region Identifier',
    description: 'Flood Planning Region number.',
    required: true,
    acceptsMultipleValues: false,
  },
  tdisCollectionIdentifier: {
    friendlyName: 'TDIS Collection Identifier',
    description: 'The TDIS unique identifier for the associated collection.',
    required: false,
    acceptsMultipleValues: false,
  },
  modelIdentifier: {
    friendlyName: 'Model Identifier (MODEL_ID)',
    description: 'MODEL_ID from ModelCoverage feature class. This is a 12-digit number where first 2-digits are the Flood Planning Region identifier, followed by regionally-unique 10-digit number.',
    required: true,
    acceptsMultipleValues: false,
  },
  hydrologicUnitCode: {
    friendlyName: 'Hydrologic Unit Code(s) (HUC)',
    description: '12-digit Hydrologic Unit Code(s) identifying the watershed(s).',
    required: false, 
    acceptsMultipleValues: true,
  },
  county: {
    friendlyName: 'Counties',
    description: 'County or counties that your model spans.',
    required: true,
    acceptsMultipleValues: true,
  },
  city: {
    friendlyName: 'Cities',
    description: 'City or cities that your model spans; will be used for spatial analysis.',
    required: false,
    acceptsMultipleValues: true,
  },
  spatialExtentShapefile: {
    friendlyName: 'Upload Spatial Extent of Model',
    description: 'Please upload a zipped geodatabase with a feature class containing the spatial area covered by this model.',
    required: true,
    acceptsMultipleValues: false,
  },
  timePeriodCovered: {
    friendlyName: 'Time Period(s) Covered',
    description: 'The time period(s) to which the data refer as a single date-time or an interval. This is not a required field. This reflects the time period covered by the data, not the dates of coding or making documents machine-readable or the dates the data were collected. Also known as the "span" covered by the data, this may reflect the dates of future conditions, event conditions, existing land-use, etc.',
    required: false,
    acceptsMultipleValues: true,
    isDate: true,
  },
  modelName: {
    friendlyName: 'Model Name',
    description: "A name given to the model and it's associated configuration.",
    required: true,
    acceptsMultipleValues: false,
  },
  modelOriginatorAgencyId: {
    friendlyName: 'Model Originator Agency',
    description: 'The name of organization(s) or person(s) who should be cited as contributing to the creation of the model. Also referred to as an "author", "contributor", "creator".',
    required: true,
    acceptsMultipleValues: false,
  },
  modelCreationDate: {
    friendlyName: 'Model Creation Date',
    description: 'The date the model was created.',
    required: true,
    acceptsMultipleValues: false,
    isDate: true,
  },
  modelPurpose: {
    friendlyName: 'Model Purpose',
    description: 'A description of the purpose for the creation of this model and/or the expected use.',
    required: true,
    acceptsMultipleValues: false,
  },
  modelDescription: {
    friendlyName: 'Model Description',
    description: 'The description may include an abstract, a table of contents, or a free-text account of the model.',
    required: true,
    acceptsMultipleValues: false,
  },
  modelType: {
    friendlyName: 'Model Type(s)',
    description: 'Choose one or more types that best describe your model from the given list.',
    required: true,
    acceptsMultipleValues: true,
  },
  softwareName: {
    friendlyName: 'Software Name',
    description: 'The name of the software used.',
    required: true,
    acceptsMultipleValues: false,
  },
  softwareVersion: {
    friendlyName: 'Software Version',
    description: 'The version of the software used.',
    required: true,
    acceptsMultipleValues: false,
  },
  modelLastUpdatedDate: {
    friendlyName: 'Model Last Updated Date',
    description: 'The date the model was last updated.',
    required: true,
    acceptsMultipleValues: false,
    isDate: true,
  },
  modelUpdateFrequency: {
    friendlyName: 'Model Update Frequency',
    description: 'How often the content is updated.',
    required: true,
    acceptsMultipleValues: false,
  },
  // metadataLastUpdated: {
  //   friendlyName: 'Metadata Last Updated',
  //   description: 'Date the metadata record was last updated.',
  //   required: false,
  //   acceptsMultipleValues: false,
  //   isDate: false,
  // },
  useConstraint: {
    friendlyName: 'Use Constraints',
    description: 'Constraints or restrictions on use of the digital object.',
    required: true,
    acceptsMultipleValues: true,
  },
  contactAffiliation: {
    friendlyName: 'Contact Affiliation',
    description: 'Organizational affiliation for the responsible point of contact.',
    required: true,
    acceptsMultipleValues: false,
  },
  contactEmail: {
    friendlyName: 'Contact Email',
    description: 'Email for the responsible point of contact.',
    required: true,
    acceptsMultipleValues: false,
  },
  contactName: {
    friendlyName: 'Contact Name',
    description: 'Name of a person who is a responsible point of contact.',
    required: true,
    acceptsMultipleValues: false,
  },
  contactPhoneNumber: {
    friendlyName: 'Contact Phone Number',
    description: 'Work phone number for the responsible point of contact.',
    required: true,
    acceptsMultipleValues: false,
  },
  contactRole: {
    friendlyName: 'Contact Role',
    description: 'The role(s) of the point of contact toward the digital object and its management within TDIS.',
    required: true,
    acceptsMultipleValues: false,
  },
  chainOfCustody: {
    friendlyName: 'Chain of Custody',
    description: 'A statement of any changes in ownership and custody of the model since its creation that are significant for its authenticity, integrity, and interpretation. The statement may include a description of any changes successive custodians made to the model. - TODO: need to improve this description',
    required: false,
    acceptsMultipleValues: true,
  },
  documentationFilenames: {
    friendlyName: 'Supporting Documentation Filenames',
    description: 'List of filenames of any supporting documentation to be included in the model upload (e.g. "about.txt").',
    required: false,
    acceptsMultipleValues: true,
  },
}
