import { SET_ACTIVE_DROPDOWN, UPDATE_CONTACT_ID, SET_SEARCH, SET_SELECTED_METADATA_ID, SET_TABLE_VIEW, ADD_ORIGINATOR_CONTACT_ID_TO_RECORD, UPDATE_CONTACT_ROLE, ADD_EMPTY_CONTACT_TO_RECORD, REMOVE_CONTACT_FROM_RECORD, SET_PASSWORD, SET_CONTACTS_AND_ROLES, UPDATE_SELECTED_AGENCY } from "../actions/general";

const defaultState = {
  activeDropdown: null,
  showTableView: false,
  searchStr: '',
  pwrd: null,
  selectedRecordId: null,
  selectedAgencyId: null,
  contactsByRecordId: {
    'new': ['']
  },
  contactRolesByRecordId: {
    'new': ['']
  },
  originatorContactsByRecordId: {},
}

const generalReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_ACTIVE_DROPDOWN:
      return {
        ...state,
        activeDropdown: action.payload,
      };
    case SET_TABLE_VIEW:
      return {
        ...state,
        showTableView: action.payload,
      };
    case SET_SEARCH:
      return {
        ...state,
        searchStr: action.payload,
      };
    case SET_SELECTED_METADATA_ID:
      return {
        ...state,
        selectedRecordId: action.payload,
      };
    case SET_PASSWORD:
      return {
        ...state,
        pwrd: action.pwrd,
      };
    case UPDATE_SELECTED_AGENCY:
      return {
        ...state,
        pwrd: action.pwrd,
      };
    case REMOVE_CONTACT_FROM_RECORD:
      const index = action.payload.index;
      let contacts = state.contactsByRecordId[action.payload.recordId] || [];
      let roles = state.contactRolesByRecordId[action.payload.recordId] || [];
      contacts.splice(index, 1);
      roles.splice(index, 1);
      return {
        ...state,
        contactsByRecordId: {
          ...state.contactsByRecordId,
          [action.payload.recordId]: contacts,
        },
        contactRolesByRecordId: {
          ...state.contactRolesByRecordId,
          [action.payload.recordId]: roles
        }
      }
    case ADD_EMPTY_CONTACT_TO_RECORD:
      return {
        ...state,
        contactsByRecordId: {
          ...state.contactsByRecordId,
          [action.payload.recordId]: [...(state.contactsByRecordId[action.payload.recordId] || []), '']
        },
        contactRolesByRecordId: {
          ...state.contactRolesByRecordId,
          [action.payload.recordId]: [...(state.contactRolesByRecordId[action.payload.recordId] || []), '']
        }
      }
    case UPDATE_CONTACT_ID:
      const originalContacts = state.contactsByRecordId[action.payload.recordId] || [];
      let newContacts = originalContacts;
      newContacts[action.payload.index] = action.payload.contactId
      return {
        ...state,
        contactsByRecordId: {
          ...state.contactsByRecordId,
          [action.payload.recordId]: newContacts
        }
      }
    case UPDATE_CONTACT_ROLE:
      const originalRoles = state.contactRolesByRecordId[action.payload.recordId] || [];
      let newRoles = originalRoles;
      newRoles[action.payload.index] = action.payload.contactRoleId
      return {
        ...state,
        contactRolesByRecordId: {
          ...state.contactRolesByRecordId,
          [action.payload.recordId]: newRoles
        }
      }
    case ADD_ORIGINATOR_CONTACT_ID_TO_RECORD:
      return {
        ...state,
        originatorContactsByRecordId: {
          ...state.originatorContactsByRecordId,
          [action.payload.recordId]: action.payload.contactId
        }
      }
    case SET_CONTACTS_AND_ROLES:
      return {
        ...state,
        contactsByRecordId: {
          [action.payload.recordId]: action.payload.contacts,
        },
        contactRolesByRecordId: {
          [action.payload.recordId]: action.payload.roles,
        }
      }
    default:
      return state;
  }
};

export default generalReducer;