import yaml from 'js-yaml'
import { convertAppFieldsToDbProperties } from '../../utils/fieldConversion'

const getYaml = (selectedRecord) => {
  return yaml.dump({
    global_attributes: selectedRecord
  })
}

export const useYMLDownload = () => {
  const getFile = (selectedRecord, recordId) => {
    const exportedFilename = `metadata_${recordId}.yml`;
    var blob = new Blob([getYaml(convertAppFieldsToDbProperties(selectedRecord.currentMetadata, selectedRecord.tdisDataIdentifier))], { type: 'text/plain;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilename);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
  }
  return getFile;
}