import { 
  SET_CONTACT_MODAL,
  SET_EXAMPLE_MODAL, SET_HISTORY_MODAL, SET_PREVIEW_MODAL, 
} from '../actions/modals';

const defaultState = {
  showExampleModal: false, 
  showHistoryModal: false,
  showContactModal: false,
  showPreviewModal: false,
  contactModalInfo: { 
    contactId: null, 
    isOriginator: false, 
    recordId: false 
  },
  previewFileObj: null,
}

const modalsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_EXAMPLE_MODAL:
      return {
        ...state,
        showExampleModal: action.payload,
      };
    case SET_HISTORY_MODAL:
      return {
        ...state,
        showHistoryModal: action.payload,
      };
    case SET_CONTACT_MODAL:
      return {
        ...state,
        showContactModal: action.payload.showModal,
        contactModalInfo: action.payload
      };
    case SET_PREVIEW_MODAL:
      return {
        ...state,
        showPreviewModal: action.payload.showModal,
        previewFileObj: action.payload.fileObj
      };
    default:
      return state;
  }
};

export default modalsReducer;