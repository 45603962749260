import { createSelector } from "reselect";

export const modalsSelector = (state) => state.modals;
export const exampleModalSelector = (state) => state.modals.showExampleModal;
export const historyModalSelector = (state) => state.modals.showHistoryModal;
export const contactModalSelector = (state) => state.modals.showContactModal;
export const contactModalInfoSelector = (state) => state.modals.contactModalInfo;
export const previewModalSelector = (state) => state.modals.showPreviewModal;
export const previewFileObjSelector = (state) => state.modals.previewFileObj;

export const anyModalIsActiveSelector = createSelector(
  [modalsSelector],
  (modals) => Object.values(modals).some(value => value === true)
);