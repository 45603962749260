import React from 'react'
import styled from 'styled-components'
import {
  HEADER_HEIGHT,
  PRIMARY_DARK,
  PRIMARY,
  PRIMARY_LIGHT,
  GREY_LIGHT,
} from '../constants/cssVars'
import { Link } from 'react-router-dom'
import tdisLogo from '../assets/tdis.png'
import twdbLogo from '../assets/twdb-logo.png'
import universityLogo from '../assets/university-logo.png' // Import the university logo

const HeaderWrapper = styled.div`
  position: fixed;
  height: ${HEADER_HEIGHT}px;
  color: ${PRIMARY_DARK};
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

const LogosWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px; /* Adjust margin as needed */
`

const TDISLogo = styled.img`
  width: 131px;
  height: 50px;
  margin-left: 8px;
  margin-right: 10px; /* Adjust margin as needed */
  @media only screen and (max-width: 700px) {
    width: 90px;
    height: 34px;
  }
`

const TWDBLogo = styled.img`
  width: 150px;
  height: 45px;
  background-color: transparent;
  border: 1px solid transparent;
  @media only screen and (max-width: 700px) {
    width: 100px;
    height: 30px;
  }
`

const UniversityLogo = styled.img`
  width: 150px; // Adjust size as needed
  height: 45px; // Adjust size as needed
  background-color: transparent;
  border: 1px solid transparent;
  @media only screen and (max-width: 700px) {
    width: 100px;
    height: 30px;
  }
`

const HeaderLink = styled(Link)`
  font-family: 'Open Sans Condensed', sans-serif;
  text-transform: uppercase;
  font-size: 1.2em;
  text-decoration: none;
  font-weight: 600;
  color: ${PRIMARY};
  display: flex;
  align-items: center;
  margin-right: 160px; /* Adjust margin as needed */
  &:hover {
    color: ${PRIMARY_LIGHT};
  }
`

export const Logos = () => {
  return (
    <LogosWrapper>
      <TWDBLogo src={twdbLogo} alt="Texas Water Development Board Logo" />
      <TDISLogo src={tdisLogo} alt="TDIS logo" />
    </LogosWrapper>
  )
}

export const LoggedOutHeader = () => {
  return (
    <header>
      <HeaderWrapper>
        <Logos />
        <HeaderLink to="/app">MS² Metadata</HeaderLink>
        <UniversityLogo src={universityLogo} alt="University Logo" />
      </HeaderWrapper>
    </header>
  )
}
