import React from 'react';
import styled from 'styled-components';
import { HEADER_HEIGHT, SHADOW_LIGHT, GREY_MEDIUM_LIGHT, PRIMARY_DARK, PRIMARY, PRIMARY_LIGHT, BG_LIGHTER, BG_DARKER, BG_PRIMARY } from '../constants/cssVars';
import { Link } from 'react-router-dom';
import { Menu } from './Menu';
import { Logos } from './LoggedOutHeader';

const HeaderWrapper = styled.div`
  width: 100%;
  position: fixed;
  height: ${HEADER_HEIGHT}px;
  // background-color: #fff;
  color: ${PRIMARY_DARK};
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  z-index: 10;
  box-shadow: ${SHADOW_LIGHT};
  border-bottom: 1px solid ${GREY_MEDIUM_LIGHT};
  background-image: radial-gradient(circle at right, ${BG_LIGHTER}, ${BG_PRIMARY});
`;

const HeaderContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderOffset = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT}px;
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Open Sans Condensed', sans-serif;
  text-transform: uppercase;
  font-size: 1.2em;
  width: 100%;
  > a {
    margin: 0px 15px;
    text-decoration: none;
    font-weight: 600;
    color: ${PRIMARY};
    &:hover {
      color: ${PRIMARY_LIGHT};
    }
  }
`;

const MenuWrapper = styled.div`
  min-width: 280px;
  margin-right: 20px;
  display: flex;
  justify-content: flex-end;
  @media only screen and (max-width: 700px) {
    min-width: 0px;
  }  
`

export const Header = () => {
  return (
    <header>
      <HeaderWrapper>
        <HeaderContent>
          <Nav>
            <Logos />
            <Link to='/app'>MS² Metadata</Link>
            <MenuWrapper>
              <Menu />
            </MenuWrapper>
          </Nav>
        </HeaderContent>
      </HeaderWrapper>
      <HeaderOffset />
    </header>
  )
}