import { useSelector } from "react-redux"
import { agenciesSelector, filteredContactRolesSelector, originatorRoleIdSelector, softwareListSelector } from "../selectors/entities"
import { useCurrentUser } from '../hooks/useCurrentUser'
import { useRequest } from 'redux-query-react';
import { getAgenciesQuery, getContactRolesQuery, getSoftwareListQuery } from "../actions/queries";

const softwareNames = [
  'HEC-RAS',
  'HEC-HMS',
  'HEC-GeoHMS',
  'HEC-WAT',
  'HEC-EFM',
  'HEC-MetVue',
  'HEC-SSP',
  'RiverWare',
  'Infoworks ICM',
  'SWMM',
  'InfoSWMM',
  'XPSWMM',
  'XPStorm',
  'ICPR',
  'OpenFlows StormCAD',
  'OpenFlows CivilStorm',
  'OpenFlows FLOOD',
  'OpenFlows CulvertMaster',
  'ADCIRC',
  'WHAFIS',
  'SWAN',
  'MIKE 21',
  'FEMA Hazus',
  'FEMA BCA',
  'HEC-FDA',
  'HEC-FIA',
  'Hydro-CAD',
  'HY-8 Culvert',
  'Delft3D',
  'SWAT',
  'PRMS',
  'WRAP',
  'Other',
  'Unknown',
]

const softwareDistributors = [
  'US Army Corps of Engineers',
  'Center for Advanced Decision Support for Water and Environmental Systems, University of Colorado',
  'Innovyze',
  'Environmental Protection Agency',
  'Streamline Technologies',
  'Bentley',
  'University of North Carolina',
  'Federal Emergency Management Agency',
  'Delft University of Technology',
  'DHI',
  'Deltares',
  'Texas A&M University',
  'US Geological Survey',
  'Other',
  'Unknown',
]

export const softwareInfoByDistributor = {
  'HEC-RAS': {
    distributionUrl: 'https://www.hec.usace.army.mil/software/hec-ras/',
    distributor: 'US Army Corps of Engineers',
  },
  'HEC-HMS': {
    distributionUrl: 'https://www.hec.usace.army.mil/software/hec-hms/',
    distributor: 'US Army Corps of Engineers',
  },
  'HEC-GeoHMS': {
    distributionUrl: 'https://www.hec.usace.army.mil/software/',
    distributor: 'US Army Corps of Engineers',
  },
  'HEC-WAT': {
    distributionUrl: 'https://www.hec.usace.army.mil/software/hec-wat/    ',
    distributor: 'US Army Corps of Engineers',
  },
  'HEC-EFM': {
    distributionUrl: 'https://www.hec.usace.army.mil/software/',
    distributor: 'US Army Corps of Engineers',
  },
  'HEC-MetVue': {
    distributionUrl: 'https://www.hec.usace.army.mil/software/hec-metvue/',
    distributor: 'US Army Corps of Engineers',
  },
  'HEC-SSP': {
    distributionUrl: 'https://www.hec.usace.army.mil/software/hec-ssp/',
    distributor: 'US Army Corps of Engineers',
  },
  'RiverWare': {
    distributionUrl: 'https://www.riverware.org',
    distributor: 'Center for Advanced Decision Support for Water and Environmental Systems, University of Colorado',
  },
  'Infoworks ICM': {
    distributionUrl: 'https://www.innovyze.com/en-us/products/infoworks-icm',
    distributor: 'Innovyze',
  },
  'SWMM': {
    distributionUrl: 'https://www.epa.gov/water-research/storm-water-management-model-swmm',
    distributor: 'Environmental Protection Agency',
  },
  'InfoSWMM': {
    distributionUrl: 'https://www.innovyze.com/en-us/products/infoswmm',
    distributor: 'Innovyze',
  },
  'XPSWMM': {
    distributionUrl: 'https://www.innovyze.com/en-us/products/xpswmm',
    distributor: 'Innovyze',
  },
  'XPStorm': {
    distributionUrl: 'https://www.innovyze.com/en-us/products/xpstorm',
    distributor: 'Innovyze',
  },
  'ICPR': {
    distributionUrl: 'https://streamnologies.com/content/',
    distributor: 'Streamline Technologies',
  },
  'OpenFlows StormCAD': {
    distributionUrl: 'https://www.bentley.com/en/products/product-line/hydraulics-and-hydrology-software/stormcad',
    distributor: 'Bentley',
  },
  'OpenFlows CivilStorm': {
    distributionUrl: 'https://www.bentley.com/en/products/product-line/hydraulics-and-hydrology-software/civilstorm',
    distributor: 'Bentley',
  },
  'OpenFlows FLOOD': {
    distributionUrl: 'https://www.bentley.com/en/products/product-line/hydraulics-and-hydrology-software/openflows-flood',
    distributor: 'Bentley',
  },
  'OpenFlows CulvertMaster': {
    distributionUrl: 'https://www.bentley.com/en/products/product-line/hydraulics-and-hydrology-software/culvertmaster',
    distributor: 'Bentley',
  },
  'ADCIRC': {
    distributionUrl: 'https://adcirc.org',
    distributor: 'University of North Carolina',
  },
  'WHAFIS': {
    distributionUrl: 'https://www.fema.gov/flood-maps/software',
    distributor: 'Federal Emergency Management Agency',
  },
  'SWAN': {
    distributionUrl: 'https://swanmodel.sourceforge.io',
    distributor: 'Delft University of Technology',
  },
  'MIKE 21': {
    distributionUrl: 'https://www.mikepoweredbydhi.com/products/mike-21/hydrodynamics',
    distributor: 'DHI',
  },
  'FEMA Hazus': {
    distributionUrl: 'https://www.fema.gov/flood-maps/products-tools/hazus',
    distributor: 'Federal Emergency Management Agency',
  },
  'FEMA BCA': {
    distributionUrl: 'https://www.fema.gov/grants/guidance-tools/benefit-cost-analysis ',
    distributor: 'Federal Emergency Management Agency',
  },
  'HEC-FDA': {
    distributionUrl: 'https://www.hec.usace.army.mil/software/hec-fda/ ',
    distributor: 'US Army Corps of Engineers',
  },
  'HEC-FIA': {
    distributionUrl: 'https://www.hec.usace.army.mil/software/hec-fia/',
    distributor: 'US Army Corps of Engineers',
  },
  'Hydro-CAD': {
    distributionUrl: 'https://www.hydrocad.net/',
    distributor: '',
  },
  'HY-8 Culvert': {
    distributionUrl: 'https://www.fhwa.dot.gov/engineering/hydraulics/software/hy8/',
    distributor: '',
  },
  'Delft3D': {
    distributionUrl: 'https://oss.deltares.nl/web/delft3d',
    distributor: 'Deltares',
  },
  'SWAT': {
    distributionUrl: 'https://swat.tamu.edu/software/',
    distributor: 'Texas A&M University',
  },
  'PRMS': {
    distributionUrl: 'https://www.usgs.gov/software/precipitation-runoff-modeling-system-prms',
    distributor: 'US Geological Survey',
  },
  'WRAP': {
    distributionUrl: 'https://wrap.engr.tamu.edu/',
    distributor: '',
  },
  'Other': {
    distributionUrl: '',
    distributor: 'Other',
  },
  'Unknown': {
    distributionUrl: '',
    distributor: 'Unknown',
  },
}

export const twdbFloodPlanningRegionIdOptions = [
  {
    label: '1 - Canadian-Upper Red',
    value: '01',
  },
  {
    label: '2 - Lower Red-Sulphur-Cypress',
    value: '02',
  },
  {
    label: '3 - Trinity',
    value: '03',
  },
  {
    label: '4 - Sabine',
    value: '04',
  },
  {
    label: '5 - Neches',
    value: '05',
  },
  {
    label: '6 - San Jacinto',
    value: '06',
  },
  {
    label: '7 - Upper Brazos',
    value: '07',
  },
  {
    label: '8 - Lower Brazos',
    value: '08',
  },
  {
    label: '9 - Upper Colorado',
    value: '09',
  },
  {
    label: '10 - Lower Colorado-Lavaca',
    value: '10',
  },
  {
    label: '11 - Guadalupe',
    value: '11',
  },
  {
    label: '12 - San Antonio',
    value: '12',
  },
  {
    label: '13 - Nueces',
    value: '13',
  },
  {
    label: '14 - Upper Rio Grande',
    value: '14',
  },
  {
    label: '15 - Lower Rio Grande',
    value: '15',
  },
]

const regions = ['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15']

const counties = [
  'Anderson', 'Andrews', 'Angelina', 'Aransas', 'Archer', 'Armstrong', 'Atascosa', 'Austin', 'Bailey', 'Bandera', 'Bastrop', 'Baylor', 'Bee', 'Bell', 'Bexar', 'Blanco', 'Borden', 
  'Bosque', 'Bowie', 'Brazoria', 'Brazos', 'Brewster', 'Briscoe', 'Brooks', 'Brown', 'Burleson', 'Burnet', 'Caldwell', 'Calhoun', 'Callahan', 'Cameron', 'Camp', 'Carson', 'Cass', 'Castro', 
  'Chambers', 'Cherokee', 'Childress', 'Clay', 'Cochran', 'Coke', 'Coleman', 'Collin', 'Collingsworth', 'Colorado', 'Comal', 'Comanche', 'Concho', 'Cooke', 'Coryell', 'Cottle', 'Crane', 'Crockett', 'Crosby', 'Culberson', 'Dallam', 
  'Dallas', 'Dawson', 'Deaf Smith', 'Delta', 'Denton', 'DeWitt', 'Dickens', 'Dimmit', 'Donley', 'Duval', 'Eastland', 'Ector', 'Edwards', 'Ellis', 'El Paso', 'Erath', 'Falls', 'Fannin', 'Fayette', 'Fisher', 'Floyd', 'Foard', 'Fort Bend', 
  'Franklin', 'Freestone', 'Frio', 'Gaines', 'Galveston', 'Garza', 'Gillespie', 'Glasscock', 'Goliad', 'Gonzales', 'Gray', 'Grayson', 'Gregg', 'Grimes', 'Guadalupe', 'Hale', 'Hall', 'Hamilton', 
  'Hansford', 'Hardeman', 'Hardin', 'Harris', 'Harrison', 'Hartley', 'Haskell', 'Hays', 'Hemphill', 'Henderson', 'Hidalgo', 'Hill', 'Hockley', 'Hood', 'Hopkins', 'Houston', 'Howard', 'Hudspeth', 'Hunt', 'Hutchinson', 'Irion', 'Jack', 
  'Jackson', 'Jasper', 'Jeff Davis', 'Jefferson', 'Jim Hogg', 'Jim Wells', 'Johnson', 'Jones', 'Karnes', 'Kaufman', 'Kendall', 'Kenedy', 'Kent', 'Kerr', 'Kimble', 'King', 'Kinney', 'Kleberg', 'Knox', 'Lamar', 'Lamb', 'Lampasas', 'La Salle', 'Lavaca', 
  'Lee', 'Leon', 'Liberty', 'Limestone', 'Lipscomb', 'Live Oak', 'Llano', 'Loving', 'Lubbock', 'Lynn', 'McCulloch', 'McLennan', 'McMullen', 'Madison', 'Marion', 'Martin', 'Mason', 'Matagorda', 'Maverick', 'Medina', 'Menard', 'Midland', 'Milam', 'Mills', 'Mitchell', 
  'Montague', 'Montgomery', 'Moore', 'Morris', 'Motley', 'Nacogdoches', 'Navarro', 'Newton', 'Nolan', 'Nueces', 'Ochiltree', 'Oldham', 'Orange', 'Palo Pinto', 'Panola', 'Parker', 'Parmer', 'Pecos', 'Polk', 'Potter', 'Presidio', 'Rains', 'Randall', 'Reagan', 'Real', 
  'Red River', 'Reeves', 'Refugio', 'Roberts', 'Robertson', 'Rockwall', 'Runnels', 'Rusk', 'Sabine', 'San Augustine', 'San Jacinto', 'San Patricio', 'San Saba', 'Schleicher', 'Scurry', 'Shackelford', 'Shelby', 'Sherman', 'Smith', 'Somervell', 'Starr', 'Stephens', 'Sterling', 
  'Stonewall', 'Sutton', 'Swisher', 'Tarrant', 'Taylor', 'Terrell', 'Terry', 'Throckmorton', 'Titus', 'Tom Green', 'Travis', 'Trinity', 'Tyler', 'Upshur', 'Upton', 'Uvalde', 'Val Verde', 'Van Zandt', 'Victoria', 'Walker', 'Waller', 'Ward', 'Washington', 'Webb', 'Wharton', 'Wheeler', 'Wichita', 'Wilbarger', 'Willacy', 'Williamson', 'Wilson',
  'Winkler', 'Wise', 'Wood', 'Yoakum', 'Young', 'Zapata', 'Zavala'
]

const cities = [ 
  'Abbott', 'Abernathy', 'Abilene', 'Ackerly', 'Adrian', 'Agua Dulce', 'Alamo', 'Alamo Heights', 'Albany', 'Aledo', 'Alice', 'Allen', 'Alpine', 'Alton', 'Alvarado', 'Alvin', 'Amarillo', 'Ames', 'Amherst', 'Anahuac', 'Anderson', 'Andrews', 'Angleton', 'Angus', 'Anna', 'Anson', 'Anton', 'Appleby', 'Aquilla', 'Aransas Pass', 'Arcola', 'Argyle', 'Arlington', 'Arp', 'Asherton', 'Athens', 'Atlanta', 'Aubrey', 'Aurora', 'Austin', 'Austwell', 'Azle', 'Bailey', 'Baird', 'Balch Springs', 'Balcones Heights', 'Ballinger', 'Balmorhea', 'Bandera', 'Bangs', 'Bardwell', 'Barry', 'Barstow', 'Bartlett', 'Bay City', 'Bayou Vista', 'Baytown', 'Beasley', 'Beaumont', 'Beckville', 'Bedford', 'Bedias', 'Bee Cave', 'Beeville', 'Bellaire', 'Bellevue', 'Bellmead', 'Bellville', 'Belton', 
  'Benavides', 'Benbrook', 'Benjamin', 'Bertram', 'Beverly Hills', 'Bevil Oaks', 'Big Lake', 'Big Spring', 'Big Wells', 'Bishop', 'Blackwell', 'Blanco', 'Blossom', 'Blue Mound', 'Blue Ridge', 'Boerne', 'Bogata', 'Bonham', 'Borger', 'Bovina', 'Bowie', 'Brackettville', 'Brady', 'Brazos Bend', 'Brazos Country', 'Breckenridge', 'Bremond', 'Brenham', 'Briaroaks', 'Bridge City', 'Bridgeport', 'Brookshire', 'Brookside Village', 'Browndell', 'Brownfield', 'Brownsboro', 'Brownsville', 'Brownwood', 'Bruceville-Eddy', 'Bryan', 'Bryson', 'Buda', 'Buffalo', 'Bulverde', 'Bunker Hill Village', 'Burkburnett', 'Burke', 'Burleson', 'Burnet', 'Burton', 'Byers', 'Cactus', 'Caddo Mills', 'Caldwell', 'Callisburg', 'Calvert', 'Cameron', 'Camp Wood', 'Campbell', 'Canadian', 'Canton', 'Canyon', 'Carmine', 'Carrizo Springs', 'Carrollton', 'Carthage', 'Cashion Community', 
  'Castle Hills', 'Castroville', 'Cedar Hill', 'Cedar Park', 'Celeste', 'Celina', 'Center', 'Centerville', 'Chandler', 'Channing', 'Charlotte', 'Chico', 'Childress', 'Chillicothe', 'China', 'Chireno', 'Cibolo', 'Cisco', 'Clarendon', 'Clarksville', 'Clarksville City', 'Claude', 'Clear Lake Shores', 'Cleburne', 'Cleveland', 'Clifton', 'Clute', 'Clyde', 'Cockrell Hill', 'Coldspring', 'Coleman', 'College Station', 'Colleyville', 'Colmesneil', 'Colorado City', 'Columbus', 'Combine', 'Commerce', 'Conroe', 'Converse', 'Cool', 'Cooper', 'Coppell', 'Copperas Cove', 'Corinth', 'Corpus Christi', 'Corsicana', 'Cottonwood', 'Cottonwood Shores', 'Cotulla', 'Coupland', 'Cove', 'Covington', 'Coyote Flats', 'Crandall', 'Crane', 'Cranfills Gap', 'Creedmoor', 'Cresson', 'Crockett', 'Crosbyton', 'Crowell', 'Crowley', 'Crystal City', 
  'Cuero', 'Cumby', 'Cushing', 'Cut and Shoot', 'Daingerfield', 'Daisetta', 'Dalhart', 'Dalworthington Gardens', 'Danbury', 'Dayton Lakes', 'De Kalb', 'De Leon', 'DeCordova', 'DeSoto', 'Dean', 'Decatur', 'Deer Park', 'Del Rio', 'Dell City', 'Denison', 'Denton', 'Deport', 'Devers', 'Devine', 'Diboll', 'Dickens', 'Dickinson', 'Dilley', 'Dimmitt', 'Donna', 'Dorchester', 'Dripping Springs', 'Driscoll', 'Dublin', 'Dumas', 'Duncanville', 'Eagle Lake', 'Eagle Pass', 'Early', 'Earth', 'East Bernard', 'East Mountain', 'East Tawakoni', 'Easton', 'Ector', 'Edcouch', 'Eden', 'Edinburg', 'Edna', 'Edom', 'El Campo', 'El Cenizo', 'El Lago', 'Eldorado', 'Electra', 'Elgin', 'Elmendorf', 'Elsa', 'Emory', 'Encinal', 'Ennis', 'Escobares', 'Euless', 'Eureka', 'Eustace', 'Everman', 'Fair Oaks Ranch', 'Fairfield', 'Falfurrias', 'Falls City', 'Farmers Branch', 'Farmersville', 'Farwell', 'Fate', 'Fayetteville', 'Ferris', 'Florence', 
  'Floresville', 'Floydada', 'Follett', 'Forest Hill', 'Forney', 'Forsan', 'Fort Stockton', 'Fort Worth', 'Franklin', 'Fredericksburg', 'Freeport', 'Freer', 'Friendswood', 'Friona', 'Frisco', 'Fritch', 'Frost', 'Fruitvale', 'Fulshear', 'Gainesville', 'Galena Park', 'Gallatin', 'Ganado', 'Garden Ridge', 'Garland', 'Garrison', 'Gatesville', 'George West', 'Georgetown', 'Gholson', 'Giddings', 'Gilmer', 'Gladewater', 'Glen Rose', 'Glenn Heights', 'Godley', 'Goldsmith', 'Goldthwaite', 'Goliad', 'Golinda', 'Goodlow', 'Goodrich', 'Gordon', 'Goree', 'Gorman', 'Graford', 'Graham', 'Granbury', 'Grand Prairie', 'Grand Saline', 'Grandview', 'Granger', 'Granite Shoals', 'Granjeno', 'Grapeland', 'Grapevine', 'Greenville', 'Gregory', 'Grey Forest', 'Groesbeck', 'Groves', 'Groveton', 'Gruver', 'Gun Barrel City', 'Gunter', 'Hale Center', 'Hallettsville', 'Hallsburg', 'Hallsville', 'Haltom City', 'Hamlin', 'Hardin', 'Harker Heights', 'Harlingen', 'Hart', 'Haskell', 
  'Haslet', 'Hawk Cove', 'Hawkins', 'Hawley', 'Hearne', 'Heath', 'Hedley', 'Hedwig Village', 'Helotes', 'Hemphill', 'Hempstead', 'Henderson', 'Henrietta', 'Hereford', 'Hewitt', 'Hico', 'Hideaway', 'Higgins', 'Highland Haven', 'Highland Village', 'Hill Country Village', 'Hillsboro', 'Hilshire Village', 'Hitchcock', 'Holliday', 'Hondo', 'Honey Grove', 'Hooks', 'Horizon City', 'Horseshoe Bay', 'Houston', 'Howardwick', 'Hubbard', 'Hudson', 'Hudson Oaks', 'Hughes Springs', 'Humble', 'Hunters Creek Village', 'Huntington', 'Huntsville', 'Hurst', 'Hutchins', 'Hutto', 'Huxley', 'Idalou', 'Industry', 'Ingleside on the Bay', 'Ingram', 'Iola', 'Iowa Park', 'Iraan', 'Iredell', 'Irving', 'Itasca', 'Ivanhoe', 'Ivanhoe North', 'Jacksboro', 'Jacksonville', 'Jamaica Beach', 'Jarrell', 'Jasper', 'Jayton', 'Jefferson', 'Jersey Village', 'Jewett', 'Joaquin', 'Johnson City', 'Jolly', 'Jonestown', 'Josephine', 'Joshua', 'Jourdanton', 'Junction', 'Justin', 'Karnes City', 'Katy', 'Keene', 'Keller', 'Kemah', 'Kemp', 'Kempner', 'Kendleton', 'Kenedy', 'Kennard', 'Kennedale', 'Kerens', 'Kermit', 
  'Kerrville', 'Kilgore', 'Killeen', 'Kingsville', 'Kirby', 'Kirbyville', 'Knollwood', 'Kountze', 'Kress', 'Krugerville', 'Krum', 'Kyle', 'La Feria', 'La Grange', 'La Grulla', 'La Joya', 'La Marque', 'La Porte', 'La Vernia', 'La Villa', 'La Ward', 'LaCoste', 'Lacy-Lakeview', 'Lago Vista', 'Lake Bridgeport', 'Lake Dallas', 'Lake Jackson', 'Lake Worth', 'Lakeport', 'Lakeway', 'Lakewood Village', 'Lamesa', 'Lampasas', 'Lancaster', 'Laredo', 'Latexo', 'Lavon', 'League City', 'Leakey', 'Leander', 'Leary', 'Leon Valley', 'Leona', 'Leonard', 'Leroy', 'Levelland', 'Lewisville', 'Liberty Hill', 'Lindale', 'Linden', 'Lindsay', 'Lipan', 'Little Elm', 'Little River-Academy', 'Littlefield', 'Live Oak', 'Liverpool', 'Lockhart', 'Log Cabin', 'Lometa', 'Lone Oak', 'Lone Star', 'Longview', 'Lorena', 'Lorenzo', 'Los Fresnos', 'Los Ybanez', 'Lott', 'Lovelady', 'Lowry Crossing', 'Lubbock', 'Lucas', 'Lueders', 'Lufkin', 'Luling', 'Lumberton', 'Lyford', 'Lytle', 'Madisonville', 'Magnolia', 'Malakoff', 'Manor', 'Mansfield', 'Manvel', 'Marble Falls', 'Marfa', 'Marion', 'Marlin', 'Marquez', 'Marshall', 'Mart', 'Martindale', 'Mason', 'Mathis', 'Maud', 'Maypearl', 'McAllen', 'McCamey', 'McGregor', 'McKinney', 'McLendon-Chisholm',
  'Meadowlakes', 'Meadows Place', 'Melissa', 'Memphis', 'Menard', 'Mercedes', 'Meridian', 'Mertzon', 'Mesquite', 'Mexia', 'Miami', 'Midland', 'Midlothian', 'Midway', 'Milano', 'Miles', 'Mineola', 'Mineral Wells', 'Mingus', 'Mission', 'Missouri City', 'Mobeetie', 'Mobile City', 'Monahans', 'Mont Belvieu', 'Montgomery', 'Moody', 'Moore Station', 'Moran', 'Morgan', "Morgan's Point", "Morgan's Point Resort", 'Morton', 'Mount Calm', 'Mount Enterprise', 'Mount Pleasant', 'Mountain City', 'Muenster', 'Muleshoe', 'Munday', 'Murchison', 'Murphy', 'Mustang Ridge', 'Nacogdoches', 'Naples', 'Nash', 'Nassau Bay', 'Natalia', 'Navasota', 'Nazareth', 'Nederland', 'Needville', 'Nevada', 'New Berlin', 'New Boston', 'New Braunfels', 'New Chapel Hill', 'New Fairview', 'New Home', 'New London', 'New Summerfield', 'New Waverly', 'Newark', 'Newcastle', 'Newton', 'Niederwald', 'Nixon', 'Nocona', 'Nolanville', 'Nome', 'Noonday', 'Nordheim', 'North Cleveland', 'North Richland Hills', 'Novice', "O'Brien", "O'Donnell", 'Oak Leaf', 'Oak Point', 'Oak Ridge North', 'Odem', 'Odessa', 'Oglesby', 'Old River-Winfree', 'Olmos Park', 'Olney', 'Olton', 'Omaha', 'Onalaska', 'Orange Grove', 'Orchard', 'Ore City', 'Overton', 'Ovilla', 'Oyster Creek', 'Palacios', 'Palestine', 'Palm Valley', 'Palmhurst', 'Palmview', 'Pampa', 'Panorama Village', 
  'Paradise', 'Paris', 'Parker', 'Pasadena', 'Pattison', 'Patton Village', 'Pearland', 'Pearsall', 'Pecan Gap', 'Pecan Hill', 'Pecos', 'Pelican Bay', 'Penitas', 'Perryton', 'Petersburg', 'Petrolia', 'Petronila', 'Pflugerville', 'Pharr', 'Pilot Point', 'Pine Forest', 'Pinehurst', 'Pineland', 'Piney Point Village', 'Pittsburg', 'Plainview', 'Plano', 'Pleasanton', 'Plum Grove', 'Point', 'Point Blank', 'Point Comfort', 'Port Aransas', 'Port Arthur', 'Port Isabel', 'Port Lavaca', 'Port Neches', 'Portland', 'Post', 'Poteet', 'Prairie View', 'Premont', 'Presidio', 'Princeton', 'Progreso', 'Progreso Lakes', 'Quanah', 'Queen City', 'Quinlan', 'Quitaque', 'Quitman', 'Ralls', 'Ranger', 'Rankin', 'Ravenna', 'Raymondville', 'Red Lick', 'Red Oak', 'Redwater', 'Reklaw', 'Reno', 'Reno', 'Rhome', 'Rice', 'Richardson', 'Richland Hills', 'Richmond', 'Richwood', 'Riesel', 'Rio Bravo', 'Rio Grande City', 'Rio Hondo', 'Rio Vista', 'River Oaks', 'Riverside', 'Roanoke', 'Robert Lee', 'Robinson', 'Robstown', 'Roby', 'Rockdale', 'Rockport', 'Rollingwood', 'Roma', 'Ropesville', 'Roscoe', 'Rose Hill Acres', 'Rosebud', 'Rosenberg', 'Ross', 'Rotan', 'Round Rock', 'Rowlett', 'Roxton', 'Royse City', 'Runaway Bay', 'Sabinal', 'Sachse', 'Sadler', 'Saginaw', 'Saint Jo', 'San Angelo', 'San Antonio', 'San Augustine', 'San Benito', 'San Diego', 'San Elizario', 'San Juan', 'San Marcos', 'San Patricio', 
  'San Perlita', 'San Saba', 'Sandy Oaks', 'Sandy Point', 'Sanger', 'Sansom Park', 'Santa Clara', 'Santa Fe', 'Savoy', 'Schertz', 'Schulenburg', 'Scotland', 'Scottsville', 'Seabrook', 'Seadrift', 'Seagoville', 'Seagraves', 'Sealy', 'Seguin', 'Selma', 'Seminole', 'Seven Oaks', 'Seven Points', 'Seymour', 'Shallowater', 'Shamrock', 'Shavano Park', 'Shenandoah', 'Shepherd', 'Sherman', 'Shiner', 'Shoreacres', 'Silsbee', 'Silverton', 'Simonton', 'Sinton', 'Slaton', 'Smiley', 'Smithville', 'Snook', 'Snyder', 'Socorro', 'Somerset', 'Somerville', 'Sonora', 'Sour Lake', 'South Houston', 'Southlake', 'Southmayd', 'Southside Place', 'Spearman', 'Splendora', 'Spofford', 'Spring Valley Village', 'Springtown', 'Spur', 'Stafford', 'Stamford', 'Stanton', 'Staples', 'Star Harbor', 'Stephenville', 'Sterling City', 'Stinnett', 'Stockdale', 'Stratford', 'Strawn', 'Sudan', 'Sugar Land', 'Sullivan City', 'Sulphur Springs', 'Sun Valley', 'Sundown', 'Sunray', 'Sunrise Beach Village', 'Sunset (historical)', 'Sunset Valley', 'Surfside Beach', 'Sweeny', 'Sweetwater', 'Taft', 'Tahoka', 'Talco', 'Tatum', 'Taylor', 'Taylor Lake Village', 'Taylor Landing', 'Teague', 'Temple', 'Terrell', 'Terrell Hills', 'Texarkana', 'Texhoma', 'The Colony', 'Thorndale', 'Thrall', 'Three Rivers', 'Timpson', 'Toco', 'Todd Mission', 'Tolar', 'Tom Bean', 'Tomball', 'Tool', 'Trenton', 'Trinidad', 'Trinity', 'Troup', 'Troy', 'Tulia', 'Turkey', 'Tuscola', 'Tye', 'Tyler', 'Uhland', 'Uncertain', 'Union Grove', 'Union Valley', 'Universal City', 'University Park', 'Uvalde', 'Valley Mills', 
  'Valley View', 'Van', 'Van Alstyne', 'Vega', 'Vernon', 'Victoria', 'Vidor', 'Von Ormy', 'Waco', 'Waelder', 'Wake Village', 'Waller', 'Wallis', 'Walnut Springs', 'Warren City', 'Waskom', 'Watauga', 'Waxahachie', 'Weatherford', 'Webster', 'Weimar', 'Weinert', 'Weir', 'Wellington', 'Wellman', 'Weslaco', 'West', 'West Columbia', 'West Lake Hills', 'West Orange', 'West Tawakoni', 'West University Place', 'Westbrook', 'Westminster (historical)', 'Weston', 'Weston Lakes', 'Westworth Village', 'Wharton', 'Wheeler', 'White Oak', 'White Settlement', 'Whitehouse', 'Whitesboro', 'Wichita Falls', 'Willis', 'Willow Park', 'Wills Point', 'Wilmer', 'Wilson', 'Wimberley', 'Windcrest', 'Winfield', 'Wink', 'Winnsboro', 'Winters', 'Wixon Valley', 'Wolfe City', 'Wolfforth', 'Woodbranch', 'Woodcreek', 'Woodway', 'Wylie', 'Yoakum', 'Yorktown', 'Zavalla'
]

const updateFrequency = [
  'Realtime',
  'Near-Realtime',
  'Hourly',
  'Daily',
  'Weekly',
  'Monthly',
  'Quarterly',
  'Biannually',
  'Annually',
  '2 or More Years',
  'None',
  'Other',
  'Unknown',
]

const useConstraint = [
  'Public',
  'Restricted – Can share with partner agencies only',
  'Restricted - Display on Web',
  'Restricted - Requires DUA',
  'Restricted - No Display on Web',
  'Other',
]

const modelTypes = [
  'Hydraulic',
  'Hydrologic',
  'Coastal',
  'Combined Riverine-Coastal',
  '2D',
  'Risk Assessment',
  'Economics/BCA',
  'Other',
  'Unknown',
]

const roles = [
  'Metadata Creator',
  'Submitter',
  'Curator',
  'Maintainer',
  'Steward',
  'Rights Holder',
  'Other'
]

const collectionIdToRegionIdMapping = {
  161: '01',
  117: '02',
  163: '03',
  164: '04',
  116: '05',
  118: '06',
  165: '07',
  166: '08',
  167: '09',
  120: '10',
  119: '11',
  168: '12',
  169: '13',
  170: '14',
  121: '15',
}

const collectionIdToNameMapping = {
  116: "Emergency Management Data Supporting TX-21215 Severe Weather Event",
  117: "Flood Model Ver 3.3",
  118: "Flood Model Ver 3.6",
  119: "Modeling, Flood Event TX-20180425",
  120: "Model, Tsnunami Event TX-20220103",
  121: "Modeling for Severe Weather Flood TX-20200512",
  161: "Region 01 Collection",
  163: "Region 03 Collection",
  164: "Region 04 Collection",
  165: "Region 07 Collection",
  166: "Region 08 Collection",
  167: "Region 09 Collection",
  168: "Region 12 Collection",
  169: "Region 13 Collection",
  170: "Region 14 Collection",
}

export const minIOUserByRegionId = {
  '01': 'tdis-twdb-region-01',
  '02': 'tdis-twdb-region-02',
  '03': 'tdis-twdb-region-03',
  '04': 'tdis-twdb-region-04',
  '05': 'tdis-twdb-region-05',
  '06': 'tdis-twdb-region-06',
  '07': 'tdis-twdb-region-07',
  '08': 'tdis-twdb-region-08',
  '09': 'tdis-twdb-region-09',
  '10': 'tdis-twdb-region-10',
  '11': 'tdis-twdb-region-11',
  '12': 'tdis-twdb-region-12',
  '13': 'tdis-twdb-region-13',
  '14': 'tdis-twdb-region-14',
  '15': 'tdis-twdb-region-15',
}

// TODO: Will probably want to not make this hardcoded
export const minIOPasswordByRegionId = {
  '01': 'd77N5KbSzLYZ68K9',
  '02': 'cGtugq9dZLe4qwKX',
  '03': 'UHCDSbvKJd9vhHnF',
  '04': '5tNphgeEWU5ZFYZz',
  '05': '2KJVMXnKgBqGj9BX',
  '06': 'gDM7W6kb3atfB6jL',
  '07': 'fxA2XCCesu8LyWJU',
  '08': 'CLeYWs3RZSsRkYkX',
  '09': 'xuq5yaUkR4x9h8JG',
  '10': 'tdAEXNDQabsBxe9k',
  '11': 'N8mFNba7K9F4D8Bx',
  '12': 'ajcQ6weNSAyG7crw',
  '13': 'azmuaFTCpAtRqHwy',
  '14': '7dVwAPKYWBm35wrg',
  '15': 'jvBrk2ftvrfSma85',
}

// Temp mapping until we get an actual mapping
export const getRegionIdFromCollectionId = (collectionId) => {
  let regionId = collectionIdToRegionIdMapping[collectionId]
  // since "collectionId" is sometimes a name ...
  if (!regionId) {
    let trueCollectionId = Object.keys(collectionIdToNameMapping).find(key => collectionIdToNameMapping[key] === collectionId);
    regionId = collectionIdToRegionIdMapping[trueCollectionId]
  }
  return regionId
}

// Temp mapping until we get an actual mapping
export const getCollectionIdFromRegion = (regionId) => 
    Object.keys(collectionIdToRegionIdMapping).find((key) => collectionIdToRegionIdMapping[key] === regionId
)

export const getOptionFromValue = value => ({ value, label: value })
export const getOptionsFromList = values => {
  return Array.isArray(values) ? values.map(val => ({ value: val, label: val })) : []
}

export const getIndexOptionsFromList = values => {
  return Array.isArray(values) ? values.map((val, idx) => ({ value: idx, label: val })) : []
}

export const countyOptions = getOptionsFromList(counties);
export const cityOptions = getOptionsFromList(cities);
export const useConstraintOptions = getOptionsFromList(useConstraint);
export const modelTypeOptions = getOptionsFromList(modelTypes);
export const updateFrequencyOptions = getOptionsFromList(updateFrequency);
export const softwareNameOptions = getOptionsFromList(softwareNames);
export const softwareDistributorOptions = getOptionsFromList(softwareDistributors);
// export const roleOptions = getOptionsFromList(roles);
// export const agencyOptions = getIndexOptionsFromList(agencies);
// export const twdbFloodPlanningRegionIdOptions = getOptionsFromList(regions);

export const getRegionOptionFromValue = (regionId) => twdbFloodPlanningRegionIdOptions.find(opt => opt.value === regionId)
// export const getAgencyOptionFromValue = (agencyId) => agencyId ? agencyOptions[agencyId] : null

const getLabelFromSoftwareObj = ({ software_name, software_abbr }) => {
  let label = `${software_name} (${software_abbr})`;
  // If the name & abbreviation are the same, just show the name
  if (software_name === software_abbr) {
    label = software_name;
  }
  if (software_abbr === 'Unknown/Other') {
    label = 'Unknown/Other';
  }
  return label;
}

export const useSoftwareNameOptions = () => {
  const { accessToken } = useCurrentUser();
  const softwareList = useSelector(softwareListSelector);
  // Only query if we haven't retrieved the software yet
  const [{ isFinished }] = useRequest(accessToken && softwareList.length === 0 ? getSoftwareListQuery(accessToken) : null);
  const nameOptions = softwareList.map((software, idx) => {
    return {
      label: getLabelFromSoftwareObj(software),
      value: software.software_abbr
    }
  })

  const getNameOptionFromValue = value => {
    const softwareObj = softwareList.find(({ software_abbr, software_name  }) => software_abbr === value || software_name === value) || {};
    if (softwareObj == null) {
      return {
        label: '',
        value: '',
      }
    }
    return {
      label: getLabelFromSoftwareObj(softwareObj),
      value: softwareObj.software_abbr
    }
  }

  return [nameOptions, getNameOptionFromValue, softwareList.length > 0 || isFinished]
}

// Agency Example: {id: 898, abbreviation: "4803696", name: "Archer City"}
export const useAgencyOptions = () => {
  const { accessToken } = useCurrentUser();
  const agencies = useSelector(agenciesSelector);
  // Only query if we haven't retrieved the software yet
  const [{ isFinished }] = useRequest(accessToken && agencies.length === 0 ? getAgenciesQuery(accessToken) : null);

  const options = agencies.map((agency, idx) => {
    return {
      label: agency.name,
      value: agency.id
    }
  })

  const getAgencyOptionFromValue = value => {
    let agencyObj = agencies.find(({ id }) => id === value);
    if (!agencyObj) {
      agencyObj = agencies.find(({ name }) => name === value) || {};
    }
    return {
      label: agencyObj.name,
      value: agencyObj.id
    }
  }

  return [options, getAgencyOptionFromValue, agencies.length > 0 || isFinished]
}

// Agency Example: {"id":4,"name":"Maintainer","description":"Contact responsible for updating ....","metadata_level":"TBD"}
export const useContactRoleOptions = () => {
  const { accessToken } = useCurrentUser();
  const roles = useSelector(filteredContactRolesSelector);
  const originatorRoleId = useSelector(originatorRoleIdSelector);
  const [{ isFinished }] = useRequest(accessToken & roles.length === 0 ? getContactRolesQuery(accessToken) : null);

  const options = roles.map((role, idx) => {
    return {
      label: role.name,
      value: role.id
    }
  })

  const getRoleOptionFromValue = value => {
    const roleObj = roles.find(({ id }) => id === value) || {};
    return {
      label: roleObj.name,
      value
    }
  }

  const getRoleNameFromId = roleId => {
    const roleObj = roles.find(({ id }) => id === roleId) || {};
    if (roleId === originatorRoleId) {
      return 'originator, or digital object creator';
    }
    if (roleObj) {
      return roleObj.name
    }
    return null
  }

  return [options, getRoleOptionFromValue, roles.length > 0 || isFinished, getRoleNameFromId]
}
