import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setSelectedRecordId } from "../actions/general";
import { recordsSelector } from "../selectors/entities";
import { selectedRecordIdSelector } from "../selectors/general";

export const useSelectedRecord = () => {
  const { recordId } = useParams();
  const dispatch = useDispatch();
  const selectedRecordId = useSelector(selectedRecordIdSelector);
  const records = useSelector(recordsSelector);

  useEffect(() => {
    if (selectedRecordId !== recordId) {
      dispatch(setSelectedRecordId(recordId))
    }
  }, [recordId])

  return [records.find(record => record.tdisDataIdentifier === recordId), recordId]
}