import moment from 'moment';
import { getCollectionIdFromRegion, getRegionIdFromCollectionId } from '../constants/formOptions';
import { metadataFieldInfo } from '../constants/metadataFields';
import { getDateUTC } from './helpers';

// Converts the database fields to our local-application fields
// See "dbFieldNamesToAppFields" object for name mapping
// Additional formatting changes are applied (e.g. converting a formatted string to an array)
export const convertDbPropertiesToAppFields = (properties) => {
  const dbMetadataFields = Object.keys(properties).reduce((accum, key) => {
    const appFieldName = dbFieldNamesToAppFields[key];
    if (appFieldName != null) {
      accum[appFieldName] = properties[key]
      // Convert dates to UTC on the way in
      if (appFieldName === 'modelCreationDate' || appFieldName === 'modelLastUpdatedDate') {
        accum[appFieldName] = getDateUTC(properties[key]);
      }
      // The backend returns time period field as a string that looks like this: "{\"[2022-03-23,2022-03-31)\"}"
      // The following logic converts that string into an array of length-two arrays of date objects
      if (appFieldName === 'timePeriodCovered') {
        let splitDates = JSON.stringify(properties[key]).split('\",\"')
        if (splitDates.length === 1) {
          splitDates = JSON.stringify(properties[key]).split('\\",\\"')
        }
        let mappedDates = splitDates.map(str => [...str.matchAll(/\d{4}-\d{2}-\d{2}/g)].map((arr, idx) => {
          let date = new Date(`${arr[0]} 00:00`)
          // if it's the second date (e.g. "2022-03-31" in "[2022-03-23,2022-03-31)"), subtract one day
          // since it's not inclusive of the date
          if (idx === 1) {
            date.setDate(date.getDate()-1);
          }
          return date
        }))
        accum[appFieldName] = mappedDates
      }
      // if app field accepts multiple values (e.g. should be array type) convert string value to an array
      else if (metadataFieldInfo[appFieldName] && metadataFieldInfo[appFieldName].acceptsMultipleValues && !Array.isArray(properties[key])) {
        accum[appFieldName] = properties[key] && properties[key].length > 0 ? properties[key].split(',') : [];
      }
    }
    return accum;
  }, {})
  // Sometimes the region is returned as "collection_id", other times as "collection"
  const regionId = getRegionIdFromCollectionId(properties.collection_id) || getRegionIdFromCollectionId(properties.collection);
  return {
    tdisDataIdentifier: properties.tdis_identifier,
    currentMetadata: {
      ...dbMetadataFields,
      twdbFloodPlanningRegionID: regionId,
    },
    statusInfo: {
      status: properties.status,
      timeOfLastStatusChange: properties.most_recent_status_update,
    }
  }
}

// Converts the application fields to database fields
// See "appFieldNamesToDBFieldNames" object for name mapping
// Additional formatting changes are applied (e.g. converting an array to a formatted string)
export const convertAppFieldsToDbProperties = (values, tdisDataIdentifier) => {
  const transformedValues = Object.keys(values).reduce((newObj, key) => {
    const dbKey = appFieldNamesToDBFieldNames[key];
    if (dbKey) {
      newObj[dbKey] = values[key];
      // if app field is timePeriodCovered, convert dates to strings
      if (key === 'timePeriodCovered') {
        // Filter out date pairs where both start & end date are empty
        const filteredTimePeriods = values[key].filter(datePair => datePair[0] != null && datePair[1] != null);
        newObj[dbKey] = filteredTimePeriods < 1 ? null : filteredTimePeriods.map(timePeriod => timePeriod.map(date => moment(date).format('YYYY-MM-DD')));
      }
      // if app field is any other array, convert to a comma separated string
      else if (Array.isArray(values[key])) {
        if (key === 'chainOfCustody' || key === 'documentationFilenames') {
          newObj[dbKey] = values[key].map(value => {
            return value.replace(/["]+/g, '') // escape commas & remove double quotes
          }).join(',')
        } else {
          newObj[dbKey] = values[key].join(',')
        }
      }
      if (key === 'modelCreationDate' || key === 'modelLastUpdatedDate') {
        newObj[dbKey] = moment(newObj[dbKey]).format('YYYY-MM-DD')
      }
    }
    return newObj;
  }, {})
  return {
    ...transformedValues,
    license: '',
    collection_id: getCollectionIdFromRegion(values.twdbFloodPlanningRegionID),
    tdis_identifier: tdisDataIdentifier,
  }
}

// Database field names to application field names
export const dbFieldNamesToAppFields = {
  twdb_flood_planning_project_ids: 'twdbFloodPlanningProjectID',
  collection: 'tdisCollectionIdentifier',
  counties: 'county',
  cities: 'city',
  time_periods: 'timePeriodCovered',
  model_title: 'modelName',
  description: 'modelDescription',
  model_types: 'modelType',
  external_identifier: 'modelIdentifier',
  software: 'softwareName',
  software_version: 'softwareVersion',
  model_created: 'modelCreationDate',
  model_last_updated: 'modelLastUpdatedDate',
  update_interval: 'modelUpdateFrequency',
  documentation_filenames: 'documentationFilenames',
  custody_chain: 'chainOfCustody',
  hucs: 'hydrologicUnitCode',
  purpose: 'modelPurpose',
  model_contacts: 'contacts',
  origination_agency: 'modelOriginatorAgencyId',
}

// Application field names to database field names
export const appFieldNamesToDBFieldNames = {
  twdbFloodPlanningProjectID: 'twdb_flood_planning_project_ids',
  tdisCollectionIdentifier: 'collection_id',
  county: 'counties',
  city: 'cities',
  timePeriodCovered: 'time_period',
  modelName: 'model_name',
  modelDescription: 'model_description',
  modelType: 'model_types',
  modelIdentifier: 'external_model_identifier',
  softwareName: 'software_abbreviation',
  softwareVersion: 'software_version',
  modelCreationDate: 'model_created',
  modelLastUpdatedDate: 'model_last_updated',
  modelUpdateFrequency: 'model_update_interval',
  useConstraint: 'use_constraints',
  documentationFilenames: 'documentation_filenames',
  chainOfCustody: 'chain_custody',
  hydrologicUnitCode: 'huc',
  modelPurpose: 'model_purpose',
  contacts: 'contacts',
  modelOriginatorAgencyId: 'origination_agency_id',
}