import moment from "moment";
import { convertDbPropertiesToAppFields } from "../utils/fieldConversion";
require('dotenv').config()

const TDIS_URL_BASE = process.env.REACT_APP_TDIS_URL_BASE || 'https://ms2-dev.tacc.utexas.edu/tdis_middleware/api'
const TDIS_AUTH_URL_BASE = process.env.REACT_APP_TDIS_AUTH_URL_BASE || 'https://ms2-dev.tacc.utexas.edu/tdis_authentication/api'
const TOKEN_HEADER = 'x-access-token'

export const loginQuery = (values) => ({
  url: `${TDIS_AUTH_URL_BASE}/auth/signin`,
  options: { 
    method: 'POST',
    headers: {"Access-Control-Allow-Origin":"*"}
  },
  body: values,
  transform: (resp) => ({
    user: {
      ...values,
      ...resp,
    },
  }),
  update: {
    user: (prev, updated) => updated,
  },
});

export const logoutQuery = (token) => ({
  url: `${TDIS_AUTH_URL_BASE}/auth/logout`,
  options: { 
    method: 'POST',
    headers: {
      "Access-Control-Allow-Origin":"*",
      [TOKEN_HEADER]: token,
    }
  },
});

export const resetPasswordQuery = (values, token) => ({
  url: `${TDIS_AUTH_URL_BASE}/auth/reset-password`,
  body: values,
  options: { 
    method: 'POST',
    headers: {
      "Access-Control-Allow-Origin":"*",
      [TOKEN_HEADER]: token,
    }
  },
});

export const getNotificationsQuery = (tdisDataIdentifier, token) => ({
  url: `${TDIS_URL_BASE}/metadata/get-notifications/${tdisDataIdentifier}`,
  options: { 
    method: 'GET',
    headers: {
      "Access-Control-Allow-Origin":"*",
      [TOKEN_HEADER]: token,
    }
  },
  transform: (resp) => ({
    notificationsById: resp && resp.features ? resp.features.map(({ properties }) => properties) : [],
  }),
  update: {
    notificationsById: (prev, updated) => {
      return {
        ...prev,
        [tdisDataIdentifier]: updated
      }
    },
  },
});

// Due to the way conflicts are set up, "conflict" object will still be returned after a conflict has been resolved.
// If they are resolved, there will only be one non-archived "conflict" object per conflict_number. 
const noActiveConflictsForModel = (conflicts) => {
  // if every conflict is part of the same upload batch, return no conflicts
  const firstConflictBatchId = conflicts.length > 0 ? conflicts[0].upload_batch_id : null;
  if (conflicts.every(conflictObj => conflictObj.upload_batch_id === firstConflictBatchId)) {
    return true;
  }
  const conflictsByFilename = conflicts.reduce((accum, conflictObj) => {
    if (!accum[conflictObj.conflict_number]) {
      accum[conflictObj.conflict_number] = [];
    }
    accum[conflictObj.conflict_number].push(conflictObj);
    return accum;
  }, {});
  return Object.values(conflictsByFilename).every(conflictsArr => conflictsArr.length < 2);
}

// All upload conflicts across all models a user has access to
export const getAllUploadConflictsQuery = (username, token) => ({
  url: `${TDIS_URL_BASE}/vendors/get-upload-conflicts/${username}`,
  options: { 
    method: 'GET',
    headers: {
      "Access-Control-Allow-Origin":"*",
      [TOKEN_HEADER]: token,
    }
  },
  transform: (resp) => ({
    uploadConflictsById: resp && resp.features ? resp.features.map(({ properties }) => properties).filter(({ archived }) => !archived) : [],
  }),
  update: {
    uploadConflictsById: (prev, conflictsArray) => {
      const conflictsById = conflictsArray.reduce((accum, conflictObj) => {
        const tdisDataIdentifier = conflictObj.tdis_identifier;
        if (!Array.isArray(accum[tdisDataIdentifier])) {
          accum[tdisDataIdentifier] = [];
        }
        accum[tdisDataIdentifier].push(conflictObj);
        return accum;
      }, {})
      // Filter out models that only have conflicts from one batch (e.g. conflicts were previously there but resolved)
      return Object.keys(conflictsById).reduce((accum, tdisId) => {
        if (!noActiveConflictsForModel(conflictsById[tdisId])) {
          accum[tdisId] = conflictsById[tdisId];
        }
        return accum;
      }, {});
    },
  },
});

// https://ms2-dev.tacc.utexas.edu/tdis_middleware/api/metadata/get-upload-conflicts/TM-9f36cc8a-93e2-11ec-ba2a-59ebf1ac4488
// Get upload conflicts for a specific model
export const getUploadConflictsQuery = (tdisDataIdentifier, token) => ({
  url: `${TDIS_URL_BASE}/metadata/get-upload-conflicts/${tdisDataIdentifier}`, //TM-9f36cc8a-93e2-11ec-ba2a-59ebf1ac4488
  options: { 
    method: 'GET',
    headers: {
      "Access-Control-Allow-Origin":"*",
      [TOKEN_HEADER]: token,
    }
  },
  transform: (resp) => {
    const features = resp && resp.features;
    const nonArchivedConflicts = features ? features.map(({ properties }) => properties).filter(({ archived }) => !archived) : null;
    return {
      uploadConflictsById: nonArchivedConflicts
    }
  },
  update: {
    uploadConflictsById: (prev, updated) => {
      return {
        ...prev,
        [tdisDataIdentifier]: updated && !noActiveConflictsForModel(updated) ? updated : null
      }
    },
  },
});

// Resolve single file conflict
// {"username":"pbuschow","upload_id": 150}
export const resolveFileUploadConflictQuery = (values, token) => ({
  url: `${TDIS_URL_BASE}/vendors/resolve-upload-conflicts`,
  options: { 
    method: 'POST',
    headers: {
      "Access-Control-Allow-Origin":"*",
      [TOKEN_HEADER]: token,
    }
  },
  body: values,
})

// Batch resolve conflict by providing upload_batch_id
// {"username":"pbuschow", "model_tdis_identifier":"TM-f1f68322-b09b-11ec-b386-256c9c6fe0cd", "upload_batch_id": "guidy-guid"}
export const batchResolveUploadConflictQuery = (values, token) => ({
  url: `${TDIS_URL_BASE}/vendors/resolve-batch-upload-conflict`,
  options: { 
    method: 'POST',
    headers: {
      "Access-Control-Allow-Origin":"*",
      [TOKEN_HEADER]: token,
    }
  },
  body: values,
})


/* 
  RECORD QUERIES
  - List all records
  - Get single record by ID
  - Duplicate record
  - Save/Add record
  - Delete record
*/

const sortByMostRecentUpdate = (a, b) => {
  if(a.properties.most_recent_status_update > b.properties.most_recent_status_update) { return -1; }
  if(a.properties.most_recent_status_update < b.properties.most_recent_status_update) { return 1; }
  return 0;
}

export const getRecordsQuery = (userId, token) => ({
  url: `${TDIS_URL_BASE}/vendors/list-records/${userId}`,
  options: { 
    method: 'GET',
    headers: {
      "Access-Control-Allow-Origin":"*",
      "x-access-token": token,
    }
  },
  transform: (resp) => {
    const features = resp && resp.features;
    return {
      records: features ? features.sort(sortByMostRecentUpdate).map(({ properties }) => convertDbPropertiesToAppFields(properties)) : []
    }
  },
  update: {
    records: (prev, updated) => updated
  },
});

export const getRecordQuery = (tdisDataIdentifier, token) => ({
  url: `${TDIS_URL_BASE}/vendors/get-record/${tdisDataIdentifier}`,
  options: { 
    method: 'GET',
    headers: {
      "Access-Control-Allow-Origin":"*",
      "x-access-token": token,
    }
  },
  transform: (resp) => {
    const features = resp && resp.features;
    return {
      records: Array.isArray(features) && features.length > 0 ? convertDbPropertiesToAppFields(features[0].properties, true) : null
    }
  },
  update: {
    records: (prev, updated) => {
      if (!updated) {
        return prev;
      }
      if (!Array.isArray(prev)) {
        return [updated];
      }      
      const origIndex = prev.findIndex(record => record.tdisDataIdentifier === tdisDataIdentifier);
      if (origIndex === -1) {
        return [updated, ...prev]
      }
      const listWithUpdatedVal = prev;
      listWithUpdatedVal[origIndex] = updated;
      return listWithUpdatedVal;
    }
  },
});

export const duplicateRecordQuery = (token, recordId) => ({
  url: `${TDIS_URL_BASE}/vendors/duplicate-record`,
  options: { 
    method: 'POST',
    headers: {
      "Access-Control-Allow-Origin":"*",
      "x-access-token": token,
    }
  },
  body: {
    tdis_identifier: recordId
  }
});

export const saveRecordQuery = (newMetadataVals, origValues, tdisDataIdentifier, token) => ({
  url: `${TDIS_URL_BASE}/vendors/${tdisDataIdentifier ? 'save' : 'add'}-record`,
  options: { 
    method: 'POST',
    headers: {
      "Access-Control-Allow-Origin":"*",
      "x-access-token": token,
    }
  },
  body: newMetadataVals,
  transform: (resp) => {
    const newId = resp && Array.isArray(resp.features) && resp.features.length > 0 && resp.features[0].properties.tdis_identifier;
    return {
      records: newId,
    };
  },
  update: {
    records: (prev, newId) => {
      const updatedRecord = {
        currentMetadata: {
          ...origValues, 
        },
        tdisDataIdentifier: tdisDataIdentifier || newId,
        statusInfo: {
          status: 'in progress',
          timeOfLastStatusChange: new Date(),
        }
      };
      const prevRecords = (prev ? prev : []);
      const currentRecordIndex = prevRecords.findIndex(record => record.tdisDataIdentifier === tdisDataIdentifier)
      const newRecordState = [...prevRecords];
      if (currentRecordIndex != null && currentRecordIndex > -1) {
        newRecordState[currentRecordIndex] = updatedRecord
      } else {
        newRecordState.unshift(updatedRecord)
      }
      return newRecordState;
    },
  },
});

export const deleteRecordQuery = (recordId, token) => ({
  url: `${TDIS_URL_BASE}/vendors/delete-record`,
  options: { 
    method: 'POST',
    headers: {
      "Access-Control-Allow-Origin":"*",
      [TOKEN_HEADER]: token,
    }
  },
  body: {
    tdis_identifier: recordId,
  },
  update: {
    records: (prev, updated) => {
      const oldRecords = prev || [];
      const filteredRecords = oldRecords.filter(record => record.tdisDataIdentifier !== recordId);
      return filteredRecords;
    }
  },
});


/* 
  Contact Queries
  - Add contact
  - Get contact by id
  - Get all contacts
*/

export const addContactQuery = (values, token) => ({
  url: `${TDIS_URL_BASE}/metadata/contacts/add-contact`,
  options: { 
    method: 'POST',
    headers: {
      "Access-Control-Allow-Origin":"*",
      "x-access-token": token,
    }
  },
  body: values,
  transform: (resp) => {
    const features = resp && resp.features;
    return {
      contactsById: Array.isArray(features) && features.length > 0 ? features[0].properties.tdis_contact_id : null
    }
  },
  update: {
    contactsById: (prev, updated) => {
      return {
        ...prev,
        [updated]: convertDbContactToFormContact({ ...values, id: updated }),
      }
    },
  },
  // TODO: Update contacts state
});

export const updateContactQuery = (values, token) => ({
  url: `${TDIS_URL_BASE}/metadata/contacts/update-contact`,
  options: { 
    method: 'POST',
    headers: {
      "Access-Control-Allow-Origin":"*",
      "x-access-token": token,
    }
  },
  body: values,
  transform: () => ({
    contactsById: null,
  }),
  update: {
    contactsById: (prev, updated) => {
      return {
        ...prev,
        [values.contact_id]: convertDbContactToFormContact({ ...values }),
      }
    },
  },
});

const convertDbContactToFormContact = (properties) => ({
  ...properties,
  firstName: properties.first_name,
  lastName: properties.last_name,
  phoneNumber: properties.work_phone,
  id: properties.contact_id,
  agency: properties.agency
})

export const getContactByIdQuery = (contactId, token) => ({
  url: `${TDIS_URL_BASE}/metadata/contacts/get-contact/${contactId} `,
  options: { 
    method: 'GET',
    headers: {
      "Access-Control-Allow-Origin":"*",
      "x-access-token": token,
    }
  },
  transform: (resp) => {
    const features = resp && resp.features;
    return {
      contactsById: features ? convertDbContactToFormContact(features[0].properties) : null
    }
  },
  update: {
    contactsById: (prev, updated) => {
      return {
        ...prev,
        [contactId]: updated,
      }
    },
  },
});

export const getContactsQuery = (token) => ({
  url: `${TDIS_URL_BASE}/metadata/contacts/get-contacts`,
  options: { 
    method: 'GET',
    headers: {
      "Access-Control-Allow-Origin":"*",
      "x-access-token": token,
    }
  },
  transform: (resp) => {
    const features = resp && resp.features;
    return {
      contactsById: features ? features.reduce((accum, contactObj) => {
        const appContactObj = convertDbContactToFormContact(contactObj.properties)
        accum[appContactObj.id] = appContactObj;
        return accum;
      }, {}) : {},
    }
  },
  update: {
    contactsById: (prev, updated) => ({
      ...(prev || {}),
      ...updated,
    })
  },
});


/* 
  Option List Queries
  - Existing Collections
  - Software Options
  - Agency Options
  - Contact Roles
*/

export const getExistingCollectionsQuery = (token) => ({
  url: `${TDIS_URL_BASE}/collections/get-existing-collections`,
  options: { 
    method: 'GET',
    headers: {
      "Access-Control-Allow-Origin":"*",
      "x-access-token": token,
    }
  },
  // transform: (resp) => {
  //   const features = resp && resp.features;
  //   return {
  //     softwareList: features ? features.map(({ properties }) => convertDbPropertiesToAppFields(properties)) : []
  //   }
  // },
  // update: {
  //   softwareList: (prev, updated) => updated
  // },
});

// 
export const getSoftwareListQuery = (token) => ({
  url: `${TDIS_URL_BASE}/metadata/get-software-list`,
  options: { 
    method: 'GET',
    headers: {
      "Access-Control-Allow-Origin":"*",
      "x-access-token": token,
    }
  },
  transform: (resp) => {
    const features = resp && resp.features;
    return {
      softwareList: features ? features.map(({ properties }) => properties) : []
    }
  },
  update: {
    softwareList: (prev, updated) => updated
  },
});

export const getAgenciesQuery = (token) => ({
  url: `${TDIS_URL_BASE}/metadata/agencies/get-agencies`,
  options: { 
    method: 'GET',
    headers: {
      "Access-Control-Allow-Origin":"*",
      [TOKEN_HEADER]: token,
    }
  },
  transform: (resp) => {
    const features = resp && resp.features;
    return {
      agencies: features ? features.map(({ properties }) => properties) : []
    }
  },
  update: {
    agencies: (prev, updated) => updated
  },
});

export const getContactRolesQuery = (token) => ({
  url: `${TDIS_URL_BASE}/metadata/contacts/get-contact-roles`,
  options: { 
    method: 'GET',
    headers: {
      "Access-Control-Allow-Origin":"*",
      [TOKEN_HEADER]: token,
    }
  },
  transform: (resp) => {
    const features = resp && resp.features;
    return {
      contactRoles: features ? features.map(({ properties }) => properties) : []
    }
  },
  update: {
    contactRoles: (prev, updated) => updated
  },
});

// Helper for making a digestible file structure
// Identifies a string comprised of the first three 'directories' from a given path
// E.g. 'cool/cooler/coolest/super/duper' => 'cool/cooler/coolest/'
export const getFirstThreePaths = (path) => {
  const pathArr = path ? path.split('/') : [];
  return pathArr.length > 2 ? `${pathArr[0]}/${pathArr[1]}/${pathArr[2]}/` : ''
}

// Have to convert un-nested files array response into a nested structure to view hierarchically 
// on our application for browsing.
export const getFileStructureQuery = (tdisDataIdentifier, token) => ({
  url: `${TDIS_URL_BASE}/metadata/get-file-structure/${tdisDataIdentifier}`,
  options: { 
    method: 'GET',
    headers: {
      "Access-Control-Allow-Origin":"*",
      [TOKEN_HEADER]: token,
    }
  },
  transform: (resp) => {
    const features = resp && resp.features;
    // only keep directory & file "upload_type"s for the file structure (e.g. filter out "archive") 
    return {
      fileStructureById: (features || []).filter(
        ({ properties }) => properties.upload_type === 'file' || properties.upload_type === 'directory'
      ),
    }
  },
  update: {
    fileStructureById: (prev, updated) => {
      const unstructuredFiles = updated;
      let structuredFiles = {};
      // _region_/content/_id_ <- we want to remove this from our nested structure
      let firstThreePaths = null;
      unstructuredFiles.forEach(obj => {
        firstThreePaths = getFirstThreePaths(obj.properties.path)
        const path = obj.properties.path.replace(firstThreePaths, '')
        path.split('/').reduce(function(r, e) {
          return r[e] || (r[e] = {})
        }, structuredFiles)
      })

      return {
        ...prev,
        [tdisDataIdentifier]: {
          fileStructure: structuredFiles,
          unstructuredFiles,
        }
      }
    },
  },
});

// Get archives with paths for ZIP file download 
export const getModelArchivesQuery = (tdisDataIdentifier, token) => ({
  url: `${TDIS_URL_BASE}/metadata/get-model-archive/${tdisDataIdentifier}`,
  options: { 
    method: 'GET',
    headers: {
      "Access-Control-Allow-Origin":"*",
      "x-access-token": token,
    }
  },
  transform: (resp) => {
    const features = resp && resp.features;
    return {
      archivesById: features
    }
  },
  update: {
    archivesById: (prev, updated) => {
      return {
        ...prev,
        [tdisDataIdentifier]: updated
      }
    },
  },
});

// Get history of changes made to the metadata record
export const getHistoryQuery = (tdisDataIdentifier, token) => ({
  url: `${TDIS_URL_BASE}/metadata/get-history/${tdisDataIdentifier}`,
  options: { 
    method: 'GET',
    headers: {
      "Access-Control-Allow-Origin":"*",
      "x-access-token": token,
    }
  },
  transform: (resp) => {
    const features = resp && resp.features;
    return {
      recordHistoryById: features ? features.map(({ properties }) => {
        const hasOldMetadata = properties.old_values && Object.keys(properties.old_values).length > 0;
        const oldValues = properties.old_values
        const newValues = properties.new_values
        return {
          action: hasOldMetadata ? 'Edited' : 'Created',
          user: `${newValues.user_first_name} ${newValues.user_last_name}`,
          userId: newValues.user_id,
          username: newValues.username,
          time: newValues.record_updated_date,
          currentMetadata: convertDbPropertiesToAppFields(newValues).currentMetadata,
          oldMetadata: oldValues ? convertDbPropertiesToAppFields(oldValues).currentMetadata : null,
        }
      }) : []
    }
  },
  update: {
    recordHistoryById: (prev, updated) => {
      return {
        ...prev,
        [tdisDataIdentifier]: updated
      }
    },
  },
});



/*
  Messages Queries
  - Get messages
  - Send message
*/

export const getMessagesQuery = (tdisDataIdentifier, token) => ({
  url: `${TDIS_URL_BASE}/metadata/get-messages/${tdisDataIdentifier}`,
  options: { 
    method: 'GET',
    headers: {
      "Access-Control-Allow-Origin":"*",
      "x-access-token": token,
    }
  },
  transform: (resp) => {
    const features = resp && resp.features;
    return {
      messagesById: features ? features.map(({ properties }) => properties) : []
    }
  },
  update: {
    messagesById: (prev, updated) => {
      return {
        ...prev,
        [tdisDataIdentifier]: updated
      }
    },
  },
});

export const sendMessageQuery = (values, token) => ({
  url: `${TDIS_URL_BASE}/metadata/send-message`,
  body: values,
  options: { 
    method: 'POST',
    headers: {
      "Access-Control-Allow-Origin":"*",
      "x-access-token": token,
    }
  },
  update: {
    messagesById: (prev, updated) => {
      return {
        ...prev,
        [values.tdis_identifier]: [
          ...(prev[values.tdis_identifier] || []),
          {
            content: values.msg_content,
            username: values.username,
            date: moment().format(),
          },
        ]
      }
    },
  },
});

