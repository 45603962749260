import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import { clearStore } from '../actions/general';
import { USER_STORAGE_KEY } from '../constants/general';

// Returns function to clear state and redirect to the homepage with an error message
// For use when an unauthorized status is found
export const useHandleUnauthorized = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  return () => {
    dispatch(clearStore());
    localStorage.removeItem(USER_STORAGE_KEY);
    toast.info("Session expired");
    history.push(`/login`);
  }
}
